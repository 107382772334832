<template>
  <div class="level2-analytics-grid">
    <level2-tiles
      :filter-controller="filterController"
      :interactive-filters="interactiveFilters"
    />
    <grid-layout
      :col-num="12"
      :row-height="125"
      :is-resizable="false"
      :is-draggable="false"
      :vertical-compact="true"
      :use-css-transforms="false"
      :layout.sync="layout"
    >
      <grid-item
        v-for="item in computedLayout"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
      >
        <component
          :ref="item.component"
          :is="item.component"
          v-bind="item.props"
        />
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";

import DowntimeDurationByCode from "./charts/DowntimeDurationByCode.vue";
import DowntimeDurationByMachine from "./charts/DowntimeDurationByMachine.vue";
import DowntimeDurationTrend from "./charts/DowntimeDurationTrend.vue";
import DowntimeEventsByCode from "./charts/DowntimeEventsByCode.vue";
import DowntimeEventsByMachine from "./charts/DowntimeEventsByMachine.vue";
import DowntimeEventsTrend from "./charts/DowntimeEventsTrend.vue";
import Level2Tiles from "./charts/Level2Tiles.vue";

export default {
  name: "Level2AnalyticsGrid.vue",
  props: ["filterController", "interactiveFilters"],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Level2Tiles,
    DowntimeEventsByMachine,
    DowntimeDurationByMachine,
    DowntimeEventsByCode,
    DowntimeDurationByCode,
    DowntimeEventsTrend,
    DowntimeDurationTrend
  },
  created() {
    if (this.isSingleMachineWithoutGroup) {
      let machineId = null;
      if (this.filterController.initialFilters.machines.length === 1) {
        machineId = this.filterController.initialFilters.machines[0].pk;
      }
      const lastDurationByCodeComponentIndex = this.layout.findLastIndex(
        (item) => item.component === "DowntimeDurationByCode"
      );
      if (lastDurationByCodeComponentIndex >= 0) {
        this.layout.splice(
          lastDurationByCodeComponentIndex + 1,
          0,
          this.buildGridItem(lastDurationByCodeComponentIndex, machineId, "DowntimeEventsByCode"),
          this.buildGridItem(lastDurationByCodeComponentIndex, machineId, "DowntimeDurationByCode")
        );
        this.buildLayout();
        return;
      }
      const durationByMachineIndex = this.layout.findIndex(
        (item) => item.component === "DowntimeDurationByMachine"
      );
      if (durationByMachineIndex >= 0) {
        this.layout.splice(
          durationByMachineIndex + 1,
          0,
          this.buildGridItem(durationByMachineIndex, machineId, "DowntimeEventsByCode"),
          this.buildGridItem(durationByMachineIndex, machineId, "DowntimeDurationByCode")
        );
        this.buildLayout();
      }
    }
  },
  data() {
    return {
      layout: [
        {
          x: 0,
          y: 0,
          w: 6,
          h: 3,
          i: 1,
          component: "DowntimeEventsByMachine",
          props: {
            filterController: this.filterController,
            interactiveFilters: this.interactiveFilters,
            setSelectedMachines: this.setSelectedMachines
          }
        },
        {
          x: 6,
          y: 0,
          w: 6,
          h: 3,
          i: 2,
          component: "DowntimeDurationByMachine",
          props: {
            filterController: this.filterController,
            interactiveFilters: this.interactiveFilters,
            setSelectedMachines: this.setSelectedMachines
          }
        },
        {
          x: 0,
          y: 3,
          w: 6,
          h: 3,
          i: 5,
          component: "DowntimeEventsTrend",
          props: {
            filterController: this.filterController,
            interactiveFilters: this.interactiveFilters
          }
        },
        {
          x: 6,
          y: 3,
          w: 6,
          h: 3,
          i: 6,
          component: "DowntimeDurationTrend",
          props: {
            filterController: this.filterController,
            interactiveFilters: this.interactiveFilters
          }
        }
      ]
    };
  },
  methods: {
    buildGridItem(index, machineId, name) {
      return {
        x: name === "DowntimeEventsByCode" ? 0 : 6,
        y: this.layout[index].y + 3,
        w: 6,
        h: 3,
        i: index + 1,
        component: name,
        props: {
          filterController: this.filterController,
          interactiveFilters: this.interactiveFilters,
          machineId: machineId
        }
      };
    },
    buildLayout() {
      this.layout = this.layout.map((item, index, layout) => ({
        ...item,
        i: index,
        ...((item.component === "DowntimeEventsTrend" ||
          item.component === "DowntimeDurationTrend") && {
          y: layout[index - 1].y + 3
        })
      }));
    },
    setSelectedMachines(machineId) {
      const foundEventsByCodeComponentIndex = this.layout.findIndex(
        (item) => item.component === "DowntimeEventsByCode" && item.props.machineId === machineId
      );
      if (foundEventsByCodeComponentIndex >= 0) {
        this.layout.splice(foundEventsByCodeComponentIndex, 1);
        const foundDurationByCodeComponentIndex = this.layout.findIndex(
          (item) =>
            item.component === "DowntimeDurationByCode" && item.props.machineId === machineId
        );
        if (foundDurationByCodeComponentIndex >= 0) {
          this.layout.splice(foundDurationByCodeComponentIndex, 1);
        }
        this.interactiveFilters.codes = this.interactiveFilters.codes.filter(
          (code) => code.machineId !== machineId
        );
        this.buildLayout();
        return;
      }
      const lastDurationByCodeComponentIndex = this.layout.findLastIndex(
        (item) => item.component === "DowntimeDurationByCode"
      );
      if (lastDurationByCodeComponentIndex >= 0) {
        this.layout.splice(
          lastDurationByCodeComponentIndex + 1,
          0,
          this.buildGridItem(lastDurationByCodeComponentIndex, machineId, "DowntimeEventsByCode"),
          this.buildGridItem(lastDurationByCodeComponentIndex, machineId, "DowntimeDurationByCode")
        );
        this.buildLayout();
        return;
      }
      const durationByMachineIndex = this.layout.findIndex(
        (item) => item.component === "DowntimeDurationByMachine"
      );
      if (durationByMachineIndex >= 0) {
        this.layout.splice(
          durationByMachineIndex + 1,
          0,
          this.buildGridItem(durationByMachineIndex, machineId, "DowntimeEventsByCode"),
          this.buildGridItem(durationByMachineIndex, machineId, "DowntimeDurationByCode")
        );
        this.buildLayout();
      }
    }
  },
  computed: {
    isSingleMachineWithoutGroup() {
      return (
        (this.interactiveFilters.machines.length === 1 ||
          this.filterController.initialFilters.machines.length === 1) &&
        this.filterController.initialFilters.machine_groups.length === 0
      );
    },
    computedLayout() {
      return this.layout.map((item) => ({
        ...item,
        props: {
          filterController: this.filterController,
          interactiveFilters: this.interactiveFilters,
          setSelectedMachines: this.setSelectedMachines,
          machineId: item.props.machineId ? item.props.machineId : null
        }
      }));
    }
  },
  watch: {
    "filterController.reload": function () {
      if (this.isSingleMachineWithoutGroup) {
        let machineId = null;
        if (this.interactiveFilters.machines.length === 1) {
          machineId = this.interactiveFilters.machines[0].id;
        } else if (this.filterController.initialFilters.machines.length === 1) {
          machineId = this.filterController.initialFilters.machines[0].pk;
        }

        const eventsByCodeComponentExists = this.layout.some(
          (item) => item.component === "DowntimeEventsByCode"
        );
        const durationByCodeComponentExists = this.layout.some(
          (item) => item.component === "DowntimeDurationByCode"
        );

        if (!eventsByCodeComponentExists || !durationByCodeComponentExists) {
          const lastDurationByCodeComponentIndex = this.layout.findLastIndex(
            (item) => item.component === "DowntimeDurationByCode"
          );

          const durationByMachineIndex = this.layout.findIndex(
            (item) => item.component === "DowntimeDurationByMachine"
          );

          if (lastDurationByCodeComponentIndex >= 0) {
            const eventsByCodeGridItem = this.buildGridItem(
              lastDurationByCodeComponentIndex,
              machineId,
              "DowntimeEventsByCode"
            );

            const durationByCodeGridItem = this.buildGridItem(
              lastDurationByCodeComponentIndex,
              machineId,
              "DowntimeDurationByCode"
            );

            this.layout.splice(
              lastDurationByCodeComponentIndex + 1,
              0,
              eventsByCodeGridItem,
              durationByCodeGridItem
            );
          } else if (durationByMachineIndex >= 0) {
            const eventsByCodeGridItem = this.buildGridItem(
              durationByMachineIndex,
              machineId,
              "DowntimeEventsByCode"
            );

            const durationByCodeGridItem = this.buildGridItem(
              durationByMachineIndex,
              machineId,
              "DowntimeDurationByCode"
            );

            this.layout.splice(
              durationByMachineIndex + 1,
              0,
              eventsByCodeGridItem,
              durationByCodeGridItem
            );
          }

          this.buildLayout();
        }
      } else {
        this.layout = this.layout.filter(
          (item) =>
            item.component !== "DowntimeEventsByCode" && item.component !== "DowntimeDurationByCode"
        );
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";

.level2-analytics-grid {
  overflow-y: auto;
  flex-grow: 1;
  .card {
    .card-header {
      h4 {
        color: $blue;
      }
    }
    .card-content {
      padding-bottom: 1rem;
      .chart-container {
        height: 300px;
      }
      .footer {
        display: flex;
        flex: unset;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
</style>
