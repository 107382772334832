<template>
  <v-dialog
    v-model="showDialogue"
    class="add-event-dialog"
    persistent
    width="972"
    height="640"
  >
    <template #activator="{ on, attrs }">
      <v-hover>
        <Btn
          class="add-event-btn"
          variant="primary"
          large
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("Add event") }}
        </Btn>
      </v-hover>
    </template>
    <v-card>
      <div class="close-wrapper">
        <v-btn
          icon
          fab
          small
          class="close-btn"
          @click="clearData"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="pb-5 text-h6">
        {{ isEditSafetyEvent ? $t("Edit Safety Events") : $t("Add Safety Events") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex dialog-body">
          <div class="flex-grow-1">
            <HumanDiagram
              class="human mt-2"
              type="event"
              :affected-areas="affectedAreas"
              @pick-point="handleBodyPartClick"
            />

            <div class="mt-4 mr-4">
              <p class="text-caption color--text-secondary">
                {{ $t("Selected body parts:") }}
                <span v-if="affectedAreas.length === 0">({{ $t("None") }})</span>
              </p>
              <ul class="list-selected">
                <li
                  v-for="(bp, index) in affectedAreas"
                  :key="index"
                  class="list-selected-item color--text-primary"
                  :class="{ 'only-one-item': affectedAreas.length < 2 }"
                  dat
                >
                  {{ camelCaseToTitleCase(bp) }}
                </li>
              </ul>
            </div>
          </div>

          <div class="fields mt-5 pl-4 flex-shrink-0">
            <div class="mb-5">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="incidentDate"
                    :label="$t('Incident Date')"
                    append-icon="mdi-calendar"
                    readonly
                    filled
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="incidentDate"
                  :max="today"
                  :locale="$i18n.locale()"
                />
              </v-menu>
            </div>

            <v-text-field
              :label="$t('Group')"
              class="mb-5 field-item"
              clearable
              filled
              readonly
              hide-details
              :value="machineGroupName"
              @click="showGroupSelectionDialog = true"
              @click:clear.stop="clearMachineGroup"
            />

            <v-text-field
              v-if="shifts.length > 0"
              :label="$t('Shift')"
              class="mb-5 field-item"
              clearable
              filled
              readonly
              hide-details
              :value="shift.name"
              @click="showShiftSelectionDialog = true"
            />

            <v-textarea
              v-model="incidentDescription"
              :label="$t('Description')"
              class="mb-5"
              maxlength="500"
              counter
              auto-grow
              filled
              hide-details
              rows="3"
            />

            <v-textarea
              v-model="correctiveAction"
              :label="$t('Corrective Action')"
              class="mb-5"
              maxlength="500"
              auto-grow
              filled
              hide-details
              rows="3"
            />

            <v-radio-group
              v-model="selectedOption"
              class="radio-group"
              hide-details
              @change="onRadioChange"
            >
              <template #label>
                <span class="text-body-1 color--text-primary">{{ $t("Aid Type") }}</span>
              </template>
              <v-radio
                :label="$t('First Aid')"
                value="firstAid"
              />
              <v-radio
                :label="$t('Recordable')"
                value="recordable"
              />
            </v-radio-group>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="px-6 py-5">
        <v-spacer></v-spacer>
        <Btn
          class="cancel-btn"
          variant="secondary"
          @click="
            showDialogue = false;
            clearData();
          "
        >
          {{ $t("Cancel") }}
        </Btn>
        <Btn
          variant="primary"
          class="color--primary ml-3 submit-btn"
          :disabled="disableSubmit"
          :loading="saving"
          @click="() => createIssue(existingIssueId)"
        >
          {{ isEditSafetyEvent ? $t("Submit") : $t("Add") }}
        </Btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showShiftSelectionDialog"
      width="33%"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("Shift") }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="shift.id">
            <v-radio
              v-for="_shift in shifts"
              :key="_shift.pk"
              :label="_shift.name"
              :value="_shift.pk"
              hide-details
              @click="shift.name = _shift.name"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="px-6 pb-4 pt-2">
          <v-spacer></v-spacer>
          <Btn
            width="110"
            @click="showShiftSelectionDialog = false"
            >{{ $t("Close") }}</Btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showGroupSelectionDialog"
      width="33%"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("Machine Group") }}</v-card-title>
        <v-card-text>
          <tree-radio
            :groups="machineGroups"
            :safety-group-level="safetyGroupLevel"
            :selected-group="machineGroupId"
            :handle-machine-group-change="handleMachineGroupChange"
          />
        </v-card-text>
        <v-card-actions class="px-6 pb-4 pt-2">
          <v-spacer></v-spacer>
          <Btn
            width="110"
            @click="showGroupSelectionDialog = false"
            >{{ $t("Close") }}</Btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";

import Btn from "@/ui/Btn";

import TreeRadio from "@/components/TreeRadio.vue";
import HumanDiagram from "./HumanDiagram.vue";

export default {
  name: "ZoneControlSafetyEvent",
  components: { Btn, HumanDiagram, TreeRadio },
  props: {
    selectedEvent: {
      type: Object,
      default: null
    }
  },
  emits: ["safetyEventSubmitted", "clear-selected-event"],
  data() {
    return {
      affectedAreas: [],
      correctiveAction: "",
      firstAid: false,
      incidentDate: new Date().toISOString().slice(0, 10),
      incidentDescription: "",
      menu: false,
      recordable: false,
      saving: false,
      showDialogue: false,
      machineGroups: [],
      machineGroupId: null,
      machineGroupName: "",
      shifts: [],
      shift: { id: null, name: "" },
      showGroupSelectionDialog: false,
      showShiftSelectionDialog: false,
      safetyGroupLevel: null,
      selectedOption: null,
      existingIssueId: null
    };
  },
  computed: {
    today() {
      return new Date().toISOString().slice(0, 10);
    },
    disableSubmit() {
      return !(
        this.incidentDate &&
        this.incidentDescription &&
        this.correctiveAction &&
        this.machineGroupId
      );
    },
    isEditSafetyEvent() {
      return this.selectedEvent && Object.keys(this.selectedEvent).length > 0;
    }
  },
  watch: {
    selectedEvent: {
      handler: function (newVal) {
        if (newVal) {
          this.loadEventData(newVal);
          this.showDialogue = true;
        } else {
          this.clearData();
        }
      },
      deep: true
    }
  },
  created() {
    this.getSafetyGroupLevel();
    this.loadMachineGroups();
  },
  methods: {
    handleBodyPartClick(name) {
      if (this.affectedAreas.includes(name)) {
        this.affectedAreas = this.affectedAreas.filter((item) => item !== name);
      } else {
        this.affectedAreas.push(name);
      }
    },
    async createIssue(existingIssueId = null) {
      try {
        this.saving = true;
        const mutationName = existingIssueId ? "updateSafetyEvent" : "createSafetyEvent";
        const inputTypeName = existingIssueId ? "UpdateSafetyEventInput" : "SafetyEventInput";
        const query = `mutation ($input: ${inputTypeName}!) {
      ${mutationName}(safetyData: $input) {
        safetyEvent {
          id
          description
          correctiveAction
          firstAid
          recordable
          affectedBodyPart
          shiftId
          machineGroupId
        }
      }
    }`;
        let input = {
          description: this.incidentDescription,
          correctiveAction: this.correctiveAction,
          firstAid: this.firstAid,
          recordable: this.recordable,
          incidentDate: moment(this.incidentDate).toISOString(),
          affectedBodyPart: this.affectedAreas.join("|"),
          shiftId: this.shift.id,
          machineGroupId: this.machineGroupId
        };
        if (existingIssueId) {
          input.id = existingIssueId;
          input.delete = false;
        }
        let variables = { input };
        const response = await this.$http.post("graphql/", {
          query,
          variables
        });
        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }
        this.saving = false;
        const message = existingIssueId ? "Safety Ticket Updated" : "Safety Ticket Submitted";
        this.$message("ALERT", {
          text: message,
          type: "success"
        });
        this.clearData();
      } catch (error) {
        console.error("Error creating/updating the issue:", error);
        this.errors = error.errors;
        this.saving = false;
        this.clearData();
        this.$message("ALERT", { text: "Failed", type: "error" });
      }
      setTimeout(() => {
        this.$emit("safetyEventSubmitted");
      }, 1000);
    },
    clearData() {
      this.incidentDate = new Date().toISOString().slice(0, 10);
      this.correctiveAction = "";
      this.incidentDescription = "";
      this.firstAid = false;
      this.recordable = false;
      this.affectedAreas = [];
      this.showDialogue = false;
      this.machineGroupId = null;
      this.machineGroupName = "";
      this.shiftId = null;
      this.selectedOption = null;
      this.existingIssueId = null;
      this.$emit("clear-selected-event");
    },
    /**
     * @param str the string to convert
     * @returns string
     */
    camelCaseToTitleCase(str) {
      return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },
    async getSafetyGroupLevel() {
      const response = await this.$http.get("graphql/", {
        params: {
          query: `{
           settings{
             safetyGroupLevel {
               pk
               name
               id
               level
             }
           }
         }`
        }
      });
      this.safetyGroupLevel = response.data.data.settings[0].safetyGroupLevel.level;
    },
    formatData(data) {
      const result = [];
      const mapping = {};
      data.forEach((item) => {
        mapping[item.pk] = item;
        item.children = [];
      });
      for (const item of data) {
        if (item.parentGroup === null) {
          result.push(item);
        } else {
          const parentId = item.parentGroup.pk;
          if (mapping[parentId]) {
            mapping[parentId].children.push(item);
          }
        }
      }
      return result;
    },
    async loadMachineGroups() {
      try {
        const res = await this.$http.get("graphql/", {
          params: {
            query: `{
        machineGroups {
          name
          pk
          level {
            level
          }
          parentGroup {
            pk
          }
        }
      }`
          }
        });
        this.machineGroups = this.formatData(res.data.data.machineGroups);
      } catch (error) {
        console.error(error);
      }
    },
    async loadShifts() {
      if (this.machineGroupId === undefined) {
        this.shifts = [];
        return;
      }
      try {
        const response = await this.$http.post("/graphql/", {
          query: `
            query {
              shifts(machineGroupId: ${this.machineGroupId}) {
                pk
                name
              }
            }
          `
        });
        this.shifts = response.data.data.shifts;
      } catch (error) {
        console.error(error);
      }
    },
    handleMachineGroupChange(pk, machineGroupName) {
      this.machineGroupId = pk;
      this.machineGroupName = machineGroupName;
      this.loadShifts();
    },
    clearMachineGroup() {
      this.machineGroupId = null;
      this.machineGroupName = null;
      this.shifts = [];
    },
    onRadioChange() {
      if (this.selectedOption === "firstAid") {
        this.firstAid = true;
        this.recordable = false;
      } else if (this.selectedOption === "recordable") {
        this.firstAid = false;
        this.recordable = true;
      }
    },
    loadEventData(eventData) {
      this.existingIssueId = eventData.id;
      this.incidentDate = eventData.incidentDate.slice(0, 10);
      this.machineGroupId = eventData.machineGroupId;
      this.machineGroupName = this.getMachineGroupName(eventData.machineGroupId);
      this.shiftId = eventData.shiftId;
      this.incidentDescription = eventData.description;
      this.correctiveAction = eventData.correctiveAction;
      this.selectedOption = eventData.selectedOption;
      this.affectedAreas = eventData.affectedBodyPart.split("|");
      this.firstAid = eventData.firstAid;
      this.recordable = eventData.recordable;
      this.selectedOption = this.firstAid ? "firstAid" : "recordable";
    },
    getMachineGroupName(pk) {
      const findMachineGroup = (machineGroups, pk) => {
        for (const machineGroup of machineGroups) {
          if (machineGroup.pk === pk) {
            return machineGroup;
          }
          const found = findMachineGroup(machineGroup.children, pk);
          if (found) {
            return found;
          }
        }
        return null;
      };
      const machineGroup = findMachineGroup(this.machineGroups, pk);
      return machineGroup ? machineGroup.name : "";
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~@/scss/dropdown";
@import "~@/scss/mq.scss";

::v-deep .v-card__text {
  padding-bottom: 0 !important;
}

.dialog-body {
  height: 495px;
  overflow-y: auto;
}

.fields {
  width: 316px;
}

.field-item::v-deep.v-input--is-dirty .v-input__icon--clear {
  opacity: 1;
}

.radio-group {
  ::v-deep {
    legend.v-label {
      padding-bottom: 0;
      margin-bottom: 4px;
      line-height: 24px;
    }
    label.v-label {
      color: var(--text-primary);
      line-height: 40px;
    }
    .v-radio {
      margin-bottom: 0 !important;
    }
  }
}

.close-wrapper {
  position: relative;
}

.close-btn {
  position: absolute;
  right: 14px;
  top: 12px;
}

.cancel-btn,
.submit-btn {
  width: calc(150px - 6px);
}

.add-event-btn {
  width: 144px;
}

.list-selected {
  padding-left: 0;
  display: block;
}

.list-selected-item {
  list-style-type: none;
  display: inline-flex;
}

.list-selected-item:not(:last-of-type):after {
  content: ",";
  display: inline-block;
  margin-right: 8px;
  position: static;
}

.only-one-item:after {
  display: none;
}

@include media_below(821px) {
  @media screen and (orientation: portrait) {
    .human {
      ::v-deep {
        & > .row > .col {
          height: 432px;
        }
        svg {
          transform: scale(1.6) translateY(44px);
        }
      }
    }
  }
}
</style>
