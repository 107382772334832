<template>
  <div class="ldms-analytics">
    <FilterLDMSAnalytics
      :applyFilters="applyFilters"
      :applySelectedFilterType="applySelectedFilterType"
      :selectedFilterType="selectedFilterType"
    />
    <div
      class="card no-data"
      v-if="loading || noData"
    >
      <p v-if="noData">{{ $t("No Data") }}</p>
      <div class="loading-bar">
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
      </div>
    </div>
    <div
      v-show="showImpactSummaryView && !loading && !noData"
      class="impact-summary"
    >
      <div
        class="charts-container"
        v-show="!loading && !noData"
      >
        <div class="machines-parts-tree-maps">
          <LDMSAnalyticsMachines
            ref="machines"
            :filters="filters"
            :interactiveFilters="interactiveFilters"
          />
          <LDMSAnalyticsParts
            ref="parts"
            :filters="filters"
            :interactiveFilters="interactiveFilters"
          />
        </div>
        <div class="reasons-tree-map">
          <LDMSAnalyticsReasons
            ref="reasons"
            :filters="filters"
            :interactiveFilters="interactiveFilters"
            :predefinedReasons="predefinedReasons"
          />
        </div>
        <div class="countermeasure-rootcause-charts">
          <LDMSAnalyticsCountermeasures
            v-show="showCountermeasuresChart"
            ref="countermeasures"
            :filters="filters"
            :interactiveFilters="interactiveFilters"
            :showRootCausesChart="showRootCausesChart"
            :predefinedCountermeasures="predefinedCountermeasures"
          />
          <LDMSAnalyticsRootCauses
            v-show="showRootCausesChart"
            ref="rootCauses"
            :filters="filters"
            :interactiveFilters="interactiveFilters"
            :showCountermeasuresChart="showCountermeasuresChart"
            :predefinedRootCauses="predefinedRootCauses"
          />
        </div>
        <div
          v-show="showSolutionsChart"
          class="solutions-chart"
        >
          <LDMSAnalyticsSolutions
            ref="solutions"
            :filters="filters"
            :interactiveFilters="interactiveFilters"
            :predefinedSolutions="predefinedSolutions"
          />
        </div>
        <div
          v-show="showIssueTable"
          class="ldms-issue-table"
        >
          <LDMSAnalyticsIssueTable :issueData="issueTableData" />
        </div>
      </div>
    </div>
    <div
      v-if="showPerformanceSummaryView && !loading && !noData"
      class="performance-summary"
    >
      <LDMSAnalyticsPerformanceSummaryView
        :filters="filters"
        :levelP1="levelP1"
        :levelP2="levelP2"
      />
    </div>
    <LDMSAnalyticsDataTableView
      v-if="showDataTableView && !loading && !noData"
      class="data-table-view"
      :filters="filters"
      :levelP1="levelP1"
      :levelP2="levelP2"
    />
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import { mapActions } from "vuex";

import { FILTER_TYPES } from "@/utils/LDMSAnalyticsUtils";

import FilterLDMSAnalytics from "./FilterLDMSAnalytics";
import LDMSAnalyticsDataTableView from "./data-table-view/LDMSAnalyticsDataTableView";
import LDMSAnalyticsCountermeasures from "./impact-summary-view/LDMSAnalyticsCountermeasures";
import LDMSAnalyticsIssueTable from "./impact-summary-view/LDMSAnalyticsIssueTable";
import LDMSAnalyticsMachines from "./impact-summary-view/LDMSAnalyticsMachines";
import LDMSAnalyticsParts from "./impact-summary-view/LDMSAnalyticsParts";
import LDMSAnalyticsReasons from "./impact-summary-view/LDMSAnalyticsReasons";
import LDMSAnalyticsRootCauses from "./impact-summary-view/LDMSAnalyticsRootCauses";
import LDMSAnalyticsSolutions from "./impact-summary-view/LDMSAnalyticsSolutions";
import LDMSAnalyticsPerformanceSummaryView from "./performance-summary-view/LDMSAnalyticsPerformanceSummaryView";

export default {
  components: {
    LDMSAnalyticsMachines,
    LDMSAnalyticsParts,
    LDMSAnalyticsReasons,
    LDMSAnalyticsCountermeasures,
    LDMSAnalyticsRootCauses,
    LDMSAnalyticsSolutions,
    FilterLDMSAnalytics,
    LDMSAnalyticsIssueTable,
    LDMSAnalyticsPerformanceSummaryView,
    LDMSAnalyticsDataTableView
  },
  data() {
    return {
      loading: false,
      noData: true,
      selectedFilterType: FILTER_TYPES.IMPACT_SUMMARY,
      filters: {},
      interactiveFilters: {
        machines: [],
        parts: [],
        reasons: [],
        countermeasures: [],
        rootCauses: [],
        solutions: []
      },
      showCountermeasuresChart: false,
      showRootCausesChart: false,
      showSolutionsChart: false,
      showIssueTable: false,
      showImpactSummaryView: true,
      showPerformanceSummaryView: false,
      showDataTableView: false,
      predefinedReasons: [],
      predefinedCountermeasures: [],
      predefinedRootCauses: [],
      predefinedSolutions: [],
      issueTableData: [],
      levelP1: null,
      levelP2: null
    };
  },
  async created() {
    this.setTitles({
      title: this.$t("LDMS Analytics"),
      mobile: this.$t("LDMS Analytics")
    });
    this.getPredefinedReasonsCountermeasuresCausesAndSolutions();
    await this.getLDMSReportMachineGroupLevels();
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    async getPredefinedReasonsCountermeasuresCausesAndSolutions() {
      const query = `{
        issueReasons {
          edges {
            node {
              text,
            }
          }
        }
        issueCountermeasures {
          edges {
            node {
              text,
            }
          }
        }
        issueCauses {
          edges {
            node {
              text,
            }
          }
        }
        issueSolutions {
          edges {
            node {
              text,
            }
          }
        }
      }`;
      try {
        const response = await this.$http.get("graphql/", { params: { query } });
        if (response && response.data && response.data.data) {
          const data = response.data.data;
          this.predefinedReasons = data.issueReasons.edges.map((reason) => ({
            id: reason.node.id,
            text: reason.node.text
          }));
          this.predefinedCountermeasures = data.issueCountermeasures.edges.map(
            (countermeasure) => ({ id: countermeasure.node.id, text: countermeasure.node.text })
          );
          this.predefinedRootCauses = data.issueCauses.edges.map((cause) => ({
            id: cause.node.id,
            text: cause.node.text
          }));
          this.predefinedSolutions = data.issueSolutions.edges.map((solution) => ({
            id: solution.node.id,
            text: solution.node.text
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getLDMSReportMachineGroupLevels() {
      const query = `{
        settings {
          ldmsReportMachineGroupLevelP1 {
            pk,
            name,
            level,
          }
          ldmsReportMachineGroupLevelP2 {
            pk,
            name,
            level,
          }
        }
      }`;
      try {
        const response = await this.$http.get("graphql/", { params: { query } });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.settings &&
          response.data.data.settings.length &&
          response.data.data.settings.length > 0
        ) {
          this.levelP1 = response.data.data.settings[0]["ldmsReportMachineGroupLevelP1"];
          this.levelP2 = response.data.data.settings[0]["ldmsReportMachineGroupLevelP2"];
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    },
    async getIssueTableData() {
      const query = `query (
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingIssueSearchConnectionBackendFilter!) {
          issues (filter: $filters, ordering: $ordering) {
            edges {
              node {
                id,
                title,
                reason,
                issueStartDate,
                reasonId,
                countermeasures,
                cause,
                solution
              }
            }
          }
      }`;
      // use global filters for machine and parts,
      // these filters will be overridden from interactiveFilters if they set below
      // reason should be used only from interactiveFilters
      const machineIds = this.filters.machines ? this.filters.machines : [];
      const partIds = this.filters.parts ? this.filters.parts : [];

      const variables = {
        filters: {
          reasonId: { in: this.interactiveFilters.reasons.map((reason) => reason.id) },
          machineId: { in: machineIds },
          partId: { in: partIds },
          issueStartDate: {
            range: {
              lower: { datetime: this.filters.fromDate },
              upper: { datetime: this.filters.toDate }
            }
          }
        },
        ordering: { issueStartDate: "ASC" }
      };
      if (this.interactiveFilters.machines.length > 0) {
        variables["filters"]["machineId"] = {
          in: this.interactiveFilters.machines.map((machine) => machine.id)
        };
      }
      if (this.interactiveFilters.parts.length > 0) {
        variables["filters"]["partId"] = {
          in: this.interactiveFilters.parts.map((part) => part.id)
        };
      }
      if (this.interactiveFilters.countermeasures.length > 0) {
        variables["filters"]["countermeasures"] = {
          terms: this.interactiveFilters.countermeasures.map((countermeasure) => countermeasure.key)
        };
      }
      if (this.interactiveFilters.rootCauses.length > 0) {
        variables["filters"]["cause"] = {
          terms: this.interactiveFilters.rootCauses.map((rootCause) => rootCause.key)
        };
      }
      if (this.interactiveFilters.solutions.length > 0) {
        variables["filters"]["solution"] = {
          terms: this.interactiveFilters.solutions.map((solution) => solution.key)
        };
      }
      try {
        const response = await this.$http.post("graphql/", { query, variables });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.issues &&
          response.data.data.issues.edges
        ) {
          const data = response.data.data.issues.edges.map((item) => {
            return {
              ...item.node,
              cause: item.node.cause
                ? item.node.cause.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "")
                : null,
              countermeasures: item.node.countermeasures
                ? item.node.countermeasures
                    .replaceAll("[", "")
                    .replaceAll("]", "")
                    .replaceAll("'", "")
                : null,
              solution: item.node.solution
                ? item.node.solution.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "")
                : null,
              issueStartDate: item.node.issueStartDate
                ? moment(item.node.issueStartDate).format("DD-MMM-YY")
                : null
            };
          });
          this.issueTableData = data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    applySelectedFilterType(filterType) {
      this.selectedFilterType = FILTER_TYPES[filterType];
    },
    applyFilters(filters) {
      this.filters = filters;
    },
    handleCountermeasuresChartVisibility(isVisible) {
      this.showCountermeasuresChart = isVisible;
    },
    handleRootCausesChartVisibility(isVisible) {
      this.showRootCausesChart = isVisible;
    },
    handleSolutionsChartVisibility(isVisible) {
      this.showSolutionsChart = isVisible;
    },
    handleIssueTableVisibility(isVisible) {
      this.showIssueTable = isVisible;
    },
    handleImpactSummaryViewVisibility(isVisible) {
      this.showImpactSummaryView = isVisible;
    },
    handlePerformanceSummaryViewVisibility(isVisible) {
      this.showPerformanceSummaryView = isVisible;
    },
    handleDataTableViewVisibility(isVisible) {
      this.showDataTableView = isVisible;
    },
    clearInteractiveFilters() {
      Object.keys(this.interactiveFilters).forEach((item) => {
        this.interactiveFilters[item] = [];
      });
    }
  },
  watch: {
    selectedFilterType(newValue, oldValue) {
      switch (newValue) {
        case FILTER_TYPES.IMPACT_SUMMARY:
          this.handleDataTableViewVisibility(false);
          this.handlePerformanceSummaryViewVisibility(false);
          this.handleImpactSummaryViewVisibility(true);
          break;
        case FILTER_TYPES.PERFORMANCE_SUMMARY:
          this.handleDataTableViewVisibility(false);
          this.handleImpactSummaryViewVisibility(false);
          this.handlePerformanceSummaryViewVisibility(true);
          break;
        case FILTER_TYPES.DATA_TABLE:
          this.handleImpactSummaryViewVisibility(false);
          this.handlePerformanceSummaryViewVisibility(false);
          this.handleDataTableViewVisibility(true);
          break;
      }
    },
    "interactiveFilters.machines.length": function () {
      this.$refs.parts.getTopPartsData();
      this.$refs.reasons.getTopReasonsData();
    },
    "interactiveFilters.parts.length": function () {
      this.$refs.machines.getTopMachinesData();
      this.$refs.reasons.getTopReasonsData();
    },
    "interactiveFilters.reasons.length": function () {
      Promise.all([this.$refs.machines.getTopMachinesData(), this.$refs.parts.getTopPartsData()]);
      if (this.interactiveFilters.reasons.length > 0) {
        Promise.all([
          this.getIssueTableData(),
          this.$refs.rootCauses.getRootCauseData(),
          this.$refs.countermeasures.getCountermeasureData(),
          this.$refs.solutions.getSolutionsData()
        ]).then(() => {
          this.handleRootCausesChartVisibility(true);
          this.handleCountermeasuresChartVisibility(true);
          this.handleSolutionsChartVisibility(true);
          this.handleIssueTableVisibility(true);
        });
      } else {
        this.handleRootCausesChartVisibility(false);
        this.handleCountermeasuresChartVisibility(false);
        this.handleSolutionsChartVisibility(false);
        this.handleIssueTableVisibility(false);
      }
    },
    "interactiveFilters.countermeasures.length": function () {
      this.getIssueTableData();
      this.$refs.solutions.getSolutionsData();
    },
    "interactiveFilters.rootCauses.length": function () {
      this.getIssueTableData();
      this.$refs.solutions.getSolutionsData();
    },
    "interactiveFilters.solutions.length": function () {
      this.getIssueTableData();
    },
    filters(newFilter, oldFilter) {
      if (!newFilter.fromDate || !newFilter.toDate) {
        return;
      }
      this.loading = true;
      this.clearInteractiveFilters();
      this.handleRootCausesChartVisibility(false);
      this.handleCountermeasuresChartVisibility(false);
      this.handleSolutionsChartVisibility(false);
      Vue.nextTick(() => {
        Promise.all([
          this.$refs.machines.getTopMachinesData(),
          this.$refs.parts.getTopPartsData(),
          this.$refs.reasons.getTopReasonsData()
        ]).then(() => {
          this.loading = false;
          this.noData = false;
        });
      });
    }
  }
};
</script>
<style lang="scss">
@import "./LDMSAnalytics";
</style>
