import Vue from "vue";

export function FormatTime(timeInSeconds, format = "hm") {
  return _FormatTime(timeInSeconds, format)
    .split(" ")
    .map((word) => Vue.i18n.translate(word.trim()))
    .join(" ");
}

export function _FormatTime(timeInSeconds, format = "hm") {
  // eslint-disable-next-line use-isnan
  if (timeInSeconds == null || timeInSeconds == NaN) {
    return "";
  }
  try {
    const hours = timeInSeconds / (60 * 60);
    const minutes = hours - Math.floor(hours);
    const seconds = ((minutes * 60) % 1) * 60;

    switch (format) {
      case "hms": {
        const hms = [Math.floor(hours), Math.floor(minutes * 60), Math.floor(seconds)];
        return `${Number(hms[0])} ${hms[0] > 1 || hms[0] == 0 ? "hrs" : "hr"} ${hms[1] > 0 ? Number(hms[1]) + (hms[1] > 1 ? " mins" : " min") : ""} ${hms[2] > 0 ? Number(hms[2]) + (hms[2] > 1 ? " secs" : " sec") : ""}`;
      }
      case "hm": {
        const hm = [Math.floor(hours), Math.floor(minutes * 60)];
        return ` ${Number(hm[0])} ${hm[0] > 1 || hm[0] == 0 ? "hrs" : "hr"} ${hm[1] > 0 ? Number(hm[1]) + (hm[1] > 1 ? " mins" : " min") : ""}
                `;
      }
      case "h": {
        const h = [Math.floor(hours), Math.floor(minutes * 60)];
        return ` ${Number(h[0])} ${h[0] > 1 || h[0] == 0 ? "hrs" : "hr"}`;
      }
    }
  } catch (e) {
    return "";
  }
}

export function GetAveragePerHour(produced, time) {
  if (time == 0) {
    return "--";
  }

  const timeInHours = time / 60 / 60;
  const perHour = produced / timeInHours;

  return Math.round(perHour);
}
