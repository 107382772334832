<template>
  <div class="image-gallery-holder">
    <div
      class="image-container"
      v-for="(document, index) of documents"
      @click="viewDocumentWithIndex(index)"
    >
      <div
        class="image-wrapper"
        :class="document._source.inspection_result"
      >
        <div
          class="image"
          :style="{ backgroundImage: `url(${document.image_url})` }"
        />
      </div>
      <div class="image-title">
        {{ moment(document._source.time_stamp).format($datetime.iot) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "AiVisionGalleryView",
  props: ["documents", "viewDocumentWithIndex"],
  methods: {
    moment
  }
};
</script>

<style scoped lang="scss">
.image-gallery-holder {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-row-gap: 0.5em;
  grid-column-gap: 1em;
  justify-content: space-evenly;
  align-content: flex-start;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 15px;

  .image-container {
    width: 250px;
    height: 180px;
    margin: 2px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .image-wrapper {
      padding: 3px;
      border-radius: 4px;
      flex: 1;

      &.PASS {
        border: 4px solid green;
      }

      &.FAIL {
        border: 4px solid red;
      }

      .image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-origin: border-box;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .image-title {
      text-align: center;
      font-size: 12px;
      height: 30px;
      flex: unset;
      justify-content: flex-start;
    }
  }
}
</style>
