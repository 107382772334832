<template>
  <div :class="`status ${color}`">{{ TEXT_BY_STATUS[status] }}</div>
</template>

<script>
import { computed, getCurrentInstance, toRefs } from "@vue/composition-api";

export default {
  name: "TicketStatus",
  components: {},
  props: {
    status: { type: String, default: undefined }
  },

  setup(props) {
    const { status } = toRefs(props);
    const root = getCurrentInstance().proxy;
    const $t = root.$t;

    const COLOR_BY_STATUS = {
      closed: "color--text-yellow",
      open: "color--text-green",
      in_progress: "color--text-accent"
    };

    const TEXT_BY_STATUS = {
      open: $t("Open"),
      closed: $t("Closed"),
      in_progress: $t("Active")
    };

    const color = computed(() => COLOR_BY_STATUS[status.value] || COLOR_BY_STATUS.closed);

    return { color, TEXT_BY_STATUS };
  }
};
</script>

<style lang="scss" scoped></style>
