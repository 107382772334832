<template>
  <div class="downtime-events-by-code">
    <div class="card">
      <div class="card-header">
        <h4>{{ $t("Downtime Events (counts) for ") }} {{ getMachineName() }}</h4>
      </div>
      <div class="card-content">
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
        <BarChart
          v-if="!loading"
          class="chart-container"
          :chart-data="chartData"
          :chart-options="{ xLabelLimit: 15 }"
          :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
        />
        <div class="footer">
          <bar-chart-paginator
            v-if="!loading"
            :refresh="filterController.reload"
            :rawData="responseData"
            :sortMethod="sortFunc"
            default-sort="desc"
            :buildChart="formatData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { tiffanyBlue } from "@/scss/_variables.scss";
import { getEndTime, getStartTime, handleBackgroundColorOpacity } from "@/utils/level2Utils";

import BarChartPaginator from "../../../../components/BarChartPaginator.vue";
import BarChart from "../../../../components/charts/BarChart";

export default {
  name: "DowntimeEventsByCode",
  props: ["filterController", "interactiveFilters", "machineId"],
  async created() {
    await this.loadCodes();
    await this.loadData();
  },
  components: {
    BarChart,
    BarChartPaginator
  },
  data() {
    return {
      loading: true,
      responseData: [],
      chartData: {},
      chartIds: [],
      codes: [],
      codeIds: [],
      slicedData: []
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk",
      machineCodes: "dbCache/machineCodes"
    })
  },
  methods: {
    getMachineName() {
      if (this.machineId !== undefined) {
        return this.machineLookup(this.machineId).name;
      }
      return this.$t("Unknown Machine");
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilter(elementIndex) {
      const codeId = this.codeIds[elementIndex];
      const foundCodeIndex = this.interactiveFilters.codes.findIndex(
        (code) => code.id === codeId && code.machineId === this.machineId
      );
      if (foundCodeIndex >= 0) {
        this.interactiveFilters.codes.splice(foundCodeIndex, 1);
      } else {
        this.interactiveFilters.codes.push({
          machineId: this.machineId,
          id: codeId,
          index: elementIndex
        });
      }
    },
    updateBackgroundColors() {
      this.chartData.datasets[0].backgroundColor = handleBackgroundColorOpacity(
        this.slicedData,
        this.chartData,
        this.interactiveFilters
      );
      this.chartData = { ...this.chartData };
    },
    async loadCodes() {
      let query = `query( $machineId: Int!){
        level2Codes(machineId: $machineId){
          id
          pk
          machine{
            pk
          }
          part{
            pk
          }
          code
          description
          eventType
        }
      }`;
      let variables = {
        machineId: this.machineId
      };
      return this.$http.get("graphql/", { params: { query, variables } }).then((res) => {
        this.codes = res.data.data.level2Codes;
      });
    },
    async loadData() {
      this.loading = true;
      const params = {
        from_date: this.filterController.initialFilters.date_range.from_date,
        to_date: this.filterController.initialFilters.date_range.to_date,
        machine_id: this.machineId
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      if (this.interactiveFilters.trends.length > 0) {
        const trendKeys = this.interactiveFilters.trends.map((trend) => trend.id);
        params.from_date = getStartTime(
          Math.min(...trendKeys),
          this.filterController.initialFilters.scale
        );
        params.to_date = getEndTime(
          Math.max(...trendKeys),
          this.filterController.initialFilters.scale
        );
      }
      if (this.filterController.initialFilters.codeEventType) {
        params.event_type = this.filterController.initialFilters.codeEventType;
      }
      try {
        const response = await this.$http.get("level2/events_by_code/", { params });
        if (response && response.data) {
          this.responseData = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    formatData(slicedData) {
      this.chartData = {};
      this.chartIds = [];

      this.chartData.datasets = [
        {
          data: [],
          label: [],
          backgroundColor: []
        }
      ];

      this.chartData.title = "eventsByMachine";
      this.chartData.labels = [];
      this.chartData.titles = [];

      let index = 0;
      for (const s of slicedData || []) {
        this.chartData.datasets[0].data.push(s.doc_count);
        this.chartData.datasets[0].label = this.$t("Downtime Events (count)");
        let name = this.codeLookup(s.key).description;
        this.chartData.labels.push(name);
        this.chartData.labels.titles = name;
        let color = tiffanyBlue;
        this.chartData.datasets[0].backgroundColor.push([color, color]);
        index++;
        this.chartIds.push(s.id);
      }
      let colors = this.chartData.datasets[0].data.map((item) => "rgba(67, 167, 180, .9)");
      this.chartData.datasets[0].backgroundColor = colors;
      this.slicedData = slicedData;
      this.codeIds = slicedData.map((code) => code.key);
      this.updateBackgroundColors();
    },
    codeLookup(code) {
      const match = this.codes.find((c) => c.code === code);
      if (match) {
        return match;
      } else {
        return {
          id: false,
          pk: -1,
          description: "Unknown Code " + String(code)
        };
      }
    },
    sortFunc(rawData, sortOrder) {
      rawData.sort((a, b) => {
        const aVal = a.doc_count;
        const bVal = b.doc_count;
        if (sortOrder == "desc") {
          return bVal - aVal;
        } else {
          return aVal - bVal;
        }
      });
      return rawData;
    }
  },
  watch: {
    "filterController.reload": function () {
      this.loadData();
    },
    "interactiveFilters.trends": function () {
      if (!this.loading) {
        this.loadData();
      }
    },
    "interactiveFilters.codes.length": function () {
      if (!this.loading) {
        this.updateBackgroundColors();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.downtime-events-by-code {
  height: 100%;
  .card {
    height: 100%;
  }
}
</style>
