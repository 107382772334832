<template>
  <aside class="history-container">
    <p class="title font-weight-bold">{{ $t("Run History") }}</p>
    <div
      v-if="!items.length"
      class="subtitle-1"
    >
      {{ $t("No history yet") }}
    </div>
    <div
      v-else
      class="history"
    >
      <div
        v-for="item in items"
        :key="item.id"
      >
        <p class="history__item-text">{{ new Date(item.createdAt).toLocaleString() }}</p>
        <p class="history__item-text">{{ $t("Initiated by ") + item.user }}</p>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name: "ModelOptimizationHistory",
  props: ["items"]
};
</script>
<style lang="scss" scoped>
.history-container {
  height: clamp(13rem, 100%, 100%);
  min-width: 12rem;

  display: grid;
  align-content: start;
  gap: 0.5rem;
}

.history {
  margin: 10px 10px 10px 0;

  display: grid;
  gap: 1rem;

  overflow-y: auto;

  &__item-text {
    font-size: 1.1rem;
    line-height: 1.1;
  }
}
</style>
