<template>
  <div class="helpnew">
    <div class="">
      <div class="card-header">
        <div class="options">
          <button
            class="btn radioBtn"
            :class="{ active: view == 'new' }"
            @click="view = 'new'"
          >
            {{ $t("New") }}
          </button>
          <button
            class="btn radioBtn"
            :class="{ active: view == 'tickets' }"
            @click="view = 'tickets'"
          >
            {{ $t("Tickets") }}
          </button>
          <i
            class="fa fa-refresh"
            :class="{ loading: loading }"
            aria-hidden="true"
            @click="loadTickets"
          ></i>
        </div>
      </div>

      <div
        id="p911-ticket"
        class="card-content"
      >
        <div
          v-if="view == 'new'"
          class="newTicket"
        >
          <div>
            <v-divider></v-divider>
            <h3>{{ $t("Create A New Ticket") }}</h3>
            <div v-if="options && 'machine' in options">
              <h4>{{ $t("Select Machine:") }}</h4>

              <v-checkbox
                v-model="includeFKs.machine"
                :label="options['machine']['machineName']"
                :value="true"
                hide-details
              >
              </v-checkbox>
            </div>
            <div v-else>
              <h4>{{ $t("Select Machine:") }}</h4>
              <v-autocomplete
                v-model="selectedMachineAutocomplete"
                :placeholder="$t('Machine')"
                :items="machines"
                :filter="filterMachines"
                clearable
                single-line
                persistent
                item-text="name"
                item-value="name"
                return-object
                :menu-props="{
                  closeOnClick: true
                }"
                @change="clear()"
              >
              </v-autocomplete>
            </div>
            <h4>{{ $t("Select Issue:") }}</h4>
            <transition-group name="sub-groups">
              <div
                v-for="(selectedTag, selectedIndex) in selectedTags"
                :key="selectedTag.id"
                class="selectedTag"
                @click="removeTag(selectedTag, selectedIndex)"
              >
                <p>
                  <span
                    :style="{ backgroundColor: selectedTag.color ? selectedTag.color : '#00BC6C' }"
                  />
                  {{ selectedTag.name }}
                  <i class="fa fa-times"></i>
                </p>
              </div>
              <div
                key="spacer"
                class="spacer"
              ></div>
              <div
                v-for="currentTag in currentTags"
                :key="currentTag.id"
                class="currentTag"
                @click="selectTag(currentTag)"
              >
                <p>
                  <span
                    :style="{ backgroundColor: currentTag.color ? currentTag.color : '#00BC6C' }"
                  />{{ currentTag.name }}
                </p>
              </div>
            </transition-group>
          </div>
          <div
            v-if="selectedTags.length > 0 && currentTags.length == 0"
            class="bottom"
          >
            <v-textarea
              v-model="details"
              filled
              auto-grow
              :label="$t('Type Details...')"
              rows="3"
              row-height="30"
              shaped
            ></v-textarea>
            <div class="btns">
              <button
                class="btn btn-warning"
                @click="clear()"
              >
                {{ $t("Clear") }}
              </button>
              <button
                class="btn btn-primary"
                @click="submit()"
              >
                {{ $t("Submit") }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="view == 'tickets'"
          class="tickets"
        >
          <v-divider class="mb-4"></v-divider>
          <!-- <h3>{{$t('Tickets')}}</h3> -->
          <router-link
            v-for="(ticket, index) in tickets"
            :key="index"
            :to="{ name: 'help_ticket', params: { ticket_id: ticket.node.id } }"
          >
            <div
              class="ticket-card"
              @click="close()"
            >
              <div class="d-flex">
                <label class="requestedBy"
                  ><span class="color--text-secondary"> {{ $t("Requested by:") }}</span> <br />{{
                    ticket.node.ticketedByName
                  }}</label
                >

                <p class="openDate color--text-secondary ml-auto">
                  {{ moment(ticket.node.ticketedDate).format("D MMM YYYY h:mmA") }}
                </p>
              </div>

              <p class="label">{{ ticket.node.title }}</p>
              <label>{{ ticket.node.tagSearch }}{{ $t(":") }}</label>
              <div>
                <label class="details color--text-secondary">{{ ticket.node.details }}</label>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      validateTicket: false,
      view: "new",
      selectedTags: [],
      helpTags: [],
      currentTagsDepth: 1,
      details: "",
      loading: false,
      title: "",
      tickets: [],
      includeFKs: {
        machine: true
      },
      selectedMachineAutocomplete: null
    };
  },

  computed: {
    ...mapGetters({
      showHelp: "components/HelpCenterVisible",
      user: "session/User",
      machines: "dbCache/machines"
    }),
    selectedMachine() {
      const pk = this.$route.params.machine_pk;
      const foundMachine = this.machines.findIndex(
        (machine) => machine.pk.toString() === (pk && pk.toString())
      );

      if (foundMachine > -1) {
        return this.machines[foundMachine];
      }

      return null;
    },
    options() {
      const machine_id = this.$route.params.machine_id;
      const machine_pk = this.$route.params.machine_pk;

      if (this.selectedMachine) {
        return {
          machine: {
            machine_id: machine_id,
            machine_pk: machine_pk,
            machineName: this.selectedMachine.name
          }
        };
      } else {
        return {};
      }
    },
    currentTags() {
      let tags = [];
      if (this.selectedTags.length == 0) {
        this.helpTags.forEach((tag) => {
          if (tag.depth == 1) {
            tags.push(tag);
          }
        });
      } else {
        let lastTag = this.selectedTags[this.selectedTags.length - 1];
        this.helpTags.forEach((tag) => {
          if (
            tag.depth == this.currentTagsDepth &&
            tag.treeId == lastTag.treeId &&
            tag.lft > lastTag.lft &&
            tag.rgt < lastTag.rgt
          ) {
            tags.push(tag);
          }
        });
      }
      return tags;
    }
  },

  watch: {
    options: function () {
      this.loadTickets();
    }
  },

  created() {
    this.loadTickets();
    this.loadHelpTags();
  },

  methods: {
    moment,
    ...mapActions({
      setHelpCenterVisibility: "components/SetHelpCenterVisibility"
    }),
    close() {
      this.setHelpCenterVisibility({ visible: false });
    },
    filterMachines(item, queryText) {
      const searchText = queryText.trim().toLowerCase();
      const name = item.name.toLowerCase();
      return name.indexOf(searchText) > -1;
    },
    loadTickets() {
      this.loading = true;
      const query = `query($filters: GrapheneElasticFilterHelpTicketSearchConnectionBackendFilter!){
        helpTickets(filter: $filters){
          edges{
            node{
              id
              ticketedBy,
              ticketedByName,
              ticketedDate,
              details,
              tagSearch,
              title
            }
          }
        }
      }`;
      const variables = {
        filters: {
          closedDate: { isNull: true }
        }
      };
      if (this.options && "machine" in this.options) {
        variables["filters"]["machineId"] = { term: this.options["machine"]["machine_pk"] };
      }
      this.$http
        .get("graphql/", { params: { query: query, variables: variables } })
        .then((res) => {
          this.tickets = res.data.data.helpTickets.edges;
          this.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
          this.loading = false;
        });
    },
    loadHelpTags() {
      this.$http
        .get("graphql/", {
          params: {
            query: `{helpTags{
          name,
          color,
          id,
          pk
          lft,
          rgt,
          treeId,
          depth
        }
      }`
          }
        })
        .then((res) => {
          this.helpTags = res.data.data.helpTags;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    selectTag(selectedTag) {
      this.currentTagsDepth += 1;
      this.selectedTags.push(selectedTag);
    },
    removeTag(removeTag, removeIndex) {
      this.currentTagsDepth = removeTag.depth;
      this.selectedTags.splice(removeIndex, this.selectedTags.length);
    },
    clear() {
      this.selectedTags = [];
      this.currentTagsDepth = 1;
      this.details = "";
      this.title = "";
    },
    submit() {
      this.validateTicket = true;
      if (this.details !== "") {
        const query = `mutation ($ticketData: HelpTicketInput!){
        createHelpTicket(ticketData:$ticketData) {
          ticketedBy,
          ticketedByName,
          ticketedDate,
          details,
          tagSearch,
          title,
          id
        }
      }`;
        const variables = {
          ticketData: {
            tagIds: [],
            tagNames: [],
            title: this.title,
            details: this.details
          }
        };
        if (this.includeFKs.machine && this.options && "machine" in this.options) {
          variables["ticketData"]["machineId"] = this.options["machine"]["machine_id"];
          variables["ticketData"]["machineName"] = this.options["machine"]["machineName"];
        } else if (this.selectedMachine) {
          variables.ticketData.machineId = this.selectedMachine.id;
          variables.ticketData.machineName = this.selectedMachine.name;
        } else if (this.selectedMachineAutocomplete) {
          variables.ticketData.machineId = this.selectedMachineAutocomplete.id;
          variables.ticketData.machineName = this.selectedMachineAutocomplete.name;
        }

        this.selectedTags.forEach((tag) => {
          variables["ticketData"]["tagIds"].push(tag.id);
          variables["ticketData"]["tagNames"].push(tag.name);
        });
        // console.log('variables' + variables)
        this.$http
          .post("graphql/", { query, variables })
          .then(() => {
            this.$message("ALERT", { text: this.$t("Ticket Saved"), type: "success" });
            this.loadTickets();
            this.clear();
            let loadTickets = this.loadTickets;
            setTimeout(loadTickets, 1000);
          })
          .catch((res) => {
            this.errors = res.errors;
            this.$message("ALERT", { text: "Failed to Save Ticket", type: "error" });
          });
      } else {
        this.$message("ALERT", { text: "Failed to Save: Must Include Details", type: "error" });
        this.validateTicket = false;
      }
      this.view = "tickets";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables";

.helpnew {
  margin: 1rem 0;
  margin-top: 0.5rem;
  display: block;
  .fa-refresh {
    cursor: pointer;
    margin-right: 0.5rem;
    &.loading {
      animation: rotation 1s infinite linear;
    }
  }
}

.card {
  z-index: 1000;
  height: 100%;
  overflow: hidden;
}

.card-content {
  position: relative;
  .newTicket {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    h3 {
      color: $blue;
      padding-top: 0.6rem;
    }
    h4 {
      padding-top: 1.25rem;
    }
    .selectedTag {
      display: flex;
      align-items: center;
      position: relative;
      height: 30px;
      margin: 10px 8px;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
      border-radius: 4rem;
      box-shadow: $navbuttonShadow;
      border: 1px solid rgba(255, 255, 255, 0);
      span {
        z-index: -1;
        height: 35px;
        position: absolute;
        opacity: 1;
        top: -3px;
        left: -1rem;
        right: -1rem;
        border-radius: 40px;
        transition: all 0.15s ease-in-out;
        box-shadow:
          inset 1px -1px 2px rgba(0, 0, 0, 0.2),
          inset -1px 1px 2px rgba(255, 255, 255, 0.2),
          -2px 2px 4px rgba(0, 0, 0, 0.4),
          1px -1px 2px rgba(255, 255, 255, 0.1);
      }
      .fa-times {
        position: absolute;
        text-align: center;
        top: 0.4rem;
        right: 0.4rem;
        height: 1rem;
        width: 1rem;
        background-color: white;
        padding-top: 0.1rem;
        border-radius: 1rem;
        color: #393742;
        font-size: 0.8rem;
      }
      &:hover {
        span {
          background-color: $red !important;
        }
        .fa-times {
          color: $red;
        }
      }
      p {
        z-index: 10;
      }
    }
    .spacer {
      height: 1rem;
    }
    .currentTag {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      height: 30px;
      margin: 10px 8px;
      transition: all 0.1s ease-in-out;
      border-radius: 4rem;
      box-shadow: $navbuttonShadow;
      border: 1px solid rgba(255, 255, 255, 0);
      span {
        z-index: -1;
        height: 35px;
        position: absolute;
        opacity: 0;
        top: -3px;
        left: 130px;
        right: 150px;
        border-radius: 0px;
        transition: all 0.15s ease-in-out;
        box-shadow:
          inset 1px -1px 2px rgba(0, 0, 0, 0.2),
          inset -1px 1px 2px rgba(255, 255, 255, 0.2),
          -2px 2px 4px rgba(0, 0, 0, 0.4),
          1px -1px 2px rgba(255, 255, 255, 0.1);
      }
      &:hover {
        span {
          opacity: 1;
          left: -1rem;
          right: -1rem;
          border-radius: 40px;
        }
        label,
        i {
          color: $white;
        }
      }
      p {
        z-index: 10;
      }
    }
    .bottom {
      padding: 0 1rem 1rem;
      width: 100%;
      bottom: 1rem;
      .btns {
        display: flex;
        justify-content: center;
        .btn {
          margin: 0 0.5rem;
          width: 6rem;
        }
      }
      textarea {
        height: auto !important;
        overflow-y: scroll;
      }
    }
  }
  .tickets {
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    padding: 0 0 0 0.5rem;

    a {
      text-decoration: none;
      color: white;
    }
  }
}
.theme--light.v-application {
  .help .card .card-content .tickets a {
    color: $light-color-val;
  }
}

.ticket-card {
  margin-bottom: 0.5rem;
  min-height: 3rem;
  padding: 0.85rem;
  padding-bottom: 1rem;

  background: var(--bg-card-white);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: color-mix(in srgb, var(--bg-card-white), #fff 15%);
  }

  .name {
    width: 100%;
    text-align: left;
    font-size: 0.95rem;
    padding-bottom: 0.5rem;
  }

  .requestedBy {
    font-size: 0.85rem;
  }
  .openDate {
    font-size: 0.85rem;
  }
  .label {
    //currently is what creates the space between requested by and blue text
    font-size: 0.85rem;
    padding-bottom: 1rem;
  }
  .details {
    font-size: 0.95rem;
    white-space: nowrap;
  }
  .details:hover {
    white-space: wrap;
    height: auto;
  }
  .primary--text {
    // padding-left: .5rem;
    font-weight: 600;
    font-size: 0.95rem;
  }
}

#p911-ticket::after {
  box-shadow: none !important;
}
</style>
