<template>
  <div class="showcase-container">
    <iframe
      v-once
      ref="showcase"
      id="showcase"
      :src="showcaseSrc"
      frameborder="”0”"
      allow="fullscreen"
    />
    <tag-form
      :tag="tagToEdit"
      :on-submit="handleTagSave"
      :on-cancel="handleCancel"
      :errors="errors"
    />
    <add-tag-button
      v-if="!addTagInProgress && tagToEdit === null"
      :disabled="tagToEdit !== null"
      :on-click="handleAddTagClick"
    />
    <MachineDetails
      class="ml-4 mr-4"
      :tag-id="selectedTag"
      v-if="selectedTag"
      :hide="() => (selectedTag = null)"
      :on-delete="() => onDelete(selectedTag)"
    />
  </div>
</template>

<script>
import MachineDetails from "./components/MachineDetails.vue";
import TagForm from "./components/TagForm.vue";
import AddTagButton from "./components/AddTagButton.vue";
import { deleteTag, saveTag } from "./api";
import { SDK_KEY } from "./constants";

export default {
  components: { AddTagButton, TagForm, MachineDetails },
  data() {
    return {
      modelId: "o2RPhHUQW6e",
      floorId: null,
      stemLength: 0.3,
      sdk: null,
      inputComponent: null,
      addTagInProgress: false,
      currentTagID: null,
      tagToEdit: null,
      selectedTag: null,
      interesectionObserver: null,
      errors: []
    };
  },
  async mounted() {
    try {
      const showcase = this.$refs.showcase;
      showcase.addEventListener("load", async () => {
        this.sdk = await showcase.contentWindow.MP_SDK.connect(showcase.contentWindow);
        this.onTagAdded();
        await this.sdk.Link.setExternalLinkPolicy(false);
        await this.sdk.Link.setSameOriginLinkPolicy("link.openpolicy.current");
        await this.subscribeOnTag();
        if (this.$route.params.tagId) {
          await this.sdk.Tag.allowAction(this.$route.params.tagId, {
            navigating: true,
            opening: true,
            docking: false
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    showcaseSrc() {
      const querySet = {
        applicationKey: SDK_KEY,
        m: this.modelId,
        play: 1,
        qs: 1,
        useLegacyIds: 0
      };
      if (this.$route.params.tagId) {
        querySet["tag"] = this.$route.params.tagId;
      }
      const queryString = Object.entries(querySet)
        .map((item) => `${item[0]}=${item[1]}`)
        .join("&");
      return `/showcase/showcase.html?${queryString}`;
    }
  },
  methods: {
    onTagAdded() {
      this.sdk.Tag.data.subscribe({
        onUpdated: (index, item, collection) => {
          if (item.id === this.tagToEdit.id) {
            this.tagToEdit = { ...item };
          }
        }
      });
    },
    async addSceneObject() {
      // Create the required input component/node/sceneObject to listen for mouse click events
      const [inputSceneObject] = await this.sdk.Scene.createObjects(1);
      const inputNode = inputSceneObject.addNode();
      this.inputComponent = inputNode.addComponent("mp.input", {
        eventsEnabled: true,
        userNavigationEnabled: true
      });
      inputSceneObject.start();

      // Define an emit path to listen for mouse click emits
      const clickEmitPath = inputSceneObject.addEmitPath(this.inputComponent, "INTERACTION.CLICK");

      // Define a click event spy
      inputSceneObject.spyOnEvent({
        path: clickEmitPath,
        onEvent: (payload) => {
          //only trigger on left clicks when addTagInProgress is true
          if (this.addTagInProgress || payload.button === 0) {
            // set the addTagInProgress flag to false to allow the next tag to be added
            this.currentTagID = null;
            this.addTagInProgress = false;
            //slightly delay enabling user navigation to allow the click event to be processed without moving the camera
            setTimeout(() => {
              this.inputComponent.inputs.userNavigationEnabled = true;
            }, 100);
          }
        }
      });

      // Subscribe to the intersection observable to set the position and stemVector of the Tag dynamically while the tag is being added
      this.interesectionObserver = this.sdk.Pointer.intersection.subscribe((intersectionData) => {
        if (this.currentTagID) {
          this.sdk.Tag.editPosition(this.currentTagID, {
            anchorPosition: intersectionData.position,
            stemVector: {
              x: intersectionData.normal.x * this.stemLength,
              y: intersectionData.normal.y * this.stemLength,
              z: intersectionData.normal.z * this.stemLength
            }
          });
        }
      });

      this.sdk.Floor.current.subscribe((currentFloor) => {
        this.floorId = currentFloor.id;
      });
    },
    async addTag(
      tag = {
        anchorPosition: {
          x: 0,
          y: 0,
          z: 0
        },
        stemVector: {
          // make the Tag stick straight up and make it 0.30 meters (~1 foot) tall
          x: 0,
          y: 0.01,
          z: 0
        },
        color: {
          r: 0.0117,
          g: 0.4078,
          b: 0.49019
        }
      }
    ) {
      const tagId = (await this.sdk.Tag.add(tag))[0];
      await this.sdk.Tag.editStem(tagId, { stemHeight: this.stemLength });
      return tagId;
    },
    async handleAddTagClick() {
      if (this.addTagInProgress) {
        return;
      }
      await this.addSceneObject();
      this.addTagInProgress = true;
      this.currentTagID = await this.addTag();
      this.tagToEdit = { id: this.currentTagID };
      // disable user mouse navigation while a tag is being added
      this.inputComponent.inputs.userNavigationEnabled = false;
    },
    async removeTag(tagId) {
      await this.sdk.Tag.remove(tagId);
    },
    async handleTagSave({ label, description, machineId }) {
      this.errors = [];

      await this.sdk.Tag.editBillboard(this.tagToEdit.id, {
        label,
        description
      });

      const tag = await saveTag(this.$http, {
        ...this.tagToEdit,
        modelId: this.modelId,
        floorId: this.floorId,
        label,
        description,
        machine_id: machineId
      });

      if (tag.error) {
        this.errors = tag.error;
        return;
      }

      await this.removeTag(this.tagToEdit.id);
      this.tagToEdit = null;
      await this.addTag(tag);
      this.interesectionObserver.cancel();
    },
    async handleCancel() {
      this.interesectionObserver.cancel();
      await this.removeTag(this.tagToEdit.id);
      this.tagToEdit = null;
    },
    async subscribeOnTag() {
      const showMachineDetails = this.showMachineDetails;
      await this.sdk.Tag.openTags.subscribe({
        prevState: {
          hovered: null,
          docked: null,
          selected: null
        },
        onChanged(newState) {
          // only compare the first 'selected' since only one tag is currently supported
          const [selected = null] = newState.selected; // destructure and coerce the first Set element to null
          if (selected !== this.prevState.selected) {
            if (selected) {
              showMachineDetails(selected);
            } else {
              showMachineDetails(selected);
            }
          }

          // clone and store the new state
          this.prevState = {
            ...newState,
            docked: false,
            selected
          };
        }
      });
    },
    showMachineDetails(selected) {
      this.selectedTag = selected ? selected : null;
    },
    async onDelete(tagId) {
      await deleteTag(this.$http, tagId, this.modelId);
      await this.removeTag(tagId);
    }
  }
};
</script>

<style lang="scss">
.showcase-container {
  width: 100%;
  height: 100%;

  display: flex;
}

#showcase {
  flex-basis: 100%;
}

.tag-form {
  padding: 1rem;
  width: 30rem;

  display: grid;
  grid-auto-rows: min-content;
}

.tag-form-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.add-tag-btn {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>
