class TableData {
  constructor(headers) {
    this.data = [];
    this.headers = headers;
    this.loadingData = false;
    this.total = 0;
    this.page = {
      itemsPerPage: 10,
      current: 1,
      load: false,
      next: null,
      prev: null
    };
    this.searchDebouncer = null;
    this.searchText = "";
    this.sortBy = [];
    this.sortDesc = [];
  }

  setData(data) {
    this.data = data;
    this.total = data.length;
  }
}

export { TableData };
