<template>
  <div class="completedAudit">
    <div
      class="formCard card"
      v-if="auditVersion && auditType"
    >
      <div class="header card">
        <div class="right-group">
          <h1>
            <span>{{ $t("Name") }}</span> : {{ auditType.name }}
          </h1>
          <h1>
            <span>{{ $t("Revision") }}</span> : {{ auditType.currentVersion }}
          </h1>
          <h1>
            <span>{{ $t("Score") }}</span> : {{ responseForm.score }}%
          </h1>
          <h1 v-if="responseForm">
            <span>{{ $t("ID") }}</span> : {{ auditType.id }}
          </h1>
          <h1 v-if="responseForm">
            <span>{{ $t("Completed User") }}</span> : {{ responseForm.submitUser.fullName }}
          </h1>
          <h1 v-if="responseForm">
            <span>{{ $t("Time Completed") }}</span> : {{ time(responseForm.submitTime) }}
          </h1>
        </div>
      </div>
      <v-tabs
        v-model="tab"
        fixed-tabs
        show-arrows
        center-active
        @change="categoryChange"
      >
        <v-tab key="Summary">
          <div
            class="audit-type"
            v-if="auditType"
          >
            <v-icon color="grey">mdi-text-account</v-icon>
          </div>
        </v-tab>
        <v-tab-item key="Summary">
          <div
            class="audit-type"
            v-if="auditType && auditVersion"
          >
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="(category, c_index) in auditVersion.categories"
                :key="category.order"
              >
                <v-expansion-panel-header>
                  <div class="expansion-cat">
                    <h3>{{ category.name }}</h3>
                    <p>
                      {{ $t("Score:") }} {{ category["score"] }} / {{ category.questions.length }}
                    </p>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    class="questionReview card"
                    v-for="(question, q_index) in category.questions"
                  >
                    <p>{{ $t("Question: ") }}{{ question.text }}</p>
                    <p class="response">{{ $t("Your response: ") }}{{ question.response }}</p>
                    <div v-if="question.isCorrect">
                      <p><v-icon color="green">mdi-check</v-icon> {{ $t("Correct!") }}</p>
                    </div>
                    <div v-if="!question.isCorrect">
                      <p><v-icon color="red">mdi-close</v-icon> {{ $t("Incorrect") }}</p>
                    </div>
                    <div v-if="question.findingId">
                      <v-btn
                        class="incorrectLink"
                        small
                        color="primary"
                        fab
                        @click.stop.prevent="launchTicket(question.findingId)"
                      >
                        <i
                          class="fa fa-external-link-square"
                          aria-hidden="true"
                        ></i>
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-tab-item>
        <v-tab
          v-for="(category, category_index) in auditVersion.categories"
          :key="category.order"
        >
          {{ category.name }}
        </v-tab>
        <v-tab-item
          v-for="(category, category_index) in auditVersion.categories"
          :key="category.order"
        >
          <div class="questionWraper">
            <div class="questionControl">
              <v-btn
                @click="changeQuestion('prev')"
                color="primary"
                small
                fab
              >
                <v-icon>fa-chevron-left</v-icon>
              </v-btn>
              <p>{{ questionIndex.current + 1 }}</p>
              <p>/</p>
              <p>{{ questionIndex.total }}</p>
              <v-btn
                @click="changeQuestion('next')"
                color="primary"
                small
                fab
              >
                <v-icon>fa-chevron-right</v-icon>
              </v-btn>
            </div>
            <div class="question row">
              <div class="col-xs-12 col-md-4">
                <div class="questionText section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Question") }}</h3>
                  </div>
                  <div
                    class="sectionContent"
                    v-if="currentQuestion"
                  >
                    <p>{{ currentQuestion.text }}</p>
                  </div>
                </div>
                <div class="instructions section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Instructions") }}</h3>
                  </div>
                  <div
                    class="sectionContent"
                    v-if="currentQuestion"
                  >
                    <p>{{ currentQuestion.instruction }}</p>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-4">
                <div class="response section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Response") }}</h3>
                  </div>
                  <div
                    v-if="currentQuestion"
                    class="sectionContent"
                  >
                    <div
                      class="multipleChoice"
                      v-if="
                        currentQuestion.type == 'multiple_choice' ||
                        currentQuestion.type == 'true_false'
                      "
                    >
                      <v-radio-group
                        v-model="currentQuestion.response"
                        readonly
                      >
                        <p v-if="currentQuestion.isCorrect">Correct!</p>
                        <p v-else>{{ $tIncorrect }}.</p>
                        <v-radio
                          v-for="(answer, answerIndex) in currentQuestion.answers"
                          :key="answerIndex"
                          :class="{
                            isCorrect:
                              currentQuestion.response != '' &&
                              currentQuestion.isCorrect &&
                              currentQuestion.response == answer.text,
                            isIncorrect:
                              currentQuestion.isCorrect == false &&
                              currentQuestion.response == answer.text
                          }"
                          v-bind:key="answer.id"
                          v-bind:label="$t(answer.text)"
                          v-bind:value="answer.text"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      class="multipleAnswer"
                      v-if="currentQuestion.type == 'multiple_answer'"
                    >
                      <div
                        model="currentQuestion.response"
                        readonly
                      >
                        <p v-if="currentQuestion.isCorrect">{{ $t("Correct!") }}</p>
                        <p v-else>{{ $t("Incorrect") }}</p>
                        <v-checkbox
                          v-for="(answer, answerIndex) in currentQuestion.answers"
                          :key="answerIndex"
                          :class="{
                            isCorrect:
                              currentQuestion.response != '' &&
                              currentQuestion.isCorrect &&
                              currentQuestion.response == answer.text,
                            isIncorrect:
                              currentQuestion.isCorrect == false &&
                              currentQuestion.response == answer.text
                          }"
                          readonly
                          :label="answer.text"
                          :value="answer.text"
                        >
                        </v-checkbox>
                      </div>
                    </div>
                    <div
                      class="text"
                      v-if="currentQuestion.type == 'text'"
                    >
                      <div
                        model="currentQuestion.response"
                        readonly
                      >
                        <p v-if="currentQuestion.isCorrect">{{ $t("Correct!") }}</p>
                        <p v-else>{{ $t("Incorrect") }}</p>
                        <v-text-field
                          v-for="(answer, answerIndex) in currentQuestion.answers"
                          :key="answerIndex"
                          :class="{
                            isCorrect:
                              currentQuestion.response != '' &&
                              currentQuestion.isCorrect &&
                              currentQuestion.response == answer.text,
                            isIncorrect:
                              currentQuestion.isCorrect == false &&
                              currentQuestion.response == answer.text
                          }"
                          v-model="currentQuestion.response"
                          v-bind:key="answer.id"
                          v-bind:label="$t(answer.text)"
                          v-bind:value="answer.text"
                          readonly
                        ></v-text-field>
                      </div>
                    </div>
                    <div
                      class="text"
                      v-if="currentQuestion.type == 'number' || currentQuestion.type == 'range'"
                    >
                      <div
                        model="currentQuestion.response"
                        readonly
                      >
                        <p v-if="currentQuestion.isCorrect">{{ $t("Correct!") }}</p>
                        <p v-else>{{ $t("Incorrect") }}</p>
                        <v-text-field
                          v-for="(answer, answerIndex) in currentQuestion.answers"
                          :key="answerIndex"
                          :class="{
                            isCorrect:
                              currentQuestion.response != '' &&
                              currentQuestion.isCorrect &&
                              currentQuestion.response == answer.text,
                            isIncorrect:
                              currentQuestion.isCorrect == false &&
                              currentQuestion.response == answer.text
                          }"
                          v-model="currentQuestion.response"
                          readonly
                          type="number"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-4">
                <div class="comment section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Additional comments or observations") }}</h3>
                  </div>
                  <div
                    class="sectionContent inputField"
                    v-if="currentQuestion"
                  >
                    <v-textarea
                      disabled
                      v-model="currentQuestion.comment"
                    ></v-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.completedAudit {
  height: calc(100% - 4rem);
  .formCard {
    margin: 1rem 0;
    padding: 1rem;
    height: calc(100% - 1rem);
    display: grid;
    grid-template-rows: minmax(30px, min-content) auto;
    .header {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      flex-wrap: wrap;
      min-height: unset;
      .right-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .idText {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .v-icon {
            color: white;
          }
        }
      }
      h1 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 1rem;
        span {
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
    .v-tabs {
      display: grid;
      grid-template-rows: min-content;
      .v-window__container {
        height: 100%;
        .v-window-item {
          height: 100%;
        }
      }
    }
    .questionWraper {
      height: calc(100% - 3.5rem);
    }
    .audit-type {
      .newType {
        margin: 1rem;
        a {
          text-decoration: none;
          color: white;
        }
      }
      h3 {
        margin-top: 1rem;
        font-weight: 600;
      }
      .newBtn {
        margin-top: 1rem;
      }
    }
    .expansion-cat {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      h3 {
        margin: 0;
      }
    }
    .questionReview {
      margin: 1rem;
      padding: 1rem;
      min-height: 100px;
      position: relative;
      .response {
        margin: 1rem;
      }
      .incorrectLink {
        float: right;
        margin-top: -5rem;
        margin-right: 1rem;
      }
    }
    .buttons {
      padding: 1.25rem 0;
      .v-btn {
        margin-right: 1rem;
        width: 150px;
      }
    }
    .formButton {
      padding: 0 1.25rem 1.25rem 0;
      float: right;
    }
    .theme--dark.v-tabs-items {
      background-color: transparent;
    }
    .questionControl {
      display: flex;
      justify-content: center;
      align-items: center;
      .v-btn {
        font-size: 1rem;
        margin: 1rem 2rem;
      }
      p {
        margin: 0 1rem;
      }
    }
    .col-md-4 {
      position: relative;
    }
    .question {
      height: 100%;
    }
    .section {
      position: relative;
      padding-top: 3rem;
      height: 50%;
      .sectionLabel {
        position: absolute;
        top: 0;
        margin: 0.5rem;
      }
      .sectionContent {
        height: 100%;
        padding: 0 0 0 1rem;
        border: 1px solid transparent;
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-radius: 5px;
        margin-top: 0;
        .theme--dark.v-input--is-disabled {
          color: white;
        }
        .theme--dark.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
          .v-icon {
          color: white !important;
        }
        .theme--dark.v-radio--is-disabled label {
          color: white;
        }
        .v-radio {
          border-radius: 10px;
          padding: 10px;
        }
        p {
          padding: 10px 0;
        }
        .isCorrect {
          --tw-bg-opacity: 1;
          background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
        }
        .isIncorrect {
          --tw-bg-opacity: 1;
          background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
        }
        &.inputField {
          min-height: 200px;
        }
      }
      &.response {
        height: 100%;
        .sectionContent {
          padding: 0rem 1rem;
        }
      }
    }
  }
}
@include light_theme() {
  .completedAudit {
    .theme--light.v-tabs-items {
      background-color: transparent;
    }

    .formCard {
      .section {
        .sectionContent {
          border-color: black;
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import { Category } from "@/datatypes/audits";
import { time } from "@/utils/filters";

//responseFormId comes from completedAudits
export default {
  props: ["auditTypeId", "responseFormId"],
  data() {
    return {
      tab: "home",
      auditType: null,
      auditVersion: null,
      responseForm: null,
      responses: [],
      savingAudit: false,
      questionIndex: {
        prev: -1,
        next: null,
        current: 0
      },
      currentCategory: null,
      currentQuestion: null,
      errors: false
      // responseTable: new TableData([
      //   {text: this.$t('ID'), value: 'id'},
      // ])
    };
  },

  created() {
    this.loadResponse();
  },
  components: {},
  computed: {
    ...mapGetters({
      user: "session/User"
    })
  },
  methods: {
    moment,
    time,
    ...mapActions({}),
    loadResponse() {
      this.errors = false;
      const query = `query ($responseForm: ID!){
        responseForm(id: $responseForm) {
            ... on ResponseFormNode {
                id,
                totalQuestions,
                totalResponse,
                badResponses
                score
                submitTime,
                submitUser {
                  fullName
                }
                startTime,
                startUser {
                  fullName
                }
                form{
                  id
                  version
                  type{
                    id
                    name
                    description
                  }
                  categories{
                    name,
                    order
                    questions{
                      id
                      text,
                      instruction,
                      type,
                      order,
                      estimatedTime,
                      answers{
                        id,
                        text,
                        isCorrect
                      }
                    }
                  }
                }
                responses {
                  edges{
                    node{
                      id,
                      text,
                      comment
                      isCorrect
                      findingId
                      question{
                        id
                      }
                    }
                  }
                }
              }
            }
      }`;
      let variables = { responseForm: this.responseFormId };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.responseForm = res.data.data.responseForm;
          this.responses = this.responseForm.responses;
          this.auditVersion = this.responseForm.form;
          this.auditType = this.auditVersion.type;
          let categories = [];
          this.auditVersion.categories.forEach((category) => {
            category["score"] = 0;
            category.questions.forEach((question) => {
              let resp = this.responses.edges.find((r) => r.node.question.id == question.id);
              if (resp) {
                question.responseId = resp.node.id;
                question.response = resp.node.text;
                question.comment = resp.node.comment;
                question.isCorrect = resp.node.isCorrect;
                question.findingId = resp.node.findingId;
                console.log(question.findingId);
                if (question.isCorrect == true) {
                  category["score"]++;
                }
              }
            });
            categories.push(new Category(category));
          });
          this.auditVersion.categories = categories;
          this.tab = 0;
          this.categoryChange(this.tab);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", {
            text: this.$t("Failed to load audit responses "),
            type: "error"
          });
        });
    },
    launchTicket(findingId) {
      let routeData = this.$router.resolve({ name: "ticket", params: { ticket_id: findingId } });
      window.open(routeData.href, "_blank");
    },
    categoryChange(tab) {
      if (tab > 0) {
        if (tab == this.auditVersion.categories.length + 1) {
          this.currentCategory = null;
          this.currentQuestion = null;
        } else {
          if (!this.responseForm) {
            this.tab = 0;
            return;
          }
          this.currentCategory = this.auditVersion.categories[tab - 1];
          this.questionIndex.total = this.currentCategory.questions.length;
          this.questionIndex.current = 0;
          this.questionIndex.prev = -1;
          if (this.questionIndex.total > 1) {
            this.questionIndex.next = 1;
          } else {
            this.questionIndex.next = null;
          }
        }
        this.setCurrentQuestion();
      } else {
        this.currentCategory = null;
        this.currentQuestion = null;
      }
    },
    changeQuestion(dir) {
      if (dir == "prev") {
        if (this.questionIndex.prev >= 0) {
          this.questionIndex.next = this.questionIndex.current;
          this.questionIndex.current = this.questionIndex.prev;
          if (this.questionIndex.current > 0) {
            this.questionIndex.prev = this.questionIndex.current - 1;
          } else {
            this.questionIndex.prev = -1;
          }
          this.setCurrentQuestion();
        } else if (this.tab > 0) {
          this.tab = this.tab - 1;
          this.categoryChange(this.tab);
        }
      } else if (dir == "next") {
        if (this.questionIndex.next) {
          this.questionIndex.prev = this.questionIndex.current;
          this.questionIndex.current = this.questionIndex.next;
          if (this.questionIndex.current < this.questionIndex.total - 1) {
            this.questionIndex.next = this.questionIndex.current + 1;
          } else {
            this.questionIndex.next = null;
          }
          this.setCurrentQuestion();
        } else if (this.tab < this.auditVersion.categories.length - 1) {
          this.tab = this.tab + 1;
          this.categoryChange(this.tab);
        }
      }
    },
    backToQuestion(questionIndex, categoryIndex) {
      this.tab = categoryIndex + 1;
      if (this.questionIndex.back) {
        this.categoryChange(this.tab);
        this.questionIndex.current = questionIndex;
        this.setCurrentQuestion();
      }
    },
    setCurrentQuestion() {
      if (this.currentCategory) {
        this.currentQuestion = this.currentCategory.questions[this.questionIndex.current];
      }
    }
  }
};
</script>
