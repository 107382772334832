var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"completed-audits card"},[_c('v-data-table',{attrs:{"height":"calc(100% - 3.25rem)","item-key":"id","items":_vm.completedTable.data,"headers":_vm.completedTable.headers,"sortBy":_vm.completedTable.sortBy,"sortDesc":_vm.completedTable.sortDesc,"page":_vm.completedTable.page.current,"itemsPerPage":_vm.completedTable.page.itemsPerPage,"serverItemsLength":_vm.completedTable.total,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50] },"loading-text":_vm.$t('Loading Audits... Please wait'),"disable-pagination":"","fixed-header":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.completedTable, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.completedTable, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.completedTable, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.completedTable, "sortDesc", $event)},"update:page":function($event){return _vm.$set(_vm.completedTable.page, "current", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.completedTable.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.completedTable.page, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.submitTime",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.time(item.node.submitTime))+"\n    ")]}},{key:"item.submitUser.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.submitUser ? item.node.submitUser.fullName : "")+"\n    ")]}},{key:"item.form.type.name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.form.type ? item.node.form.type.name : "")+"\n    ")]}},{key:"item.machine.name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.machine ? item.node.machine.name : "")+"\n    ")]}},{key:"item.form.version",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.form.version)+"\n    ")]}},{key:"item.responseFindings",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.responseFindings)+"\n    ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"actionButton",attrs:{"small":"","color":"success","fab":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotToAudit(item)}}},[_c('v-icon',[_vm._v("mdi-format-list-checks")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }