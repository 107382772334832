<template>
  <div class="d-flex align-center text-left">
    <btn
      icon
      class="mx-6 color--primary"
      @click="value = HELP_CENTER_ITEMS.search"
    >
      <v-icon>mdi-magnify</v-icon>
    </btn>

    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <btn
          v-bind="attrs"
          class="color--primary"
          v-on="on"
        >
          <v-icon left>mdi-lifebuoy</v-icon>
          {{ $t("Help Center") }}
        </btn>
      </template>
      <v-list class="root">
        <v-list-item
          v-for="(item, index) in menu"
          :key="index"
          @click="value = item.key"
        >
          <v-icon left>{{ item.icon }}</v-icon>
          <v-list-item-title class="text-capitalize">{{ item.title }}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="digitalTwinIsEnabled && Boolean(tagId)"
          @click="handle3DClick"
        >
          <v-icon left>mdi-video-3d</v-icon>
          <v-list-item-title>{{ $t("3D View") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <sidebar
      v-if="value === HELP_CENTER_ITEMS.videos"
      v-model="value"
      :title="$t('Videos')"
    >
      <help-center-videos />
    </sidebar>

    <sidebar
      v-if="value === HELP_CENTER_ITEMS.eDocs"
      v-model="value"
      :title="$t('E-Docs')"
    >
      <help-center-docs />
    </sidebar>

    <sidebar
      v-if="value === HELP_CENTER_ITEMS.audits"
      v-model="value"
      :title="$t('Audits')"
    >
      <help-center-audits />
    </sidebar>

    <sidebar
      v-if="value === HELP_CENTER_ITEMS.p911"
      v-model="value"
      :title="$t('P911')"
    >
      <help-center-p911-tickets />
    </sidebar>

    <sidebar
      v-if="value === HELP_CENTER_ITEMS.search"
      v-model="value"
      :title="$t('Search')"
    >
      <help-center-search />
    </sidebar>
  </div>
</template>

<script>
import Sidebar from "../../ui/Sidebar/Sidebar.vue";
import { computed, getCurrentInstance, watch, ref, onMounted } from "@vue/composition-api";
import HelpCenterVideos from "./components/HelpCenterVideos.vue";
import Btn from "../../ui/Btn/Btn.vue";
import HelpCenterDocs from "./components/HelpCenterDocs.vue";
import HelpCenterAudits from "./components/HelpCenterAudits.vue";
import HelpCenterP911Tickets from "./components/HelpCenterP911Tickets.vue";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import axios from "axios";
import { getTagByMachineId } from "@/views/digital-twin/api";
import { hasUserRole, userRoles } from "@/utils/user";
import { mapGetters } from "vuex";
import HelpCenterSearch from "./components/HelpCenterSearch.vue";

export default {
  name: "HelpCenter",
  components: {
    HelpCenterSearch,
    HelpCenterAudits,
    HelpCenterDocs,
    Btn,
    HelpCenterVideos,
    Sidebar,
    HelpCenterP911Tickets
  },

  setup() {
    const root = getCurrentInstance().proxy;
    const $t = root.$t;
    const $route = useRoute();
    const $router = useRouter();
    const machine_pk = computed(() => $route.params.machine_pk);

    const tagId = ref();
    const value = ref();

    const loadTag = async () => {
      if (machine_pk.value) {
        tagId.value = await getTagByMachineId(axios, machine_pk.value);
      } else {
        tagId.value = null;
      }
    };

    const handle3DClick = () => {
      $router.push({ name: "digital-twin", params: { tagId: tagId.value } });
    };

    watch(machine_pk, loadTag);
    onMounted(loadTag);

    const HELP_CENTER_ITEMS = {
      p911: "p911",
      audits: "audits",
      eDocs: "eDocs",
      videos: "videos",
      views3d: "views3d",
      search: "search"
    };

    const menu = computed(() => [
      {
        title: $t("P911"),
        key: HELP_CENTER_ITEMS.p911,
        icon: "mdi-ticket-confirmation-outline"
      },
      {
        title: $t("Audits"),
        key: HELP_CENTER_ITEMS.audits,
        icon: "mdi-clipboard-edit-outline"
      },
      {
        title: $t("E-docs"),
        key: HELP_CENTER_ITEMS.eDocs,
        icon: "mdi-file-document-outline"
      },
      {
        title: $t("Videos"),
        key: HELP_CENTER_ITEMS.videos,
        icon: "mdi-video-outline"
      }
    ]);

    return {
      menu,
      HELP_CENTER_ITEMS,
      tagId,
      value,
      handle3DClick
    };
  },

  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    digitalTwinIsEnabled() {
      return hasUserRole(this.user, userRoles.DigitalTwin);
    }
  }
};
</script>

<style scoped>
.root {
  min-width: 165px;
}
</style>
