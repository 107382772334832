<template>
  <div class="scrapReasonCodeTable">
    <!-- scrap reason totals -->
    <generic-table
      :columns="[
        { title: $t('Scrap Reason'), key: 'description' },
        { title: $t('Part Number'), key: 'part_number' },
        { title: $t('Scrapped (pcs)'), key: 'scrapped', format: this.numberWithCommas }
      ]"
      :footer="[
        { type: 'text', text: $t('Total') },
        {},
        { type: 'text', text: this.numberWithCommas(sumValue(this.scrapReasonData, ['scrapped'])) }
      ]"
      :data="scrapReasonData"
    />
  </div>
</template>

<script>
import GenericTable from "@/components/GenericTable";
import { numberWithCommas } from "@/utils/filters";
import { getDateFormatter } from "@/utils/getDateFormatter";

export default {
  name: "ScrapReasonTable.vue",
  components: { GenericTable },
  data() {
    return {
      scrapReasonData: []
    };
  },
  methods: {
    getDateFormatter,
    numberWithCommas,
    sumValue(dataset, fields) {
      let sum = 0;
      for (const d of dataset) {
        let temp = d;
        for (const f of fields) {
          temp = temp[f];
        }
        sum += temp;
      }
      return sum;
    }
  },
  created() {
    const { scale, from_date, to_date, zone, machine, part } = this.filters;
    let { scaleString } = this.getDateFormatter(scale);

    let params = {
      interval: scaleString,
      from_date: from_date.toISOString(),
      to_date: to_date.toISOString()
    };

    let machine_pk;
    if (machine) {
      params.machine_id = machine.pk;
      machine_pk = machine.pk;
    } else if (zone) {
      params.machine_group_id = zone.pk;
      machine_pk = params.machine_group_id;
    }

    if (part) {
      params.part_number = part;
    }

    this.$http.get("scrap/scrap_by_interval/", { params: params }).then((res) => {
      this.scrapReasonData = res.data.sort((a, b) => {
        return a.part_number - b.part_number;
      });
    });
  },
  props: ["filters"]
};
</script>

<style lang="scss">
.scrapReasonCodeTable {
  display: flex;
  flex-direction: column;

  .v-data-table__wrapper {
    overflow-x: hidden;
  }

  thead {
    tr {
      white-space: nowrap;
    }
  }

  tbody {
    overflow: unset;
  }
}
</style>
