import { getCurrentInstance } from "@vue/composition-api";

export const useIntl = () => {
  const root = getCurrentInstance().proxy;
  const $t = root.$t;

  return {
    $t
  };
};
