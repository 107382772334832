var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realtime_charts"},[_c('v-btn',{staticClass:"hideDesktop",attrs:{"fab":"","x-small":"","light":""},on:{"click":function($event){_vm.showSearch = !_vm.showSearch}}},[(!_vm.showSearch)?_c('i',{staticClass:"fa fa-filter"}):_vm._e(),_vm._v(" "),(_vm.showSearch)?_c('i',{staticClass:"fa fa-times"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"row"},[(_vm.vw > 1200 || _vm.showSearch)?_c('div',{staticClass:"searchbars"},[_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Zone'),"items":Array.from(_vm.zoneSet),"value":_vm.filters.zone,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('zone', e);
          }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
            _vm.setFilter('zone', null);
          }}},[(_vm.filters.zone != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Machine'),"items":Array.from(_vm.machineSet),"value":_vm.filters.machine,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('machine', e);
          }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
            _vm.setFilter('machine', null);
          }}},[(_vm.filters.machine != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Part'),"items":Array.from(_vm.partSet),"value":_vm.filters.part,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('part', e);
          }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
            _vm.setFilter('part', null);
          }}},[(_vm.filters.part != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Operator'),"items":Array.from(_vm.operatorSet),"value":_vm.filters.name,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('name', e);
          }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
            _vm.setFilter('name', null);
          }}},[(_vm.filters.name != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Operator Status'),"items":Array.from(_vm.operatorStatusSet),"value":_vm.filters.checked_in == null ? null : _vm.filters.checked_in ? 'Checked-In' : 'Checked-Out',"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('checked_in', e == null ? null : e == 'Checked-In');
          }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
            _vm.setFilter('checked_in', null);
          }}},[(_vm.filters.checked_in != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"box"},[_c('v-tabs',{attrs:{"fixed-tabs":"","value":_vm.calendarCase},on:{"change":_vm.ChangeCalendar}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t("Shift")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Day")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Week")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Month")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Year")))])],1)],1)]),_vm._v(" "),_c('div',{staticClass:"gridPadding chartGrid"},[_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: '#00b1f1',
          keys: ['target_units'],
          label: this.$t('Target'),
          type: 'scatter',
          stack: 'combined'
        },
        {
          color: '#ffd965',
          keys: ['actual'],
          label: this.$t('Produced'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.Shorten,"label_name":"name","sortOn":"actual","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItems,"useNumberRecordsPicker":true,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":_vm.$t('Operators')}}),_vm._v(" "),_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: '#00b04f',
          keys: ['event_duration'],
          label: this.$t('Production Time'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.FormatTime,"label_name":"name","sortOn":"event_duration","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItems,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":_vm.$t('Operators - Production Time')}}),_vm._v(" "),_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: '#00b1f1',
          keys: ['production_target'],
          label: this.$t('Target'),
          type: 'scatter',
          stack: 'combined'
        },
        {
          color: '#2e5496',
          keys: ['produced'],
          label: this.$t('Produced'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.Shorten,"label_name":"part_number","sortOn":"produced","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItemsPartNumbers,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":'Part Numbers'}}),_vm._v(" "),_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: '#00b1f1',
          keys: ['production_target'],
          label: this.$t('Target'),
          type: 'scatter',
          stack: 'combined'
        },
        {
          color: '#ffd965',
          keys: ['produced'],
          label: this.$t('Produced'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.Shorten,"label_name":"machine_name","sortOn":"produced","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItemsMachines,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":"Machines"}}),_vm._v(" "),_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: '#00b1f1',
          keys: ['production_target'],
          label: this.$t('Target'),
          type: 'scatter',
          stack: 'combined'
        },
        {
          color: '#b01536',
          keys: ['produced'],
          label: this.$t('Produced'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.Shorten,"label_name":"zone","sortOn":"produced","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItemsZone,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":_vm.$t('By Zone')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }