<template>
  <section>
    <p>UploadTab</p>
  </section>
</template>
<script>
export default {
  name: "UploadTab",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped></style>
