<template>
  <v-card class="machine-details">
    <dialog-close-button :on-click="hide" />
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    />
    <div v-else>
      <h1 class="machine-details-header">{{ $t("Machine info") }}</h1>
      <div class="machine-details-body">
        <h2 class="machine-details-text">{{ $t("Status") }} - {{ status.name }}</h2>
        <h2 class="machine-details-text">{{ $t("Start Date") }} - {{ machineStartDate }}</h2>
        <h2 class="machine-details-text">{{ $t("Qty (pc)") }} - {{ machine.lot_size }}</h2>

        <v-btn
          color="error"
          @click="onDelete"
          >{{ $t("Delete") }}</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import DialogCloseButton from "@/components/DialogCloseButton";
import { mapGetters } from "vuex";
import moment from "moment";
import { getMachine, getMachineIdByTagId } from "../api";

export default {
  props: ["tagId", "hide", "onDelete"],
  data() {
    return {
      loading: true,
      machineSocket: null,
      machine: null
    };
  },
  components: { DialogCloseButton },
  async created() {
    await this.getMachine();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses"
    }),
    status() {
      let statusMatch = "";
      if (this.machine && this.machine.status_code) {
        this.statuses.forEach((status) => {
          status.statuscodeSet.forEach((code) => {
            if (String(code.code) === String(this.machine.status_code)) {
              statusMatch = status;
            }
          });
        });
      }
      return statusMatch;
    },
    machineStartDate() {
      if (!this.machine) return "";
      return moment(this.machine.start_date.value_as_string).format("D MMM YYYY h:mmA");
    }
  },
  methods: {
    async getMachine() {
      const machineId = await getMachineIdByTagId(this.$http, this.tagId);
      this.machine = await getMachine(this.$http, machineId);
    }
  }
};
</script>

<style scoped>
.machine-details {
  padding-inline: 12px;
  padding-block: 20px;

  display: grid;
  align-content: center;
  justify-items: center;

  min-width: 20rem;
  min-height: 10rem;
  height: min-content;
}
.machine-details-header {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}
.machine-details-body {
  display: grid;
  gap: 8px;
}
.machine-details-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}
</style>
