<template>
  <div>
    <div class="card-content">
      <help-center-search-input v-model="search" />

      <help-center-card
        v-for="audit in data.edges || []"
        :key="audit.id"
        :description="audit.node.description"
        :name="`${audit.node.name} - ${audit.node.latestVersion ? audit.node.latestVersion.version : ' '}`"
        @click="selectDoc(audit.node.id)"
      />
    </div>
  </div>
</template>

<script>
import HelpCenterCard from "./HelpCenterCard.vue";
import HelpCenterSearchInput from "./HelpCenterSearchInput.vue";
import { computed, ref } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { useHelpCenterAudits } from "../hooks/useHelpCenterAudits";

export default {
  components: { HelpCenterSearchInput, HelpCenterCard },

  setup() {
    const search = ref("");
    const selectedMedia = ref(undefined);
    const showDialog = ref(false);
    const $route = useRoute();
    const $router = useRouter();
    const machine_pk = computed(() => $route.params.machine_pk);
    const machine_id = computed(() => $route.params.machine_id);

    const params = computed(() => ({
      machine_pk: machine_pk.value,
      search: search.value
    }));

    const { data } = useHelpCenterAudits(params);

    const selectDoc = (id) => {
      $router.push({
        name: "audit-form",
        params: { auditTypeId: id, machine_id: machine_id.value }
      });
    };

    return {
      search,
      data,
      selectDoc,
      showDialog,
      selectedMedia
    };
  }
};
</script>
