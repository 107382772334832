var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"application-issue-tickets"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"text-search-container"},[_c('v-text-field',{staticClass:"text-search",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.applicationIssueSearchText),callback:function ($$v) {_vm.applicationIssueSearchText=$$v},expression:"applicationIssueSearchText"}})],1)]),_vm._v(" "),_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.applicationIssueTicketsTable.headers,"items":_vm.applicationIssueTicketsTable.data,"page":_vm.applicationIssueTicketsTable.page.current,"sortBy":_vm.applicationIssueTicketsTable.sortBy,"sortDesc":_vm.applicationIssueTicketsTable.sortDesc,"serverItemsLength":_vm.applicationIssueTicketsTable.total,"loading":_vm.applicationIssueTicketsTable.loadingData,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100] },"loading-text":_vm.$t('Loading Application Issue Tickets... Please wait'),"disable-pagination":"","fixed-header":""},on:{"update:page":function($event){return _vm.$set(_vm.applicationIssueTicketsTable.page, "current", $event)},"update:sortBy":function($event){return _vm.$set(_vm.applicationIssueTicketsTable, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.applicationIssueTicketsTable, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.applicationIssueTicketsTable, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.applicationIssueTicketsTable, "sortDesc", $event)},"click:row":_vm.goToTicket,"update:options":_vm.handleUpdatePageOptions},scopedSlots:_vm._u([{key:"item.issueStartDate",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatDate(item.issueStartDate))+"\n    ")]}},{key:"item.issueEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatDate(item.issueEndDate))+"\n    ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatDuration(item.duration))+"\n    ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"hidden-xs-only launch-button",attrs:{"small":"","color":"primary","fab":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.launchTicket(item)}}},[_c('i',{staticClass:"fa fa-external-link-square",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('v-btn',{staticClass:"hidden-sm-and-up launch-button",attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.launchTicket(item)}}},[_vm._v("\n        "+_vm._s(_vm.$t("Launch"))+"\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }