import moment from "moment";

const handleBackgroundColorOpacity = (slicedData, chartData, interactiveFilters) => {
  const { codes, machines, trends } = interactiveFilters;

  if (codes.length === 0 && machines.length === 0 && trends.length === 0) {
    return chartData.datasets[0].backgroundColor.map(
      (color) => color.substring(0, color.length - 2) + "9)"
    );
  }

  let foundActive = false;
  const newData = slicedData.map((dataItem, index) => {
    const colorString = chartData.datasets[0].backgroundColor[index].substring(
      0,
      chartData.datasets[0].backgroundColor[index].length - 2
    );
    const foundInCodes = codes.findIndex((filterItem) => dataItem.key === filterItem.id);
    const foundInMachines = machines.findIndex((filterItem) => dataItem.key === filterItem.id);
    const foundInTrends = trends.findIndex((filterItem) => dataItem.key === filterItem.id);

    if (foundInCodes >= 0 || foundInMachines >= 0 || foundInTrends >= 0) {
      foundActive = true;
      return colorString + "9)";
    }
    return colorString + "1)";
  });

  if (!foundActive) {
    return slicedData.map((dataItem, index) => {
      const colorString = chartData.datasets[0].backgroundColor[index].substring(
        0,
        chartData.datasets[0].backgroundColor[index].length - 2
      );
      return colorString + "9)";
    });
  } else {
    return newData;
  }
};

const getEndTime = (endTimeString, interval) => {
  switch (interval) {
    case "hour":
      return moment(endTimeString).endOf("hour").toDate();
    case "day":
      return moment(endTimeString).endOf("day").toDate();
    case "week":
      return moment(endTimeString).endOf("week").toDate();
    case "month":
      return moment(endTimeString).endOf("month").toDate();
    case "year":
      return moment(endTimeString).endOf("year").toDate();
  }
};

const getStartTime = (startTimeString, interval) => {
  switch (interval) {
    case "hour":
      return moment(startTimeString).startOf("hour").toDate();
    case "day":
      return moment(startTimeString).startOf("day").toDate();
    case "week":
      return moment(startTimeString).startOf("week").toDate();
    case "month":
      return moment(startTimeString).startOf("month").toDate();
    case "year":
      return moment(startTimeString).startOf("year").toDate();
  }
};

export { handleBackgroundColorOpacity, getStartTime, getEndTime };
