<template>
  <div class="counter-dialog">
    <v-dialog
      v-model="showDialog"
      persistent
      content-class="issue-entry-dialog counter-measure"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <div class="dialogBtn">
            <v-badge
              color="white"
              :content="countermeasures.length"
              offset-x="0"
              offset-y="35"
              :value="countermeasures.length"
            >
              <v-btn
                class="edit-btn"
                v-bind="attrs"
                v-on="on"
                color="#f7bd59"
                light
                fab
                x-small
              >
                <i
                  class="fa fa-plus"
                  aria-hidden="true"
                ></i>
              </v-btn>
            </v-badge>
            <v-card
              class="issues"
              v-if="hover && countermeasures.length"
            >
              <div
                class="issueText"
                v-for="cm in countermeasures"
                :key="'cm' + cm"
              >
                <p>{{ cm }}</p>
              </div>
            </v-card>
          </div>
        </v-hover>
      </template>
      <v-card>
        <DialogCloseButton :onClick="() => (showDialog = false)" />
        <v-card-title class="headline">
          {{ $t("Countermeasure For") }} {{ timeDisplay }}
        </v-card-title>
        <v-card-text>
          <div class="selections">
            <div
              class="selection"
              @click="selectedReason = false"
              v-if="selectedReason"
            >
              <p class="label">{{ $t("Reason") }}</p>
              <div class="reason">
                <ReasonButtonWithTooltip
                  :text="selectedReason.reason"
                  :isSelected="false"
                  :action="
                    () => {
                      selectedReason = false;
                    }
                  "
                />
              </div>
            </div>
          </div>
          <div class="flex">
            <div
              class="reasons"
              v-if="!selectedReason"
            >
              <div
                class="reason"
                v-for="issue in issues"
                :key="issue.id"
              >
                <ReasonButtonWithTooltip
                  :text="issue.reason"
                  :isSelected="selectedReason.id == issue.id"
                  :action="
                    () => {
                      selectReason(issue);
                    }
                  "
                />
              </div>
            </div>
            <div
              class="cms"
              v-if="selectedReason && cms"
            >
              <div
                class="countermeasure"
                v-for="cm in cms"
                :key="'cm' + cm.node.id"
              >
                <ReasonButtonWithTooltip
                  :text="cm.node.text"
                  :isSelected="selectedCM == cm"
                  :action="
                    () => {
                      selectedCM = cm;
                    }
                  "
                  :disabled="selectedReasonCMs.includes(cm.node.text)"
                />
              </div>
              <div
                class="countermeasure"
                key="other"
              >
                <ReasonButtonWithTooltip
                  :text="$t('Other')"
                  :isSelected="selectedCM == 'other'"
                  :action="
                    () => {
                      selectedCM = 'other';
                    }
                  "
                />
              </div>
            </div>
          </div>
          <div
            class="otherText"
            v-if="selectedCM == 'other'"
          >
            <v-text-field
              :label="$t('Countermeasure')"
              v-model="otherText"
            >
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showDialog = false"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            primary
            text
            :disabled="!selectedCM || (selectedCM == 'other' && otherText == '')"
            :loading="saving"
            @click="updateIssue"
          >
            {{ $t("Submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
@import "../../scss/variables";

.counter-dialog {
  .edit-btn {
    i {
      color: white;
    }
  }
  .issues {
    overflow: visible;
    width: 150px;
    min-width: 150px;
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);
    padding: 0.5rem;
    z-index: 9999;
    .issueText {
      max-height: 3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-y: auto;
    }
  }
  .dialogBtn {
    position: relative;
  }
}
.v-dialog {
  .flex {
    display: flex;
    justify-content: center;
  }
  .selections {
    width: 100%;
    &:empty {
      border-bottom: none;
    }
    .selection {
      display: flex;
      align-items: center;
      .reason {
        width: 75%;
        max-width: 75%;
        :hover {
          color: $red;
        }
      }
    }
  }
  .reason {
    width: 100%;
    .v-btn {
      border: 2px solid rgba(0, 0, 0, 0);
      transition: all 0.25s;
      margin: 0.25rem;
    }
    .selected {
      border: 2px solid white;
    }
  }
  .countermeasure {
    .v-btn {
      transition: all 0.25s;
      margin: 0.25rem;
      border: 2px solid rgba(0, 0, 0, 0);
    }
    .selected {
      border: 2px solid white;
    }
  }
}
</style>
<script>
import moment from "moment";

import DialogCloseButton from "@/components/DialogCloseButton";
import ReasonButtonWithTooltip from "@/components/ReasonButtonWithTooltip";

export default {
  components: { ReasonButtonWithTooltip, DialogCloseButton },
  props: ["issues", "time", "machine_id", "tab"],
  data() {
    return {
      showDialog: false,
      reasons: {},
      cms: [],
      selectedReason: false,
      selectedCM: false,
      saving: false,
      otherText: ""
    };
  },
  created() {},
  computed: {
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    selectedReasonCMs() {
      if (this.selectedReason.countermeasures) {
        return this.selectedReason.countermeasures
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    countermeasures() {
      let countermeasures = [];
      this.issues.forEach((issue) => {
        if (issue.countermeasures) {
          let issueCM = issue.countermeasures
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll("'", "")
            .split(",");
          if (issueCM.length > 0) {
            countermeasures = countermeasures.concat(issueCM);
          }
        }
      });
      return countermeasures;
    }
  },
  methods: {
    moment,
    selectReason(issue) {
      this.selectedReason = issue;
      this.cms = [];
      if (issue.reason in this.reasons) {
        this.cms = this.reasons[issue.reason];
      } else {
        const query = `query($reason_Text: String, $machine: [ID]!){
          issueCountermeasures(reason_Text: $reason_Text, machine:$machine){
            edges{
              node{
                id
                text
              }
            }
          }
        }`;
        const variables = {
          reason_Text: issue.reason,
          machine: [this.machine_id]
        };
        this.$http
          .post("graphql/", { query: query, variables: variables })
          .then((res) => {
            this.reasons[issue.reason] = res.data.data.issueCountermeasures.edges.sort((a, b) => {
              if (a.node.text < b.node.text) {
                return -1;
              }
              if (a.node.text > b.node.text) {
                return 1;
              }
              return 0;
            });
            this.cms = this.reasons[issue.reason];
          })
          .catch((res) => {
            this.errors = res.errors;
          });
      }
    },
    updateIssue() {
      this.saving = true;
      const query = `mutation ($id: ID!, $issueInput: IssueInput!){
        updateIssue(globalId:$id, issueData: $issueInput) {
          issue{
            id,
            title,
            openedDate,
            closedDate,
            issueStartDate,
            issueEndDate,
            machineId,
            machineName,
            issueTypeId,
            issueTypeName,
            reason,
            countermeasures,
            cause,
            solution,
          }
        }
      }`;
      let variables = {
        id: this.selectedReason.id,
        issueInput: {}
      };

      if (this.selectedCM == "other" && this.otherText) {
        variables["issueInput"]["countermeasures"] = this.otherText;
      } else {
        variables["issueInput"]["countermeasures"] = this.selectedCM.node.text;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.$message("ALERT", { text: this.$t("Reason Submitted"), type: "success" });
          this.showDialog = false;
          this.selectedReason = false;
          this.selectedCM = false;
          this.cms = [];
          this.saving = false;
          this.$emit("UpdatedCM", res.data.data.updateIssue.issue);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
        });
    }
  }
};
</script>
