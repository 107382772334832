<template>
  <div class="card data-table-view">
    <v-text-field
      v-model="orderByMachineSearchText"
      append-icon="mdi-magnify"
      :label="$t('Search')"
      single-line
      hide-details
      clearable
    ></v-text-field>
    <v-data-table
      :headers="scrapOrderByMachineHeaders"
      :items="filteredTableData"
      :loading="scrapOrderByMachineLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Scrap Details... Please wait')"
      :no-data-text="$t('No data available')"
      :expanded.sync="expanded"
      @item-expanded="handleExpand"
      item-key="name"
      fixed-header
      show-expand
    >
      <template v-slot:body.prepend>
        <tr
          class="total-row"
          v-if="scrapOrderByMachineData.length > 0"
        >
          <td class="total-text">
            <span>{{ $t("Total") }}</span>
          </td>
          <td></td>
          <td
            v-if="expanded.length === 0"
            class="total-cost"
          >
            <span>{{ totalCost }}</span>
          </td>
          <td v-if="expanded.length === 0"></td>
          <td v-if="expanded.length > 0"></td>
          <td v-if="expanded.length > 0"></td>
          <td v-if="expanded.length > 0"></td>
          <td
            v-if="expanded.length > 0"
            class="total-cost"
          >
            <span>{{ totalCost }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td>
          <div
            v-for="(data, index) in item.items"
            class="expanded-cell"
          >
            <span v-if="index === item.items.length - 1">{{ $t("Sub Total") }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          ></div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          >
            <span>{{ data.component }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          >
            <span
              class="description-text"
              :title="data.description"
              >{{ data.description }}</span
            >
          </div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          >
            <span>{{ data.enteredQuantity }} {{ data.uom }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="(data, index) in item.items"
            class="expanded-cell"
          >
            <span v-if="index === item.items.length - 1">{{ data.formattedSubTotal }}</span>
            <span v-else>{{ data.formattedCost }}</span>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ScrapAnalyticsDataTable",
  props: {
    filters: { type: Object, default: () => {} }
  },
  data() {
    return {
      expanded: [],
      orderByMachineSearchText: "",
      scrapOrderByMachineData: [],
      scrapOrderByMachineLoading: false
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    }),
    filteredTableData() {
      return this.orderByMachineSearchText
        ? this.scrapOrderByMachineData.filter((data) =>
            data.name.toLowerCase().includes(this.orderByMachineSearchText.toLowerCase())
          )
        : this.scrapOrderByMachineData;
    },
    totalCost() {
      return `$ ${this.scrapOrderByMachineData.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    scrapOrderByMachineHeaders() {
      if (this.expanded.length > 0) {
        return [
          { text: this.$t("Machine"), value: "name", cellClass: "machine-name" },
          { text: this.$t("Components"), value: "component", cellClass: "component" },
          { text: this.$t("Description"), value: "description" },
          { text: this.$t("Scrapped Quantity"), value: "enteredQuantity", cellClass: "quantity" },
          { text: this.$t("Cost"), value: "formattedCost", cellClass: "cost" }
        ];
      }
      return [
        { text: this.$t("Machine"), value: "name", width: "70%" },
        { text: this.$t("Cost"), value: "formattedCost", width: "30%", cellClass: "cost" }
      ];
    }
  },
  methods: {
    async handleExpand({ item }) {
      this.scrapOrderByMachineLoading = true;
      try {
        const params = {
          from_date: this.filters.fromDate,
          to_date: this.filters.toDate,
          machine_id: parseInt(item.id, 10)
        };
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_code/", { params });
        if (response && response.data) {
          const data = response.data.map((scrap) => {
            return {
              component:
                scrap.scrap_type === "non_process"
                  ? scrap.child_component_name
                  : scrap.scrap_reason_code,
              description:
                scrap.scrap_type === "non_process"
                  ? scrap.child_component_description
                  : scrap.description,
              enteredQuantity: scrap.entered_quantity,
              uom: "scrap_quantity_uom" in scrap ? scrap.scrap_quantity_uom : "",
              cost: scrap.total_cost,
              formattedCost: `$ ${scrap.total_cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            };
          });
          item.items = data;
          item.items.push({
            formattedSubTotal:
              data.length > 0
                ? `$ ${item.items.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                : item.formattedCost
          });
        }
        this.scrapOrderByMachineLoading = false;
      } catch (error) {
        console.error(error);
        this.scrapOrderByMachineLoading = false;
      }
    },
    async getSummaryTableData() {
      this.scrapOrderByMachineLoading = true;
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate
      };
      if (this.filters && this.filters.machines && this.filters.machines.length > 0) {
        params.machine_ids = this.filters.machines;
      }
      if (this.filters && this.filters.partNumbers && this.filters.partNumbers.length > 0) {
        params.part_numbers = this.filters.partNumbers;
      }
      try {
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_machine/", { params });
        if (response && response.data) {
          const data = Object.keys(response.data).map((key) => ({
            id: key,
            name: this.machineLookup(parseInt(key, 10)).name,
            cost: response.data[key],
            formattedCost: `$ ${response.data[key].toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
            transmit: this.selectedSubmittedTab === 0 ? true : false,
            items: []
          }));
          this.scrapOrderByMachineData = data;
        }
        this.scrapOrderByMachineLoading = false;
      } catch (error) {
        console.error(error);
        this.scrapOrderByMachineLoading = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import "./ScrapAnalyticsDataTable.scss";
</style>
