<template>
  <div class="card ai-vision-filters">
    <div class="filter-scroll">
      <div
        v-if="datatableToggle"
        class="filter-card card filter-types"
      >
        <v-radio-group
          :value="selectedFilterType"
          @change="setFilterType"
        >
          <v-radio
            :ripple="false"
            :label="this.$t('Data Table')"
            :value="FILTER_TYPES.DATA_TABLE"
          />
          <v-radio
            :ripple="false"
            :label="this.$t('Analytics')"
            :value="FILTER_TYPES.ANALYTICS"
          />
        </v-radio-group>
      </div>
      <div class="filter-card date-group-card card">
        <div class="option">
          <h4 class="filter-label">{{ $t("Presets") }}</h4>
          <v-tabs
            class="preset-tabs"
            height="25"
            v-model="preset"
            grow
            optional
            grow
            :show-arrows="false"
            @change="changePreset"
          >
            <v-tab class="notab"></v-tab>
            <v-tab class="preset-tab">{{ $t("Day") }}</v-tab>
            <v-tab class="preset-tab">{{ $t("Week") }}</v-tab>
            <v-tab class="preset-tab">{{ $t("Month") }}</v-tab>
            <v-tab class="preset-tab">{{ $t("Year") }}</v-tab>
          </v-tabs>
        </div>

        <div
          v-if="!hideScale"
          class="option"
        >
          <h4>{{ $t("Scale") }}</h4>
          <v-tabs
            class="scales-tabs"
            height="25"
            v-model="scale"
            grow
            optional
            grow
            :show-arrows="false"
            @change="reloadAndSetupFilters"
          >
            <v-tab class="notab"></v-tab>
            <v-tab class="scales-tab">{{ $t("Hour") }}</v-tab>
            <v-tab class="scales-tab">{{ $t("Day") }}</v-tab>
            <v-tab class="scales-tab">{{ $t("Week") }}</v-tab>
            <v-tab class="scales-tab">{{ $t("Month") }}</v-tab>
            <v-tab class="scales-tab">{{ $t("Year") }}</v-tab>
          </v-tabs>
        </div>

        <div class="pickers">
          <div class="from">
            <h4 class="filter-label">{{ $t("From Date") }}</h4>
            <datetime-picker
              :textFieldProps="{
                dense: true,
                class: 'tinyText'
              }"
              class="date-picker"
              id="from_date"
              v-model="from_date"
              clearable
              :is-dialog="true"
              @input="changeDateManually"
              :onClear="
                () => {
                  this.from_date = null;
                  this.changeDateManually();
                }
              "
            />
          </div>

          <div class="to">
            <h4 class="filter-label">{{ $t("To Date") }}</h4>
            <datetime-picker
              :textFieldProps="{
                dense: true,
                class: 'tinyText'
              }"
              class="date-picker"
              id="to_date"
              v-model="to_date"
              clearable
              :is-dialog="true"
              @input="changeDateManually"
              :onClear="
                () => {
                  this.to_date = null;
                  this.changeDateManually();
                }
              "
            />
          </div>
        </div>
      </div>

      <div class="filter-card filters-group-card card">
        <v-autocomplete
          :placeholder="$t('Asset')"
          :items="assets"
          item-text="asset_name"
          return-object
          clearable
          :menu-props="{
            closeOnSelect: true,
            closeOnClick: true
          }"
          v-model="asset"
          @change="reloadAndSetupFilters"
        />
        <v-autocomplete
          :placeholder="$t('Machine')"
          :items="machines"
          item-text="machine"
          return-object
          clearable
          :menu-props="{
            closeOnSelect: true,
            closeOnClick: true
          }"
          v-model="machine"
          @change="reloadAndSetupFilters"
        />
        <v-autocomplete
          :placeholder="$t('Part Number')"
          :items="parts"
          item-text="part_name"
          return-object
          clearable
          :menu-props="{
            closeOnSelect: true,
            closeOnClick: true
          }"
          v-model="part"
          @change="reloadAndSetupFilters"
        />
        <v-autocomplete
          :placeholder="$t('Process Type')"
          :items="process_types"
          clearable
          :menu-props="{
            closeOnSelect: true,
            closeOnClick: true
          }"
          v-model="process_type"
          @change="reloadAndSetupFilters"
        />
        <v-autocomplete
          :placeholder="$t('Result')"
          :items="inspection_results"
          clearable
          :menu-props="{
            closeOnSelect: true,
            closeOnClick: true
          }"
          v-model="inspection_result"
          @change="reloadAndSetupFilters"
        />
      </div>

      <div
        v-if="!hideHelp"
        class="filter-card help-center-group-card card"
      >
        <help-center />
      </div>
    </div>
    <div
      class="button-container"
      v-if="!autoload"
    >
      <v-btn
        color="primary"
        primary
        class="max-btn-width"
        @click="loadDocuments"
      >
        {{ $t("Load") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import DatetimePicker from "@/components/form/DatetimePicker";

import HelpCenter from "../../components/HelpCenter";

export const FILTER_TYPES = {
  DATA_TABLE: "DATA_TABLE",
  ANALYTICS: "ANALYTICS"
};

export default {
  name: "AiVisionFilters",
  components: {
    HelpCenter,
    DatetimePicker
  },
  computed: {
    ...mapGetters({
      getMachineFromPk: "dbCache/machineFromPk"
    })
  },
  props: [
    "setupFilters",
    "loadDocuments",
    "type",
    "asset_id",
    "group_id",
    "hideHelp",
    "hideScale",
    "datatableToggle",
    "filters",
    "autoload"
  ],
  data() {
    return {
      FILTER_TYPES,
      presets: [null, "day", "week", "month", "year"],
      scales: [null, "hour", "day", "week", "month", "year"],
      preset: 0,
      scale: null,
      presetChanged: false,
      from_date_menu: false,
      to_date_menu: false,
      from_date: null,
      to_date: null,
      assets: [],
      asset: null,
      machines: [],
      machine: null,
      parts: [],
      part: null,
      process_types: [],
      process_type: null,
      inspection_results: [],
      inspection_result: null,
      reloadFiltersTimeout: null,
      selectedFilterType: FILTER_TYPES.ANALYTICS
    };
  },
  mounted() {
    this.reloadAndSetupFilters();
  },
  created: function () {
    this.repopulateFilters();
  },
  methods: {
    setFilterType(e) {
      this.selectedFilterType = e;
      this.sendFilters();
    },
    repopulateFilters: async function () {
      try {
        const document_filters = await this.loadFilters();
        this.machines = [];
        for (const machine of document_filters.machine.buckets) {
          this.machines.push({
            machine: this.getMachineFromPk(machine.last_record.hits.hits[0]._source.machine_id)
              .name,
            machine_id: machine.last_record.hits.hits[0]._source.machine_id
          });
        }

        this.assets = [];
        for (const asset of document_filters.asset.buckets) {
          if (asset.last_record.hits.hits[0]._source) {
            this.assets.push({
              asset_name: asset.last_record.hits.hits[0]._source.asset_name,
              asset_id: asset.last_record.hits.hits[0]._source.asset_id
            });
          }
        }

        if (this.asset) {
          const a_filtered = this.assets.filter((p) => p.asset_id === this.asset.asset_id);
          if (a_filtered.length === 0) {
            this.asset = null;
          }
        }

        if (this.machine) {
          const m_filtered = this.machines.filter((m) => m.machine_id === this.machine.machine_id);
          if (m_filtered.length === 0) {
            this.machine = null;
          }
        }

        this.parts = [];
        for (const part of document_filters.part.buckets) {
          this.parts.push({
            part_name: part.last_record.hits.hits[0]._source.part_name,
            part_id: part.last_record.hits.hits[0]._source.part_id
          });
        }
        if (this.part) {
          const p_filtered = this.parts.filter((p) => p.part_name === this.part.part_name);
          if (p_filtered.length === 0) {
            this.part = null;
          }
        }

        this.process_types = [];
        for (const process_type of document_filters.process_type.buckets) {
          this.process_types.push(process_type.last_record.hits.hits[0]._source.process_type);
        }
        if (this.process_type) {
          const pt_filtered = this.process_types.filter((pt) => pt === this.process_type);
          if (pt_filtered.length === 0) {
            this.process_type = null;
          }
        }

        this.inspection_results = [];
        for (const inspection_result of document_filters.inspection_result.buckets) {
          this.inspection_results.push(
            inspection_result.last_record.hits.hits[0]._source.inspection_result
          );
        }
        if (this.inspection_result) {
          const ir_filtered = this.inspection_results.filter((ir) => ir === this.inspection_result);
          if (ir_filtered.length === 0) {
            this.inspection_result = null;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    changePreset(e) {
      const currentPreset = this.presets[e];
      switch (currentPreset) {
        case "day":
          {
            this.from_date = moment().startOf("day").toDate();
            this.to_date = moment().endOf("day").toDate();
            this.scale = 1;
          }
          break;
        case "week":
          {
            this.from_date = moment().startOf("week").toDate();
            this.to_date = moment().endOf("week").toDate();
            this.scale = 2;
          }
          break;
        case "month":
          {
            this.from_date = moment().startOf("month").toDate();
            this.to_date = moment().endOf("month").toDate();
            this.scale = 3;
          }
          break;
        case "year":
          {
            this.from_date = moment().startOf("year").toDate();
            this.to_date = moment().endOf("year").toDate();
            this.scale = 4;
          }
          break;
        default:
          {
            this.from_date = null;
            this.to_date = null;
          }
          break;
      }

      this.presetChanged = true;
      this.reloadAndSetupFilters();
    },
    changeDateManually() {
      this.preset = 0;
      this.reloadAndSetupFilters();
    },
    reloadAndSetupFilters: function () {
      if (this.reloadFiltersTimeout) {
        clearTimeout(this.reloadFiltersTimeout);
      }

      this.reloadFiltersTimeout = setTimeout(() => {
        this.repopulateFilters();
        this.sendFilters();
        this.reloadFiltersTimeout = null;

        if (this.autoload && this.from_date != null && this.to_date != null) {
          this.loadDocuments();
        }
      }, 500);
    },
    sendFilters() {
      this.setupFilters({
        selectedFilterType: this.selectedFilterType,
        from_date: this.from_date,
        to_date: this.to_date,
        asset: this.asset,
        scale: [null, "1h", "1d", "1w", "1M", "1y"][this.scale],
        machine: this.machine,
        part: this.part,
        process_types: this.process_type,
        inspection_result: this.inspection_result
      });
    },
    loadFilters() {
      return new Promise((resolve, reject) => {
        const query = {
          from_date: this.from_date ? this.from_date.toISOString() : null,
          to_date: this.to_date ? this.to_date.toISOString() : null,
          asset_id: this.asset ? this.asset.asset_id : this.type === "asset" ? this.asset_id : null,
          group_id: this.type === "group" ? this.group_id : null,
          machine_id: this.machine ? this.machine.machine_id : null,
          part_name: this.part ? this.part.part_name : null,
          process_type: this.process_type,
          result: this.result
        };
        this.$http
          .get("aivision/filters/", { params: query })
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject();
          });
      });
    }
  },
  watch: {
    group_id: function () {
      this.repopulateFilters();
    },
    asset_id: function () {
      this.repopulateFilters();
    },
    deep: true
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/variables";

.ai-vision-filters {
  padding: 2px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .filter-types {
    min-height: unset;
  }

  .preset-tabs,
  .scales-tabs {
    min-height: unset;
    width: 100%;
    margin-bottom: 20px;
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none;
    }
    .preset-tab,
    .scales-tab {
      padding: 0 8px;
      margin: 0;
      min-width: unset;
      height: 20px;
      font-size: 12px;
    }

    .notab {
      display: none;
      padding: 0;
      margin: 0;
      min-width: unset;
      height: 20px;
      font-size: 10px;
      width: 0;
    }
  }

  .pickers {
    display: flex;
    justify-content: space-between;

    .from,
    .to {
      width: calc(50% - 10px);
      .v-text-field {
        padding-top: 0;
      }

      .v-text-field__details {
        display: none;
      }
    }
  }

  .filter-scroll {
    flex: 1;
    overflow-y: scroll;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .help-center-group-card {
      height: 160px;
      flex: unset;
      margin-bottom: 10px;
    }
  }

  .button-container {
    flex: unset;
    padding: 5px;
  }

  .v-time-picker-custom .v-input {
    padding-top: 0;
  }

  .card {
    padding: 5px;
  }

  .filter-group {
    .v-input {
      padding: 0 10px;
    }
  }

  .date-picker {
    padding: 0 4px;
  }

  .filters-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: unset;
    padding: 10px;
  }

  .date-group-card {
    min-height: unset;
    flex: unset;
    padding-top: 15px;

    .v-input--selection-controls {
      margin-top: 0px;
      padding-top: 16px;
    }
  }

  .filters-group-card {
    flex: 1;
    padding: 5px 15px;
    min-height: 360px;
  }

  .filter-card {
    margin-bottom: 15px;

    h4 {
      padding: 0 10px;
      color: $blue;
    }
  }

  .max-btn-width {
    width: 100%;
  }
}
</style>
