var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card full-height"},[_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"header"},[_c('h4',[_vm._v(_vm._s(("" + (_vm.titlesName || (_vm.filters && _vm.filters.loss_name_string) || "--"))))]),_vm._v(" "),_c('div',{staticClass:"icons"},[_c('i',{staticClass:"fa fa-table",class:_vm.chartType === _vm.chartTypes.TABLE ? 'selected' : null,on:{"click":function () {
              _vm.chartType = _vm.chartTypes.TABLE;
            }}}),_vm._v(" "),_c('i',{staticClass:"fa fa-bar-chart",class:_vm.chartType === _vm.chartTypes.CHART ? 'selected' : null,on:{"click":function () {
              _vm.chartType = _vm.chartTypes.CHART;
            }}})])]),_vm._v(" "),(_vm.chartType == _vm.chartTypes.CHART && !_vm.loading)?_c('div',{staticClass:"chart"},[_c('BarChart',{staticClass:"chart-container",attrs:{"chart-data":_vm.chartData,"uom":"%","click-function":_vm.clickEvent}}),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('bar-chart-paginator',{attrs:{"refresh":_vm.interactiveFilters,"rawData":_vm.rawData,"sortMethod":_vm.sortByTime,"default-sort":"asc","buildChart":_vm.buildChart,"defaultQuantity":"50"}})],1)],1):(_vm.chartType == _vm.chartTypes.TABLE && !_vm.loading)?_c('div',{staticClass:"table"},[_c('GenericTable',{attrs:{"columns":[
          { title: '', key: 'name' },
          {
            title: _vm.$t('Total Downtime'),
            key: 'total_downtime',
            format: _vm.seconds,
            sortable: false
          },
          {
            title: _vm.$t('Status Downtime'),
            key: 'status_runtime',
            format: _vm.seconds,
            sortable: false
          }
        ],"data":_vm.tableData}})],1):_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }