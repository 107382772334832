<template>
  <div class="reports-view">
    <GridLayout
      :col-num="12"
      :row-height="110"
      :is-resizable="false"
      :is-draggable="false"
      :vertical-compact="true"
      :use-css-transforms="false"
      :layout.sync="layout"
    >
      <GridItem
        v-for="(item, index) in layout"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
      >
        <Component
          :ref="item.component"
          :is="item.component"
          v-bind="item.props"
        />
      </GridItem>
    </GridLayout>
  </div>
</template>
<script>
import { GridItem, GridLayout } from "vue-grid-layout";

import ScrapAnalyticsCostByMachine from "./ScrapAnalyticsCostByMachine.vue";
import ScrapAnalyticsCostByPart from "./ScrapAnalyticsCostByPart.vue";
import ScrapAnalyticsCostByType from "./ScrapAnalyticsCostByType.vue";

export default {
  name: "ScrapAnalyticsReports",
  props: {
    filters: { type: Object, default: () => {} }
  },
  components: {
    GridLayout,
    GridItem,
    ScrapAnalyticsCostByMachine,
    ScrapAnalyticsCostByType,
    ScrapAnalyticsCostByPart
  },
  computed: {
    layout() {
      return [
        {
          x: 0,
          y: 0,
          w: 12,
          h: 3,
          i: 0,
          component: "ScrapAnalyticsCostByMachine",
          props: {
            filters: this.filters
          }
        },
        {
          x: 0,
          y: 3,
          w: 12,
          h: 3,
          i: 1,
          component: "ScrapAnalyticsCostByPart",
          props: {
            filters: this.filters
          }
        },
        {
          x: 0,
          y: 6,
          w: 12,
          h: 3,
          i: 2,
          component: "ScrapAnalyticsCostByType",
          props: {
            filters: this.filters
          }
        }
      ];
    }
  }
};
</script>
<style lang="scss">
@import "./ScrapAnalyticsReports.scss";
</style>
