<template>
  <transition name="slide-fade">
    <div class="opportunity_cost_container">
      <div class="metric-component auto-opportunity-cost">
        <div class="title">
          <div class="mobile-chart-title-container">
            <h2 class="card-title">{{ $t(metric.node.name) }}</h2>
            <span class="mobile-gauge-container">
              <v-gauge
                :gaugeValueClass="gaugeValue(metric.node.currentValue)"
                :value="total_opportunityCost"
                :donut="true"
                :options="noringGaugeOpts"
              />
            </span>
          </div>
        </div>

        <v-progress-linear
          indeterminate
          v-if="!loaded"
        ></v-progress-linear>

        <div
          class="charts"
          v-else
        >
          <div
            class="gauge-container hide-circle"
            v-if="loaded"
          >
            <v-gauge
              :gaugeValueClass="gaugeValue(metric.node.currentValue)"
              :value="total_opportunityCost"
              :donut="true"
              :options="noringGaugeOpts"
              width="140px"
              height="140px"
            />
          </div>
          <div class="charts_container">
            <div class="chart">
              <BarChart
                uom="$"
                :chart-id="metric.node.id"
                :chart-name="metric.node.name"
                :chart-data="chart_data[metric.node.name]"
                :chart-options="{
                  scales: {
                    yAxes: [{ zeroLineColor: '#FFF', stacked: true }],
                    xAxes: [
                      {
                        ticks: { minRotation: 90, maxRotation: 90 },
                        stacked: true
                      }
                    ]
                  }
                }"
                class="dynamicChart"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="metric-component shiftTableContainer">
        <div class="chart">
          <v-data-table
            :headers="shiftSummary.headers"
            :items="shiftSummary.shifts"
            :loading="!loaded"
            height="100%"
            disable-pagination
            fixed-header
            hide-default-footer
            class="shiftTable"
          >
            <template v-slot:item.name="{ item }">
              <div :style="{ color: item.color }">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item.cost="{ item }">
              <div>${{ numberWithCommas(item.cost ? item.cost.toFixed(2) : 0) }}</div>
            </template>
            <template v-slot:item.percentage="{ item }">
              <div>{{ getPercentage(item) }}%</div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss">
.opportunity_cost_container {
  display: flex;
  flex: 1;
  justify-self: normal;
  align-self: normal;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-flow: column;
  }
}

.cost-trend {
  display: flex;
  width: unset;
  flex-flow: column;
  align-items: flex-start;
  flex: 1;

  .chart-body {
    display: flex;
    flex-flow: row;
    width: unset;
  }
}
.shiftTableContainer {
  width: 400px;
  min-height: 275px;
  flex: unset;

  @media screen and (max-width: 1200px) {
    width: 100% !important;
  }
}

.charts_container {
  .chart {
    height: 250px;
    .dynamicChart {
      height: 250px;
    }
  }
}
.auto-opportunity-cost {
  flex: 1;

  .charts {
    justify-content: unset;
    display: flex;
    align-items: center;
  }

  .gauge-container {
    flex: none;
  }

  .bar-container {
    flex: 1;
    height: 250px;
  }

  .qualityChart {
    height: 100% !important;
    padding: 0 20px !important;
  }
}
</style>
<script>
import moment from "moment";
import VGauge from "vgauge";
import { Fragment } from "vue-fragment";

import { numberWithCommas, shorten } from "@/utils/filters";

import BarChart from "../charts/BarChart";
import { chartOptions } from "../charts/chartOptions";
import { SCALE_TAB_VALUE } from "./zone_control_enum";

export default {
  props: [`metric`, "measurements", "timeScale", "machine_group_pk"],
  components: {
    BarChart,
    Fragment,
    VGauge
  },
  data() {
    return {
      chartOptions: new chartOptions(),
      production: null,
      chart_data: [],
      total_opportunityCost: 0,
      noringGaugeOpts: {
        radius: 150,
        angle: 0.49,
        lineWidth: 0,
        colorStop: "transparent",
        strokeColor: "transparent",
        highDpiSupport: true
      },
      shiftInfo: [],
      shiftSummary: {
        shifts: [],
        headers: [
          { text: this.$t("Shift"), value: "name", sortable: true, width: "100px" },
          { text: this.$t("Cost"), value: "cost", align: "center", sortable: false },
          { text: this.$t("%"), value: "percentage", align: "center", sortable: false }
        ]
      },
      loaded: false
    };
  },
  created: function () {
    this.getChartData();
  },
  computed: {},
  methods: {
    shorten,
    numberWithCommas,
    getChartData: async function () {
      this.loaded = false;
      const opportunityCostSums = await this.loadOpportunityCost();

      //insert blank elements into each chart
      const data = {};
      const name = this.metric.node.name;
      this.shiftSummary.shifts = [];
      data[name] = { total: { data: [], label: [], color: "white", name: this.$t("Total") } };
      this.total_opportunityCost = 0;
      opportunityCostSums.forEach((months, shift) => {
        data[name][shift] = { data: [], label: [], color: [], name: "" };
        let shiftTotal = 0;
        let color = "white";
        let label = this.$t("Unknown");
        this.shiftInfo.forEach((shiftObj) => {
          if (shiftObj.id == shift) {
            data[name][shift].name = shiftObj.name;
            color = shiftObj.color;
          }
        });
        let index = 0;
        months.forEach((value, key) => {
          data[name][shift].data.push(value);
          data[name][shift].label.push(moment(key).format("DD MMM"));
          data[name][shift].color.push(color);
          this.total_opportunityCost += value;
          shiftTotal += value;
          if (data[name]["total"].data.length >= index + 1) {
            data[name]["total"].data[index] += value;
          } else {
            data[name]["total"].label.push(moment(key).format("DD MMM"));
            data[name]["total"].data[index] = value;
          }
          index++;
        });
        this.shiftSummary.shifts.push({
          name: data[name][shift].name,
          color: color,
          cost: shiftTotal
        });
      });
      this.chart_data = this.formatDataForCharts(data);
      this.loaded = true;
    },
    loadOpportunityCost: async function () {
      /* TODO: Grouped currently by day */
      /* TODO: We need different scale grouping */
      const opportunityCostSums = new Map();
      let params = {
        machine_group_id: this.machine_group_pk,
        from_date: moment().startOf("month").toISOString(),
        to_date: moment().endOf("month").toISOString(),
        scale: "day"
      };

      switch (this.timeScale) {
        case SCALE_TAB_VALUE.WEEK: {
          params.scale = "day";
          params.from_date = moment().startOf("week").toISOString();
          params.to_date = moment().endOf("week").toISOString();
          break;
        }
        case SCALE_TAB_VALUE.MONTH: {
          params.scale = "day";
          params.from_date = moment().startOf("month").toISOString();
          params.to_date = moment().endOf("month").toISOString();
          break;
        }
        case SCALE_TAB_VALUE.YEAR: {
          params.scale = "month";
          params.from_date = moment().startOf("year").toISOString();
          params.to_date = moment().endOf("year").toISOString();
          break;
        }
        default: {
          // handles cases when timeScale is in the form 'start_date_as_ISO_string end_date_as_ISO_string'
          let dates = this.timeScale.split(" ");
          params.from_date = dates[0];
          params.to_date = dates[1];
          params.scale = dates[2];
          break;
        }
      }

      const res = await this.$http.get("variance/cost_per_hour_per_shift/", { params: params });
      this.shiftInfo = res.data.shifts;
      const shifts = res.data.data;
      for (const shift of shifts) {
        if (!opportunityCostSums.get(shift.key)) {
          opportunityCostSums.set(shift.key, new Map());
        }
        for (const row of shift.aggs.buckets) {
          const d = moment(row["key_as_string"]);
          const day = d.startOf("day").toISOString();
          let sum = opportunityCostSums.get(day) || 0;
          for (const key of this.measurements) {
            const qualityVariance = row["total_" + key] ? row["total_" + key].value || 0 : 0;
            sum += qualityVariance;
          }
          opportunityCostSums.get(shift.key).set(day, sum);
        }
      }
      return opportunityCostSums;
    },
    formatDataForCharts(data) {
      const keys = Object.keys(data);
      const formattedData = {};
      for (const key of keys) {
        const datasets = [];
        let labels = [];
        const shiftKeys = Object.keys(data[key]);
        for (const shift of shiftKeys) {
          const dataset = {
            data: data[key][shift].data,
            backgroundColor: data[key][shift].color,
            titles: "",
            footers: "",
            label: data[key][shift].name
          };
          if (data[key][shift].name == this.$t("Total")) {
            dataset["type"] = "line";
            dataset["borderColor"] = "white";
            dataset["backgroundColor"] = "";
          }
          datasets.push(dataset);
          // eslint-disable-next-line no-const-assign
          labels = data[key][shift].label;
        }
        formattedData[key] = {
          datasets: datasets,
          labels: labels
        };
      }
      return formattedData;
    },
    gaugeValue(value) {
      if (value == null) {
        return "gv";
      } else if (value.value.indexOf("%") >= 0) {
        return "gv percent";
      } else if (value.value.indexOf("$") >= 0) {
        return "gv dollar";
      } else {
        return "gv";
      }
    },
    getPercentage(item) {
      return this.total_opportunityCost > 0
        ? ((item.cost / this.total_opportunityCost) * 100).toFixed(0)
        : 0;
    }
  },
  watch: {
    timeScale: function () {
      this.production = null;
      this.getChartData();
    }
  }
};
</script>
