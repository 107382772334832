<template>
  <div class="card trend-data">
    <p class="title">{{ $t("P911 Trend Chart") }}</p>
    <BarChart
      class="bar-chart"
      :chartData="chartData"
      :chartOptions="trendChartOptions"
      :axisType="AXISTYPE.SHORTEN"
    />
  </div>
</template>
<script>
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";

export default {
  name: "P911OpenClosedTrendChart",
  props: {
    trendChartData: { type: Object, default: () => ({ datasets: [{ data: [] }], labels: [] }) }
  },
  components: {
    BarChart
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      trendChartOptions: {
        legend: {
          display: true
        }
      },
      chartData: { datasets: [{ data: [] }], labels: [] }
    };
  },
  watch: {
    trendChartData: {
      handler() {
        this.chartData = this.trendChartData;
      },
      deep: true
    }
  }
};
</script>
