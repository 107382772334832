var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"counter-dialog"},[_c('v-dialog',{attrs:{"persistent":"","content-class":"issue-entry-dialog counter-measure"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"dialogBtn"},[_c('v-badge',{attrs:{"color":"white","content":_vm.countermeasures.length,"offset-x":"0","offset-y":"35","value":_vm.countermeasures.length}},[_c('v-btn',_vm._g(_vm._b({staticClass:"edit-btn",attrs:{"color":"#f7bd59","light":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}})])],1),_vm._v(" "),(hover && _vm.countermeasures.length)?_c('v-card',{staticClass:"issues"},_vm._l((_vm.countermeasures),function(cm){return _c('div',{key:'cm' + cm,staticClass:"issueText"},[_c('p',[_vm._v(_vm._s(cm))])])}),0):_vm._e()],1)]}}],null,true)})]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._v(" "),_c('v-card',[_c('DialogCloseButton',{attrs:{"onClick":function () { return (_vm.showDialog = false); }}}),_vm._v(" "),_c('v-card-title',{staticClass:"headline"},[_vm._v("\n        "+_vm._s(_vm.$t("Countermeasure For"))+" "+_vm._s(_vm.timeDisplay)+"\n      ")]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"selections"},[(_vm.selectedReason)?_c('div',{staticClass:"selection",on:{"click":function($event){_vm.selectedReason = false}}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Reason")))]),_vm._v(" "),_c('div',{staticClass:"reason"},[_c('ReasonButtonWithTooltip',{attrs:{"text":_vm.selectedReason.reason,"isSelected":false,"action":function () {
                    _vm.selectedReason = false;
                  }}})],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex"},[(!_vm.selectedReason)?_c('div',{staticClass:"reasons"},_vm._l((_vm.issues),function(issue){return _c('div',{key:issue.id,staticClass:"reason"},[_c('ReasonButtonWithTooltip',{attrs:{"text":issue.reason,"isSelected":_vm.selectedReason.id == issue.id,"action":function () {
                    _vm.selectReason(issue);
                  }}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.selectedReason && _vm.cms)?_c('div',{staticClass:"cms"},[_vm._l((_vm.cms),function(cm){return _c('div',{key:'cm' + cm.node.id,staticClass:"countermeasure"},[_c('ReasonButtonWithTooltip',{attrs:{"text":cm.node.text,"isSelected":_vm.selectedCM == cm,"action":function () {
                    _vm.selectedCM = cm;
                  },"disabled":_vm.selectedReasonCMs.includes(cm.node.text)}})],1)}),_vm._v(" "),_c('div',{key:"other",staticClass:"countermeasure"},[_c('ReasonButtonWithTooltip',{attrs:{"text":_vm.$t('Other'),"isSelected":_vm.selectedCM == 'other',"action":function () {
                    _vm.selectedCM = 'other';
                  }}})],1)],2):_vm._e()]),_vm._v(" "),(_vm.selectedCM == 'other')?_c('div',{staticClass:"otherText"},[_c('v-text-field',{attrs:{"label":_vm.$t('Countermeasure')},model:{value:(_vm.otherText),callback:function ($$v) {_vm.otherText=$$v},expression:"otherText"}})],1):_vm._e()]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t("Cancel"))+"\n        ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","primary":"","text":"","disabled":!_vm.selectedCM || (_vm.selectedCM == 'other' && _vm.otherText == ''),"loading":_vm.saving},on:{"click":_vm.updateIssue}},[_vm._v("\n          "+_vm._s(_vm.$t("Submit"))+"\n        ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }