<template>
  <v-dialog
    v-model="visibleProp"
    max-width="300"
    persistent
  >
    <v-card v-if="loginError">
      <DialogCloseButton :onClick="() => $emit('update:visibleProp', false)" />
      <v-card-title>
        {{ $t("Log In Error") }}
      </v-card-title>
      <div class="loginPadding loginDialog">
        {{ loginError }}
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="loginError = null"
          >{{ $t("Ok") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-if="!loginBlock && !loginError">
      <DialogCloseButton :onClick="() => $emit('update:visibleProp', false)" />
      <v-card-title>
        {{ $t("Pin Login") }}
      </v-card-title>
      <div class="loginPadding">
        <v-autocomplete
          class="loginname"
          v-model="username"
          :items="users"
          :loading="!loadedUsers"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
          item-text="Username"
          :label="$t('Select a user')"
          outlined
          hide-details
        ></v-autocomplete>
        <pin-pad :pincallback="pinCallback" />
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="tryLogin"
          >{{ $t("Login") }}</v-btn
        >
        <v-btn
          color="error"
          @click="$emit('update:visibleProp', false)"
          >{{ $t("Cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
@import "../scss/variables";
.loginPadding {
  padding: 0 15px;
  min-height: 100px;

  &.loginDialog {
    padding: 20px 30px;
  }

  .loginname {
    margin: 15px 25px;
  }
}
</style>
<script>
import DialogCloseButton from "@/components/DialogCloseButton";

import PinPad from "./PinPad";

export default {
  props: ["loginCallback", "visibleProp", "userRole", "callbackData"],
  components: { PinPad, DialogCloseButton },
  data() {
    return {
      loginBlock: false,
      loginError: null,
      pin: "",
      username: "",
      users: [],
      loadedUsers: false
    };
  },
  created() {},
  watch: {
    visibleProp: function (v) {
      if (v) {
        if (this.userRole) {
          this.getUsers("Machine Admin");
        } else {
          this.getUsers();
        }
      }
      this.$emit("update:visibleProp", v);
    }
  },
  methods: {
    tryLogin: function () {
      this.loginBlock = true;
      this.$http
        .post("pinlogin", { username: this.username, pin: this.pin })
        .then((res) => {
          this.loginBlock = false;
          this.$emit("update:visibleProp", false);
          this.loginCallback(res.data.token, this.callbackData, this.username);
        })
        .catch((e) => {
          this.loginBlock = false;
          this.loginError = this.$t("Incorrect Username/Password");
        });
    },
    pinCallback(v) {
      this.pin = v;
    },
    getUsers(role) {
      let query;
      if (role) {
        query = `query{
        users(role:"${role}"){
            username
        }
      }`;
      } else {
        query = `query{
        users{
            username
        }
      }`;
      }
      let variables = {
        order: {
          username: "ASC"
        }
      };

      this.$http.post("graphql/", { query, variables }).then((res) => {
        if (res.data.data.users) {
          this.users = res.data.data.users.map((u) => u.username);
        }
        this.loadedUsers = true;
      });
    }
  }
};
</script>
