<template>
  <div class="availabilityReasonCodeTable">
    <generic-table
      :columns="[
        { title: $t('Reason Codes'), key: 'description' },
        { title: $t('Actual'), key: 'duration', format: this.seconds }
      ]"
      :footer="[
        { type: 'csv', csv: downloadCsv },
        { type: 'text', text: this.seconds(sumValue(reasonCodeList, ['duration'])) }
      ]"
      :data="reasonCodeList"
    />
    <!--
      <v-simple-table dense fixed-header>
        <thead>
        <tr>
          <th style="width: 200px">
            {{$t('Reason Codes')}}
          </th>
          <th style="width: 200px">
            {{$t('Actual')}}
          </th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="reason in reasonCodeList">
            <td>{{reason.description}}</td>
            <td>{{seconds(reason.duration)}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              {{$t('Total')}}
            </td>
            <td>
              {{seconds(sumValue(reasonCodeList, ['duration']))}}
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
    </div>
    <v-btn
        color="primary"
        small
        class="ma-2"
        @click="downloadCsv"
        style="font-size: 12px; width: 64px"
    >
      <v-icon style="font-size: 12px" small>mdi-file-download</v-icon> CSV
    </v-btn>
    -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { seconds } from "@/utils/filters";
import { sumValue } from "@/utils/sumValue";

export default {
  name: "AvailabilityReasonCode.vue",
  components: { GenericTable },
  props: ["chartOptions", "production"],
  data() {
    return {
      reasonCodeList: []
    };
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    })
  },
  methods: {
    seconds,
    sumValue,
    createCSVFromRows,
    downloadCsv() {
      const rows = [];
      rows.push([this.$t("Reason Codes"), this.$t("Actual (Mins)")]);
      for (const reason of this.reasonCodeList) {
        rows.push([reason.description, Math.round(reason.duration / 60)]);
      }
      this.createCSVFromRows(rows, `reasonCodes_${new Date().toISOString()}`);
    }
  },
  created() {
    const statusMap = {};
    for (const status of this.statuses) {
      for (const set of status.statuscodeSet) {
        statusMap[set.code] = {
          ...status,
          ...set
        };
      }
    }

    const reasonCodeBucket = {};
    for (const interval of this.production) {
      for (const status of interval.status_codes.buckets) {
        const code = status.key;
        const duration = status.duration.value;
        //console.log(code, duration);
        const statusInfo = statusMap[code];
        if (!statusInfo || !statusInfo.running) {
          if (reasonCodeBucket[code] == null) {
            reasonCodeBucket[code] = {
              duration: 0,
              description: statusInfo ? statusInfo.description : status.key
            };
          }
          reasonCodeBucket[code].duration += duration;
        }
      }
    }
    for (const loss of Object.keys(reasonCodeBucket)) {
      this.reasonCodeList.push(reasonCodeBucket[loss]);
    }

    this.reasonCodeList.sort((a, b) => {
      return b.duration - a.duration;
    });
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.availabilityReasonCodeTable {
  flex: 1;
  display: flex;
  flex-direction: column;

  thead {
    tr {
      white-space: nowrap;
    }
  }

  tbody {
    overflow: unset;
  }

  td {
    text-align: left !important;
  }

  .badColor {
    background: $green;
  }

  .goodColor {
    background: $red;
  }

  tfoot {
    position: sticky;
    bottom: 0;

    .theme--dark & {
      background: $grey;
    }

    .theme--light & {
      background: $light-background;
    }
  }
}
</style>
