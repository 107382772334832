export class Category {
  constructor({ name = "", order = 0, questions = [], score = 0 } = {}) {
    this.name = name;
    this.order = order;
    this.questions = questions;
    this.score = score;
  }
  get invalid() {
    let invalid_question = false;
    this.questions.forEach((question) => {
      if (question.invalid) {
        invalid_question = true;
      }
    });
    return invalid_question;
  }

  //differnet icon for save tab - checklist icon? to indicate summary
  //button stuff no longer is neccessary, but we will still need the button

  get progress() {
    let complete = 0;
    let total = 0;
    this.questions.forEach((question) => {
      total += 1;
      if (question.response) {
        complete += 1;
      }
    });
    if (total > 0) {
      return ((complete / total) * 100).toFixed(0);
    }
    return 0;
  }
}

export class Question {
  constructor({
    id = "",
    type = "",
    text = "",
    instruction = "",
    order = order,
    estimatedTime = 0,
    answers = [],
    questionTypes = [
      { name: "True / False", value: "true_false" },
      { name: "Multiple Choice", value: "multiple_choice" },
      // {name: 'Multiple Answer', value: 'multiple_answer'},
      { name: "Text", value: "text" },
      { name: "Number", value: "number" },
      { name: "Range", value: "range" }
    ]
  }) {
    this.id = id;
    this.type = type;
    this.text = text;
    this.instruction = instruction;
    this.type = type;
    this.order = order;
    this.estimatedTime = estimatedTime;
    this.answers = answers;
    this.questionTypes = questionTypes;
  }

  get invalid() {
    if (typeof this.estimatedTime !== "number") {
      this.estimatedTime = 0;
    }
    if (!this.type || !this.text) {
      return true;
    } else {
      return false;
    }
  }
}

export class Answer {
  constructor({ id = "", text = "", order = 0, isCorrect = false }) {
    this.id = id;
    this.text = text;
    this.order = order;
    this.isCorrect = isCorrect;
  }
}

export class Response {
  constructor(
    input = {
      id: "",
      text: "",
      comment: "",
      answerId: false
    }
  ) {
    this.id = input.id;
    this.text = input.text;
    this.comment = input.comment;
    this.answerId = input.answerId;
  }
  // get invalid(){
  //   let invalid_response = false
  //   console.log("1")
  //   this.responses.forEach((response)=>{
  //     console.log("2")
  //     if (response.invalid){
  //       console.log("3")
  //       invalid_response = true
  //       console.log("4 in audit")
  //     }
  //   })
  //   console.log("5")
  //   return invalid_response
  // }
}
