<template>
  <div class="audits-parent">
    <v-tabs
      class="tabs hideMobile"
      centered
      grow
      height="60px"
      v-model="tab"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        :to="tab.route"
        exact
        >{{ tab.name }}</v-tab
      >
    </v-tabs>

    <v-tabs
      class="smallPageTabs hideDesktop"
      centered
      grow
      height="60px"
      v-model="tab"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        :to="tab.route"
        exact
        >{{ tab.name }}</v-tab
      >
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CompletedAudits from "./CompletedAudits";

export default {
  components: { CompletedAudits },
  data() {
    return {
      tab: null,
      tabs: [
        { name: this.$t("Audit Types"), route: `/audits/types/` },
        { name: this.$t("Completed Audits"), route: `/audits/CompletedAudits/` },
        { name: this.$t("Findings"), route: `/audits/findings/` }
      ]
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      titles: "app/Titles"
    })
  },
  methods: {}
};
</script>

<style lang="scss">
@import "../../scss/variables";
.audits-parent {
  padding: 1rem;
  height: unset;
  min-height: 100%;
  display: flex;
  flex-flow: column;

  .v-tabs {
    flex: unset;
  }

  .smallPageTabs {
    a {
      font-size: 12px !important;
    }
  }

  .formCard {
    display: flex;
    flex-flow: column;
    height: unset !important;
    flex: 1;
  }

  @media screen and(max-width: 599px) {
    height: unset;

    tbody {
      overflow-x: hidden;
    }
  }

  .completedAudit,
  .auditForm {
    display: flex;
    flex: 1;

    .comment.section {
      height: 100% !important;
    }

    .sectionContent {
      overflow-y: scroll;
    }
    .sectionLabel {
      width: 95%;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .v-input__control {
      flex: 1;
    }

    th {
      padding: 0 !important;
    }

    .completed-audits.card {
      height: unset !important;
    }

    table .v-data-table__mobile-row__cell button.actionButton {
      margin-bottom: 20px;
      margin-top: 10px;
      border-radius: 3px;
      width: 100% !important;
    }

    .v-data-table__wrapper {
      height: 100% !important;
    }

    .v-text-field {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
    }

    .header {
      padding-right: unset !important;
    }

    .v-data-table__mobile-row {
      min-height: 35px !important;

      border-bottom: solid 1px rgba(0, 0, 0, 0.05);

      .v-data-table__mobile-row__header {
        color: $blue;
        flex: 1;
        text-align: left;
      }

      .v-data-table__mobile-row__cell {
        justify-content: left;
        align-items: flex-start;
        flex: 1;
        text-align: left;
      }
    }

    .completedAudit,
    .auditForm {
      display: flex;
      flex-direction: column;

      .question {
        display: flex;
        flex-flow: column nowrap;
        padding: 10px;

        .section {
          display: table;
          height: unset;
          max-height: unset;
          min-height: unset;
          padding: 0 !important;
          min-height: 50px;
          padding-bottom: 10px !important;
          align-items: normal;
          height: unset !important;

          .sectionLabel {
            position: relative !important;
            margin: 0px !important;
            padding: 0px !important;
            padding-bottom: 5px !important;
            white-space: unset;
            width: unset;
          }
          .sectionContent {
            width: unset;
            margin: 0px;
            padding: 0px !important;
            flex: 1;
            height: 100%;
            padding: 1rem;
            border: 1px solid transparent;
            border: 1px solid rgba(255, 255, 255, 0.8);
            border-radius: 5px;
            display: flex;
            justify-content: normal;
            align-items: normal;

            textarea {
              width: unset !important;
            }

            .v-textarea {
              .v-input__slot {
                align-items: flex-start !important;
                flex-direction: row;
              }
            }

            p {
              margin: 10px;
            }

            .multipleChoice {
              margin: 10px;
              flex: 1;
              width: unset;
              height: unset;
              max-width: unset;
              display: flex;
              align-items: flex-start;

              .v-input {
                flex: 1;
                align-self: normal;
                justify-content: normal;
              }

              .v-input__control {
                display: flex;
                flex-flow: column nowrap;
              }

              .v-input--radio-group__input {
                flex-flow: column;

                .v-radio {
                  flex-flow: row !important;
                  align-items: flex-start;
                  justify-content: flex-start;

                  .v-input--selection-controls__input {
                    flex: unset;
                  }
                }
              }
            }

            textarea {
              flex: 1;
              justify-self: flex-start;
              margin: 10px;
            }
          }
        }

        div {
          max-height: unset;
          height: unset;
          padding: 0;
          margin: 0;
          flex: 1;
          display: flex;
          flex-flow: column nowrap;
        }
      }

      .header {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        background: transparent;
        box-shadow: none !important;
        flex: unset !important;

        h1 {
          font-size: 16px !important;
          margin: 5px 15px !important;
        }
        span {
          font-size: 16px !important;
        }
      }

      .comment {
        display: flex;
        flex-flow: column;
        margin-top: 0 !important;
        padding-top: 0 !important;
        height: unset !important;

        div {
          position: relative !important;
        }
      }

      .questionWrapper,
      .questionWraper {
        height: unset;

        .v-textarea {
        }
      }

      .formCard {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        background: transparent;
        box-shadow: none !important;
        display: flex;

        .right-group {
          padding-bottom: 15px;

          h1 {
            font-size: 16px !important;
            margin: 5px 15px !important;
          }

          span {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}
</style>
