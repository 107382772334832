<template>
  <div class="order-by-scrap-type">
    <div class="tabs-search-container">
      <v-tabs
        class="submitted-tabs"
        v-model="selectedSubmittedTab"
        key="submitted-tabs"
        hide-slider
      >
        <v-tab key="submitted">
          <p>
            <span class="button_text">{{ $t("Submitted") }}</span>
            <span class="tiny_button_text">{{ $t("Submitted") }}</span>
          </p>
        </v-tab>
        <v-tab key="unsubmitted">
          <p>
            <span class="button_text">{{ $t("Unsubmitted") }}</span>
            <span class="tiny_button_text">{{ $t("Unsubmitted") }}</span>
          </p>
        </v-tab>
      </v-tabs>
      <div class="scrap-type-search">
        <v-text-field
          v-model="orderByTypeSearchText"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </div>
    </div>
    <v-data-table
      :headers="scrapOrderByTypeHeaders"
      :items="scrapOrderByTypeData"
      :loading="scrapOrderByTypeLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Scrap Details... Please wait')"
      :no-data-text="$t('No data available')"
      :expanded.sync="expanded"
      :search="orderByTypeSearchText"
      @click:row="handleExpand"
      item-key="id"
      fixed-header
      show-expand
    >
      <template v-slot:body.prepend>
        <tr
          class="total-row"
          v-if="scrapOrderByTypeData.length > 0"
        >
          <td class="total-text">
            <span>{{ $t("Total") }}</span>
          </td>
          <td></td>
          <td
            v-if="expanded.length === 0"
            class="total-cost"
          >
            <span>{{ totalCost }}</span>
          </td>
          <td v-if="expanded.length > 0"></td>
          <td v-if="expanded.length > 0"></td>
          <td v-if="expanded.length > 0"></td>
          <td
            v-if="expanded.length > 0"
            class="total-cost"
          >
            <span>{{ totalCost }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td>
          <div
            v-for="(data, index) in item.machines"
            class="expanded-cell"
          >
            <span v-if="index === item.machines.length - 1">{{ $t("Sub Total") }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="data in item.machines"
            class="expanded-cell"
          ></div>
        </td>
        <td>
          <div
            v-for="data in item.machines"
            class="expanded-cell"
          >
            <span>{{ data.machine }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="data in item.machines"
            class="expanded-cell"
          >
            <span
              class="description-text"
              :title="data.description"
              >{{ data.description }}</span
            >
          </div>
        </td>
        <td>
          <div
            v-for="data in item.machines"
            class="expanded-cell"
          >
            <span>{{ data.quantity }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="(data, index) in item.machines"
            class="expanded-cell"
          >
            <span v-if="index === item.machines.length - 1">{{ data.formattedSubTotal }}</span>
            <span v-else>{{ data.formattedCost }}</span>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "QualityOrderByScrapType",
  props: ["machineGroupPk", "from_date", "to_date", "shift"],
  data() {
    return {
      expanded: [],
      orderByTypeSearchText: "",
      selectedSubmittedTab: 0,
      scrapOrderByTypeData: [],
      scrapOrderByTypeLoading: false
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    }),
    totalCost() {
      return `$ ${this.scrapOrderByTypeData.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    scrapOrderByTypeHeaders() {
      if (this.expanded.length > 0) {
        return [
          { text: this.$t("Scrap Type"), value: "scrapType", width: "20%" },
          { text: this.$t("Machine"), value: "machine", width: "10%" },
          { text: this.$t("Description"), value: "description", width: "30%" },
          { text: this.$t("Quantity"), value: "quantity", width: "20%" },
          { text: this.$t("Cost"), value: "formattedCost", width: "20%" }
        ];
      }
      return [
        { text: this.$t("Scrap Type"), value: "scrapType", width: "80%" },
        { text: this.$t("Cost"), value: "formattedCost", width: "20%", cellClass: "cost" }
      ];
    }
  },
  methods: {
    handleExpand(item, event) {
      if (event.isExpanded) {
        const foundExpandedIndex = this.expanded.findIndex((row) => row.id === item.id);
        this.expanded.splice(foundExpandedIndex, 1);
        return;
      }
      this.expanded.push(item);
    },
    async getSummaryTableData() {
      this.scrapOrderByTypeLoading = true;
      const params = {
        machine_group_id: this.machineGroupPk,
        submitted: this.selectedSubmittedTab === 0 ? true : false,
        shift_id: this.shift ? this.shift : null,
        from_date: this.from_date.toISOString(),
        to_date: this.to_date.toISOString()
      };
      try {
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_type/", { params });
        if (response && response.data) {
          const data = Object.keys(response.data).map((key, index) => {
            const scrapData = {
              id: index,
              scrapType: key,
              cost: response.data[key].cost,
              formattedCost: `$ ${response.data[key].cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
              machines: response.data[key].machines.map((machine) => {
                machine = Array.isArray(machine) ? machine[0] : machine;
                return {
                  machine: this.machineLookup(parseInt(machine.key)).name,
                  description: machine.last_record.hits.hits[0]._source.description,
                  quantity: machine.total_qty.value,
                  formattedCost: `$ ${machine.total_cost.value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                  cost: machine.total_cost.value
                };
              })
            };
            scrapData.machines.push({ formattedSubTotal: scrapData.formattedCost });
            return scrapData;
          });
          this.scrapOrderByTypeData = data;
        }
        this.scrapOrderByTypeLoading = false;
      } catch (error) {
        console.error(error);
        this.scrapOrderByTypeLoading = false;
      }
    }
  },
  watch: {
    selectedSubmittedTab() {
      this.getSummaryTableData();
    },
    shift() {
      this.getSummaryTableData();
    },
    from_date() {
      this.getSummaryTableData();
    },
    to_date() {
      this.getSummaryTableData();
    }
  }
};
</script>
<style lang="scss"></style>
