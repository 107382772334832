<template>
  <v-card
    :class="[!customPadding && 'px-4 py-3', 'root']"
    elevation="0"
  >
    <slot name="default"></slot>
  </v-card>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Card",
  props: {
    customPadding: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.theme--light.v-application .card {
  background: var(--bg-card);
}

.root {
  background: var(--bg-card);
}
</style>
