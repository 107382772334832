<template>
  <div class="search-panel">
    <div
      class="overlay"
      @click="close()"
      :class="{ show: rightPanel == 'search' }"
    ></div>
    <div class="card">
      <div class="card-header">
        <h2>{{ $t("Search") }}</h2>
        <div class="options">
          <i
            class="fa fa-times"
            @click="close()"
          />
        </div>
      </div>
      <div class="card-content">
        <v-text-field
          v-model="searchText"
          prepend-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <v-expansion-panels
          focusable
          v-model="panel"
        >
          <v-expansion-panel
            @change="loadMachines"
            v-if="results.machines.total"
          >
            <v-expansion-panel-header
              >{{ results.machines.total }} : {{ $t("Machines") }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-data-iterator
                class="comments-wrapper"
                :items="results.machines.results"
                :loading="results.machines.loading"
                :page.sync="results.machines.currentPage"
                :items-per-page.sync="results.machines.itemsPerPage"
                :serverItemsLength="results.machines.total"
                :no-data-text="$t('No Machines Found')"
                :hide-default-footer="results.machines.total < results.machines.itemsPerPage"
              >
                <template v-slot:default="props">
                  <transition-group
                    :class="'items'"
                    name="slide-fade-list"
                    mode="out-in"
                    tag="div"
                  >
                    <div
                      class="item"
                      v-for="(item, index) in props.items"
                      :key="item.id"
                      v-if="
                        index >= props.pagination.pageStart && index < props.pagination.pageStop
                      "
                    >
                      <router-link
                        :to="{
                          name: 'machine',
                          params: { machine_id: item.global_id, machine_pk: item.id }
                        }"
                        @click="close"
                      >
                        {{ item.name }}
                      </router-link>
                    </div>
                  </transition-group>
                </template>
              </v-data-iterator>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            @change="loadIssues"
            v-if="results.issues.total"
          >
            <v-expansion-panel-header
              >{{ results.issues.total }} : {{ $t("Issues") }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-data-iterator
                class="comments-wrapper"
                :items="results.issues.results"
                :loading="results.issues.loading"
                :page.sync="results.issues.currentPage"
                :items-per-page.sync="results.issues.itemsPerPage"
                :serverItemsLength="results.issues.total"
                :no-data-text="$t('No Issues Found')"
                :hide-default-footer="results.issues.total < results.issues.itemsPerPage"
              >
                <template v-slot:default="props">
                  <transition-group
                    :class="'items'"
                    name="slide-fade-list"
                    mode="out-in"
                    tag="div"
                  >
                    <div
                      class="item"
                      v-for="(item, index) in props.items"
                      :key="item.node.id"
                    >
                      <router-link
                        :to="{ name: 'ticket', params: { ticket_id: item.node.id } }"
                        @click="close"
                      >
                        {{ item.node.title }}
                      </router-link>
                    </div>
                  </transition-group>
                </template>
              </v-data-iterator>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            @change="loadHelp"
            v-if="results.help.total"
          >
            <v-expansion-panel-header
              >{{ results.help.total }} : {{ $t("P911") }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-data-iterator
                class="comments-wrapper"
                :items="results.help.results"
                :loading="results.help.loading"
                :page.sync="results.help.currentPage"
                :items-per-page.sync="results.help.itemsPerPage"
                :serverItemsLength="results.help.total"
                :no-data-text="$t('No P911 Found')"
                :hide-default-footer="results.help.total < results.help.itemsPerPage"
              >
                <template v-slot:default="props">
                  <transition-group
                    :class="'items'"
                    name="slide-fade-list"
                    mode="out-in"
                    tag="div"
                  >
                    <div
                      class="item"
                      v-for="(item, index) in props.items"
                      :key="item.node.id"
                    >
                      <router-link
                        :to="{ name: 'help_ticket', params: { ticket_id: item.node.id } }"
                        @click="close"
                      >
                        {{ item.node.details }}
                      </router-link>
                    </div>
                  </transition-group>
                </template>
              </v-data-iterator>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="results.docs.total">
            <v-expansion-panel-header
              >{{ results.docs.total }} : {{ $t("Docs") }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-data-iterator
                class="comments-wrapper"
                :items="results.docs.results"
                :loading="results.docs.loading"
                :page.sync="results.docs.currentPage"
                :items-per-page.sync="results.docs.itemsPerPage"
                :serverItemsLength="results.docs.total"
                :no-data-text="$t('No Docs Found')"
                :hide-default-footer="results.docs.total < results.docs.itemsPerPage"
              >
                <template v-slot:default="props">
                  <transition-group
                    :class="'items'"
                    name="slide-fade-list"
                    mode="out-in"
                    tag="div"
                  >
                    <div
                      class="doc"
                      v-for="(doc, index) in props.items"
                      :key="doc.id"
                      @click="selectDoc(doc)"
                    >
                      <div class="thumbnail">
                        <img
                          :src="doc.thumbnailUrl"
                          v-if="doc.thumbnailUrl"
                        />
                        <i
                          v-else-if="doc.latestVersion.video"
                          class="fa fa-file-video-o"
                          aria-hidden="true"
                        ></i>
                        <i
                          v-else-if="doc.latestVersion.image"
                          class="fa fa-file-image-o"
                          aria-hidden="true"
                        ></i>
                        <i
                          v-else-if="doc.latestVersion.pdf"
                          class="fa fa-file-pdf-o"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="name">
                        <p class="label">{{ doc.name }}</p>
                        <p class="description">{{ doc.description }}</p>
                      </div>
                    </div>
                  </transition-group>
                </template>
              </v-data-iterator>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="results.videos.total">
            <v-expansion-panel-header
              >{{ results.videos.total }} : {{ $t("Videos") }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-data-iterator
                class="comments-wrapper"
                :items="results.videos.results"
                :loading="results.videos.loading"
                :page.sync="results.videos.currentPage"
                :items-per-page.sync="results.videos.itemsPerPage"
                :serverItemsLength="results.videos.total"
                :no-data-text="$t('No Videos Found')"
                :hide-default-footer="results.videos.total < results.videos.itemsPerPage"
              >
                <template v-slot:default="props">
                  <transition-group
                    :class="'items'"
                    name="slide-fade-list"
                    mode="out-in"
                    tag="div"
                  >
                    <div
                      class="doc"
                      v-for="(video, vid_index) in props.items"
                      :key="video.id"
                      @click="selectDoc(video)"
                    >
                      <div class="thumbnail">
                        <img
                          :src="video.thumbnailUrl"
                          v-if="video.thumbnailUrl"
                        />
                        <i
                          v-else-if="video.latestVersion.video"
                          class="fa fa-file-video-o"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="name">
                        <p class="label">{{ video.name }}</p>
                        <p class="description">{{ video.description }}</p>
                      </div>
                    </div>
                  </transition-group>
                </template>
              </v-data-iterator>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <v-dialog
      v-model="showDialog"
      width="1000"
    >
      <media-viewer
        :media="selectedMedia"
        @close="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.search-panel {
  width: $rightPanelWidth;
  margin: 1rem 0;
  margin-top: 0.5rem;
  display: block;
  .card {
    z-index: 1000;
    height: 100%;
    overflow: hidden;
    .card-content {
      position: relative;
      .v-input {
        margin-bottom: 1rem;
      }
      .items {
        .item {
          margin: 0.5rem;
          a {
            text-decoration: none;
            color: white;
          }
        }
        .doc {
          margin: 1rem 0.5rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          border: 1.5px solid white;
          border-radius: 0.5rem;
          min-height: 4rem;
          overflow: hidden;
          transition: all 0.2s ease-in-out;
          &:hover {
            border-color: $blue;
          }
          .thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            width: 100px;
            overflow: hidden;
            margin-right: 0.5rem;
            img {
              min-height: 100px;
              max-width: 100px;
              text-align: center;
            }
            i {
              font-size: 2rem;
            }
          }
          .name {
            width: calc(100% - 100px - 1rem);
            // height: calc(100% - 2rem);
            text-align: left;
          }
          .download {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5rem;
            color: white;
            height: 3rem;
            width: 3rem;
            border-radius: 2rem;
            background-color: $blue;
            cursor: pointer;
            i {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import MediaViewer from "@/components/MediaViewer";

export default {
  props: {
    // filters:{
    //   default: "machine: 0"
    // }
  },
  data() {
    return {
      searchText: "",
      searchDebouncer: false,
      selectedSection: false,
      panel: false,
      selectedMedia: false,
      showDialog: false,
      results: {
        machines: {
          itemsPerPage: 10,
          currentPage: 1,
          loading: false,
          results: [],
          total: 0
        },
        issues: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        },
        help: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        },
        docs: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        },
        videos: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        }
      }
    };
  },
  created() {},
  components: {
    MediaViewer
  },
  computed: {
    ...mapGetters({
      rightPanel: "components/RightPanel",
      user: "session/User"
    })
  },
  methods: {
    moment,
    ...mapActions({
      setRightPanel: "components/SetRightPanel"
    }),
    close() {
      console.log("closing");
      this.setRightPanel(false);
    },
    loadCounts() {
      this.loadMachines();
      this.loadIssues();
      this.loadHelp();
      this.loadDocs();
      this.loadVideos();
    },
    selectDoc(doc) {
      this.selectedMedia = doc;
      this.showDialog = true;
    },
    loadMachines() {
      let params = {
        searchText: this.searchText
      };
      this.results.machines.currentPage = 1;
      this.results.machines.loading = true;
      this.results.machines.results = this.results.machines.results.slice();
      this.$http.get("search/machines/", { params }).then((res) => {
        this.results.machines.results = res.data;
        this.results.machines.total = res.data.length;
      });
    },
    loadIssues() {
      this.results.issues.loading = true;
      const query = `query (
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        issues (
          simpleQueryString: $search,
          filter: $filters,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [automatic]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              id,
              title,
              reason,
              openedDate,
              ticketedDate,
              assignedToNames,
              issueStartDate,
              issueEndDate,
              machineId,
              machineName,
              issueTypeId,
              issueTypeName,
            }
          }
        }
      }`;
      const variables = {
        filters: {
          ticketed: { value: true }
        },
        before: false,
        after: false,
        search: this.searchText + "*"
      };
      if (this.results.issues.load) {
        Object.assign(variables, this.results.issues.load);
      } else {
        variables["first"] = this.results.issues.itemsPerPage;
      }
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.results.issues.results = res.data.data.issues.edges;
          this.results.issues.total = res.data.data.issues.facets.automatic.doc_count;
          this.results.issues.next = res.data.data.issues.pageInfo.endCursor;
          this.results.issues.prev = res.data.data.issues.pageInfo.startCursor;
          this.results.issues.load = false;
          this.results.issues.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadHelp() {
      this.results.help.loading = true;
      const query = `query(
        $filters: GrapheneElasticFilterHelpTicketSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        helpTickets(
          simpleQueryString: $search,
          filter: $filters,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [title]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            node{
              id
              ticketedBy,
              ticketedByName,
              ticketedDate,
              details,
              tagSearch,
              title
            }
          }
        }
      }`;
      const variables = {
        filters: {},
        before: false,
        after: false,
        search: this.searchText + "*"
      };
      if (this.results.issues.load) {
        Object.assign(variables, this.results.issues.load);
      } else {
        variables["first"] = this.results.issues.itemsPerPage;
      }
      this.$http
        .get("graphql/", { params: { query: query, variables: variables } })
        .then((res) => {
          this.results.help.results = res.data.data.helpTickets.edges;
          this.results.help.total = res.data.data.helpTickets.facets.title.doc_count;
          this.results.help.next = res.data.data.helpTickets.pageInfo.endCursor;
          this.results.help.prev = res.data.data.helpTickets.pageInfo.startCursor;
          this.results.help.load = false;
          this.results.help.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadDocs() {
      const query = `query($search: String){
        helpDocs(search: $search){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;
      const variables = {};
      if (this.searchText) {
        variables["search"] = this.searchText;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.results.docs.results = res.data.data.helpDocs;
          this.results.docs.total = res.data.data.helpDocs.length;
          this.results.docs.load = false;
          this.results.docs.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadVideos() {
      const query = `query($search: String){
        helpDocs(search: $search, video: true){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;
      const variables = {};
      if (this.searchText) {
        variables["search"] = this.searchText;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.results.videos.results = res.data.data.helpDocs;
          this.results.videos.total = res.data.data.helpDocs.length;
          this.results.videos.load = false;
          this.results.videos.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    }
  },
  watch: {
    $route(to, from) {
      this.close();
    },
    searchText: function () {
      this.results.machines.results = [];
      this.results.machines.total = 0;
      this.results.issues.results = [];
      this.results.issues.total = 0;
      this.results.help.results = [];
      this.results.help.total = 0;
      this.results.docs.results = [];
      this.results.docs.total = 0;
      this.results.videos.results = [];
      this.results.videos.total = 0;

      if (this.searchDebouncer) {
        clearTimeout(this.searchDebouncer);
      }
      if (this.searchText) {
        let load = this.loadCounts;
        this.searchDebouncer = setTimeout(load, 500);
      }
    }
  }
};
</script>
