<template>
  <div>
    <ZoneControlWeeklyGroup
      v-if="data"
      :shifts="data && data.shifts"
      :data="data && data.groups[0]"
      :show-change-button="true"
      :dates="dates"
      @change="change($event)"
      @click-drill-in="showDrillIn = true"
    ></ZoneControlWeeklyGroup>

    <ZoneControlSidebar
      :is-open="showDrillIn"
      :shifts="nestedData && nestedData.shifts"
      :data="nestedData"
      :dates="dates"
      :loading="nestedLoading"
      @close="close"
      @click-drill-in="$event.pk ? (nestedGroupPk = $event.pk) : (nestedGroupPk = $event.id)"
    >
      <template #beforeClose>
        <date-range-picker
          id="weekPicker2"
          class="mr-6 picker"
          :dates-in="nestedDates"
          position="bottom right"
          append-icon="mdi-calendar-range-outline"
          :text-field-props="{
            filled: true
          }"
          @input="changeNested($event)"
        ></date-range-picker>
      </template>
    </ZoneControlSidebar>
  </div>
</template>

<script>
import moment from "moment-timezone";

import { computed, ref } from "@vue/composition-api";
import { useGroupParams } from "@/features/group/useGroupParams";
import { useZoneControlWeeklyProduction } from "@/features/zone-control-weekly/useZoneControlWeeklyProduction";
import ZoneControlWeeklyGroup from "@/features/zone-control-weekly/components/ZoneControlWeeklyGroup.vue";
import ZoneControlSidebar from "@/features/zone-control-weekly/components/ZoneControlSidebar.vue";
import DateRangePicker from "@/components/form/DateRangePicker.vue";

export default {
  components: {
    ZoneControlSidebar,
    ZoneControlWeeklyGroup,
    DateRangePicker
  },
  setup() {
    const DATE_FORMAT = "YYYY-MM-DD";
    const showDrillIn = ref(false);
    const drillInData = ref(undefined);
    const nestedDrillInData = ref(undefined);
    const { machine_group_pk } = useGroupParams();
    const defaultDates = [
      moment().startOf("week").format(DATE_FORMAT),
      moment().endOf("week").format(DATE_FORMAT)
    ];

    const nestedGroupPk = ref(machine_group_pk.value);
    const dates = ref(defaultDates);
    const nestedDates = ref(defaultDates);

    const changeHandler = (dates, value) => {
      let start = moment(value[0]).startOf("week");
      let end = moment(value[0]).endOf("week");
      dates.value = [start.format(DATE_FORMAT), end.format(DATE_FORMAT)];
    };

    const change = (val) => changeHandler(dates, val);
    const changeNested = (val) => changeHandler(nestedDates, val);

    const close = () => {
      nestedGroupPk.value = machine_group_pk.value;
      nestedDates.value = dates.value;
      showDrillIn.value = false;
    };

    const getParams = (dates, machine_group_pk, children = true) => ({
      from_date: moment(dates[0]).startOf("week").toISOString(),
      to_date: moment(dates[1]).endOf("week").toISOString(),
      machine_group_id: machine_group_pk,
      children: children
    });

    const paramsApi = computed(() => getParams(dates.value, machine_group_pk.value, false));
    const nestedApiParams = computed(() => getParams(nestedDates.value, nestedGroupPk.value));

    const { data } = useZoneControlWeeklyProduction(paramsApi);
    const { data: nestedData, isFetching: nestedLoading } =
      useZoneControlWeeklyProduction(nestedApiParams);

    return {
      data,
      dates,
      change,
      showDrillIn,
      drillInData,
      nestedDrillInData,
      nestedDates,
      changeNested,
      nestedData,
      nestedGroupPk,
      nestedLoading,
      close
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/views/ldms/zone-control/ZoneControl";
</style>

<style scoped>
.picker {
  min-width: 249px;
  max-width: 249px;
  height: 44px;

  .v-input__slot {
    min-height: 44px !important;
  }

  .v-input__append-inner {
    margin-top: 10px;
  }
}
</style>
