<template>
  <Dropdown
    :label="title"
    :name="name"
    :items="items"
    :value="value"
    item-value="value"
    :loading="isFetching"
    :search-input.sync="search"
    :concat-selected-from="1"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { computed, ref, toRefs } from "@vue/composition-api";
import Dropdown from "@/ui/Dropdown";
import { useQuery } from "@tanstack/vue-query";

export default {
  name: "FiltersDropdown",
  components: {
    Dropdown
  },
  props: {
    name: { type: String, default: "" },
    title: { type: String, default: "" },
    value: { type: Array, default: () => [] },
    isTextAsValue: { type: Boolean, default: false },
    queryKey: { type: String, default: "" },
    queryFieldName: { type: String, default: "" },
    queryParams: { type: Object, required: true },
    queryFn: { type: Function, required: true },
    dataAdapter: { type: Function, required: false, default: undefined }
  },
  setup(props) {
    const { queryFn, queryParams, queryKey, queryFieldName, isTextAsValue, dataAdapter } =
      toRefs(props);
    const search = ref("");

    const query = useQuery({
      queryKey: [queryKey.value, queryFieldName, queryParams],
      queryFn: () => queryFn.value(queryParams.value, { field: queryFieldName.value }),
      keepPreviousData: true
    });

    const items = computed(() => {
      if (query.data && query.data.value) {
        if (dataAdapter.value) {
          return dataAdapter.value(query.data.value);
        }

        if (Array.isArray(query.data.value)) {
          return query.data.value.map((value) => ({
            value: value,
            text: value
          }));
        }

        return Object.keys(query.data.value).map((id) => {
          const value = query.data.value[id];
          return {
            value: !isTextAsValue.value ? id || value : value,
            text: value
          };
        });
      }
      return [];
    });

    return {
      items,
      isFetching: query.isFetching,
      search
    };
  }
};
</script>
