<template>
  <div class="aivision_datagrid">
    <div class="datagrid">
      <generic-table
        :columns="[
          { title: $t('Equipment'), key: 'equipment', sortable: true },
          { title: $t('Part Number'), key: 'part_name', sortable: true },
          {
            title: $t('Total Processed'),
            key: 'total_processed',
            format: numberWithCommas,
            sortable: true
          },
          { title: $t('Passed'), key: 'passed', format: numberWithCommas, sortable: true },
          { title: $t('Failed'), key: 'failed', format: numberWithCommas, sortable: true },
          { title: $t('FPY(%)'), key: 'passed_percent', format: (e) => `${e}%`, sortable: true },
          { title: $t('Reject %'), key: 'reject_percent', format: (e) => `${e}%`, sortable: true }
        ]"
        :nested_columns="[
          { key: '' },
          { key: 'part_name' },
          { key: 'total_processed', format: numberWithCommas },
          { key: 'passed', format: numberWithCommas },
          { key: 'failed', format: numberWithCommas },
          { key: 'passed_percent', format: (e) => `${e}%` },
          { key: 'reject_percent', format: (e) => `${e}%` }
        ]"
        :data="table_data"
      />
    </div>

    <div class="footer">
      <div class="csv_button_container">
        <v-btn
          color="primary"
          @click="createCSV"
        >
          <div class="buttoncontent">
            <i class="fa fa-download" />
            <span>
              {{ $t("CSV") }}
            </span>
          </div>
        </v-btn>
      </div>
      <div class="paginator_container">
        <div class="paginator">
          <div class="selector">
            <div class="rows-title">{{ $t("Rows per page") }}</div>
            <select @change="handleRequestCountChange">
              <option
                :selected="count === 10"
                value="10"
              >
                10
              </option>
              <option
                :selected="count === 50"
                value="50"
              >
                50
              </option>
              <option
                :selected="count === 100"
                value="100"
              >
                100
              </option>
            </select>
          </div>

          <div class="range-indicator">
            <div>
              <span>
                {{ currentStart !== null ? currentStart + 1 : "---" }}
              </span>
              <span class="divider"> - </span>
              <span>
                {{
                  currentStart !== null ? Math.min(currentStart + Number(count), totalcount) : "---"
                }}
              </span>
              <span class="divider"> of {{ totalcount || "---" }} </span>
            </div>
          </div>

          <div class="back-next-select">
            <i
              class="fa fa-chevron-left"
              v-bind:class="`${this.currentStart <= 0 && 'disabled'}`"
              @click="
                () => {
                  this.handlePage(-1, this.currentStart <= 0);
                }
              "
            />
            <i
              class="fa fa-chevron-right"
              v-bind:class="`${this.currentStart + Number(count) >= totalcount && 'disabled'}`"
              @click="
                () => {
                  this.handlePage(1, this.currentStart + Number(count) >= totalcount);
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import dbCache from "@/store/modules/dbCache";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { numberWithCommas } from "@/utils/filters";

import GenericTable from "../../../components/GenericTable";

export default {
  name: "AiVisionAnalyticsDataGrid",
  components: { GenericTable },
  computed: {
    ...mapGetters({
      partFromPk: "dbCache/partFromPk",
      assetFromPK: "dbCache/assetFromPk"
    })
  },
  data() {
    return {
      table_data: [],
      unsliced_data: [],
      count: 10,
      currentStart: 0,
      totalcount: 0
    };
  },
  props: {
    filters: Object
  },
  mounted() {
    this.loadDataTable().then(() => {
      this.sliceData();
    });
  },
  methods: {
    numberWithCommas,
    createCSVFromRows,
    createCSV() {
      const rows = [];
      rows.push([
        this.$t("Equipment"),
        this.$t("Part Number"),
        this.$t("Total Processed"),
        this.$t("Passed"),
        this.$t("Failed"),
        this.$t("FPY(%)"),
        this.$t("Reject %")
      ]);

      for (const equipment of this.unsliced_data) {
        const equipmentName = equipment.equipment;
        for (const nested of equipment._nested) {
          rows.push([
            equipmentName,
            nested.part_name,
            nested.total_processed,
            nested.passed,
            nested.failed,
            nested.passed_percent,
            nested.reject_percent
          ]);
        }
      }

      createCSVFromRows(rows, `aivision_${new Date().toISOString()}`);
    },
    handlePage(direction, disabled) {
      if (!disabled) {
        this.currentStart += this.count * direction;
        this.sliceData();
      }
    },
    handleRequestCountChange(e) {
      this.currentStart = 0;
      this.count = parseInt(e.target.value);
      this.sliceData();
    },
    sliceData() {
      this.table_data = this.unsliced_data.slice(this.currentStart, this.currentStart + this.count);
    },
    loadDataTable: function () {
      return new Promise((resolve, reject) => {
        const filters = {
          asset_id: this.filters.asset && this.filters.asset.asset_id,
          machine_id: this.filters.machine && this.filters.machine.machine_id,
          part_name: this.filters.part && this.filters.part.part_name,
          from_date: this.filters.from_date,
          to_date: this.filters.to_date,
          process_type: this.filters.process_types,
          inspection_result: this.filters.inspection_result,
          scale: this.filters.scale || this.defaultScale
        };

        this.$http.get("aivision/resultByAssetMachine/", { params: filters }).then((result) => {
          this.unsliced_data = [];
          for (const bucket of result.data.buckets) {
            let passedCount = 0;

            for (const i_r of bucket.inspection_result.buckets) {
              if (i_r.key.toLowerCase() === "pass") {
                passedCount = i_r.count.value;
              }
            }

            const passedPercent =
              (bucket.count.value > 0 ? passedCount / bucket.count.value : 1) * 100;
            let partName = "";
            if (bucket.part && bucket.part.buckets.length > 0) {
              if (bucket.part.buckets.length === 1) {
                partName = bucket.part.buckets[0].key;
              } else {
                partName = `${bucket.part.buckets[0].key} +${bucket.part.buckets.length - 1} more`;
              }
            }
            const equipment = {
              equipment: this.assetFromPK(Number(bucket.key)).name,
              part_name: partName,
              total_processed: bucket.count.value,
              passed: passedCount,
              failed: bucket.count.value - passedCount,
              passed_percent: passedPercent.toFixed(2),
              reject_percent: (100 - passedPercent).toFixed(2),
              _nested: []
            };

            for (const part of bucket.part.buckets) {
              let passedPartCount = 0;
              for (const i_r of part.inspection_result.buckets) {
                if (i_r.key.toLowerCase() === "pass") {
                  passedPartCount = i_r.count.value;
                }
              }

              const partPassedPercent =
                (part.count.value > 0 ? passedPartCount / part.count.value : 1) * 100;
              equipment._nested.push({
                part_name: part.key,
                total_processed: part.count.value,
                passed: passedPartCount,
                failed: part.count.value - passedPartCount,
                passed_percent: partPassedPercent.toFixed(2),
                reject_percent: (100 - partPassedPercent).toFixed(2)
              });
            }
            this.unsliced_data.push(equipment);
            this.totalcount = this.unsliced_data.length;
            resolve();
          }
        });
      });
    }
  },
  watch: {
    filters: function () {
      this.loadDataTable().then(() => {
        this.sliceData();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.aivision_datagrid {
  flex: 1;
  display: flex;
  flex-direction: column;

  .datagrid {
    flex: 1;
  }
  .selector {
    height: 40px;
    flex: unset;
  }

  .footer {
    flex: unset;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 5px 20px;

    .buttoncontent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        padding-right: 10px;
      }
    }

    .paginator {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      flex-direction: row;
      flex: unset;
      width: 400px;
      height: 30px;
      align-items: center;

      .selector {
        display: flex;
        align-items: center;

        .rows-title {
          padding-right: 5px;
        }

        select {
          color: white;
          text-decoration: underline;

          .theme--light & {
            color: black;
          }

          option {
            color: black;
          }
        }
      }

      .range-indicator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;

        .divider {
          padding: 0 5px;
        }
      }

      .back-next-select {
        display: flex;
        flex: unset;
        min-width: 60px;
        justify-content: space-between;
        align-items: center;

        i {
          cursor: pointer;

          &.disabled {
            opacity: 50%;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
