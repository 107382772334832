import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  machines: [],
  machineCodes: [],
  parts: [],
  assets: [],
  machineGroupTree: {},
  machineGroups: [],
  shifts: []
};

const mutations = {
  "dbCache/SET-MACHINES"(state, machines) {
    state.machines = machines;
  },
  "dbCache/SET-MACHINES-CODES"(state, machineCodes) {
    state.machineCodes = machineCodes;
  },
  "dbCache/SET-MACHINE-GROUPS"(state, machineGroups) {
    state.machineGroups = machineGroups;
  },
  "dbCache/SET-SHIFTS"(state, shifts) {
    state.shifts = shifts;
  },
  "dbCache/SET-PARTS"(state, parts) {
    state.parts = parts;
  },
  "dbCache/SET-ASSETS"(state, assets) {
    state.assets = assets;
  }
};

const actions = {
  "dbCache/initializeCache"({ commit }) {
    const query = `{
            shifts{
                name
                id
                pk
                color
            }
            machines{
                id
                pk
                name
            }
            parts{
                id
                pk
                name
            }
            assets {
                id
                pk
                name
            }
            machineGroups {
                id,
                pk,
                name,
                parentGroup {
                    id,
                    name,
                    pk,
                },
                level {
                    pk,
                    level
                },
                allMachines {
                    id,
                    pk,
                    name,
                }
                allSubGroups {
                    id,
                    pk,
                    name,
                }
            }
        }`;
    return Vue.http.get("graphql/", { params: { query } }).then((res) => {
      const mgMap = {};
      for (const machineGroup of res.data.data.machineGroups) {
        mgMap[machineGroup.pk] = machineGroup;
      }
      for (const machineGroup of res.data.data.machineGroups) {
        machineGroup.parent = machineGroup.parentGroup ? mgMap[machineGroup.parentGroup.pk] : null;
      }

      for (const machineGroup of res.data.data.machineGroups) {
        if (machineGroup.allParentGroups == null) {
          machineGroup.allParentGroups = [];
        }

        let mg = machineGroup;
        while (mg) {
          if (mg.allChildGroups == null) {
            mg.allChildGroups = [];
          }
          if (mg.pk !== machineGroup.pk) {
            mg.allChildGroups.push(machineGroup.pk);
          }

          if (mg.parent) {
            machineGroup.allParentGroups.push(mg.parent.pk);
          }
          mg = mg.parent;
        }
      }

      let machines = res.data.data.machines;
      commit("dbCache/SET-MACHINES", machines);
      commit("dbCache/SET-MACHINE-GROUPS", res.data.data.machineGroups);
      commit("dbCache/SET-SHIFTS", res.data.data.shifts);
      commit("dbCache/SET-PARTS", res.data.data.parts);
      commit("dbCache/SET-ASSETS", res.data.data.assets);
    });
  }
};

const getters = {
  "dbCache/parts"(state) {
    return state.parts;
  },
  "dbCache/machines"(state) {
    return state.machines;
  },
  "dbCache/machineCodes"(state) {
    return state.machineCodes;
  },
  "dbCache/machineGroups"(state) {
    return state.machineGroups;
  },
  "dbCache/shifts"(state) {
    return state.shifts;
  },
  "dbCache/partFromPk": (state) => (pk) => {
    const part = state.parts.find((part) => part.pk === pk);
    if (part) {
      return part;
    } else {
      return {
        id: false,
        pk: -1,
        name: "Unknown Part " + String(pk)
      };
    }
  },
  "dbCache/assetFromPk": (state) => (pk) => {
    const asset = state.assets.find((asset) => asset.pk === pk);
    if (asset) {
      return asset;
    } else {
      return {
        id: false,
        pk: -1,
        name: "Unknown Asset " + String(pk)
      };
    }
  },
  "dbCache/machineGroupFromId": (state) => (id) => {
    return state.machineGroups.find((group) => group.pk === id);
  },
  "dbCache/machineFromId": (state) => (id) => {
    const machine = state.machines.find((machine) => machine.id === id);
    if (machine) {
      return machine;
    } else {
      return {
        id: false,
        pk: -1,
        name: "Unknown Machine " + String(id)
      };
    }
  },
  "dbCache/machineFromPk": (state) => (pk) => {
    const machine = state.machines.find((machine) => machine.pk === pk);
    if (machine) {
      return machine;
    } else {
      return {
        id: false,
        pk: -1,
        name: "Unknown Machine " + String(pk)
      };
    }
  },
  "dbCache/machineFromName": (state) => (name) => {
    const machine = state.machines.find((machine) => machine.name === name);
    if (machine) {
      return machine;
    } else {
      return {
        id: false,
        pk: -1,
        name: "Unknown Machine " + String(name)
      };
    }
  },
  "dbCache/shiftFromPk": (state) => (pk) => {
    const shift = state.shifts.find((shift) => shift.pk == pk);
    if (shift) {
      return shift;
    } else {
      return {
        id: false,
        color: "white",
        pk: -1,
        name: `${Vue.i18n.translate("Unknown Shift")} ${String(pk)}`
      };
    }
  },
  "dbCache/machinesFromMachineGroup": (state) => (groupId) => {
    let allMachines = [];
    let visitedGroups = new Set();

    const fetchMachines = (id) => {
      if (visitedGroups.has(id)) {
        return;
      }

      visitedGroups.add(id);

      // Find the group with the given ID
      const group = state.machineGroups.find((group) => group.pk === id);
      if (group) {
        // Add all machines of the current group
        allMachines = allMachines.concat(group.allMachines);
        // Recurse for each child group
        group.allChildGroups.forEach((childGroupId) => {
          fetchMachines(childGroupId);
        });
      }
    };

    // Start recursion with the root group
    fetchMachines(groupId);

    return allMachines;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
