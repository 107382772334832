<template>
  <div class="root">
    <div
      v-if="loading"
      class="preloader"
    >
      <PreloaderPanel />
    </div>

    <BarChart
      v-if="!loading && chartDataFormatted.datasets.length > 0"
      style="height: 100%"
      class="weekly-chart"
      :chart-data="chartDataFormatted"
      :axis-type="'shorten'"
      :commas="true"
    />
  </div>
</template>

<script>
import BarChart from "../../../components/charts/BarChart";
import { round } from "@amcharts/amcharts5/.internal/core/util/Math";
import PreloaderPanel from "@/ui/PreloaderPanel/PreloaderPanel.vue";
import moment from "moment-timezone";
import { computed, toRefs } from "@vue/composition-api";
import { useZoneControlWeeklyProduction } from "@/features/zone-control-weekly/useZoneControlWeeklyProduction";
import { useIntl } from "@/shared/useIntl";

export default {
  name: "ZoneControlWeeklyChart",
  components: {
    PreloaderPanel,
    BarChart
  },
  props: {
    machine_group_id: {
      type: String,
      required: true
    },
    machine_group_pk: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },

  setup(props) {
    const { machine_group_pk, startDate, endDate } = toRefs(props);
    const { $t } = useIntl();

    const paramsApi = computed(() => ({
      from_date: moment(startDate.value).startOf("week").toISOString(),
      to_date: moment(endDate.value).endOf("week").toISOString(),
      machine_group_id: machine_group_pk.value,
      children: true
    }));

    const { data, isFetching } = useZoneControlWeeklyProduction(paramsApi);

    const chartDataFormatted = computed(() => {
      if (!data.value) {
        return null;
      }

      let subgroups = [];

      data.value.groups.forEach((item, index) => {
        if (index === 0) {
          return;
        }

        subgroups.push({
          id: item.id,
          name: item.name,
          pk: item.pk,
          produced: item.total.actual,
          productionTarget2: item.total.target
        });
      });

      return {
        labels: subgroups.map((subgroup) => subgroup.name),
        datasets: [
          {
            label: $t("Production Target"),
            data: subgroups.map((subgroup) => round(subgroup.productionTarget2)),
            type: "scatter",
            pointRadius: 5,
            fill: false,
            borderColor: "#ffffff",
            pointBackgroundColor: "#ffffff"
          },
          {
            label: $t("Produced"),
            data: subgroups.map((subgroup) => subgroup.produced),
            backgroundColor: subgroups.map((subgroup) => {
              return subgroup.produced < subgroup.productionTarget2 ? "#EA4D55" : "#00BC6C";
            }),
            borderColor: subgroups.map((subgroup) => {
              return subgroup.produced < subgroup.productionTarget2 ? "#EA4D55" : "#00BC6C";
            }),
            borderWidth: 1
          }
        ]
      };
    });

    return {
      chartDataFormatted,
      loading: isFetching
    };
  }
};
</script>

<style scoped lang="scss">
.root {
  display: flex;
  flex-direction: column;
  height: 421px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.preloader {
  height: 421px;
}
</style>
