<template>
  <Production
    class="auto-performance"
    :production="production"
    :options="chartOptions"
  />
</template>

<script>
import moment from "moment-timezone";

import { chartOptions } from "@/ui/charts/old/chartOptions";
import Production from "./metrics/Production.vue";
import { SCALE_TAB_VALUE } from "./zone_control_enum";

export default {
  components: {
    Production
  },
  props: {
    metric: {
      type: Object,
      default: undefined
    },
    timeScale: {
      type: Number,
      default: undefined
    },
    machine_group_pk: {
      type: [String, Number],
      default: undefined
    }
  },
  data() {
    return {
      chartOptions: new chartOptions(),
      production: null
    };
  },
  computed: {},
  watch: {
    timeScale: function () {
      this.production = null;
      this.getChartData();
    }
  },
  created() {
    this.getChartData();
  },
  methods: {
    getChartData() {
      switch (this.timeScale) {
        case SCALE_TAB_VALUE.WEEK: {
          this.chartOptions.from_date = moment().startOf("week");
          this.chartOptions.to_date = moment().endOf("week");
          this.chartOptions.scale = "day";
          break;
        }
        case SCALE_TAB_VALUE.MONTH: {
          this.chartOptions.from_date = moment().startOf("month");
          this.chartOptions.to_date = moment().endOf("month");
          this.chartOptions.scale = "day";
          break;
        }
        case SCALE_TAB_VALUE.YEAR: {
          this.chartOptions.from_date = moment().startOf("year");
          this.chartOptions.to_date = moment().endOf("year");
          this.chartOptions.scale = "month";
          break;
        }
        default: {
          // handles cases when timeScale is in the form 'start_date_as_ISO_string end_date_as_ISO_string'
          let dates = this.timeScale.split(" ");
          this.chartOptions.from_date = moment(dates[0]);
          this.chartOptions.to_date = moment(dates[1]);
          this.chartOptions.scale = dates[2];
          break;
        }
      }

      this.$emit("editShift", this.chartOptions.from_date, this.chartOptions.to_date);
      this.chartOptions.machine_group_id = this.machine_group_pk;
      this.chartOptions.dateFormat = "DD MMM";
      this.chartOptions.targets.perf = this.metric.node.currentValue.value.replace(/\D/g, "");

      this.chartOptions.settings = {
        scales: {
          yAxes: [{ zeroLineColor: "#FFF", display: true }],
          xAxes: [{ ticks: { minRotation: 90, maxRotation: 90 } }]
        }
      };

      this.loadProduction();
    },
    loadProduction() {
      let params = this.chartOptions.params;

      this.$http.get("metrics/production_per_interval/", { params: params }).then((res) => {
        this.production = res.data;
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/scss/variables";

.auto-performance {
  .bar-container {
    flex: 1;
    height: 250px;

    .productionChart {
      height: 250px !important;
    }
  }
}
</style>
