<template>
  <div class="rejectSummaryTable">
    <div class="tableContainer">
      <generic-table
        :columns="[
          {
            title: $t('Failure Mode'),
            key: 'failure_mode',
            sortable: true,
            sort_method: handleSort
          },
          { title: $t('Machine'), key: 'machine_name', sortable: true, sort_method: handleSort },
          { title: $t('Part Number'), key: 'part_name', sortable: true, sort_method: handleSort },
          { title: $t('Quantity'), key: 'quantity', sortable: true, sort_method: handleSort },
          {
            title: $t('Defect (%)'),
            key: 'percent',
            format: (value) => `${value}%`,
            sortable: true,
            sort_method: handleSort
          },
          {
            title: $t(''),
            key: 'chartdata',
            html: 'image_link',
            click: addOrRemoveChart,
            selected: {
              data: chartsClickedMap,
              keys: ['machine_id', 'part_id', 'failure_mode'],
              color: selectColor
            }
          }
        ]"
        :data="table_data"
      />
    </div>
    <div class="footer">
      <div class="paginator">
        <div class="selector">
          <div class="rows-title">{{ $t("Rows per page") }}</div>
          <select @change="handleRequestCountChange">
            <option
              :selected="count === 10"
              value="10"
            >
              10
            </option>
            <option
              :selected="count === 50"
              value="50"
            >
              50
            </option>
            <option
              :selected="count === 100"
              value="100"
            >
              100
            </option>
          </select>
        </div>

        <div class="range-indicator">
          <div>
            <span>
              {{ currentStart !== null ? currentStart + 1 : "---" }}
            </span>
            <span class="divider"> - </span>
            <span>
              {{
                currentStart !== null ? Math.min(currentStart + Number(count), totalcount) : "---"
              }}
            </span>
            <span class="divider"> of {{ totalcount || "---" }} </span>
          </div>
        </div>

        <div class="back-next-select">
          <i
            class="fa fa-chevron-left"
            v-bind:class="`${this.currentStart <= 0 && 'disabled'}`"
            @click="
              () => {
                this.handlePage(-1, this.currentStart <= 0);
              }
            "
          />
          <i
            class="fa fa-chevron-right"
            v-bind:class="`${this.currentStart + Number(count) >= totalcount && 'disabled'}`"
            @click="
              () => {
                this.handlePage(1, this.currentStart + Number(count) >= totalcount);
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import dbCache from "@/store/modules/dbCache";

import GenericTable from "../../../components/GenericTable";

export default {
  name: "AIVisionRejectSummaryTable",
  components: { GenericTable },
  data: function () {
    return {
      unsliced_data: [],
      table_data: [],
      currentStart: 0,
      count: 10,
      totalcount: 0,
      chartsClickedMap: {},
      selectColor: this.$colors.blue
    };
  },
  computed: {
    ...mapGetters({
      machineFromPk: "dbCache/machineFromPk"
    })
  },
  props: {
    data: Array,
    addChart: Function,
    removeChart: Function
  },
  mounted() {
    this.setupData();
    this.sliceData();
  },
  methods: {
    addOrRemoveChart(object) {
      const machine_id = object.machine_id;
      const part_id = object.part_id;
      const failure_mode = object.failure_mode;

      if (!this.chartsClickedMap[machine_id]) {
        this.chartsClickedMap[machine_id] = {};
      }

      if (!this.chartsClickedMap[machine_id][part_id]) {
        this.chartsClickedMap[machine_id][part_id] = {};
      }

      if (this.chartsClickedMap[machine_id][part_id][failure_mode] != null) {
        delete this.chartsClickedMap[machine_id][part_id][failure_mode];
        this.removeChart(object);
      } else {
        this.chartsClickedMap[machine_id][part_id][failure_mode] = object;
        this.addChart(object);
      }

      if (
        this.chartsClickedMap[machine_id][part_id] &&
        Object.keys(this.chartsClickedMap[machine_id][part_id]).length === 0
      ) {
        delete this.chartsClickedMap[machine_id][part_id];
      }

      if (this.chartsClickedMap[machine_id].length === 0) {
        delete this.chartsClickedMap[machine_id];
      }

      this.chartsClickedMap = { ...this.chartsClickedMap };
    },
    handleSort(sortKey, direction) {
      this.setupData();
      this.unsliced_data.sort((a, b) => {
        if (typeof a[sortKey] === "string" && typeof b[sortKey] === "string") {
          return a[sortKey].localeCompare(b[sortKey], undefined, { numeric: true }) * direction;
        }
        return (a[sortKey] - b[sortKey]) * direction;
      });
      this.sliceData();
    },
    handlePage(direction, disabled) {
      if (!disabled) {
        this.currentStart += this.count * direction;
        this.sliceData();
      }
    },
    handleRequestCountChange(e) {
      this.currentStart = 0;
      this.count = parseInt(e.target.value);
      this.sliceData();
    },
    sliceData() {
      this.table_data = this.unsliced_data.slice(this.currentStart, this.currentStart + this.count);
    },
    setupData() {
      this.unsliced_data = [];
      for (const machine of this.data) {
        const machine_id = machine.key;
        const machine_name = this.machineFromPk(Number(machine_id)).name;

        for (const part of machine.part.buckets) {
          const part_name = part.key;
          const part_id = part.last_record.hits.hits[0]._source.part_id;

          let totalFail = 0;
          let totalPass = 0;
          let total = part.count.value;

          for (const inspection_result of part.inspection_result.buckets) {
            if (inspection_result.key.toLowerCase() === "pass") {
              totalPass = inspection_result.count.value;
            }

            for (const failure_mode of inspection_result.failure_mode.buckets) {
              //in case the failure_mode has a field (like NA or similar) we don't want to add this to
              //the list of failure modes
              totalFail = failure_mode.count.value;

              const failure_mode_text = failure_mode.key;
              if (inspection_result.key.toLowerCase() === "fail") {
                this.unsliced_data.push({
                  failure_mode: failure_mode_text,
                  quantity: failure_mode.count.value,
                  machine_name: machine_name,
                  machine_id: machine_id,
                  part_id: part_id,
                  part_name: part_name,
                  totalFail: totalFail,
                  totalPass: totalPass,
                  total: total,
                  percent: total > 0 ? ((totalFail / total) * 100).toFixed(2) : 0,
                  image_link: `<i class="fa fa-line-chart" />`,
                  chartdata: {
                    machine_id: machine_id,
                    machine_name: machine_name,
                    part_name: part_name,
                    part_id: part_id,
                    failure_mode: failure_mode_text
                  }
                });
              }
            }
          }
        }
      }

      this.totalcount = this.unsliced_data.length;
    }
  },
  watch: {
    data: function () {
      this.setupData();
      this.sliceData();
    }
  }
};
</script>

<style scoped lang="scss">
.rejectSummaryTable {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .tableContainer {
    flex: 1;
    display: flex;
    overflow: hidden;

    i {
      cursor: pointer;
    }
  }

  .footer {
    flex: unset;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding: 5px 20px;

    .buttoncontent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        padding-right: 10px;
      }
    }

    .paginator {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      flex-direction: row;
      flex: unset;
      width: 400px;
      height: 30px;
      align-items: center;

      .selector {
        display: flex;
        align-items: center;

        .rows-title {
          padding-right: 5px;
        }

        select {
          color: white;
          text-decoration: underline;

          .theme--light & {
            color: black;
          }

          option {
            color: black;
          }
        }
      }

      .range-indicator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;

        .divider {
          padding: 0 5px;
        }
      }

      .back-next-select {
        display: flex;
        flex: unset;
        min-width: 60px;
        justify-content: space-between;
        align-items: center;

        i {
          cursor: pointer;

          &.disabled {
            opacity: 50%;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
