<template>
  <div class="downtime-duration-by-machine">
    <div class="card">
      <div class="card-header">
        <h4>{{ $t("Downtime Duration (hours)") }}</h4>
      </div>
      <div class="card-content">
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
        <BarChart
          v-if="!loading"
          class="chart-container"
          :chart-data="chartData"
          :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
          commas="true"
        />
        <div class="footer">
          <bar-chart-paginator
            v-if="!loading"
            :refresh="filterController.reload"
            :rawData="responseData"
            :sortMethod="sortFunc"
            default-sort="desc"
            :buildChart="formatData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { coralReef } from "@/scss/_variables.scss";
import { getEndTime, getStartTime, handleBackgroundColorOpacity } from "@/utils/level2Utils";

import BarChartPaginator from "../../../../components/BarChartPaginator.vue";
import BarChart from "../../../../components/charts/BarChart";
import { seconds } from "../../../../utils/filters";

export default {
  name: "DowntimeDurationByMachine",
  props: ["filterController", "interactiveFilters", "setSelectedMachines"],
  created() {
    this.loadData();
  },
  components: {
    BarChart,
    BarChartPaginator
  },
  data() {
    return {
      loading: true,
      responseData: [],
      chartData: {},
      chartIds: [],
      machines: []
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk",
      machineCodes: "dbCache/machineCodes"
    })
  },
  methods: {
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      this.setSelectedMachines(this.machines[elementIndex].key);
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilter(elementIndex) {
      const machineId = this.machines[elementIndex].key;
      const foundMachineIndex = this.interactiveFilters.machines.findIndex(
        (machine) => machine.id === machineId
      );
      if (foundMachineIndex >= 0) {
        this.interactiveFilters.machines.splice(foundMachineIndex, 1);
      } else {
        this.interactiveFilters.machines.push({ id: machineId, index: elementIndex });
      }
    },
    updateBackgroundColors() {
      this.chartData.datasets[0].backgroundColor = handleBackgroundColorOpacity(
        this.slicedData,
        this.chartData,
        this.interactiveFilters
      );
      this.chartData = { ...this.chartData };
    },
    async loadData() {
      this.loading = true;
      const params = {
        from_date: this.filterController.initialFilters.date_range.from_date,
        to_date: this.filterController.initialFilters.date_range.to_date
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      if (this.filterController.hasFilter("machines")) {
        params["machine_ids"] = this.filterController.getFilterPks("machines");
      }
      if (this.filterController.hasFilter("machine_groups")) {
        params["machine_group_ids"] = this.filterController.getFilterPks("machine_groups");
      }
      if (this.interactiveFilters.codes.length > 0) {
        params.codes = this.interactiveFilters.codes.map((code) => code.id);
      }
      if (this.interactiveFilters.trends.length > 0) {
        const trendKeys = this.interactiveFilters.trends.map((trend) => trend.id);
        params.from_date = getStartTime(
          Math.min(...trendKeys),
          this.filterController.initialFilters.scale
        );
        params.to_date = getEndTime(
          Math.max(...trendKeys),
          this.filterController.initialFilters.scale
        );
      }
      if (this.filterController.initialFilters.codeEventType) {
        params.event_type = this.filterController.initialFilters.codeEventType;
      }
      try {
        const response = await this.$http.get("level2/duration_by_machine/", { params });
        if (response && response.data) {
          this.responseData = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    formatData(slicedData) {
      this.chartData = {};
      this.chartIds = [];

      this.chartData.datasets = [
        {
          data: [],
          label: [],
          tooltips: [],
          backgroundColor: []
        }
      ];

      this.chartData.title = "durationByMachine";
      this.chartData.labels = [];
      this.chartData.titles = [];

      let index = 0;
      for (const s of slicedData || []) {
        this.chartData.datasets[0].data.push((s.downtime.value / 3600).toFixed(1));
        this.chartData.datasets[0].tooltips.push(seconds(s.downtime.value));
        this.chartData.datasets[0].label = this.$t("Downtime Duration (hours)");
        let name = this.machineLookup(s.key).name;
        this.chartData.labels.push(name);
        this.chartData.labels.titles = name;
        let color = coralReef;
        this.chartData.datasets[0].backgroundColor.push([color, color]);
        index++;
        this.chartIds.push(s.id);
      }
      let colors = this.chartData.datasets[0].data.map((item) => "rgba(199, 78, 78, .9)");
      this.chartData.datasets[0].backgroundColor = colors;
      this.slicedData = slicedData;
      this.machines = [...slicedData];
      this.updateBackgroundColors();
    },
    sortFunc(rawData, sortOrder) {
      rawData.sort((a, b) => {
        const aVal = a.downtime.value;
        const bVal = b.downtime.value;
        if (sortOrder == "desc") {
          return bVal - aVal;
        } else {
          return aVal - bVal;
        }
      });
      return rawData;
    }
  },
  watch: {
    "filterController.reload": function () {
      this.loadData();
    },
    "interactiveFilters.codes.length": function () {
      if (!this.loading) {
        this.loadData();
      }
    },
    "interactiveFilters.trends": function () {
      if (!this.loading) {
        this.loadData();
      }
    },
    "interactiveFilters.machines.length": function () {
      if (!this.loading) {
        this.updateBackgroundColors();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.downtime-duration-by-machine {
  height: 100%;
  .card {
    height: 100%;
  }
}
</style>
