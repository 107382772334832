import { AppRoutes } from "@/shared/app-routes";
import Vue from "vue";

export const ticketsGroupRoutes = [
  {
    name: AppRoutes.groupTicketsNew.name,
    path: AppRoutes.groupTicketsNew.url(),
    redirect: () => ({ name: AppRoutes.groupTicketsP911.name }),
    props: true,
    displayName: false
  },

  {
    name: AppRoutes.groupTicketsP911.name,
    path: AppRoutes.groupTicketsP911.url(),
    component: Vue.component(
      AppRoutes.groupTicketsP911.name,
      () => import("./TicketsGroup911.vue")
    ),
    props: true,
    displayName: false
  },

  {
    name: AppRoutes.groupTicketsLdms.name,
    path: AppRoutes.groupTicketsLdms.url(),
    component: Vue.component(
      AppRoutes.groupTicketsLdms.name,
      () => import("./TicketsGroupLdms.vue")
    ),
    props: true,
    displayName: false
  }
];
