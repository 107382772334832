<template>
  <div :class="['preloader', { blur }]">
    <v-progress-circular
      indeterminate
      class="icon"
      :width="3"
      :size="72"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "PreloaderPanel",
  props: {
    blur: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.preloader {
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;
  flex: 1;
  min-height: 100%;
}
.icon {
  color: rgb(244, 244, 244);
  caret-color: rgb(244, 244, 244);
}

.theme--light .icon {
  color: rgb(0, 0, 0);
  caret-color: rgb(0, 0, 0);
}
.blur {
  backdrop-filter: blur(1px);
}
</style>
