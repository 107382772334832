<template>
  <div class="machine-metric-heatmap">
    <v-btn-toggle
      class="toggleBtns"
      group
      v-model="toggle"
      color="primary"
    >
      <v-btn value="group">
        {{ $t("Group") }}
      </v-btn>
      <v-btn value="machine">
        {{ $t("Machines") }}
      </v-btn>
    </v-btn-toggle>

    <v-tabs
      right
      class="smallPageTabs"
      v-model="activeTab"
      @change="
        (index) => {
          if (index == 0) {
            this.toggle = 'group';
          } else {
            this.toggle = 'machine';
          }
        }
      "
    >
      <v-tab class="smalltab">{{ $t("Group") }}</v-tab>
      <v-tab class="smalltab">{{ $t("Machines") }}</v-tab>
    </v-tabs>

    <div class="bc-row">
      <v-icon
        v-if="groupSelections.length > 0"
        @click="
          groupSelections = [];
          loadHeatmapData();
        "
        >fa-times</v-icon
      >
      <v-breadcrumbs :items="groupSelections">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item @click="drillDown(item)">
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <base-heatmap
      :xCoords="xCoords"
      :yCoords="sortedY"
      v-if="dataFormatted && !selectedMetric"
    >
      <template v-slot:xLabels="props">
        <v-tooltip
          top
          content-class="machine-heatmap-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="mapLabel"
              @click="updateSort(props.x)"
              v-bind="attrs"
              v-on="on"
            >
              {{ props.x.label }}
              <i
                class="fa fa-arrow-up"
                aria-hidden="true"
                v-if="sortBy == props.x.key && sortDir == 'DESC'"
              ></i>
              <i
                class="fa fa-arrow-down"
                aria-hidden="true"
                v-if="sortBy == props.x.key && sortDir == 'ASC'"
              ></i>
            </div>
          </template>
          <span>
            {{ props.x.label }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:yLabels="props">
        <v-tooltip
          bottom
          content-class="machine-heatmap-tooltip"
          >>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <p
                v-if="objs[props.y].machine_group"
                @click="drillDown(objs[props.y])"
              >
                {{ objs[props.y].name }}
              </p>
              <router-link
                v-else
                class="machine-link"
                :to="{
                  name: 'machine',
                  params: {
                    machine_id: machines[props.y].id,
                    machine_pk: machines[props.y].pk
                  }
                }"
              >
                {{ machines[props.y].name }}
              </router-link>
            </span>
          </template>
          <span>
            <span v-if="objs[props.y].machine_group">
              {{ objs[props.y].name }}
            </span>
            <span v-else>
              {{ machines[props.y].name }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:cells="props">
        <transition name="fade">
          <div
            class="cell"
            :class="objs[props.y][props.x.key].cls"
            @click="selectMetric(objs[props.y], props.x.key)"
          >
            {{
              objs[props.y][props.x.key].value
                ? objs[props.y][props.x.key].value.toFixed(0)
                : $t("No Data")
            }}
          </div>
        </transition>
      </template>
    </base-heatmap>
    <div
      class="chart-container"
      v-if="selectedMetric"
    >
      <h3>
        {{
          toggle == "machine"
            ? machines[selectedMetric.machine.key].name
            : selectedMetric.machine.name
        }}
      </h3>
      <div class="chart-content">
        <div
          class="back"
          @click="clearSelected"
          v-if="selectedMetric"
        >
          <p><i class="fa fa-chevron-left" />{{ $t("Back") }}</p>
        </div>
        <button
          class="btn radioBtn"
          v-for="(btn, btnIndex) in intervals"
          :key="btn.value"
          :class="{ active: selectedMetric['options'].preset == btn.value }"
          @click="presetClick(btn.value)"
        >
          {{ btn.name }}
        </button>
      </div>
      <v-divider class="mt-2"></v-divider>
      <oee
        v-if="selectedMetric.metric == 'oeeVar' && selectedMetric.options"
        :options="selectedMetric.options"
        :production="production"
      />
      <production
        v-if="selectedMetric.metric == 'performanceVar' && selectedMetric.options"
        :options="selectedMetric.options"
        :production="production"
      />
      <quality
        v-if="selectedMetric.metric == 'qualityVar' && selectedMetric.options"
        :options="selectedMetric.options"
        :production="production"
      />
      <availability
        v-if="selectedMetric.metric == 'availabilityVar' && selectedMetric.options"
        :options="selectedMetric.options"
        :production="production"
      />
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

@media screen and(max-width: 1200px) {
  .machine-heatmap-tooltip.v-tooltip__content {
    display: none;
  }
}

.machine-metric-heatmap {
  height: 100%;

  .mobile-chart-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: row-reverse;
  }

  .charts_container {
    width: 100% !important;
    padding: 0;
  }

  .bar-container {
    width: 100% !important;
  }

  .data-row {
    .mapLabel {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media screen and(max-width: 1200px) {
        font-size: 12px;
      }
    }
  }
  .heatmap-column {
    &.label {
      @media screen and(max-width: 1200px) {
        p {
          font-size: 12px;
        }
      }

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .machine-link {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
  .heatmap {
    height: calc(100% - 2rem);

    @media screen and (max-width: 1200px) {
      margin-top: 0px !important;
    }
  }
  .mapLabel {
    cursor: pointer;
    i {
      color: $blue;
    }
  }
  .bc-row {
    display: flex;
    .v-icon {
      margin: 0.25rem 0.5rem;
    }
  }
  .toggleBtns {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    z-index: 99999;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .v-breadcrumbs {
    padding: 0.25rem;
    .v-breadcrumbs__item {
      cursor: pointer;
    }
  }
  .machine-link {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
  .cell {
    height: 100%;
    width: 100%;
    border-radius: 0.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    cursor: pointer;
    &.group0 {
      animation: fadeIn 1s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
      background-color: #2e2b35;
      color: white;
    }
    &.group1 {
      animation: fadeIn 1s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
      background-color: #7bc57d;
    }
    &.group2 {
      animation: fadeIn 1s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;
      background-color: #b9d780;
    }
    &.group3 {
      animation: fadeIn 0.75s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 0.3s;
      background-color: #feec84;
    }
    &.group4 {
      animation: fadeIn 0.5s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 0.4s;
      background-color: #fba075;
    }
    &.group5 {
      animation: fadeIn 0.35s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
      background-color: #f46c6a;
    }
  }
  .chart-container {
    height: 100%;

    .mobile-gauge-container {
      display: none !important;
    }

    @media screen and(max-width: 1200px) {
      display: flex;
      position: relative;
      flex-flow: column;

      .mobile-gauge-container {
        display: flex !important;
        color: black;

        .gaugeValue {
          font-size: 16px;
        }

        canvas {
          scale: 125%;
        }
      }

      .gauge-container {
        display: none !important;
      }

      h3 {
        position: relative !important;
        right: 0 !important;
        top: 0 !important;
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }

    h3 {
      position: absolute;
      margin-right: 0.65rem;
      right: 15rem;
      top: 1rem;
      color: $blue;
      font-weight: 450;
    }
    .chart-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .back {
        cursor: pointer;
        .fa-chevron-left {
          margin-right: 0.5rem;
          padding-bottom: 0.75rem;
        }
      }
      .btn {
        padding-bottom: 0.65rem;
      }
    }
    h2 {
      width: 100%;
      padding-left: 0.5rem;
    }
    .metric-component {
      height: calc(100% - 3rem);
      .charts {
        position: relative;
        height: calc(100% - 3rem);
      }
    }
    .production-chart .charts .bar-container .productionChart,
    .availability-chart .charts .bar-container .availabilityChart,
    .quality-chart .charts .bar-container .qualityChart,
    .oee-chart .charts .line-container .oeeChart {
      text-align: center;
      display: flex;
    }
    .presets {
      display: flex;
      justify-content: center;
      padding-bottom: 0.5rem;
    }
    .bar-container {
      padding-right: 1.5rem;
      position: relative;
    }
  }
}
.theme--light.v-application {
  .machine-metric-heatmap {
    .machine-link {
      color: $light-color-val;
    }
  }
}
@include media_below(550px) {
  .machine-metric-heatmap {
    .toggleBtns {
      top: 3rem;
    }
    .heatmap {
      margin-top: 2rem;
      height: 300px;
    }
  }
}
</style>

<script>
import moment from "moment";

import { chartOptions } from "@/components/charts/chartOptions.js";
import Availability from "@/components/metrics/Availability";
import Oee from "@/components/metrics/Oee";
import Production from "@/components/metrics/Production";
import Quality from "@/components/metrics/Quality";
import { Dispatcher } from "@/utils/eventbus";
import { seconds } from "@/utils/filters";

import BaseHeatmap from "./Base.vue";

export default {
  props: ["machine_group_id", "machine_group_pk"],
  components: {
    BaseHeatmap,
    Production,
    Quality,
    Availability,
    Oee
  },
  data() {
    return {
      shift: false,
      toggle: "group",
      xCoords: [
        { label: this.$t("Performance"), key: "performanceVar" },
        { label: this.$t("Quality"), key: "qualityVar" },
        { label: this.$t("Availability"), key: "availabilityVar" },
        { label: this.$t("OEE"), key: "oeeVar" }
      ],
      yCoords: [],
      intervals: [
        { name: this.$t("Shift"), value: "shift" },
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ],
      groupSelections: [],
      chartOptions: new chartOptions(),
      heatmapRawData: null,
      machineDataLoaded: false,
      heatmapDataLoaded: false,
      dataFormatted: false,
      machines: {},
      machineGroups: {},
      objs: {},
      itemData: {},
      settings: false,
      sortBy: "name",
      sortDir: "DESC",
      selectedMetric: false,
      production: null,
      activeTab: 0
    };
  },
  created() {
    this.$http
      .get("graphql/", {
        params: {
          query: `{settings{
        defaultPreset
        showPerformance
        showQuality
        showAvailability
        showTimeline
        showOee
        showRuntime
        showDowntime
      }
    }`
        }
      })
      .then((res) => {
        if (res.data.data.settings.length > 0) {
          _.merge(this.selectedMetric, res.data.data.settings[0]);
          switch (this.defaultPreset) {
            case "A_0":
              this.presetClick("shift");
              break;
            case "A_1":
              this.presetClick("day");
              break;
            case "A_2":
              this.presetClick("week");
              break;
            case "A_3":
              this.presetClick("month");
              break;
            case "A_4":
              this.presetClick("year");
              break;
          }
        }
      });
    this.loadVarThresholds();
    this.loadMachineData();
    Dispatcher.$listen("PRODUCTION_UPDATE", this.productionUpdate);
  },
  beforeDestroy() {},
  computed: {
    sortedY() {
      if (this.dataFormatted) {
        let itemLookup = this.itemLookup;
        let itemData = this.itemData;
        let sortBy = this.sortBy;
        let yCoords = [];
        if (sortBy == "name") {
          yCoords = _.sortBy(this.yCoords, function (item_id) {
            return itemLookup[item_id].name;
          });
        } else {
          yCoords = _.sortBy(this.yCoords, function (item_id) {
            return itemData[item_id][sortBy].value;
          });
        }
        if (this.sortDir == "ASC") {
          return yCoords.reverse();
        }
        return yCoords;
      }
    },
    itemLookup() {
      if (this.toggle == "group") {
        return this.machineGroups;
      } else {
        return this.machines;
      }
    }
  },
  methods: {
    moment,
    seconds,
    loadVarThresholds() {
      this.dataFormatted = false;
      this.machineDataLoaded = false;
      let query = `query{
        settings{
          goodTolerance
          warningTolerance
          badTolerance
        }
      }`;
      this.$http.post("graphql/", { query: query }).then((res) => {
        if (res.data.data.settings.length > 0) {
          this.settings = res.data.data.settings[0];
        }
      });
    },
    loadMachineData() {
      this.dataFormatted = false;
      this.machineDataLoaded = false;
      let query = `query ($machineGroupId: ID!){
        machineGroup(id: $machineGroupId){
          id
          ... on MachineGroupType{
            id
            pk
            name
            allMachines{
              id
              pk
              name
              oeeTarget
              performanceTarget
              qualityTarget
          		runtimeTarget
            }
            allSubGroups{
              id
              pk
              name
              allMachines{
                id
                pk
                name
                oeeTarget
                performanceTarget
                qualityTarget
            		runtimeTarget
              }
            }
          }
        }
      }`;
      let variables = { machineGroupId: this.machine_group_id };
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        this.machineGroups = {};
        res.data.data.machineGroup.allSubGroups.forEach((group) => {
          this.machineGroups[group.pk] = group;
        });
        this.machines = {};
        res.data.data.machineGroup.allMachines.forEach((machine) => {
          this.machines[machine.pk] = machine;
        });
        this.loadHeatmapData();
        this.machineDataLoaded = true;
      });
    },
    loadHeatmapData() {
      this.dataFormatted = false;
      this.heatmapDataLoaded = false;
      this.yCoords = [];
      let params = {
        from_date: moment().set("minute", 0).subtract(24, "h").toISOString(),
        to_date: moment().set("minute", 0).toISOString(),
        machine_group_id: this.machine_group_pk
      };
      if (this.groupSelections.length > 0) {
        params["machine_group_id"] = this.groupSelections[this.groupSelections.length - 1].id;
      }
      let url = "metrics/machine_group_metric_breakdown/";
      if (this.toggle == "machine") {
        url = "metrics/machine_metric_breakdown/";
      }
      this.$http.get(url, { params: params }).then((res) => {
        if (res.data.length == 0 && this.toggle == "group") {
          this.toggle = "machine";
        } else {
          this.heatmapRawData = res.data;
          this.heatmapRawData.forEach((obj) => {
            this.yCoords.push(obj.key);
            this.objs[obj.key] = obj;
            this.itemData[obj.key] = obj;
          });
          if (this.machineDataLoaded) {
            this.formatData();
          }
          this.heatmapDataLoaded = true;
        }
      });
    },
    formatData() {
      this.heatmapRawData.forEach((item) => {
        this.itemData[item.key]["performanceVar"] = this.getPerformanceVar(item.key);
        this.itemData[item.key]["qualityVar"] = this.getQualityVar(item.key);
        this.itemData[item.key]["availabilityVar"] = this.getAvailabilityVar(item.key);
        this.itemData[item.key]["oeeVar"] = this.getOeeVar(item.key);
      });
      this.dataFormatted = true;
    },
    drillDown(obj) {
      if (obj.machine_group) {
        this.groupSelections.forEach((group, i) => {
          if (group.id == obj.id) {
            this.groupSelections.length = i;
          }
        });

        this.groupSelections.push(obj);
        this.loadHeatmapData(obj.id);
      }
    },
    getClass(variance) {
      let cls = "group0";
      switch (true) {
        case variance >= this.settings.goodTolerance:
          cls = "group1";
          break;
        case variance >= 0 && variance < this.settings.goodTolerance:
          cls = "group2";
          break;
        case variance < 0 && variance > this.settings.warningTolerance:
          cls = "group3";
          break;
        case variance < this.settings.warningTolerance && variance > this.settings.badTolerance:
          cls = "group4";
          break;
        case variance < this.settings.badTolerance:
          cls = "group5";
          break;
      }
      return cls;
    },
    getPerformanceVar(item_id) {
      let target = 0;
      if (this.toggle == "machine") {
        target = parseFloat(this.machines[item_id].performanceTarget);
      }
      if (this.toggle == "group" && this.machineGroups[item_id].allMachines.length > 0) {
        target = parseFloat(this.machineGroups[item_id].allMachines[0].performanceTarget);
      }
      let actual = parseFloat(this.itemData[item_id].performance.value) * 100;
      let variance = actual - target;
      let cls = this.getClass(variance);
      return {
        value: variance,
        cls: cls
      };
    },
    getQualityVar(item_id) {
      let target = 0;
      if (this.toggle == "machine") {
        target = parseFloat(this.machines[item_id].qualityTarget);
      }
      if (this.toggle == "group" && this.machineGroups[item_id].allMachines.length > 0) {
        target = parseFloat(this.machineGroups[item_id].allMachines[0].qualityTarget);
      }
      let actual = parseFloat(this.itemData[item_id].quality.value) * 100;
      let variance = actual - target;
      let cls = this.getClass(variance);
      return {
        value: variance,
        cls: cls
      };
    },
    getAvailabilityVar(item_id) {
      let target = 0;
      if (this.toggle == "machine") {
        target = parseFloat(this.machines[item_id].runtimeTarget);
      }
      if (this.toggle == "group" && this.machineGroups[item_id].allMachines.length > 0) {
        target = parseFloat(this.machineGroups[item_id].allMachines[0].runtimeTarget);
      }
      let actual =
        parseFloat(
          this.itemData[item_id].runtime.duration.value / this.itemData[item_id].duration.value
        ) * 100;
      let variance = actual - target;
      let cls = this.getClass(variance);
      return {
        value: variance,
        cls: cls
      };
    },
    getOeeVar(item_id) {
      let target = 0;
      if (this.toggle == "machine") {
        target = parseFloat(this.machines[item_id].oeeTarget);
      }
      if (this.toggle == "group" && this.machineGroups[item_id].allMachines.length > 0) {
        target = parseFloat(this.machineGroups[item_id].allMachines[0].oeeTarget);
      }
      let actual = parseFloat(this.itemData[item_id].oee.value) * 100;
      let variance = actual - target;
      let cls = this.getClass(variance);
      return {
        value: variance,
        cls: cls
      };
    },
    updateSort(x) {
      if (this.sortBy == x.key) {
        if (this.sortDir == "ASC") {
          this.sortBy = "name";
          this.sortDir = "DESC";
        } else {
          this.sortDir = "ASC";
        }
      } else {
        this.sortBy = x.key;
        this.sortDir = "DESC";
      }
    },
    selectMetric(machine, metric) {
      let options = {};
      if (this.toggle == "machine") {
        options = new chartOptions(machine.key);
      } else {
        options = new chartOptions(false, machine.key);
      }
      this.selectedMetric = { machine, metric, options: options };
      this.$http
        .get("graphql/", {
          params: {
            query:
              `{machines(id: ` +
              machine.key +
              `){
          performanceTarget,
          qualityTarget,
        	oeeTarget,
          runtimeTarget,
        }
      }`
          }
        })
        .then((res) => {
          if (res.data.data.machines.length > 0) {
            this.selectedMetric.options.targets.perf = res.data.data.machines[0].performanceTarget;
            this.selectedMetric.options.targets.quality = res.data.data.machines[0].qualityTarget;
            this.selectedMetric.options.targets.runtime = res.data.data.machines[0].runtimeTarget;
            this.selectedMetric.options.targets.oee = res.data.data.machines[0].oeeTarget;
            this.loadProduction();
          }
        });
    },
    loadProduction() {
      let params = this.selectedMetric["options"].params;
      this.$http.get("metrics/production_per_interval/", { params }).then((res) => {
        this.production = res.data;
      });
    },
    productionUpdate(newProd) {
      if (newProd.scale == this.selectedMetric.scale) {
        let updated = false;
        let key = this.selectedMetric["options"].labelFromString(newProd.min_date.value_as_string);
        this.production.forEach((interval) => {
          if (key == this.selectedMetric["options"].labelFromString(interval.key_as_string)) {
            _.merge(interval, newProd);
            updated = true;
          }
        });
        if (!updated) {
          if (
            moment(newProd.min_date.value_as_string).isAfter(
              moment(this.production[this.production.length - 1].key_as_string)
            )
          ) {
            newProd["key_as_string"] = newProd.min_date.value_as_string;
            this.production.shift().push(newProd);
          }
        }
      }
    },
    presetClick(preset) {
      let from_date = moment();
      let to_date = moment();
      let dateFormat = "D MMM hhA";
      let scale = "1h";
      switch (preset) {
        case "shift":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          if (this.shift.length == 1 && this.shift[0].shiftDays.length == 1) {
            from_date = moment(this.shift[0].shiftDays[0].nextStartDatetime);
            to_date = moment(this.shift[0].shiftDays[0].nextEndDatetime);
          }
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "day":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "week":
          from_date = moment().startOf("week");
          to_date = moment().endOf("week");
          dateFormat = "D MMM";
          scale = "1d";
          break;
        case "month":
          from_date = moment().startOf("month");
          to_date = moment().endOf("month");
          dateFormat = "week";
          scale = "1w";
          break;
        case "year":
          from_date = moment().endOf("month").subtract(12, "months");
          to_date = moment().endOf("month");
          dateFormat = "MMM YY";
          scale = "1M";
          break;
      }
      Object.assign(this.selectedMetric["options"], {
        scale: scale,
        preset: preset,
        dateFormat: dateFormat,
        from_date: from_date,
        to_date: to_date
      });
      this.loadProduction();
    },
    saveStatus() {
      this.statusUpdateLoading = true;
      const query = `mutation ($statusData: ManualMachineStatusMutationInput!){
        updateMachineStatus(input:$statusData){
          errors{
            field,
            messages
          }
        }
      }`;
      const variables = {
        statusData: {
          latest: true,
          machine: this.machine_group_pk,
          code: this.newCode.code,
          statusCode: this.newCode.id,
          user: this.user.id
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var statusUpdateLoading = this.statusUpdateLoading;
          this.newCode = false;
          this.newSelectedStatus = false;
          this.status_dialog = false;
          this.statusUpdateLoading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    clearSelected() {
      this.selectedMetric = false;
      this.production = false;
    }
  },
  watch: {
    $route(to, from) {},
    machine_group_id: function () {
      this.loadHeatmapData();
    },
    toggle: function () {
      this.loadHeatmapData();
      this.activeTab = this.toggle == "group" ? 0 : 1;
    }
  }
};
</script>
