<template>
  <FiltersLayout
    :portal-node-id="portalNodeId"
    :is-open="isOpen"
    @apply="$emit('acceptFilters', localFilters)"
    @cancel="cancelFilters"
    @close="cancelFilters"
    @clear="clearFilters"
    v-on="$listeners"
  >
    <TicketsFiltersFormLdms
      v-if="isOpen"
      :filters="localFilters"
      :params="params"
      :machine_group_pk="machine_group_pk"
      :machine_id="machine_id"
      @change="handleSearchChange"
    />
  </FiltersLayout>
</template>

<script>
import FiltersLayout from "@/common/filters/FiltersLayout.vue";
import { useFilters } from "@/common/filters";
import TicketsFiltersFormLdms from "@/features/tickets/components/TicketsFiltersFormLdms.vue";

export default {
  name: "TicketsFiltersLdms",
  components: {
    TicketsFiltersFormLdms,
    FiltersLayout
  },
  props: {
    portalNodeId: { type: String, default: undefined },
    filters: { type: Object, default: undefined },
    isOpen: { type: Boolean, default: false },
    params: { type: Object, default: undefined },
    machine_group_pk: { type: Number, required: false, default: undefined },
    machine_id: { type: Number, required: false, default: undefined }
  },
  emits: ["acceptFilters", "input"],
  setup(props, { emit }) {
    const { localFilters, clearFilters, cancelFilters, handleSearchChange } = useFilters({
      filters: props.filters,
      isOpen: props.isOpen,
      emit,
      defaultValues: {
        issues: [],
        machine_ids: [],
        statuses: null,
        category: [],
        assigned_to: []
      }
    });

    return {
      localFilters,
      clearFilters,
      cancelFilters,
      handleSearchChange
    };
  }
};
</script>
