<template lang="html">
  <div class="mapComponent">
    <div class="headerContainer">
      <div class="clockContainer">
        <div
          v-for="(item, index) in clocks"
          class="clockWithTitle"
        >
          <clock
            theme="map"
            v-model="item.date"
          />
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="buttonContainer">
        <v-btn
          fab
          color="primary"
          class="editBtn"
          @click="edit = !edit"
          v-if="accessToConfig"
        >
          <i class="fa fa-edit" />
        </v-btn>
      </div>
    </div>
    <div class="mapWrapper">
      <img
        ref="map"
        :src="theme == 'light' ? mapSVGLight : mapSVG"
        @click="mapClick"
      />
      <div
        class="pin"
        v-for="(pin, index) in coords"
        :style="{ left: pin.left, top: pin.top }"
        :class="{ currentLocation: location.id == pin.group.pk }"
        @click="goToLocation(pin)"
      >
        <i
          class="fa fa-map-marker"
          aria-hidden="true"
        ></i>
        <div class="groupLabel">
          <h2>
            {{ pin.group.name }}
            <i
              v-if="edit"
              class="fa fa-trash"
              @click.stop.prevent="deletePin(pin)"
              aria-hidden="true"
            ></i>
          </h2>
        </div>
      </div>
      <div
        class="pin"
        v-if="newCoord"
        :style="{ left: newCoord.left, top: newCoord.top }"
      >
        <i
          class="fa fa-map-marker"
          aria-hidden="true"
        ></i>
        <div class="card groupSelection">
          <v-select
            v-model="newCoord.group"
            :items="groups"
            :label="$t('Machine Group')"
            outlined
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-select>
          <v-btn @click.stop.prevent="newCoord = false">
            {{ $t("Delete") }}
          </v-btn>
          <v-btn @click.stop.prevent="savePin('new')">
            {{ $t("Save") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Clock from "vue-html-analog-clock";
import { mapActions, mapGetters } from "vuex";

import { hasUserRole, userRoles } from "@/utils/user";

export default {
  data() {
    return {
      mapSVG: require("../assets/world.svg"),
      mapSVGLight: require("../assets/worldLightTheme.svg"),
      coords: [],
      groups: [],
      clocks: [],
      newCoord: false,
      gs: false,
      edit: false,
      accessToConfig: false
    };
  },
  created() {
    this.loadCoords();
    this.accessToConfig = hasUserRole(this.user, userRoles.ApplicationAdmin);
    this.$http
      .get("graphql/", {
        params: {
          query: `{
      locations{
        id,
        pk,
        name,
        bannerText,
        locationWeatherSearch
      }
    }`
        }
      })
      .then((res) => {
        this.groups = res.data.data.locations;
      })
      .catch((res) => {
        this.errors = res.errors;
      });

    this.$http
      .get("graphql/", {
        params: {
          query: `{
      settings{
        clocksEnabled
      },
      clocks{
        name,
        timezone,
        timezoneString
      }
    }`
        }
      })
      .then((res) => {
        const data = res.data.data;

        if (data.settings[0].clocksEnabled) {
          for (const clock of data.clocks || []) {
            const momentDateString = moment()
              .tz(clock.timezoneString)
              .format("MM/DD/YYYY hh:mm:ss");
            const date = moment(momentDateString).toDate();

            this.clocks.push({ name: clock.name, timezone: clock.timezoneString, date: date });
          }
        }
      })
      .catch((e) => {
        this.errors = e.errors;
      });

    console.log(this.clocks);
  },
  components: {
    Clock
  },
  computed: {
    ...mapGetters({
      user: "session/User",
      location: "session/Location",
      theme: "app/Theme"
    })
  },
  methods: {
    ...mapActions({
      setLocation: "session/SetLocation"
    }),
    loadCoords() {
      this.$http
        .get("graphql/", {
          params: {
            query: `{machineGroupCoords{
          id
         	group{
            id
            pk,
            name,
            bannerText,
            locationWeatherSearch
          }
          top
          left
        }
      }`
          }
        })
        .then((res) => {
          this.coords = res.data.data.machineGroupCoords;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    mapClick(e) {
      if (e && this.edit) {
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top; //y position within the element.
        let height = this.$refs.map.clientHeight;
        let width = this.$refs.map.clientWidth;
        this.newCoord = {
          left: (x / width) * 100 + "%",
          top: (y / height) * 100 + "%",
          group: null
        };
      }
    },
    deletePin(pin) {
      this.updateLoading = true;
      const query = `mutation ($coordData: MachineGroupCoordsDeletionInput!){
        deleteMachineGroupCoord(input:$coordData){
          clientMutationId
        }
      }`;
      const variables = {
        coordData: {
          id: pin.id
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.loadCoords();
          this.newCoord = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    savePin(pin) {
      this.updateLoading = true;
      const query = `mutation ($coordData: MachineGroupCoordsMutationInput!){
        createMachineGroupCoord(input:$coordData){
          coord{
            name
          }
        }
      }`;
      const variables = {
        coordData: {
          group: this.newCoord.group.id,
          left: this.newCoord.left,
          top: this.newCoord.top
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.loadCoords();
          this.newCoord = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    goToLocation(pin) {
      this.setLocation({
        location: pin.group.pk,
        locationBanner: pin.group.bannerText,
        locationLookup: pin.group.locationWeatherSearch,
        locationName: pin.group.name
      });
      this.$router.push({ name: "welcome" });
    }
  }
};
</script>

<style lang="scss">
@import "../scss/variables.scss";
.mapComponent {
  width: 100%;
  position: relative;
  @media screen and (max-width: 1200px) {
    height: 100%;
    overflow: hidden;
  }
  .headerContainer {
    position: relative;
    display: flex;
    min-width: 5em;
    padding: 5px 20px;
  }
  .buttonContainer {
    flex: unset;
    width: 56px;
    position: absolute;
    right: 1rem;
    button {
      left: 0;
      top: 0;
      position: relative;
    }
  }
  .clockContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex: 1;
    padding: 0 10px;

    .clockWithTitle {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    div.vue-analog-clock.vue-analog-clock-map {
      width: 100px !important;
      height: 100px !important;
      font-size: 0.55em;
      color: black !important;
      @media screen and (max-width: 1200px) {
        margin-top: 4rem;
        width: 80px !important;
        height: 80px !important;
      }
      div.clock-outer-ring {
        background-color: #606060;
      }

      div.clock-container {
        background-color: lighten(#9f9f9f, 100%);
      }

      // if you're not following the hierarchy, you might need to use !important
      div.pointer {
        background-color: darken(#656565, 50%) !important;
        &.hour-pointer {
          width: 30%;
        }
        &.minute-pointer {
          width: 40%;
        }
        &.second-pointer {
          width: 40%;
        }
      }
    }
  }
  .mapWrapper {
    width: 100%;
    position: relative;
    @media screen and (max-width: 1200px) {
      width: 125%;
      margin-left: -14%;
      margin-top: 75px;
    }

    img {
      width: 100%;
      opacity: 0.4;
    }
    .pin {
      position: absolute;
      transform: translate(-50%, -100%);
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      &.currentLocation {
        color: $green;
      }
      &:hover {
        color: $blue;
      }
      i {
        font-size: 5rem;
        &.fa-trash {
          font-size: 2rem;
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }
      .groupLabel {
        width: 20rem;
        position: absolute;
        top: 100%;
        transform-origin: center;
        transform: translate(calc(-50% + 0.8rem), 0%);
        text-align: center;
        h2 {
          margin: 0.5rem;
        }
      }
      @media screen and (max-width: 1200px) {
        i {
          font-size: 2rem;
        }
        .groupLabel {
          width: 5rem;
          top: 70%;
          h2 {
            font-size: 1rem;
            text-align: center;
          }
        }
      }

      .groupSelection {
        width: 20rem;
        min-height: unset;
        padding: 1rem;
        position: absolute;
        top: 100%;
        transform: translate(calc(-50% + 1.5rem), 0%);
        text-align: center;
      }
    }
  }
  .editBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    i {
      font-size: 1.5rem;
    }
  }
}
</style>
