<template>
  <div class="card issue-table">
    <div class="chart-title-icons">
      <span class="header-text">{{ openClosedOrTotal }} {{ $t("LDMS Issues Details") }}</span>
    </div>
    <v-data-table
      item-key="id"
      :headers="ldmsIssuesDetails.headers"
      :items="ldmsIssuesDetails.data"
      :page.sync="ldmsIssuesDetails.page.current"
      :sortBy.sync="ldmsIssuesDetails.sortBy"
      :sortDesc.sync="ldmsIssuesDetails.sortDesc"
      :serverItemsLength="ldmsIssuesDetails.total"
      :loading="loadingIssuesDetailsData"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading LDMS Issues Details... Please wait')"
      @update:options="handleUpdatePageOptions"
      disable-pagination
      fixed-header
    >
      <template v-slot:item="{ item }">
        <tr
          class="table-row"
          @click="handleRowClick(item)"
        >
          <td :style="{ width: '100px' }">{{ item.issueStartDate }}</td>
          <td>{{ item.department }}</td>
          <td>{{ item.zone }}</td>
          <td>{{ item.machineName }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.reason }}</td>
          <td>
            <p v-for="cm in item.countermeasures.split(',')">{{ cm }}</p>
          </td>
          <td>
            <p v-for="cause in item.cause.split(',')">{{ cause }}</p>
          </td>
          <td>
            <p v-for="solution in item.solution.split(',')">{{ solution }}</p>
          </td>
          <td>{{ item.totalTime }}</td>
          <td>{{ item.currentStatus }}</td>
          <td>{{ item.respondingNames }}</td>
        </tr>
      </template>
      <template v-slot:footer.prepend>
        <v-btn
          class="csv-download-button"
          color="primary"
          medium
          @click="downloadCsv"
        >
          <v-icon size="16">mdi-file-download</v-icon> CSV
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "LDMSAnalyticsIssuesDetailsTable",
  props: {
    ldmsIssuesDetails: { type: Object, default: () => {} },
    downloadCsv: { type: Function, default: () => null },
    openClosedOrTotal: { type: String, default: "" },
    loadingIssuesDetailsData: { type: Boolean, default: false },
    handleUpdatePageOptions: { type: Function, default: () => null }
  },
  methods: {
    handleRowClick(row) {
      const routeData = this.$router.resolve({ name: "ticket", params: { ticket_id: row.id } });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style lang="scss"></style>
