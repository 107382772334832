var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('div',[(_vm.assignedTicket)?_c('TicketAssignDialog',{attrs:{"type":"ldms","open":Boolean(_vm.assignedTicket),"ticket":_vm.formatIssue(_vm.assignedTicket),"machine_group_id":_vm.machine_group_id},on:{"close":function($event){_vm.assignedTicket = null},"success":_vm.handleRefresh}}):_vm._e(),_vm._v(" "),(_vm.selectedCause)?_c('TicketCauseDialog',{attrs:{"open":Boolean(_vm.selectedCause),"issues":[_vm.formatIssue(_vm.selectedCause)],"machine_id":_vm.selectedCause.machine && _vm.selectedCause.machine.id,"time":_vm.selectedCause.key_as_string,"tab":-1},on:{"UpdatedCause":_vm.handleRefresh,"close":function($event){_vm.selectedCause = null}}}):_vm._e(),_vm._v(" "),(_vm.selectedSolution)?_c('TicketLongTermSolutionDialog',{attrs:{"issues":[_vm.formatIssue(_vm.selectedSolution)],"machine_id":_vm.selectedSolution.machine.pk,"time":_vm.selectedSolution.key_as_string,"tab":-1},on:{"UpdatedSolution":_vm.handleRefresh,"close":function($event){_vm.selectedSolution = null}}}):_vm._e(),_vm._v(" "),(_vm.selectedCountermeasure)?_c('TicketsCountermeasureDialog',{attrs:{"issues":[_vm.formatIssue(_vm.selectedCountermeasure)],"machine_id":_vm.selectedCountermeasure.machine.pk,"time":_vm.selectedCountermeasure.key_as_string,"tab":-1},on:{"UpdatedCM":_vm.handleRefresh,"close":function($event){_vm.selectedCountermeasure = null}}}):_vm._e(),_vm._v(" "),_c('DynamicTable',{staticClass:"root",attrs:{"item-key":"graphQL_id","items":_vm.items,"headers":_vm.headers,"calculate-widths":true,"page":_vm.config.page,"items-per-page":_vm.config.itemsPerPage,"server-items-length":_vm.totalItems,"sort-by":_vm.config.sortBy,"sort-desc":_vm.config.sortDesc,"loading":_vm.isFetchingTable,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100] },"loading-text":_vm.$t('Loading Help Tickets... Please wait'),"dynamic-slots":[
      'title',
      'created_by_name',
      'issue.text',
      'machine_name',
      'datetime',
      'short_term_countermeasures',
      'long_term_solution',
      'root_cause',
      'status',
      'id',
      'assigned_names',
      'launch',
      'responding_names',
      'machine_name'
    ]},on:{"update:items-per-page":function($event){_vm.config.itemsPerPage = $event},"update:page":function($event){_vm.config.page = $event},"update:sort-by":function($event){_vm.config.sortBy = $event},"update:sort-desc":function($event){_vm.config.sortDesc = $event}},scopedSlots:_vm._u([{key:"item.issue.text",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.issue)?_c('TicketTag',{class:[_vm.getColorByFlag(item)],attrs:{"issue":{ name: item.issue && item.issue.text }}}):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('TicketStatus',{attrs:{"status":item.status}})]}},{key:"item.datetime",fn:function(ref){
    var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.moment(item.datetime).format(_vm.$datetime.default))+"\n    ")]}},{key:"item.long_term_solution",fn:function(ref){
    var item = ref.item;
return [_c('TableMultivalue',{attrs:{"items":item.long_term_solution,"show-add-button":Boolean(item.root_cause)},on:{"click-add-button":function($event){_vm.selectedSolution = item}}})]}},{key:"item.root_cause",fn:function(ref){
    var item = ref.item;
return [_c('TableMultivalue',{attrs:{"items":item.root_cause,"show-add-button":true},on:{"click-add-button":function($event){_vm.selectedCause = item}}})]}},{key:"item.short_term_countermeasures",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('TableMultivalue',{attrs:{"items":item.short_term_countermeasures,"show-add-button":true},on:{"click-add-button":function($event){_vm.selectedCountermeasure = item}}})],1)]}},{key:"item.assigned_names",fn:function(ref){
    var item = ref.item;
return [_c('TableMultivalue',{attrs:{"items":item.assigned_names,"show-add-button":true},on:{"click-add-button":function($event){_vm.assignedTicket = item}}})]}},{key:"item.responding_names",fn:function(ref){
    var item = ref.item;
return [_c('TableMultivalue',{attrs:{"items":item.responding_names,"show-add-button":false}})]}},{key:"item.launch",fn:function(ref){
    var item = ref.item;
return [_c('Btn',{staticClass:"color--primary",attrs:{"small":"","icon":"","to":{ name: 'ticket', params: { ticket_id: item.graphQL_id } },"target":"_blank","fab":"","transparent":""}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}},{key:"item.machine_name",fn:function(ref){
    var item = ref.item;
return [(item.machine)?_c('span',[_vm._v("\n        "+_vm._s(item.machine.name)+"\n\n        "),_c('router-link',{staticClass:"tickets-link",attrs:{"to":{
            name: _vm.AppRoutes.machineKpi.name,
            params: {
              machine_id: item.machine.id,
              machine_pk: item.machine.pk
            }
          }}},[_c('Btn',{staticClass:"color--primary",attrs:{"icon":"","x-small":"","primary":"","transparent":""}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1)],1):_vm._e(),_vm._v(" "),(item.machine_group)?_c('span',[_vm._v("\n        "+_vm._s(item.machine_group.name)+"\n\n        "),_c('router-link',{staticClass:"tickets-link",attrs:{"to":{
            name: 'group',
            params: {
              machine_group_id: item.machine_group.id,
              machine_group_pk: item.machine_group.pk
            }
          }}},[_c('Btn',{attrs:{"icon":"","x-small":"","color":"primary","transparent":""}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1)],1):_vm._e()]}}],null,true)})],1):_c('preloader-panel',{staticClass:"pa-6"})}
var staticRenderFns = []

export { render, staticRenderFns }