<template>
  <div class="doc-center">
    <div
      class="overlay"
      @click="close()"
      :class="{ show: rightPanel == 'docs' || rightPanel == 'video' }"
    ></div>
    <div class="card">
      <div class="card-header">
        <h2>{{ rightPanel == "docs" ? $t("Doc Center") : $t("Videos") }}</h2>
        <div class="options">
          <i
            class="fa fa-times"
            @click="close()"
          />
        </div>
      </div>
      <div class="card-content">
        <v-text-field
          :value="getSearchModel()"
          @input="updateSearchModel"
          prepend-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <div
          class="doc"
          v-for="(doc, doc_index) in docs"
          @click="selectDoc(doc)"
          v-if="rightPanel == 'docs'"
        >
          <div class="thumbnail">
            <img
              :src="doc.thumbnailUrl"
              v-if="doc.thumbnailUrl"
            />
            <i
              v-else-if="doc.latestVersion.video"
              class="fa fa-file-video-o"
              aria-hidden="true"
            ></i>
            <i
              v-else-if="doc.latestVersion.image"
              class="fa fa-file-image-o"
              aria-hidden="true"
            ></i>
            <i
              v-else-if="doc.latestVersion.pdf"
              class="fa fa-file-pdf-o"
              aria-hidden="true"
            ></i>
          </div>
          <div class="name">
            <p class="label">{{ doc.name }}</p>
            <p class="description">{{ doc.description }}</p>
          </div>
        </div>
        <div
          class="doc"
          v-for="(video, vid_index) in videos"
          @click="selectDoc(video)"
          v-if="rightPanel == 'video'"
        >
          <div class="thumbnail">
            <img
              :src="video.thumbnailUrl"
              v-if="video.thumbnailUrl"
            />
            <i
              v-else-if="video.latestVersion.video"
              class="fa fa-file-video-o"
              aria-hidden="true"
            ></i>
          </div>
          <div class="name">
            <p class="label">{{ video.name }}</p>
            <p class="description">{{ video.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showDialog"
      width="1000"
    >
      <media-viewer
        v-if="showDialog"
        :media="selectedMedia"
        @close="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.doc-center {
  width: $rightPanelWidth;
  margin: 1rem 0;
  margin-top: 0.5rem;
  display: block;
  .card {
    z-index: 1000;
    height: 100%;
    overflow: hidden;
    .card-content {
      position: relative;
      .doc {
        margin: 1rem 0.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border: 1.5px solid white;
        border-radius: 0.5rem;
        min-height: 4rem;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        &:hover {
          border-color: $blue;
        }
        .thumbnail {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          width: 100px;
          overflow: hidden;
          margin-right: 0.5rem;
          img {
            min-height: 100px;
            max-width: 100px;
            text-align: center;
          }
          i {
            font-size: 2rem;
          }
        }
        .name {
          width: calc(100% - 100px - 1rem);
          // height: calc(100% - 2rem);
          text-align: left;
        }
        .download {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0.5rem;
          color: white;
          height: 3rem;
          width: 3rem;
          border-radius: 2rem;
          background-color: $blue;
          cursor: pointer;
          i {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import MediaViewer from "@/components/MediaViewer";

export default {
  props: {
    // filters:{
    //   default: "machine: 0"
    // }
  },
  data() {
    return {
      docs: [],
      videos: [],
      selectedMedia: false,
      showDialog: false,
      searchDocs: "",
      searchVideos: "",
      searchDebouncer: false
    };
  },
  created() {
    this.loadDocs();
    this.loadVideos();
  },
  components: {
    MediaViewer
  },
  computed: {
    ...mapGetters({
      rightPanel: "components/RightPanel",
      user: "session/User"
    })
  },
  methods: {
    moment,
    ...mapActions({
      setRightPanel: "components/SetRightPanel"
    }),
    close() {
      this.setRightPanel(false);
    },
    loadDocs() {
      //TODO switch to globalid
      const query = `query($machine: Int, $search: String){
        helpDocs(machine: $machine, search: $search){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;
      const variables = {};
      if ("machine_pk" in this.$route.params) {
        variables["machine"] = this.$route.params.machine_pk;
      }
      if (this.searchDocs) {
        variables["search"] = this.searchDocs;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.docs = res.data.data.helpDocs;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadVideos() {
      //TODO switch to globalid
      const query = `query($machine: Int, $search: String){
        helpDocs(machine: $machine, search: $search, video: true){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;
      const variables = {};
      if ("machine_pk" in this.$route.params) {
        variables["machine"] = this.$route.params.machine_pk;
      }
      if (this.searchVideos) {
        variables["search"] = this.searchVideos;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.videos = res.data.data.helpDocs;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    selectDoc(doc) {
      this.selectedMedia = doc;
      this.showDialog = true;
    },
    downloadFile(doc) {
      this.$http
        .get(`https://${window.location.host}/${doc.latestVersion.fileUrl}`, {
          responseType: "blob"
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(console.error);
    },
    getSearchModel() {
      if (this.rightPanel == "docs") {
        return this.searchDocs;
      } else {
        return this.searchVideos;
      }
    },
    updateSearchModel(value) {
      if (this.rightPanel == "docs") {
        this.searchDocs = value;
      } else {
        this.searchVideos = value;
      }
    }
  },
  watch: {
    $route(to, from) {
      this.loadDocs();
    },
    searchDocs: function () {
      if (this.searchDebouncer) {
        clearTimeout(this.searchDebouncer);
      }
      let load = this.loadDocs;
      this.searchDebouncer = setTimeout(load, 500);
    },
    searchVideos: function () {
      if (this.searchDebouncer) {
        clearTimeout(this.searchDebouncer);
      }
      let load = this.loadVideos;
      this.searchDebouncer = setTimeout(load, 500);
    }
  }
};
</script>
