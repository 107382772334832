<template>
  <div class="range-tabs-container">
    <!--    <div class="range-container">-->
    <!--      <v-icon small @click="handlePreviousRangeClick">mdi-chevron-left</v-icon>-->
    <!--      <span class="range">{{ range }}</span>-->
    <!--      <v-icon small @click="handleNextRangeClick">mdi-chevron-right</v-icon>-->
    <!--    </div>-->
    <v-tabs
      v-model="tab"
      key="top-tabs"
    >
      <v-tab
        v-for="(option, index) in tabOptions"
        :key="index"
      >
        <span>{{ $t(option.title) }}</span>
      </v-tab>
    </v-tabs>
  </div>
</template>
<script>
export default {
  name: "AnalyticsPagination",
  props: {
    handleTabChange: { type: Function, default: () => null }
  },
  data() {
    return {
      tab: 0,
      tabOptions: [
        {
          title: this.$t("TOP 10"),
          range: this.$t("1 - 10"),
          pageSize: 10
        },
        {
          title: this.$t("TOP 25"),
          range: "1 - 25",
          pageSize: 25
        },
        {
          title: this.$t("TOP 50"),
          range: "1 - 50",
          pageSize: 50
        },
        {
          title: this.$t("ALL"),
          range: "ALL"
        }
      ]
    };
  },
  computed: {
    currentTab() {
      return this.tab;
    },
    range() {
      return this.tabOptions[this.tab].range;
    }
  },
  methods: {
    handlePreviousRangeClick() {
      this.tab = this.tab === 0 ? this.tabOptions.length - 1 : this.tab - 1;
    },
    handleNextRangeClick() {
      this.tab = this.tab === this.tabOptions.length - 1 ? 0 : this.tab + 1;
    }
  },
  watch: {
    currentTab() {
      const pageSize = this.tabOptions[this.tab].pageSize
        ? this.tabOptions[this.tab].pageSize
        : null;
      this.handleTabChange(pageSize);
    }
  }
};
</script>
<style lang="scss"></style>
