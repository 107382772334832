<template>
  <div class="card ai-vision-anomalies">
    <v-dialog
      v-model="createTicketOpen"
      persistent
      max-width="400px"
    >
      <v-card>
        <DialogCloseButton
          :onClick="
            () => {
              createTicketOpen = false;
              openItem = null;
            }
          "
        />
        <v-card-title class="headline">
          {{ $t("Create ticket from this anomaly?") }}
        </v-card-title>
        <v-card-text>
          {{ openItem ? openItem.reason : "" }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!ticketingAnomalyPending"
            color="error"
            text
            @click="
              () => {
                createTicketOpen = false;
                openItem = null;
              }
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            primary
            text
            :loading="ticketingAnomalyPending"
            @click="ticketAnomaly(openItem)"
          >
            {{ $t("OK") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="tablecontainer">
      <generic-table
        :columns="[
          { title: $t('Title'), key: 'title', sortable: true, sort_method: sort_request },
          {
            title: $t('Detected'),
            key: 'detected',
            sortable: true,
            sort_method: sort_request,
            style: { textAlign: 'left' }
          },
          {
            title: $t('Issue Window'),
            key: 'issueWindow',
            sortable: true,
            sort_method: sort_request,
            style: { textAlign: 'left' }
          },
          {
            hidden: !ticketed,
            title: $t('Ticketed'),
            key: 'ticketedDate',
            sortable: true,
            sort_method: sort_request,
            style: { textAlign: 'left' }
          },
          { title: $t('Type'), key: 'type', sortable: true, sort_method: sort_request },
          { title: $t('Asset'), key: 'asset', sortable: true, sort_method: sort_request },
          {
            title: $t('Assigned'),
            key: 'assigned',
            sortable: true,
            sort_method: sort_request,
            style: { textAlign: 'left' }
          },
          { title: $t('Reason'), key: 'reason', sortable: true, style: { textAlign: 'left' } },
          {
            title: $t(''),
            key: 'item',
            html: 'image_link',
            click: ticketed ? launchTicket : createTicket
          }
        ]"
        :data="records"
      />
    </div>
    <div class="tablefooter">
      <div class="paginator_container">
        <div class="paginator">
          <div class="selector">
            <div class="rows-title">{{ $t("Rows per page") }}</div>
            <select @change="handleRequestCountChange">
              <option
                :selected="count === 10"
                value="10"
              >
                10
              </option>
              <option
                :selected="count === 50"
                value="50"
              >
                50
              </option>
              <option
                :selected="count === 100"
                value="100"
              >
                100
              </option>
            </select>
          </div>

          <div class="range-indicator">
            <div>
              <span>
                {{ currentStart !== null ? currentStart + 1 : "---" }}
              </span>
              <span class="divider"> - </span>
              <span>
                {{
                  currentStart !== null ? Math.min(currentStart + Number(count), totalcount) : "---"
                }}
              </span>
              <span class="divider"> of {{ totalcount || "---" }} </span>
            </div>
          </div>

          <div class="back-next-select">
            <i
              class="fa fa-chevron-left"
              v-bind:class="`${this.currentStart <= 0 && 'disabled'}`"
              @click="
                () => {
                  this.handlePage(-1, this.currentStart <= 0);
                }
              "
            />
            <i
              class="fa fa-chevron-right"
              v-bind:class="`${this.currentStart + Number(count) >= totalcount && 'disabled'}`"
              @click="
                () => {
                  this.handlePage(1, this.currentStart + Number(count) >= totalcount);
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import DialogCloseButton from "@/components/DialogCloseButton";

import GenericTable from "../../components/GenericTable";

export default {
  name: "AiVisionAnomalies",
  components: { GenericTable, DialogCloseButton },
  props: {
    ticketed: Boolean
  },
  mounted() {
    this.first = this.count;
    this.getRecords();
  },
  data() {
    return {
      columns: [],
      startCursor: false,
      endCursor: false,
      nextPage: null,
      previousPage: null,
      hasNextPage: false,
      hasPreviousPage: false,
      totalcount: 0,
      records: [],
      sortKey: null,
      sortDirection: 0,
      currentStart: 0,
      count: 10,
      requestFinished: true,
      first: null,
      last: null,
      openItem: null,
      createTicketOpen: false,
      ticketingAnomalyPending: false
    };
  },
  methods: {
    moment,
    launchTicket(item) {
      let routeData = this.$router.resolve({ name: "ticket", params: { ticket_id: item.id } });
      window.open(routeData.href, "_blank");
    },
    ticketAnomaly(item) {
      this.ticketingAnomalyPending = true;
      const query = `mutation ($id: ID!, $issueData: IssueInput!){
        updateIssue(globalId:$id, issueData:$issueData){
          ok,
          issue{
            id,
            ticketed
          }
        }
      }`;
      const variables = {
        id: item.id,
        issueData: {
          ticketed: true
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          let context = this;
          let loadAnomalies = this.loadAnomalies;
          setTimeout(() => {
            this.createTicketOpen = false;
            this.ticketingAnomalyPending = false;
            setTimeout(() => {
              this.getRecords();
            }, 1000);
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    createTicket(item) {
      this.createTicketOpen = true;
      this.openItem = item;
    },
    handleRequestCountChange(num) {
      this.count = Number(num.target.value);
      this.startCursor = false;
      this.endCursor = false;
      this.requestFinished = false;
      this.first = null;
      this.last = null;
      this.getRecords();
    },
    handlePage(direction, disabled) {
      if (!this.requestFinished || disabled) {
        return;
      }

      this.startCursor = false;
      this.endCursor = false;
      this.requestFinished = false;
      this.first = null;
      this.last = null;

      if (direction > 0) {
        this.first = this.count;

        this.startCursor = this.nextPage;
        this.getRecords().then((result) => {
          this.requestFinished = true;
          this.currentStart += this.count;
        });
      } else {
        this.last = this.count;

        this.endCursor = this.previousPage;
        this.getRecords().then((result) => {
          this.requestFinished = true;
          this.currentStart -= this.count;
        });
      }
    },
    sort_request(sortKey, sortDirection) {
      this.sortKey = sortKey;
      this.sortDirection = sortDirection;
      this.first = this.count;
      this.last = null;
      this.currentStart = 0;

      this.getRecords();
    },
    getRecords() {
      return new Promise((resolve, reject) => {
        const query = `query (
            $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
            $ordering: GrapheneElasticOrderingIssueSearchConnectionBackendFilter!,
            $first: Int,
            $last: Int,
            $after: String,
            $before: String,
            $search: String){
          issues (
              simpleQueryString: $search,
              filter: $filters,
              ordering: $ordering,
              first: $first,
              last: $last,
              after: $after,
              before: $before,
              facets: [automatic]) {
            facets,
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              },
            edges{
              cursor,
                  node{
                    id,
                    title,
                    reason,
                    openedDate,
                    assignedToNames,
                    issueStartDate,
                    issueEndDate,
                    machineId,
                    machineName,
                    partName,
                    factoryOrder,
                    issueTypeId,
                    issueTypeName,
                    ticketed,
                    ticketedDate,
                    dashboard,
                    assetId,
                    assetName
              }
            }
          }
        }`;

        let sortDict = {
          title: "title",
          detected: "openedDate",
          issueWindow: "issueStartDate",
          type: "issueTypeName",
          asset: "assetName",
          assigned: "assignedToNames",
          reason: "reason",
          ticketedDate: "ticketedDate"
        };

        let sortKey = this.sortKey
          ? sortDict[this.sortKey]
          : this.ticketed
            ? "ticketedDate"
            : "issueStartDate";

        let ordering = {};
        ordering[sortKey] = this.sortDirection == 1 ? "ASC" : "DESC";

        const variables = {
          filters: {
            ticketed: {
              exists: this.ticketed
            },
            dashboard: {
              value: "ai-vision"
            }
          },
          ordering: ordering,
          before: this.endCursor,
          after: this.startCursor,
          search: "",
          first: this.first,
          last: this.last
        };

        this.$http
          .post("/graphql/", { query, variables })
          .then((result) => {
            const {
              edges,
              facets: {
                automatic: { doc_count }
              },
              pageInfo: { startCursor, endCursor, hasNextPage, hasPreviousPage }
            } = result.data.data.issues;

            this.records = [];
            for (const r of edges) {
              this.records.push({
                title: r.node.title,
                detected: this.moment(r.node.openedDate).format(this.$datetime.default),
                issueWindow: `${this.moment(r.node.issueStartDate).format(this.$datetime.default)} - ${this.moment(r.node.issueEndDate).format(this.$datetime.default)}`,
                type: r.node.issueTypeName,
                asset: r.node.assetName,
                assigned: r.node.assignedToNames,
                reason: r.node.reason,
                id: r.node.id,
                ticketedDate: this.moment(r.node.ticketedDate).format(this.$datetime.default),
                item: r.node,
                image_link: this.ticketed
                  ? `<button type="button" class="v-btn v-btn--fab v-btn--round v-size--small primary" style="margin: 5px" >
                              <i class="fa fa-external-link-square" style="font-size: 12px"/>
                          </button>`
                  : `<button type="button" class="v-btn v-btn--fab v-btn--round v-size--small primary" style="margin: 5px" >
                              <i class="fa fa-ticket" style="font-size: 12px"/>
                          </button>`
              });
            }

            this.totalcount = doc_count;
            this.previousPage = startCursor;
            this.nextPage = endCursor;
            this.hasNextPage = hasNextPage;
            this.hasPreviousPage = hasPreviousPage;

            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject();
          });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.ai-vision-anomalies {
  min-height: 100%;
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .tablecontainer {
    flex: 1;
    overflow: hidden;
  }

  .tablefooter {
    display: flex;
    flex: unset;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
  }

  .paginator {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    flex-direction: row;
    flex: unset;
    width: 400px;
    height: 30px;
    align-items: center;

    .selector {
      display: flex;
      align-items: center;

      .rows-title {
        padding-right: 5px;
      }

      select {
        color: white;
        text-decoration: underline;

        .theme--light & {
          color: black;
        }

        option {
          color: black;
        }
      }
    }

    .range-indicator {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;

      .divider {
        padding: 0 5px;
      }
    }

    .back-next-select {
      display: flex;
      flex: unset;
      min-width: 60px;
      justify-content: space-between;
      align-items: center;

      i {
        cursor: pointer;

        &.disabled {
          opacity: 50%;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
