<template>
  <div style="width: 100%; height: 100%">
    <div v-if="selectedRegion === null && $vuetify.breakpoint.smAndDown">
      <ul class="mobile-regions">
        <li
          v-for="region in regions"
          :key="region.title"
          style="font-size: 3rem"
          @click="selectedRegion = region"
        >
          {{ region.title }}
        </li>
      </ul>
    </div>
    <div
      v-if="selectedRegion === null && $vuetify.breakpoint.mdAndUp"
      ref="map"
      style="width: 100%; height: 100%"
    ></div>
    <div
      v-if="selectedRegion !== null"
      style="height: 100%; overflow: hidden"
    >
      <v-row>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            @click="goHome"
            style="margin: 1rem"
          >
            {{ $t("Go Back") }}
          </v-btn>
        </v-col>
      </v-row>
      <div style="height: 100%; display: flex">
        <MiniMap
          v-if="$vuetify.breakpoint.mdAndUp"
          :selected-region="selectedRegion"
          style="min-width: 50%; max-width: 50%; height: 100%"
        />
        <InstanceLinks :selected-region="selectedRegion" />
      </div>
    </div>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5geodata_continentsLow from "@amcharts/amcharts5-geodata/continentsLow";
import am5geodata_region_world_asiaLow from "@amcharts/amcharts5-geodata/region/world/asiaLow";
import am5geodata_region_world_centralAmericaLow from "@amcharts/amcharts5-geodata/region/world/centralAmericaLow";
import am5geodata_region_world_europeLow from "@amcharts/amcharts5-geodata/region/world/europeLow";
import am5geodata_region_world_latinAmericaLow from "@amcharts/amcharts5-geodata/region/world/latinAmericaLow";
import am5geodata_region_world_northAmericaLow from "@amcharts/amcharts5-geodata/region/world/northAmericaLow";
import am5geodata_region_world_southAmericaLow from "@amcharts/amcharts5-geodata/region/world/southAmericaLow";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "@lottiefiles/lottie-player";

import InstanceLinks from "./InstanceLinks.vue";
import MiniMap from "./MiniMap.vue";

export default {
  name: "NewMap",
  components: { InstanceLinks, MiniMap },
  data() {
    return {
      selectedRegion: null,
      chart: null,
      regions: [
        {
          lat: 15,
          lon: -80,
          title: "Americas",
          regionGeoData: this.mergeGeoJSON([
            am5geodata_region_world_northAmericaLow,
            am5geodata_region_world_southAmericaLow,
            am5geodata_region_world_centralAmericaLow,
            am5geodata_region_world_latinAmericaLow
          ])
        },
        {
          lat: 50,
          lon: 15,
          title: "Europe",
          regionGeoData: am5geodata_region_world_europeLow
        },
        {
          lat: 20,
          lon: 100,
          title: "Asia",
          regionGeoData: am5geodata_region_world_asiaLow
        }
      ]
    };
  },
  mounted() {
    this.createMap();
  },
  beforeUnmount() {
    if (this.map) {
      this.map.dispose();
    }
  },
  methods: {
    mergeGeoJSON(geoJSONs) {
      return {
        type: "FeatureCollection",
        features: geoJSONs.reduce((features, geoJSON) => {
          return features.concat(geoJSON.features);
        }, [])
      };
    },
    goHome() {
      this.selectedRegion = null;
      this.$nextTick(() => {
        this.createMap();
      });
    },
    createMap() {
      const context = this;
      const scale = 1.3;
      const americasGeoData = mergeGeoJSON([
        am5geodata_region_world_northAmericaLow,
        am5geodata_region_world_southAmericaLow,
        am5geodata_region_world_centralAmericaLow,
        am5geodata_region_world_latinAmericaLow
      ]);
      const mapRef = this.$refs.map;
      let root = am5.Root.new(mapRef);
      root.setThemes([am5themes_Animated.new(root)]);
      let chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: "none",
          panY: "none",
          wheelY: "none",
          projection: am5map.geoEqualEarth()
        })
      );
      let continentSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_continentsLow,
          exclude: ["antarctica"],
          fill: am5.color(0x7a787d)
        })
      );
      let regionSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"],
          visible: false,
          fill: am5.color(0x7a787d)
        })
      );
      let pointSeries = chart.series.push(
        am5map.MapPointSeries.new(root, {
          latitudeField: "lat",
          longitudeField: "lon"
        })
      );
      pointSeries.bullets.push(function () {
        const container = am5.Container.new(root, {});
        const outerPulse = container.children.push(
          am5.Circle.new(root, {
            radius: 40 * scale,
            stroke: am5.color(0x47bcff),
            strokeWidth: 0.4,
            fillOpacity: 1
          })
        );
        const midPulse = container.children.push(
          am5.Circle.new(root, {
            radius: 30 * scale,
            stroke: am5.color(0x47bcff),
            strokeWidth: 0.4,
            fillOpacity: 1
          })
        );
        const innerPulse = container.children.push(
          am5.Circle.new(root, {
            radius: 20 * scale,
            stroke: am5.color(0x47bcff),
            strokeWidth: 0.4,
            fillOpacity: 1
          })
        );
        const outerCircle = container.children.push(
          am5.Circle.new(root, {
            radius: 60 * scale,
            fill: am5.color(0x47bcff),
            strokeOpacity: 0
          })
        );
        const midCircle = container.children.push(
          am5.Circle.new(root, {
            radius: 50 * scale,
            fill: am5.color(0x3283b3),
            strokeOpacity: 0
          })
        );
        const innerCircle = container.children.push(
          am5.Circle.new(root, {
            radius: 40 * scale,
            tooltipY: 0,
            fill: am5.color(0x1d4b66),
            strokeOpacity: 0,
            tooltipText: "{title}"
          })
        );
        setTimeout(function () {
          innerCircle.animate({
            key: "scale",
            from: 1,
            to: 1.2,
            duration: 4000,
            easing: am5.ease.yoyo(am5.ease.linear),
            loops: Infinity
          });
        }, 0);
        setTimeout(function () {
          midCircle.animate({
            key: "scale",
            from: 1,
            to: 1.1,
            duration: 4000,
            easing: am5.ease.yoyo(am5.ease.linear),
            loops: Infinity
          });
        }, 500);
        setTimeout(function () {
          outerCircle.animate({
            key: "scale",
            from: 1,
            to: 1.05,
            duration: 4000,
            easing: am5.ease.yoyo(am5.ease.linear),
            loops: Infinity
          });
        }, 1000);
        const pulses = [outerPulse, midPulse, innerPulse];
        pulses.forEach((pulse) => {
          pulse.animate({
            key: "scale",
            from: 1,
            to: 5,
            duration: 4000,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity
          });
          pulse.animate({
            key: "opacity",
            from: 1,
            to: 0,
            duration: 4000,
            easing: am5.ease.out(am5.ease.linear),
            loops: Infinity
          });
        });
        innerCircle.events.on("click", function (event) {
          root.dispose();
          context.selectedRegion = event.target.dataItem.dataContext;
        });
        return am5.Bullet.new(root, {
          sprite: container
        });
      });
      pointSeries.data.setAll([
        {
          lat: 15,
          lon: -80,
          title: "Americas",
          regionGeoData: americasGeoData
        },
        {
          lat: 50,
          lon: 15,
          title: "Europe",
          regionGeoData: am5geodata_region_world_europeLow
        },
        {
          lat: 20,
          lon: 100,
          title: "Asia",
          regionGeoData: am5geodata_region_world_asiaLow
        }
      ]);
      context.chart = chart;
      context.chart.continentSeries = continentSeries;
      context.chart.regionSeries = regionSeries;
      context.chart.pointSeries = pointSeries;
      function mergeGeoJSON(geoJSONs) {
        return {
          type: "FeatureCollection",
          features: geoJSONs.reduce((features, geoJSON) => {
            return features.concat(geoJSON.features);
          }, [])
        };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.mobile-regions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  list-style: none;
  li {
    font-size: 3rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
</style>
