import Vue from "vue";

const FILTER_TYPES = {
  DATA_TABLE: "DATA_TABLE",
  SCRAP_REPORTS: "SCRAP_REPORTS",
  DETAILS_TABLE: "DETAILS_TABLE"
};

const getDatasets = (data, colors) => {
  return [
    {
      data: data.map((item) => item.cost),
      label: Vue.i18n.translate("Scrap Cost"),
      backgroundColor: colors
    }
  ];
};

export { FILTER_TYPES, getDatasets };
