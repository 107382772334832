<template>
  <div class="commentFeed">
    <div class="card-header">
      <h2>{{ $t("Comments") }}</h2>
      <div class="options">
        <v-tabs
          class="tabs"
          v-model="tab"
          align-with-title
          v-if="special && !showNewComment"
          key="tabs"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="regular">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <i
                  v-bind="attrs"
                  v-on="on"
                  class="mdi mdi-message-text"
                />
              </template>
              <span>{{ $t("Comments") }}</span>
            </v-tooltip>
          </v-tab>
          <v-tab key="special">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <i
                  v-bind="attrs"
                  v-on="on"
                  class="fa fa-star"
                />
              </template>
              <span>{{ $t("Special Notes") }}</span>
            </v-tooltip>
          </v-tab>
        </v-tabs>
        <file-upload
          v-if="showNewComment"
          accept="image/png, image/jpeg"
          v-model="imageBase64"
          key="file"
        />
        <camera
          v-if="showNewComment"
          v-model="imageBase64"
          key="camera"
        />
        <video-upload
          v-if="showNewComment"
          accept="video/mp4,video/x-m4v,video/"
          v-model="video"
          key="video"
        />
        <v-btn
          color="white"
          light
          fab
          x-small
          @click="showNewComment = true"
          v-if="!showNewComment"
          key="open"
        >
          <i
            class="fa fa-plus"
            aria-hidden="true"
          ></i>
        </v-btn>
        <v-btn
          color="white"
          light
          fab
          x-small
          @click="showNewComment = false"
          v-if="showNewComment"
          key="close"
        >
          <i
            class="fa fa-times"
            aria-hidden="true"
          ></i>
        </v-btn>
      </div>
    </div>
    <div class="card-content">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <div
          class="newComment"
          v-if="showNewComment"
        >
          <v-textarea
            outlined
            rows="4"
            name="input-7-4"
            :label="$t('Add Comment')"
            v-model="comment"
          >
          </v-textarea>
          <div class="image">
            <v-btn
              color="error"
              light
              fab
              x-small
              @click="imageBase64 = false"
              v-if="imageBase64"
            >
              <i
                class="fa fa-times"
                aria-hidden="true"
              ></i>
            </v-btn>
            <img
              v-if="imageBase64"
              :src="imageBase64"
              class="preview"
            />
          </div>
          <div class="video">
            <v-btn
              color="error"
              light
              fab
              x-small
              @click="video = false"
              v-if="video"
            >
              <i
                class="fa fa-times"
                aria-hidden="true"
              ></i>
            </v-btn>
            <video-player
              v-if="video"
              :file="video"
            />
          </div>
          <div class="btns">
            <i
              v-if="special"
              @click="newCommentSpecial = !newCommentSpecial"
              class="fa fa-star"
              :class="{ active: newCommentSpecial }"
            />
            <v-btn
              color="error"
              @click="clearComment"
              :disabled="comment == ''"
            >
              {{ $t("Clear") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="submitComment"
              :loading="savingComment"
              :disabled="comment == ''"
            >
              {{ $t("Submit") }}
            </v-btn>
          </div>
        </div>
      </transition>
      <div class="feedOptions">
        <v-btn
          color="white"
          light
          fab
          x-small
          v-if="direction == 'DESC'"
          @click="direction = 'ASC'"
        >
          <i
            class="fa fa-arrow-up"
            aria-hidden="true"
          ></i>
        </v-btn>
        <v-btn
          color="white"
          light
          fab
          x-small
          v-if="direction == 'ASC'"
          @click="direction = 'DESC'"
        >
          <i
            class="fa fa-arrow-down"
            aria-hidden="true"
          ></i>
        </v-btn>
        <v-text-field
          v-model="searchComments"
          prepend-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <v-btn
          class="editBtn"
          color="white"
          light
          fab
          x-small
          v-if="hasUserRole(user, userRoles.Supervisor)"
          @click="editComments = !editComments"
        >
          <i
            class="fa fa-pencil"
            aria-hidden="true"
          ></i>
        </v-btn>
      </div>
      <v-data-iterator
        class="comments-wrapper"
        :items="comments"
        :itemsPerPage.sync="commentsPerPage"
        :page.sync="commentPageCurrent"
        :serverItemsLength="commentsTotal"
        no-data-text=""
        :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
      >
        <template v-slot:default="props">
          <transition-group
            :class="'comments'"
            name="slide-fade-list"
            mode="out-in"
            tag="div"
          >
            <div
              class="card comment"
              v-for="(comment, index) in props.items"
              :key="comment.node.id"
            >
              <div class="card-header">
                <p class="label">
                  <v-btn
                    color="error"
                    light
                    fab
                    x-small
                    v-if="editComments && hasUserRole(user, userRoles.Supervisor)"
                    @click="hideComment(comment)"
                  >
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                    ></i>
                  </v-btn>
                  {{ comment.node.userName }}
                </p>
                <p>{{ moment(comment.node.date).format($datetime.default) }}</p>
              </div>
              <div class="card-content">
                <p class="preserve-newline">{{ getCommentBody(comment) }}</p>
                <img
                  v-if="comment.node.imageUrl"
                  :src="comment.node.imageUrl"
                  class="preview"
                />
                <video-player
                  v-if="comment.node.videoUrl"
                  :file="comment.node.videoUrl"
                />
              </div>
            </div>
          </transition-group>
        </template>
      </v-data-iterator>
    </div>
  </div>
</template>

<style lang="scss">
@import "../scss/variables";
.commentFeed {
  max-height: 100%;
  height: 100%;
  .card-header {
    .options {
      display: flex;
      align-items: center;
      button {
        margin: 0 0.5rem;
        .fa {
          font-size: 1.25rem;
          color: $cardColor;
        }
      }
      .tabs {
        i {
          font-size: 1.15rem;
        }
      }
    }
  }
  .card-content {
    max-height: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    .preserve-newline {
      white-space: pre-wrap;
    }
    .newComment {
      .image {
        .v-btn {
          position: absolute;
          right: 2rem;
        }
        img {
          margin-top: -1rem;
          max-width: 100%;
          border-radius: 0.25rem;
        }
      }
      .btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .fa-star {
          height: 2rem;
          width: 2rem;
          line-height: 2rem;
          border-radius: 2rem;
          background: grey;
          margin-right: 1rem;
          text-align: center;
          cursor: pointer;
          transition: all 0.25s ease-in-out;
          &.active {
            background-color: $blue;
          }
        }
        .v-btn {
          margin: 0 0.5rem;
        }
      }
      .video {
        margin-top: -1rem;
        .v-btn {
          position: absolute;
          margin-top: 0.5rem;
          right: 2rem;
          z-index: 99;
        }
        .videoPlayer {
          height: 250px;
          border-radius: 0.25rem;
          overflow: hidden;
          video {
            height: 225px;
          }
        }
      }
    }
    .feedOptions {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      background: $cardColor;
      button {
        margin-right: 1rem;
        margin-top: 0.5rem;
        .fa-arrow-up,
        .fa-arrow-down,
        .fa-pencil {
          font-size: 1.25rem;
        }
      }
      .editBtn {
        margin-left: 1rem;
        margin-right: 0.5rem;
      }
    }
    .comments-wrapper {
      flex-shrink: 1;
      overflow-y: auto;
      .comments {
        padding: 0 0.5rem;
        padding-bottom: 4rem;
        .comment {
          margin: 1rem 0;
          padding: 0.5rem;
          min-height: 5rem;
          height: fit-content;
          .card-header {
            height: 2.5rem;
            .v-btn {
              margin-right: 0.5rem;
            }
          }
          .card-content {
            padding: 2rem 1rem 0 1rem;
            img {
              max-width: 100%;
              margin: 0.75rem 0;
              border-radius: 0.25rem;
            }
            .videoPlayer {
              height: 250px;
              border-radius: 0.25rem;
              overflow: hidden;
              video {
                height: 225px;
              }
            }
          }
        }
      }

      .v-data-footer {
        z-index: 9;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $cardColor;
      }
    }
  }
}
.theme--light.v-application {
  .commentFeed {
    .card-content {
      .feedOptions {
        background: $light-cardColor;
      }
      .comments-wrapper {
        .v-data-footer {
          background: $light-cardColor;
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import VideoPlayer from "@/components/VideoPlayer";
import Camera from "@/components/form/Camera.vue";
import FileUpload from "@/components/form/FileUpload.vue";
import VideoUpload from "@/components/form/VideoUpload.vue";
import { hasUserRole, userRoles } from "@/utils/user";

export default {
  props: {
    filters: {
      default: {}
    },
    newCommentParams: {
      default: {}
    },
    special: {
      default: false
    },
    reload: {
      default: false
    }
  },
  components: {
    Camera,
    FileUpload,
    VideoUpload,
    VideoPlayer
  },
  data() {
    return {
      editComments: false,
      imageBase64: null,
      video: null,
      comments: [],
      commentsTotal: 0,
      commentPageCurrent: 1,
      commentsPerPage: 10,
      loadPage: null,
      nextPage: null,
      prevPage: null,
      searchComments: "",
      showNewComment: false,
      comment: "",
      newCommentSpecial: false,
      savingComment: false,
      direction: "DESC",
      tab: "comments",
      userRoles: userRoles
    };
  },
  created() {
    this.loadComments();
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    })
  },
  methods: {
    moment,
    hasUserRole,
    loadComments() {
      this.showNewComment = false;
      const query = `query (
        $filters: GrapheneElasticFilterCommentSearchConnectionBackendFilter!,
        $direction: Direction!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String){
        comments(
          filter: $filters,
          ordering: {date: $direction},
          after: $after,
          before: $before,
          first: $first,
          last: $last,
          simpleQueryString: $search,
          facets: [user]){
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              userName,
              user,
              date,
              body,
              id,
              hidden
              imageUrl,
              videoUrl
            }
          }
        }
      }`;
      //TODO switch filter to use globalid instead of PK
      // Check creation too
      let filters = {
        special: { value: this.tab == 1 },
        hidden: { exclude: [true] }
      };
      const variables = {
        filters: _.merge(filters, this.filters),
        direction: this.direction,
        before: false,
        after: false,
        search: ""
      };
      if (this.searchComments) {
        variables["search"] = this.searchComments + "*";
      }
      if (this.loadPage) {
        Object.assign(variables, this.loadPage);
      } else {
        variables["first"] = this.commentsPerPage;
      }
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.loadPage = null;
          this.nextPage = res.data.data.comments.pageInfo.endCursor;
          this.prevPage = res.data.data.comments.pageInfo.startCursor;
          this.comments = res.data.data.comments.edges;
          this.commentsTotal = res.data.data.comments.facets.user.doc_count;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    clearComment() {
      this.comment = "";
      this.imageBase64 = false;
      this.video = false;
      this.newCommentSpecial = false;
    },
    submitComment() {
      this.savingComment = true;
      const query = `mutation ($commentData: CommentInput!){
        createComment(commentData:$commentData) {
          id,
          body,
          image,
          video
        }
      }`;
      let variables = { commentData: _.clone(this.newCommentParams) };
      variables["commentData"]["body"] = this.comment;
      if (this.imageBase64) {
        variables["commentData"]["image"] = this.imageBase64;
      }
      if (this.video) {
        variables["commentData"]["video"] = this.video;
      }
      if (this.newCommentSpecial) {
        variables["commentData"]["special"] = true;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          this.clearComment();
          setTimeout(function () {
            context.savingComment = false;
            //this emitted$ value is not used by all comment feeds (some have websocket updates)
            context.$emit("commentSaved");
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.savingComment = false;
        });
    },
    hideComment(comment) {
      comment.saving = true;
      const query = `mutation ($commentData: HideCommentInput!){
        hideComment(commentData:$commentData) {
          comment{
            id
            hidden
          }
        }
      }`;
      let variables = { commentData: { id: comment.node.id } };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          setTimeout(function () {
            comment.saving = false;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
          comment.saving = false;
        });
    },
    getCommentBody(comment) {
      if (!String(comment.node.body).includes("Reference Number Updated")) return comment.node.body;
      return comment.node.body.replace(
        "Reference Number Updated",
        this.$t("Reference Number Updated")
      );
    }
  },
  watch: {
    reload: function () {
      let loadComments = this.loadComments;
      setTimeout(loadComments, 500);
    },
    filters: {
      deep: true,
      handler: function () {
        this.comments = [];
        this.loadComments();
      }
    },
    direction: function () {
      this.loadComments();
    },
    searchComments: function () {
      if (this.searchDebouncerComments) {
        clearTimeout(this.searchDebouncerComments);
      }
      let load = this.loadComments;
      this.searchDebouncerComments = setTimeout(load, 500);
    },
    commentsPerPage: function () {
      this.commentCurrentPage = 1;
      this.loadComments();
    },
    commentPageCurrent: function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.loadPage = {
          after: this.nextPage,
          before: false,
          first: this.commentsPerPage
        };
        this.loadComments();
      } else if (newPage < oldPage) {
        this.loadPage = {
          after: false,
          before: this.prevPage,
          last: this.commentsPerPage
        };
        this.loadComments();
      }
    },
    tab: function () {
      this.loadComments();
    }
  }
};
</script>
