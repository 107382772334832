var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manualTrend metric-component"},[_c('h2',{staticClass:"card-title text-subtitle-2 color--text-primary text-uppercase mb-3"},[_vm._v("\n    "+_vm._s(_vm.metric.node.name)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"charts"},[_c('div',{staticClass:"mb-5 text-h5 color--text-primary font-weight-medium"},[_vm._v("\n      "+_vm._s(_vm.metric.node.currentValue && _vm.metric.node.currentValue.value
          ? parseFloat(_vm.metric.node.currentValue.value.replace(/\D/g, ""))
          : "0".trim())+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"charts_container"},[_c('div',{staticClass:"chart"},[_c('BarChart',{staticClass:"dynamicChart",attrs:{"chart-name":_vm.metric.node.name,"chart-data":_vm.chart_data[_vm.metric.node.name],"chart-options":{
            scales: {
              yAxes: [{ zeroLineColor: '#FFF' }],
              xAxes: [
                {
                  ticks: {
                    minRotation: 90,
                    maxRotation: 90
                  }
                }
              ]
            }
          }}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }