<template>
  <div class="enlargedCard card">
    <div class="card-header">
      <div class="tabs-compress-container">
        <v-tabs
          class="tabs"
          v-model="tab"
          align-with-title
          key="tabs"
        >
          <v-tab key="summary">
            <p>
              <span class="button_text">{{ $t("Summary") }}</span>
              <span class="tiny_button_text">{{ $t("Summary") }}</span>
            </p>
          </v-tab>
          <v-tab key="unresolved">
            <p>
              <span class="button_text">{{ enlargedTable.title }}</span>
              <span class="tiny_button_text">{{ enlargedTable.title }}</span>
            </p>
          </v-tab>
        </v-tabs>
        <v-btn
          class="expand first-compress-button"
          small
          light
          color="white"
          fab
          @click.stop.prevent="closeTable"
        >
          <i
            class="fa fa-compress"
            aria-hidden="true"
          ></i>
        </v-btn>
      </div>
      <div
        v-if="tab === 0"
        class="preset-container"
      >
        <div class="preset-date-range">
          <h3>{{ fromDate }} - {{ toDate }}</h3>
        </div>
        <div class="preset-title-buttons-container">
          <h3>{{ $t("Presets") }}</h3>
          <div class="interval-buttons-container">
            <button
              class="btn radioBtn interval-buttons"
              v-for="(btn, btnIndex) in intervals"
              :key="btn.value"
              @click="handlePresetClick(btn.value)"
              :class="{ active: btn.value === selectedInterval }"
            >
              {{ btn.name }}
            </button>
          </div>
        </div>
      </div>
      <v-btn
        class="expand second-compress-button"
        small
        light
        color="white"
        fab
        @click.stop.prevent="closeTable"
      >
        <i
          class="fa fa-compress"
          aria-hidden="true"
        ></i>
      </v-btn>
    </div>
    <div
      v-if="tab === 0"
      class="card-content"
    >
      <Summary
        :enlargedTable="enlargedTable"
        :reasons="reasons"
        :machineGroupPk="machineGroupPk"
        :machines="machines"
        :selectedInterval="selectedInterval"
        :setPresetDates="setPresetDates"
        :setInterval="setInterval"
        ref="summary"
      />
    </div>
    <div
      v-if="tab === 1"
      class="card-content"
    >
      <UnresolvedIssues
        :enlargedTable="enlargedTable"
        :getInprocess="getInprocess"
        :getLatest="getLatest"
        :getOldest="getOldest"
        :enlargedIssueHeaders="enlargedIssueHeaders"
        :loadIssuePage="loadIssuePage"
        :handleItemsPerPageChange="handleItemsPerPageChange"
        :getStatusIcon="getStatusIcon"
        :machinesLookup="machinesLookup"
        :loadIssues="loadIssues"
      />
    </div>
  </div>
</template>
<script>
import Summary from "./Summary.vue";
import UnresolvedIssues from "./UnresolvedIssues.vue";

export default {
  name: "EnlargedTable",
  props: {
    enlargedTable: { type: Object, default: () => {} },
    reasons: { type: Array, default: () => [] },
    machineGroupPk: { type: Number },
    machines: { type: Array, default: () => [] },
    selectedInterval: { type: String, default: "" },
    setPresetDates: { type: Function, default: () => null },
    getInprocess: { type: Function, default: () => null },
    getLatest: { type: Function, default: () => null },
    getOldest: { type: Function, default: () => null },
    enlargedIssueHeaders: { type: Array, default: () => [] },
    loadIssuePage: { type: Function, default: () => null },
    handleItemsPerPageChange: { type: Function, default: () => null },
    getStatusIcon: { type: Function, default: () => null },
    machinesLookup: { type: Object, default: () => {} },
    closeTable: { type: Function, default: () => null },
    fromDate: { type: String, default: "" },
    toDate: { type: String, default: "" },
    handlePresetClick: { type: Function, default: () => null },
    intervals: { type: Array, default: () => [] },
    setInterval: { type: Function, default: () => null },
    loadIssues: { type: Function, default: () => null }
  },
  components: {
    Summary,
    UnresolvedIssues
  },
  data() {
    return {
      tab: 0
    };
  }
};
</script>
