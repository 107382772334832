var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card full-height"},[_c('div',{staticClass:"header"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Minor Losses")))]),_vm._v(" "),_c('div',{staticClass:"icons"},[_c('i',{staticClass:"fa fa-table",class:_vm.chartType === _vm.chartTypes.TABLE ? 'selected' : null,on:{"click":function () {
            _vm.chartType = _vm.chartTypes.TABLE;
          }}}),_vm._v(" "),_c('i',{staticClass:"fa fa-bar-chart",class:_vm.chartType === _vm.chartTypes.CHART ? 'selected' : null,on:{"click":function () {
            _vm.chartType = _vm.chartTypes.CHART;
          }}})])]),_vm._v(" "),(_vm.chartType == _vm.chartTypes.CHART && !_vm.loading)?_c('div',{staticClass:"chart-container"},[(_vm.minorLosses)?_c('TreeMapChart',{staticClass:"tree-map",attrs:{"data":_vm.minorLosses.filter(function (loss) { return this$1.localFiltered.indexOf(loss.data.status_code) < 0; }),"label-format":"{name} {value}%","id":'tree-map',"handle-click":_vm.handleClick}}):_vm._e(),_vm._v(" "),(_vm.minorLosses)?_c('div',{staticClass:"legend"},_vm._l((_vm.minorLosses),function(loss,i){return _c('div',{staticClass:"legend-container",on:{"click":function () {
            _vm.handleClickLegend(loss.data.status_code);
          }}},[_c('div',{staticClass:"swatch",class:_vm.machineStatusClassName(loss.data)},[_c('i',{staticClass:"cross-out mdi mdi-close",style:({
              visibility: _vm.localFiltered.indexOf(loss.data.status_code) < 0 ? 'hidden' : 'visible'
            })})]),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v(_vm._s(loss.name)+" "+_vm._s(loss.value)+"%")])])}),0):_vm._e()],1):(_vm.chartType == _vm.chartTypes.TABLE && !_vm.loading)?_c('div',{staticClass:"chart-container"},[_c('GenericTable',{attrs:{"columns":[
        { title: '', key: 'name' },
        { title: _vm.$t('Duration'), key: 'duration', format: _vm.seconds, sortable: true },
        { title: _vm.$t('Total Downtime'), key: 'total_downtime', format: _vm.seconds, sortable: false },
        {
          title: _vm.$t('Downtime Percent'),
          key: 'downtime_percentage',
          format: _vm.percent,
          sortable: true
        },
        { title: _vm.$t('Production Loss'), key: 'loss', format: _vm.percent, sortable: true }
      ],"data":_vm.tableData}})],1):_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }