<template>
  <div class="root">
    <v-btn
      :class="{ selected: isSelected }"
      :disabled="disabled"
      class="root-btn"
      primary
      text
      outlined
      v-bind="attrs"
      v-on="on"
      @click="action"
    >
      {{ text }}
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "ZoneControlReason",
  props: {
    action: {
      type: Function,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  width: 100%;
}

.root-btn {
  height: 40px !important;
  width: 100%;
  margin: 8px 0 !important;
}

.root-btn {
  border: 1px solid var(--border-primary) !important;
  border-radius: 4px;
  display: block;
  width: 100%;
  text-align: center;
  padding: 8px 12px;
  background: none !important;
  box-shadow: none;
  margin: 0 !important;
  text-transform: none;
  height: auto;
  letter-spacing: normal;

  &.selected {
    border: 1px solid var(--btn-primary) !important;
  }

  &:disabled {
    border: 1px solid var(--btn-secondary) !important;
    color: var(--text-disabled);
    background: none !important;
  }
}

.root-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: normal;
  display: block;
  line-height: 1.2em;
  white-space: nowrap;
}
</style>
