<template>
  <div class="p911-analytics">
    <P911Filters
      :selectedFilterType="selectedFilterType"
      :applyFilters="applyFilters"
      :applySelectedFilterType="applySelectedFilterType"
      :helpTags="helpTags"
      :defaultFilters="defaultFilters"
    />
    <div
      class="card no-data"
      v-if="loading || noData"
    >
      <p v-if="noData">{{ $t("No Data") }}</p>
      <div class="loading-bar">
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
      </div>
    </div>
    <P911DataTableView
      ref="dataTableView"
      v-show="showDataTableView && !loading && !noData"
      :filters="filters"
    />
    <P911ImpactSummaryView
      ref="impactSummaryView"
      v-show="showImpactSummaryView && !loading && !noData"
      :filters="filters"
      :interactiveFilters="interactiveFilters"
      :helpTags="helpTags"
      :filterChange="filterChange"
    />
    <P911PerformanceSummaryView
      ref="performanceSummaryView"
      v-show="showPerformanceSummaryView && !loading && !noData"
      :filters="filters"
      :levelP1="levelP1"
      :levelP2="levelP2"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import isEmpty from "lodash/isEmpty";

import { FILTER_TYPES } from "@/utils/p911AnalyticsUtils";

import P911Filters from "./P911Filters.vue";
import P911DataTableView from "./data-table-view/P911DataTableView.vue";
import P911ImpactSummaryView from "./impact-summary-view/P911ImpactSummaryView.vue";
import P911PerformanceSummaryView from "./performance-summary-view/P911PerformanceSummaryView.vue";
import { queryArrayToNumber } from "../../../utils/queryArrayToNumber";

export default {
  name: "P911Analytics",
  components: {
    P911Filters,
    P911DataTableView,
    P911ImpactSummaryView,
    P911PerformanceSummaryView
  },
  data() {
    const query = this.$route.query || {};
    const defaultFilters = {
      ...query,
      selectedPreset: query.selectedPreset ? Number(query.selectedPreset) : undefined,
      fromDate: query.fromDate ? new Date(query.fromDate) : undefined,
      toDate: query.toDate ? new Date(query.toDate) : undefined,
      selectedMachines: queryArrayToNumber(query.selectedMachines),
      selectedMachineGroups: queryArrayToNumber(query.selectedMachineGroups)
    };

    return {
      loading: false,
      noData: true,
      interactiveFilters: { machines: [], helpTag: null },
      selectedFilterType: FILTER_TYPES.DATA_TABLE,
      filters: defaultFilters,
      defaultFilters,
      showPerformanceSummaryView: false,
      showImpactSummaryView: false,
      showDataTableView: true,
      helpTags: [],
      filterChange: false,
      levelP1: null,
      levelP2: null
    };
  },
  mounted() {
    if (!isEmpty(this.$route.query)) {
      this.loadFilters();
    }
  },
  async created() {
    this.setTitles({
      title: this.$t("P911 Analytics"),
      mobile: this.$t("P911 Analytics")
    });
    await Promise.all([this.loadHelpTags(), this.getP911ReportMachineGroupLevels()]);
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    applySelectedFilterType(filterType) {
      this.selectedFilterType = FILTER_TYPES[filterType];
    },
    applyFilters(filters) {
      this.filters = filters;
    },
    handlePerformanceSummaryViewVisibility(isVisible) {
      this.showPerformanceSummaryView = isVisible;
    },
    handleImpactSummaryViewVisibility(isVisible) {
      this.showImpactSummaryView = isVisible;
    },
    handleDataTableViewVisibility(isVisible) {
      this.showDataTableView = isVisible;
    },
    handleLoading(isLoading) {
      this.loading = isLoading;
    },
    handleNoData(noData) {
      this.noData = noData;
    },
    clearInteractiveFilters() {
      Object.keys(this.interactiveFilters).forEach((item) => {
        this.interactiveFilters[item] = item === "helpTag" ? null : [];
      });
    },
    async loadHelpTags() {
      const query = `{
        helpTags {
          name,
          color,
          id,
          pk
          lft,
          rgt,
          treeId,
          depth
        }
      }`;
      try {
        const response = await this.$http.get("graphql/", { params: { query } });
        if (response && response.data && response.data.data && response.data.data.helpTags) {
          this.helpTags = response.data.data.helpTags;
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    },
    async getP911ReportMachineGroupLevels() {
      const query = `{
        settings {
          p911ReportMachineGroupLevelP1 {
            pk,
            name,
            level,
          }
          p911ReportMachineGroupLevelP2 {
            pk,
            name,
            level,
          }
        }
      }`;
      try {
        const response = await this.$http.get("graphql/", { params: { query } });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.settings &&
          response.data.data.settings.length > 0
        ) {
          this.levelP1 = response.data.data.settings[0]["p911ReportMachineGroupLevelP1"];
          this.levelP2 = response.data.data.settings[0]["p911ReportMachineGroupLevelP2"];
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    },
    async loadFilters() {
      if (!this.filters.fromDate || !this.filters.toDate) {
        return;
      }

      this.filterChange = true;
      this.clearInteractiveFilters();
      Vue.nextTick(() => {
        Promise.all([
          this.$refs.dataTableView.getHelpTickets(),
          this.$refs.impactSummaryView.$refs.P911AnalyticsMachines[0].getTopMachinesData(),
          this.$refs.impactSummaryView.$refs.P911AnalyticsHelpTags[0].getTicketsByTag(),
          this.$refs.impactSummaryView.$refs.P911TicketTable[0].getHelpTicketsData(),
          this.$refs.performanceSummaryView.getP911TrendData(),
          this.$refs.performanceSummaryView.$refs.P911GroupLevelOneChart[0].getP911ByGroupLevelP1(),
          this.$refs.performanceSummaryView.$refs.P911GroupLevelTwoChart[0].getP911ByGroupLevelP2(),
          this.$refs.performanceSummaryView.$refs.P911TicketsByMachine[0].getP911ByMachines()
        ]).then(() => {
          this.loading = false;
          this.noData = false;
          this.filterChange = false;
        });
      });
    }
  },
  watch: {
    selectedFilterType(newValue, oldValue) {
      switch (newValue) {
        case FILTER_TYPES.DATA_TABLE:
          this.handlePerformanceSummaryViewVisibility(false);
          this.handleImpactSummaryViewVisibility(false);
          this.handleDataTableViewVisibility(true);
          break;
        case FILTER_TYPES.IMPACT_SUMMARY:
          this.handlePerformanceSummaryViewVisibility(false);
          this.handleDataTableViewVisibility(false);
          this.handleImpactSummaryViewVisibility(true);
          break;
        case FILTER_TYPES.PERFORMANCE_SUMMARY:
          this.handleImpactSummaryViewVisibility(false);
          this.handleDataTableViewVisibility(false);
          this.handlePerformanceSummaryViewVisibility(true);
          break;
      }
    },
    filters() {
      this.loadFilters();
    }
  }
};
</script>
<style lang="scss">
@import "./P911Analytics.scss";
</style>
