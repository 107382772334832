import moment from "moment";
import Vue from "vue";

/**
 * Default value method/filter to use in templates
 * @param value - value to check
 * @param def - fallback value
 * @param truthy - value to display when exists
 * @returns {*}
 */
export function d(value, def, truthy) {
  if (value == null || value == undefined) {
    return def;
  }
  if (truthy == null || truthy == undefined) {
    return value;
  }
  return truthy;
}

/**
 * Abbreviates long numbers to non-scientific notation
 * @param value
 * @returns {*}
 */
export function shorten(value, precision = 0, sbt) {
  if (Math.abs(value) >= 1000000000000) {
    return `${(value / 1000000000000).toPrecision(3)}T`;
  } else if (Math.abs(value) >= 1000000000) {
    return `${(value / 1000000000).toPrecision(3)}B`;
  } else if (Math.abs(value) >= 1000000) {
    return `${(value / 1000000).toPrecision(3)}M`;
  } else if (Math.abs(value) >= 1000) {
    return `${(value / 1000).toPrecision(3)}K`;
  } else if (Math.abs(value) < 1) {
    return parseFloat(value).toPrecision(2);
  }

  if (sbt) {
    return parseFloat(value).toPrecision(precision);
  } else {
    return parseFloat(value).toFixed(precision);
  }
}

export function numberWithCommas(x) {
  // see Number.prototype.toLocaleString()
  if (x === 0) {
    return 0;
  }
  if (x != null) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return "";
}

function _seconds(time, precise = false, single = false) {
  var hour = 0;
  var min = 0;
  var day = 0;
  if (time === 0) {
    return "0 Secs";
  }

  if (time < 0) {
    hour = Math.floor((time / 3600) * -1);
    min = Math.floor((-1 * (time % 3600)) / 60);
  } else {
    day = Math.floor(time / 86400);
    hour = Math.floor((time % 86400) / 3600);
    min = Math.floor((time % 3600) / 60);
  }
  var secs = Math.floor(time % 60);
  var string = "";
  if (day > 0) {
    //For numer with commas seprated -  Fixed by VTE
    var iday = day;
    day = numberWithCommas(day);
    string = string.concat(day + " Day" + (iday > 1 ? "s " : " "));
    if (single) {
      return string;
    }
  }
  if (hour > 0) {
    string = string.concat(numberWithCommas(hour) + " Hour" + (hour > 1 ? "s " : " "));
    if (single) {
      return string;
    }
  }
  if (min > 0 && !precise && day < 1) {
    string = string.concat(min + " Min" + (min > 1 ? "s" : ""));
    if (single) {
      return string;
    }
  }
  if (precise) {
    if (precise == "exact" && secs > 0) {
      string = string.concat(secs + " Sec" + (secs > 1 ? "s" : ""));
    } else if (day == 0 && hour == 0 && min == 0) {
      string = string.concat(secs + " Sec" + (secs > 1 ? "s" : ""));
    }
  }
  return string;
}

export function seconds(time, precise = false, single = false) {
  return _seconds(time, precise, single)
    .split(" ")
    .map((word) => Vue.i18n.translate(word.trim()))
    .join(" ");
}

/**
 * Formats text as markdown
 */
const marked = require("marked");
export function markdown(text) {
  return marked(text, { gmf: true, sanitize: true, breaks: true });
}

export function time(date, minimal = false) {
  if (date) {
    let m = moment(date);
    if (minimal) {
      return m.format("MM/DD/YYYY HH:mm");
    }
    return m.format("MMM D, YYYY, hh:mm A");
  }
  return "--";
}
export function basic_date(date) {
  if (date) {
    let m = moment(date);
    return m.format("MM/DD/YYYY");
  }
  return "--";
}
export function basic_date_dash(date) {
  if (date) {
    let m = moment(date);
    return m.format("YYYY-MM-DD");
  }
  return "--";
}
export function date_time(date) {
  if (date) {
    let m = moment(date);
    return m.format("dddd, MMMM Do YYYY, h:mm a");
  }
  return "--";
}
export function duration(date) {
  if (date) {
    let m = moment.utc(date);
    let m_str = m.format("DD:HH:mm");
    if (m_str.indexOf("00") > -1) {
      return m.format("HH:mm") + " hours";
    }
    return m.format("DD") + " days";
  }
  return "--";
}

export function floatToPercent(number) {
  number = parseFloat(number);

  if (isNaN(number)) {
    return "Incomplete";
  }
  if (number < 0) {
    return "N/A";
  }
  return (number * 100).toFixed(2) + "%";
}

export function capitalize(string) {
  if (string.charAt) {
    let caps = string.charAt(0).toUpperCase() + string.slice(1);
    return caps.replace("_", " ");
  } else {
    return string;
  }
}

/**
 * Adds digit padding to numbers
 * @param number
 * @param digits
 * @returns {*}
 */
Vue.filter("fixed", function (number, digits = 2) {
  number = parseFloat(number);
  if (isNaN(number)) {
    return 0.0;
  }
  return number.toFixed(digits);
});
