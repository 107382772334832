<template>
  <main class="lh-container">
    <SearchForm />
  </main>
</template>

<script>
import { SearchForm } from "./components";

export default {
  name: "LightHouseSearchView",
  components: { SearchForm }
};
</script>

<style scoped lang="scss">
.lh-container {
  height: 100%;
  display: grid;
}
</style>
