<template>
  <div class="my-5">
    <FiltersDropdown
      v-if="!machine_id"
      :title="$t('Machine')"
      name="machine_ids"
      class="mb-5"
      :value="filters.machine_ids"
      query-key="quality-events-filters"
      :query-params="requestParams"
      :query-fn="ticketsApi.getFiltersLdms"
      query-field-name="machine_ids"
      v-on="$listeners"
    />

    <FiltersDropdown
      :title="$t('Issue')"
      name="issues"
      class="mb-5"
      :value="filters.issues"
      query-key="quality-events-filters"
      :query-params="requestParams"
      :query-fn="ticketsApi.getFiltersLdms"
      query-field-name="issues"
      v-on="$listeners"
    />

    <FiltersDropdown
      :title="$t('Ticket status')"
      name="statuses"
      class="mb-5"
      :is-text-as-value="true"
      :value="filters['statuses']"
      query-key="quality-events-filters"
      :query-params="requestParams"
      :query-fn="ticketsApi.getFiltersLdms"
      :multiple="false"
      :data-adapter="statusFieldAdapter"
      query-field-name="statuses"
      v-on="$listeners"
    />

    <FiltersDropdown
      :title="$t('Assigned')"
      name="assigned_to"
      class="mb-5"
      :value="filters.assigned_to"
      query-key="quality-events-filters"
      :query-params="requestParams"
      :query-fn="ticketsApi.getFiltersLdms"
      query-field-name="assigned_to"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { computed, toRefs } from "@vue/composition-api";
import FiltersDropdown from "@/common/filters/FiltersDropdown.vue";
import moment from "moment/moment";
import { ticketsApi } from "@/features/tickets/tickets-api";
import { useIntl } from "@/shared/useIntl";

export default {
  name: "TicketsFiltersForm",
  components: {
    FiltersDropdown
  },
  props: {
    filters: {
      type: Object,
      default: undefined
    },
    params: { type: Object, default: undefined },
    machine_group_pk: { type: Number, default: undefined },
    machine_id: { type: Number, default: undefined }
  },

  setup(props) {
    const { params, machine_group_pk, machine_id } = toRefs(props);
    const { $t } = useIntl();

    const TICKETS_STATUS_LABELS = {
      in_progress: $t("In Progress"),
      open: $t("Open"),
      closed: $t("Closed")
    };

    const statusFieldAdapter = (arr) =>
      arr.map((item) => ({
        text: TICKETS_STATUS_LABELS[item],
        value: item
      }));

    const requestParams = computed(() => ({
      machine_group_id: machine_group_pk.value,
      "machine_ids[]": machine_id.value,
      from_date: moment(params.value.dateFrom).toISOString(),
      to_date: moment(params.value.dateTo).toISOString(),
      dashboard: "ldms"
    }));

    return {
      requestParams,
      ticketsApi,
      statusFieldAdapter
    };
  }
};
</script>
