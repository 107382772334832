<template>
  <div class="ai-vision-analytics">
    <div class="ai-vision-analytics-filters">
      <AiVisionFilters
        :hideHelp="true"
        :datatableToggle="true"
        :setupFilters="setupFilters"
        :loadDocuments="loadDocuments"
        :autoload="true"
      />
    </div>
    <div
      class="main-ai-vision-analytics"
      v-if="filters.selectedFilterType === FILTER_TYPES.DATA_TABLE"
    >
      <ai-vision-analytics-data-grid :filters="filters" />
    </div>
    <div
      class="main-ai-vision-analytics"
      v-if="filters.selectedFilterType === FILTER_TYPES.ANALYTICS"
    >
      <div class="top-cards">
        <div class="summary-card total-processed">
          <div>
            <h3>{{ $t("Total Processed (pcs)") }}</h3>
          </div>
          <div class="value">
            <h1 class="right">{{ numPass + numFail }}</h1>
          </div>
        </div>
        <div class="summary-card total-passed">
          <div>
            <h3>{{ $t("Total Passed (pcs)") }}</h3>
          </div>
          <div class="value">
            <h1 class="right">{{ numPass }}</h1>
          </div>
        </div>
        <div class="summary-card total-failed">
          <div>
            <h3>{{ $t("Total Failed (pcs)") }}</h3>
          </div>
          <div class="value">
            <h1 class="right">{{ numFail }}</h1>
          </div>
        </div>
        <div class="summary-card first-pass-yield">
          <div>
            <h3>{{ $t("First Pass Yield") }}</h3>
          </div>
          <div class="value">
            <h1 class="right">
              {{
                numPass + numFail !== 0
                  ? `${((numPass / (numPass + numFail)) * 100).toFixed(2)}%`
                  : "---"
              }}
            </h1>
          </div>
        </div>
      </div>
      <div class="performance-summary main-cards">
        <div class="card">
          <a-i-vision-performance-summary :data="resultByAsset" />
        </div>
      </div>
      <div class="project-summary main-cards">
        <div class="card">
          <a-i-vision-reject-summary-table
            :data="rejectSummary"
            :add-chart="addChart"
            :remove-chart="removeChart"
          />
        </div>
      </div>
      <div
        v-if="rejectTrendCharts.length > 0"
        class="machine-info main-cards"
      >
        <div
          v-for="r in rejectTrendCharts"
          class="inner-machine-info"
        >
          <div class="card">
            <AiVisionRejectTrendChart
              :rejectTrend="r"
              :filters="filters"
            />
          </div>
        </div>
      </div>
      <div class="reject-summary-trend main-cards">
        <div class="card">
          <AiVisionRejectTrendSummaryChart
            :data="rejectSummaryTrend.trend"
            :machine="rejectSummaryTrend.machine"
            :scale="filters.scale || defaultScale"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AiVisionFilters, { FILTER_TYPES } from "../../aivision/AiVisionFilters";
import AIVisionPerformanceSummary from "./AIVisionPerformanceSummary";
import AIVisionRejectSummaryTable from "./AIVisionRejectSummaryTable";
import AiVisionAnalyticsDataGrid from "./AiVisionAnalyticsDataGrid";
import AiVisionRejectTrendChart from "./AiVisionRejectTrendChart";
import AiVisionRejectTrendSummaryChart from "./AiVisionRejectTrendSummaryChart";

export default {
  name: "AiVisionAnalytics",
  components: {
    AiVisionAnalyticsDataGrid,
    AiVisionRejectTrendSummaryChart,
    AiVisionRejectTrendChart,
    AIVisionRejectSummaryTable,
    AIVisionPerformanceSummary,
    AiVisionFilters
  },
  data() {
    return {
      FILTER_TYPES,
      defaultScale: "1d",
      filters: {},
      numPass: 0,
      numFail: 0,
      resultByAsset: [],
      rejectSummary: [],
      rejectTrendCharts: [],
      rejectTrendData: [],
      rejectSummaryTrend: { trend: [], machine: {} }
    };
  },
  methods: {
    setupFilters(filters) {
      this.filters = filters;
    },
    loadDocuments() {
      const filters = {
        asset_id: this.filters.asset && this.filters.asset.asset_id,
        machine_id: this.filters.machine && this.filters.machine.machine_id,
        part_name: this.filters.part && this.filters.part.part_name,
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        process_type: this.filters.process_types,
        inspection_result: this.filters.inspection_result,
        scale: this.filters.scale || this.defaultScale
      };

      this.loadResultByAsset(filters);
      this.loadSummary(filters);
      this.loadRejectSummary(filters);
      this.loadRejectSummaryTrend(filters);
    },
    addChart(data) {
      this.rejectTrendCharts.push(data);
      this.rejectTrendCharts = [...this.rejectTrendCharts];
    },
    removeChart(data) {
      this.rejectTrendCharts = this.rejectTrendCharts.filter((v) => {
        return v.part_id !== data.part_id || v.failure_mode !== data.failure_mode;
      });
    },
    loadRejectSummary(filters) {
      this.$http.get("aivision/rejectSummary/", { params: filters }).then((result) => {
        this.rejectSummary = result.data.machine.buckets;
      });
    },
    loadRejectSummaryTrend(filters) {
      this.$http.get("aivision/rejectSummaryTrend/", { params: filters }).then((result) => {
        this.rejectSummaryTrend = {
          trend: result.data.trend.buckets,
          machine: result.data.machine
        };
      });
    },
    loadResultByAsset(filters) {
      this.$http.get("aivision/resultByAsset/", { params: filters }).then((result) => {
        this.resultByAsset = result.data.buckets;
      });
    },
    loadSummary(filters) {
      this.numPass = 0;
      this.numFail = 0;
      this.$http.get("aivision/failsummary/", { params: filters }).then((result) => {
        for (const bucket of result.data.buckets) {
          if (bucket.key.toLowerCase() === "pass") {
            this.numPass = bucket.count.value;
          } else {
            this.numFail = bucket.count.value;
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.ai-vision-analytics {
  display: flex;
  padding: 0 10px;
  overflow: hidden;
  height: 100%;

  .ai-vision-analytics-filters {
    width: 340px;
    padding: 0 10px;
    overflow-y: auto;
    display: flex;
  }
  .main-ai-vision-analytics {
    flex: 1;
    flex-direction: column;
    padding: 0 5px;
    overflow: auto;
    display: flex;

    .main-cards {
      padding: 5px 0;
    }
    .top-cards {
      height: 90px !important;
      flex: unset;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 5px;

      .summary-card {
        border-radius: 5px;
        height: 100%;
        color: black;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90px;
        flex: 1;

        div {
          flex: 1;
        }

        h1 {
          font-size: 22px;
        }

        .value {
          flex: unset;
          bottom: 5px;
          right: 10px;
        }

        .right {
          text-align: right;
        }

        &.total-processed {
          background: rgb(254, 217, 182);
        }

        &.total-passed {
          background: rgb(0, 174, 184);
        }

        &.total-failed {
          background: rgb(240, 113, 102);
        }

        &.first-pass-yield {
          background: rgb(0, 129, 166);
        }
      }
    }
    .performance-summary {
      height: 500px;
      min-height: 500px;
      .card {
        height: 100%;
      }
    }
    .project-summary {
      height: 300px;
      min-height: 300px;
      .card {
        height: 100%;
      }
    }
    .machine-info {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      .inner-machine-info {
        .card {
          min-height: 400px;
        }
      }
    }
    .reject-summary-trend {
      height: 400px;
      min-height: 400px;
      .card {
        height: 100%;
      }
    }
  }
}
</style>
