<template>
  <div class="realtime_table">
    <v-btn
      fab
      x-small
      light
      @click="showSearch = !showSearch"
      class="hideDesktop"
    >
      <i
        class="fa fa-filter"
        v-if="!showSearch"
      ></i>
      <i
        class="fa fa-times"
        v-if="showSearch"
      ></i>
    </v-btn>
    <div
      class="searchbars"
      v-if="vw > 1200 || showSearch"
    >
      <v-autocomplete
        :placeholder="$t('Zone')"
        :items="Array.from(zoneSet)"
        @change="
          (e) => {
            setFilter('zone', e);
          }
        "
        :value="filters.zone"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('zone', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.zone != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Machine')"
        :items="Array.from(machineSet)"
        @change="
          (e) => {
            setFilter('machine', e);
          }
        "
        :value="filters.machine"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('machine', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.machine != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Part')"
        :items="Array.from(partSet)"
        @change="
          (e) => {
            setFilter('part', e);
          }
        "
        :value="filters.part"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('part', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.part != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Operator')"
        :items="Array.from(operatorSet)"
        @change="
          (e) => {
            setFilter('name', e);
          }
        "
        :value="filters.name"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('name', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.name != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Operator Status')"
        :items="Array.from(operatorStatusSet)"
        @change="
          (e) => {
            setFilter('checked_in', e == null ? null : e == 'Checked-In');
          }
        "
        :value="
          filters.checked_in == null ? null : filters.checked_in ? 'Checked-In' : 'Checked-Out'
        "
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('checked_in', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.checked_in != null"
        />
      </div>
    </div>
    <div class="middle_row">
      <div class="box">
        <div class="shift_performance blue-title">
          <h3 v-if="vw > 1000">{{ $t("Shift Performance") }}</h3>
          <h4>
            {{ fromDate && fromDate.format("MMMM DD, YYYY hh:mm A") }} -
            {{ toDate && toDate.format("MMMM DD, YYYY hh:mm A") }}
          </h4>
        </div>
      </div>

      <div class="box totals">
        <v-card class="card checked_in">
          <div class="checked_in_box">
            <div class="blue-title">{{ $t("Total Checked-In") }}</div>
            <div>{{ numCheckedIn }}</div>
          </div>
          <div>
            <div class="checked_in_box">
              <div class="blue-title">{{ $t("Total Checked-Out") }}</div>
              <div>{{ numCheckedOut }}</div>
            </div>
          </div>
        </v-card>
      </div>

      <div class="box intervalSelection">
        <v-tabs
          @change="ChangeCalendar"
          fixed-tabs
          :value="calendarCase"
        >
          <v-tab>{{ $t("Shift") }}</v-tab>
          <v-tab>{{ $t("Day") }}</v-tab>
          <v-tab>{{ $t("Week") }}</v-tab>
          <v-tab>{{ $t("Month") }}</v-tab>
          <v-tab>{{ $t("Year") }}</v-tab>
        </v-tabs>
      </div>
    </div>
    <v-data-table
      :headers="computed_table_headers"
      :table_headers="header_options"
      :items="filtered_items"
      @click:row="rowClick"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="key"
      :hide-default-header="vw > 1000"
      :loading="loading"
    >
      <template
        v-slot:header="{ props }"
        v-if="vw > 1000"
      >
        <thead>
          <tr>
            <th v-for="head in computed_table_headers">
              <div
                class="header_item"
                v-bind:class="head.class"
                @click="
                  () => {
                    sortby(head);
                  }
                "
              >
                <div class="rowcontent">{{ head.text }}</div>
                <i
                  v-if="head.sort == 'asc'"
                  class="mdi mdi-arrow-up"
                />
                <i
                  v-else-if="head.sort == 'desc'"
                  class="mdi mdi-arrow-down"
                />
                <i v-else></i>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.expand="{ item }">
        <v-btn
          x-small
          fab
          light
          v-if="item.machines && item.machines.length > 1 && !expanded.includes(item)"
        >
          <i
            class="fa fa-caret-down"
            aria-hidden="true"
          ></i>
        </v-btn>
        <v-btn
          x-small
          fab
          light
          v-if="item.machines && item.machines.length > 1 && expanded.includes(item)"
        >
          <i
            class="fa fa-caret-up"
            aria-hidden="true"
          ></i>
        </v-btn>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div class="table_name">
          <div
            class="icon"
            v-bind:style="
              item.avatar
                ? {
                    background: `url(${item.avatar})`,
                    'background-size': 'cover',
                    'background-position-x': 'center',
                    'background-position-y': 'center'
                  }
                : null
            "
          >
            <i
              class="fa fa-user"
              v-if="item.avatar == null"
            ></i>
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="table_status">
          <div
            class="tiny-light"
            v-bind:class="item.checked_in ? 'green' : 'red'"
          ></div>
          <div class="name">{{ item.checked_in ? $t("Checked-In") : $t("Checked-Out") }}</div>
        </div>
      </template>

      <template v-slot:[`item.machine_status`]="{ item }">
        <div class="table_machine_status">
          <p :class="`${machineStatusClassName(item.machine_status)} py-1 px-2 rounded`">
            {{ item.machine_status ? item.machine_status.name : "" }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.target_units`]="{ item }">
        {{ numberWithCommas(item.target_units.toFixed()) }}
      </template>

      <template v-slot:[`item.actual`]="{ item }">
        {{ numberWithCommas(item.actual.toFixed()) }}
      </template>

      <template v-slot:[`item.average`]="{ item }">
        {{ numberWithCommas(item.average) }}
      </template>

      <template v-slot:[`item.gap`]="{ item }">
        <div
          class="table_gap"
          v-bind:class="GetGapColor(item.gap, item.target_units, item.actual)"
        >
          {{ numberWithCommas(item.gap.toFixed()) }}
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0"
        >
          <div class="nestedTable">
            <realtime-table-expand
              :checkedIn="item.checked_in"
              :machines="item.machines"
              :table_headers="table_headers"
              :now="now"
              :role="item.role"
              :badges="item"
            />
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";

.realtime_table {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex: 1;
  .searchbars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    height: 60px;
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      background-color: $cardColor;
      box-shadow: $cardBoxShadow;
      height: unset;
      margin: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      position: absolute;
      top: 3rem;
      left: 1rem;
      right: 1rem;
      z-index: 99;
      .v-input {
        width: 100%;
      }
    }
    .clearbutton {
      width: 35px;
      flex: unset;
      font-weight: lighter;
    }
  }
  @media screen and (max-width: 1200px) {
    h4 {
      margin-top: 5px;
      font-size: 0.8rem;
    }
  }
  .blue-title {
    color: $blue;
  }

  th {
    vertical-align: middle;
  }

  tr {
    cursor: pointer;
    .fa-caret-up,
    .fa-caret-down {
      font-size: 1.15rem;
    }
  }

  .v-data-table {
    padding-bottom: 50px;
    margin-bottom: 20px;

    .v-data-table__wrapper {
      height: unset !important;
      overflow: auto;
    }
  }

  .nestedTable {
    padding: 0 1rem;
    margin-left: -16px;
    width: calc(100% + 32px);
    background-color: rgba(0, 0, 0, 0.2);
    .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      background-color: transparent;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      background-color: transparent;
    }
  }

  .table_name {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      padding: 10px;
      margin: 10px;
      width: 40px;
      height: 40px;
      background: $blue;
      border-radius: 100%;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .name {
      flex: 1;
    }
  }
  .table_status {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .tiny-light {
      background: green;
      border-radius: 100%;
      padding: 10px;
      margin: 10px;
      width: 20px;
      height: 20px;
    }
  }
  .table_gap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 30px;
    text-align: center;
    border-radius: 20px;
    &.good {
      background: green;
    }

    &.bad {
      background: red;
    }
  }

  .middle_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box {
      flex: 1;
      display: flex;
      justify-content: center;

      &:first-child {
        justify-content: flex-start !important;
      }
      &:last-child {
        justify-content: flex-end !important;
      }

      .shift_performance {
        margin-left: 15px;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      .box {
        flex: unset;
        order: 2;
      }
      .totals {
        width: 100%;
        order: 3;
      }
      .intervalSelection {
        order: 1;
        width: 100%;
        .v-tabs {
          width: 100%;
          .v-tab {
            font-size: 12px;
            min-width: unset;
          }
        }
      }
    }

    .v-tabs {
      flex: unset;
      width: unset;
    }

    .checked_in {
      margin: 10px;
      display: flex;
      min-height: unset;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .checked_in_box {
        margin: 10px;
      }
    }
  }

  .table_machine_status {
    &.production {
      color: #13ab13;
    }

    &.scheduled_break {
      color: #ce4817;
    }

    &.maintenance {
      color: #e1ad2a;
    }

    &.lunch_break {
      color: #8f1c8f;
    }

    &.materials {
      color: #4646e7;
    }
  }
  @media screen and (max-width: 1200px) {
    .v-data-table {
      height: calc(100% - 160px) !important;
      margin-bottom: 160px;
    }
  }
  .v-data-table__mobile-row {
    border-bottom: 1px rgba(255, 255, 255, 0.05) solid;
    justify-content: flex-start;
    display: flex !important;

    .v-data-table__mobile-row__header {
      color: $blue;
      width: 150px;
      text-align: left;
      flex: unset;
    }

    .v-data-table__mobile-row__cell {
      flex: 1;
      text-align: left;

      p {
        text-align: left;
      }
      div {
        text-align: left;
      }
    }
  }

  tr:hover {
    background: inherit !important;
    color: inherit !important;
  }
}
</style>
<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import { numberWithCommas } from "@/utils/filters";
import { useMachineStatusClassName } from "@/features/machine-status/hooks/useMachineStatusClassName";

import RealtimeTableExpand from "./realtimeTableExpand";

export default {
  components: { RealtimeTableExpand },
  props: [
    "table_headers",
    "items",
    "fromDate",
    "toDate",
    "setFromDate",
    "setToDate",
    "shiftStart",
    "shiftEnd",
    "numCheckedIn",
    "numCheckedOut",
    "setCalendarCase",
    "calendarCase",
    "now",
    "loading"
  ],
  data() {
    return {
      date_from: null,
      date_to: null,
      expanded: [],
      header_options: { "sort-icon": "mdi-minus" },
      computed_table_headers: [],
      computed_sub_table_headers: [],
      sorted_items: [],
      filtered_items: [],
      zoneSet: new Set(),
      machineSet: new Set(),
      partSet: new Set(),
      operatorSet: new Set(),
      operatorStatusSet: new Set(),
      filters: {},
      showSearch: false
    };
  },
  created() {
    this.sorted_items = this.items.slice();
    this.filtered_items = this.items.slice();
    this.date_from = moment(this.shiftStart);
    this.date_to = moment(this.shiftEnd);
    this.computed_table_headers = this.table_headers.slice();
    this.computed_sub_table_headers = this.table_headers.slice();
  },
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  methods: {
    numberWithCommas,
    createDropdownElements() {
      if (this.items == null) {
        return;
      }

      for (const item of this.items) {
        this.zoneSet.add(item.zone);
        this.machineSet.add(item.machine);
        this.partSet.add(item.part);
        this.operatorSet.add(item.name);
        this.operatorStatusSet.add(item.checked_in ? "Checked-In" : "Checked-Out");
      }
    },
    setFilter(filter, e) {
      if (e != null) {
        this.filters[filter] = e;
      } else {
        delete this.filters[filter];
      }
      this.filterItems();
    },
    filterItems() {
      const keys = Object.keys(this.filters);
      const filteredOut = new Set();
      this.sorted_items.forEach((item) => {
        for (const k of keys) {
          if (item[k] != this.filters[k]) {
            filteredOut.add(item);
          }
        }
      });

      this.filtered_items = this.sorted_items.filter((item) => {
        return !filteredOut.has(item);
      });
    },
    sortby(item) {
      if (item.sort == null) {
        item.sort = "desc";
      } else if (item.sort == "desc") {
        item.sort = "asc";
      } else if (item.sort == "asc") {
        item.sort = null;
      }
      this.computed_table_headers = this.computed_table_headers.map((i) => {
        if (i == item) {
          return i;
        } else {
          i.sort = null;
          return i;
        }
      });

      const sortKey = item.value;
      const sort = item.sort;

      if (sort == null) {
        this.sorted_items = this.items.slice();
      } else {
        this.sorted_items.sort((item_a, item_b) => {
          if (sort == "asc") {
            if (item_a[sortKey] < item_b[sortKey] || item_a[sortKey] == null) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (item_a[sortKey] > item_b[sortKey] || item_b[sortKey] == null) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
      this.filterItems();
    },
    rowClick(item) {
      if (this.expanded.includes(item)) {
        this.expanded = [];
      } else {
        this.expanded = [];
        //Bcole: For some reason setting expanded on
        //same frame without clearing it first results
        //in a visual glitch
        window.requestAnimationFrame(() => {
          this.expanded = [item];
        });
      }
    },
    GetGapColor(gap, target_units, actual) {
      if (actual < target_units) {
        return "bad";
      } else {
        return "good";
      }
    },
    ChangeCalendar(e) {
      this.setCalendarCase(e);
      this.expanded = [];
      switch (e) {
        case 0:
          if (this.shiftStart != null && this.shiftEnd != null) {
            this.setFromDate(moment(this.shiftStart));
            this.setToDate(moment(this.shiftEnd));
          }
          break;
        case 1:
          this.setFromDate(moment().startOf("day"));
          this.setToDate(moment().endOf("day"));
          break;
        case 2:
          this.setFromDate(moment().startOf("week"));
          this.setToDate(moment().endOf("week"));
          break;
        case 3:
          this.setFromDate(moment().startOf("month"));
          this.setToDate(moment().endOf("month"));
          break;
        case 4:
          this.setFromDate(moment().startOf("year"));
          this.setToDate(moment().endOf("year"));
          break;
      }
    },
    machineStatusClassName(status) {
      return useMachineStatusClassName(status);
    }
  },
  watch: {
    items: function () {
      this.sorted_items = this.items.slice();
      this.filtered_items = this.items.slice();
      this.createDropdownElements();
      this.filterItems();
    }
  }
};
</script>
