<template>
  <div>
    <span
      v-if="shiftName"
      :class="['mr-3', isTotal ? 'font-weight-bold' : '']"
    >
      {{ shiftName }}
    </span>
    <span :class="actual > target ? 'color--text-green' : 'color--text-red'">
      {{ unit(actual, UnitTypes.NUMBER) }} /
    </span>
    <span class="color--text-secondary">
      {{ unit(target, UnitTypes.NUMBER) }}
    </span>
  </div>
</template>

<script>
import { unit, UnitTypes } from "../../../utils/unit";

export default {
  name: "ZoneControlWeeklyTableCell",
  props: {
    actual: {
      type: Number,
      default: 0
    },
    target: {
      type: Number,
      default: 0
    },
    shiftName: {
      type: String,
      default: undefined
    },
    isTotal: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      unit,
      UnitTypes
    };
  }
};
</script>
