<template>
  <div class="new-audit">
    <div
      class="audit-type card"
      v-if="auditType && !editAuditType"
    >
      <div class="card-header">
        <div>
          <h2>{{ $t(auditType.name) }}</h2>
          <h3>{{ $t("Current Version") }} : {{ auditType.currentVersion }}</h3>
        </div>
        <v-btn
          small
          color="warning"
          fab
          @click.stop.prevent="editAuditType = true"
        >
          <i
            class="fa fa-edit"
            aria-hidden="true"
          ></i>
        </v-btn>
      </div>
      <div class="card-content">
        <v-select
          v-if="auditType.machineRequired && auditType"
          ref="machineSelect"
          :items="auditType.machines"
          :menu-props="{ maxHeight: '400' }"
          :label="this.$t('Machines')"
          item-text="name"
          outlined
          v-click-outside="closeSelect"
        >
          <template v-slot:item="{ item }">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(item.name) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <div class="item">
          <label>{{ $t("Description") }}</label>
          <p>{{ $t(auditType.description) }}</p>
        </div>
        <div
          class="item"
          v-if="auditType.issueType"
        >
          <label>{{ $t("Audit Issues") }}</label>
          <p>{{ $t(auditType.issueType.name) }}</p>
        </div>
        <div
          class="item"
          v-if="auditType.issueType && auditType.notificationGroup"
        >
          <label>{{ $t("Notification Group") }}</label>
          <p>{{ $t(auditType.notificationGroup.name) }}</p>
        </div>
      </div>
    </div>
    <create-audit-type
      v-if="auditType && editAuditType"
      :edit="true"
      :auditType="auditType"
      @close="loadAudit"
    >
    </create-audit-type>
    <div class="version-fields">
      <v-text-field
        v-model="auditVersion.version"
        :rules="rules.version"
        :label="'* ' + $t('Version')"
        required
        class="version"
        counter="16"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="auditVersion.revisionComment"
        :label="'* ' + $t('Revision Comment')"
        outlined
      ></v-text-field>
    </div>
    <div class="categories card">
      <v-btn
        color="primary"
        class="addButton"
        @click="addCatgory()"
      >
        {{ $t("Add Category") }}
      </v-btn>
      <v-tabs
        v-model="tab"
        fixed-tabs
        show-arrows
        center-active
      >
        <draggable
          v-model="auditVersion.categories"
          class="category_tabs"
          @update="tabUpdate"
        >
          <v-tab
            v-for="(category, category_index) in auditVersion.categories"
            :key="category.order"
          >
            {{ $t(category.name) }}
          </v-tab>
        </draggable>
        <v-tab-item
          v-for="(category, category_index) in auditVersion.categories"
          :key="category.order"
        >
          <div class="audit-version">
            <div class="category">
              <div class="category-row">
                <v-text-field
                  v-model="category.name"
                  :rules="rules.category"
                  :label="$t('Category Name')"
                  counter="100"
                  outlined
                ></v-text-field>
                <v-btn
                  color="red"
                  light
                  fab
                  x-small
                  @click="auditVersion.categories.splice(category_index, 1)"
                >
                  <i
                    class="fa fa-times"
                    aria-hidden="true"
                  ></i>
                </v-btn>
              </div>
              <draggable
                class="questions"
                v-model="category.questions"
              >
                <div
                  class="question card"
                  v-for="(question, question_index) in category.questions"
                >
                  <div class="question-row">
                    <h4>{{ question_index + 1 }}</h4>
                    <v-select
                      v-model="question.type"
                      @change="typeUpdate(question)"
                      :items="question.questionTypes"
                      :label="$t('Question Type')"
                      rows="3"
                      item-text="name"
                      item-value="value"
                      outlined
                    >
                      <template v-slot:item="{ item }">
                        {{ $t(item.name) }}
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ $t(item.name) }}
                      </template>
                    </v-select>
                    <v-text-field
                      class="estTime"
                      v-model="question.estimatedTime"
                      :label="$t('Est Time') + ' (' + $t('optional') + ')'"
                      type="number"
                      outlined
                    ></v-text-field>
                    <v-btn
                      color="red"
                      light
                      fab
                      x-small
                      @click="category.questions.splice(question_index, 1)"
                    >
                      <i
                        class="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    </v-btn>
                  </div>
                  <v-textarea
                    v-model="question.text"
                    :label="$t('Question') + ' ...'"
                    outlined
                  ></v-textarea>
                  <v-textarea
                    v-model="question.instruction"
                    :label="$t('Instruction') + ' ...'"
                    outlined
                  ></v-textarea>
                  <draggable
                    class="answers"
                    v-model="question.answers"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div
                      class="answer"
                      v-for="(answer, answer_index) in question.answers"
                      :key="question_index + ' - ' + answer_index"
                    >
                      <v-text-field
                        v-model="answer.text"
                        :label="$t('Answer') + ' ...'"
                        single-line
                        hide-details
                        outlined
                      >
                        <template v-slot:prepend-inner>
                          <v-tooltip
                            bottom
                            v-if="question.type != 'range'"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                @click="toggleAnswer(question, answer)"
                                :class="{ correct: answer.isCorrect, check: true }"
                              >
                                fa-check-circle
                              </v-icon>
                            </template>
                            {{ $t("Check If correct answer") }}
                          </v-tooltip>
                          <v-tooltip
                            bottom
                            v-if="question.type == 'range' && answer_index == 0"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-less-than-or-equal</v-icon>
                            </template>
                            {{ $t("Response must be greater than or equal to this value") }}
                          </v-tooltip>
                          <v-tooltip
                            bottom
                            v-if="question.type == 'range' && answer_index == 1"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-greater-than-or-equal</v-icon>
                            </template>
                            {{ $t("Response must be less than or equal to this value") }}
                          </v-tooltip>
                        </template>
                        <template v-slot:append>
                          <v-tooltip
                            bottom
                            v-if="question.type != 'true_false' && question.type != 'range'"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                light
                                fab
                                x-small
                                icon
                                class="icon-btn"
                                @click="question.answers.splice(answer_index, 1)"
                              >
                                <i
                                  class="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </v-btn>
                            </template>
                            {{ $t("Remove Answer") }}
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </div>
                  </draggable>
                  <v-btn
                    color="primary"
                    @click="addAnswer(question)"
                    v-if="question.type != 'true_false' && question.type != 'range'"
                  >
                    {{ $t("Add Answer") }}
                  </v-btn>
                </div>
                <v-btn
                  color="primary"
                  @click="addQuestion(category)"
                >
                  {{ $t("Add Question") }}
                </v-btn>
              </draggable>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
    <v-btn
      color="primary"
      @click="saveAudit()"
      :disabled="disableSave"
      :loading="this.savingAudit"
    >
      {{ $t("Save Audit Version") }}
    </v-btn>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.new-audit {
  .audit-type,
  .audit-version {
    margin: 1rem;
    padding: 1rem;
    .card-header {
      height: 5rem;
    }
    .card-content {
      padding-top: 5rem;
    }
    .item {
      padding: 0.5rem 0;
      label {
        font-weight: 600;
      }
    }
  }
  .version-fields {
    display: flex;
    padding: 0 1rem;
    .version {
      margin-right: 1rem;
      max-width: 200px;
    }
  }
  .addButton {
    margin-left: 1rem;
  }
  .theme--dark.v-tabs-items {
    background: transparent;
  }
  .categories {
    margin: 1rem;
    padding: 1rem;
    .category_tabs {
      display: flex;
    }
  }
  .category {
    .category-row {
      display: flex;
      align-items: baseline;
      .v-input {
        padding-right: 1rem;
      }
    }
    .fa-times {
      color: white;
      font-size: 1rem;
    }
    .questions {
      padding: 1rem;
      .question {
        padding: 1rem;
        margin-bottom: 1rem;
        .question-row {
          display: flex;
          align-items: baseline;
          h4 {
            padding: 1rem;
          }
          .v-input {
            padding: 0 1rem;
          }
          .estTime {
            max-width: 250px;
          }
        }
        .answers {
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          .answer {
            margin: 0.5rem;
            width: 300px;
            .check {
              margin-right: 0.5rem;
            }
            .correct {
              color: $blue;
            }
          }
        }
        .v-btn {
          margin: 0.5rem;
        }
        .icon-btn {
          margin: 0;
          background: $red;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

import { Answer, Category, Question } from "@/datatypes/audits";

import CreateAuditType from "./CreateAuditType";

export default {
  props: ["auditTypeId"],
  data() {
    return {
      tab: null,
      editAuditType: false,
      auditType: null,
      auditVersion: {
        type: this.auditTypeId,
        version: "",
        revisionComment: "",
        categories: []
      },
      rules: {
        name: [(v) => v.length <= 250 || "Max 250 characters"],
        version: [(v) => v.length <= 16 || "Max 16 characters"],
        category: [(v) => v.length <= 100 || "Max 100 characters"]
      },
      savingAudit: false,
      errors: false
    };
  },
  created() {
    this.loadAudit();
  },
  components: {
    draggable,
    CreateAuditType
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    disableSave() {
      if (this.auditVersion) {
        if (!this.auditVersion.version || !this.auditVersion.revisionComment) {
          return true;
        }
        let invalid_form = false;
        this.auditVersion.categories.forEach((category) => {
          if (category.invalid) {
            invalid_form = true;
          }
        });
        return invalid_form;
      } else {
        return true;
      }
    }
  },

  methods: {
    moment,
    ...mapActions({}),
    toggleAnswer(question, selectedAnswer) {
      question.answers.forEach((answer) => {
        if (answer !== selectedAnswer) {
          answer.isCorrect = false;
        }
      });
      selectedAnswer.isCorrect = !selectedAnswer.isCorrect;
    },
    loadAudit() {
      this.editAuditType = false;
      const query = `query getByID($id: ID!){
        auditType(id: $id){
          id,
          ... on AuditTypeNode{
            id,
            name,
            currentVersion,
            description,
            machineRequired,
            machines{
              name
              id
              pk
            }
            issueType{
              name
              id
              pk
            }
            notificationGroup{
              name
              id
              pk
            }
            latestVersion{
              categories {
                name,
                order
                questions{
                  text,
                  instruction,
                  type,
                  order,
                  estimatedTime,
                  answers{
                    text,
                    order,
                    isCorrect
                  }
                }
              }
            }
          }
        }
      }`;
      const variables = {
        id: this.auditTypeId
      };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        this.auditType = res.data.data.auditType;
        this.auditVersion.categories = [];
        this.auditType.latestVersion.categories.forEach((category) => {
          let questions = [];
          category.questions.forEach((question) => {
            let answers = [];
            question.answers.forEach((answer, i) => {
              answers.push(new Answer(answer));
            });
            let q = new Question(question);
            q.answers = answers;
            questions.push(q);
          });
          let c = new Category(category);
          c.questions = questions;
          this.auditVersion.categories.push(c);
        });
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.machines.length === this.newAudit.machines.length) {
          this.newAudit.machines = [];
        } else {
          this.newAudit.machines = this.machines.map((m) => m.pk);
        }
      });
    },
    addCatgory() {
      this.auditVersion.categories.push(
        new Category({
          name: this.$t("Category") + " " + (this.auditVersion.categories.length + 1),
          order: this.auditVersion.categories.length
        })
      );
      this.tab = this.auditVersion.categories.length - 1;
    },
    addQuestion(category) {
      category.questions.push(
        new Question({
          order: category.questions.length
        })
      );
    },
    addAnswer(question) {
      question.answers.push(
        new Answer({
          order: question.answers.length
        })
      );
    },
    tabUpdate(evt) {
      this.tab = 0;
      this.$nextTick(function () {
        this.tab = this.auditVersion.categories[evt.newIndex].order;
      });
    },
    typeUpdate(question) {
      switch (question.type) {
        case "true_false":
          question.answers = [
            new Answer({
              order: 1,
              text: this.$t("True")
            }),
            new Answer({
              order: 2,
              text: this.$t("False")
            })
          ];
          break;
        case "range":
          question.answers = [
            new Answer({
              order: 1,
              text: 0
            }),
            new Answer({
              order: 2,
              text: 1
            })
          ];
          break;
      }
    },
    saveAudit() {
      this.errors = false;
      this.savingAudit = true;
      const query = `mutation ($auditData: AuditFormMutationInput!){
        createAuditForm(input: $auditData) {
          auditForm{
            id,
            version,
            revisionComment,
            categories {
              name,
              order
              questions{
                text,
                instruction,
                type,
                order,
                estimatedTime,
                answers{
                  text,
                  order,
                  isCorrect
                }
              }
            }
          }
        }
      }`;

      let variables = { auditData: _.clone(this.auditVersion) };
      variables["auditData"]["categories"].forEach((category) => {
        delete category.score;
        category.questions.forEach((question) => {
          delete question.questionTypes;
        });
      });

      variables["auditData"]["clientMutationId"] = "createRevision";

      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          this.auditType.latestVersion = res.data.data.createAuditForm.auditForm;
          this.auditType.currentVersion = res.data.data.createAuditForm.auditForm.version;
          this.$message("ALERT", { text: this.$t("Saved"), type: "success" });
          setTimeout(function () {
            context.savingAudit = false;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.savingAudit = false;
        });
    },
    closeSelect() {
      this.$refs.machineSelect.blur();
    }
  },
  watch: {}
};
</script>
