var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scrapReasonCodeTable"},[_c('generic-table',{attrs:{"columns":[
      { title: _vm.$t('Scrap Reason'), key: 'description' },
      { title: _vm.$t('Part Number'), key: 'part_number' },
      { title: _vm.$t('Scrapped (pcs)'), key: 'scrapped', format: this.numberWithCommas }
    ],"footer":[
      { type: 'text', text: _vm.$t('Total') },
      {},
      { type: 'text', text: this.numberWithCommas(_vm.sumValue(this.scrapReasonData, ['scrapped'])) }
    ],"data":_vm.scrapReasonData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }