<template>
  <div class="page-wrapper">
    <div class="header">
      <v-tabs fixed-tabs>
        <v-tab
          v-for="tab in tabs"
          :key="tab.type"
          @click="switchTable(tab.type)"
          :disabled="loadingTable === tab.type"
        >
          {{ $t(tab.label) }}
        </v-tab>
      </v-tabs>
    </div>
    <span
      v-if="errorMessage"
      class="error-msg"
      >{{ errorMessage }}</span
    >

    <div
      class="data-table"
      v-if="tableData.items && tableData.items.length > 0"
    >
      <v-text-field
        style="padding: 0 50px 0 50px; text-align: center"
        v-model="search"
        :label="$t('Search in following format: column_name=value')"
        v-on:keyup.enter="submit"
        single-line
        hide-details
      >
        <template v-slot:append-outer>
          <v-btn
            icon
            @click="submit"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
      <v-data-table
        height="calc(100% - 3.25rem)"
        :headers="tableData.headers"
        :items="tableData.items"
        :loading="tableData.loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 100, 200] }"
        :server-items-length="tableData.totalItems"
        :items-per-page="itemsPerPage"
        :page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:page="updatePage"
        @update:items-per-page="updatePagination"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
        fixed-header
      >
        <template v-slot:header="{ headers }">
          <tr>
            <th
              v-for="header in headers"
              :key="header.value"
              :class="[header.sortable ? 'sortable' : 'non-sortable']"
            >
              {{ header.text }}
              <v-icon
                v-if="header.sortable"
                @click="updateSortBy(header.value)"
              >
                mdi-sort
              </v-icon>
            </th>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: {},
      loadingTable: null,
      currentTable: "demand",
      search: "",
      currentPage: 1,
      itemsPerPage: 10,
      sortBy: "",
      sortDesc: false,
      tabs: [
        { type: "demand", label: this.$t("Demand") },
        { type: "productivity", label: this.$t("Productivity") },
        { type: "forecast", label: this.$t("Forecast") }
      ],
      errorMessage: null
    };
  },
  methods: {
    async switchTable(tableType) {
      this.currentTable = tableType;
      if (this.loadingTable === tableType) return;
      await this.loadTable(tableType);
    },
    cleanAllVariables() {
      this.sortDesc = false;
      this.sortBy = "";
      this.sortDesc = "";
      this.search = "";
      this.errorMessage = null;
      this.tableData = {};
    },
    async submit() {
      await this.loadTable(this.currentTable);
    },
    updateSortBy(newSortBy) {
      this.sortBy = newSortBy;
      this.loadTable(this.currentTable);
    },
    updateSortDesc(newSortDesc) {
      this.sortDesc = newSortDesc;
      this.loadTable(this.currentTable);
    },
    updatePage(newPage) {
      this.currentPage = newPage;
      this.loadTable(this.currentTable);
    },
    updatePagination(newOffSet) {
      this.itemsPerPage = newOffSet;
      this.loadTable(this.currentTable);
    },
    async loadTable(tableType) {
      this.cleanAllVariables();
      try {
        const params = {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage
        };
        if (this.search) {
          params.search = this.convertSearchText(this.search);
        }
        if (this.sortBy) {
          params.sort = `${this.sortBy}:${this.sortDesc ? "desc" : "asc"}`;
        }
        this.loadingTable = tableType;
        this.tableData.loading = true;
        const response = await this.$http.get(`frb/raw-data/${tableType}`, { params });
        if (response.data.data.length > 0) {
          this.tableData.totalItems = response.data.count;
          this.tableData.items = response.data.data;
          this.tableData.headers = response.data.headers;
        }
      } catch (error) {
        console.error("Error loading table:", error);
        this.errorMessage = error.response ? error.response.data.error : "Unknown error occurred.";
      } finally {
        this.loadingTable = null;
        this.tableData.loading = false;
      }
    },
    convertSearchText(searchText) {
      const [key, value] = searchText.split("=").map((item) => item.trim());
      return `${key}:${value}`;
    }
  },
  mounted() {
    this.currentTable = "demand";
    this.loadTable(this.currentTable);
  }
};
</script>

<style scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  margin-bottom: 20px;
}

.data-table {
  width: 100%;
}

.error-msg {
  background-color: lightcoral;
  font-size: 16px;
}
</style>
