<template>
  <div class="operator_analytics">
    <v-tabs
      class="primary-tabs"
      align-with-title
      v-model="tab"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        :to="tab.route"
      >
        <i :class="tab.icon" />
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <router-view
      :realtime_type_selected="realtime_type_selected"
      :SetRealtimeType="SetRealtimeType"
    ></router-view>
  </div>
</template>
<style lang="scss">
@import "@/scss/_variables";
.theme--light {
  .operator_analytics {
    .v-tab--active {
      color: $darkGrey;
    }
    .v-tabs-slider-wrapper {
      color: $lightGrey;
    }
  }
}
.operator_analytics {
  padding: 0.5rem 1rem;
  padding-bottom: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  height: 100%;
  .v-tabs {
    flex: unset;
    .v-tab {
      font-size: 1rem;
      i {
        margin-right: 1rem;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    padding: 0;
    .primary-tabs {
      height: 60px;
      .v-tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 9px;
        height: 60px;
        i {
          font-size: 24px;
          margin-right: 0;
          &.fa {
            margin-bottom: 5px;
          }
        }
      }
      .v-slide-group__wrapper {
        z-index: 100;
        height: 60px;
        .v-slide-group__content {
          justify-content: space-around !important;
          height: 60px;
          a {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      tab: 0,
      realtime_type_selected: "table",
      tabs: [
        {
          name: this.$t("Realtime"),
          route: "/analytics/operator/realtime",
          development: false,
          icon: "mdi mdi-heart-pulse"
        },
        {
          name: this.$t("Leaderboard"),
          route: "/analytics/operator/leaderboard",
          development: true,
          icon: "fa fa-trophy"
        },
        {
          name: this.$t("Analytics"),
          route: "/analytics/operator/analytics",
          development: true,
          icon: "fa fa-bar-chart"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    SetRealtimeType(realtime_type_selected) {
      this.realtime_type_selected = realtime_type_selected;
    }
  },
  mounted() {
    this.setTitles({
      title: this.$t("Operator Performance Analytics"),
      mobile: this.$t("Operator Performance Analytics")
    });
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  }
};
</script>
