<template>
  <div>
    <div class="values">
      <div class="item total-open">
        <p class="label">{{ $t("Total Open") }}</p>
        <p class="total">{{ enlargedTable.issues.length }}</p>
      </div>
      <div class="item in-process">
        <p class="label">{{ $t("In Process") }}</p>
        <p class="total">{{ getInprocess(enlargedTable.issues) }}</p>
      </div>
      <div class="item latest">
        <p class="label">{{ $t("Latest (Days)") }}</p>
        <p class="total">{{ getLatest(enlargedTable.issues) }}</p>
      </div>
      <div class="item oldest">
        <p class="label">{{ $t("Oldest (Days)") }}</p>
        <p class="total">{{ getOldest(enlargedTable.issues) }}</p>
      </div>
    </div>
    <v-data-table
      :headers="enlargedIssueHeaders"
      item-key="key_as_string"
      :items="enlargedTable.issues"
      :loading="enlargedTable.loading"
      :itemsPerPage.sync="enlargedTable.itemsPerPage"
      :serverItemsLength="enlargedTable.total"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
      @pagination="(...args) => loadIssuePage(enlargedTable, ...args)"
      @update:items-per-page="handleItemsPerPageChange($event, enlargedTable)"
      disable-pagination
      fixed-header
      multi-sort
    >
      <template v-slot:item.relation="{ item }">
        <p>{{ item.node.machineName ? item.node.machineName : item.node.machineGroupName }}</p>
      </template>
      <template v-slot:[`item.launch`]="{ item }">
        <v-btn
          small
          color="primary"
          fab
          @click.stop.prevent="launchTicket(item)"
        >
          <i
            class="fa fa-external-link-square"
            aria-hidden="true"
          ></i>
        </v-btn>
      </template>
      <template v-slot:item.status="{ item }">
        <i
          class="harveyBall mdi"
          :class="getStatusIcon(item)"
          aria-hidden="true"
        ></i>
      </template>
      <template v-slot:item.countermeasures="{ item }">
        <div v-bind:class="{ 'list-plus-button': getCMS(item).length > 0 }">
          <counter-measure
            :issues="[item.node]"
            :machine_id="machinesLookup[item.node.machineId].id"
            :time="item.key_as_string"
            :tab="-1"
            @UpdatedCM="(...args) => updateIssue(item, ...args)"
          />
        </div>
      </template>
      <template v-slot:item.cause="{ item }">
        <div v-bind:class="{ 'list-plus-button': getCauses(item).length > 0 }">
          <cause
            :issues="[item.node]"
            :machine_id="machinesLookup[item.node.machineId].id"
            :time="item.key_as_string"
            :tab="-1"
            @UpdatedCause="(...args) => updateIssue(item, ...args)"
          />
        </div>
      </template>
      <template v-slot:item.solution="{ item }">
        <div v-bind:class="{ 'list-plus-button': getSolutions(item).length > 0 }">
          <solution
            v-if="hasCause([item.node])"
            :issues="[item.node]"
            :machine_id="machinesLookup[item.node.machineId].id"
            :time="item.key_as_string"
            :tab="-1"
            @UpdatedSolution="(...args) => loadIssues(enlargedTable)"
          />
        </div>
      </template>
      <template
        v-slot:item.status="{ item }"
        class="align-center"
      >
        <i
          class="harveyBall mdi"
          :class="getStatusIcon(item)"
          aria-hidden="true"
        ></i>
      </template>
      <template
        v-slot:[`item.launch`]="{ item }"
        class="align-center"
      >
        <v-btn
          small
          color="primary"
          fab
          @click.stop.prevent="launchTicket(item)"
        >
          <i
            class="fa fa-external-link-square"
            aria-hidden="true"
          ></i>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Cause from "@/components/dialogs/Cause";
import CounterMeasure from "@/components/dialogs/CounterMeasure";
import Solution from "@/components/dialogs/Solution";

export default {
  name: "UnresolvedIssues",
  props: {
    enlargedTable: { type: Object, default: () => {} },
    getInprocess: { type: Function, default: () => null },
    getLatest: { type: Function, default: () => null },
    getOldest: { type: Function, default: () => null },
    enlargedIssueHeaders: { type: Array, default: () => [] },
    loadIssuePage: { type: Function, default: () => null },
    handleItemsPerPageChange: { type: Function, default: () => null },
    getStatusIcon: { type: Function, default: () => null },
    machinesLookup: { type: Object, default: () => {} },
    loadIssues: { type: Function, default: () => null }
  },
  components: {
    Cause,
    CounterMeasure,
    Solution
  },
  methods: {
    launchTicket(item) {
      let routeData = this.$router.resolve({ name: "ticket", params: { ticket_id: item.node.id } });
      window.open(routeData.href, "_blank");
    },
    getCMS(item) {
      if (item.node.countermeasures) {
        return item.node.countermeasures
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    updateIssue(item, issue) {
      //just copy the updated array into the existing one to re-render ui
      item.node.cause = issue.cause;
      item.node.countermeasures = issue.countermeasures;
      item.node.solution = issue.solution;
    },
    getCauses(item) {
      if (item.node.cause) {
        return item.node.cause
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    getSolutions(item) {
      if (item.node.solution) {
        return item.node.solution
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    hasCause(issues) {
      let cause = false;
      if (issues) {
        issues.forEach((issue) => {
          if (issue.cause) {
            cause = true;
          }
        });
      }
      return cause;
    }
  }
};
</script>
