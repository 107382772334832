import Vue from "vue";

/**
 * Simple event bus used for global messaging of custom events between components.
 */
class EventBus {
  constructor() {
    this.listeners = {};
  }

  register(listener, type, method) {
    // eslint-disable-next-line no-prototype-builtins
    if (!this.listeners.hasOwnProperty(type)) {
      this.listeners[type] = {};
    }
    this.listeners[type][listener] = method;
  }

  silence(listener, type) {
    // eslint-disable-next-line no-prototype-builtins
    if (!this.listeners.hasOwnProperty(type)) {
      this.listeners[type] = {};
    }
    delete this.listeners[type][listener];
  }

  message(type, params) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.listeners.hasOwnProperty(type)) {
      for (let listener in this.listeners[type]) {
        this.listeners[type][listener](params);
      }
    }
  }
}

EventBus.install = function (Vue) {
  // register event bus into Vue
  Vue.eventBus = new EventBus();

  // add convenience methods on components for handling events
  Vue.prototype.$bus = Vue.eventBus;
  Vue.prototype.$message = function (type, params) {
    this.$bus.message(type, params);
  };
  Vue.prototype.$listen = function (type, method) {
    this.$bus.register(this, type, method);
  };

  Vue.prototype.$silence = function (type) {
    this.$bus.silence(this, type);
  };

  /* $listen and $silence doesn't work correctly
   * listener is [Object object]
   * cause of that not possible to setup 2 listeners for one type
   * in the same time */

  Vue.prototype.$register = function (listener, type, method) {
    this.$bus.register(listener, type, method);
  };

  Vue.prototype.$off = function (listener, type) {
    this.$bus.silence(listener, type);
  };
};

export default EventBus;

export const Dispatcher = new Vue({
  name: "EventBus"
});
