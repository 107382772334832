<template>
  <div class="reason-button-with-tooltip">
    <v-tooltip
      bottom
      max-width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on">
          <v-btn
            :class="{ selected: isSelected }"
            :disabled="disabled"
            color="primary"
            primary
            text
            v-bind="attrs"
            v-on="on"
            @click="action"
          >
            {{ text }}
          </v-btn>
        </div>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
  </div>
</template>
<style lang="scss">
.reason-button-with-tooltip {
  .v-btn {
    height: 36px;
    width: 100%;
  }

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: normal;
    display: block;
    line-height: 1.2em;
    white-space: nowrap;
  }
}
</style>
<script>
export default {
  name: "ReasonButtonWithTooltip",
  props: ["action", "isSelected", "text", "disabled"]
};
</script>
