import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#00AADC",
        secondary: "#FFCDD2",
        accent: "#3F51B5",
        success: "#00BC6C",
        info: "#2196F3",
        warning: "#FFC794",
        error: "#EA4D55",
        good: "#00BC6C",
        bad: "#EA4D55"
      },
      dark: {
        primary: "#54DDFF",
        accent: "#5657e8",
        secondary: "#D12AC2",
        success: "#00BC6C",
        info: "#2196F3",
        warning: "#FFC794",
        error: "#EA4D55",
        good: "#00BC6C",
        bad: "#EA4D55"
      }
    }
  },
  lang: {
    t: (key, ...params) => Vue.i18n.translate(key, params)
  }
});
