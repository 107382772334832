<template>
  <div class="flex-table">
    <v-simple-table
      dense
      fixed-header
      height="100%"
    >
      <thead>
        <tr>
          <th />
          <th
            @click="setSort('duration')"
            class="clickable"
          >
            <div class="flex-head">
              <i v-bind:class="caretClass[sort['duration']]" />
              <div>{{ $t("Total Duration") }}</div>
              <i class="hidden" />
            </div>
          </th>
          <th
            @click="setSort('runtime')"
            class="clickable"
          >
            <div class="flex-head">
              <i v-bind:class="caretClass[sort['runtime']]" />
              <div>{{ $t("Total Runtime") }}</div>
              <i class="hidden" />
            </div>
          </th>
          <th
            @click="setSort('downtime')"
            class="clickable"
          >
            <div class="flex-head">
              <i v-bind:class="caretClass[sort['downtime']]" />
              <div>{{ $t("Total Downtime") }}</div>
              <i class="hidden" />
            </div>
          </th>
          <th
            @click="setSort('utilization')"
            class="clickable"
          >
            <div class="flex-head">
              <i v-bind:class="caretClass[sort['utilization']]" />
              <div>{{ $t("Asset Utilization") }}</div>
              <i class="hidden" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(m, i) in keys"
          :key="i"
        >
          <td>{{ data[m].name }}</td>

          <td v-if="chartData[m]">
            {{ seconds(chartData[m].duration.value) }}
          </td>
          <td v-else>0</td>

          <td v-if="chartData[m]">
            {{ seconds(chartData[m].runtime.duration.value) }}
          </td>
          <td v-else>0</td>

          <td v-if="chartData[m]">
            {{ seconds(chartData[m].duration.value - chartData[m].runtime.duration.value) }}
          </td>
          <td v-else>0</td>

          <td v-if="chartData[m]">
            {{
              `${Math.round((chartData[m].runtime.duration.value / chartData[m].duration.value) * 100)}%`
            }}
          </td>
          <td v-else>0%</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import { seconds } from "@/utils/filters";

export default {
  name: "TLAUtilizationTable",
  props: ["rawData", "lookupKey"],
  data() {
    return {
      keys: [],
      chartData: {},
      data: {},
      sort: {
        duration: 0,
        runtime: 0,
        downtime: 0,
        utilization: 0
      },
      caretClass: {
        0: "fa fa-caret-up hidden",
        1: "fa fa-caret-up",
        "-1": "fa fa-caret-down"
      }
    };
  },
  mounted() {
    this.setupData();
  },
  methods: {
    seconds,
    setSort(sortKey) {
      for (const s of Object.keys(this.sort)) {
        if (s !== sortKey) {
          this.sort[s] = 0;
        } else {
          switch (this.sort[s]) {
            case 0:
              this.sort[s] = -1;
              break;
            case -1:
              this.sort[s] = 1;
              break;
            case 1:
              this.sort[s] = 0;
              break;
          }
        }
      }
      this.setupData();
    },
    setupData() {
      this.keys = [];
      this.chartData = {};
      this.data = {};

      for (const data of this.rawData.buckets || this.rawData) {
        this.chartData[data.key] = data;
      }

      //parts are joining the objects fetched from the database to the es buckets with 'name' instead of 'id'
      for (const m of this.rawData.groups || this.rawData) {
        this.keys.push(this.lookupKey ? m[this.lookupKey] : m.id);
        this.data[this.lookupKey ? m[this.lookupKey] : m.id] = m;
      }

      for (const s of Object.keys(this.sort)) {
        if (this.sort[s] !== 0) {
          switch (s) {
            case "duration":
              this.keys.sort((a, b) => {
                if (this.chartData[a] && this.chartData[b]) {
                  return (
                    (this.chartData[a].duration.value - this.chartData[b].duration.value) *
                    this.sort[s]
                  );
                } else {
                  return this.sort[s];
                }
              });
              break;
            case "runtime":
              this.keys.sort((a, b) => {
                if (this.chartData[a] && this.chartData[b]) {
                  return (
                    (this.chartData[a].runtime.duration.value -
                      this.chartData[b].runtime.duration.value) *
                    this.sort[s]
                  );
                } else {
                  return this.sort[s];
                }
              });
              break;
            case "downtime":
              this.keys.sort((a, b) => {
                if (this.chartData[a] && this.chartData[b]) {
                  const aVal =
                    this.chartData[a].duration.value - this.chartData[a].runtime.duration.value;
                  const bVal =
                    this.chartData[b].duration.value - this.chartData[b].runtime.duration.value;
                  return (aVal - bVal) * this.sort[s];
                } else {
                  return this.sort[s];
                }
              });
              break;
            case "utilization":
              this.keys.sort((a, b) => {
                if (this.chartData[a] && this.chartData[b]) {
                  const aVal =
                    this.chartData[a].runtime.duration.value / this.chartData[a].duration.value;
                  const bVal =
                    this.chartData[b].runtime.duration.value / this.chartData[b].duration.value;
                  return (aVal - bVal) * this.sort[s];
                } else {
                  return this.sort[s];
                }
              });
              break;
          }
        }
      }
    }
  },
  watch: {
    rawData: function () {
      this.setupData();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../scss/variables";
@import "../../../scss/mq";

.flex-table {
  overflow-y: hidden;
  padding-top: 5px;
}

.flex-head {
  display: flex;
  div {
    flex: 1;
    text-align: center;
  }
  i {
    flex: unset;
    width: 10px;
  }
}

th {
  color: $blue;

  &.clickable {
    cursor: pointer;
  }
}

i.hidden {
  visibility: hidden;
}

td:first-child {
  color: $blue;
}
</style>
