<template>
  <portal :to="portalNodeId">
    <sidebar
      :value="isOpen"
      :title="$t('Filters')"
      class="sidebar-container"
      v-on="$listeners"
    >
      <slot name="default"></slot>

      <template #append>
        <div class="text-uppercase d-flex flex-wrap filter-footer px-4 pb-4">
          <Btn
            variant="primary"
            block
            class="w-100"
            large
            @click="$emit('apply')"
          >
            {{ $t("Apply") }}
          </Btn>
          <Btn
            class="filter-button"
            large
            @click="$emit('cancel')"
          >
            {{ $t("Cancel") }}
          </Btn>
          <Btn
            class="filter-button"
            large
            @click="$emit('clear')"
          >
            {{ $t("Clear") }}
          </Btn>
        </div>
      </template>
    </sidebar>
  </portal>
</template>

<script>
import Btn from "@/ui/Btn";
import Sidebar from "@/ui/Sidebar/Sidebar.vue";

export default {
  name: "FiltersLayout",
  components: {
    Btn,
    Sidebar
  },
  props: {
    isOpen: { type: Boolean, default: false },
    portalNodeId: { type: String, default: "side-drawer" }
  },
  emits: ["apply", "cancel", "clear"]
};
</script>

<style lang="scss" scoped>
.filter-footer {
  gap: 10px;
}

.filter-button {
  flex: 1 0 calc(50% - 10px);
}

::v-deep {
  .v-input__slot:before {
    border-color: var(--border-input) !important;
  }

  .v-select .v-select__selection--comma {
    color: var(--text-primary);
  }
}
</style>
