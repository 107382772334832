<template>
  <div>
    <v-dialog
      v-model="showDialog"
      content-class="zone-issue-dialog"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <div class="dialogBtn">
            <v-btn
              class="edit-btn"
              v-bind="attrs"
              v-on="on"
              color="#f7bd59"
              light
              fab
              small
            >
              <i
                class="fa fa-plus"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </v-hover>
      </template>
      <v-card>
        <DialogCloseButton :onClick="() => (showDialog = false)" />
        <v-card-title class="headline">
          {{ $t("Submit Issue") }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            class="dropdown"
            :value="machine"
            @input="machine = $event || {}"
            :items="machines"
            :disabled="Object.keys(machineGroup).length > 0"
            :menu-props="{ maxHeight: '400', closeOnClick: true, closeOnContentClick: true }"
            :label="$t('Machines')"
            clearable
            item-text="name"
            outlined
            return-object
            hide-details
          >
          </v-autocomplete>
          <v-autocomplete
            class="dropdown"
            :value="machineGroup"
            @input="machineGroup = $event || {}"
            :disabled="Object.keys(machine).length > 0"
            :items="machineGroups"
            :menu-props="{ maxHeight: '400', closeOnClick: true, closeOnContentClick: true }"
            :label="$t('Machine Group')"
            clearable
            item-text="name"
            outlined
            return-object
            hide-details
          >
          </v-autocomplete>
          <v-checkbox
            v-if="machineGroup"
            v-model="applyToAllMachines"
            :label="$t('Apply to all machines')"
            class="ml-3 mt-1 mb-0"
          ></v-checkbox>
          <v-layout
            row
            justify-center
            v-if="applyToAllMachines"
            class="mb-3"
          >
            <v-tabs
              fixed-tabs
              v-model="activeTab"
            >
              <v-tab key="calendar">
                <i
                  class="fa fa-calendar"
                  aria-hidden="true"
                />
              </v-tab>
              <v-tab key="timer">
                <i
                  class="fa fa-clock-o"
                  aria-hidden="true"
                />
              </v-tab>
              <v-tab-item key="calendar">
                <v-date-picker
                  v-model="issueDate"
                  @input="showTimePicker"
                  :max="moment().toDate().toISOString().substr(0, 10)"
                  full-width
                  :locale="$i18n.locale()"
                />
              </v-tab-item>
              <v-tab-item key="timer">
                <v-time-picker
                  ref="timer"
                  class="v-time-picker-custom"
                  v-model="issueTime"
                  full-width
                ></v-time-picker>
              </v-tab-item>
            </v-tabs>
          </v-layout>
          <v-autocomplete
            class="dropdown"
            v-model="issueType"
            :items="issueTypes"
            :label="$t('Issue Type')"
            clearable
            item-text="name"
            outlined
            return-object
            hide-details
          >
            <template v-slot:item="{ item }">
              {{ $t(item.name) }}
            </template>
            <template v-slot:selection="{ item }">
              {{ $t(item.name) }}
            </template>
          </v-autocomplete>
          <div class="button-column">
            <div
              class="reason long-reason"
              v-for="reason in reasonsForSelected"
              :key="reason.node.id"
            >
              <ReasonButtonWithTooltip
                :text="reason.node.text"
                :isSelected="selected === reason"
                :action="
                  () => {
                    selected = reason;
                  }
                "
              />
            </div>
            <div
              class="reason long-reason"
              key="other"
            >
              <ReasonButtonWithTooltip
                :text="$t('Other')"
                :isSelected="selected === 'other'"
                :action="
                  () => {
                    selected = 'other';
                  }
                "
              />
            </div>
          </div>
          <div
            class="otherText"
            v-if="selected === 'other'"
          >
            <v-text-field
              :label="$t('Reason')"
              v-model="otherText"
            >
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showDialog = false"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            primary
            text
            :disabled="disableSubmit"
            :loading="saving"
            @click="createIssue"
          >
            {{ $t("Submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.zone-issue-dialog {
  max-width: 400px;

  .long-reason {
    max-width: unset !important;
  }

  .otherText {
    margin-left: 10px;
    margin-right: 4px;
  }

  .flex-container {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .dropdown {
    flex: 6;
    padding: 5px;
  }

  .button-column {
    flex: 4;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px;
  }
  .dialogBtn {
    position: relative;
    .edit-btn {
      width: 26px;
      height: 26px;
      margin-left: 10px;
    }
  }
  .issues {
    overflow: visible;
    width: 150px;
    min-width: 150px;
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);
    padding: 0.5rem;
    z-index: 9999;
    .issueText {
      max-height: 3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-y: auto;
    }
  }
}
.v-dialog {
  .dropdown {
    width: 100%;
    padding: 5px;
    margin: 5px;
  }
  .reason {
    .v-btn {
      border: 2px solid rgba(0, 0, 0, 0);
      transition: all 0.25s;
    }
    .selected {
      border: 2px solid white;
    }
  }
}
</style>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import ReasonButtonWithTooltip from "@/components/ReasonButtonWithTooltip";

export default {
  components: { ReasonButtonWithTooltip, DialogCloseButton },
  props: ["machine_group_id", "machine_group_pk"],
  data() {
    return {
      showDialog: false,
      selected: false,
      saving: false,
      otherText: "",
      reasons: [],
      machines: [],
      machine: {},
      machineGroups: [],
      machineGroup: {},
      time: "",
      applyToAllMachines: false,
      menu: false,
      issueDate: moment().toDate().toISOString().substr(0, 10),
      issueTime: moment().startOf("hour").format("H:m"),
      activeTab: "calendar",
      issueType: null,
      issueTypes: [
        { name: "Performance", value: "performance" },
        { name: "Quality", value: "quality" },
        { name: "Availability", value: "availability" }
      ]
    };
  },
  created() {
    this.loadMachines();
  },
  computed: {
    disableSubmit() {
      if (this.machineGroup) {
        if (this.issueTime == null) {
          return true;
        }
      }
      if (!this.selected) {
        return true;
      }
      return this.selected === "other" && (this.otherText === "" || this.otherText == null);
    },
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    reasonsForSelected() {
      let reasons = [];
      if (this.machine && this.machine.reasonSet) {
        reasons = this.machine.reasonSet.edges;
      } else if (this.machineGroup && this.machineGroup.reasonSet) {
        reasons = this.machineGroup.reasonSet.edges;
      } else {
        reasons = this.reasons;
      }

      reasons = reasons.filter((reason) => {
        if (this.issueType === null) {
          return true;
        } else if (this.issueType.value === "performance") {
          return reason.node.performance;
        } else if (this.issueType.value === "quality") {
          return reason.node.quality;
        } else if (this.issueType.value === "availability") {
          return reason.node.availability;
        } else {
          return false;
        }
      });

      return reasons.sort((a, b) => {
        if (a.node.order < b.node.order) {
          return -1;
        }
        if (a.node.order > b.node.order) {
          return 1;
        }
        return 0;
      });
    },
    ...mapGetters(["dbCache/machinesFromMachineGroup"]),
    dateTime() {
      if (this.issueDate && this.issueTime) {
        return new Date(`${this.issueDate}T${this.issueTime}`);
      }
      return null;
    }
  },
  methods: {
    moment,
    loadMachines() {
      let query = `query getMachineGroup($id: ID!){
        machineGroup(id: $id){
          id
          ... on MachineGroupType{
            id
            pk
            name
            allMachines{
              id
              pk
              name
              reasonSet{
                edges{
                  node{
                    id
                    pk
                    text
                  }
                }
              }
            }
            allSubGroups{
              id
              pk
              name
              reasonSet{
                edges{
                  node{
                    id
                    pk
                    text
                  }
                }
              }
            }
            reasonSet{
              edges{
                node{
                  id
                  pk
                  text
                  performance
                  quality
                  availability
                }
              }
            }
          }
        }
        settings{
          zcbEscalationTime
        }
      }`;

      let variables = { id: this.machine_group_id };
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        this.reasons = res.data.data.machineGroup.reasonSet.edges;
        this.machineGroups = res.data.data.machineGroup.allSubGroups;
        this.machineGroups.unshift({
          id: res.data.data.machineGroup.id,
          pk: res.data.data.machineGroup.pk,
          name: res.data.data.machineGroup.name,
          reasonSet: res.data.data.machineGroup.reasonSet
        });
        this.machines = res.data.data.machineGroup.allMachines;
        if (res.data.data.settings.length > 0) {
          this.time = res.data.data.settings[0].zcbEscalationTime;
        }
      });
    },
    async createIssue() {
      this.saving = true;
      const query = `mutation ($issueInput: CreateIssueMutationInput!){
    createIssue(input: $issueInput) {
      issue{
        id,
        title,
        openedDate,
        closedDate,
        ticketedDate,
        issueStartDate,
        issueEndDate,
        machineId,
        machineName,
        machineGroupId,
        machineGroupName,
        issueTypeId,
        issueTypeName,
        reason,
        countermeasures,
        cause,
        solution
      }
    }
  }`;
      let startDate;
      let endDate;
      if (this.dateTime && this.machineGroup) {
        startDate = moment(this.dateTime).startOf("hour");
        endDate = moment(this.dateTime).endOf("hour");
      } else {
        const currentDate = moment();
        startDate = currentDate.startOf("hour").subtract(this.time, "hours");
        endDate = currentDate.endOf("hour");
      }
      const baseVariables = {
        issueInput: {
          autoEscalate: startDate.toISOString(),
          issueStartDate: startDate.toISOString(),
          issueEndDate: endDate.toISOString()
        }
      };
      if (this.selected === "other" && this.otherText) {
        baseVariables["issueInput"]["reason"] = this.otherText;
        baseVariables["issueInput"]["other"] = `other-${this.issueType.value}`;
      } else {
        baseVariables["issueInput"]["reason"] = this.selected.node.id;
      }
      let promiseArray = [];
      let machineIds = [];
      if (this.applyToAllMachines && this.machineGroup) {
        const machines = this["dbCache/machinesFromMachineGroup"](this.machineGroup.pk);
        for (const machine of machines) {
          if (!machineIds.includes(machine.pk)) {
            let variables = { ...baseVariables };
            variables["issueInput"]["machine"] = machine.pk;
            promiseArray.push(this.$http.post("graphql/", { query, variables: baseVariables }));
            machineIds.push(machine.pk);
          }
        }
      } else {
        if (this.machine) {
          baseVariables["issueInput"]["machine"] = this.machine.pk;
        } else if (this.machineGroup) {
          baseVariables["issueInput"]["machineGroup"] = this.machineGroup.pk;
        } else {
          baseVariables["issueInput"]["machineGroup"] = this.machine_group_pk;
        }
        promiseArray.push(this.$http.post("graphql/", { query, variables: baseVariables }));
      }
      Promise.all(promiseArray)
        .then((responses) => {
          this.$message("ALERT", {
            text: this.$t("Reason Submitted for " + machineIds.length + " machines"),
            type: "success"
          });
          for (const res of responses) {
            this.$emit("submitted", res.data.data.createIssue.issue);
          }
          this.showDialog = false;
          this.selected = false;
          this.saving = false;
          this.showDialog = false;
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
          this.showDialog = false;
        });
    },
    showTimePicker() {
      this.activeTab = "timer";
    }
  },
  watch: {
    machineGroup(newValue) {
      if (!newValue) {
        this.applyToAllMachines = false;
        this.issueTime = null;
        this.issueDate = null;
      }
    },
    showDialog(newValue) {
      if (!newValue) {
        this.machine = {};
        this.machineGroup = {};
        this.selected = false;
        this.otherText = "";
        this.applyToAllMachines = false;
        this.issueTime = null;
        this.issueDate = null;
        this.activeTab = "calendar";
        this.issueType = null;
      }
    }
  }
};
</script>
