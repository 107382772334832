<template>
  <div class="">
    <div class="card-content">
      <help-center-search-input v-model="search" />

      <help-center-card
        v-for="video in data"
        :key="video.id"
        :description="video.description"
        :name="video.name"
        icon="mdi-file-video-outline"
        @click="selectDoc(video)"
      />
    </div>

    <v-dialog
      v-model="showDialog"
      width="1000"
    >
      <media-viewer
        v-if="showDialog"
        :media="selectedMedia"
        @close="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import MediaViewer from "@/components/MediaViewer";
import HelpCenterSearchInput from "./HelpCenterSearchInput.vue";
import { computed, ref } from "@vue/composition-api";
import { useRoute } from "vue2-helpers/vue-router";
import { useHelpCenterVideos } from "../hooks/useHelpCenterVideos";
import HelpCenterCard from "./HelpCenterCard.vue";

export default {
  components: {
    HelpCenterCard,
    HelpCenterSearchInput,
    MediaViewer
  },
  props: {},

  setup() {
    const search = ref("");
    const selectedMedia = ref(undefined);
    const showDialog = ref(false);
    const $route = useRoute();
    const machine_pk = computed(() => $route.params.machine_pk);

    const { data } = useHelpCenterVideos(machine_pk, search);

    const selectDoc = (doc) => {
      selectedMedia.value = doc;
      showDialog.value = true;
    };

    return {
      search,
      data,
      selectDoc,
      showDialog,
      selectedMedia
    };
  }
};
</script>
