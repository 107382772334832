<template>
  <ModalDialog
    v-model="showDialog"
    persistent
    class="cause-dialog"
    content-class="issue-entry-dialog cause"
    max-width="576px"
  >
    <template #title> {{ $t("Countermeasure For") }} {{ timeDisplay }} </template>

    <template #body>
      <div>
        <div class="selections">
          <div
            v-if="selectedReason"
            class="selection pb-2"
            @click="selectedReason = false"
          >
            <p
              class="selection-title text-subtitle-2 font-weight-regular color--text-secondary mr-1"
            >
              {{ $t("Reason") }}
            </p>
            <div class="reason">
              <v-btn
                class="reason-item color--text-primary text-subtitle-1"
                @click="
                  () => {
                    selectedReason = false;
                    selectedCM = null;
                  }
                "
              >
                {{ selectedReason.reason }}
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <div v-if="!selectedReason">
            <div
              v-for="issue in issues"
              :key="issue.id"
              class="reason"
            >
              <v-btn
                :class="{ selected: selectedReason.id == issue.id }"
                class="reason-item color--text-primary text-subtitle-1"
                @click="
                  () => {
                    selectReason(issue);
                  }
                "
              >
                {{ issue.reason }}
              </v-btn>
            </div>
          </div>
          <div
            v-if="selectedReason && cms"
            class="mt-4"
          >
            <div
              v-for="cm in cms"
              :key="'cm' + cm.node.id"
              class="countermeasure"
            >
              <v-btn
                :class="{ selected: selectedCM == cm }"
                :disabled="selectedReasonCMs.includes(cm.node.text)"
                class="reason-item color--text-primary text-subtitle-1"
                @click="
                  () => {
                    selectedCM = cm;
                  }
                "
              >
                {{ cm.node.text }}
              </v-btn>
            </div>
            <div
              key="other"
              class="countermeasure"
            >
              <v-btn
                :class="{ selected: selectedCM == 'other' }"
                class="reason-item color--text-primary text-subtitle-1"
                @click="
                  () => {
                    selectedCM === 'other' ? (selectedCM = null) : (selectedCM = 'other');
                  }
                "
              >
                {{ $t("Other") }}
              </v-btn>
            </div>
          </div>
        </div>
        <div
          v-if="selectedCM == 'other'"
          class="otherText"
        >
          <v-textarea
            v-model="otherText"
            :label="$t('Countermeasure')"
            class="mt-0 pt-5"
            height="120"
            filled
            hide-details
            clearable
          ></v-textarea>
        </div>
      </div>
    </template>

    <template #actions>
      <Btn
        class="cause-dialog-btn"
        width="144"
        @click="showDialog = false"
      >
        {{ $t("Cancel") }}
      </Btn>
      <Btn
        variant="primary"
        class="cause-dialog-btn"
        primary
        width="144"
        :disabled="!selectedCM || (selectedCM == 'other' && otherText == '')"
        :loading="saving"
        @click="updateIssue"
      >
        {{ $t("Submit") }}
      </Btn>
    </template>
  </ModalDialog>
</template>
<script>
import moment from "moment-timezone";

import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";

/* TODO: https://intelycx.atlassian.net/browse/MIM-4421
 *  This is a copy-paste component. It is necessary to bring about one type with MachineHourCounterMeasure
 * */
export default {
  components: { ModalDialog, Btn },
  // eslint-disable-next-line vue/require-prop-types
  props: ["issues", "time", "machine_id", "tab"],
  data() {
    return {
      showDialog: true,
      reasons: {},
      cms: [],
      selectedReason: false,
      selectedCM: false,
      saving: false,
      otherText: ""
    };
  },
  computed: {
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    selectedReasonCMs() {
      if (this.selectedReason.countermeasures) {
        return this.selectedReason.countermeasures
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    countermeasures() {
      let countermeasures = [];
      this.issues.forEach((issue) => {
        if (issue.countermeasures) {
          let issueCM = issue.countermeasures
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll("'", "")
            .split(",");
          if (issueCM.length > 0) {
            countermeasures = countermeasures.concat(issueCM);
          }
        }
      });
      return countermeasures;
    }
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.$emit("close");
      }
    }
  },
  created() {},
  methods: {
    moment,
    selectReason(issue) {
      this.selectedReason = issue;
      this.cms = [];
      if (issue.reason in this.reasons) {
        this.cms = this.reasons[issue.reason];
      } else {
        const query = `query($reason_Text: String, $machine: [ID]!){
          issueCountermeasures(reason_Text: $reason_Text, machine:$machine){
            edges{
              node{
                id
                text
              }
            }
          }
        }`;
        const variables = {
          reason_Text: issue.reason,
          machine: [this.machine_id]
        };
        this.$http
          .post("graphql/", { query: query, variables: variables })
          .then((res) => {
            this.reasons[issue.reason] = res.data.data.issueCountermeasures.edges.sort((a, b) => {
              if (a.node.text < b.node.text) {
                return -1;
              }
              if (a.node.text > b.node.text) {
                return 1;
              }
              return 0;
            });
            this.cms = this.reasons[issue.reason];
          })
          .catch((res) => {
            this.errors = res.errors;
          });
      }
    },
    updateIssue() {
      this.saving = true;
      const query = `mutation ($id: ID!, $issueInput: IssueInput!){
        updateIssue(globalId:$id, issueData: $issueInput) {
          issue{
            id,
            title,
            openedDate,
            closedDate,
            issueStartDate,
            issueEndDate,
            machineId,
            machineName,
            issueTypeId,
            issueTypeName,
            reason,
            countermeasures,
            cause,
            solution,
          }
        }
      }`;
      let variables = {
        id: this.selectedReason.id,
        issueInput: {}
      };

      if (this.selectedCM == "other" && this.otherText) {
        variables["issueInput"]["countermeasures"] = this.otherText;
      } else {
        variables["issueInput"]["countermeasures"] = this.selectedCM.node.text;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.$message("ALERT", { text: this.$t("Reason Submitted"), type: "success" });
          this.showDialog = false;
          this.selectedReason = false;
          this.selectedCM = false;
          this.cms = [];
          this.saving = false;
          this.$emit("UpdatedCM", res.data.data.updateIssue.issue);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import "~@/scss/dropdown";
@import "~@/ui/Reason/reason";

.dialog-btn {
  position: relative;
}

.issues {
  overflow: visible;
  width: auto;
  min-width: 250px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 0.5rem;
  z-index: 9999;
}

.v-badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ::v-deep {
    .v-badge__wrapper {
      position: static;
      width: auto;

      span {
        position: static;
        inset: 0;
        background-color: var(--btn-secondary) !important;
        color: var(--text-primary) !important;
        padding-left: 8px;
        padding-right: 8px;
        margin-left: 4px;
      }
    }
  }
}

.countermeasure ~ .countermeasure,
.reason ~ .reason {
  margin-top: 16px;
}

.selections {
  border: none;
}

.selection {
  border-bottom: 1px solid var(--border-primary);
}

.v-dialog .selections .selection .reason {
  width: calc(100% - 75px - 75px);

  button {
    border: none !important;
  }
}

.selection-title {
  width: 75px;
}

.close-button {
  top: 13px;
  right: 13px;
}

::v-deep {
  .issue-entry-dialog {
    width: 576px;
    height: 500px;
    background-color: var(--bg-secondary);
  }
}

.dialog-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-secondary);
}

.dialog-card-body {
  height: calc(100% - 69px);
  overflow-y: auto;
  padding-top: 20px !important;
}

.dialog-card-actions {
  margin-top: auto;
}

.dialog-title {
  padding: 18px 48px 18px 24px !important;
  border-bottom: 1px solid var(--border-secondary);
}
</style>
