var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"values"},[_c('div',{staticClass:"item total-open"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Total Open")))]),_vm._v(" "),_c('p',{staticClass:"total"},[_vm._v(_vm._s(_vm.enlargedTable.issues.length))])]),_vm._v(" "),_c('div',{staticClass:"item in-process"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("In Process")))]),_vm._v(" "),_c('p',{staticClass:"total"},[_vm._v(_vm._s(_vm.getInprocess(_vm.enlargedTable.issues)))])]),_vm._v(" "),_c('div',{staticClass:"item latest"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Latest (Days)")))]),_vm._v(" "),_c('p',{staticClass:"total"},[_vm._v(_vm._s(_vm.getLatest(_vm.enlargedTable.issues)))])]),_vm._v(" "),_c('div',{staticClass:"item oldest"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Oldest (Days)")))]),_vm._v(" "),_c('p',{staticClass:"total"},[_vm._v(_vm._s(_vm.getOldest(_vm.enlargedTable.issues)))])])]),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.enlargedIssueHeaders,"item-key":"key_as_string","items":_vm.enlargedTable.issues,"loading":_vm.enlargedTable.loading,"itemsPerPage":_vm.enlargedTable.itemsPerPage,"serverItemsLength":_vm.enlargedTable.total,"footer-props":{ 'items-per-page-options': [10, 25, 50, 100] },"disable-pagination":"","fixed-header":"","multi-sort":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.enlargedTable, "itemsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.enlargedTable, "itemsPerPage", $event)},function($event){return _vm.handleItemsPerPageChange($event, _vm.enlargedTable)}],"pagination":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.loadIssuePage.apply(void 0, [ _vm.enlargedTable ].concat( args ));
}},scopedSlots:_vm._u([{key:"item.relation",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.node.machineName ? item.node.machineName : item.node.machineGroupName))])]}},{key:"item.launch",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","fab":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.launchTicket(item)}}},[_c('i',{staticClass:"fa fa-external-link-square",attrs:{"aria-hidden":"true"}})])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"harveyBall mdi",class:_vm.getStatusIcon(item),attrs:{"aria-hidden":"true"}})]}},{key:"item.countermeasures",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'list-plus-button': _vm.getCMS(item).length > 0 }},[_c('counter-measure',{attrs:{"issues":[item.node],"machine_id":_vm.machinesLookup[item.node.machineId].id,"time":item.key_as_string,"tab":-1},on:{"UpdatedCM":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.updateIssue.apply(void 0, [ item ].concat( args ));
}}})],1)]}},{key:"item.cause",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'list-plus-button': _vm.getCauses(item).length > 0 }},[_c('cause',{attrs:{"issues":[item.node],"machine_id":_vm.machinesLookup[item.node.machineId].id,"time":item.key_as_string,"tab":-1},on:{"UpdatedCause":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.updateIssue.apply(void 0, [ item ].concat( args ));
}}})],1)]}},{key:"item.solution",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'list-plus-button': _vm.getSolutions(item).length > 0 }},[(_vm.hasCause([item.node]))?_c('solution',{attrs:{"issues":[item.node],"machine_id":_vm.machinesLookup[item.node.machineId].id,"time":item.key_as_string,"tab":-1},on:{"UpdatedSolution":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.loadIssues(_vm.enlargedTable);
}}}):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }