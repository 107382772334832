import dayjs from "dayjs";
import Vue from "vue";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const dateDuration = ({
  durationValue,
  type = "short",
  dateTo,
  dateFrom,
  useLessThanOneLabel = false,
  showSeconds = false
}) => {
  const $t = Vue.i18n.translate;

  const date = durationValue
    ? dayjs.duration(durationValue * 1000)
    : dayjs.duration(dayjs(dateTo).diff(dateFrom));

  const dataDurationFormats = {
    short: {
      seconds: `s[${$t("s")}]`,
      minutes: `m[${$t("min")}]`,
      hours: `H[${$t("h")}]`,
      days: `D[${$t("d")}]`,
      months: `M[${$t("m")}]`,
      years: `Y[${$t("y")}]`
    },
    long: {
      minutes: `m [${$t("min")}]`,
      hours: `H [${$t("hours")}]`,
      days: `D [${$t("days")}]`,
      months: `M [${$t("months")}]`,
      years: `Y [${$t("years")}]`
    }
  };

  const value = date;
  let selectedFormat;
  let additionalFormat;

  if (durationValue === 0) {
    return 0;
  }

  if (value.years()) {
    selectedFormat = "years";
    additionalFormat = value.months() ? "months" : null;
  } else if (value.months()) {
    selectedFormat = "months";
    additionalFormat = value.days() ? "days" : null;
  } else if (value.days()) {
    selectedFormat = "days";
    additionalFormat = value.hours() ? "hours" : null;
  } else if (value.hours()) {
    selectedFormat = "hours";
    additionalFormat = value.minutes() ? "minutes" : null;
  } else if (value.minutes()) {
    selectedFormat = "minutes";
    additionalFormat = showSeconds && value.seconds() ? "seconds" : null;
  } else {
    selectedFormat = "minutes";
  }

  const mainDateFormat = dataDurationFormats[type][selectedFormat];
  const additionalDateFormat = dataDurationFormats[type][additionalFormat];

  const format = additionalDateFormat
    ? mainDateFormat + " " + additionalDateFormat
    : mainDateFormat;
  let result = value.format(format);

  /* EDGE CASE:
   * When duration = 1 day
   * need to show 24 hours */
  if (selectedFormat === "days" && value.days() === 1 && value.hours() === 0) {
    result = `24 ${$t("h")}`;
  }

  if (useLessThanOneLabel && result.startsWith("0")) {
    return `< ${result.replace("0", "1")}`;
  }
  return result;
};
