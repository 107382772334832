<template>
  <div class="data-table-view">
    <P911AnalyticsIssueCards
      :filters="filters"
      :clickHandler="handleIssueCardClick"
      :openClosedOrTotal="openClosedOrTotal"
    />
    <div class="p911-ticket-table">
      <HelpTickets
        ref="helpTickets"
        :p911Table="table"
        :filters="updatedFilters"
        :p911OpenClosedOrTotal="openClosedOrTotal"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";

import { TableData } from "@/datatypes/table";
import { ISSUE_CARD_TYPES } from "@/utils/p911AnalyticsUtils";
import HelpTickets from "@/views/metrics/group/HelpTickets";

import P911AnalyticsIssueCards from "../P911AnalyticsIssueCards";

export default {
  name: "P911DataTableView",
  props: {
    filters: { type: Object, default: () => {} }
  },
  components: {
    HelpTickets,
    P911AnalyticsIssueCards
  },
  data() {
    return {
      ISSUE_CARD_TYPES: Object.freeze(ISSUE_CARD_TYPES),
      openClosedOrTotal: ISSUE_CARD_TYPES.TOTAL,
      table: new TableData([
        { text: this.$t("Machine"), value: "machineName", sortable: true, width: "115px" },
        { text: this.$t("Tags"), value: "tagSearch", sortable: false },
        {
          text: this.$t("Details"),
          value: "node.details",
          sortable: false,
          cellClass: "ticket-details"
        },
        { text: this.$t("Reference"), value: "node.reference", align: "right", sortable: true },
        { text: this.$t("Ticketed By"), value: "ticketedByName", sortable: true },
        { text: this.$t("Date"), value: "ticketedDate", sortable: true, width: "115px" },
        { text: this.$t("Duration"), value: "duration", sortable: false },
        { text: this.$t("Assigned"), value: "assignedToNames", sortable: false, width: "250px" },
        { text: "", value: "launch", sortable: false }
      ])
    };
  },
  computed: {
    updatedFilters() {
      if (this.filters.machines && this.filters.machines.length > 0) {
        const machines = this.filters.machines.map((machine) => machine.pk);
        return { ...this.filters, machines };
      }
      return this.filters;
    }
  },
  methods: {
    handleIssueCardClick(type) {
      this.openClosedOrTotal = type;
    },
    async getHelpTickets() {
      Vue.nextTick(async () => {
        await this.$refs.helpTickets.loadHelpTickets();
        Promise.resolve();
      });
    }
  },
  watch: {
    openClosedOrTotal() {
      this.getHelpTickets();
    },
    filters: {
      deep: true,
      handler: function () {
        this.$refs.helpTickets.helpTable.page.current = 1;
      }
    }
  }
};
</script>
