export const queryArrayToNumber = (param) => {
  if (!param) {
    return undefined;
  }

  if (Array.isArray(param)) {
    return param.map(Number);
  }

  return [Number(param)];
};
