<template>
  <div class="p911-impact-summary-view">
    <GridLayout
      :col-num="12"
      :row-height="110"
      :is-resizable="false"
      :is-draggable="false"
      :vertical-compact="true"
      :use-css-transforms="false"
      :layout.sync="layout"
    >
      <GridItem
        v-for="(item, index) in layout"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
      >
        <Component
          :ref="item.component"
          :is="item.component"
          v-bind="item.props"
        />
      </GridItem>
    </GridLayout>
  </div>
</template>
<script>
import { GridItem, GridLayout } from "vue-grid-layout";

import P911AnalyticsHelpTags from "./P911AnalyticsHelpTags.vue";
import P911AnalyticsMachines from "./P911AnalyticsMachines.vue";
import P911TicketTable from "./P911TicketTable.vue";

export default {
  name: "P911ImpactSummaryView",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    helpTags: { type: Array, default: () => [] },
    filterChange: { type: Boolean, default: false }
  },
  components: {
    GridLayout,
    GridItem,
    P911AnalyticsMachines,
    P911TicketTable,
    P911AnalyticsHelpTags
  },
  computed: {
    layout() {
      return [
        {
          x: 0,
          y: 0,
          w: 12,
          h: 3,
          i: 0,
          component: "P911AnalyticsMachines",
          props: {
            filters: this.filters,
            interactiveFilters: this.interactiveFilters,
            filterChange: this.filterChange
          }
        },
        {
          x: 0,
          y: 3,
          w: 12,
          h: 3,
          i: 1,
          component: "P911AnalyticsHelpTags",
          props: {
            filters: this.filters,
            interactiveFilters: this.interactiveFilters,
            filterChange: this.filterChange,
            helpTags: this.helpTags
          }
        },
        {
          x: 0,
          y: 6,
          w: 12,
          h: 6,
          i: 2,
          component: "P911TicketTable",
          props: {
            filters: this.filters,
            interactiveFilters: this.interactiveFilters,
            filterChange: this.filterChange,
            helpTags: this.helpTags
          }
        }
      ];
    }
  }
};
</script>
<style lang="scss"></style>
