<template>
  <div class="order-by-machine-container">
    <div class="search-send-container">
      <v-tabs
        class="submitted-tabs"
        v-model="selectedSubmittedTab"
        key="submitted-tabs"
        hide-slider
      >
        <v-tab key="submitted">
          <p>
            <span class="button_text">{{ $t("Submitted") }}</span>
            <span class="tiny_button_text">{{ $t("Submitted") }}</span>
          </p>
        </v-tab>
        <v-tab key="unsubmitted">
          <p>
            <span class="button_text">{{ $t("Unsubmitted") }}</span>
            <span class="tiny_button_text">{{ $t("Unsubmitted") }}</span>
          </p>
        </v-tab>
      </v-tabs>
      <div class="machine-search">
        <v-text-field
          v-model="orderByMachineSearchText"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </div>
      <v-checkbox
        v-model="showMyMachines"
        class="my-machines-checkbox"
        :class="{
          'no-send': selectedSubmittedTab === 0,
          'send-present': selectedSubmittedTab === 1
        }"
        :label="$t('My Machines')"
      >
      </v-checkbox>
      <v-btn
        v-if="selectedSubmittedTab === 1"
        color="primary"
        large
        class="send-button"
        :disabled="disableOrderByMachineSendToERPButton"
        @click="handleSendToERP"
      >
        {{ $t("Send to ERP") }}
      </v-btn>
    </div>
    <v-data-table
      :headers="scrapOrderByMachineHeaders"
      :items="filteredTableData"
      :loading="scrapOrderByMachineLoading"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Scrap Details... Please wait')"
      :no-data-text="$t('No data available')"
      :expanded.sync="expanded"
      @item-expanded="handleExpand"
      item-key="name"
      fixed-header
      show-expand
    >
      <template v-slot:item.transmit="{ item }">
        <v-checkbox
          v-model="item.transmit"
          :disabled="selectedSubmittedTab === 0"
        >
        </v-checkbox>
      </template>
      <template v-slot:body.prepend>
        <tr
          class="total-row"
          v-if="scrapOrderByMachineData.length > 0"
        >
          <td class="total-text">
            <span>{{ $t("Total") }}</span>
          </td>
          <td></td>
          <td
            v-if="expanded.length === 0"
            class="total-cost"
          >
            <span>{{ totalCost }}</span>
          </td>
          <td
            v-if="expanded.length === 0"
            class="select-all-button"
          >
            <v-btn
              text
              @click="selectAll"
              :disabled="selectedSubmittedTab === 0"
            >
              {{ $t("Select All") }}
            </v-btn>
          </td>
          <td v-if="expanded.length > 0"></td>
          <td v-if="expanded.length > 0"></td>
          <td v-if="expanded.length > 0"></td>
          <td
            v-if="expanded.length > 0"
            class="total-cost"
          >
            <span>{{ totalCost }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td>
          <div
            v-for="(data, index) in item.items"
            class="expanded-cell"
          >
            <span v-if="index === item.items.length - 1">{{ $t("Sub Total") }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          ></div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          >
            <span>{{ data.component }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          >
            <span
              class="description-text"
              :title="data.description"
              >{{ data.description }}</span
            >
          </div>
        </td>
        <td>
          <div
            v-for="data in item.items"
            class="expanded-cell"
          >
            <span>{{ data.enteredQuantity }} {{ data.uom }}</span>
          </div>
        </td>
        <td>
          <div
            v-for="(data, index) in item.items"
            class="expanded-cell"
          >
            <span v-if="index === item.items.length - 1">{{ data.formattedSubTotal }}</span>
            <span v-else>{{ data.formattedCost }}</span>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "QualityOrderByMachine",
  props: ["machineGroupPk", "from_date", "to_date", "shift"],
  data() {
    return {
      expanded: [],
      scrapOrderByMachineData: [],
      selectAllOrderByMachine: false,
      orderByMachineSearchText: "",
      selectedSubmittedTab: 0,
      showMyMachines: false,
      scrapOrderByMachineLoading: false
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    }),
    filteredTableData() {
      return this.orderByMachineSearchText
        ? this.scrapOrderByMachineData.filter((data) =>
            data.name.toLowerCase().includes(this.orderByMachineSearchText.toLowerCase())
          )
        : this.scrapOrderByMachineData;
    },

    disableOrderByMachineSendToERPButton() {
      return (
        this.selectedSubmittedTab === 0 ||
        this.scrapOrderByMachineData.findIndex((data) => data.transmit) === -1
      );
    },
    totalCost() {
      return `$ ${this.scrapOrderByMachineData.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    scrapOrderByMachineHeaders() {
      if (this.expanded.length > 0) {
        return [
          { text: this.$t("Machine"), value: "name", cellClass: "machine-name" },
          { text: this.$t("Components"), value: "component", cellClass: "component" },
          { text: this.$t("Description"), value: "description" },
          { text: this.$t("Scrapped Quantity"), value: "enteredQuantity", cellClass: "quantity" },
          { text: this.$t("Cost"), value: "formattedCost", cellClass: "cost" }
        ];
      }
      return [
        { text: this.$t("Machine"), value: "name", width: "60%" },
        { text: this.$t("Cost"), value: "formattedCost", width: "20%", cellClass: "cost" },
        {
          text: this.$t("Transmit to ERP"),
          value: "transmit",
          width: "20%",
          cellClass: "transmit",
          sortable: false
        }
      ];
    }
  },
  created() {
    this.getSummaryTableData();
  },
  methods: {
    async handleExpand({ item }) {
      this.scrapOrderByMachineLoading = true;
      try {
        const params = {
          machine_id: parseInt(item.id, 10),
          submitted: this.selectedSubmittedTab === 0 ? true : false,
          shift_id: this.shift ? this.shift : null,
          from_date: this.from_date.toISOString(),
          to_date: this.to_date.toISOString()
        };
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_code/", { params });
        if (response && response.data) {
          const data = response.data.map((scrap) => {
            return {
              component:
                scrap.scrap_type === "non_process"
                  ? scrap.child_component_name
                  : scrap.scrap_reason_code,
              description:
                scrap.scrap_type === "non_process"
                  ? scrap.child_component_description
                  : scrap.description,
              enteredQuantity: scrap.entered_quantity,
              uom: "scrap_quantity_uom" in scrap ? scrap.scrap_quantity_uom : "",
              cost: scrap.total_cost,
              formattedCost: `$ ${scrap.total_cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            };
          });
          item.items = data;
          item.items.push({
            formattedSubTotal:
              data.length > 0
                ? `$ ${item.items.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                : item.formattedCost
          });
        }
        this.scrapOrderByMachineLoading = false;
      } catch (error) {
        console.error(error);
        this.scrapOrderByMachineLoading = false;
      }
    },
    async getSummaryTableData() {
      this.scrapOrderByMachineLoading = true;
      const params = {
        machine_group_id: this.machineGroupPk,
        my_machines: this.showMyMachines,
        submitted: this.selectedSubmittedTab === 0 ? true : false,
        shift_id: this.shift ? this.shift : null,
        from_date: this.from_date.toISOString(),
        to_date: this.to_date.toISOString()
      };
      try {
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_machine/", { params });
        if (response && response.data) {
          const data = Object.keys(response.data).map((key) => ({
            id: key,
            name: this.machineLookup(parseInt(key, 10)).name,
            cost: response.data[key],
            formattedCost: `$ ${response.data[key].toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`,
            transmit: this.selectedSubmittedTab === 0 ? true : false,
            items: []
          }));
          this.scrapOrderByMachineData = data;
        }
        this.scrapOrderByMachineLoading = false;
      } catch (error) {
        console.error(error);
        this.scrapOrderByMachineLoading = false;
      }
    },
    selectAll() {
      this.selectAllOrderByMachine = !this.selectAllOrderByMachine;
      this.scrapOrderByMachineData.forEach(
        (data) => (data.transmit = this.selectAllOrderByMachine)
      );
    },
    sendToERP(machine_id) {
      const params = {
        machine_id: machine_id
      };
      const handleResponse = async (response) => {
        response = response.data;
        const errors = response["errors"];
        console.log("errors", errors);
        const successes = response["successful_submissions"];
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        const delayTime = 20;
        for (const error of errors) {
          this.$message("ALERT", {
            text: `${error.code}: ${error.error}. Scrap not submitted.`,
            type: "error"
          });
          await delay(delayTime);
        }
        for (const success of successes) {
          this.$message("ALERT", {
            text: this.$t(success),
            type: "success"
          });
          await delay(delayTime);
        }
        if (errors.length === 0) {
          this.$message("ALERT", {
            text: this.$t("All scrap submitted successfully."),
            type: "success"
          });
        }
      };
      this.$http
        .post("scrap-reporting/submit_scrap/", { params })
        .then((response) => {
          handleResponse(response);
        })
        .catch((error) => {
          handleResponse(error.response);
        })
        .finally(() => {
          this.getSummaryTableData();
        });
    },
    handleSendToERP() {
      // for each unique machine id in scrapOrderByMachineData where transmit is true, call sendToERP
      this.scrapOrderByMachineData
        .filter((data) => data.transmit)
        .forEach((data) => this.sendToERP(data.id));
    }
  },
  watch: {
    selectedSubmittedTab() {
      this.getSummaryTableData();
      this.scrapOrderByMachineData.forEach(
        (data) => (data.transmit = this.selectedSubmittedTab === 0 ? true : false)
      );
      this.expanded.forEach((row) => this.handleExpand({ item: row }));
    },
    showMyMachines() {
      this.getSummaryTableData();
      this.expanded = [];
    },
    shift() {
      this.getSummaryTableData();
      this.expanded = [];
    },
    from_date() {
      this.getSummaryTableData();
      this.expanded = [];
    },
    to_date() {
      this.getSummaryTableData();
      this.expanded = [];
    }
  }
};
</script>
<style lang="scss"></style>
