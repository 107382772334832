<template>
  <div class="card solutions">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Long Term Solution") }}</span>
    </div>
    <div class="analytics-card-header">
      <AnalyticsPagination :handleTabChange="handleTabChange" />
    </div>
    <div class="analytics-card-content">
      <BarChart
        class="bar-chart"
        :chartData="solutionsBarChartData"
        :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
      />
    </div>
  </div>
</template>
<script>
import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart from "@/components/charts/BarChart";
import { tiffanyBlue } from "@/scss/_variables.scss";
import {
  handleBackgroundColorsNoOthersPresent,
  handleDataClickColors,
  handleOtherClickColors
} from "@/utils/LDMSAnalyticsUtils";
import { hexToRGB } from "@/utils/colors";

export default {
  name: "LDMSAnalyticsSolutions",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    predefinedSolutions: { type: Array, default: () => [] }
  },
  components: {
    AnalyticsPagination,
    BarChart
  },
  data() {
    return {
      solutionsBarChartData: {},
      solutionsKeys: [],
      pageSize: 10,
      otherSolutions: []
    };
  },
  methods: {
    isOtherSolutions(elementIndex) {
      return (
        this.otherSolutions.length > 0 &&
        elementIndex === this.solutionsBarChartData.datasets[0].data.length - 1
      );
    },
    otherSolutionsExistInFilter() {
      return this.otherSolutions.some(
        (otherSolution) =>
          this.interactiveFilters.solutions.findIndex(
            (solution) => otherSolution.key === solution.key
          ) >= 0
      );
    },
    allNonOtherSolutionsExistInFilter() {
      return this.solutionKeys.every(
        (key) =>
          this.interactiveFilters.solutions.findIndex((solution) => key === solution.key) >= 0
      );
    },
    updateDataBackgroundColors() {
      this.solutionsBarChartData.datasets[0].backgroundColor = handleDataClickColors(
        this.solutionsBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.solutions
      );
    },
    updateOtherBackgroundColors() {
      const allNonOtherSolutionsExistInFilter = this.allNonOtherSolutionsExistInFilter();
      this.solutionsBarChartData.datasets[0].backgroundColor = handleOtherClickColors(
        this.solutionsBarChartData.datasets[0].backgroundColor,
        allNonOtherSolutionsExistInFilter
      );
    },
    updateBackgroundColorsNoOthersPresent() {
      this.solutionsBarChartData.datasets[0].backgroundColor =
        handleBackgroundColorsNoOthersPresent(
          this.solutionsBarChartData.datasets[0].backgroundColor,
          this.interactiveFilters.solutions
        );
    },
    async handleTabChange(pageSize) {
      this.pageSize = pageSize;
      await this.getSolutionsData();
      if (this.interactiveFilters.solutions.length === 0) {
        return;
      }
      if (this.otherSolutionsExistInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      if (this.isOtherSolutions(elementIndex)) {
        this.setInteractiveFilterForOther();
        return;
      }
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilterForOther() {
      if (!this.otherSolutionsExistInFilter()) {
        this.interactiveFilters.solutions.push(...this.otherSolutions);
      } else {
        const filterCopy = this.interactiveFilters.solutions.slice();
        this.otherSolutions.forEach((otherSolution) => {
          const foundSolutionIndex = filterCopy.findIndex(
            (solution) => otherSolution.key === solution.key
          );
          if (foundSolutionIndex >= 0) {
            filterCopy.splice(foundSolutionIndex, 1);
          }
        });
        this.interactiveFilters.solutions = filterCopy.slice();
      }
      this.updateOtherBackgroundColors();
    },
    setInteractiveFilter(elementIndex) {
      const solutionKey = this.solutionKeys[elementIndex];
      const foundSolutionIndex = this.interactiveFilters.solutions.findIndex(
        (solution) => solution.key === solutionKey
      );
      if (foundSolutionIndex >= 0) {
        this.interactiveFilters.solutions.splice(foundSolutionIndex, 1);
      } else {
        this.interactiveFilters.solutions.push({ key: solutionKey, index: elementIndex });
      }
      if (this.otherSolutionsExistInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    async getSolutionsData() {
      const machines = this.interactiveFilters.machines.map((machine) => machine.id);
      const parts = this.interactiveFilters.parts.map((part) => part.id);
      const reasons = this.interactiveFilters.reasons.map((reason) => reason.id);
      const countermeasures = this.interactiveFilters.countermeasures.map(
        (countermeasure) => countermeasure.key
      );
      const causes = this.interactiveFilters.rootCauses.map((rootCause) => rootCause.key);
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_group: this.filters.machineGroups,
        machine_ids: machines.length > 0 ? machines : this.filters.machines,
        part_numbers: parts.length > 0 ? parts : this.filters.parts,
        reason_ids: reasons.length > 0 ? reasons : this.filters.reasons,
        countermeasures,
        causes
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/solutions/", { params });
        if (response && response.data) {
          this.otherSolutions = response.data.filter(
            (item) =>
              this.predefinedSolutions.findIndex((solution) => solution.text === item.key) === -1
          );
          const data = response.data.filter(
            (item) =>
              this.predefinedSolutions.findIndex((solution) => solution.text === item.key) >= 0
          );
          this.solutionKeys = data.map((solution) => solution.key);
          // const colors = [...Array(this.otherSolutions.length > 0 ? data.length + 1 : data.length).fill(0).map(() => getColor())];
          const colors = [
            ...Array(this.otherSolutions.length > 0 ? data.length + 1 : data.length)
              .fill(0)
              .map(() => hexToRGB(tiffanyBlue, "0.9"))
          ];
          this.solutionsBarChartData = {
            labels: data.map((solution) => solution.key),
            datasets: [
              {
                data: data.map((item) => item.doc_count),
                label: this.$t("Number of Issues"),
                backgroundColor: colors
              }
            ]
          };
          if (this.otherSolutions.length > 0) {
            let otherIssueTotal = 0;
            this.otherSolutions.forEach((solution) => (otherIssueTotal += solution.doc_count));
            this.solutionsBarChartData.labels.push(this.$t("Other"));
            this.solutionsBarChartData.datasets[0].data.push(otherIssueTotal);
          }
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
<style lang="scss"></style>
