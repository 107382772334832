<template>
  <fragment>
    <div class="top-parts-cost metric-component">
      <div class="mobile-chart-title-container">
        <h2 class="card-title">{{ $t("Top 10 Product Codes") }}</h2>
      </div>

      <div class="charts_container">
        <div class="chart">
          <HBarChart
            v-if="chart_data[metric.node.name]"
            :chartID="metric.node.name + 'parts'"
            :chart-name="metric.node.name + 'parts'"
            :chart-data="chart_data[metric.node.name]"
            :chart-options="{
              scales: {
                yAxes: [{ zeroLineColor: '#FFF' }],
                xAxes: [
                  {
                    ticks: {
                      minRotation: 90,
                      maxRotation: 90
                    }
                  }
                ]
              }
            }"
            class="dynamicChart"
          />
        </div>
      </div>
    </div>
  </fragment>
</template>
<style lang="scss">
.top-parts-cost {
  position: relative;
  margin-left: 1rem;
  flex: unset;
  align-self: flex-end;

  @media screen and(max-width: 1200px) {
    flex: 1;
    align-self: normal;

    .charts {
      display: flex;
      justify-content: center;
      align-items: center;
      .line-container {
        width: calc(100% - 150px);
        .oeeChart {
          width: 100%;
          height: 18vh;
          padding-left: 0.5rem;
        }
      }
    }

    .chart {
      width: 100% !important;
      display: grid;
    }
  }

  .chart-title {
    position: absolute;
    top: -2rem;
    font-size: 1.25rem;
  }
  .chart {
    width: 350px;
  }
  .gauge-container {
    flex: none;
  }

  .charts_container {
    .chart {
      height: 250px;
      .dynamicChart {
        height: 250px;
        position: absolute;
        width: 100%;
      }
    }
  }
}
</style>
<script>
import moment from "moment";
import { Fragment } from "vue-fragment";
import { chartOptions } from "../charts/chartOptions";
import HBarChart from "../charts/HBarChart";
import { SCALE_TAB_VALUE } from "./zone_control_enum";

export default {
  props: [`metric`, "timeScale", "machine_group_pk"],
  components: {
    HBarChart,
    Fragment
  },
  data() {
    return {
      chartOptions: new chartOptions(),
      production: null,
      chart_data: []
    };
  },
  created: async function () {
    const parts = await this.loadOpportunityCost();
  },
  computed: {},
  methods: {
    loadOpportunityCost: async function () {
      let params = {
        machine_group_id: this.machine_group_pk,
        from_date: moment().set("minute", 0).subtract(1, "month").toISOString(),
        to_date: moment().set("minute", 0).toISOString(),
        sort: "total_performance_variance"
      };
      switch (this.metric.node.automatic) {
        case "PERFORMANCE": {
          params["sort"] = "total_performance_variance";
          break;
        }
        case "QUALITY": {
          params["sort"] = "total_quality_variance";
          break;
        }
        case "OEE": {
          params["sort"] = "total_oee_variance";
          break;
        }
      }
      switch (this.timeScale) {
        case SCALE_TAB_VALUE.WEEK: {
          params["from_date"] = moment().startOf("week").toISOString();
          params["to_date"] = moment().endOf("week").toISOString();
          break;
        }
        case SCALE_TAB_VALUE.MONTH: {
          params["from_date"] = moment().startOf("month").toISOString();
          params["to_date"] = moment().endOf("month").toISOString();
          break;
        }
        case SCALE_TAB_VALUE.YEAR: {
          params["from_date"] = moment().startOf("year").toISOString();
          params["to_date"] = moment().endOf("year").toISOString();
          break;
        }
        default: {
          // handles cases when timeScale is in the form 'start_date_as_ISO_string end_date_as_ISO_string'
          let dates = this.timeScale.split(" ");
          params["from_date"] = dates[0];
          params["to_date"] = dates[1];
          break;
        }
      }
      this.$http.get("variance/cost_per_part/", { params: params }).then((res) => {
        const parts = res.data;
        const data = {};
        const name = this.metric.node.name;
        data[name] = { data: [], label: [], color: [], tooltips: [] };
        parts.forEach((part) => {
          let value = 0;
          switch (this.metric.node.automatic) {
            case "QUALITY":
              value = part.total_quality_variance.value;
              break;
            case "PERFORMANCE":
              value = part.total_performance_variance.value;
              break;
            case "OEE":
              value = part.total_oee_variance.value;
              break;
          }

          data[name].data.push(value > 0 ? value.toFixed(2) : 0);
          data[name].tooltips.push(value > 0 ? "$" + value.toFixed(2) : 0);
          data[name].label.push(part.key);
          data[name].color.push(this.$colors.chart.good_gradient);
        });
        this.chart_data = this.formatDataForCharts(data);
      });
    },
    formatDataForCharts(data) {
      const keys = Object.keys(data);
      const formattedData = {};

      for (const key of keys) {
        const d = {
          datasets: [
            {
              data: data[key].data,
              type: "horizontalBar",
              backgroundColor: "rgb(247, 189, 89)",
              titles: "",
              footers: "",
              tooltips: data[key].tooltips,
              label: key
            }
          ],
          labels: data[key].label
        };

        formattedData[key] = d;
      }

      return formattedData;
    }
  },
  watch: {
    timeScale: function () {
      console.log("top chart trigger");
      this.chart_data = [];
      this.loadOpportunityCost();
    }
  }
};
</script>
