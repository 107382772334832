export const normalize = (vector) => {
  const length = Math.sqrt(vector.x * vector.x + vector.y * vector.y + vector.z * vector.z);
  return { x: vector.x / length, y: vector.y / length, z: vector.z / length };
};

export const fromSdkToApi = ({ x, y, z }) => ({ x: x, y: -z, z: y });
export const fromApiToSdk = ({ x, y, z }) => ({ x: x, y: z, z: -y });

export const hexToRgb = (hex) => {
  // Remove '#' if it's included in the input string
  hex = hex.replace("#", "");

  // Parse the hex string into separate RGB components
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Return an object containing the RGB components
  return { r: r / 255, g: g / 255, b: b / 255 };
};
