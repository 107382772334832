import { useQuery } from "@tanstack/vue-query";
import { zoneControlApi } from "@/features/zone-control/zoneControlApi";

export const useZoneControlWeeklyProduction = (params) => {
  return useQuery({
    queryKey: ["zone-control-weekly-production", params],
    queryFn: () => zoneControlApi.getWeeklyProduction(params.value),
    keepPreviousData: true,
    staleTime: 10000,
    retry: 0
  });
};
