<template>
  <v-app class="theme--dark">
    <v-card
      style="margin: 1rem 1rem 1rem 1rem"
      class="theme--dark"
    >
      <v-card-title class="headline">{{ $t("Permission Denied") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            ><lottie-player
              :src="notLaunched"
              background="transparent"
              speed="1"
              style="width: 500px; height: 500px"
              class="mx-auto"
              autoplay
            ></lottie-player
          ></v-col>
          <v-col><div v-html="helpText"></div></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="
            () => {
              clearHelpText();
              goBack();
            }
          "
        >
          {{ $t("Back") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import { stringify } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import "@lottiefiles/lottie-player";

import notLaunched from "../assets/lottie/noaccess.json";

export default {
  name: "PermissionDenied",
  data() {
    return {
      notLaunched: stringify(notLaunched),
      helpText: ""
    };
  },
  mounted() {
    this.helpText = localStorage.getItem("helpText");
  },
  methods: {
    clearHelpText() {
      localStorage.removeItem("helpText");
    },
    goBack() {
      this.$router.go(-3);
    }
  }
};
</script>

<style scoped></style>
