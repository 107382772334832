<template>
  <div class="flex-grow-1">
    <v-text-field
      :value="search"
      class="search"
      prepend-inner-icon="mdi-magnify"
      :label="$t('Search')"
      single-line
      hide-details
      clearable
      @input="debounceInput"
    ></v-text-field>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  name: "SearchField",
  props: {
    onInputSearch: { type: Function, default: undefined }
  },
  setup(props) {
    const search = ref("");

    const debounceInput = _.debounce((e) => {
      search.value = e;
      props.onInputSearch(search.value);
    }, 750);

    return {
      search,
      debounceInput
    };
  }
};
</script>

<style scoped lang="scss">
.search {
  width: 200px;
}

.search ::v-deep {
  .v-label,
  .v-icon {
    color: var(--text-secondary) !important;
    opacity: 1;
  }
  .v-icon.v-icon {
    font-size: 20px;
  }
}

::v-deep .theme--dark.v-text-field > .v-input__control > .v-input__slot:before,
::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: var(--border-primary);
}
</style>
