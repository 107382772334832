var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"manualTrend metric-component"},[_c('div',{staticClass:"mobile-chart-title-container"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t(_vm.metric.node.name)))]),_vm._v(" "),_c('span',{staticClass:"mobile-gauge-container"},[_c('v-gauge',{attrs:{"gaugeValueClass":_vm.gaugeValue(_vm.metric.node.currentValue),"value":_vm.metric.node.currentValue
              ? parseFloat(_vm.metric.node.currentValue.value.replace(/\D/g, ''))
              : 0,"donut":true,"options":_vm.metric.node.currentValue && _vm.metric.node.currentValue.value.indexOf('%') >= 0
              ? _vm.gaugeOpts
              : _vm.noringGaugeOpts,"width":"140px","height":"140px"}})],1)]),_vm._v(" "),_c('div',{staticClass:"charts"},[_c('div',{staticClass:"gauge-container",class:_vm.metric.node.currentValue &&
          _vm.metric.node.currentValue.value &&
          _vm.metric.node.currentValue.value.indexOf('%') >= 0
            ? ''
            : 'hide-circle'},[_c('v-gauge',{attrs:{"gaugeValueClass":_vm.gaugeValue(_vm.metric.node.currentValue),"value":_vm.metric.node.currentValue && _vm.metric.node.currentValue.value
              ? parseFloat(_vm.metric.node.currentValue.value.replace(/\D/g, ''))
              : 0,"donut":true,"options":_vm.metric.node.currentValue &&
            _vm.metric.node.currentValue.value &&
            _vm.metric.node.currentValue.value.indexOf('%') >= 0
              ? _vm.gaugeOpts
              : _vm.noringGaugeOpts,"width":"140px","height":"140px"}})],1),_vm._v(" "),_c('div',{staticClass:"charts_container"},[_c('div',{staticClass:"chart"},[_c('BarChart',{staticClass:"dynamicChart",attrs:{"chart-name":_vm.metric.node.name,"chart-data":_vm.chart_data[_vm.metric.node.name],"chart-options":{
              scales: {
                yAxes: [{ zeroLineColor: '#FFF' }],
                xAxes: [
                  {
                    ticks: {
                      minRotation: 90,
                      maxRotation: 90
                    }
                  }
                ]
              }
            }}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }