<template>
  <div
    class="card root-cause"
    :class="{ 'only-chart': !showCountermeasuresChart, 'two-charts': showCountermeasuresChart }"
  >
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Root Cause") }}</span>
    </div>
    <div class="analytics-card-header">
      <AnalyticsPagination :handleTabChange="handleTabChange" />
    </div>
    <div class="analytics-card-content">
      <HBarChart
        class="bar-chart"
        :chartData="rootCausesBarChartData"
        :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
      />
    </div>
  </div>
</template>
<script>
import AnalyticsPagination from "@/components/AnalyticsPagination";
import HBarChart from "@/components/charts/HBarChart";
import { peachPuff } from "@/scss/_variables.scss";
import {
  handleBackgroundColorsNoOthersPresent,
  handleDataClickColors,
  handleOtherClickColors
} from "@/utils/LDMSAnalyticsUtils";
import { hexToRGB } from "@/utils/colors";

export default {
  name: "LDMSAnalyticsRootCauses",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    showCountermeasuresChart: { type: Boolean, default: false },
    predefinedRootCauses: { type: Array, default: () => [] }
  },
  components: {
    AnalyticsPagination,
    HBarChart
  },
  data() {
    return {
      rootCausesBarChartData: {},
      rootCauseKeys: [],
      pageSize: 10,
      otherRootCauses: []
    };
  },
  methods: {
    isOtherRootCauses(elementIndex) {
      return (
        this.otherRootCauses.length > 0 &&
        elementIndex === this.rootCausesBarChartData.datasets[0].data.length - 1
      );
    },
    otherRootCausesExistInFilter() {
      return this.otherRootCauses.some(
        (otherRootCause) =>
          this.interactiveFilters.rootCauses.findIndex(
            (rootCause) => otherRootCause.key === rootCause.key
          ) >= 0
      );
    },
    allNonOtherRootCausesExistInFilter() {
      return this.rootCauseKeys.every(
        (key) =>
          this.interactiveFilters.rootCauses.findIndex((rootCause) => key === rootCause.key) >= 0
      );
    },
    updateDataBackgroundColors() {
      this.rootCausesBarChartData.datasets[0].backgroundColor = handleDataClickColors(
        this.rootCausesBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.rootCauses
      );
    },
    updateOtherBackgroundColors() {
      const allNonOtherRootCausesExistInFilter = this.allNonOtherRootCausesExistInFilter();
      this.rootCausesBarChartData.datasets[0].backgroundColor = handleOtherClickColors(
        this.rootCausesBarChartData.datasets[0].backgroundColor,
        allNonOtherRootCausesExistInFilter
      );
    },
    updateBackgroundColorsNoOthersPresent() {
      this.rootCausesBarChartData.datasets[0].backgroundColor =
        handleBackgroundColorsNoOthersPresent(
          this.rootCausesBarChartData.datasets[0].backgroundColor,
          this.interactiveFilters.rootCauses
        );
    },
    async handleTabChange(pageSize) {
      this.pageSize = pageSize;
      await this.getRootCauseData();
      if (this.interactiveFilters.rootCauses.length === 0) {
        return;
      }
      if (this.otherRootCausesExistInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      if (this.isOtherRootCauses(elementIndex)) {
        this.setInteractiveFilterForOther();
        return;
      }
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilterForOther() {
      if (!this.otherRootCausesExistInFilter()) {
        this.interactiveFilters.rootCauses.push(...this.otherRootCauses);
      } else {
        const filterCopy = this.interactiveFilters.rootCauses.slice();
        this.otherRootCauses.forEach((otherRootCause) => {
          const foundRootCauseIndex = filterCopy.findIndex(
            (rootCause) => otherRootCause.key === rootCause.key
          );
          if (foundRootCauseIndex >= 0) {
            filterCopy.splice(foundRootCauseIndex, 1);
          }
        });
        this.interactiveFilters.rootCauses = filterCopy.slice();
      }
      this.updateOtherBackgroundColors();
    },
    setInteractiveFilter(elementIndex) {
      const rootCauseKey = this.rootCauseKeys[elementIndex];
      const foundRootCauseIndex = this.interactiveFilters.rootCauses.findIndex(
        (rootCause) => rootCause.key === rootCauseKey
      );
      if (foundRootCauseIndex >= 0) {
        this.interactiveFilters.rootCauses.splice(foundRootCauseIndex, 1);
      } else {
        this.interactiveFilters.rootCauses.push({ key: rootCauseKey, index: elementIndex });
      }
      if (this.otherRootCausesExistInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    async getRootCauseData() {
      const machines = this.interactiveFilters.machines.map((machine) => machine.id);
      const parts = this.interactiveFilters.parts.map((part) => part.id);
      const reasons = this.interactiveFilters.reasons.map((reason) => reason.id);
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_group: this.filters.machineGroups,
        machine_ids: machines.length > 0 ? machines : this.filters.machines,
        part_numbers: parts.length > 0 ? parts : this.filters.parts,
        reason_ids: reasons.length > 0 ? reasons : this.filters.reasons
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/causes/", { params });
        if (response && response.data) {
          this.otherRootCauses = response.data.filter(
            (item) =>
              this.predefinedRootCauses.findIndex((rootCause) => rootCause.text === item.key) === -1
          );
          const data = response.data.filter(
            (item) =>
              this.predefinedRootCauses.findIndex((rootCause) => rootCause.text === item.key) >= 0
          );
          this.rootCauseKeys = data.map((rootCause) => rootCause.key);
          const colors = [
            ...Array(this.otherRootCauses.length > 0 ? data.length + 1 : data.length)
              .fill(0)
              .map(() => hexToRGB(peachPuff, "0.9"))
          ];
          this.rootCausesBarChartData = {
            labels: data.map((rootCause) => rootCause.key),
            datasets: [
              {
                data: data.map((item) => item.doc_count),
                label: this.$t("Number of Issues"),
                backgroundColor: colors
              }
            ]
          };
          if (this.otherRootCauses.length > 0) {
            let otherIssueTotal = 0;
            this.otherRootCauses.forEach((rootCause) => (otherIssueTotal += rootCause.doc_count));
            this.rootCausesBarChartData.labels.push(this.$t("Other"));
            this.rootCausesBarChartData.datasets[0].data.push(otherIssueTotal);
          }
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
<style lang="scss"></style>
