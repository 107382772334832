var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"production-report-data-table"},[_c('div',{staticClass:"card",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"data-table-card card-content"},[_c('GenericTable',{attrs:{"columns":[
          { title: '', key: 'machine_name' },
          {
            title: _vm.$t('Total Duration'),
            key: 'total_duration',
            format: _vm.seconds,
            sortable: true
          },
          {
            title: _vm.$t('Total Downtime'),
            key: 'downtime_duration',
            format: _vm.seconds,
            sortable: true
          },
          { title: _vm.$t('Runtime'), key: 'runtime', format: _vm.seconds },
          {
            title: _vm.$t('Asset Utilization'),
            key: 'asset_utilization',
            format: _vm.percent
          },
          {
            title: _vm.$t('Produced'),
            key: 'produced',
            format: _vm.toLocaleStringFormat,
            alignRight: true
          },
          {
            title: _vm.$t('Production Target'),
            key: 'production_target',
            format: _vm.toLocaleStringFormat,
            alignRight: true
          },
          { title: _vm.$t('Performance'), key: 'performance', format: _vm.percent },
          {
            title: _vm.$t('Scrapped'),
            key: 'scrapped',
            format: _vm.toLocaleStringFormat,
            alignRight: true
          },
          { title: _vm.$t('Scrap Threshold'), key: 'scrap_threshold' },
          { title: _vm.$t('Quality %'), key: 'quality', format: _vm.percent },
          { title: _vm.$t('OEE'), key: 'oee', format: _vm.percent, sortable: true }
        ],"nested_columns":[
          { key: 'date', format: _vm.date },
          { key: 'duration', title: _vm.$t('Duration'), format: _vm.seconds },
          {
            key: 'downtime_duration',
            title: _vm.$t('Downtime Duration'),
            format: _vm.seconds
          },
          { key: 'runtime', title: _vm.$t('Runtime'), format: _vm.seconds },
          {
            key: 'asset_utilization',
            title: _vm.$t('Asset Utilization'),
            format: _vm.percent
          },
          {
            key: 'produced',
            title: _vm.$t('Produced'),
            format: _vm.toLocaleStringFormat,
            class: 'numeric-cell',
            style: { display: null }
          },
          {
            key: 'production_target',
            title: _vm.$t('Production Target'),
            format: _vm.toLocaleStringFormat
          },
          { key: 'performance', title: _vm.$t('Performance'), format: _vm.percent },
          {
            key: 'scrapped',
            title: _vm.$t('Scrapped'),
            format: _vm.toLocaleStringFormat
          },
          { key: 'scrap_threshold', title: _vm.$t('Scrap Threshold') },
          { key: 'quality', title: _vm.$t('Quality %'), format: _vm.percent },
          { key: 'oee', title: _vm.$t('OEE'), format: _vm.percent }
        ],"data":_vm.tableData}}),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('v-btn',{staticClass:"ma-2",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","small":""},on:{"click":_vm.downloadCsv}},[_c('v-icon',{staticStyle:{"font-size":"12px"}},[_vm._v("mdi-file-download")]),_vm._v("\n          "+_vm._s(_vm.$t("CSV"))+"\n        ")],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }