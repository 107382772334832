export const ASSET_UTILIZATION_TARGET = 100;

export const MACHINE_STATUS_CHANGE_EVENT = "MACHINE_STATUS_CHANGE_EVENT";

export const MACHINE_DELAY_BETWEEN_UPDATE_AND_GET = 500;

export const MACHINE_TABS = {
  GRID: "grid",
  CHARTS: "charts"
};
