import axios from "axios";
import ReconnectingWebSocket from "reconnecting-websocket";
import Vue from "vue";

import { env } from "../shared/env";

class Route {
  constructor(url) {
    this._url = url;
  }

  get url() {
    return this._url;
  }
}

/**
 * A route pointing to a resource list
 */
class ListRoute extends Route {
  get(params = null) {
    return Vue.http.get(this.url, {
      params
    });
  }

  post(body = null, options = null) {
    return Vue.http.post(this.url, body, options);
  }
}

/**
 * A route pointing to an individual resource
 */
class DetailRoute extends Route {
  _prepare(resource) {
    return this.url.replace("$", resource) + "/";
  }

  get(resource, params = null, options = null) {
    return Vue.http.get(this._prepare(resource), params, options);
  }

  post(resource, body = null, options = null) {
    return Vue.http.post(this._prepare(resource), body, options);
  }
}

/**
 * An api resource route and Vue plugin definition
 */

class Resource {
  static install(Vue, options) {
    Vue.http = axios;
    Vue.prototype.$http = axios;

    // configure http to handle jwt and our api requests
    axios.defaults.baseURL = env.apiBaseUrl;

    if (localStorage.getItem("jwt")) {
      axios.defaults.headers["Authorization"] = "JWT " + localStorage.getItem("jwt");
    }

    // add simple websocket configuration
    Vue.prototype.$socket = function (url, msgHandler) {
      let ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
      let socket = new ReconnectingWebSocket(
        // `${ws_scheme}://${window.location.host}/api/socket/${url}`,
        `${ws_scheme}://${env.wsBaseUrl}/socket/${url}`,
        [],
        {
          maxRetries: 3
          // debug: true
        }
      );
      socket.addEventListener("message", msgHandler);
      return socket;
    };

    // Asynchronous emit configuration
    Vue.prototype.$emitAsync = async function (method, ...params) {
      let listener = this.$listeners[method] || this.$attrs[method] || this[method];
      if (listener) {
        let res = await listener(...params);
        return res === undefined || res;
      }
      return false;
    };

    Vue.prototype.$resource = options;
    Vue.resources = options;
  }

  constructor(route) {
    this._route = route;
    this.list = null;
    this.detail = null;

    // eslint-disable-next-line no-prototype-builtins
    if (route.hasOwnProperty("detail")) {
      this.detail = new DetailRoute(route.detail);
    }
    // eslint-disable-next-line no-prototype-builtins
    if (route.hasOwnProperty("list")) {
      this.list = new ListRoute(route.list);
    }
  }
}

export default Resource;
