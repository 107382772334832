var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"solution-dialog"},[_c('ModalDialog',{staticClass:"cause-dialog",attrs:{"persistent":"","content-class":"issue-entry-dialog cause","max-width":"576px"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("Long Term Solution For"))+" "+_vm._s(_vm.timeDisplay)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',[(_vm.selectedCause || _vm.selectedReason)?_c('div',{staticClass:"selections mb-4",class:{
            bordered: _vm.selectedCause || _vm.selectedReason
          }},[(_vm.selectedReason)?_c('div',{staticClass:"selection mt-4 pb-2",on:{"click":function($event){_vm.selectedReason = false}}},[_c('p',{staticClass:"selection-title text-subtitle-2 font-weight-regular color--text-secondary mr-1"},[_vm._v("\n              "+_vm._s(_vm.$t("Reason"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"reason"},[_c('v-btn',{staticClass:"reason-item color--text-primary text-subtitle-1",on:{"click":function () {
                    _vm.selectedReason = false;
                    _vm.selectedCause = false;
                    _vm.selectedSolution = null;
                  }}},[_vm._v("\n                "+_vm._s(_vm.selectedReason.reason)+"\n              ")])],1)]):_vm._e(),_vm._v(" "),(_vm.selectedCause)?_c('div',{staticClass:"selection mt-4 pb-2",on:{"click":function($event){_vm.selectedCause = false}}},[_c('p',{staticClass:"selection-title text-subtitle-2 font-weight-regular color--text-secondary mr-1"},[_vm._v("\n              "+_vm._s(_vm.$t("Root Cause"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"reason"},[(_vm.selectedCause)?_c('v-btn',{staticClass:"reason-item color--text-primary text-subtitle-1",on:{"click":function () {
                    _vm.selectedReason = false;
                    _vm.selectedCause = false;
                  }}},[_vm._v("\n                "+_vm._s(_vm.selectedCause)+"\n              ")]):_vm._e()],1)]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',[(!_vm.selectedReason)?_c('div',_vm._l((_vm.filteredIssues),function(issue){return _c('div',{key:issue.id,staticClass:"reason"},[_c('v-btn',{staticClass:"reason-item color--text-primary text-subtitle-1",class:{ selected: _vm.selectedReason.id == issue.id },on:{"click":function () {
                    _vm.selectReason(issue);
                  }}},[_vm._v("\n                "+_vm._s(issue.reason)+"\n              ")])],1)}),0):_vm._e(),_vm._v(" "),(_vm.selectedReason && _vm.causes && !_vm.selectedCause)?_c('div',_vm._l((_vm.causes),function(cause){return _c('div',{key:'cause' + cause,staticClass:"countermeasure"},[_c('v-btn',{staticClass:"reason-item color--text-primary text-subtitle-1",class:{ selected: _vm.selectedCause == cause },on:{"click":function () {
                    _vm.selectCause(cause);
                  }}},[_vm._v("\n                "+_vm._s(cause)+"\n              ")])],1)}),0):_vm._e(),_vm._v(" "),(_vm.selectedReason && _vm.selectedCause && _vm.solutionOptions)?_c('div',[_vm._l((_vm.solutionOptions),function(solution){return _c('div',{key:'solution' + solution,staticClass:"solution"},[_c('v-btn',{staticClass:"reason-item color--text-primary text-subtitle-1",class:{ selected: _vm.selectedSolution == solution },attrs:{"disabled":_vm.selectedReasonSolutions.includes(solution.node.text)},on:{"click":function () {
                    _vm.selectedSolution = solution;
                  }}},[_vm._v("\n                "+_vm._s(solution.node.text)+"\n              ")])],1)}),_vm._v(" "),(_vm.selectedCause)?_c('div',{key:"other",staticClass:"solution"},[_c('v-btn',{staticClass:"reason-item color--text-primary text-subtitle-1",class:{ selected: _vm.selectedSolution == 'other' },on:{"click":function () {
                    _vm.selectedSolution === 'other'
                      ? (_vm.selectedSolution = null)
                      : (_vm.selectedSolution = 'other');
                  }}},[_vm._v("\n                "+_vm._s(_vm.$t("Other"))+"\n              ")])],1):_vm._e()],2):_vm._e()]),_vm._v(" "),(_vm.selectedSolution == 'other')?_c('div',{staticClass:"otherText"},[_c('v-textarea',{staticClass:"mt-0 pt-5",attrs:{"label":_vm.$t('Solution'),"height":"120","filled":"","hide-details":"","clearable":""},model:{value:(_vm.otherText),callback:function ($$v) {_vm.otherText=$$v},expression:"otherText"}})],1):_vm._e()])]},proxy:true},{key:"actions",fn:function(){return [_c('Btn',{staticClass:"cause-dialog-btn",attrs:{"width":"144"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("\n        "+_vm._s(_vm.$t("Cancel"))+"\n      ")]),_vm._v(" "),_c('Btn',{staticClass:"cause-dialog-btn",attrs:{"variant":"primary","primary":"","disabled":!_vm.selectedSolution || (_vm.selectedSolution == 'other' && _vm.otherText == ''),"loading":_vm.saving,"width":"144"},on:{"click":_vm.updateIssue}},[_vm._v("\n        "+_vm._s(_vm.$t("Submit"))+"\n      ")])]},proxy:true}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }