<template>
  <section class="selected-measurements-table">
    <v-list>
      <v-subheader>{{ $t("Select scenario") }}</v-subheader>
      <v-list-group
        v-for="scenario in scenarios.results"
        :key="scenario.id"
        v-model="scenario.active"
        @click="scenarioId = scenario.id"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ scenario.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-row class="description-margin">{{ scenario.description }}</v-row>
        <v-subheader>{{ $t("Select output group") }}</v-subheader>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="outputGroup in outputGroups.results"
            :key="outputGroup.id"
            @click="outputGroupId = outputGroup.id"
          >
            <v-expansion-panel-header>
              {{ outputGroup.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-if="outputGroup.description">{{ outputGroup.description }}</v-row>
              <v-row>
                <v-data-table
                  v-if="scenarioResult"
                  :headers="headers"
                  :items="items"
                  :page.sync="tableOptions.page"
                  :items-per-page="tableOptions.itemsPerPage"
                  :loading="loading"
                  hide-default-footer
                >
                  <template #footer>
                    <div class="btn-group">
                      <v-btn
                        @click="fetchPrevResults"
                        :disabled="tableOptions.prevPages.length < 2"
                      >
                        prev
                      </v-btn>
                      <v-btn
                        @click="fetchNextResults"
                        :disabled="
                          !scenarioResult ||
                          scenarioResult.data.results.length <= tableOptions.itemsPerPage - 1
                        "
                        >{{ $t("next") }}</v-btn
                      >
                    </div>
                  </template>
                </v-data-table>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-pagination
            v-if="Math.ceil(outputGroups.count / outputGroups.totalVisible) > 1"
            v-model="outputGroups.page"
            :length="Math.ceil(outputGroups.count / outputGroups.totalVisible)"
            :total-visible="outputGroups.totalVisible"
            @input="fetchOutputGroups"
          />
        </v-expansion-panels>
      </v-list-group>
    </v-list>
    <v-pagination
      v-if="Math.ceil(scenarios.count / scenarios.totalVisible) > 1"
      v-model="scenarios.page"
      :length="Math.ceil(scenarios.count / scenarios.totalVisible)"
      :total-visible="scenarios.totalVisible"
      @input="fetchScenarios"
      color="success"
    />
  </section>
</template>

<script>
import { fetchOutputGroups, fetchScenarioResult, fetchScenarios } from "../../../shared/api";

export default {
  name: "ResultsTab",
  data() {
    return {
      loading: true,
      outputGroupId: null,
      outputGroups: {
        page: 1,
        count: 0,
        totalVisible: 10
      },
      scenarioId: null,
      scenarios: {
        page: 1,
        count: 0,
        totalVisible: 10
      },
      scenarioResult: null,
      tableOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        prevPages: [-1]
      }
    };
  },
  created() {
    this.loading = true;
    Promise.all([this.fetchOutputGroups(), this.fetchScenarios()]).then(
      () => (this.loading = false)
    );
  },
  computed: {
    headers() {
      if (this.scenarioResult && this.scenarioResult.fields) {
        return this.scenarioResult.fields.reduce((reducer, field) => {
          reducer.push({
            text: field.name,
            value: field.name,
            align: "center"
          });
          return reducer;
        }, []);
      }
      return [];
    },
    items() {
      return this.scenarioResult && this.scenarioResult.data
        ? this.scenarioResult.data.results
        : [];
    },
    optimizationModelId() {
      return this.$route.params.id;
    },
    orderingKey() {
      if (this.scenarioResult && this.scenarioResult.data) {
        return this.scenarioResult.data.orderingKey[0];
      }
      return "-1";
    }
  },
  methods: {
    fetchResults(orderingKey) {
      this.loading = true;
      fetchScenarioResult({
        client: this.$http,
        optimizationModelId: this.optimizationModelId,
        scenarioId: this.scenarioId,
        outputGroupId: this.outputGroupId,
        offset: orderingKey || this.orderingKey,
        limit: this.tableOptions.itemsPerPage
      }).then((data) => {
        this.scenarioResult = data;
        this.loading = false;
      });
    },
    fetchNextResults() {
      this.tableOptions.prevPages.push(this.orderingKey);
      this.fetchResults();
    },
    fetchPrevResults() {
      if (this.tableOptions.prevPages.length > 1) {
        this.tableOptions.prevPages.pop();
        this.fetchResults([this.tableOptions.prevPages[this.tableOptions.prevPages.length - 1]]);
      }
    },
    async fetchScenarios(page = 1) {
      const scenarios = await fetchScenarios(this.$http, this.optimizationModelId, page);
      this.scenarios = { ...this.scenarios, ...scenarios };
      this.scenarios.results.forEach((scenario) => (scenario.active = false));
    },
    async fetchOutputGroups(page = 1) {
      const scenarios = await fetchOutputGroups(this.$http, this.optimizationModelId, page);
      this.outputGroups = { ...this.outputGroups, ...scenarios };
      this.outputGroups.results.forEach((outputGroup) => (outputGroup.active = false));
    }
  },
  watch: {
    outputGroupId(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.scenarioResult = null;
        this.tableOptions.prevPages = [-1];
        this.fetchResults();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-measurements-table {
  padding: 1rem;
  margin: 1rem;
}
.description-margin {
  margin: 0.3rem 0 0.3rem 1rem;
}
.v-data-table {
  width: 100%;
}
body .home nav {
  position: initial;
}
</style>
