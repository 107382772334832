<template>
  <div class="manualTrend metric-component">
    <h2 class="card-title text-subtitle-2 color--text-primary text-uppercase mb-3">
      {{ metric.node.name }}
    </h2>

    <div class="charts">
      <div class="mb-5 text-h5 color--text-primary font-weight-medium">
        {{
          metric.node.currentValue && metric.node.currentValue.value
            ? parseFloat(metric.node.currentValue.value.replace(/\D/g, ""))
            : "0".trim(),
        }}
      </div>
      <div class="charts_container">
        <div class="chart">
          <BarChart
            :chart-name="metric.node.name"
            :chart-data="chart_data[metric.node.name]"
            :chart-options="{
              scales: {
                yAxes: [{ zeroLineColor: '#FFF' }],
                xAxes: [
                  {
                    ticks: {
                      minRotation: 90,
                      maxRotation: 90
                    }
                  }
                ]
              }
            }"
            class="dynamicChart"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import BarChart from "@/ui/charts/old/BarChart";

export default {
  components: {
    BarChart
  },
  props: {
    metric: {
      type: Object,
      default: undefined
    },
    timeScale: {
      type: Number,
      default: undefined
    },
    machine_group_id: {
      type: [String, Number],
      default: undefined
    }
  },
  data() {
    return {
      metric_ids: [],
      date_range: [],
      chart_data: {},
      formatted_chartData: {},
      numberElements: 0
    };
  },
  computed: {},
  created() {
    //todo: initialize these at different time ranges
    const start = moment();
    const end = moment();
    start.subtract(30, "days");
    this.date_range = [start, end];
    this.numberElements = end.diff(start, "days");

    const metricIds = [];
    metricIds.push(this.metric.node.id);

    this.metric_ids = metricIds;
    this.loadMetrics();
  },
  methods: {
    loadMetrics() {
      let query = `query (
          $metricIds: [ID]!,
          $machineGroupId: ID!
        ){
        metricRecords(
          metricId_In: $metricIds,
          machineGroupId: $machineGroupId
        ){
          edges{
            node{
              metric{
                name
              }
              date
              value
            }
          }
        }
      }`;

      //TODO: Add date range.  For now filter on records retrieved
      let variables = {
        metricIds: this.metric_ids,
        machineGroupId: this.machine_group_id
      };

      const data = {};
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        res.data.data.metricRecords.edges.sort(function (a, b) {
          if (a.node.date < b.node.date) {
            return -1;
          }
          if (a.node.date > b.node.date) {
            return 1;
          }
          return 0;
        });

        //insert blank elements into each chart
        const name = this.metric.node.name;
        data[name] = { data: [], label: [], color: [] };

        for (let i = 0; i <= this.numberElements; i++) {
          const d = moment(this.date_range[0]);

          data[name].data.push(0);
          data[name].label.push(d.add(i, "days").format("DD MMM"));
          data[name].color.push(this.$colors.chart.good_gradient);
        }

        //grab days since and insert it into that field
        res.data.data.metricRecords.edges.forEach((record) => {
          const date = moment(record.node.date);
          const name = record.node.metric.name;
          const value = record.node.value;

          const daysSince = date.diff(this.date_range[0], "days") + 1;
          if (daysSince <= this.numberElements) {
            if (data[name] == null) {
              data[name] = { data: [], label: [], color: [] };
            }

            data[name].data[daysSince] = value;
            data[name].label[daysSince] = date.format("DD MMM");
            data[name].color[daysSince] = this.$colors.chart.good_gradient;
          }
        });

        this.chart_data = this.formatDataForCharts(data);
      });
    },
    formatDataForCharts(data) {
      const keys = Object.keys(data);
      const formattedData = {};

      for (const key of keys) {
        const d = {
          datasets: [
            {
              data: data[key].data,
              gradients: data[key].color,
              titles: "",
              footers: "",
              label: key
            }
          ],
          labels: data[key].label
        };

        formattedData[key] = d;
      }

      return formattedData;
    },
    gaugeValue(value) {
      if (value == null) {
        return "gv";
      } else if (value.value.indexOf("%") >= 0) {
        return "gv percent";
      } else if (value.value.indexOf("$") >= 0) {
        return "gv dollar";
      } else {
        return "gv";
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/scss/variables";

.manualTrend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 18vh;
  flex: 1;
  justify-self: normal;
  align-self: normal;

  .charts {
    display: block;
  }
}

.gv {
  position: absolute;
  font-size: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.percent {
    &:first-child::after {
      content: "%" !important;
    }
  }

  &.dollar {
    &:first-child::before {
      content: "$" !important;
    }
  }
}

.charts_container {
  position: static;
  display: block;
  padding: 0;

  .chart {
    overflow: auto;
    margin: 0;
    position: relative;
  }

  .dynamicChart {
    height: 200px;
    width: 100%;
    position: absolute;
    top: 0;
    left: -4px;
    right: 0;
    bottom: 0;
  }

  .qualityChart {
    height: 200px !important;
  }
}
</style>
