<template>
  <div class="rejectTrendChart">
    <h4 class="rejectTrendTitle">{{ machine_name }}</h4>
    <div
      ref="barchart"
      class="chart"
    ></div>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { mapGetters } from "vuex";

import dbCache from "@/store/modules/dbCache";
import { getDateDisplay } from "@/utils/datetime";

export default {
  name: "AiVisionRejectTrendSummaryChart",
  props: {
    data: Array,
    machine: Object,
    scale: String
  },
  mounted() {
    this.createChart();
    this.createTitle();
  },
  data() {
    return {
      root: null,
      chart: null,
      machine_name: "--"
    };
  },
  computed: {
    ...mapGetters({
      machineFromPk: "dbCache/machineFromPk",
      theme: "app/Theme"
    })
  },
  methods: {
    getDateDisplay,
    createTitle() {
      if (this.machine && this.machine.buckets) {
        if (this.machine.buckets.length > 1) {
          this.machine_name = `${this.machineFromPk(Number(this.machine.buckets[0].key)).name}
              (+${this.machine.buckets.length - 1} ${this.$t("More")}) ${this.$t("Reject Summary Trend")}`;
        } else {
          this.machine_name = `${this.machineFromPk(Number(this.machine.buckets[0].key)).name}
              ${this.$t("Reject Summary Trend")}`;
        }
      }
    },
    createChart() {
      if (this.root) {
        this.root.dispose();
      }

      //create the axis
      this.root = am5.Root.new(this.$refs.barchart);
      this.chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          layout: this.root.verticalLayout,
          maxTooltipDistance: 0
        })
      );
      this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}));

      if (this.theme === "dark") {
        this.root.interfaceColors.set("text", am5.color(0xffffff));
      }

      const xRenderer = am5xy.AxisRendererX.new(this.root, {
        minGridDistance: 0
      });

      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.percent(50),
        fontSize: 11
      });

      xRenderer.grid.template.set("strokeOpacity", 0);

      const xAxis = this.chart.xAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          categoryField: "time",
          oversizedBehavior: "truncate",
          renderer: xRenderer
        })
      );

      const yAxisPercentRenderer = am5xy.AxisRendererY.new(this.root, {});
      yAxisPercentRenderer.labels.template.set("fontSize", 11);
      yAxisPercentRenderer.grid.template.set("strokeOpacity", 0);

      const yAxisPercent = this.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          align: "right",
          numberFormat: "#'%'",
          renderer: yAxisPercentRenderer
        })
      );

      const scales = {
        "1h": "hour",
        "1d": "day",
        "1w": "week",
        "1M": "month",
        "1y": "year"
      };
      let scale = scales[this.scale];

      const failureModeKeys = [];
      const failureModes = {};
      const hours = [];

      for (const trend of this.data) {
        let time = this.getDateDisplay(trend.key_as_string, scale);
        hours.push(time);
      }

      let minPercent = 0;
      let maxPercent = 0;
      for (const trend of this.data) {
        let total = 0;
        let time = this.getDateDisplay(trend.key_as_string, scale);

        for (const inspection_result of trend.inspection_result.buckets) {
          //total records for segment
          total += inspection_result.count.value;
        }

        //Now we need to grab each row
        for (const inspection_result of trend.inspection_result.buckets) {
          if (inspection_result.key === "FAIL") {
            for (const failure_mode of inspection_result.failure_mode.buckets.sort((a, b) =>
              a.key.localeCompare(b.key)
            )) {
              const failure_mode_name = failure_mode.key;

              if (!failureModes[failure_mode_name]) {
                failureModeKeys.push(failure_mode_name);
                failureModes[failure_mode_name] = {
                  name: failure_mode_name,
                  trendMap: {},
                  trend: []
                };
              }
              const count = failure_mode.count.value;
              const percent = total > 0 ? ((count / total) * 100).toFixed(2) : 0;

              //keep your min and max to draw axis
              minPercent = Math.min(minPercent, percent);
              maxPercent = Math.max(maxPercent, percent);
              failureModes[failure_mode_name].trendMap[time] = {
                time: time,
                count: count,
                total: total,
                percent: percent
              };
            }
          }
        }
      }

      for (const failure of failureModeKeys) {
        const d = failureModes[failure];

        for (const hour of hours) {
          if (d.trendMap[hour]) {
            d.trend.push(d.trendMap[hour]);
          } else {
            d.trend.push({
              time: hour,
              count: 0,
              total: 0,
              percent: 0
            });
          }
        }
      }

      for (const failure of failureModeKeys) {
        const d = failureModes[failure];
        xAxis.data.setAll(d.trend);

        const seriesPercent = this.chart.series.push(
          am5xy.LineSeries.new(this.root, {
            name: `[fontSize: 12px]${d.name}[/]`,
            xAxis: xAxis,
            yAxis: yAxisPercent,
            valueYField: "percent",
            categoryXField: "time",
            cursorTooltipEnabled: true,
            seriesTooltipTarget: "bullet",
            tooltip: am5.Tooltip.new(this.root, {
              labelText: `${d.name}: {valueY}%`,
              keepTargetHover: true
            })
          })
        );

        seriesPercent.bullets.push(() => {
          return am5.Bullet.new(this.root, {
            locationY: 0,
            sprite: am5.Circle.new(this.root, {
              radius: 2,
              stroke: seriesPercent.get("fill"),
              strokeWidth: 1,
              fill: seriesPercent.get("fill")
            })
          });
        });

        seriesPercent.events.on("datavalidated", function () {
          yAxisPercent.setAll({
            min: minPercent,
            max: maxPercent,
            start: 0,
            end: 1
          });
        });
        seriesPercent.data.setAll(d.trend);
      }

      const legend = this.chart.children.push(
        am5.Legend.new(this.root, {
          centerX: am5.percent(50),
          x: am5.percent(50)
        })
      );
      legend.data.setAll(this.chart.series.values);
    }
  },
  watch: {
    data: function () {
      this.createChart();
    },
    machine: function () {
      this.createTitle();
    }
  }
};
</script>

<style scoped lang="scss">
.rejectTrendChart {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;

  .rejectTrendTitle {
    flex: unset;
    padding: 10px;
  }

  .chart {
    flex: 1;
    min-height: 300px;
  }
}
</style>
