<template>
  <div class="realtime_cards">
    <v-btn
      fab
      x-small
      light
      @click="showSearch = !showSearch"
      class="hideDesktop"
    >
      <i
        class="fa fa-filter"
        v-if="!showSearch"
      ></i>
      <i
        class="fa fa-times"
        v-if="showSearch"
      ></i>
    </v-btn>
    <div
      class="searchbars"
      v-if="vw > 1200 || showSearch"
    >
      <v-autocomplete
        :placeholder="t('Zone')"
        :items="Array.from(zoneSet)"
        @change="
          (e) => {
            setFilter('zone', e);
          }
        "
        :value="filters.zone"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('zone', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.zone != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Machine')"
        :items="Array.from(machineSet)"
        @change="
          (e) => {
            setFilter('machine', e);
          }
        "
        :value="filters.machine"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('machine', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.machine != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Part')"
        :items="Array.from(partSet)"
        @change="
          (e) => {
            setFilter('part', e);
          }
        "
        :value="filters.part"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('part', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.part != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Operator')"
        :items="Array.from(operatorSet)"
        @change="
          (e) => {
            setFilter('name', e);
          }
        "
        :value="filters.name"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('name', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.name != null"
        />
      </div>
      <v-autocomplete
        :placeholder="$t('Operator Status')"
        :items="Array.from(operatorStatusSet)"
        @change="
          (e) => {
            setFilter('checked_in', e == null ? null : e == 'Checked-In');
          }
        "
        :value="
          filters.checked_in == null ? null : filters.checked_in ? 'Checked-In' : 'Checked-Out'
        "
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true
        }"
      />
      <div
        class="clearbutton"
        @click="
          () => {
            setFilter('checked_in', null);
          }
        "
      >
        <i
          class="fa fa-remove"
          v-if="filters.checked_in != null"
        />
      </div>
    </div>
    <div class="grid">
      <realtime-card
        v-for="(item, idx) in filteredItems"
        :key="idx"
        class="item"
        :item="item"
      />
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
.realtime_cards {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  .v-btn {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    i {
      font-size: 1rem;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    justify-content: space-evenly;
    flex: 1;
    overflow: auto;
    padding-bottom: 50px;
    @media screen and (max-width: 620px) {
      padding: 0.5rem;
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }
  .searchbars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    height: 60px;
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      background-color: $cardColor;
      box-shadow: $cardBoxShadow;
      height: unset;
      margin: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      position: absolute;
      top: 3rem;
      left: 1rem;
      right: 1rem;
      z-index: 99;
      .v-input {
        width: 100%;
      }
    }
    .clearbutton {
      width: 35px;
      flex: unset;
      font-weight: lighter;
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";

import RealtimeCard from "./realtimeCard";

export default {
  components: { RealtimeCard },
  props: ["items"],
  data() {
    return {
      filteredItems: [],
      zoneSet: new Set(),
      machineSet: new Set(),
      partSet: new Set(),
      operatorSet: new Set(),
      operatorStatusSet: new Set(),
      filters: {},
      showSearch: false
    };
  },
  created() {
    this.createDropdownElements();
    this.filterItems();
  },
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  methods: {
    setFilter(filter, e) {
      if (e != null) {
        this.filters[filter] = e;
      } else {
        delete this.filters[filter];
      }

      this.filterItems();
    },
    filterItems() {
      const keys = Object.keys(this.filters);
      const filteredOut = new Set();
      this.items.forEach((item) => {
        for (const k of keys) {
          if (item[k] != this.filters[k]) {
            filteredOut.add(item);
          }
        }
      });

      this.filteredItems = this.items.filter((item) => {
        return !filteredOut.has(item);
      });
    },
    createDropdownElements() {
      if (this.items == null) {
        return;
      }

      for (const item of this.items) {
        this.zoneSet.add(item.zone);
        this.machineSet.add(item.machine);
        this.partSet.add(item.part);
        this.operatorSet.add(item.name);
        this.operatorStatusSet.add(item.checked_in ? "Checked-In" : "Checked-Out");
      }
    }
  },
  watch: {
    items: function () {
      this.createDropdownElements();
      this.filterItems();
    }
  }
};
</script>
