<template>
  <v-text-field
    v-model="inputValue"
    class="input"
    color="default"
    prepend-inner-icon="mdi-magnify"
    :placeholder="$t('Search')"
    single-line
    hide-details
    clearable
  ></v-text-field>
</template>

<script>
export default {
  name: "HelpCenterSearchInput",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
