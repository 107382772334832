<template>
  <div class="pinpad">
    <div class="pinpad_text">
      <div class="text">
        <span
          v-for="(p, idx) in pin"
          :key="idx"
        >
          *
        </span>
      </div>
    </div>
    <div class="pinpad_row">
      <div
        class="pinbutton"
        @click="pin += '1'"
      >
        1
      </div>
      <div
        class="pinbutton"
        @click="pin += '2'"
      >
        2
      </div>
      <div
        class="pinbutton"
        @click="pin += '3'"
      >
        3
      </div>
    </div>
    <div class="pinpad_row">
      <div
        class="pinbutton"
        @click="pin += '4'"
      >
        4
      </div>
      <div
        class="pinbutton"
        @click="pin += '5'"
      >
        5
      </div>
      <div
        class="pinbutton"
        @click="pin += '6'"
      >
        6
      </div>
    </div>
    <div class="pinpad_row">
      <div
        class="pinbutton"
        @click="pin += '7'"
      >
        7
      </div>
      <div
        class="pinbutton"
        @click="pin += '8'"
      >
        8
      </div>
      <div
        class="pinbutton"
        @click="pin += '9'"
      >
        9
      </div>
    </div>
    <div class="pinpad_row">
      <div
        class="pinbutton"
        @click="backspace"
      >
        <i class="fa fa-backward" />
      </div>
      <div
        class="pinbutton"
        @click="pin += '0'"
      >
        0
      </div>
      <div
        class="pinbutton"
        @click="pin = ''"
      >
        <i class="fa fa-ban" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../scss/variables";
.theme--light {
  .pinpad {
    .pinbutton {
      color: $light-white;
    }
  }
}
.pinpad {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;

  .pinbutton {
    margin: 5px;
    border: #7e7e7e solid 1px;
    align-items: center;
    color: inherit;
    display: flex;
    flex: 1 0 auto;
    justify-content: inherit;
    line-height: normal;
    position: relative;
    transition: inherit;
    transition-property: opacity;
    background: #343434;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 3px;
    cursor: pointer;
    max-width: 65px;
  }

  .v-btn {
    margin: 5px;
    border: #7e7e7e solid 1px;
  }

  .pinpad_row {
    display: flex;
    flex-flow: row;
    justify-content: center;
  }

  .pinpad_text {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;

    .text {
      border: #7e7e7e solid 1px;
      flex: unset;
      min-width: 9em;
      margin: 0 15px;
      overflow: hidden;
      height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<script>
export default {
  props: ["pincallback"],
  components: {},
  data() {
    return {
      pin: ""
    };
  },
  created() {},
  watch: {
    pin: function () {
      if (this.pincallback) {
        this.pincallback(this.pin);
      }
    }
  },
  methods: {
    backspace() {
      this.pin = this.pin.substring(0, this.pin.length - 1);
    }
  }
};
</script>
