<template>
  <div class="solution-dialog">
    <ModalDialog
      v-model="showDialog"
      persistent
      class="cause-dialog"
      content-class="issue-entry-dialog cause"
      max-width="576px"
    >
      <template #title> {{ $t("Long Term Solution For") }} {{ timeDisplay }} </template>

      <template #body>
        <div>
          <div
            v-if="selectedCause || selectedReason"
            class="selections mb-4"
            :class="{
              bordered: selectedCause || selectedReason
            }"
          >
            <div
              v-if="selectedReason"
              class="selection mt-4 pb-2"
              @click="selectedReason = false"
            >
              <p
                class="selection-title text-subtitle-2 font-weight-regular color--text-secondary mr-1"
              >
                {{ $t("Reason") }}
              </p>
              <div class="reason">
                <v-btn
                  class="reason-item color--text-primary text-subtitle-1"
                  @click="
                    () => {
                      selectedReason = false;
                      selectedCause = false;
                      selectedSolution = null;
                    }
                  "
                >
                  {{ selectedReason.reason }}
                </v-btn>
              </div>
            </div>
            <div
              v-if="selectedCause"
              class="selection mt-4 pb-2"
              @click="selectedCause = false"
            >
              <p
                class="selection-title text-subtitle-2 font-weight-regular color--text-secondary mr-1"
              >
                {{ $t("Root Cause") }}
              </p>
              <div class="reason">
                <v-btn
                  v-if="selectedCause"
                  class="reason-item color--text-primary text-subtitle-1"
                  @click="
                    () => {
                      selectedReason = false;
                      selectedCause = false;
                    }
                  "
                >
                  {{ selectedCause }}
                </v-btn>
              </div>
            </div>
          </div>
          <div>
            <div v-if="!selectedReason">
              <div
                v-for="issue in filteredIssues"
                :key="issue.id"
                class="reason"
              >
                <v-btn
                  :class="{ selected: selectedReason.id == issue.id }"
                  class="reason-item color--text-primary text-subtitle-1"
                  @click="
                    () => {
                      selectReason(issue);
                    }
                  "
                >
                  {{ issue.reason }}
                </v-btn>
              </div>
            </div>
            <div v-if="selectedReason && causes && !selectedCause">
              <div
                v-for="cause in causes"
                :key="'cause' + cause"
                class="countermeasure"
              >
                <v-btn
                  :class="{ selected: selectedCause == cause }"
                  class="reason-item color--text-primary text-subtitle-1"
                  @click="
                    () => {
                      selectCause(cause);
                    }
                  "
                >
                  {{ cause }}
                </v-btn>
              </div>
            </div>
            <div v-if="selectedReason && selectedCause && solutionOptions">
              <div
                v-for="solution in solutionOptions"
                :key="'solution' + solution"
                class="solution"
              >
                <v-btn
                  :disabled="selectedReasonSolutions.includes(solution.node.text)"
                  :class="{ selected: selectedSolution == solution }"
                  class="reason-item color--text-primary text-subtitle-1"
                  @click="
                    () => {
                      selectedSolution = solution;
                    }
                  "
                >
                  {{ solution.node.text }}
                </v-btn>
              </div>
              <div
                v-if="selectedCause"
                key="other"
                class="solution"
              >
                <v-btn
                  :class="{ selected: selectedSolution == 'other' }"
                  class="reason-item color--text-primary text-subtitle-1"
                  @click="
                    () => {
                      selectedSolution === 'other'
                        ? (selectedSolution = null)
                        : (selectedSolution = 'other');
                    }
                  "
                >
                  {{ $t("Other") }}
                </v-btn>
              </div>
            </div>
          </div>
          <div
            v-if="selectedSolution == 'other'"
            class="otherText"
          >
            <v-textarea
              v-model="otherText"
              :label="$t('Solution')"
              class="mt-0 pt-5"
              height="120"
              filled
              hide-details
              clearable
            ></v-textarea>
          </div>
        </div>
      </template>

      <template #actions>
        <Btn
          class="cause-dialog-btn"
          width="144"
          @click="showDialog = false"
        >
          {{ $t("Cancel") }}
        </Btn>
        <Btn
          variant="primary"
          primary
          class="cause-dialog-btn"
          :disabled="!selectedSolution || (selectedSolution == 'other' && otherText == '')"
          :loading="saving"
          width="144"
          @click="updateIssue"
        >
          {{ $t("Submit") }}
        </Btn>
      </template>
    </ModalDialog>
  </div>
</template>
<script>
import moment from "moment";

import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";

/* TODO: https://intelycx.atlassian.net/browse/MIM-4421
 *  This is a copy-paste component. It is necessary to bring about one type with MachineHourSolution
 * */
export default {
  components: { ModalDialog, Btn },
  // eslint-disable-next-line vue/require-prop-types
  props: ["issues", "time", "machine_id"],
  data() {
    return {
      showDialog: true,
      reasons: {},
      causes: [],
      solutionOptions: [],
      selectedReason: false,
      selectedCause: false,
      selectedSolution: false,
      saving: false,
      otherText: ""
    };
  },
  computed: {
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    filteredIssues() {
      let issues = [];
      if (this.issues) {
        this.issues.forEach((issue) => {
          if (issue.cause) {
            issues.push(issue);
          }
        });
      }
      return issues;
    },
    selectedReasonSolutions() {
      if (this.selectedReason.solution) {
        return this.selectedReason.solution
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    solutions() {
      let solutions = [];
      if (this.issues) {
        this.issues.forEach((issue) => {
          if (issue.solution) {
            let issueSolution = issue.solution
              .replaceAll("[", "")
              .replaceAll("]", "")
              .replaceAll("'", "")
              .split(",");
            if (issueSolution.length > 0) {
              solutions = solutions.concat(issueSolution);
            }
          }
        });
      }

      return solutions;
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.$emit("close");
      }
    }
  },
  methods: {
    moment,
    selectReason(issue) {
      this.selectedReason = issue;
      this.causes = issue.cause;
    },
    selectCause(cause) {
      this.selectedCause = cause;
      this.solutionOptions = [];
      const query = `query($cause_Text: String, $machine: [ID]!){
        issueSolutions(cause_Text: $cause_Text, machine:$machine){
          edges{
            node{
              id
              text
            }
          }
        }
      }`;
      const variables = {
        cause_Text: cause,
        machine: [this.machine_id]
      };
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.solutionOptions = res.data.data.issueSolutions.edges.sort((a, b) => {
            if (a.node.text < b.node.text) {
              return -1;
            }
            if (a.node.text > b.node.text) {
              return 1;
            }
            return 0;
          });
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    updateIssue() {
      this.saving = true;
      const query = `mutation ($id: ID!, $issueInput: IssueInput!){
        updateIssue(globalId:$id, issueData: $issueInput) {
          issue{
            id,
            title,
            openedDate,
            closedDate,
            issueStartDate,
            issueEndDate,
            machineId,
            machineName,
            issueTypeId,
            issueTypeName,
            reason,
            countermeasures,
            cause,
            solution,
          }
        }
      }`;
      let variables = {
        id: this.selectedReason.id,
        issueInput: {}
      };
      if (this.selectedSolution == "other" && this.otherText) {
        variables["issueInput"]["solution"] = this.otherText;
      } else {
        variables["issueInput"]["solution"] = this.selectedSolution.node.text;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.$message("ALERT", { text: this.$t("Solution Submitted"), type: "success" });
          this.showDialog = false;
          this.selectedReason = false;
          this.selectedCause = false;
          this.causes = [];
          this.saving = false;
          this.$emit("UpdatedSolution", res.data.data.updateIssue.issue);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import "~@/scss/dropdown";
@import "~@/ui/Reason/reason";

.issues {
  overflow: visible;
  width: auto;
  min-width: 250px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 0.5rem;
  z-index: 9999;
}

.dialogBtn {
  position: relative;
}

.v-badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ::v-deep {
    .v-badge__wrapper {
      position: static;
      width: auto;

      span {
        position: static;
        inset: 0;
        background-color: var(--btn-secondary) !important;
        color: var(--text-primary) !important;
        padding-left: 8px;
        padding-right: 8px;
        margin-left: 4px;
      }
    }
  }
}

.solution ~ .solution,
.countermeasure ~ .countermeasure,
.reason ~ .reason {
  margin-top: 16px;
}

.selections {
  border: none;
}

.selection {
  border-bottom: 1px solid var(--border-primary);
}

.v-dialog .selections .selection .reason {
  width: calc(100% - 75px - 75px);

  button {
    border: none !important;
  }
}

.selection-title {
  width: 75px;
}

.close-button {
  top: 13px;
  right: 13px;
}

::v-deep {
  .issue-entry-dialog {
    width: 576px;
    height: 500px;
  }
}

.dialog-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-secondary);
}

.dialog-card-body {
  height: calc(100% - 69px);
  overflow-y: auto;
  padding-top: 12px;
}

.dialog-card-actions {
  margin-top: auto;
}

.dialog-title {
  padding: 18px 48px 18px 24px !important;
  border-bottom: 1px solid var(--border-secondary);
}
</style>
