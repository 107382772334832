<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        class="primary add-tag-btn"
        height="64px"
        width="64px"
        v-bind="attrs"
        v-on="on"
        @click="onClick"
        :disabled="disabled"
      >
        <v-icon size="32">mdi-plus</v-icon>
      </v-btn>
    </template>
    <span>{{ $t("Add new tag") }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    onClick: Function,
    disabled: Boolean
  }
};
</script>
<style></style>
