import { AppRoutes } from "@/shared/app-routes";
import Vue from "vue";

export default [
  {
    name: AppRoutes.irisIngestion.name,
    path: AppRoutes.irisIngestion.url,
    component: Vue.component(AppRoutes.irisIngestion.name, () => import("./IrisIngestion.vue")),
    props: false,
    displayName: false,
    meta: AppRoutes.irisIngestion.meta,
    redirect: { name: AppRoutes.irisIngestionKnowledgePortalViewBase.name },
    children: [
      {
        name: AppRoutes.irisIngestionKnowledgePortalViewBase.name,
        path: AppRoutes.irisIngestionKnowledgePortalViewBase.url,
        component: Vue.component(
          AppRoutes.irisIngestionKnowledgePortalViewBase.name,
          () => import("./components/IrisIngestionKnowledgePortalViewBase.vue")
        ),
        props: false,
        displayName: false,
        meta: AppRoutes.irisIngestionKnowledgePortalViewBase.meta,
        redirect: { name: AppRoutes.irisIngestionFiles.name },
        children: [
          {
            name: AppRoutes.irisIngestionFiles.name,
            path: AppRoutes.irisIngestionFiles.url,
            component: Vue.component(
              AppRoutes.irisIngestionFiles.name,
              () => import("./components/files/IrisIngestionFiles.vue")
            ),
            props: false,
            displayName: false,
            meta: AppRoutes.irisIngestionFiles.meta
          },
          {
            name: AppRoutes.irisIngestionTypeKnowledge.name,
            path: AppRoutes.irisIngestionTypeKnowledge.url,
            component: Vue.component(
              AppRoutes.irisIngestionTypeKnowledge.name,
              () => import("./components/type-knowledge/IrisIngestionTypeKnowledge.vue")
            ),
            props: false,
            displayName: false,
            meta: AppRoutes.irisIngestionTypeKnowledge.meta
          }
        ]
      },
      {
        name: AppRoutes.irisIngestionKnowledgePortalUploadBase.name,
        path: AppRoutes.irisIngestionKnowledgePortalUploadBase.url,
        component: Vue.component(
          AppRoutes.irisIngestionKnowledgePortalUploadBase.name,
          () => import("./components/IrisIngestionKnowledgePortalUploadBase.vue")
        ),
        props: false,
        displayName: false,
        meta: AppRoutes.irisIngestionKnowledgePortalUploadBase.meta,
        redirect: { name: AppRoutes.irisIngestionUploadDropzone.name },
        children: [
          {
            name: AppRoutes.irisIngestionKnowledgePortalUploadFiltersLayout.name,
            path: AppRoutes.irisIngestionKnowledgePortalUploadFiltersLayout.url,
            component: Vue.component(
              AppRoutes.irisIngestionKnowledgePortalUploadFiltersLayout.name,
              () => import("./components/IrisIngestionKnowledgePortalUploadFiltersLayout.vue")
            ),
            props: false,
            displayName: false,
            meta: AppRoutes.irisIngestionKnowledgePortalUploadFiltersLayout.meta,
            children: [
              {
                name: AppRoutes.irisIngestionUploadDropzone.name,
                path: AppRoutes.irisIngestionUploadDropzone.url,
                component: Vue.component(
                  AppRoutes.irisIngestionUploadDropzone.name,
                  () => import("./components/upload/IrisIngestionUploadDropzone.vue")
                ),
                props: false,
                displayName: false,
                meta: AppRoutes.irisIngestionUploadDropzone.meta
              },
              {
                name: AppRoutes.irisIngestionUploadPreview.name,
                path: AppRoutes.irisIngestionUploadPreview.url,
                component: Vue.component(
                  AppRoutes.irisIngestionUploadPreview.name,
                  () => import("./components/upload/IrisIngestionUploadPreview.vue")
                ),
                props: false,
                displayName: false,
                meta: AppRoutes.irisIngestionUploadPreview.meta
              },
              {
                name: AppRoutes.irisIngestionTypeKnowledgeEdit.name,
                path: AppRoutes.irisIngestionTypeKnowledgeEdit.url,
                component: Vue.component(
                  AppRoutes.irisIngestionTypeKnowledgeEdit.name,
                  () => import("./components/type-knowledge/IrisIngestionTypeKnowledgeEdit.vue")
                ),
                props: false,
                displayName: false,
                meta: AppRoutes.irisIngestionTypeKnowledgeEdit.meta
              }
            ]
          },
          {
            name: AppRoutes.irisIngestionTypeKnowledgeReview.name,
            path: AppRoutes.irisIngestionTypeKnowledgeReview.url(),
            component: Vue.component(
              AppRoutes.irisIngestionTypeKnowledgeReview.name,
              () => import("./components/type-knowledge/IrisIngestionTypeKnowledgeReview.vue")
            ),
            props: true,
            displayName: false,
            meta: AppRoutes.irisIngestionTypeKnowledgeReview.meta
          }
        ]
      }
    ]
  }
];
