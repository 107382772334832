<template>
  <v-form
    ref="form"
    class="tag-form"
    @submit.prevent="handleSubmit"
    v-if="tag"
  >
    <h3 class="mb-6">{{ $t("Title and description") }}</h3>
    <v-text-field
      v-model="label"
      :label="$t('Label')"
      outlined
      :rules="[rules.required]"
    />
    <v-textarea
      v-model="description"
      :label="$t('Description')"
      outlined
      :rules="[rules.required]"
    />
    <v-autocomplete
      v-model="machine"
      :items="machines"
      item-text="name"
      item-value="id"
      :label="$t('Select machine')"
      :rules="[rules.required]"
      outlined
      clearable
    />
    <div class="tag-form-actions">
      <v-btn @click="handleCancel">{{ $t("Cancel") }}</v-btn>
      <v-btn
        type="submit"
        class="primary"
        >{{ $t("Save") }}</v-btn
      >
    </div>
    <div class="mt-4">
      <p
        v-if="errors && errors.length"
        v-for="error in errors"
        :key="error"
        class="error--text"
      >
        {{ error }}
      </p>
    </div>
  </v-form>
</template>
<script>
import { getMachines } from "../api";

export default {
  props: {
    tag: null | Object,
    onSubmit: Function,
    onCancel: Function,
    errors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      machines: {},
      label: "",
      description: "",
      machine: null,
      rules: {
        required: (value) => !!value || "Required."
      }
    };
  },
  async created() {
    this.machines = await getMachines(this.$http);
  },
  mounted() {
    if (this.$refs.form) this.$refs.form.reset();
  },
  methods: {
    async handleCancel() {
      this.$refs.form.reset();
      await this.onCancel();
    },
    async handleSubmit() {
      if (!this.$refs.form.validate()) return;
      const machine = this.machines.find((_machine) => _machine.id === this.machine);
      await this.onSubmit({
        label: this.label,
        description: `[Machine ${machine.name}](${window.location.origin}/machine/${machine.id}/${machine.pk});\n${this.description}`,
        machineId: machine.pk
      });
    }
  }
};
</script>
