import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  disabledRoutes: null
};

const mutations = {
  "routes/SetDisabled": function (state, disabledRoutes) {
    state.disabledRoutes = disabledRoutes;
  }
};

const getters = {
  "routes/Disabled"(state) {
    return {
      disabledRoutes: state.disabledRoutes
    };
  }
};

const actions = {
  "routes/SetDisabledRoutes"({ commit }, disabledRoutes) {
    commit("routes/SetDisabled", disabledRoutes);
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
