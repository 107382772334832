<template>
  <div class="quality-chart metric-component">
    <div class="mobile-chart-title-container">
      <h2 class="card-title">{{ $t("Quality") }}</h2>
      <span class="mobile-gauge-container">
        <v-gauge
          v-if="quality_percentage"
          gaugeValueClass="gaugeValue"
          :value="quality_percentage"
          :donut="true"
          :options="gaugeOpts"
        />
      </span>
    </div>

    <v-progress-linear
      indeterminate
      v-if="!(production && production.length > 0)"
    ></v-progress-linear>
    <div
      class="charts"
      v-else
    >
      <div class="gauge-container">
        <v-gauge
          v-if="quality_percentage"
          gaugeValueClass="gaugeValue"
          :value="quality_percentage"
          :donut="true"
          :options="gaugeOpts"
        />
      </div>
      <div class="bar-container">
        <bar-chart
          v-if="chartData"
          chartName="qualityChart"
          class="qualityChart"
          uom=""
          :commas="true"
          :chartData="chartData"
          :chartOptions="chartOptions"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.quality-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 18vh;
  flex: 1;

  @media screen and (max-width: 1200px) {
    align-self: normal;
    justify-self: normal;
    width: unset !important;

    .charts {
      height: unset !important;
    }

    .bar-container {
      margin-right: 0 !important;

      .qualityChart {
        padding: 0 !important;
      }
    }
  }

  .charts {
    display: flex;
    justify-content: center;
    align-items: center;
    .bar-container {
      width: calc(100% - 150px);
      .qualityChart {
        width: 100%;
        height: 18vh;
        padding-left: 0.5rem;
        display: grid;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import VGauge from "vgauge";
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart.js";

export default {
  components: {
    BarChart,
    VGauge
  },
  props: ["options", "production", "interactiveFilters"],
  data() {
    return {
      chartData: false,
      loaded: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      },
      quality_percentage: false,
      lastParams: {}
    };
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    getScrapTargetByMachine(interval) {
      let totalScrapTargetPerInterval = 0;
      for (const m of interval.by_machine) {
        for (const p of m.by_part) {
          if (
            this.interactiveFilters.machines.length === 0 ||
            this.interactiveFilters.machines.indexOf(m.machine.machine_id) >= 0
          ) {
            if (
              this.interactiveFilters.parts.length === 0 ||
              this.interactiveFilters.parts.indexOf(p.part.part_number) >= 0
            ) {
              totalScrapTargetPerInterval +=
                p.produced *
                ((100 - (this.options.targets.qualityByMachine[m.machine.machine_id] || 100)) /
                  100);
            }
          }
        }
      }

      return Math.round(totalScrapTargetPerInterval);
    },
    getScrappedByMachine(interval) {
      let total = 0;
      for (const m of interval.by_machine) {
        for (const p of m.by_part) {
          if (
            this.interactiveFilters.machines.length === 0 ||
            this.interactiveFilters.machines.indexOf(m.machine.machine_id) >= 0
          ) {
            if (
              this.interactiveFilters.parts.length === 0 ||
              this.interactiveFilters.parts.indexOf(p.part.part_number) >= 0
            ) {
              total += p.scrapped;
            }
          }
        }
      }
      return total;
    },
    getProducedByMachine(interval) {
      let total = 0;
      for (const m of interval.by_machine) {
        for (const p of m.by_part) {
          if (
            this.interactiveFilters.machines.length === 0 ||
            this.interactiveFilters.machines.indexOf(m.machine.machine_id) >= 0
          ) {
            if (
              this.interactiveFilters.parts.length === 0 ||
              this.interactiveFilters.parts.indexOf(p.part.part_number) >= 0
            ) {
              total += p.produced;
            }
          }
        }
      }
      return total;
    },
    format_data() {
      let scrappedList = [];
      let targets = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let total_scrapped = 0;
      let total_produced = 0;
      if (this.production) {
        this.production.forEach((interval) => {
          let scrapped = this.getScrappedByMachine(interval);
          let produced = this.getProducedByMachine(interval);
          scrappedList.push(scrapped);

          const scrapTarget = this.getScrapTargetByMachine(interval);
          targets.push(scrapTarget);

          if (scrapTarget >= scrapped) {
            gradients.push(this.$colors.chart.good_gradient);
          } else {
            gradients.push(this.$colors.chart.bad_gradient);
          }
          footers.push([
            this.$t("Target (pcs)") + " : " + Math.round(scrapTarget).toLocaleString()
          ]);

          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          total_scrapped += scrapped;
          total_produced += produced;
        });

        this.quality_percentage = ((total_produced - total_scrapped) / total_produced) * 100;
      }
      this.chartData = {
        datasets: [
          {
            type: "line",
            data: targets,
            borderWidth: 2,
            pointRadius: 3,
            pointBackgroundColor: this.theme == "dark" ? "white" : "#44B2F3",
            hoverPointRadius: 0,
            borderColor: this.theme == "dark" ? "white" : "#44B2F3",
            titles: titles,
            fill: true,
            label: this.$t("Target Scrap (pcs)"),
            showLine: false
          },
          {
            data: scrappedList,
            gradients: gradients,
            titles: titles,
            footers: footers,
            label: this.$t("Scrapped (pcs)")
          }
        ],
        labels: labels
      };
    }
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      deep: true,
      handler: "format_data"
    },
    interactiveFilters: {
      handler: "format_data"
    }
  }
};
</script>
