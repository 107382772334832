<template>
  <div class="numberInput">
    <v-btn
      color="white"
      light
      fab
      small
      @click="decrement()"
      :disabled="input < min"
    >
      <i
        class="fa fa-minus"
        aria-hidden="true"
      ></i>
    </v-btn>
    <v-text-field
      v-model="input"
      @input="updateValue"
      :placeholder="0"
      type="number"
    ></v-text-field>
    <v-btn
      color="white"
      light
      fab
      small
      @click="increment()"
    >
      <i
        class="fa fa-plus"
        aria-hidden="true"
      ></i>
    </v-btn>
  </div>
</template>
<style lang="scss">
.numberInput {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
  max-width: 100%;
  margin: 0.5rem auto;
  .v-input {
    margin: 0 1rem;
    input {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
</style>

<script>
export default {
  props: {
    value: {
      default: null
    },
    min: {
      default: false
    },
    id: {
      default: "number"
    }
  },
  data() {
    return {
      input: this.value
    };
  },
  components: {},
  created() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    validateValue() {
      if (!isNaN(this.min) && this.input < this.min) {
        this.input = this.min;
      }
    },
    updateValue() {
      this.validateValue();
      this.$emit("input", this.input);
    },
    increment() {
      this.input++;
      this.validateValue();
      this.$emit("input", this.input);
    },
    decrement() {
      this.input--;
      this.validateValue();
      this.$emit("input", this.input);
    }
  },
  watch: {
    value() {
      this.input = this.value;
    }
  }
};
</script>
