<template>
  <div class="trendCard">
    <div
      class="chart"
      v-for="metric in trend.metrics.edges"
    >
      <h2 class="auto-title">{{ metric.node.name }}</h2>
      <div class="row_container">
        <AutoQualityTrend
          v-if="metric.node.automatic == 'QUALITY'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :timeScale="timeScale"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'QUALITY_OPPORTUNITY_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['quality_variance']"
          :timeScale="timeScale"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'PERFORMANCE_OPPORTUNITY_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['performance_variance']"
          :timeScale="timeScale"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'OEE_OPPORTUNITY_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['quality_variance', 'performance_variance', 'availability_variance']"
          :timeScale="timeScale"
        />
        <AutoOpportunityCost
          v-if="metric.node.automatic == 'SCRAP_COST'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :measurements="['scrap_cost']"
          :timeScale="timeScale"
        />
        <AutoOEE
          v-if="metric.node.automatic == 'OEE'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :timeScale="timeScale"
        />
        <AutoPerformance
          v-if="metric.node.automatic == 'PERFORMANCE'"
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :timeScale="timeScale"
        />
        <ManualTrend
          v-if="metric.node.automatic == null || metric.node.automatic == ''"
          :metric="metric"
          :machine_group_id="machine_group_id"
          :timeScale="timeScale"
        />
        <TopPartCost
          v-if="
            metric.node.automatic == 'QUALITY' ||
            metric.node.automatic == 'PERFORMANCE' ||
            metric.node.automatic == 'OEE'
          "
          :metric="metric"
          :machine_group_pk="machine_group_pk"
          :timeScale="timeScale"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";
.trendCard {
  .gauge-container.hide-circle {
    canvas {
      display: none;
    }
  }

  .chart_mobile_container {
    display: none;
  }

  .mobile-gauge-container {
    display: none;

    @media screen and(max-width: 1200px) {
      color: black;
      display: flex;

      canvas {
        width: 100%;
        scale: 125%;
      }

      .gaugeValue {
        font-size: 16px;
      }

      .gv {
        font-size: 16px;
      }
    }
  }

  .chart {
    margin: 30px 20px;

    @media screen and(max-width: 1200px) {
      margin: 10px;
    }

    .auto-title {
      display: none;
    }

    h2.card-title {
      display: block;
    }
  }

  .row_container {
    position: relative;
    display: flex;

    @media screen and(max-width: 1200px) {
      flex-flow: column;
      align-items: flex-start;

      .chart {
        margin-right: 0;
      }

      .charts_container {
        padding: 0;
      }

      .chart_mobile_container {
        flex-flow: column;
        align-items: baseline;
        background: #e9e8ef;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 40px;
        width: unset !important;
      }

      .mobile-chart-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .gauge-container {
        display: none;
      }

      div {
        margin-left: 0;
      }
    }
  }

  .auto-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 400;
  }
}
</style>
<script>
import AutoOEE from "./AutoOEE";
import AutoOpportunityCost from "./AutoOpportunityCost";
import AutoPerformance from "./AutoPerformance";
import AutoQualityTrend from "./AutoQualityTrend";
import ManualTrend from "./ManualTrend.vue";
import TopPartCost from "./TopPartCost";

export default {
  props: [`trend`, "timeScale"],
  components: {
    AutoPerformance,
    AutoOEE,
    AutoOpportunityCost,
    AutoQualityTrend,
    ManualTrend,
    TopPartCost
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    machine_group_pk() {
      return this.$route.params.machine_group_pk;
    },
    machine_group_id() {
      return this.$route.params.machine_group_id;
    }
  },
  methods: {}
};
</script>
