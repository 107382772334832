<template>
  <div class="operator_realtime">
    <div class="top_row">
      <v-text-field
        v-if="realtime_type_selected == 'table'"
        :placeholder="$t('Search')"
        prepend-inner-icon="mdi-magnify"
        class="searchbar"
        dense
        @input="setFilter"
        clearable
      />
      <div
        v-else
        class="searchbar"
      />
      <div class="top_row_icons">
        <button>
          <i
            class="fa fa-address-card-o"
            v-bind:class="realtime_type_selected == 'card' && 'selected'"
            @click="
              () => {
                SetRealtimeType('card');
                setShift();
              }
            "
          />
        </button>
        <button>
          <i
            class="fa fa-table"
            v-bind:class="realtime_type_selected == 'table' && 'selected'"
            @click="
              () => {
                SetRealtimeType('table');
              }
            "
          />
        </button>
        <button>
          <i
            class="fa fa-line-chart"
            v-bind:class="realtime_type_selected == 'chart' && 'selected'"
            @click="
              () => {
                SetRealtimeType('chart');
              }
            "
          />
        </button>
      </div>
    </div>

    <realtime-table
      v-if="realtime_type_selected == 'table'"
      :table_headers="table_headers"
      :items="filteredItems"
      :setFromDate="setFromDate"
      :setToDate="setToDate"
      :setCalendarCase="
        (e) => {
          this.calendarCase = e;
        }
      "
      :calendarCase="calendarCase"
      :fromDate="fromDate"
      :toDate="toDate"
      :shiftStart="shiftStart"
      :shiftEnd="shiftEnd"
      :numCheckedIn="numCheckedIn"
      :numCheckedOut="numCheckedOut"
      :now="now"
      :loading="loading"
    />
    <realtime-cards
      v-if="realtime_type_selected == 'card'"
      :items="items"
    />
    <realtime-charts
      v-if="realtime_type_selected == 'chart'"
      :items="items"
      :setCalendarCase="
        (e) => {
          this.calendarCase = e;
        }
      "
      :calendarCase="calendarCase"
      :shiftStart="shiftStart"
      :shiftEnd="shiftEnd"
      :fromDate="fromDate"
      :toDate="toDate"
      :setFromDate="setFromDate"
      :setToDate="setToDate"
      :now="now"
      :numberRecords="numberRecords"
      :setNumberRecords="
        (e) => {
          this.numberRecords = e;
        }
      "
    />
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.operator_realtime {
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    padding: 0.5rem;
  }

  .searchbar {
    width: 33%;
    height: 40px !important;
    max-width: unset;
    margin-top: 4px;
  }

  .header_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    flex: unset;
    font-weight: lighter;
    color: $blue;
    text-align: left;
    font-size: 14px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:hover {
      color: $green;
    }

    .rowcontent {
      flex: unset;
      margin-right: 10px;
    }

    i {
      font-size: 18px;
      width: 18px;
      position: relative;
      left: 0;
      bottom: 0;
    }

    &.header_name {
      width: 160px;
      box-sizing: content-box;
    }

    &.header_role {
      width: 130px;
      box-sizing: content-box;
    }

    &.header_status {
      width: 160px;
      box-sizing: content-box;
    }

    &.header_machine {
      width: 110px;
      box-sizing: content-box;
    }

    &.header_machine_status {
      width: 115px;
      box-sizing: content-box;
    }

    &.header_zone {
      width: 110px;
      box-sizing: content-box;
    }

    &.header_part {
      width: 110px;
      box-sizing: content-box;
    }

    &.header_production_time {
      width: 100px;
      box-sizing: content-box;
    }

    &.header_target_units {
      width: 95px;
      box-sizing: content-box;
    }

    &.header_actual {
      width: 95px;
      box-sizing: content-box;
    }

    &.header_gap {
      width: 95px;
      box-sizing: content-box;
    }

    &.header_average {
      width: 95px;
      box-sizing: content-box;
    }

    &.header_scrap {
      width: 95px;
      box-sizing: content-box;
    }

    &.header_efficiency {
      width: 95px;
      box-sizing: content-box;
    }
  }

  .top_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .top_row_icons {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .selected {
        color: deepskyblue;
      }

      button {
        margin: 0 10px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .theme--light {
    .operator_analytics {
      .searchbars {
        background: $lighterGrey;
      }
    }
  }
}
</style>
<script>
import moment from "moment-timezone";

import { FormatTime, GetAveragePerHour } from "../../../utils/timeFormat";
import RealtimeCards from "./realtimeCards";
import RealtimeCharts from "./realtimeCharts";
import RealtimeTable from "./realtimeTable";

export default {
  props: ["realtime_type_selected", "SetRealtimeType"],
  components: { RealtimeCharts, RealtimeCards, RealtimeTable },
  created: async function () {
    const shift = await this.loadShift();
    if (shift.length > 0 && shift[0].shiftDays.length > 0) {
      this.shiftStart = shift[0].shiftDays[0].lastStartDatetime;
      this.shiftEnd = shift[0].shiftDays[0].nextEndDatetime;

      this.fromDate = moment(this.shiftStart);
      this.toDate = moment(this.shiftEnd);
    } else {
      this.loading = false;
    }
    this.now = new moment();
    let getOperatorData = this.getOperatorData;
    this.reloadInterval = setInterval(function () {
      getOperatorData();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.reloadInterval);
  },
  methods: {
    setFilter(e) {
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce);
      }
      this.searchDebounce = setTimeout(() => {
        this.textFilter = e;
        this.filteredItems = this.filterItems(e);
      }, 500);
    },
    filterItems(filter) {
      if (!this.textFilter || this.textFilter == "") {
        return this.items;
      }
      const filteredItems = this.items.filter((item) => {
        return (
          (item.name && item.name.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.role && item.role.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.status && item.status.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.machine && item.machine.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.machine_status &&
            item.machine_status.name &&
            item.machine_status.name.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.zone && item.zone.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.part && item.part.toLowerCase().includes(this.textFilter.toLowerCase()))
        );
      });

      return filteredItems;
    },
    setShift() {
      this.calendarCase = "shift";
      if (this.shiftStart != null && this.shiftEnd != null) {
        this.setFromDate(moment(this.shiftStart));
        this.setToDate(moment(this.shiftEnd));
      }
    },
    setFromDate(date) {
      this.fromDate = date;
    },
    setToDate(date) {
      this.toDate = date;
    },
    getOperatorData: async function () {
      if (this.fetchDataDebounce) {
        clearTimeout(this.fetchDataDebounce);
      }
      this.items = [];
      this.filteredItems = [];
      this.loading = true;
      this.fetchDataDebounce = setTimeout(async () => {
        this.now = new moment();
        const from = this.fromDate ? this.fromDate.toISOString() : null;
        const to = this.toDate ? this.toDate.toISOString() : null;

        const result = await this.$http.get("/operator/metrics/", {
          params: {
            from_date: from,
            to_date: to
          }
        });
        const checkins = await this.$http.get("/operator/check_in_log/", {
          params: {
            from_date: from,
            to_date: to
          }
        });
        this.checkins = checkins.data.results.badges.buckets;
        this.items = this.CreateItemsData(result.data.results);
        this.filteredItems = this.filterItems(this.textFilter);
        this.loading = false;
      }, 500);
    },
    loadShift() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{shifts(current:true){
            name
            shiftDays(current:true){
              lastStartDatetime
              nextStartDatetime
              nextEndDatetime
            }
          }
        }`
            }
          })
          .then((res) => {
            resolve(res.data.data.shifts);
          })
          .catch((e) => {
            reject();
          });
      });
    },
    CreateItemsData(data) {
      const items = [];
      let numCheckedIn = 0;
      let numCheckedOut = 0;
      data.forEach((d, i) => {
        const item = {
          key: i,
          name: d.last_record.username,
          avatar: d.last_record.avatar,
          role: d.last_record.role,
          status: "1",
          machine: d.last_record.machine_name,
          machine_status: d.current_machine_status || "",
          end_time: d.last_record.end_time,
          zone: d.last_record.zone,
          part: d.last_record.part_number,
          production_time: FormatTime(d.event_duration),
          event_duration: d.event_duration,
          target_units: d.production_target,
          actual: d.produced,
          gap: d.production_target - d.produced,
          average: GetAveragePerHour(d.produced, d.event_duration),
          scrap: d.scrapped,
          efficiency: `${d.production_target > 0 ? Math.round((d.produced / d.production_target) * 100) + "%" : "--"}`,
          checked_in: 0,
          machines: d.machines
        };
        this.checkins.forEach((event) => {
          if (d.last_record.badges.includes(event.key)) {
            if (event.last_record.hits.hits.length > 0) {
              let check_time = event.last_record.hits.hits[0]._source.check_time;
              if (
                this.now.diff(moment(check_time), "hours") <= 24 &&
                event.last_record.hits.hits.length > 0 &&
                event.last_record.hits.hits[0]["_source"]["check_type"] == 1
              ) {
                item.checked_in = event.last_record.hits.hits[0]._source.machine_id;
              }
            }
          }
        });
        if (item.checked_in) {
          numCheckedIn++;
        } else {
          numCheckedOut++;
        }

        items.push(item);
      });

      this.numCheckedIn = numCheckedIn;
      this.numCheckedOut = numCheckedOut;
      return items;
    }
  },
  data() {
    return {
      loading: true,
      fetchDataDebounce: null,
      reloadInterval: null,
      now: new moment(),
      textFilter: "",
      searchDebounce: null,
      fromDate: null,
      toDate: null,
      shiftStart: null,
      shiftEnd: null,
      numCheckedIn: 0,
      numCheckedOut: 0,
      calendarCase: 0,
      numberRecords: 10,
      table_headers: [
        {
          text: "",
          value: "expand",
          align: "end"
        },
        {
          text: this.$t("Name"),
          value: "name",
          width: 160,
          class: "header_name"
        },
        {
          text: this.$t("Role"),
          value: "role",
          width: 130,
          class: "header_role"
        },
        {
          text: this.$t("Status"),
          value: "status",
          width: 160,
          class: "header_status"
        },
        {
          text: this.$t("Machine"),
          value: "machine",
          width: 110,
          class: "header_machine"
        },
        {
          text: this.$t("Machine Status"),
          value: "machine_status",
          width: 115,
          class: "header_machine_status"
        },
        {
          text: this.$t("Zone"),
          value: "zone",
          width: 110,
          class: "header_zone"
        },
        {
          text: this.$t("Part"),
          value: "part",
          width: 110,
          class: "header_part"
        },
        {
          text: this.$t("Production Time"),
          value: "production_time",
          width: 110,
          class: "header_production_time"
        },
        {
          text: this.$t("Target (units)"),
          value: "target_units",
          width: 95,
          class: "header_target_units"
        },
        {
          text: this.$t("Actual (units)"),
          value: "actual",
          width: 95,
          class: "header_actual"
        },
        {
          text: this.$t("Gap"),
          value: "gap",
          width: 95,
          class: "header_gap",
          align: "center"
        },
        {
          text: this.$t("Average (UPH)"),
          value: "average",
          width: 95,
          class: "header_average"
        },
        {
          text: this.$t("Scrap (units)"),
          value: "scrap",
          width: 95,
          class: "header_scrap"
        },
        {
          text: this.$t("Efficiency (UPH)"),
          value: "efficiency",
          width: 95,
          class: "header_efficiency"
        }
      ],
      items: [],
      checkins: [],
      filteredItems: []
    };
  },
  watch: {
    fromDate: function () {
      if (this.fromDate && this.toDate) {
        this.getOperatorData();
      }
    },
    toDate: function () {
      if (this.fromDate && this.toDate) {
        this.getOperatorData();
      }
    }
  }
};
</script>
