<template>
  <div class="machine-prioritization">
    <v-dialog
      v-model="showDialog"
      content-class="machine-prioritization-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <div class="dialogBtn">
            <v-btn
              class="edit-btn"
              v-bind="attrs"
              v-on="on"
              color="primary"
              light
              fab
              small
            >
              <i
                class="fa fa-pencil"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </v-hover>
      </template>
      <div class="card">
        <DialogCloseButton :onClick="() => (showDialog = false)" />
        <div class="card-header headline">
          {{ $t("Prioritize Machines") }}
        </div>
        <div class="card-content">
          <draggable
            v-model="machines"
            class="machines"
          >
            <div
              v-for="(machine, machine_index) in machines"
              class="machine"
              :key="machine.id"
            >
              <h2 :class="{ changed: machine_index + 1 != machine.priority }">
                <span>{{ machine_index + 1 }}.</span> {{ machine.name }}
              </h2>
            </div>
          </draggable>
        </div>
        <div class="card-action">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showDialog = false"
            :disabled="saving"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            primary
            text
            :disabled="saving"
            :loading="saving"
            @click="updatePriority"
          >
            {{ $t("Submit") }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
@import "../../../../scss/variables";
@import "../../../../scss/light_theme";
@import "../../../../scss/mq";

.machine-prioritization-dialog {
  float: right;
  min-width: 500px;
  .card {
    max-height: 90vh;
    .card-content {
      max-height: calc(100% - 2rem);
      .machines {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        max-height: 85vh;
        padding-bottom: 3rem;
        .machine {
          cursor: move; /* fallback if grab cursor is unsupported */
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
          span {
            margin: 0 0.5rem;
            color: white;
          }
          h2 {
            border: 1px solid rgba(255, 255, 255, 0.4);
            color: white;
            margin: 0.5rem;
            padding: 0.5rem;
            width: 250px;
            font-size: 1.15rem;
            border-radius: 0.25rem;
            &.changed {
              border: 2px solid $blue !important;
            }
          }
        }

        /* (Optional) Apply a "closed-hand" cursor during drag operation. */
        .machine:active {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      }
    }
    .card-action {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      bottom: 0;
    }
  }
}
@include media_below(550px) {
  .machine-prioritization-dialog {
    .card {
      max-width: 95vw;
      margin: 5vw;
      .card-header,
      .card-action {
        background-color: $cardColor;
      }
      .card-content {
        max-height: 80vh;
        padding-bottom: 5rem;
        .machines {
          max-height: calc(80vh - 5rem);
          flex-direction: row;
          align-items: flex-start;
          overflow-y: auto;
        }
      }
    }
  }
}
@include light_theme() {
  .machine-prioritization-dialog {
    .card {
      .card-content {
        .machines {
          .machine {
            h2,
            span {
              color: rgba(0, 0, 0, 0.6);
              border-color: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import moment from "moment";
import draggable from "vuedraggable";

import DialogCloseButton from "@/components/DialogCloseButton";

export default {
  components: { draggable, DialogCloseButton },
  props: ["machine_group_id"],
  data() {
    return {
      showDialog: false,
      machines: [],
      saving: false
    };
  },
  created() {
    this.loadMachinePriority();
  },
  computed: {},
  methods: {
    moment,
    loadMachinePriority() {
      let query = `query getMachineGroup($id: ID!){
        machineGroup(id: $id){
          id
          ... on MachineGroupType{
            allMachines{
              id
              pk
              name
              priority
            }
          }
        }
      }`;
      let variables = { id: this.machine_group_id };
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        this.machines = res.data.data.machineGroup.allMachines;
        this.machines = this.machines.sort((a, b) => a.priority - b.priority);
      });
    },
    updatePriority() {
      let updates = [];
      this.saving = true;
      this.machines.forEach(async (machine, machine_index) => {
        let query = `mutation ($input: MachineMutationInput!){
          updateMachine(input: $input){
            machine{
              id
              pk
              name
              priority
            }
          }
        }`;
        let variables = { input: { id: machine.id, priority: machine_index + 1 } };
        const res = await this.$http.post("graphql/", { query, variables });
        machine.priority = res.data.data.updateMachine.machine.priority;
        if (machine_index == this.machines.length - 1) {
          this.$message("ALERT", { text: this.$t("Changes Saved"), type: "success" });
          this.showDialog = false;
        }
      });
      this.saving = false;
    }
  }
};
</script>
