<template>
  <v-avatar
    :min-height="size"
    :min-width="size"
    :height="size"
    :width="size"
    color="grey"
  >
    <img
      v-if="user.avatar"
      :src="user.avatar"
      :alt="user.firstName"
    />
    <span v-else>{{ nameInitials }}</span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "75px"
    }
  },
  computed: {
    nameInitials() {
      let initials = "";
      if (this.user && this.user.firstName) {
        initials += this.user.firstName.charAt(0);
      }
      if (this.user && this.user.lastName) {
        initials += this.user.lastName.charAt(0);
      }
      return initials;
    }
  }
};
</script>

<style scoped lang="scss"></style>
