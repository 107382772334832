<template>
  <section class="h-100">
    <v-tabs
      class="tabs"
      centered
      grow
      v-model="tab"
    >
      <v-tabs-slider color="primary" />
      <v-tab
        v-for="tab in tabs"
        :key="tab"
      >
        <span class="tab_text">{{ tab }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="h-100"
    >
      <v-tab-item
        v-for="tab in tabs"
        :key="tab"
      >
        <keep-alive>
          <AboutTab v-if="tab === tabs.about" />
        </keep-alive>
        <ResultsTab v-if="tab === tabs.results" />
        <ScenarioAnalysisTab v-if="tab === tabs.scenarioAnalysis" />
        <CommentsTab v-if="tab === tabs.comments" />
        <UploadTab v-if="tab === tabs.upload" />
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>
<script>
import { AboutTab, CommentsTab, ResultsTab, ScenarioAnalysisTab, UploadTab } from "./components";

export default {
  name: "OptimizationModelView",
  components: {
    AboutTab,
    CommentsTab,
    UploadTab,
    ResultsTab,
    ScenarioAnalysisTab
  },
  data() {
    return {
      tab: 0,
      tabs: {
        about: "About",
        results: "Results",
        scenarioAnalysis: "Scenario Analysis",
        comments: "Comments",
        upload: "Upload"
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.h-100 {
  height: 100%;
  overflow: auto;
}
</style>
