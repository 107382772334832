import Chart from "chart.js";
import { Pie, mixins } from "vue-chartjs";
import { mapGetters } from "vuex";

import { Dispatcher } from "@/utils/eventbus";

const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    backgroundColors: { type: Array, default: () => [] }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            boxWidth: 10,
            boxHeight: 10
          },
          position: "right"
        },
        tooltips: {
          callbacks: {
            label: this.formatTooltip
          }
        },
        layout: {
          padding: 0
        }
      }
    };
  },
  created() {
    this.options = _.merge(this.options, this.chartOptions);
    Chart.defaults.global.defaultFontColor = this.isLightTheme ? "#6F6F6F" : "#8D8D8D";
    Chart.defaults.global.defaultFontFamily = "IBM Plex Sans";
    Chart.defaults.global.defaultFontFamily = "Verdana";
    Dispatcher.$listen(this.chartName, this.redrawChart);
  },
  mounted() {
    this.renderChart(this.formattedChartData, this.options);
  },
  beforeDestroy() {
    Dispatcher.$silence(this.chartName, this.redrawChart);
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    isLightTheme() {
      return this.theme === "light";
    },
    formattedChartData() {
      let chartData = _.merge({}, this.chartData);
      if (chartData && chartData.datasets) {
        chartData.datasets.forEach((dataset) => {
          Object.assign(dataset, {
            borderWidth: 0,
            backgroundColor:
              this.backgroundColors.length > 0
                ? this.backgroundColors
                : [
                    "#AD84C6",
                    "#708183",
                    "#84ACB6",
                    "#EEFF99",
                    "#99B8FF",
                    "#CCDBFF",
                    "#FF99FF",
                    "#D4FFCC",
                    "#5E739A",
                    "#CCFFF7",
                    "#8093FF",
                    "#66FFA3",
                    "#BB99FF",
                    "#99FFA2",
                    "#BBFF99",
                    "#CC99FF",
                    "#FFFFCC",
                    "#8784C7",
                    "#D4FF99",
                    "#FFAC99",
                    "#D1FF99",
                    "#FFEE99",
                    "#6997AF",
                    "#99FFA3",
                    "#BB99FF",
                    "#CCE5FF",
                    "#99AAFF",
                    "#FFDD99",
                    "#9EFF99",
                    "#FFCCCC"
                  ]
          });
        });
      }
      return chartData;
    }
  },
  watch: {
    chartOptions: {
      handler: "redrawChart",
      deep: true
    },
    chartData: {
      handler: "redrawChart",
      deep: true
    },
    theme: {
      handler: "redrawChart",
      deep: true
    }
  },
  methods: {
    redrawChart() {
      Chart.defaults.global.defaultFontColor = this.isLightTheme ? "#6F6F6F" : "#8D8D8D";
      this.options = _.merge(this.options, this.chartOptions);
      this.renderChart(this.formattedChartData, this.options);
      if (this.$data._chart) {
        this.$data._chart.update();
      }
    },
    formatTooltip(item, data) {
      return data["labels"][item["index"]] + ": " + data["datasets"][0]["data"][item["index"]];
    }
  }
};
