<template>
  <div
    class="trends"
    key="trends"
    v-bind:class="mobileTabIndex == 1 && 'tabSelected'"
  >
    <h2>{{ $t("Trends") }}</h2>
    <v-progress-linear
      indeterminate
      v-if="loadingTrends"
    ></v-progress-linear>
    <div class="trends-card-overflow">
      <div
        class="card"
        v-for="trend in trends"
        v-if="!loadingTrends"
      >
        <div class="card-header">
          <h3>{{ $t(trend.node.name) }}</h3>
          <div class="headerBtns">
            <metric-input
              v-if="trend.node.name !== 'Safety'"
              :trend="trend"
              @metric_update="loadTrends"
              :machine_group_id="machine_group_id"
            />
            <v-btn
              class="expand"
              small
              light
              color="white"
              fab
              @click.stop.prevent="setEnlargedTrend(trend.node)"
            >
              <i
                class="fa fa-expand"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </div>
        <div class="card-content">
          <div class="metrics">
            <div class="metric">
              <div class="metric-value">
                <p class="name"></p>
                <p class="target label">{{ $t("Target") }}</p>
                <p class="actual label">{{ $t("Actual") }}</p>
              </div>
            </div>
            <div
              class="metric"
              v-for="metric in trend.node.metrics.edges"
            >
              <div class="metric-value">
                <p class="name">{{ $t(metric.node.name) }}</p>
                <p class="target">{{ metric.node.target ? metric.node.target : "" }}</p>
                <p class="actual">
                  {{ metric.node.currentValue ? metric.node.currentValue.value : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MetricInput from "@/components/dialogs/MetricInput";
import { numberWithCommas } from "@/utils/filters";
import moment from "moment";

export default {
  name: "Trends",
  props: {
    mobileTabIndex: { type: Number, default: 0 },
    machine_group_id: { type: String, default: "" },
    machineGroupPk: { type: Number },
    levelId: { type: Number },
    enlargedTrend: { type: Object, default: () => {} },
    setEnlargedTrend: { type: Function, default: () => null }
  },
  components: {
    MetricInput
  },
  data() {
    return {
      loadingTrends: false,
      safetyGroupLevel: null,
      groupsBySafetyLevel: [],
      trends: [
        {
          node: {
            name: this.$t("Safety"),
            metrics: {
              edges: [
                { node: { name: this.$t("Number of Incidents"), value: 3 } },
                { node: { name: this.$t("Open Incidents"), value: 1 } },
                { node: { name: this.$t("Closed Incidents"), value: 2 } }
              ]
            }
          }
        },
        {
          node: {
            name: this.$t("Quality"),
            metrics: {
              edges: [
                { node: { name: this.$t("First Time Quality"), value: "98%" } },
                { node: { name: this.$t("Opportunity Cost"), value: "$2,835" } }
              ]
            }
          }
        },
        {
          node: {
            name: this.$t("Delivery"),
            metrics: {
              edges: [{ node: { name: this.$t("Schedule Attainment"), value: "95%" } }]
            }
          }
        },
        {
          node: {
            name: this.$t("Cost"),
            metrics: {
              edges: [
                { node: { name: this.$t("OEE"), value: "88%" } },
                { node: { name: this.$t("Opportunity Cost"), value: "$12,835" } },
                { node: { name: this.$t("Labor Efficiency"), value: "78%" } }
              ]
            }
          }
        }
      ]
    };
  },
  async created() {
    await this.setGroupsBySafetyLevel();
    this.loadTrends();
    if (window.location.href.includes("safety")) {
      const safetyTrend = this.trends.find((trend) => trend.node.name === this.$t("Safety"));
      if (safetyTrend) {
        this.setEnlargedTrend(safetyTrend.node);
      }
    }
  },
  methods: {
    loadSafetyMetrics(metric, machine_group_ids) {
      const params = {
        metric: metric.node["automatic"].toLowerCase(),
        group_ids: machine_group_ids
      };
      this.$http.get(`safety/safety_metric/`, { params }).then((res) => {
        metric.node.currentValue = {
          value: res.data.toLocaleString()
        };
      });
    },
    async getSafetyGroupLevel() {
      const response = await this.$http.get("graphql/", {
        params: {
          query: `{
           settings {
             safetyGroupLevel {
               pk
               name
               id
               level
             }
           }
         }`
        }
      });
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.settings &&
        response.data.data.settings.length > 0 &&
        response.data.data.settings[0].safetyGroupLevel &&
        response.data.data.settings[0].safetyGroupLevel.level
      ) {
        this.safetyGroupLevel = response.data.data.settings[0].safetyGroupLevel.level;
      } else {
        this.safetyGroupLevel = null;
      }
    },
    async setGroupsBySafetyLevel() {
      await this.getSafetyGroupLevel();
      if (this.levelId === this.safetyGroupLevel) {
        this.groupsBySafetyLevel.push(this.machineGroupPk);
      } else if (this.levelId > this.safetyGroupLevel) {
        // get all subgroup ids at the safety group level
        this.$http
          .get("graphql/", {
            params: {
              query: `{
              machineGroups {
                pk
                level {
                  level
                }
                allSubGroups {
                  pk
                  level {
                    level
                  }
                }
              }
            }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroup = machineGroups.find(
              (machineGroup) => machineGroup.pk == this.machineGroupPk
            );
            const subGroups = machineGroup["allSubGroups"];
            subGroups.forEach((subGroup) => {
              if (subGroup.level.level === this.safetyGroupLevel) {
                this.groupsBySafetyLevel.push(subGroup.pk);
              }
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        // find the parent at the safety group level
        this.$http
          .get("graphql/", {
            params: {
              query: `{
              machineGroups {
                pk
                level {
                  level
                }
                allSubGroups {
                  pk
                  level {
                    level
                  }
                }
              }
            }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroupsAtThisLevel = machineGroups.filter((machineGroup) => {
              return machineGroup.level.level === this.safetyGroupLevel;
            });
            machineGroupsAtThisLevel.forEach((machineGroup) => {
              const subGroups = machineGroup["allSubGroups"];
              subGroups.forEach((subGroup) => {
                if (subGroup.pk === this.machineGroupPk) {
                  this.groupsBySafetyLevel.push(machineGroup.pk);
                }
              });
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    loadTrends() {
      this.loadingTrends = true;
      let query = `query($machineGroupId: ID!) {
        plantTrends {
          edges {
            node {
              name
              order
              metrics {
                edges {
                  node {
                    id
                    name
                    order
                    target
                    automatic
                    autoMetricHours
                    currentValue(machineGroup: $machineGroupId) {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }`;
      let variables = { machineGroupId: this.machine_group_id };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        this.trends = res.data.data.plantTrends.edges.sort((a, b) => a.node.order - b.node.order);
        this.trends.forEach((trend) => {
          const numberOfMetrics = trend.node.metrics.edges.length;
          let numberOfAutomaticMetrics = 0;
          trend.node.metrics.edges.forEach((metric) => {
            if (metric.node.automatic) {
              numberOfAutomaticMetrics++;
              let params = {
                machine_group_id: this.machineGroupPk,
                from_date: moment()
                  .startOf("hour")
                  .subtract(metric.node.autoMetricHours, "hours")
                  .toISOString(),
                to_date: moment().startOf("hour").toISOString()
              };
              if (metric.node.automatic.includes("COST")) {
                this.$http.get("variance/summary/", { params }).then((res) => {
                  switch (metric.node.automatic) {
                    case "PERFORMANCE_OPPORTUNITY_COST":
                      metric.node.currentValue = {
                        value:
                          "$" +
                          numberWithCommas(Math.round(res.data.total_performance_variance.value))
                      };
                      break;
                    case "QUALITY_OPPORTUNITY_COST":
                      metric.node.currentValue = {
                        value:
                          "$" + numberWithCommas(Math.round(res.data.total_quality_variance.value))
                      };
                      break;
                    case "OEE_OPPORTUNITY_COST":
                      metric.node.currentValue = {
                        value:
                          "$" +
                          numberWithCommas(
                            Math.round(
                              res.data.total_performance_variance.value +
                                res.data.total_quality_variance.value +
                                res.data.total_availability_variance.value
                            )
                          )
                      };
                      // eslint-disable-next-line prettier/prettier
                      break;
                    case "SCRAP_COST":
                      metric.node.currentValue = {
                        value: "$" + numberWithCommas(Math.round(res.data.total_scrap_cost.value))
                      };
                      break;
                  }
                });
              } else if (metric.node["automatic"].includes("SAFETY")) {
                this.setGroupsBySafetyLevel()
                  .then(() => this.loadSafetyMetrics(metric, this.groupsBySafetyLevel))
                  .catch((err) => {
                    console.error(err);
                  });
              } else {
                this.$http.get("metrics/machine_group_summary/", { params }).then((res) => {
                  switch (metric.node.automatic) {
                    case "PERFORMANCE":
                      if (res.data.production_target.value == 0) {
                        metric.node.currentValue = { value: "0%" };
                      } else {
                        metric.node.currentValue = {
                          value:
                            Math.round(
                              (res.data.produced.value / res.data.production_target.value) * 100
                            ) + "%"
                        };
                      }
                      break;
                    case "QUALITY":
                      if (res.data.produced.value == 0) {
                        metric.node.currentValue = { value: "0%" };
                      } else {
                        metric.node.currentValue = {
                          value:
                            Math.round(
                              ((res.data.produced.value - res.data.scrapped.value) /
                                res.data.produced.value) *
                                100
                            ) + "%"
                        };
                      }
                      break;
                    case "OEE":
                      if (
                        res.data.production_target.value == 0 ||
                        res.data.produced.value == 0 ||
                        res.data.duration.value == 0
                      ) {
                        metric.node.currentValue = { value: "0%" };
                      } else {
                        metric.node.currentValue = {
                          value:
                            Math.round(
                              (((((res.data.produced.value / res.data.production_target.value) *
                                (res.data.produced.value - res.data.scrapped.value)) /
                                res.data.produced.value) *
                                res.data.runtime.duration.value) /
                                (res.data.duration.value - res.data.planned.duration.value)) *
                                100
                            ) + "%"
                        };
                      }
                      break;
                  }
                });
              }
            }
          });
          if (numberOfAutomaticMetrics == numberOfMetrics) {
            trend.isAllAutomatic = true;
          }
        });
        this.loadingTrends = false;
      });
    }
  }
};
</script>
