var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{staticClass:"opportunity_cost_container"},[_c('div',{staticClass:"metric-component auto-opportunity-cost"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"mobile-chart-title-container"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t(_vm.metric.node.name)))]),_vm._v(" "),_c('span',{staticClass:"mobile-gauge-container"},[_c('v-gauge',{attrs:{"gaugeValueClass":_vm.gaugeValue(_vm.metric.node.currentValue),"value":_vm.total_opportunityCost,"donut":true,"options":_vm.noringGaugeOpts}})],1)])]),_vm._v(" "),(!_vm.loaded)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_c('div',{staticClass:"charts"},[(_vm.loaded)?_c('div',{staticClass:"gauge-container hide-circle"},[_c('v-gauge',{attrs:{"gaugeValueClass":_vm.gaugeValue(_vm.metric.node.currentValue),"value":_vm.total_opportunityCost,"donut":true,"options":_vm.noringGaugeOpts,"width":"140px","height":"140px"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"charts_container"},[_c('div',{staticClass:"chart"},[_c('BarChart',{staticClass:"dynamicChart",attrs:{"uom":"$","chart-id":_vm.metric.node.id,"chart-name":_vm.metric.node.name,"chart-data":_vm.chart_data[_vm.metric.node.name],"chart-options":{
                scales: {
                  yAxes: [{ zeroLineColor: '#FFF', stacked: true }],
                  xAxes: [
                    {
                      ticks: { minRotation: 90, maxRotation: 90 },
                      stacked: true
                    }
                  ]
                }
              }}})],1)])])],1),_vm._v(" "),_c('div',{staticClass:"metric-component shiftTableContainer"},[_c('div',{staticClass:"chart"},[_c('v-data-table',{staticClass:"shiftTable",attrs:{"headers":_vm.shiftSummary.headers,"items":_vm.shiftSummary.shifts,"loading":!_vm.loaded,"height":"100%","disable-pagination":"","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{style:({ color: item.color })},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])]}},{key:"item.cost",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v("$"+_vm._s(_vm.numberWithCommas(item.cost ? item.cost.toFixed(2) : 0)))])]}},{key:"item.percentage",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getPercentage(item))+"%")])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }