<template>
  <div class="date-range-filter">
    <h4 class="filter-label">{{ $t("Presets") }}</h4>
    <v-tabs
      class="preset-tabs"
      height="25"
      grow
      optional
      grow
      :show-arrows="false"
      v-model="preset"
      @change="changePreset"
    >
      <v-tab
        class="preset-tab"
        v-show="includeShift"
        >{{ $t("Shift") }}</v-tab
      >
      <v-tab class="preset-tab">{{ $t("Day") }}</v-tab>
      <v-tab class="preset-tab">{{ $t("Week") }}</v-tab>
      <v-tab class="preset-tab">{{ $t("Month") }}</v-tab>
      <v-tab class="preset-tab">{{ $t("Year") }}</v-tab>
    </v-tabs>
    <h4
      class="filter-label"
      v-if="includeScale"
    >
      {{ $t("Scales") }}
    </h4>
    <v-tabs
      v-if="includeScale"
      class="scales-tabs"
      height="25"
      grow
      :show-arrows="false"
      v-model="scale"
    >
      <v-tab class="scales-tab">{{ $t("Hour") }}</v-tab>
      <v-tab class="scales-tab">{{ $t("Day") }}</v-tab>
      <v-tab class="scales-tab">{{ $t("Week") }}</v-tab>
      <v-tab class="scales-tab">{{ $t("Month") }}</v-tab>
      <v-tab class="scales-tab">{{ $t("Year") }}</v-tab>
    </v-tabs>
    <div class="pickers">
      <div class="from">
        <h4 class="filter-label">{{ $t("From Date") }}</h4>
        <datetime-picker
          class="date-picker"
          id="fromDate"
          v-model="from_date"
          :is-dialog="true"
          :max="to_date ? to_date : null"
          @input="changeDateManually"
        />
      </div>
      <div class="to">
        <h4 class="filter-label">{{ $t("To Date") }}</h4>
        <datetime-picker
          class="date-picker to-date-picker"
          id="toDate"
          v-model="to_date"
          :is-dialog="true"
          :min="from_date ? from_date : null"
          @input="changeDateManually"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import DatetimePicker from "@/components/form/DatetimePicker";

import { AnalyticsFilterController } from "./AnalyticsFilterController";

export default {
  components: {
    DatetimePicker
  },
  props: {
    filterController: AnalyticsFilterController,
    includeScale: Boolean,
    includeShift: Boolean
  },
  created() {
    this.changePreset(1);
  },
  data: () => {
    return {
      preset: null,
      presets: ["shift", "day", "week", "month", "year"],
      scale: null,
      scales: ["hour", "day", "week", "month", "year"],
      from_date: null,
      to_date: null
    };
  },
  methods: {
    moment,
    changeDateManually() {
      this.preset = null;
      this.filterController.setFilter("date_range", {
        from_date: this.from_date,
        to_date: this.to_date
      });
    },
    changePreset(e) {
      this.preset = e;
      const currentPreset = this.presets[this.preset];
      switch (currentPreset) {
        case "shift":
          {
            this.from_date = this.shift_start;
            this.to_date = this.shift_end;
            this.scale = 0;
          }
          break;
        case "day":
          {
            this.from_date = moment().startOf("day").toDate();
            this.to_date = moment().endOf("day").toDate();
            this.scale = 0;
          }
          break;
        case "week":
          {
            this.from_date = moment().startOf("week").toDate();
            this.to_date = moment().endOf("week").toDate();
            this.scale = 1;
          }
          break;
        case "month":
          {
            this.from_date = moment().startOf("month").toDate();
            this.to_date = moment().endOf("month").toDate();
            this.scale = 2;
          }
          break;
        case "year":
          {
            this.from_date = moment().startOf("year").toDate();
            this.to_date = moment().endOf("year").toDate();
            this.scale = 3;
          }
          break;
      }
      this.filterController.setFilter("date_range", {
        from_date: this.from_date,
        to_date: this.to_date
      });
      if (this.includeScale) {
        this.filterController.setFilter("scale", this.scales[this.scale]);
      }
    }
  },
  watch: {
    scale: function () {
      if (this.includeScale) {
        this.filterController.setFilter("scale", this.scales[this.scale]);
      }
    }
  }
};
</script>
<style lang="scss">
.date-range-filter {
  .preset-tabs,
  .scales-tabs {
    min-height: unset;
    width: 100%;
    margin-bottom: 20px;
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none;
    }
    .preset-tab,
    .scales-tab {
      padding: 0 8px;
      margin: 0;
      min-width: unset;
      height: 20px;
      font-size: 12px;
    }
  }

  .pickers {
    display: flex;
    justify-content: space-between;
    .from,
    .to {
      width: calc(50% - 5px);
      .v-text-field {
        padding-top: 0;
      }
      .v-text-field__details {
        display: none;
      }
    }
  }
}
</style>
