<template>
  <div class="tableDiv">
    <h2 class="card-title">{{ $t("Performance") }}</h2>
    <generic-table
      :columns="[
        { title: $t('Time'), key: 'time' },
        { title: $t('Machine'), key: 'machine' },
        { title: $t('Part Number'), key: 'part_number' },
        { title: $t('Target'), key: 'target' },
        { title: $t('Produced'), key: 'produced' }
      ]"
      :nested_columns="[
        { key: '' },
        { key: 'machine' },
        { key: 'part_number' },
        { key: 'target' },
        { key: 'produced' }
      ]"
      :footer="[
        { type: 'csv', csv: downloadCsv },
        { type: 'text', text: $t('Total') },
        {},
        {
          type: 'text',
          text: this.numberWithCommas(
            Math.round(sumValue(production, ['production_target', 'value']))
          )
        },
        { type: 'text', text: this.numberWithCommas(sumValue(production, ['produced', 'value'])) }
      ]"
      :data="tableData"
    />
  </div>
</template>

<script>
import moment from "moment/moment";
import { Fragment } from "vue-fragment";

import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { numberWithCommas } from "@/utils/filters";
import { getDateFormatter } from "@/utils/getDateFormatter";
import { sumValue } from "@/utils/sumValue";

import { listHasPartsOrMachines, listMachineParts } from "../../utils/listUtils";
import production from "./Production";

export default {
  name: "PerformanceQuantityTable",
  props: ["production", "chartOptions", "filters"],
  components: {
    GenericTable,
    Fragment
  },
  data() {
    return {
      productionList: [],
      tableData: []
    };
  },
  created() {
    for (const p of this.production) {
      let percentage_target = 0;
      for (const machine of p.by_machine) {
        const machineId = machine.machine.machine_id;
        const byMachine = (this.chartOptions.targets.perfByMachine[machineId] || 100) / 100;
        percentage_target += byMachine * machine.production_target;
      }
      //
      const e = { ...p };
      e.percentage_target = percentage_target;
      e.expanded = false;
      this.productionList.push(e);
    }
  },
  methods: {
    numberWithCommas,
    sumValue,
    getDateFormatter,
    listMachineParts,
    listHasPartsOrMachines,
    createCSVFromRows,
    downloadCsv() {
      const rows = [];
      rows.push([
        this.$t("Time"),
        this.$t("Timestamp"),
        this.$t("Machine"),
        this.$t("Part Number"),
        this.$t("Target"),
        this.$t("Produced")
      ]);
      for (const p of this.productionList) {
        for (const machine_part of listMachineParts(p.by_machine)) {
          rows.push([
            this.formatDate(p.key_as_string),
            p.key_as_string,
            machine_part && machine_part.machine
              ? machine_part.machine.machine_name
              : "unknown machine",
            machine_part && machine_part.part ? machine_part.part.part_number : "unknown part",
            Math.round(machine_part.production_target),
            machine_part.produced
          ]);
        }
      }
      this.createCSVFromRows(rows, `performance_${new Date().toISOString()}`);
    },
    expandMachines(element) {
      element.expanded = !element.expanded;
    },
    formatDate(date) {
      const { scale } = this.filters;
      let { dateFormat, scaleString } = this.getDateFormatter(scale);

      if (dateFormat == "week") {
        let start = moment(date);
        let end = moment(date).add(7, "d");
        if (start.month() != end.month()) {
          return start.format("D MMM") + " - " + end.format("D MMM");
        } else {
          return start.format("D") + " - " + end.format("D MMM");
        }
      } else {
        switch (scaleString) {
          case "1h":
            return (
              moment(date).format("D MMM hhA") + " - " + moment(date).add("hours", 1).format("hhA")
            );
            break;
          case "1d":
            return moment(date).format("D") + " - " + moment(date).add("days", 1).format("D MMM");
            break;
          case "1M":
            return (
              moment(date).format("D") + " - " + moment(date).endOf("month").format("D MMM YYYY")
            );
            break;
        }
      }
    },
    machineList(machine_list, abbreviate) {
      let list = [];
      for (const m of machine_list) {
        list.push(m.machine.machine_name);
      }
      list = list.sort();
      if (abbreviate && list.length > 1) {
        return `${list[0]} +${list.length - 1} more`;
      }

      const stringList = list.length > 0 ? list.join(", ") : "-";
      return stringList;
    },

    partNumberList(machine_list, abbreviate) {
      let list = [];
      for (const m of machine_list) {
        for (const p of m.by_part) {
          list.push(p.part.part_number);
        }
      }
      list = list.sort();
      if (abbreviate && list.length > 1) {
        return `${list[0]} +${list.length - 1} more`;
      }

      const stringList = list.length > 0 ? list.join(", ") : "-";
      return stringList;
    },
    getColor(actualValue, targetValue) {
      if (actualValue >= targetValue) {
        return this.$colors.chart.good;
      } else {
        return this.$colors.chart.bad;
      }
    }
  },
  watch: {
    productionList: function () {
      const list = [];
      this.productionList.forEach((p, i) => {
        const l = {
          time: this.formatDate(p.key_as_string),
          machine: this.machineList(p.by_machine, true),
          part_number: this.partNumberList(p.by_machine, true),
          target: this.numberWithCommas(Math.round(p.production_target.value)),
          produced: this.numberWithCommas(p.produced.value),
          _nested: []
        };
        this.listMachineParts(p.by_machine).forEach((machine_part, mi) => {
          l._nested.push({
            time: "",
            machine:
              machine_part.type === "label" || machine_part.number_parts == 1
                ? machine_part.machine.machine_name
                : "",
            part_number:
              machine_part.type == "label"
                ? machine_part.partsLabel
                : machine_part.part.part_number,
            target: this.numberWithCommas(Math.round(machine_part.production_target)),
            produced: this.numberWithCommas(machine_part.produced)
          });
        });

        list.push(l);
      });

      this.tableData = list;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/variables";

.machineTitleButton {
  white-space: nowrap;
}

.expand-icon {
  padding: 5px;
  transition: transform 0.25s;
  cursor: pointer;

  &.hidden {
    visibility: hidden;
  }
}

.production_table {
  tr {
    td,
    th {
      user-select: none !important;
      -webkit-user-select: none;
    }
  }
}

.rowMachine {
  background: $darkGrey;
  td {
    border-top: 1px rgba(255, 255, 255, 0.25) solid !important;
    border-bottom: 1px rgba(255, 255, 255, 0.25) solid !important;
  }
}

.rowPart {
  background: $darkGrey;
}

.rotated {
  transform: rotate(90deg);
}
</style>
