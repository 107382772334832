<template>
  <div class="card ai-vision-gallery">
    <v-dialog
      v-model="viewingImage"
      max-width="90vw"
      width="unset"
      content-class="ai-vision-gallery modal"
    >
      <v-card class="fill-card">
        <DialogCloseButton :onClick="() => (viewingImage = false)" />
        <v-card-text class="card-text">
          <div
            class="modal-image-container"
            :key="index"
          >
            <i
              class="fa fa-chevron-left"
              v-bind:class="`${index <= 0 && currentStart <= 0 && 'disabled'}`"
              @click="
                () => {
                  navigateImage(-1, index <= 0 && currentStart <= 0);
                }
              "
            />
            <div class="modal-image-content">
              <img
                v-if="documents[index] && documents[index].image_url"
                :src="documents[index].image_url"
                @load="imageLoaded"
                v-bind:style="{ visibility: imageLoading ? 'hidden' : null }"
              />
              <div
                v-if="imageLoading"
                class="loading-spinner"
              >
                <i class="mdi mdi-spin mdi-loading" />
              </div>
            </div>
            <i
              class="fa fa-chevron-right"
              v-bind:class="`${index + currentStart >= totalcount - 1 && 'disabled'}`"
              @click="
                () => {
                  navigateImage(1, index + currentStart >= totalcount - 1);
                }
              "
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="viewingImage = false"
          >
            {{ $t("Close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="main_content">
      <div class="content-switch">
        <i
          class="fa fa-table"
          v-bind:class="viewType === 'GALLERY' ? 'selected' : null"
          @click="viewType = 'GALLERY'"
        />
        <i
          class="fa fa-list"
          v-bind:class="viewType === 'LIST' ? 'selected' : null"
          @click="viewType = 'LIST'"
        />
      </div>

      <ai-vision-gallery-view
        v-if="viewType === 'GALLERY'"
        :documents="documents"
        :view-document-with-index="openImageWithIndex"
      />
      <ai-vision-list-view
        v-if="viewType === 'LIST'"
        :documents="documents"
        :filters="filters"
        :open-image="openImageWithIndex"
      />
    </div>
    <div class="footer">
      <div class="buttons">
        <v-btn
          color="primary"
          @click="createZipFile"
        >
          <div class="buttoncontent">
            <i class="fa fa-download" />
            <span v-if="zipDownloading && zipCreationPercent !== 100">
              {{ `${$t("Cancel Download ")} (${$t("Creating ")} ${zipCreationPercent}%)` }}
            </span>
            <span v-else-if="zipDownloading && zipCreationPercent === 100">
              <span>{{ $t("Downloading ") }}</span> <i class="mdi mdi-spin mdi-loading" />
            </span>
            <span v-else>
              {{ $t("Download Images") }}
            </span>
          </div>
        </v-btn>

        <v-btn
          color="primary"
          @click="createCSV"
        >
          <div class="buttoncontent">
            <i class="fa fa-download" />
            <span>
              {{ $t("CSV") }}
            </span>
          </div>
        </v-btn>
      </div>

      <div class="paginator">
        <div class="selector">
          <div class="rows-title">{{ $t("Rows per page") }}</div>
          <select @change="handleRequestCountChange">
            <option
              :selected="count === 10"
              value="10"
            >
              10
            </option>
            <option
              :selected="count === 50"
              value="50"
            >
              50
            </option>
            <option
              :selected="count === 100"
              value="100"
            >
              100
            </option>
          </select>
        </div>

        <div class="range-indicator">
          <div>
            <span>
              {{ currentStart !== null ? currentStart + 1 : "---" }}
            </span>
            <span class="divider"> - </span>
            <span>
              {{
                currentStart !== null ? Math.min(currentStart + Number(count), totalcount) : "---"
              }}
            </span>
            <span class="divider"> of {{ totalcount || "---" }} </span>
          </div>
        </div>

        <div class="back-next-select">
          <i
            class="fa fa-chevron-left"
            v-bind:class="`${this.currentStart <= 0 && 'disabled'}`"
            @click="
              () => {
                this.handlePage(-1);
              }
            "
          />
          <i
            class="fa fa-chevron-right"
            v-bind:class="`${this.currentStart + Number(count) >= totalcount && 'disabled'}`"
            @click="
              () => {
                this.handlePage(1);
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import DialogCloseButton from "@/components/DialogCloseButton";

import AiVisionGalleryView from "./AiVisionGalleryView";
import AiVisionListView from "./AiVisionListView";

export default {
  name: "AiVisionGallery",
  components: {
    AiVisionListView,
    AiVisionGalleryView,
    DialogCloseButton
  },
  props: [
    "setRequestCount",
    "count",
    "totalcount",
    "pagenumber",
    "currentStart",
    "handlePage",
    "documents",
    "createZipFile",
    "cancelZipDownload",
    "zipCreationPercent",
    "zipDownloading",
    "filters",
    "createCSV"
  ],
  data() {
    return {
      before: null,
      after: null,
      viewingImage: null,
      index: null,
      viewType: "GALLERY",
      imageLoading: true
    };
  },
  methods: {
    moment,
    openImageWithIndex(index) {
      const document = this.documents[index];
      this.imageLoading = true;
      this.$http
        .get("aivision/signedurl/", { params: { image: document._source.image_location } })
        .then((response) => {
          this.documents[index].image_url = response.data;
          this.viewingImage = true;
          this.index = index;
          this.imageLoading = false;
        });
    },
    imageLoaded(e) {
      e.target.style.maxWidth = "100%";
      e.target.style.maxHeight = "70vh";
    },
    handleRequestCountChange(e) {
      this.setRequestCount(parseInt(e.target.value));
    },
    navigateImage(direction, disabled) {
      const nextIndex = this.index + direction;
      if (nextIndex < 0 && this.currentStart > 0 && direction < 0) {
        this.handlePage(-1);
        this.index = this.count - 1;
      } else if (
        nextIndex >= 0 &&
        nextIndex < this.count &&
        nextIndex + this.currentStart < this.totalcount
      ) {
        this.index = nextIndex;
      } else if (nextIndex + this.currentStart < this.totalcount && direction > 0) {
        this.handlePage(1);
        this.index = 0;
      }

      if (!disabled) {
        this.openImageWithIndex(this.index);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/variables";

.ai-vision-gallery {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .fill-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .card-text {
      flex: 1;
    }
  }

  .modal-image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .loading-spinner {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-image-content {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 300px;
      min-height: 300px;
    }

    i {
      font-size: 40px;
      flex: unset;
      width: 40px;
      margin: 10px;
      color: $blue;
      cursor: pointer;

      &.disabled {
        opacity: 50%;
        cursor: not-allowed;
      }
    }
  }

  .main_content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .content-switch {
      height: 30px;
      flex: unset;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        padding: 5px;
        cursor: pointer;

        &.selected {
          color: $blue;
        }
      }
    }
  }
  .footer {
    flex: unset;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .buttoncontent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        padding-right: 10px;
      }
    }

    .paginator {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      flex-direction: row;
      flex: unset;
      width: 400px;
      height: 30px;
      align-items: center;

      .selector {
        display: flex;
        align-items: center;

        .rows-title {
          padding-right: 5px;
        }

        select {
          color: white;
          text-decoration: underline;

          .theme--light & {
            color: black;
          }

          option {
            color: black;
          }
        }
      }

      .range-indicator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;

        select {
          color: white;
          text-decoration: underline;

          .theme--light & {
            color: black;
          }

          option {
            color: black;
          }
        }

        .divider {
          padding: 0 5px;
        }
      }

      .back-next-select {
        display: flex;
        flex: unset;
        min-width: 60px;
        justify-content: space-between;
        align-items: center;

        i {
          cursor: pointer;

          &.disabled {
            opacity: 50%;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
