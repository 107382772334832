import axios from "axios";

export const helpCenterApi = {
  loadVideos: (machine_pk, search) => {
    const query = `query($machine: Int, $search: String){
        helpDocs(machine: $machine, search: $search, video: true){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;

    const variables = {};

    if (machine_pk) {
      variables["machine"] = machine_pk;
    }

    if (search) {
      variables["search"] = search;
    }

    return axios
      .post("graphql/", { query, variables })
      .then((res) => res.data.data.helpDocs)
      .catch((res) => {
        this.errors = res.errors;
      });
  },

  loadDocs(machine_pk, search) {
    const query = `query($machine: Int, $search: String){
        helpDocs(machine: $machine, search: $search){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;

    const variables = {};

    if (machine_pk) {
      variables["machine"] = machine_pk;
    }

    if (search) {
      variables["search"] = search;
    }

    return axios
      .post("graphql/", { query, variables })
      .then((res) => res.data.data.helpDocs)
      .catch((res) => {
        this.errors = res.errors;
      });
  },

  loadAudits({ machine_pk, search, itemsPerPage }) {
    const query = `query(
          $machine: Int,
          $search: String
          $first: Int,
          $last: Int,
          $after: String,
          $before: String
      ){
        auditTypes(
          machine: $machine,
          search: $search,
          first: $first,
          last: $last,
          after: $after,
          before: $before
        ){
          edges{
            node{
              id,
              name,
              description,
              latestVersion{
                version,
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
          }
          totalCount
        }
      }`;

    const variables = {
      before: false,
      after: false
    };

    variables["first"] = itemsPerPage;

    if (machine_pk) {
      variables["machine"] = machine_pk;
    }

    if (search) {
      variables["search"] = search;
    }

    return axios
      .post("graphql/", { query, variables })
      .then((res) => {
        return res.data.data.auditTypes;
      })
      .catch((res) => {
        this.errors = res.errors;
      });
  }
};
