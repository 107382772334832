import { computed } from "@vue/composition-api";

const localStorageToogleName = "redirectToogle";

export const useRedirects = () => {
  const isRedirectToogleEnabled = () => {
    return computed(() => localStorage.getItem(localStorageToogleName) === "true");
  };

  const updateRedirectToogle = () => {
    const reversedToogle = !isRedirectToogleEnabled().value;
    localStorage.setItem(localStorageToogleName, reversedToogle.toString());
  };

  return {
    isRedirectToogleEnabled,
    updateRedirectToogle
  };
};
