export const listMachineParts = (machines) => {
  const flatMap = [];
  let machines_copy = [...machines];
  const collator = new Intl.Collator([], { numeric: true });

  machines_copy.sort((a, b) => collator.compare(a.machine.machine_name, b.machine.machine_name));

  for (const m of machines_copy) {
    const mcopy = { ...m };
    //delete the part list so we don't
    //have a circular reference
    delete mcopy.by_part;

    const byPart_copy = [...m.by_part];

    byPart_copy.sort((a, b) => collator.compare(a.part.part_number, b.part.part_number));
    for (const p of byPart_copy) {
      const pcopy = { ...p, machine: { ...mcopy.machine } };
      pcopy.type = "part";
      pcopy.number_parts = m.by_part.length;
      flatMap.push(pcopy);
    }
  }
  return flatMap;
};

export const partNumberListByMachine = (part_list, abbreviate) => {
  let list = [];
  for (const p of part_list) {
    list.push(p.part.part_number);
  }
  list = list.sort();
  if (abbreviate && list.length > 1) {
    return `${list[0]} +${list.length - 1} more`;
  }

  const stringList = list.length > 0 ? list.join(", ") : "-";
  return stringList;
};

export const listHasPartsOrMachines = (row) => {
  if (row.by_machine.length > 1) return true;
  for (const m of row.by_machine) {
    if (m.by_part.length > 1) return true;
  }
  return false;
};
