<template>
  <div class="card top-parts">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Top Impacted Part Numbers") }}</span>
      <div class="chart-icons">
        <v-icon
          class="bar-chart-icon"
          :color="showBarChart ? 'primary' : null"
          @click="handleShowBarChart"
        >
          mdi-chart-bar
        </v-icon>
        <v-icon
          class="tree-map-icon"
          :color="showTreeMap ? 'primary' : null"
          @click="handleShowTreeMap"
        >
          mdi-chart-tree
        </v-icon>
      </div>
    </div>
    <div class="analytics-card-header">
      <AnalyticsPagination :handleTabChange="handleTabChange" />
    </div>
    <div class="analytics-card-content">
      <TreeMapChart
        v-if="showTreeMap"
        class="tree-map"
        :data="partsTreeMapData"
        :id="'parts-tree-map'"
        :handleClick="(event) => handleTreeMapClick(event)"
      />
      <BarChart
        v-if="showBarChart"
        class="bar-chart"
        :chartData="partsBarChartData"
        :axisType="AXISTYPE.SHORTEN"
        :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
      />
    </div>
  </div>
</template>
<script>
import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import TreeMapChart from "@/components/charts/TreeMapChart";
import { macawBlueGreen } from "@/scss/_variables.scss";
import { getDatasets, handleBackgroundColorsNoOthersPresent } from "@/utils/LDMSAnalyticsUtils";
import { hexToRGB } from "@/utils/colors";

export default {
  name: "LDMSAnalyticsParts",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} }
  },
  components: {
    AnalyticsPagination,
    TreeMapChart,
    BarChart
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      showTreeMap: true,
      showBarChart: false,
      partsTreeMapData: [],
      partsBarChartData: {},
      partIds: [],
      pageSize: 10
    };
  },
  created() {
    this.getTopPartsData();
  },
  methods: {
    handleShowBarChart() {
      this.showTreeMap = false;
      this.showBarChart = true;
    },
    handleShowTreeMap() {
      this.showBarChart = false;
      this.showTreeMap = true;
    },
    updateBackgroundColors() {
      this.partsBarChartData.datasets[0].backgroundColor = handleBackgroundColorsNoOthersPresent(
        this.partsBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.parts
      );
      const treeMapColors = this.partsTreeMapData.map((data) => data.color);
      const newTreeMapColors = handleBackgroundColorsNoOthersPresent(
        treeMapColors,
        this.interactiveFilters.parts
      );
      this.partsTreeMapData = this.partsTreeMapData.map((data, index) => ({
        ...data,
        color: newTreeMapColors[index]
      }));
    },
    async handleTabChange(pageSize) {
      this.pageSize = pageSize;
      await this.getTopPartsData();
      if (this.interactiveFilters.parts.length > 0) {
        this.updateBackgroundColors();
      }
    },
    handleTreeMapClick(event) {
      const elementIndex = event.target._dataItem._index;
      this.setInteractiveFilter(elementIndex);
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilter(elementIndex) {
      const partId = this.partIds[elementIndex];
      const foundPartIndex = this.interactiveFilters.parts.findIndex((part) => part.id === partId);
      if (foundPartIndex >= 0) {
        this.interactiveFilters.parts.splice(foundPartIndex, 1);
      } else {
        this.interactiveFilters.parts.push({ id: partId, index: elementIndex });
      }
      this.updateBackgroundColors();
    },
    async getTopPartsData() {
      const machines = this.interactiveFilters.machines.map((machine) => machine.id);
      const reasons = this.interactiveFilters.reasons.map((reason) => reason.id);
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_group: this.filters.machineGroups,
        machine_ids: machines.length > 0 ? machines : this.filters.machines,
        part_numbers: this.filters.parts,
        reason_ids: reasons.length > 0 ? reasons : this.filters.reasons
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/top_part_number/", { params });
        if (response && response.data) {
          this.partIds = response.data.map((part) => part.part_id);
          const colors = response.data.map((part) => hexToRGB(macawBlueGreen, "0.9"));
          this.partsTreeMapData = response.data.map((part, index) => {
            return {
              name: part.part_name,
              value: part.number_of_issues,
              color: colors[index]
            };
          });
          this.partsBarChartData = {
            labels: response.data.map((part) => part.part_name),
            datasets: getDatasets(response.data, colors)
          };
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
<style lang="scss"></style>
