<template>
  <div class="card ticket-table">
    <div class="title-container">
      <span class="header-text">{{ $t("P911 Tickets") }}</span>
    </div>
    <div class="generic-table-container">
      <GenericTable
        :columns="ticketTableHeaders"
        :data="slicedTicketData"
        :height="tableHeight"
      />
      <v-data-footer
        :options="footerOptions"
        :items-per-page-options="[10, 20, 50, 100]"
        :pagination="paginationOptions"
        @update:options="updateOptions"
      >
      </v-data-footer>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";

export default {
  name: "P911TicketTable",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    helpTags: { type: Array, default: () => [] },
    filterChange: { type: Boolean, default: false }
  },
  components: {
    GenericTable
  },
  data() {
    return {
      ticketTableHeaders: [
        {
          title: this.$t("Open Date"),
          key: "ticketedDate",
          sortable: true,
          clickHandler: this.goToTicket,
          format: (ticketedDate) =>
            ticketedDate ? moment(ticketedDate).format(this.$datetime.default) : "",
          cssClass: "open-date"
        },
        {
          title: this.$t("Closed Date"),
          key: "closedDate",
          sortable: true,
          clickHandler: this.goToTicket,
          format: (closedDate) =>
            closedDate ? moment(closedDate).format(this.$datetime.default) : "",
          cssClass: "closed-date"
        },
        {
          title: this.$t("Machine"),
          key: "machine",
          sortable: true,
          clickHandler: this.goToTicket
        },
        {
          title: this.$t("Help Tags"),
          key: "helpTags",
          html: "tagHtml",
          clickHandler: this.goToTicket
        },
        { title: this.$t("Details"), key: "details", sortable: true, clickHandler: this.goToTicket }
      ],
      ticketData: [],
      slicedTicketData: [],
      tableHeight: 0,
      selectedPage: 1,
      selectedItemsPerPage: 20
    };
  },
  created() {
    this.tableHeight = 652 - parseInt(getComputedStyle(document.documentElement).fontSize) * 3.5;
  },
  computed: {
    ...mapGetters({
      machineFromPk: "dbCache/machineFromPk"
    }),
    filterHasChanged() {
      return this.filterChange;
    },
    footerOptions() {
      return {
        page: this.selectedPage,
        itemsPerPage: this.selectedItemsPerPage
      };
    },
    paginationOptions() {
      return {
        page: this.selectedPage,
        itemsPerPage: this.selectedItemsPerPage,
        itemsLength: this.ticketData.length,
        pageStart:
          this.selectedPage === 1 ? 0 : (this.selectedPage - 1) * this.selectedItemsPerPage,
        pageStop: this.selectedPage * this.selectedItemsPerPage,
        pageCount: Math.ceil(this.ticketData.length / this.selectedItemsPerPage) || 0
      };
    }
  },
  methods: {
    updateOptions(options) {
      this.selectedPage = options.page;
      this.selectedItemsPerPage = options.itemsPerPage;
      this.slicedTicketData = this.ticketData.slice(
        this.selectedPage === 1 ? 0 : (this.selectedPage - 1) * this.selectedItemsPerPage,
        this.selectedPage * this.selectedItemsPerPage
      );
    },
    goToTicket(item) {
      const routeData = this.$router.resolve({
        name: "help_ticket",
        params: { ticket_id: item.id }
      });
      window.open(routeData.href, "_blank");
    },
    getTagSlot(tags) {
      const parentDiv = '<div class="tags">';
      let htmlString = "";
      if (this.helpTags) {
        tags.forEach((tag) => {
          const foundTag = this.helpTags.find((helpTag) => helpTag.id === tag);
          if (foundTag) {
            htmlString += `<div class='tag-chip' style='background-color: ${foundTag.color}'>${foundTag.name}</div>`;
          }
        });
      }
      return parentDiv + htmlString + "</div>";
    },
    async getHelpTicketsData() {
      const query = `query (
        $filters: GrapheneElasticFilterHelpTicketSearchConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingHelpTicketSearchConnectionBackendFilter!,
      ) {
        helpTickets (
          filter: $filters,
          ordering: $ordering,
        ) {
          edges {
            node {
              id,
              ticketedDate,
              closedDate,
              machineId,
              machineName,
              partName,
              tagSearch,
              tagLevel1,
              tagLevel2,
              tagLevel3,
              tagLevel4,
              tagLevel5,
              details,
            }
          }
        }
      }`;
      const variables = {
        filters: {
          ticketedDate: {
            range: {
              lower: { datetime: this.filters.fromDate },
              upper: { datetime: this.filters.toDate }
            }
          }
        },
        ordering: { ticketedDate: "ASC" }
      };
      if (this.filters.machines && this.filters.machines.length > 0) {
        variables["filters"]["machineId"] = {
          in: this.filters.machines.map((machine) => machine.pk)
        };
      }
      if (this.interactiveFilters.machines.length > 0) {
        variables["filters"]["machineId"] = {
          in: this.interactiveFilters.machines.map((machine) => machine.id)
        };
      }
      if (this.interactiveFilters.helpTag) {
        const helpTag = this.interactiveFilters.helpTag;
        variables.filters[[Object.keys(helpTag)[0]]] = { value: Object.values(helpTag)[0] };
      }
      try {
        const response = await this.$http.post("graphql/", { query, variables });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.helpTickets &&
          response.data.data.helpTickets.edges
        ) {
          const data = response.data.data.helpTickets.edges.map((issue) => {
            const foundMachine = this.machineFromPk(parseInt(issue.node.machineId, 10));
            const issueData = {
              ...issue.node,
              closedDate: issue.node.closedDate ? moment(issue.node.closedDate).valueOf() : 0,
              machine: foundMachine
                ? foundMachine.name
                : issue.node.machineName
                  ? issue.node.machineName
                  : `${$t("Unknown Machine")} ${issue.node.machineId}`,
              part: issue.node.partName,
              details: issue.node.details
            };
            issueData.helpTags = [];
            this.getTagLevels(issueData, 1);
            issueData.tagHtml = this.getTagSlot([...issueData.helpTags]);
            return issueData;
          });
          this.ticketData = data;
          this.slicedTicketData = this.ticketData.slice(0, this.selectedItemsPerPage);
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    },
    getTagLevels(data, level) {
      if (`tagLevel${level}` in data && data[`tagLevel${level}`] !== null) {
        data.helpTags.push(data[`tagLevel${level}`]);
      }
      if (`tagLevel${level + 1}` in data) {
        this.getTagLevels(data, level + 1);
      }
    }
  },
  watch: {
    "interactiveFilters.machines.length": function () {
      if (!this.filterHasChanged) {
        this.getHelpTicketsData();
      }
    },
    "interactiveFilters.helpTag": function () {
      this.getHelpTicketsData();
    }
  }
};
</script>
