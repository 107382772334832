<template>
  <div class="level2">
    <Level2Filter :filter-controller="filterController"></Level2Filter>
    <Transition
      name="scroll-x-transition"
      mode="out-in"
    >
      <Level2Table
        v-if="filterController.view === 'data_table'"
        :filter-controller="filterController"
      ></Level2Table>
      <Level2Timeline
        v-if="filterController.view === 'timeline'"
        :filter-controller="filterController"
      ></Level2Timeline>
      <Level2AnalyticsGrid
        v-else-if="filterController.view === 'analytics'"
        :filter-controller="filterController"
        :interactiveFilters="interactiveFilters"
      >
      </Level2AnalyticsGrid>
    </Transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { AnalyticsFilterController } from "../../../components/analytics_filters/AnalyticsFilterController";
import Level2AnalyticsGrid from "./Level2AnalyticsGrid.vue";
import Level2Filter from "./Level2Filters.vue";
import Level2Table from "./Level2Table.vue";
import Level2Timeline from "./Level2Timeline.vue";

export default {
  components: {
    Level2Filter,
    Level2Table,
    Level2AnalyticsGrid,
    Level2Timeline
  },
  data: () => {
    return {
      filterController: new AnalyticsFilterController(),
      interactiveFilters: { codes: [], machines: [], trends: [] }
    };
  },
  created() {
    this.setTitles({
      title: this.$t("Level 2 Analytics"),
      mobile: this.$t("Level 2 Analytics")
    });
    this.filterController.setupInitialFilters([
      "date_range",
      "scale",
      "machine_groups",
      "machines",
      "machine",
      "parts",
      "code",
      "codeEventType"
    ]);
    this.filterController.setupDrilldownFilters(["machines"]);
    this.filterController.drillDownFilters["codes"] = [];
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    applyFilters(filters) {
      this.filters = filters;
    },
    clearInteractiveFilters() {
      this.interactiveFilters = { codes: [], machines: [], trends: [] };
    }
  },
  watch: {
    "filterController.reload": function () {
      this.clearInteractiveFilters();
    }
  }
};
</script>

<style lang="scss">
.level2 {
  height: calc(100% - 20px);
  margin: 0 10px;
  display: flex;
}
</style>
