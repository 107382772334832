var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"machineSummaryTable"},[_c('div',{staticClass:"headerContainer"},[_c('div',{staticClass:"datesbox"},[_c('div',{staticClass:"shift_performance blue-title"},[(_vm.fromDate && _vm.toDate && _vm.calendarCase > 2)?_c('span',[_c('h4',[_vm._v(_vm._s(_vm.fromDate && _vm.fromDate.format("MMMM DD, YYYY"))+" -")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.toDate && _vm.toDate.format("MMMM DD, YYYY")))])]):(_vm.fromDate && _vm.toDate && _vm.calendarCase == 0)?_c('span',[(_vm.currentShift)?_c('h4',[_vm._v(_vm._s(_vm.currentShift.name))]):_vm._e(),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.fromDate && _vm.fromDate.format("MMMM DD, YYYY h:mma"))+" -")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.toDate && _vm.toDate.format("MMMM DD, YYYY h:mma")))])]):(_vm.fromDate && _vm.toDate)?_c('span',[_c('h4',[_vm._v(_vm._s(_vm.fromDate && _vm.fromDate.format("MMMM DD, YYYY")))])]):_c('span',[_c('h4',[_vm._v(_vm._s(_vm.$t("No Shift Data")))])])])]),_vm._v(" "),_c('div',{staticClass:"box intervalSelection"},[_c('v-tabs',{attrs:{"fixed-tabs":"","value":_vm.calendarCase},on:{"change":_vm.ChangeCalendar}},[_c('v-tab',[(_vm.shifts && _vm.shifts.length > 1)?[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding":"0"},attrs:{"plain":""}},'v-btn',attrs,false),on),[_vm._v("\n                  "+_vm._s(_vm.$t("Shift"))+"\n                  "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1613840240)},[_vm._v(" "),_c('v-list',_vm._l((_vm.shifts),function(shift){return _c('v-list-item',{key:shift.id,on:{"click":function($event){return _vm.selectShift(shift)}}},[_c('v-list-item-title',[_vm._v(_vm._s(shift.name))])],1)}),1)],1)]:[_vm._v("\n            "+_vm._s(_vm.$t("Shift"))+"\n          ")]],2),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.productionDay.clone().subtract(1, "day").format("MMM DD")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.productionDay.clone().format("MMM DD")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Week")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Month")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Year")))])],1)],1)]),_vm._v(" "),(!_vm.machinesLoaded)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_c('data-table',{attrs:{"item-key":"equipment","headers":_vm.tableHeaders,"items":_vm.filteredMachines,"slots":['status'],"show-expand":true,"single-expand":true,"itemExpanded":_vm.itemExpanded,"expanded":_vm.expanded},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"py-1 px-2 rounded text-no-wrap text--primary",class:("" + (item.statusColorSet))},[_vm._v("\n        "+_vm._s(item.status)+"\n      ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.seconds(item.duration))+"\n    ")]}},{key:"item.run_time",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.seconds(item.run_time))+"\n    ")]}},{key:"item.produced",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.numberWithCommas(item.produced))+"\n    ")]}},{key:"item.scrapped",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.numberWithCommas(item.scrapped))+"\n    ")]}},{key:"item.downtime",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.seconds(item.downtime) || "--")+"\n    ")]}},{key:"item.performance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance)+"%")]}},{key:"item.quality",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quality)+"%")]}},{key:"item.availability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.availability)+"%")]}},{key:"item.oee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.oee)+"%")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner_table_class",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"nestedTable"},[(_vm.loading_detail)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_c('data-table',{staticClass:"innerHeader",attrs:{"headers":_vm.nestedHeaders,"items":_vm.machineDetailsTable,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"py-1 px-2 rounded text-no-wrap text--primary",class:("" + (item.statusColorSet))},[_vm._v("\n                "+_vm._s(item.status)+"\n              ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.seconds(item.duration))+"\n            ")]}},{key:"item.run_time",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.seconds(item.run_time))+"\n            ")]}},{key:"item.produced",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.numberWithCommas(item.produced))+"\n            ")]}},{key:"item.scrapped",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.numberWithCommas(item.scrapped))+"\n            ")]}},{key:"item.downtime",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.seconds(item.downtime) || "--")+"\n            ")]}},{key:"item.performance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance)+"%")]}},{key:"item.quality",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quality)+"%")]}},{key:"item.availability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.availability)+"%")]}},{key:"item.oee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.oee)+"%")]}}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }