import { render, staticRenderFns } from "./RadialFab.vue?vue&type=template&id=54351232&"
import script from "./RadialFab.vue?vue&type=script&lang=js&"
export * from "./RadialFab.vue?vue&type=script&lang=js&"
import style0 from "./RadialFab.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports