<template>
  <div
    class="problem-solving"
    key="ps"
    v-bind:class="mobileTabIndex == 2 && 'tabSelected'"
  >
    <h2>{{ $t("Problem Solving") }}</h2>
    <div class="issueTables">
      <div
        class="card"
        v-for="(issueTable, tableIndex) in issueTables"
      >
        <div class="card-header">
          <h3>{{ $t(issueTable.title) }}</h3>
          <div class="headerBtns">
            <zone-issue
              :machine_group_id="machine_group_id"
              :machine_group_pk="machineGroupPk"
              v-if="tableIndex == 0"
              @submitted="(...args) => addIssue(issueTable.issues, ...args)"
            />
            <v-btn
              class="expand"
              small
              light
              color="white"
              fab
              @click.stop.prevent="setEnlargedTable({ ...issueTable })"
            >
              <i
                class="fa fa-expand"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </div>
        <div class="card-content">
          <div class="values">
            <div class="item">
              <p class="label">{{ $t("Total Open") }}</p>
              <p>{{ issueTable.total }}</p>
            </div>
            <div class="item">
              <p class="label">{{ $t("In Process") }}</p>
              <p>{{ getInprocess(issueTable.issues) }}</p>
            </div>
            <div class="item">
              <p class="label">{{ $t("Latest") }}</p>
              <p>{{ getLatest(issueTable.issues) }}</p>
            </div>
            <div class="item">
              <p class="label">{{ $t("Oldest") }}</p>
              <p>{{ getOldest(issueTable.issues) }}</p>
            </div>
          </div>
          <v-data-table
            :headers="issueHeaders"
            item-key="key_as_string"
            :items="issueTable.issues"
            :loading="issueTable.loading"
            :page.sync="issueTable.current"
            :itemsPerPage.sync="issueTable.itemsPerPage"
            :serverItemsLength="issueTable.total"
            :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
            @pagination="(...args) => loadIssuePage(issueTable, ...args)"
            @update:items-per-page="handleItemsPerPageChange($event, issueTable)"
            disable-pagination
            fixed-header
            multi-sort
          >
            <template v-slot:item.status="{ item }">
              <i
                class="harveyBall mdi"
                :class="getStatusIcon(item)"
                aria-hidden="true"
              ></i>
            </template>
            <template v-slot:item.relation="{ item }">
              <p>
                {{ item.node.machineName ? item.node.machineName : item.node.machineGroupName }}
              </p>
            </template>
            <template v-slot:item.reason="{ item }">
              <p>
                {{ item.node.reason.substring(0, 20)
                }}{{ item.node.reason.length > 20 ? "..." : "" }}
              </p>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZoneIssue from "@/components/dialogs/ZoneIssue";

export default {
  name: "ProblemSolving",
  props: {
    mobileTabIndex: { type: Number, default: 0 },
    machine_group_id: { type: String, default: "" },
    machineGroupPk: { type: Number },
    enlargedTable: { type: Object, default: () => {} },
    issueTables: { type: Array, default: () => [] },
    issueHeaders: { type: Array, default: () => [] },
    setEnlargedTable: { type: Function, default: () => null },
    loadIssuePage: { type: Function, default: () => null },
    handleItemsPerPageChange: { type: Function, default: () => null },
    getStatusIcon: { type: Function, default: () => null },
    getInprocess: { type: Function, default: () => null },
    getLatest: { type: Function, default: () => null },
    getOldest: { type: Function, default: () => null },
    addIssue: { type: Function, default: () => null }
  },
  components: {
    ZoneIssue
  }
};
</script>
