<template>
  <div class="audits-center">
    <div
      class="overlay"
      @click="close()"
      :class="{ show: rightPanel == 'audits' }"
    ></div>
    <div class="card">
      <div class="card-header">
        <h2>{{ $t("Audits") }}</h2>
        <div class="options">
          <i
            class="fa fa-times"
            @click="close()"
          />
        </div>
      </div>
      <div class="card-content">
        <v-text-field
          v-model="searchAudits"
          prepend-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <v-data-iterator
          class="tickets-wrapper"
          :items="iteratorData.data"
          :itemsPerPage.sync="iteratorData.itemsPerPage"
          :page.sync="iteratorData.page.current"
          :serverItemsLength="iteratorData.total"
          no-data-text=""
          :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
        >
          <template v-slot:default="props">
            <transition-group
              :class="'audits'"
              name="slide-fade-list"
              mode="out-in"
              tag="div"
            >
              <div
                class="card audit"
                v-for="(audit, index) in props.items"
                :key="audit.node.id"
                @click="gotToAudit(audit.node.id)"
              >
                <div class="card-header">
                  <p class="label">
                    {{ audit.node.name }} -
                    {{ audit.node.latestVersion ? audit.node.latestVersion.version : " " }}
                  </p>
                </div>
                <div class="card-content">
                  <p class="label">{{ audit.node.description }}</p>
                </div>
              </div>
            </transition-group>
          </template>
        </v-data-iterator>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.audits-center {
  width: $rightPanelWidth;
  margin: 1rem 0;
  margin-top: 0.5rem;
  display: block;
  .card {
    z-index: 1000;
    height: 100%;
    overflow: hidden;

    .card-content {
      position: relative;
      &::after {
        box-shadow: none;
      }
    }
    .audit-button {
      position: relative;
      margin: 1rem 0;
      margin-top: 1rem;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .audits {
    overflow-y: auto;
  }
  .audit {
    min-height: 3rem;
    margin: 1rem 0.25rem;
    padding-bottom: 1rem;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: $blue !important;
      color: white;
    }
  }
  .v-data-footer {
    z-index: 9;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $cardColor;
    flex-wrap: nowrap;
  }
}
.theme--light.v-application {
  .audits-center {
    .v-data-footer {
      background: $light-cardColor;
    }
  }
}
</style>

<script>
// On machine dashboard:
// -Pull up audits drawer on right
// -Pull up audits related this particular machine
// -Audit-type name and audit version , and include
//a link from the drawer to the audit to be able to fill one out
// ***tricky: query to recieve those audit types, but we should
//we able to adapt queries for retrieving audit types in
//audit type. We just need to include a machine filter
// - Under audittypes machine filter in the graphql
// - Pass a machine id
// -Then display name, current version, and part of
//description and link to the audit using the green audit button
//auditType.name
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import { TableData } from "@/datatypes/table";

export default {
  props: {
    // filters:{
    //   default: "machine: 0"
    // }
  },
  data() {
    return {
      auditHeaders: [
        { text: this.$t("Name"), value: "name" },
        { text: this.$t("Version"), value: "currentVersion" },
        { text: this.$t("Description"), value: "description" },
        { text: this.$t("Perform Audit"), value: "perform" }
      ],
      audits: [],
      selectedAudit: false,
      showDialog: false,
      searchAudits: "",
      searchDebouncer: false,
      includeFKs: {
        machine: true
      },
      iteratorData: new TableData([])
    };
  },
  created() {
    this.loadAudits();
  },
  components: {},
  computed: {
    ...mapGetters({
      rightPanel: "components/RightPanel",
      user: "session/User",
      options: "components/HelpCenterOptions"
    })
  },
  methods: {
    moment,
    ...mapActions({
      setRightPanel: "components/SetRightPanel"
    }),
    close() {
      this.setRightPanel(false);
    },
    loadAudits() {
      //TODO switch to globalid
      const query = `query(
          $machine: Int,
          $search: String
          $first: Int,
          $last: Int,
          $after: String,
          $before: String
      ){
        auditTypes(
          machine: $machine,
          search: $search,
          first: $first,
          last: $last,
          after: $after,
          before: $before
        ){
          edges{
            node{
              id,
              name,
              description,
              latestVersion{
                version,
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
          }
          totalCount
        }
      }`;
      const variables = {
        before: false,
        after: false
      };
      if (this.iteratorData.page.load) {
        Object.assign(variables, this.iteratorData.page.load);
      } else {
        variables["first"] = this.iteratorData.page.itemsPerPage;
      }
      if ("machine_pk" in this.$route.params) {
        variables["machine"] = this.$route.params.machine_pk;
      }
      if (this.searchAudits) {
        variables["search"] = this.searchAudits;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.iteratorData.data = res.data.data.auditTypes.edges;
          this.iteratorData.total = res.data.data.auditTypes.totalCount;
          this.iteratorData.page.next = res.data.data.auditTypes.pageInfo.endCursor;
          this.iteratorData.page.prev = res.data.data.auditTypes.pageInfo.startCursor;
          this.iteratorData.page.load = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    gotToAudit(id) {
      this.$router.push({
        name: "audit-form",
        params: { auditTypeId: id, machine_id: this.$route.params.machine_id }
      });
      this.close();
    },
    selectAudit(audit) {
      this.selectedAudit = audit;
    }
  },
  watch: {
    $route(to, from) {
      this.loadAudits();
    },
    searchAudits: function () {
      if (this.searchDebouncer) {
        clearTimeout(this.searchDebouncer);
      }
      let load = this.loadAudits;
      this.searchDebouncer = setTimeout(load, 500);
    },
    "iteratorData.page.itemsPerPage": function () {
      this.loadAudits();
    },
    "iteratorData.page.current": function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.iteratorData.page.load = {
          after: this.iteratorData.page.next,
          before: false,
          first: this.iteratorData.page.itemsPerPage
        };
        this.loadAudits();
      } else if (newPage < oldPage) {
        this.iteratorData.page.load = {
          after: false,
          before: this.iteratorData.page.prev,
          last: this.iteratorData.page.itemsPerPage
        };
        this.loadAudits();
      }
    }
  }
};
</script>
