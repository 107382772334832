<template>
  <div class="production-chart metric-component">
    <h2 class="card-title">{{ $t("Runtime") }}</h2>
    <v-progress-linear
      indeterminate
      v-if="!(production && production.length > 0)"
    ></v-progress-linear>
    <div
      class="charts"
      v-else
    >
      <div class="gauge-container">
        <v-gauge
          v-if="perf"
          gaugeValueClass="gaugeValue"
          :value="perf"
          :donut="true"
          :options="gaugeOpts"
        />
      </div>
      <div class="charts_container">
        <div class="chart">
          <bar-chart
            v-if="chartData"
            chartName="runtimeChart"
            class="runtimeChart"
            uom="%"
            :decimal="2"
            :chartData="chartData"
            :chartOptions="chartOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VGauge from "vgauge";

import BarChart from "@/components/charts/BarChart.js";
import { seconds } from "@/utils/filters";

export default {
  name: "RuntimeQuantity",
  props: ["options", "production", "interactiveFilters"],
  components: {
    BarChart,
    VGauge
  },
  data() {
    return {
      loaded: false,
      chartData: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      },
      perf: false
    };
  },
  computed: {
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.formatData();
    }
  },
  methods: {
    formatData() {
      let percentage = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let total_runtime = 0;
      let total_time = 0;
      this.production.forEach((interval) => {
        let runtime = interval.runtime.duration.value;
        let interval_time = interval.interval_duration.value;
        let runtime_percentage = interval.runtime.duration.value / interval.interval_duration.value;
        percentage.push(runtime_percentage * 100);
        gradients.push(this.$colors.chart.good_gradient);
        footers.push([
          this.$t("Runtime") + " : " + seconds(runtime),
          this.$t("Interval") + " : " + seconds(interval_time)
        ]);
        labels.push(this.options.labelFromString(interval.key_as_string));
        titles.push(this.options.labelFromToString(interval.key_as_string));

        total_runtime += runtime;
        total_time += interval_time;
      });
      this.perf = (total_runtime / total_time) * 100;
      this.chartData = {
        datasets: [
          {
            data: percentage,
            gradients: gradients,
            titles: titles,
            footers: footers,
            label: this.$t("Runtime")
          }
        ],
        labels: labels
      };
    }
  },
  watch: {
    runtime: {
      deep: true,
      handler: "formatData"
    },
    interactiveFilters: {
      handler: "formatData"
    }
  }
};
</script>
<style lang="scss">
.production-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 18vh;
  .charts {
    display: flex;
    justify-content: center;
    align-items: center;
    .charts_container {
      width: calc(100% - 150px);
      .runtimeChart {
        width: 100%;
        height: 100%;
        padding-left: 0.5rem;
        display: grid;
      }
    }
  }
}
</style>
