<template>
  <div>
    <p class="title font-weight-bold">{{ $t(name) }}</p>
    <p class="body-1">{{ value }}</p>
  </div>
</template>
<script>
export default {
  name: "ModelOptimizationAboutField",
  props: ["name", "value"]
};
</script>
<style lang="scss" scoped></style>
