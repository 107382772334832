import moment from "moment";

class chartOptions {
  constructor(machine_id = null, machine_group_id = null) {
    this.machine_id = machine_id;
    this.machine_group_id = machine_group_id;
    this.machine = null;
    this.machine_group = null;
    this.scale = "1h";
    this.preset = "day";
    this.dateFormat = "D MMM hhA";
    this.from_date = moment().startOf("hour").subtract(23, "h");
    this.to_date = moment().endOf("hour");
    this.selectedOptions = {
      from_date: this.from_date.toDate(),
      to_date: this.to_date.toDate()
    };
    this.targets = {
      perf: 0,
      quality: 0,
      runtime: 0,
      oee: 0
    };
    this.settings = {
      scales: {
        scaleLineColor: "transparent",
        yAxes: [{ display: false }]
      },
      tooltips: {
        callbacks: {
          title: this.getTitles
        }
      }
    };
  }
  get params() {
    let params = {
      from_date: this.from_date.toISOString(),
      to_date: this.to_date.toISOString(),
      scale: this.scale
    };
    if (this.machine_id) {
      params["machine_id"] = this.machine_id;
    }
    if (this.machine_group_id) {
      params["machine_group_id"] = this.machine_group_id;
    }
    if (this.machine) {
      params["machine"] = this.machine;
    }
    if (this.machine_group) {
      params["machine_group"] = this.machine_group;
    }

    return params;
  }
  labelFromString(timeString) {
    if (this.dateFormat == "week") {
      let start = moment(timeString);
      let end = moment(timeString).add(7, "d");
      if (start.month() != end.month()) {
        return start.format("D MMM") + " - " + end.format("D MMM");
      } else {
        return start.format("D") + " - " + end.format("D MMM");
      }
    } else {
      return moment(timeString).format(this.dateFormat);
    }
  }
  getTitles(item, data) {
    return data.datasets[item[0].datasetIndex].titles[item[0].index];
  }
  labelFromToString(timeString) {
    if (this.dateFormat == "week") {
      let start = moment(timeString);
      let end = moment(timeString).add(7, "d");
      if (start.month() != end.month()) {
        return start.format("D MMM") + " - " + end.format("D MMM");
      } else {
        return start.format("D") + " - " + end.format("D MMM");
      }
    } else {
      switch (this.scale) {
        case "1h":
          return (
            moment(timeString).format("D MMM hhA") +
            " - " +
            moment(timeString).add("hours", 1).format("hhA")
          );
          break;
        case "1d":
          return (
            moment(timeString).format("D") +
            " - " +
            moment(timeString).add("days", 1).format("D MMM")
          );
          break;
        case "1M":
          return (
            moment(timeString).format("D") +
            " - " +
            moment(timeString).endOf("month").format("D MMM YYYY")
          );
          break;
      }
    }
  }
}

export { chartOptions };
