<template>
  <div class="machineSummaryTable">
    <div class="headerContainer">
      <div class="datesbox">
        <div class="shift_performance blue-title">
          <span v-if="fromDate && toDate && calendarCase > 2">
            <h4>{{ fromDate && fromDate.format("MMMM DD, YYYY") }} -</h4>
            <h4>{{ toDate && toDate.format("MMMM DD, YYYY") }}</h4>
          </span>
          <span v-else-if="fromDate && toDate && calendarCase == 0">
            <h4 v-if="currentShift">{{ currentShift.name }}</h4>
            <h4>{{ fromDate && fromDate.format("MMMM DD, YYYY h:mma") }} -</h4>
            <h4>{{ toDate && toDate.format("MMMM DD, YYYY h:mma") }}</h4>
          </span>
          <span v-else-if="fromDate && toDate">
            <h4>{{ fromDate && fromDate.format("MMMM DD, YYYY") }}</h4>
          </span>
          <span v-else>
            <h4>{{ $t("No Shift Data") }}</h4>
          </span>
        </div>
      </div>

      <div class="box intervalSelection">
        <v-tabs
          @change="ChangeCalendar"
          fixed-tabs
          :value="calendarCase"
        >
          <v-tab>
            <template v-if="shifts && shifts.length > 1">
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    style="padding: 0"
                    plain
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t("Shift") }}
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="shift in shifts"
                    :key="shift.id"
                    @click="selectShift(shift)"
                  >
                    <v-list-item-title>{{ shift.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-else>
              {{ $t("Shift") }}
            </template>
          </v-tab>
          <v-tab>{{ productionDay.clone().subtract(1, "day").format("MMM DD") }}</v-tab>
          <v-tab>{{ productionDay.clone().format("MMM DD") }}</v-tab>
          <v-tab>{{ $t("Week") }}</v-tab>
          <v-tab>{{ $t("Month") }}</v-tab>
          <v-tab>{{ $t("Year") }}</v-tab>
        </v-tabs>
      </div>
    </div>

    <v-progress-linear
      v-if="!machinesLoaded"
      indeterminate
    />
    <data-table
      v-else
      item-key="equipment"
      :headers="tableHeaders"
      :items="filteredMachines"
      :slots="['status']"
      :show-expand="true"
      :single-expand="true"
      :itemExpanded="itemExpanded"
      :expanded="expanded"
    >
      <template v-slot:item.status="{ item }">
        <span
          class="py-1 px-2 rounded text-no-wrap text--primary"
          :class="`${item.statusColorSet}`"
        >
          {{ item.status }}
        </span>
      </template>
      <template v-slot:item.duration="{ item }">
        {{ seconds(item.duration) }}
      </template>
      <template v-slot:item.run_time="{ item }">
        {{ seconds(item.run_time) }}
      </template>
      <template v-slot:item.produced="{ item }">
        {{ numberWithCommas(item.produced) }}
      </template>
      <template v-slot:item.scrapped="{ item }">
        {{ numberWithCommas(item.scrapped) }}
      </template>
      <template v-slot:item.downtime="{ item }">
        {{ seconds(item.downtime) || "--" }}
      </template>
      <template v-slot:item.performance="{ item }"> {{ item.performance }}%</template>
      <template v-slot:item.quality="{ item }"> {{ item.quality }}%</template>
      <template v-slot:item.availability="{ item }"> {{ item.availability }}%</template>
      <template v-slot:item.oee="{ item }"> {{ item.oee }}%</template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="inner_table_class"
        >
          <div class="nestedTable">
            <v-progress-linear
              v-if="loading_detail"
              indeterminate
            />
            <data-table
              v-else
              :headers="nestedHeaders"
              :items="machineDetailsTable"
              :hide-default-footer="true"
              class="innerHeader"
            >
              <template v-slot:item.status="{ item }">
                <span
                  class="py-1 px-2 rounded text-no-wrap text--primary"
                  :class="`${item.statusColorSet}`"
                >
                  {{ item.status }}
                </span>
              </template>
              <template v-slot:item.duration="{ item }">
                {{ seconds(item.duration) }}
              </template>
              <template v-slot:item.run_time="{ item }">
                {{ seconds(item.run_time) }}
              </template>
              <template v-slot:item.produced="{ item }">
                {{ numberWithCommas(item.produced) }}
              </template>
              <template v-slot:item.scrapped="{ item }">
                {{ numberWithCommas(item.scrapped) }}
              </template>
              <template v-slot:item.downtime="{ item }">
                {{ seconds(item.downtime) || "--" }}
              </template>
              <template v-slot:item.performance="{ item }"> {{ item.performance }}%</template>
              <template v-slot:item.quality="{ item }"> {{ item.quality }}%</template>
              <template v-slot:item.availability="{ item }"> {{ item.availability }}%</template>
              <template v-slot:item.oee="{ item }"> {{ item.oee }}%</template>
            </data-table>
          </div>
        </td>
      </template>
    </data-table>
  </div>
</template>
<style lang="scss">
.machineSummaryTable {
  .table_part {
    width: 70px;
  }

  .inner_table_class {
    padding: 0 !important;
  }

  .innerHeader {
    margin: 0px 50px;
    width: calc(100% - 100px);
    background: rgba(0, 0, 0, 0.2);

    th {
      background-color: transparent !important;
    }
  }

  .headerContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 20px;

    .datesbox {
      max-width: 300px;
    }

    .blue-title {
      color: #44b2f3;
    }
  }
}
</style>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { numberWithCommas, seconds } from "@/utils/filters";

import DataTable from "@/components/dataTable.vue";
import PaginatedChart from "../../analytics/operator/PaginatedChart";
import MachineSummaryTable from "./MachineSummaryTable";
import { useMachineStatusClassName } from "@/features/machine-status/hooks/useMachineStatusClassName";

export default {
  props: [
    "machine_group_id",
    "machine_group_pk",
    "machinesLookup",
    "fromDate",
    "toDate",
    "setFromDate",
    "setToDate",
    "setCalendarCase",
    "calendarCase",
    "filters"
  ],
  data() {
    return {
      tableHeaders: [
        { text: this.$t("Machine"), value: "equipment" },
        { text: this.$t("Status"), value: "status" },
        { text: this.$t("Total Time"), value: "duration" },
        { text: this.$t("Run Time"), value: "run_time" },
        { text: this.$t("Produced"), value: "produced" },
        { text: this.$t("Scrapped"), value: "scrapped" },
        { text: this.$t("Downtime"), value: "downtime" },
        { text: this.$t("P(%)"), value: "performance" },
        { text: this.$t("Q(%)"), value: "quality" },
        { text: this.$t("A(%)"), value: "availability" },
        { text: this.$t("OEE(%)"), value: "oee" }
      ],
      nestedHeaders: [
        { text: this.$t("Job"), value: "job" },
        { text: this.$t("Part #"), value: "part", class: "table_part" },
        { text: this.$t("Description"), value: "description" },
        { text: this.$t("Total Time"), value: "duration" },
        { text: this.$t("Run Time"), value: "run_time" },
        { text: this.$t("Produced"), value: "produced" },
        { text: this.$t("Scrapped"), value: "scrapped" },
        { text: this.$t("Downtime"), value: "downtime" },
        { text: this.$t("P(%)"), value: "performance" },
        { text: this.$t("Q(%)"), value: "quality" },
        { text: this.$t("A(%)"), value: "availability" },
        { text: this.$t("OEE(%)"), value: "oee" }
      ],
      expanded: [],
      machines: [],
      shifts: [],
      machineDetailsTable: [],
      machinesLoaded: false,
      machinesTimeout: null,
      loading_detail: false,
      productionDay: moment(),
      currentShift: false
    };
  },
  components: {
    DataTable,
    PaginatedChart,
    MachineSummaryTable
  },
  created() {
    this.loadShiftInfo().then((shift) => {
      if (shift) {
        if (shift.shiftDays.length > 0) {
          this.shiftStart = new moment(shift.shiftDays[0].lastStartDatetime);
          this.shiftEnd = new moment(shift.shiftDays[0].nextEndDatetime);
        }
      }

      if (this.fromDate == null && this.shiftStart != null) {
        this.setFromDate(this.shiftStart);
      }

      if (this.toDate == null && this.shiftEnd != null) {
        this.setToDate(this.shiftEnd);
      }

      this.loadMachines();
    });
  },
  beforeDestroy() {},
  computed: {
    ...mapGetters({
      statuses: "app/Statuses"
    }),
    filteredMachines() {
      let filteredMachines = [];
      this.machines.forEach((machine, i) => {
        let include = true;
        if (this.filters.machine && this.filters.machine != machine.equipment) {
          include = false;
        }
        if (this.filters.part && this.filters.part != machine.part) {
          include = false;
        }
        if (this.filters.job && this.filters.job != machine.job) {
          include = false;
        }
        if (this.filters.status && this.filters.status != machine.statuscode) {
          include = false;
        }
        if (include) {
          filteredMachines.push(machine);
        }
      });
      return filteredMachines;
    }
  },
  methods: {
    seconds,
    numberWithCommas,
    moment,
    buildTable(recordData, machine) {
      const table = [];
      for (const record of recordData) {
        table.push({
          machine_id: machine ? machine.machine_id : record.machine_id,
          equipment: machine ? machine.equipment : record.machine_name,
          status: `${record.status_code} - ${this.getStatus(record.status_code).name}`,
          statusColorSet: useMachineStatusClassName(this.getStatus(record.status_code)),
          job: record.factory_order,
          part: record.part_number,
          description: record.part_description,
          duration: record.duration,
          run_time: record.runtime,
          produced: record.produced,
          scrapped: record.scrapped,
          downtime: record.duration - record.runtime,
          statuscode: record.status_code,
          performance: (
            (record.production_target ? record.produced / record.production_target : 0) * 100
          ).toFixed(2),
          quality: (
            (record.produced ? (record.produced - record.scrapped) / record.produced : 0) * 100
          ).toFixed(2),
          availability:
            record.duration > 0 ? ((record.runtime / record.duration) * 100).toFixed(2) : 0,
          oee: (
            (record.production_target ? record.produced / record.production_target : 0) *
            (record.produced ? (record.produced - record.scrapped) / record.produced : 0) *
            (record.duration > 0 ? record.runtime / record.duration : 0) *
            100
          ).toFixed(2)
        });
      }

      return table;
    },
    itemExpanded(item) {
      if (this.expanded.includes(item)) {
        this.expanded = [];
      } else {
        this.expanded = [];
        //Bcole: For some reason setting expanded on
        //same frame without clearing it first results
        //in a visual glitch
        this.loading_detail = true;
        this.machineDetailsTable = [];

        window.requestAnimationFrame(() => {
          this.expanded = [item];

          const params = {
            start_date: this.fromDate.toISOString(),
            end_date: this.toDate.toISOString(),
            machine: item.item.machine_id,
            get_jobs: true
          };
          this.$http.get("metrics/job_status_machine/", { params: params }).then((res) => {
            this.loading_detail = false;

            const factoryOrders = res.data[item.item.machine_id].factory_orders;
            this.machineDetailsTable = this.buildTable(factoryOrders, item.item);
          });
        });
      }
    },
    loadShiftInfo() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{shifts(current:true${this.machine_group_pk ? `, machineGroupId: ${this.machine_group_pk}` : ""}){
                name
                shiftDays(current:true){
                  lastStartDatetime
                  nextStartDatetime
                  nextEndDatetime
                }
              }
            }`
            }
          })
          .then((res) => {
            if (res.data.data.shifts.length > 0) {
              let latestShift = null;
              let latestDatetime = null;
              this.shifts = res.data.data.shifts;
              res.data.data.shifts.forEach((shift) => {
                shift.shiftDays.forEach((day) => {
                  const currentDatetime = new Date(day.lastStartDatetime);
                  if (!latestDatetime || currentDatetime > latestDatetime) {
                    latestDatetime = currentDatetime;
                    latestShift = shift;
                  }
                });
              });

              if (latestDatetime) {
                this.productionDay = moment(latestDatetime).startOf("day");
                this.currentShift = latestShift;
                if (latestShift.shiftDays.length > 0) {
                  this.shiftStart = new moment(latestShift.shiftDays[0].lastStartDatetime);
                  this.shiftEnd = new moment(latestShift.shiftDays[0].nextEndDatetime);
                }
                resolve(latestShift);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          })
          .catch((e) => {
            reject();
          });
      });
    },
    selectShift(shift) {
      this.calendarCase = 0;
      this.currentShift = shift;
      if (shift.shiftDays.length > 0) {
        this.shiftStart = new moment(shift.shiftDays[0].lastStartDatetime);
        this.shiftEnd = new moment(shift.shiftDays[0].nextEndDatetime);
      }
      this.fromDate = moment(this.shiftStart);
      this.toDate = moment(this.shiftEnd);
      this.loadMachines();
    },
    loadMachines() {
      //bail if we don't have a date set
      if (this.fromDate == null || this.toDate == null) {
        this.machinesLoaded = true;
        return;
      }

      this.machinesLoaded = false;
      let params = {
        machine_group_id: this.machine_group_pk,
        start_date: this.fromDate.toISOString(),
        end_date: this.toDate.toISOString()
      };

      const machineList = [];
      this.$http.get("metrics/job_status_machine/", { params: params }).then((res) => {
        const machinesResponse = res.data;
        for (const m of Object.keys(machinesResponse)) {
          const row = this.buildTable([machinesResponse[m].machine]);
          machineList.push(row[0]);
        }

        this.machines = [...machineList];

        this.machinesLoaded = true;
        if (this.machinesTimeout) {
          clearTimeout(this.machinesTimeout);
        }
        let loadMachines = this.loadMachines;
        this.machinesTimeout = setTimeout(loadMachines, 300000);
      });
    },
    getStatus(machine_code) {
      let statusMatch = false;
      if (machine_code) {
        this.statuses.forEach((status) => {
          status.statuscodeSet.forEach((code) => {
            if (String(code.code) == String(machine_code)) {
              statusMatch = status;
            }
          });
        });
      }
      return statusMatch;
    },
    ChangeCalendar(e) {
      this.setCalendarCase(e);
      this.expanded = [];
      this.machines = [];
      switch (e) {
        case 0:
          if (this.shiftStart != null && this.shiftEnd != null) {
            this.setFromDate(moment(this.shiftStart));
            this.setToDate(moment(this.shiftEnd));
          } else {
            this.setFromDate(null);
            this.setToDate(null);
          }
          break;
        case 1:
          this.setFromDate(moment().subtract(1, "day").startOf("day"));
          this.setToDate(moment().subtract(1, "day").endOf("day"));
          break;
        case 2:
          this.setFromDate(moment().startOf("day"));
          this.setToDate(moment().endOf("day"));
          break;
        case 3:
          this.setFromDate(moment().startOf("week"));
          this.setToDate(moment().endOf("week"));
          break;
        case 4:
          this.setFromDate(moment().startOf("month"));
          this.setToDate(moment().endOf("month"));
          break;
        case 5:
          this.setFromDate(moment().startOf("year"));
          this.setToDate(moment().endOf("year"));
          break;
      }
    }
  },
  watch: {
    fromDate: function () {
      if (this.fromDate && this.toDate) {
        this.loadMachines();
      }
    },
    toDate: function () {
      if (this.fromDate && this.toDate) {
        this.loadMachines();
      }
    }
  }
};
</script>
