var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operator_realtime"},[_c('div',{staticClass:"top_row"},[(_vm.realtime_type_selected == 'table')?_c('v-text-field',{staticClass:"searchbar",attrs:{"placeholder":_vm.$t('Search'),"prepend-inner-icon":"mdi-magnify","dense":"","clearable":""},on:{"input":_vm.setFilter}}):_c('div',{staticClass:"searchbar"}),_vm._v(" "),_c('div',{staticClass:"top_row_icons"},[_c('button',[_c('i',{staticClass:"fa fa-address-card-o",class:_vm.realtime_type_selected == 'card' && 'selected',on:{"click":function () {
              _vm.SetRealtimeType('card');
              _vm.setShift();
            }}})]),_vm._v(" "),_c('button',[_c('i',{staticClass:"fa fa-table",class:_vm.realtime_type_selected == 'table' && 'selected',on:{"click":function () {
              _vm.SetRealtimeType('table');
            }}})]),_vm._v(" "),_c('button',[_c('i',{staticClass:"fa fa-line-chart",class:_vm.realtime_type_selected == 'chart' && 'selected',on:{"click":function () {
              _vm.SetRealtimeType('chart');
            }}})])])],1),_vm._v(" "),(_vm.realtime_type_selected == 'table')?_c('realtime-table',{attrs:{"table_headers":_vm.table_headers,"items":_vm.filteredItems,"setFromDate":_vm.setFromDate,"setToDate":_vm.setToDate,"setCalendarCase":function (e) {
        this$1.calendarCase = e;
      },"calendarCase":_vm.calendarCase,"fromDate":_vm.fromDate,"toDate":_vm.toDate,"shiftStart":_vm.shiftStart,"shiftEnd":_vm.shiftEnd,"numCheckedIn":_vm.numCheckedIn,"numCheckedOut":_vm.numCheckedOut,"now":_vm.now,"loading":_vm.loading}}):_vm._e(),_vm._v(" "),(_vm.realtime_type_selected == 'card')?_c('realtime-cards',{attrs:{"items":_vm.items}}):_vm._e(),_vm._v(" "),(_vm.realtime_type_selected == 'chart')?_c('realtime-charts',{attrs:{"items":_vm.items,"setCalendarCase":function (e) {
        this$1.calendarCase = e;
      },"calendarCase":_vm.calendarCase,"shiftStart":_vm.shiftStart,"shiftEnd":_vm.shiftEnd,"fromDate":_vm.fromDate,"toDate":_vm.toDate,"setFromDate":_vm.setFromDate,"setToDate":_vm.setToDate,"now":_vm.now,"numberRecords":_vm.numberRecords,"setNumberRecords":function (e) {
        this$1.numberRecords = e;
      }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }