<template>
  <div class="card full-height">
    <div class="header">
      <h4>{{ $t("Major Losses") }}</h4>
      <div class="icons">
        <i
          class="fa fa-table"
          v-bind:class="chartType === chartTypes.TABLE ? 'selected' : null"
          @click="
            () => {
              chartType = chartTypes.TABLE;
            }
          "
        />
        <i
          class="fa fa-bar-chart"
          v-bind:class="chartType === chartTypes.CHART ? 'selected' : null"
          @click="
            () => {
              chartType = chartTypes.CHART;
            }
          "
        />
      </div>
    </div>
    <div
      class="chart-container"
      v-if="chartType == chartTypes.CHART && !loading"
    >
      <div
        class="bar-container"
        v-if="majorLosses.length > 0"
      >
        <div class="bar-ticks">
          <div class="tick-holder">
            <div
              v-for="i in 11"
              class="tick"
            >
              <span>{{ (i - 1) * 10 }}</span>
            </div>
          </div>
        </div>
        <div class="horizontal-bar">
          <div
            v-for="(majorLoss, i) in majorLosses.filter(
              (majorLoss) => this.localFiltered.indexOf(majorLoss.status_codes[0]) < 0
            )"
            @mouseenter="
              (e) => {
                tooltip(e, true);
              }
            "
            @mouseleave="
              (e) => {
                tooltip(e, false);
              }
            "
            @mousemove="
              (e) => {
                tooltip(e, true);
              }
            "
            @click="
              () => {
                handleClick(majorLoss.status_codes);
              }
            "
            :key="i"
            class="major_loss_bar"
            :class="machineStatusClassName(majorLoss.status)"
            v-bind:style="{
              flex: (majorLoss.value / (totalFilteredDowntime || 1)) * 100
            }"
          >
            <div class="tooltip">
              <div class="tooltip-content">
                {{ majorLoss.name }} :
                {{ ((majorLoss.value / (totalDowntime || 1)) * 100).toFixed(2) }}%
              </div>
              <div class="tooltip-pointer" />
            </div>
            <div class="major_loss_text">
              <div>{{ ((majorLoss.value / (totalDowntime || 1)) * 100).toFixed(2) }}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="legend">
        <div
          v-for="(majorLoss, i) in majorLosses"
          class="legend-container"
          @click="
            () => {
              handleClickLegend(majorLoss.status_codes[0]);
            }
          "
        >
          <div
            class="swatch"
            :class="machineStatusClassName(majorLoss.status)"
          >
            <i
              class="cross-out mdi mdi-close"
              v-bind:style="{
                visibility:
                  localFiltered.indexOf(majorLoss.status_codes[0]) < 0 ? 'hidden' : 'visible'
              }"
            />
          </div>
          <div class="label">
            {{ majorLoss.name }} {{ ((majorLoss.value / (totalDowntime || 1)) * 100).toFixed(2) }}%
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-container"
      v-else-if="chartType == chartTypes.TABLE && !loading"
    >
      <GenericTable
        :columns="[
          { title: '', key: 'name' },
          { title: $t('Duration'), key: 'duration', format: seconds, sortable: true },
          { title: $t('Total Downtime'), key: 'total_downtime', format: seconds, sortable: false },
          {
            title: $t('Downtime Percent'),
            key: 'downtime_percentage',
            format: percent,
            sortable: true
          },
          { title: $t('Production Loss'), key: 'loss', format: percent, sortable: true }
        ]"
        :data="tableData"
      />
    </div>

    <v-progress-linear
      indeterminate
      v-else
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import { hexToRGB } from "@/utils/colors";
import { seconds } from "@/utils/filters";
import { useMachineStatusClassName } from "@/features/machine-status/hooks/useMachineStatusClassName";

import useTLAFilters from "./useTLAFilters";

const chartTypes = {
  CHART: "chart",
  TABLE: "table"
};

export default {
  name: "TLA_Major_Losses",
  components: { GenericTable },
  props: ["filters", "priority"],
  setup() {
    const {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    } = useTLAFilters();
    return {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    };
  },
  data() {
    return {
      majorLosses: [],
      rawData: {},
      statusMap: {},
      totalDowntime: 0,
      totalFilteredDowntime: 0,
      totalDuration: 0,
      chartType: chartTypes.CHART,
      chartTypes: chartTypes,
      tableData: [],
      filterName: "major_losses",
      loading: 0,
      cancelToken: null,
      localFiltered: []
    };
  },
  mounted() {
    this.statusMap = this.createStatusMap();
    this.loadMajorLosses()
      .then((result) => {
        this.rawData = result;
        this.majorLosses = this.createMajorLosses(result);
        this.tableData = this.createTableData(result);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    })
  },
  methods: {
    seconds,
    hexToRGB,
    handleClick(status_codes) {
      console.log(this.priority, this.priorityApplied, this.priority < this.priorityApplied);
      if (this.priority < this.priorityApplied) {
        this.removeAllFilters([
          "tlaReportMachineGroupLevelP2",
          "tlaReportMachineGroupLevelP2",
          "machine_id",
          "major_losses"
        ]);
      }

      for (const s of status_codes) {
        this.toggleFilter(this.filterName, s, this.priority);
      }

      this.majorLosses = this.createMajorLosses(this.rawData);
    },
    handleClickLegend(status_code) {
      const index = this.localFiltered.indexOf(status_code);
      if (index < 0) {
        this.localFiltered.push(status_code);
      } else {
        this.localFiltered.splice(index, 1);
      }

      this.majorLosses = [...this.createMajorLosses(this.rawData)];
    },
    percent(val) {
      return `${(val * 100).toFixed(2)}%`;
    },
    tooltip(e, visible) {
      if (visible) {
        const tooltip = e.target.getElementsByClassName("tooltip")[0];
        tooltip.classList.add("visible");
        const x = e.clientX,
          y = e.clientY;
        tooltip.style.top = y - 60 + "px";
        tooltip.style.left =
          Math.min(x - tooltip.clientWidth / 2, window.outerWidth - tooltip.clientWidth) + "px";
      } else {
        e.target.getElementsByClassName("tooltip")[0].classList.remove("visible");
      }
    },
    createStatusMap() {
      const statusMap = {};
      for (const status of this.statuses) {
        for (const statusCode of status.statuscodeSet) {
          statusMap[statusCode.code] = { ...status, ...statusCode };
        }
      }
      return statusMap;
    },
    createTableData(result) {
      const majorLossesMap = {};
      for (const r of result.buckets) {
        const statusCode = r.key;
        const status = this.statusMap[statusCode];
        if (status && !status.running) {
          if (majorLossesMap[status.name] == null) {
            majorLossesMap[status.name] = { name: status.name, value: 0 };
          }
          majorLossesMap[status.name].value += r.duration.value;
        }
      }

      const tableData = Object.keys(majorLossesMap).map((r) => {
        return {
          name: majorLossesMap[r].name,
          duration: majorLossesMap[r].value,
          total_downtime: this.totalDowntime,
          downtime_percentage: majorLossesMap[r].value / this.totalDowntime,
          loss: majorLossesMap[r].value / (this.totalDuration || 1)
        };
      });

      return tableData;
    },
    createMajorLosses(result) {
      this.totalDowntime = 0;
      this.totalFilteredDowntime = 0;

      const majorLossesMap = {};
      for (const r of result.buckets) {
        const statusCode = r.key;
        const status = this.statusMap[statusCode];

        if (status && !status.running) {
          if (majorLossesMap[status.name] == null) {
            const statuses = [];
            for (const s of status.statuscodeSet) {
              statuses.push(s.code);
            }
            majorLossesMap[status.name] = {
              name: status.name,
              value: 0,
              status_codes: statuses,
              status
            };
          }
          majorLossesMap[status.name].value += r.duration.value;
          this.totalDowntime += r.duration.value;

          if (this.localFiltered.indexOf(majorLossesMap[status.name].status_codes[0]) < 0) {
            this.totalFilteredDowntime += r.duration.value;
          }
        }
        this.totalDuration += r.duration.value;
        this.totalFilteredDuration += r.duration.value;
      }
      const majorLosses = Object.keys(majorLossesMap)
        .map((key) => majorLossesMap[key])
        .sort((a, b) => {
          return b.value - a.value;
        });
      return [...majorLosses];
    },
    loadMajorLosses() {
      return new Promise((resolve, reject) => {
        //guard
        if (!this.filters || !this.filters.from_date || !this.filters.to_date) {
          reject();
        } else {
          let params = {
            from_date: this.filters.from_date,
            to_date: this.filters.to_date
          };

          if (this.hasFilters) {
            if (
              this.interactiveFilters["machine_id"] &&
              this.interactiveFilters["machine_id"].length > 0
            ) {
              params.machine_id = this.interactiveFilters["machine_id"].join(",");
            } else if (this.filters.machines.length) {
              params.machine_id = this.filters.machines.join(",");
            } else if (this.filters.machine_groups.length) {
              params.machine_id = this.filters.machine_groups.join(",");
            }

            if (
              this.interactiveFilters["part_numbers"] &&
              this.interactiveFilters["part_numbers"].length > 0
            ) {
              params.part_numbers = this.interactiveFilters["part_numbers"].join(",");
            } else if (this.filters.part_numbers.length) {
              params.part_numbers = this.filters.part_numbers.join(",");
            }

            if (
              this.interactiveFilters["minor_losses"] &&
              this.interactiveFilters["minor_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["minor_losses"].join(",");
            } else if (
              this.interactiveFilters["major_losses"] &&
              this.interactiveFilters["major_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["major_losses"].join(",");
            } else if (this.filters.status_codes.length) {
              params.status_codes = this.filters.status_codes.join(",");
            }
          } else {
            params.machine_id = this.filters.machines.length
              ? this.filters.machines.join(",")
              : null;
            params.part_numbers = this.filters.part_numbers.length
              ? this.filters.part_numbers.join(",")
              : null;
            params.status_codes = this.filters.status_codes.length
              ? this.filters.status_codes.join(",")
              : null;
          }

          if (this.cancelSource) {
            this.cancelSource.cancel();
          }
          const CancelToken = axios.CancelToken;
          this.cancelSource = CancelToken.source();

          this.loading++;

          this.$http
            .get("metrics/status_code_percent/", {
              params: params,
              cancelToken: this.cancelSource.token
            })
            .then((result) => {
              this.loading--;
              this.cancelSource = null;
              resolve(result.data);
            })
            .catch((e) => {
              this.loading--;
              this.cancelSource = null;
              console.log(e);
            });
        }
      });
    },
    machineStatusClassName(status) {
      return useMachineStatusClassName(status);
    }
  },
  watch: {
    filters: function () {
      this.statusMap = this.createStatusMap();
      this.loadMajorLosses()
        .then((result) => {
          this.rawData = result;
          this.majorLosses = this.createMajorLosses(result);
          this.tableData = this.createTableData(result);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    interactiveFilters: function () {
      if (this.filterApplied !== this.filterName && this.priority > this.priorityApplied) {
        this.loadMajorLosses()
          .then((result) => {
            this.rawData = result;
            this.majorLosses = this.createMajorLosses(result);
            this.tableData = this.createTableData(result);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: unset;

  i {
    padding-left: 10px;
    cursor: pointer;

    &.selected {
      color: $blue;
    }
  }
}

.table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chart-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin: 25px 0;

  .legend {
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    flex-wrap: wrap;

    .legend-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 8px 10px;
      cursor: pointer;

      .swatch {
        width: 18px;
        height: 18px;
        position: relative;

        .cross-out {
          visibility: hidden;
          font-size: 13px;
          position: absolute;
          text-align: center;
          width: 100%;
        }
      }

      .label {
        padding-left: 10px;
        font-size: 12px;
      }
    }
  }

  .bar-container {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    .bar-ticks {
      width: 100%;
      height: 0px;
    }

    .tooltip {
      display: none;
      z-index: 1;
      position: fixed;
      pointer-events: none;
      align-items: center;
      flex-direction: column;
      width: max-content;

      .tooltip-content {
        background: black;
        padding: 8px;
        bottom: 10px;

        .theme--light & {
          color: white;
        }
      }

      .tooltip-pointer {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        border-top: 8px solid #000;
      }

      &.visible {
        display: flex;
      }
    }

    .tick-holder {
      height: 48px;
      display: flex;
      width: 100%;
      align-items: flex-end;

      .tick {
        flex: 1;
        font-size: 12px;
        border-left: 1px solid white;
        position: relative;
        height: 100%;
        display: flex;
        align-items: flex-end;

        span {
          position: absolute;
          left: -10px;
          right: 0;
          width: 20px;
          text-align: center;
          bottom: -18px;
          word-wrap: unset;
        }

        &:last-child {
          flex: 0;
        }
      }
    }

    .horizontal-bar {
      height: 40px;
      display: flex;
      width: 100%;
      border-radius: 4px;

      .major_loss_bar {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .major_loss_text {
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          color: white;
          font-size: 16px;
          overflow: hidden;
          pointer-events: none;
        }
      }
    }
  }
}

.full-height {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

h4 {
  color: $blue;
  flex: unset;
}
</style>
