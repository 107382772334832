import { useQuery } from "@tanstack/vue-query";
import { computed } from "@vue/composition-api";
import { ticketsApi } from "@/features/tickets/tickets-api";

export const useTicketsLdms = (variables, loadSummary = true) => {
  const query = useQuery({
    queryKey: ["tickets-ldms", variables],
    queryFn: () => ticketsApi.getTicketsLdms(variables.value),
    keepPreviousData: true
  });

  const summaryQuery = useQuery({
    queryKey: ["tickets-ldms-summary", variables],
    queryFn: () => (loadSummary ? ticketsApi.getTicketsLdmsSummary(variables.value) : null),
    keepPreviousData: true
  });

  const items = computed(() => query.data.value && query.data.value.items);
  const summary = computed(() => summaryQuery.data.value);
  const categories = computed(() => query.data.value && query.data.value.categories);

  const total = computed(() => query.data.value && query.data.value.page_info.total);

  return {
    ...query,
    summary,
    categories,
    items,
    total
  };
};
