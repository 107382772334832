<template>
  <div class="enlargedCard card">
    <div class="card-header">
      <div class="desktop-card-header">
        <div class="card-title">
          <h3>{{ $t(enlargedTrend.name) }}</h3>
        </div>
        <div class="card-buttons">
          <v-tabs v-if="enlargedTrend.name !== 'Safety'">
            <v-tab @click="scaleTab = 0">{{ $t("week") }}</v-tab>
            <v-tab @click="scaleTab = 1">{{ $t("month") }}</v-tab>
            <v-tab @click="scaleTab = 2">{{ $t("year") }}</v-tab>
            <v-tab @click="showDateRangePicker = true">{{ $t("custom") }}</v-tab>
          </v-tabs>
          <SafetyEvent
            v-if="enlargedTrend.name === 'Safety'"
            :selectedEvent="selectedSafetyEvent"
            @safetyEventSubmitted="incrementSafetyEventSubmits"
            @clear-selected-event="selectedSafetyEvent = null"
          />
          <v-btn
            class="expand"
            small
            light
            color="white"
            fab
            @click.stop.prevent="setEnlargedTrend({})"
          >
            <i
              class="fa fa-compress"
              aria-hidden="true"
            ></i>
          </v-btn>
        </div>
        <v-dialog
          v-model="showDateRangePicker"
          max-width="800"
        >
          <v-card>
            <DialogCloseButton :onClick="() => (showDateRangePicker = false)" />
            <v-card-title class="pb-0">{{ $t("Custom Date Range") }}</v-card-title>
            <v-card-text>
              <v-col>
                <v-row>
                  <v-tabs
                    v-model="customDateInterval"
                    class="d-flex justify-center align-center"
                  >
                    <v-tab>{{ $t("day") }}</v-tab>
                    <v-tab>{{ $t("week") }}</v-tab>
                    <v-tab>{{ $t("month") }}</v-tab>
                    <v-tab>{{ $t("year") }}</v-tab>
                  </v-tabs>
                </v-row>
                <v-row class="d-flex justify-center align-center">
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    scrollable
                    :locale="$i18n.locale()"
                  />
                  <v-date-picker
                    v-model="endDate"
                    :min="startDate"
                    no-title
                    scrollable
                    :locale="$i18n.locale()"
                  />
                </v-row>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error"
                text
                @click="showDateRangePicker = false"
                >{{ $t("Cancel") }}</v-btn
              >
              <v-btn
                color="primary"
                text
                @click="applyDateRange()"
                :disabled="!dateRangeValid"
                >{{ $t("Apply") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="mobile-card-header">
        <div class="header-set">
          <div class="card-title">
            <h3>{{ $t(enlargedTrend.name) }}</h3>
          </div>
          <v-btn
            class="expand card-buttons"
            small
            light
            color="white"
            fab
            @click.stop.prevent="setEnlargedTrend({})"
          >
            <i
              class="fa fa-compress"
              aria-hidden="true"
            ></i>
          </v-btn>
        </div>
        <div class="card-buttons">
          <v-tabs v-if="enlargedTrend.name !== 'Safety'">
            <v-tab @click="scaleTab = 0">{{ $t("week") }}</v-tab>
            <v-tab @click="scaleTab = 1">{{ $t("month") }}</v-tab>
            <v-tab @click="scaleTab = 2">{{ $t("year") }}</v-tab>
            <v-tab @click="showDateRangePicker = true">{{ $t("custom") }}</v-tab>
          </v-tabs>
        </div>
      </div>
    </div>
    <div
      class="card-content"
      :style="
        enlargedTrend.name === 'Safety' && $vuetify.breakpoint.mdAndUp ? 'overflow-y: hidden' : ''
      "
    >
      <SafetyCard
        v-if="enlargedTrend.name === 'Safety'"
        :level="levelId"
        :machine_group_pk="machineGroupPk"
        :safetyEventSubmits="safetyEventSubmits"
        @editSafetyEvent="handleEditSafetyEvent"
      />
      <TrendCard
        v-else
        :trend="{ ...enlargedTrend, machine_group_id: this.machine_group_id }"
        :timeScale="scaleTab"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";

import DialogCloseButton from "@/components/DialogCloseButton";
import DateRangePicker from "@/components/form/DateRangePicker";
import SafetyCard from "@/components/zone_control/SafetyCard.vue";
import TrendCard from "@/components/zone_control/TrendCard";

import SafetyEvent from "../../../components/dialogs/SafetyEvent.vue";

export default {
  name: "EnlargedTrend",
  props: {
    enlargedTrend: { type: Object, default: () => {} },
    setEnlargedTrend: { type: Function, default: () => null },
    levelId: { type: Number },
    machine_group_id: { type: String, default: "" },
    machineGroupPk: { type: Number }
  },
  components: {
    DateRangePicker,
    SafetyCard,
    SafetyEvent,
    TrendCard,
    DialogCloseButton
  },
  data() {
    return {
      scaleTab: 0,
      showDateRangePicker: false,
      customDateInterval: null,
      startDate: null,
      endDate: null,
      dateRangeValid: false,
      selectedSafetyEvent: null,
      safetyEventSubmits: 0
    };
  },
  methods: {
    applyDateRange() {
      const scales = ["day", "week", "month", "year"];
      this.scaleTab = `${moment(this.startDate).startOf("day").toISOString()} ${moment(this.endDate)
        .endOf("day")
        .toISOString()} ${scales[this.customDateInterval]}`;
      this.showDateRangePicker = false;
    },
    determineDateRangeValid() {
      // ensure that startDate and endDate are both set, and that startDate is before endDate
      if (this.startDate && this.endDate) {
        this.dateRangeValid = moment(this.startDate).isBefore(moment(this.endDate));
      } else {
        this.dateRangeValid = false;
      }
    },
    handleEditSafetyEvent(selectedEvent) {
      this.selectedSafetyEvent = selectedEvent;
    },
    incrementSafetyEventSubmits() {
      this.safetyEventSubmits++;
    }
  },
  watch: {
    startDate() {
      this.determineDateRangeValid();
    },
    endDate() {
      this.determineDateRangeValid();
    }
  }
};
</script>
