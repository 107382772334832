<template>
  <div class="ai_vision">
    <div class="ai_vision_nav_bar menu">
      <v-tabs
        class="tabs"
        v-model="tab"
        align-with-title
        grow
        centered
        key="tabs"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="gallery">
          {{ $t("Gallery") }}
        </v-tab>
        <v-tab key="anomalies">
          {{ $t("Anomalies") }}
        </v-tab>
        <v-tab key="exceptions">
          {{ $t("Exceptions") }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="ai_vision_main_content">
      <ai-vision-filters
        v-show="tab == 0"
        class="filter"
        :hideScale="true"
        :type="typeData"
        :asset_id="assetData && assetData.id"
        :group_id="groupData && (groupData.pk || groupData.id)"
        :setupFilters="setupFilters"
        :loadDocuments="loadDocuments"
        :documents="documents"
        :filters="filters"
      />
      <div class="ai_vision_content">
        <div
          v-if="tab === 0"
          key="0"
          class="inner_content"
        >
          <ai-vision-gallery
            :setRequestCount="setRequestCount"
            :count="count"
            :totalcount="totalcount"
            :currentStart="currentStart"
            :handlePage="handlePage"
            :documents="documents"
            :createZipFile="createZipFile"
            :cancelZipDownload="cancelZipDownload"
            :zipCreationPercent="zipCreationPercent"
            :zipDownloading="zipDownloading"
            :createCSV="createCSV"
          />
        </div>
        <div
          v-else-if="tab === 1"
          key="1"
          class="inner_content"
        >
          <ai-vision-anomalies :ticketed="false" />
        </div>
        <div
          v-else-if="tab === 2"
          key="2"
          class="inner_content"
        >
          <ai-vision-anomalies :ticketed="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AiVisionAnalytics from "../analytics/aivision/AiVisionAnalytics";
import AiVisionAnomalies from "./AiVisionAnomalies";
import AiVisionFilters from "./AiVisionFilters";
import AiVisionGallery from "./AiVisionGallery";
import AiVisionRealtime from "./AiVisionRealtime";

export default {
  name: "AiVision",
  components: {
    AiVisionAnomalies,
    AiVisionGallery,
    AiVisionRealtime,
    AiVisionFilters
  },
  props: ["asset", "group", "type"],
  mounted: function () {
    const { assetId, groupId } = this.$router.history.current.params;
    console.log("assset id", this.$router.history.current.params);
    if (assetId) {
      this.$http
        .post("graphql/", {
          query: `query($type:String, $assetId:Int){
                    assets(typeName:$type, id: $assetId) {
                      name
                      pk
                      type {
                        name
                        pk
                      }
                    }
                  }`,
          variables: { type: "AiVision", assetId: assetId }
        })
        .then((res) => {
          const asset = res.data.data.assets[0];
          this.typeData = "asset";
          this.assetData = { name: asset.name, id: asset.pk };
          this.groupData = null;
        });
    } else if (groupId) {
      this.$http
        .post("graphql/", {
          query: `query($groupId:Int){
                    assetLocations(id: $groupId) {
                      name
                      pk
                    }
                  }`,
          variables: { groupId: groupId }
        })
        .then((res) => {
          const group = res.data.data.assetLocations[0];
          this.typeData = "group";
          this.groupData = { name: group.name, id: group.pk };
          this.assetData = null;
        })
        .catch((e) => {
          console.error(e);
        });
    }

    const id = localStorage.getItem("zipdownload");
    if (id) {
      this.zipDownloading = true;
      this.checkStatus(id);
    }
  },
  data() {
    return {
      tab: 0,
      assetData: null,
      groupData: null,
      typeData: null,
      filters: {},
      count: 10,
      totalcount: 0,
      currentStart: 0,
      documents: [],
      zipCreationPercent: 0,
      zipDownloading: false
    };
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    cancelZipDownload() {
      if (localStorage.getItem("zipdownload") != null) {
        const id = localStorage.getItem("zipdownload");
        this.$http
          .get("aivision/abortzip/", { params: { id: id } })
          .then((result) => {
            this.zipDownloading = false;
            localStorage.removeItem("zipdownload");
          })
          .catch(() => {
            alert(this.$t("error canceling zip download"));
          });
      }
    },
    createZipFile() {
      const { assetId, groupId } = this.$router.history.current.params;
      const query = {
        ...this.filters,
        location: groupId
      };

      //we can send an asset_id by the primary page filters
      //or by filtering with the filter component.
      if (!this.filters.asset_id) {
        query.asset_id = assetId;
      }

      if (localStorage.getItem("zipdownload") == null) {
        this.$http
          .get("aivision/createzip/", { params: query })
          .then((result) => {
            const num = result.data.num;
            const id = result.data.id;

            localStorage.setItem("zipdownload", id);
            this.checkStatus(id);
          })
          .catch(() => {
            alert(this.$t("error generating zip download"));
          });
      } else if (this.zipCreationPercent < 100) {
        this.cancelZipDownload();
      }
    },
    createCSV() {
      const { assetId, groupId } = this.$router.history.current.params;
      const query = {
        ...this.filters,
        location: groupId
      };

      if (!this.filters.asset_id) {
        query.asset_id = assetId;
      }

      //todo: create csv in chunks or assemble on api in chunks
      this.$http.get("aivision/createcsv/", { params: query }).then((result) => {
        const fileURL = window.URL.createObjectURL(new Blob([result.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `ai_vision_csv_${new Date().toISOString()}.csv`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    checkStatus(id) {
      this.zipDownloading = true;

      this.$http
        .get("aivision/zipstatus/", { params: { id: id } })
        .then((result) => {
          const { percent, status } = result.data;
          if (status === "success") {
            this.zipCreationPercent = 100;
            this.downloadZip(id);
          } else {
            this.zipCreationPercent = Math.round(percent * 100);
            window.setTimeout(() => {
              this.checkStatus(id);
            }, 2000);
          }
        })
        .catch((e) => {
          this.zipDownloading = false;
          localStorage.removeItem("zipdownload");
        });
    },
    downloadZip(id) {
      this.$http
        .get(`aivision/zipdownload/`, { params: { id: id }, responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `ai_vision_${new Date().toISOString()}.zip`);
          document.body.appendChild(fileLink);

          fileLink.click();
          this.zipCreationPercent = 1;
          this.zipDownloading = false;
          localStorage.removeItem("zipdownload");
        })
        .catch((e) => {
          console.error(e);
          this.zipCreationPercent = 1;
          this.zipDownloading = false;
          localStorage.removeItem("zipdownload");
        });
    },
    setRequestCount(count) {
      //we're changing the request count
      this.count = count;
      this.currentStart = 0;
      this.handleLoad();
    },
    loadDocuments() {
      this.currentStart = 0;
      this.handleLoad();
    },
    handlePage(direction) {
      const newStart = this.currentStart + direction * this.count;
      if (newStart >= 0 && newStart < this.totalcount) {
        this.currentStart = newStart;
        this.handleLoad();
      }
    },
    handleLoad() {
      const { assetId, groupId } = this.$router.history.current.params;
      const query = {
        ...this.filters,
        start: this.currentStart,
        count: this.count,
        location: groupId
      };

      //we can send an asset_id by the primary page filters
      //or by filtering with the filter component.
      if (!this.filters.asset_id) {
        query.asset_id = assetId;
      }

      this.$http.get("aivision/", { params: query }).then((result) => {
        const hits = result.data.hits;
        this.documents = hits.hits;
        this.totalcount = result.data.count;
      });
    },
    setupFilters({ part, machine, process_type, inspection_result, from_date, to_date, asset }) {
      this.filters = {
        part_name: part && part.part_name,
        machine_id: machine && machine.machine_id,
        asset_id: asset && asset.asset_id,
        process_type: process_type,
        inspection_result: inspection_result,
        from_date: from_date ? from_date.toISOString() : null,
        to_date: to_date ? to_date.toISOString() : null
      };
    }
  },
  watch: {
    asset: function () {
      if (this.asset) {
        this.assetData = this.asset;
        this.typeData = "asset";
      }
    },
    group: function () {
      if (this.group) {
        this.groupData = this.group;
        this.typeData = "group";
      }
    },
    assetData: function () {
      if (this.typeData === "asset") {
        this.setTitles({
          title: `${this.$t("AEye Vision")}: ${this.assetData.name}`,
          mobile: `${this.$t("AEye Vision")}: ${this.assetData.name}`
        });
      }
    },
    groupData: function () {
      if (this.typeData === "group") {
        this.setTitles({
          title: `${this.$t("AEye Vision")}: ${this.groupData.name}`,
          mobile: `${this.$t("AEye Vision")}: ${this.groupData.name}`
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ai_vision {
  padding: 0.5rem 1rem;
  padding-bottom: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  right: 0;
  justify-content: space-between;

  .tabs {
    i {
      font-size: 1.15rem;
    }
  }

  .filter {
    width: 400px;
    flex: unset;
    min-width: 400px;
  }

  .ai_vision_main_content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;

    .ai_vision_nav_bar {
      height: 10px;
      flex: unset;
    }
    .ai_vision_content {
      flex: 1;
      padding: 0 10px;
      display: flex;
      flex-direction: column;

      .inner_content {
        min-height: 100%;
        display: flex;
      }
    }
  }
}
</style>
