<template>
  <div class="analyticsSummaryTable">
    <div class="analyticsSummaryHead">
      <h2>{{ $t("Summary") }}</h2>
    </div>
    <div class="flex-table">
      <generic-table
        :columns="[
          { title: '', key: 'machine_name' },
          {
            title: $t('Target (pcs)'),
            key: 'production_target',
            format: (value) => this.numberWithCommas(Math.round(value)),
            sortable: false,
            style: { textAlign: 'center' }
          },
          {
            title: $t('Total Produced (pcs)'),
            key: 'produced',
            format: (value) => this.numberWithCommas(Math.round(value)),
            sortable: false,
            style: { textAlign: 'center' }
          },
          {
            title: $t('Quality Losses (pcs)'),
            key: 'scrapped',
            format: (value) => this.numberWithCommas(Math.round(value)),
            sortable: false,
            style: { textAlign: 'center' }
          },
          {
            title: $t('Net Produced (pcs)'),
            key: 'netproduced',
            format: (value) => this.numberWithCommas(Math.round(value)),
            sortable: false,
            style: { textAlign: 'center' }
          }
        ]"
        :footer="[
          {
            type: 'csv',
            csv: downloadCsv
          },
          {
            type: 'text',
            text: this.numberWithCommas(Math.round(this.totalProductionTarget))
          },
          {
            type: 'text',
            text: this.numberWithCommas(Math.round(this.totalProduced)),
            class: this.performanceTargetClass(
              this.totalProduced,
              this.totalTargetProductionPercent
            )
          },
          {
            type: 'text',
            text: this.numberWithCommas(Math.round(this.totalScrapped)),
            class: this.qualityTargetClass(this.totalProduced, this.totalScrapProductionPercent)
          },
          {
            type: 'text',
            text: this.numberWithCommas(Math.round(this.totalProduced - this.totalScrapped)),
            class: this.qualityTargetClass(this.totalProduced, this.totalScrapProductionPercent)
          }
        ]"
        :data="table_data"
      />
    </div>
    <h2 class="summaryProduction">
      {{ $t("Total Production Time") }}: {{ seconds(sumValue(machines, ["duration"])) }}
    </h2>

    <div
      class="backBreadcrumb"
      v-if="isMachineFilterView(filterViewType)"
    >
      <v-tabs
        class="smalltabs"
        @change="changePartView"
        v-model="summaryChartTypes.indexOf(chartType)"
      >
        <v-tab
          class="smalltab"
          v-for="(m, idx) in summaryChartTypes"
          :key="idx"
          >{{ $t(m) }}</v-tab
        >
      </v-tabs>
    </div>
    <div
      class="backBreadcrumb"
      v-else
    >
      <span
        class="backButton"
        v-if="filterHistory.length > 0"
        @click="popFilterHistory"
      >
        <i class="fa fa-chevron-left" />
        {{ filterHistory[filterHistory.length - 1].name }}
      </span>
    </div>
    <div class="summaryCharts">
      <div>
        <summary-performance-chart
          key="summary-performance"
          v-if="isMachineFilterView(filterViewType)"
          :production="production"
          :options="options"
          :filters="filters"
          :chartType="chartType"
          :setInteractiveFilter="setInteractiveFilter"
          :interactiveFilters="interactiveFilters"
        />
        <summary-machine-group-performance-chart
          key="summary-mg-performance"
          v-else
          :options="options"
          :machineGroupData="machineGroupData"
          :setNewMachineGroup="setNewMachineGroup"
        />
      </div>
      <div>
        <summary-quality-chart
          key="summary-quality"
          v-if="isMachineFilterView(filterViewType)"
          :production="production"
          :options="options"
          :filters="filters"
          :chartType="chartType"
          :setInteractiveFilter="setInteractiveFilter"
          :interactiveFilters="interactiveFilters"
        />
        <summary-machine-group-quality-chart
          key="summary-mg-quality"
          v-else
          :options="options"
          :machineGroupData="machineGroupData"
          :setNewMachineGroup="setNewMachineGroup"
        />
      </div>
      <div>
        <summary-availability-chart
          key="summary-availability"
          v-if="isMachineFilterView(filterViewType)"
          :production="production"
          :options="options"
          :filters="filters"
          :chartType="chartType"
          :setInteractiveFilter="setInteractiveFilter"
          :interactiveFilters="interactiveFilters"
        />
        <summary-machine-group-availability-chart
          key="summary-mg-availability"
          v-else
          :options="options"
          :machineGroupData="machineGroupData"
          :setNewMachineGroup="setNewMachineGroup"
        />
      </div>
    </div>
  </div>
</template>
<script>
import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { numberWithCommas, seconds } from "@/utils/filters";

import { FILTERVIEWTYPES } from "./MachineGroupSelector";
import SummaryAvailabilityChart from "./summary_charts/SummaryAvailabilityChart";
import SummaryMachineGroupAvailabilityChart from "./summary_charts/SummaryMachineGroupAvailabilityChart";
import SummaryMachineGroupPerformanceChart from "./summary_charts/SummaryMachineGroupPerformanceChart";
import SummaryMachineGroupQualityChart from "./summary_charts/SummaryMachineGroupQualityChart";
import SummaryPerformanceChart from "./summary_charts/SummaryPerformanceChart";
import SummaryQualityChart from "./summary_charts/SummaryQualityChart";

export default {
  name: "SummaryTable.vue",
  components: {
    GenericTable,
    SummaryMachineGroupAvailabilityChart,
    SummaryMachineGroupQualityChart,
    SummaryPerformanceChart,
    SummaryQualityChart,
    SummaryAvailabilityChart,
    SummaryMachineGroupPerformanceChart
  },
  props: [
    "production",
    "machineGroupData",
    "filters",
    "options",
    "setFilters",
    "filterHistory",
    "popFilterHistory",
    "chartType",
    "setSummaryChartType",
    "setInteractiveFilter",
    "interactiveFilters"
  ],
  data() {
    return {
      productionByMachine: {},
      machines: [],
      totalProduced: 0,
      totalScrapped: 0,
      totalProductionTarget: 0,
      totalTargetProductionPercent: 0,
      totalScrapProductionPercent: 0,
      filterViewType: "",
      summaryChartTypes: ["Machine", "Part"],
      table_data: []
    };
  },
  created() {
    this.createMachineTotals();
  },
  methods: {
    seconds,
    numberWithCommas,
    createCSVFromRows,
    downloadCsv() {
      const rows = [];
      rows.push([
        this.$t("Machine"),
        this.$t("Target (pcs)"),
        this.$t("Total Produced (pcs)"),
        this.$t("Quality Losses (pcs)"),
        this.$t("Net Produced (pcs)")
      ]);
      for (const m of this.machines) {
        rows.push([
          m.machine_name,
          Math.round(m.production_target),
          Math.round(m.produced),
          Math.round(m.scrapped),
          Math.round((m.produced || 0) - (m.scrapped || 0))
        ]);
      }
      this.createCSVFromRows(rows, `summary_${new Date().toISOString()}`);
    },
    changePartView(view) {
      let type = "";

      switch (view) {
        case 0:
          type = SUMMARYCHARTTYPE.MACHINE;
          break;
        case 1:
          type = SUMMARYCHARTTYPE.PART;
          break;
      }

      this.setSummaryChartType(type);
    },
    isMachineFilterView(viewType) {
      return viewType === FILTERVIEWTYPES.GROUPMACHINES;
    },
    setNewMachineGroup(zone, reportZoneLevel, groupLevelName) {
      this.setFilters(
        { zone: zone, reportZoneLevel: { level: reportZoneLevel - 1 } },
        groupLevelName
      );
    },
    performanceTargetClass(actual, target) {
      if (actual < target) {
        return "badColor";
      } else {
        return "goodColor";
      }
    },
    qualityTargetClass(number, targetNumber) {
      if (number < targetNumber) {
        return "badColor";
      } else {
        return "goodColor";
      }
    },
    createMachineTotals() {
      this.filterViewType = this.filters.filterViewType;

      const machinesDict = {};
      this.totalProduced = 0;
      this.totalScrapped = 0;
      this.totalProductionTarget = 0;
      this.totalTargetProductionPercent = 0;
      this.totalScrapProductionPercent = 0;

      if (this.production) {
        for (const p of this.production) {
          for (const m of p.by_machine) {
            if (machinesDict[m.machine.machine_id] == null) {
              machinesDict[m.machine.machine_id] = {
                machine_name: m.machine.machine_name,
                machine_id: m.machine.machine_id,
                produced: 0,
                production_target: 0,
                scrapped: 0,
                duration: 0,
                scrap_threshold: 0,
                performanceTarget: this.options.targets.perfByMachine[m.machine.machine_id],
                qualityTarget: this.options.targets.qualityByMachine[m.machine.machine_id],
                runtimeTarget: this.options.targets.runtimeTargets[m.machine.machine_id]
              };
            }

            machinesDict[m.machine.machine_id].produced += m.produced;
            machinesDict[m.machine.machine_id].production_target += m.production_target;
            machinesDict[m.machine.machine_id].scrapped += m.scrapped;
            machinesDict[m.machine.machine_id].scrap_threshold += m.scrap_threshold;
            machinesDict[m.machine.machine_id].duration += m.runtime.duration;

            this.totalProduced += m.produced;
            this.totalScrapped += m.scrapped;
            this.totalProductionTarget += m.production_target;
            this.totalTargetProductionPercent +=
              m.production_target *
              (this.options.targets.perfByMachine[m.machine.machine_id] / 100);
            this.totalScrapProductionPercent +=
              m.produced * (this.options.targets.qualityByMachine[m.machine.machine_id] / 100);
          }
        }

        //add the dictionary items to an array.
        this.machines = [];
        for (const m of Object.keys(machinesDict)) {
          this.machines.push(machinesDict[m]);
        }
      }
    },
    sumValue(dataset, fields) {
      let sum = 0;
      for (const d of dataset) {
        let temp = d;
        for (const f of fields) {
          temp = temp[f];
        }
        sum += temp;
      }
      return sum;
    }
  },
  watch: {
    production: function () {
      this.createMachineTotals();
    },
    machineGroupData: function () {
      this.createMachineTotals();
    },
    machines: function () {
      const table = [];
      this.machines.forEach((m, i) => {
        table.push({
          machine_name: m.machine_name,
          production_target: m.production_target,
          produced: m.produced,
          scrapped: m.scrapped,
          netproduced: m.produced - m.scrapped,
          _class: {
            machine_name: null,
            production_target: null,
            produced: this.performanceTargetClass(
              m.produced,
              m.production_target * (m.performanceTarget / 100)
            ),
            scrapped: this.qualityTargetClass(
              m.produced - m.scrapped,
              (m.produced * m.qualityTarget) / 100
            ),
            netproduced: this.qualityTargetClass(
              m.produced - m.scrapped,
              (m.produced * m.qualityTarget) / 100
            )
          }
        });
      });

      this.table_data = table;
    }
  }
};

export const SUMMARYCHARTTYPE = {
  MACHINE: "Machine",
  PART: "Part"
};
</script>
<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.analyticsSummaryTable {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .backBreadcrumb {
    flex: unset;
    padding-top: 10px;
    .backButton {
      color: $blue;
      padding: 5px;
      cursor: pointer;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .summaryCharts {
    display: flex;
    overflow: hidden;
    flex: 1;
    div {
      margin: 1px;
      flex: 1;
      overflow: hidden;
    }
  }

  .flex-table {
    flex: unset;
    height: 250px;

    .machine-name {
      color: $blue;
    }

    .bold {
      font-weight: bold;
    }

    .badColor {
      color: $red;
    }

    .goodColor {
      color: $green;
    }

    tfoot {
      position: sticky;
      bottom: 0;

      .theme--dark & {
        background: $grey;
      }

      .theme--light & {
        background: $light-background;
      }
    }
  }

  td {
    text-align: left !important;
  }
}
</style>
