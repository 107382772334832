import { ref, watch } from "@vue/composition-api";

export function useFilters({ filters, isOpen, emit, defaultValues }) {
  const localFilters = ref(Object.assign({}, filters));

  const clearFilters = () => {
    return Object.assign(localFilters.value, defaultValues);
  };

  const cancelFilters = () => {
    emit("input", false);
    return Object.assign(localFilters.value, filters);
  };

  const handleSearchChange = (value, name) => {
    localFilters.value[name] = Array.isArray(value) && !value.length ? [] : value;
  };

  watch(
    () => isOpen,
    (val) => {
      if (val) {
        Object.assign(localFilters.value, filters);
      }
    }
  );

  return {
    localFilters,
    clearFilters,
    cancelFilters,
    handleSearchChange
  };
}
