var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{staticClass:"oee-chart metric-component"},[_c('div',{staticClass:"mobile-chart-title-container"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("OEE")))]),_vm._v(" "),_c('span',{staticClass:"mobile-gauge-container"},[(_vm.oee_sum)?_c('v-gauge',{attrs:{"gaugeValueClass":"gaugeValue","value":_vm.oee_sum,"donut":true,"options":_vm.gaugeOpts}}):_vm._e()],1)]),_vm._v(" "),(!_vm.loaded)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):(_vm.type === 'graph')?_c('div',{staticClass:"charts"},[_c('div',{staticClass:"gauge-container"},[(_vm.oee_sum)?_c('v-gauge',{attrs:{"gaugeValueClass":"gaugeValue","value":_vm.oee_sum,"donut":true,"options":_vm.gaugeOpts}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"charts_container"},[_c('div',{staticClass:"chart"},[(_vm.chartData)?_c('bar-chart',{staticClass:"productionChart",attrs:{"chartName":"productionChart","chartData":_vm.chartData,"commas":true,"chartOptions":_vm.chartOptions,"uom":"%"}}):_vm._e()],1)])]):(_vm.type === 'table')?_c('div',{staticClass:"charts"},[_c('generic-table',{attrs:{"columns":[
          { title: '', key: 'date' },
          { title: _vm.$t('OEE'), key: 'oee', format: function (value) { return (value + "%"); }, sortable: false },
          {
            title: _vm.$t('Performance'),
            key: 'performance',
            format: function (value) { return (value + "%"); },
            sortable: false
          },
          {
            title: _vm.$t('Quality'),
            key: 'quality',
            format: function (value) { return (value + "%"); },
            sortable: false
          },
          {
            title: _vm.$t('Availability'),
            key: 'availability',
            format: function (value) { return (value + "%"); },
            sortable: false
          }
        ],"footer":[{ type: 'csv', csv: _vm.generate_csv }, {}, {}, {}, {}],"data":_vm.table_data}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }