var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-by-machine-container"},[_c('div',{staticClass:"search-send-container"},[_c('v-tabs',{key:"submitted-tabs",staticClass:"submitted-tabs",attrs:{"hide-slider":""},model:{value:(_vm.selectedSubmittedTab),callback:function ($$v) {_vm.selectedSubmittedTab=$$v},expression:"selectedSubmittedTab"}},[_c('v-tab',{key:"submitted"},[_c('p',[_c('span',{staticClass:"button_text"},[_vm._v(_vm._s(_vm.$t("Submitted")))]),_vm._v(" "),_c('span',{staticClass:"tiny_button_text"},[_vm._v(_vm._s(_vm.$t("Submitted")))])])]),_vm._v(" "),_c('v-tab',{key:"unsubmitted"},[_c('p',[_c('span',{staticClass:"button_text"},[_vm._v(_vm._s(_vm.$t("Unsubmitted")))]),_vm._v(" "),_c('span',{staticClass:"tiny_button_text"},[_vm._v(_vm._s(_vm.$t("Unsubmitted")))])])])],1),_vm._v(" "),_c('div',{staticClass:"machine-search"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.orderByMachineSearchText),callback:function ($$v) {_vm.orderByMachineSearchText=$$v},expression:"orderByMachineSearchText"}})],1),_vm._v(" "),_c('v-checkbox',{staticClass:"my-machines-checkbox",class:{
        'no-send': _vm.selectedSubmittedTab === 0,
        'send-present': _vm.selectedSubmittedTab === 1
      },attrs:{"label":_vm.$t('My Machines')},model:{value:(_vm.showMyMachines),callback:function ($$v) {_vm.showMyMachines=$$v},expression:"showMyMachines"}}),_vm._v(" "),(_vm.selectedSubmittedTab === 1)?_c('v-btn',{staticClass:"send-button",attrs:{"color":"primary","large":"","disabled":_vm.disableOrderByMachineSendToERPButton},on:{"click":_vm.handleSendToERP}},[_vm._v("\n      "+_vm._s(_vm.$t("Send to ERP"))+"\n    ")]):_vm._e()],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.scrapOrderByMachineHeaders,"items":_vm.filteredTableData,"loading":_vm.scrapOrderByMachineLoading,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100] },"loading-text":_vm.$t('Loading Scrap Details... Please wait'),"no-data-text":_vm.$t('No data available'),"expanded":_vm.expanded,"item-key":"name","fixed-header":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.handleExpand},scopedSlots:_vm._u([{key:"item.transmit",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.selectedSubmittedTab === 0},model:{value:(item.transmit),callback:function ($$v) {_vm.$set(item, "transmit", $$v)},expression:"item.transmit"}})]}},{key:"body.prepend",fn:function(){return [(_vm.scrapOrderByMachineData.length > 0)?_c('tr',{staticClass:"total-row"},[_c('td',{staticClass:"total-text"},[_c('span',[_vm._v(_vm._s(_vm.$t("Total")))])]),_vm._v(" "),_c('td'),_vm._v(" "),(_vm.expanded.length === 0)?_c('td',{staticClass:"total-cost"},[_c('span',[_vm._v(_vm._s(_vm.totalCost))])]):_vm._e(),_vm._v(" "),(_vm.expanded.length === 0)?_c('td',{staticClass:"select-all-button"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.selectedSubmittedTab === 0},on:{"click":_vm.selectAll}},[_vm._v("\n            "+_vm._s(_vm.$t("Select All"))+"\n          ")])],1):_vm._e(),_vm._v(" "),(_vm.expanded.length > 0)?_c('td'):_vm._e(),_vm._v(" "),(_vm.expanded.length > 0)?_c('td'):_vm._e(),_vm._v(" "),(_vm.expanded.length > 0)?_c('td'):_vm._e(),_vm._v(" "),(_vm.expanded.length > 0)?_c('td',{staticClass:"total-cost"},[_c('span',[_vm._v(_vm._s(_vm.totalCost))])]):_vm._e()]):_vm._e()]},proxy:true},{key:"expanded-item",fn:function(ref){
      var item = ref.item;
return [_c('td',_vm._l((item.items),function(data,index){return _c('div',{staticClass:"expanded-cell"},[(index === item.items.length - 1)?_c('span',[_vm._v(_vm._s(_vm.$t("Sub Total")))]):_vm._e()])}),0),_vm._v(" "),_c('td',_vm._l((item.items),function(data){return _c('div',{staticClass:"expanded-cell"})}),0),_vm._v(" "),_c('td',_vm._l((item.items),function(data){return _c('div',{staticClass:"expanded-cell"},[_c('span',[_vm._v(_vm._s(data.component))])])}),0),_vm._v(" "),_c('td',_vm._l((item.items),function(data){return _c('div',{staticClass:"expanded-cell"},[_c('span',{staticClass:"description-text",attrs:{"title":data.description}},[_vm._v(_vm._s(data.description))])])}),0),_vm._v(" "),_c('td',_vm._l((item.items),function(data){return _c('div',{staticClass:"expanded-cell"},[_c('span',[_vm._v(_vm._s(data.enteredQuantity)+" "+_vm._s(data.uom))])])}),0),_vm._v(" "),_c('td',_vm._l((item.items),function(data,index){return _c('div',{staticClass:"expanded-cell"},[(index === item.items.length - 1)?_c('span',[_vm._v(_vm._s(data.formattedSubTotal))]):_c('span',[_vm._v(_vm._s(data.formattedCost))])])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }