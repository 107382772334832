var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"node-tree"},[_c('span',{staticClass:"label"},[_c('v-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate(),"value":_vm.isChecked()},on:{"change":function (e) {
          _vm.handleSelectMachineGroups(e, _vm.node);
        }}}),_vm._v(" "),_c('v-btn',{on:{"click":_vm.expand}},[_c('p',[_vm._v(_vm._s(_vm.node.name))]),_vm._v(" "),_c('i',{class:("expand-icon fa fa-chevron-right " + (_vm.expanded ? 'expanded' : 'unexpanded'))})])],1),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.node.machines &&
      _vm.node.machines.length > 0 &&
      ((!_vm.searchText && _vm.expanded) || (_vm.searchText && (_vm.machineMatch || _vm.node.searchMatch)))
    ),expression:"\n      node.machines &&\n      node.machines.length > 0 &&\n      ((!searchText && expanded) || (searchText && (machineMatch || node.searchMatch)))\n    "}],staticClass:"machines"},[_c('span',{staticClass:"label"},[_vm._v("\n      "+_vm._s(_vm.$t("Machines"))+"\n    ")]),_vm._v(" "),_vm._l((_vm.node.machines),function(machine,m_index){return (!_vm.searchText || machine.searchMatch || (!_vm.machineMatch && _vm.node.searchMatch))?_c('li',[_c('span',{staticClass:"label"},[_c('v-checkbox',{ref:"machines",refInFor:true,attrs:{"label":machine.name,"value":_vm.machineChecked(machine.pk)},on:{"change":function (e) {
              _vm.handleSelectMachine(e, machine.pk, _vm.node);
            }}})],1)]):_vm._e()})],2),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.node.children && _vm.node.children.length),expression:"node.children && node.children.length"}]},_vm._l((_vm.node.children),function(child,idx){return _c('listNode',{directives:[{name:"show",rawName:"v-show",value:((!_vm.searchText && _vm.expanded) || (_vm.searchText && child.searchMatch)),expression:"(!searchText && expanded) || (searchText && child.searchMatch)"}],key:idx,ref:"children",refInFor:true,attrs:{"searchText":_vm.searchText,"node":child,"changeMachineGroups":function (e) {
          _vm.handleSelectMachineGroups(e, child);
        },"changeMachines":_vm.handleSelectMachine}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }