var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',_vm._g(_vm._b({staticClass:"button",class:{
    primary: _vm.variant === 'primary',
    secondary: _vm.variant === 'secondary',
    error: _vm.variant === 'error',
    transparent: Boolean(_vm.transparent),
    text: _vm.text,
    'rounded-lg': 'large' in _vm.$attrs && !('icon' in _vm.$attrs)
  },attrs:{"text":""}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }