<template>
  <div class="completed-audits card">
    <v-data-table
      height="calc(100% - 3.25rem)"
      item-key="id"
      :items="completedTable.data"
      :headers="completedTable.headers"
      :sortBy.sync="completedTable.sortBy"
      :sortDesc.sync="completedTable.sortDesc"
      :page.sync="completedTable.page.current"
      :itemsPerPage.sync="completedTable.page.itemsPerPage"
      :serverItemsLength="completedTable.total"
      :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
      :loading-text="$t('Loading Audits... Please wait')"
      disable-pagination
      fixed-header
    >
      <template v-slot:[`item.submitTime`]="{ item }">
        {{ time(item.node.submitTime) }}
      </template>
      <template v-slot:[`item.submitUser.fullName`]="{ item }">
        {{ item.node.submitUser ? item.node.submitUser.fullName : "" }}
      </template>
      <template v-slot:[`item.form.type.name`]="{ item }">
        {{ item.node.form.type ? item.node.form.type.name : "" }}
      </template>

      <template v-slot:[`item.machine.name`]="{ item }">
        {{ item.node.machine ? item.node.machine.name : "" }}
      </template>

      <template v-slot:[`item.form.version`]="{ item }">
        {{ item.node.form.version }}
      </template>
      <template v-slot:[`item.responseFindings`]="{ item }">
        {{ item.node.responseFindings }}
      </template>
      <template v-slot:[`item.link`]="{ item }">
        <v-btn
          class="actionButton"
          small
          color="success"
          fab
          @click.stop.prevent="gotToAudit(item)"
        >
          <v-icon>mdi-format-list-checks</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.completed-audits {
  margin-top: 0.25rem;
  height: calc(100% - 4rem);
  padding-bottom: 0.5rem;
  .v-data-table {
    height: 100%;
  }
}
</style>

<script>
import moment from "moment";

import { TableData } from "@/datatypes/table";
import { seconds, time } from "@/utils/filters";

export default {
  props: ["options"],
  data() {
    return {
      lastOptions: {},
      completedTable: new TableData([
        { text: this.$t("Date"), value: "submitTime" },
        { text: this.$t("User"), value: "submitUser.fullName" },
        { text: this.$t("Audit Type"), value: "form.type.name" },
        { text: this.$t("Machine Name"), value: "machine.name" },
        { text: this.$t("Audit Version"), value: "form.version" },
        { text: this.$t("Findings Count"), value: "responseFindings" },
        { text: this.$t("Link"), value: "link" }
      ])
    };
  },
  created() {
    this.lastOptions = this.options;
    this.completedTable.loading = true;
    this.loadData();
  },

  methods: {
    moment,
    seconds,
    time,
    //// TODO: need to support sorting, searching, filtering (be needs to be updated to django connection field)
    loadData() {
      this.completedTable.loading = true;
      const query = `query(
        $first: Int,
        $last: Int,
        $after: String,
        $before: String
      ){
        responseForms(
          submitted: true
          first: $first,
          last: $last,
          after: $after,
          before: $before){
          totalCount
          edges{
            node{
              id,
              submitTime
              submitUser{
                fullName
              }
              machine{
                name
              }
              responseFindings
              form{
                id
                version
                type{
                  id
                  name
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
          }
        }
      }`;
      const variables = {
        before: false,
        after: false
      };
      if (this.completedTable.page.load) {
        Object.assign(variables, this.completedTable.page.load);
      } else {
        variables["first"] = this.completedTable.page.itemsPerPage;
      }
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        this.completedTable.data = res.data.data.responseForms.edges;
        this.completedTable.total = res.data.data.responseForms.totalCount;
        this.completedTable.page.next = res.data.data.responseForms.pageInfo.endCursor;
        this.completedTable.page.prev = res.data.data.responseForms.pageInfo.startCursor;
        this.completedTable.page.load = false;
        this.completedTable.loading = false;
      });
    },
    gotToAudit(item, body) {
      this.$router.push({
        name: "audit-response",
        params: { auditTypeId: item.node.form.type.id, responseFormId: item.node.id }
      });
    }
  },
  watch: {
    "completedTable.sortBy": function () {
      this.loadData();
    },
    "completedTable.sortDesc": function () {
      this.loadData();
    },
    "completedTable.page.itemsPerPage": function () {
      this.loadData();
    },
    "completedTable.page.current": function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.completedTable.page.load = {
          after: this.completedTable.page.next,
          before: false,
          first: this.completedTable.page.itemsPerPage
        };
        this.loadData();
      } else if (newPage < oldPage) {
        this.completedTable.page.load = {
          after: false,
          before: this.completedTable.page.prev,
          last: this.completedTable.page.itemsPerPage
        };
        this.loadData();
      }
    }
  }
};
</script>
