export default function createCSVFromRows(rows, title) {
  if (rows) {
    let csvContent = "";
    //Iterate though the rows and surround strings with quotes
    try {
      csvContent =
        "data:text/csv;charset=utf-8," +
        rows
          .map((e) => {
            e = e.map((s) => {
              if (typeof s === "string") {
                return `"${s}"`;
              }
              return s;
            });
            return e.join(",");
          })
          .join("\n");
    } catch (e) {
      console.log(e);
    }

    const encodedUri = encodeURI(csvContent).replaceAll("#", "%23");
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${title}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
    link.remove();
  }
}
