<template>
  <transition name="slide-fade">
    <div class="availability-chart metric-component">
      <h2>{{ $t("Availability") }}</h2>
      <v-progress-linear
        indeterminate
        v-if="!(production && production.length > 0)"
      ></v-progress-linear>
      <div
        class="charts"
        v-else
      >
        <div class="gauge-container">
          <v-gauge
            v-if="availabilityPer"
            gaugeValueClass="gaugeValue"
            :value="availabilityPer"
            :donut="true"
            :options="gaugeOpts"
          />
        </div>
        <div class="bar-container">
          <bar-chart
            v-if="chartData"
            chartName="availabilityChart"
            class="availabilityChart"
            uom="%"
            :decimal="2"
            :chartData="chartData"
            :chartOptions="chartOptions"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.availability-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  .charts {
    display: flex;
    justify-content: center;
    align-items: center;
    .bar-container {
      width: calc(100% - 150px);
      padding-left: 0.5rem;
      .availabilityChart {
        width: 100%;
        height: 18vh;
        padding-left: 0.5rem;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import VGauge from "vgauge";
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart.js";
import { seconds } from "@/utils/filters";
import { useMachineStatusHexColor } from "@/features/machine-status/hooks/useMachineStatusHexColor";

export default {
  components: {
    BarChart,
    VGauge
  },
  props: ["options", "production"],
  data() {
    return {
      loaded: false,
      availabilityPer: 1,
      chartData: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      }
    };
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    format_data() {
      let datasets = [];
      let labels = [];
      let titles = [];
      let total_availability = 0;
      let total_time = 0;

      if (this.production) {
        this.statuses.forEach((status) => {
          datasets.push({
            status: status,
            data: new Array(this.production.length).fill(0),
            footers: new Array(this.production.length),
            titles: [],
            label: status.name,
            backgroundColor: useMachineStatusHexColor(status)
          });
        });
        this.production.forEach((interval, data_index) => {
          this.statuses.forEach((status, dataset_index) => {
            let dataset = datasets[dataset_index];
            dataset.footers[data_index] = [];
            dataset.titles.push(this.options.labelFromToString(interval.key_as_string));
            const status_codes = interval.codes || interval.status_codes;
            status_codes.buckets.forEach((interval_code) => {
              let found = status.statuscodeSet.find((bucket_code) => {
                return bucket_code.code == interval_code.key;
              });
              if (found) {
                dataset.data[data_index] += interval_code.duration.value;
                dataset.footers[data_index].push(
                  found.code +
                    " - " +
                    found.description +
                    " : " +
                    seconds(interval_code.duration.value)
                );
              }
            });

            if (status.running) {
              total_availability += dataset.data[data_index];
            }
            if (!status.plannedDown) {
              total_time += dataset.data[data_index];
            }
            dataset.data[data_index] = (dataset.data[data_index] / interval.duration.value) * 100;
          });
          labels.push(this.options.labelFromString(interval.key_as_string));
        });
      }
      this.availabilityPer = (total_availability / total_time) * 100;
      this.chartData = {
        datasets: datasets,
        labels: labels
      };
    }
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    }),
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false, stacked: true }],
          xAxes: [{ stacked: true }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      deep: true,
      handler: "format_data"
    },
    theme: {
      handler: "format_data"
    }
  }
};
</script>
