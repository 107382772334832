<template>
  <div class="card full-height">
    <div class="header">
      <h4>{{ $t("Minor Losses") }}</h4>
      <div class="icons">
        <i
          class="fa fa-table"
          v-bind:class="chartType === chartTypes.TABLE ? 'selected' : null"
          @click="
            () => {
              chartType = chartTypes.TABLE;
            }
          "
        />
        <i
          class="fa fa-bar-chart"
          v-bind:class="chartType === chartTypes.CHART ? 'selected' : null"
          @click="
            () => {
              chartType = chartTypes.CHART;
            }
          "
        />
      </div>
    </div>
    <div
      class="chart-container"
      v-if="chartType == chartTypes.CHART && !loading"
    >
      <TreeMapChart
        v-if="minorLosses"
        class="tree-map"
        :data="minorLosses.filter((loss) => this.localFiltered.indexOf(loss.data.status_code) < 0)"
        label-format="{name} {value}%"
        :id="'tree-map'"
        :handle-click="handleClick"
      />

      <div
        class="legend"
        v-if="minorLosses"
      >
        <div
          v-for="(loss, i) in minorLosses"
          class="legend-container"
          @click="
            () => {
              handleClickLegend(loss.data.status_code);
            }
          "
        >
          <div
            class="swatch"
            :class="machineStatusClassName(loss.data)"
          >
            <i
              class="cross-out mdi mdi-close"
              v-bind:style="{
                visibility: localFiltered.indexOf(loss.data.status_code) < 0 ? 'hidden' : 'visible'
              }"
            />
          </div>
          <div class="label">{{ loss.name }} {{ loss.value }}%</div>
        </div>
      </div>
    </div>

    <div
      class="chart-container"
      v-else-if="chartType == chartTypes.TABLE && !loading"
    >
      <GenericTable
        :columns="[
          { title: '', key: 'name' },
          { title: $t('Duration'), key: 'duration', format: seconds, sortable: true },
          { title: $t('Total Downtime'), key: 'total_downtime', format: seconds, sortable: false },
          {
            title: $t('Downtime Percent'),
            key: 'downtime_percentage',
            format: percent,
            sortable: true
          },
          { title: $t('Production Loss'), key: 'loss', format: percent, sortable: true }
        ]"
        :data="tableData"
      />
    </div>

    <v-progress-linear
      indeterminate
      v-else
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import { seconds } from "@/utils/filters";
import { useMachineStatusClassName } from "@/features/machine-status/hooks/useMachineStatusClassName";

import TreeMapChart from "../../../components/charts/TreeMapChart";
import useTLAFilters from "./useTLAFilters";

const chartTypes = {
  CHART: "chart",
  TABLE: "table"
};

export default {
  name: "TLA_Minor_Losses",
  components: { GenericTable, TreeMapChart },
  props: ["filters", "priority"],
  setup() {
    const {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    } = useTLAFilters();
    return {
      interactiveFilters,
      filterApplied,
      priorityApplied,
      hasFilters,
      toggleFilter,
      removeAllFilters,
      copyFilterTo
    };
  },
  data() {
    return {
      minorLosses: [],
      rawData: {},
      statusMap: {},
      totalDowntime: 0,
      totalDuration: 0,
      chartType: chartTypes.CHART,
      chartTypes: chartTypes,
      tableData: [],
      filterName: "minor_losses",
      loading: 0,
      cancelToken: null,
      localFiltered: []
    };
  },
  mounted() {
    this.statusMap = this.createStatusMap();
    this.loadLosses()
      .then((result) => {
        this.rawData = result;
        this.minorLosses = this.createMinorLosses(result);
        this.tableData = this.createTableData(result);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    })
  },
  methods: {
    seconds,
    handleClick(e) {
      const status_code = e.target.dataItem._dataContext._dataContext.data.status_code;
      this.toggleFilter(this.filterName, status_code, this.priority);

      let status_code_string = "";
      for (const m of this.statusMap[status_code].statuscodeSet) {
        if (m.code == status_code) {
          status_code_string = `${m.code}:${m.description}`;
        }
      }

      this.minorLosses = this.createMinorLosses(this.rawData);
    },
    handleClickLegend(status_code) {
      const index = this.localFiltered.indexOf(status_code);
      if (index < 0) {
        this.localFiltered.push(status_code);
      } else {
        this.localFiltered.splice(index, 1);
      }

      this.minorLosses = [...this.createMinorLosses(this.rawData)];
    },
    percent(val) {
      return `${(val * 100).toFixed(2)}%`;
    },
    tooltip(e, visible) {
      if (visible) {
        const tooltip = e.target.getElementsByClassName("tooltip")[0];
        tooltip.classList.add("visible");
        const x = e.clientX,
          y = e.clientY;
        tooltip.style.top = y - 60 + "px";
        tooltip.style.left =
          Math.min(x - tooltip.clientWidth / 2, window.outerWidth - tooltip.clientWidth) + "px";
      } else {
        e.target.getElementsByClassName("tooltip")[0].classList.remove("visible");
      }
    },
    createStatusMap() {
      const statusMap = {};
      for (const status of this.statuses) {
        for (const statusCode of status.statuscodeSet) {
          statusMap[statusCode.code] = { ...status, ...statusCode };
        }
      }
      return statusMap;
    },
    createTableData(result) {
      const tableData = [];
      for (const r of result.buckets) {
        const statusCode = r.key;
        const status = this.statusMap[statusCode];
        if (status && !status.running) {
          tableData.push({
            name: `${status.code}: ${status.description}`,
            duration: r.duration.value,
            total_downtime: this.totalDowntime,
            downtime_percentage: r.duration.value / this.totalDowntime,
            loss: r.duration.value / (this.totalDuration || 1)
          });
        }
      }
      return tableData;
    },
    createMinorLosses(result) {
      this.totalDowntime = 0;
      this.totalDuration = 0;
      let lossesMap = {};
      for (const r of result.buckets) {
        const statusCode = r.key;
        const status = this.statusMap[statusCode];
        if (status && !status.running) {
          if (lossesMap[status.code] == null) {
            lossesMap[status.code] = {
              name: status.name,
              value: r.duration.value,
              colorSet: status.color_set || status.colorSet,
              description: status.description,
              code: status.code
            };
          }
          this.totalDowntime += r.duration.value;
        }
        this.totalDuration += r.duration.value;
      }

      const treeMapData = Object.keys(lossesMap)
        .map((key) => {
          return {
            name: lossesMap[key].description,
            value: `${((lossesMap[key].value / (this.totalDowntime || 1)) * 100).toFixed(2)}`,
            colorSet: lossesMap[key].colorSet,
            data: { status_code: lossesMap[key].code }
          };
        })
        .sort((a, b) => b.value - a.value);

      return [...treeMapData];
    },
    loadLosses() {
      return new Promise((resolve, reject) => {
        //guard
        if (!this.filters || !this.filters.from_date || !this.filters.to_date) {
          reject();
        } else {
          let params = {
            from_date: this.filters.from_date,
            to_date: this.filters.to_date
          };

          if (this.hasFilters) {
            if (
              this.interactiveFilters["machine_id"] &&
              this.interactiveFilters["machine_id"].length > 0
            ) {
              params.machine_id = this.interactiveFilters["machine_id"].join(",");
            } else if (this.filters.machines.length) {
              params.machine_id = this.filters.machines.join(",");
            } else if (this.filters.machine_groups.length) {
              params.machine_id = this.filters.machine_groups.join(",");
            }

            if (
              this.interactiveFilters["part_numbers"] &&
              this.interactiveFilters["part_numbers"].length > 0
            ) {
              params.part_numbers = this.interactiveFilters["part_numbers"].join(",");
            } else if (this.filters.part_numbers.length) {
              params.part_numbers = this.filters.part_numbers.join(",");
            }

            if (
              this.interactiveFilters["minor_losses"] &&
              this.interactiveFilters["minor_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["minor_losses"].join(",");
            } else if (
              this.interactiveFilters["major_losses"] &&
              this.interactiveFilters["major_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["major_losses"].join(",");
            } else if (this.filters.status_codes.length) {
              params.status_codes = this.filters.status_codes.join(",");
            }
          } else {
            params.machine_id = this.filters.machines.length
              ? this.filters.machines.join(",")
              : null;
            params.part_numbers = this.filters.part_numbers.length
              ? this.filters.part_numbers.join(",")
              : null;
            params.status_codes = this.filters.status_codes.length
              ? this.filters.status_codes.join(",")
              : null;
          }

          if (this.cancelSource) {
            this.cancelSource.cancel();
          }
          const CancelToken = axios.CancelToken;
          this.cancelSource = CancelToken.source();

          this.loading++;

          this.$http
            .get("metrics/status_code_percent/", {
              params: params,
              cancelToken: this.cancelSource.token
            })
            .then((result) => {
              this.loading--;
              this.cancelSource = null;
              resolve(result.data);
            })
            .catch((e) => {
              this.loading--;
              this.cancelSource = null;
              console.log(e);
            });
        }
      });
    },
    machineStatusClassName(status) {
      return useMachineStatusClassName(status);
    }
  },
  watch: {
    filters: function () {
      this.statusMap = this.createStatusMap();
      this.loadLosses()
        .then((result) => {
          this.rawData = result;
          this.minorLosses = this.createMinorLosses(result);
          this.tableData = this.createTableData(result);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    interactiveFilters: function () {
      if (this.filterApplied !== this.filterName) {
        this.loadLosses()
          .then((result) => {
            this.rawData = result;
            this.minorLosses = this.createMinorLosses(result);
            this.tableData = this.createTableData(result);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: unset;

  i {
    padding-left: 10px;
    cursor: pointer;

    &.selected {
      color: $blue;
    }
  }
}

.chart-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .tree-map {
    height: 100%;
  }

  .legend {
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    flex-wrap: wrap;

    .legend-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 8px 10px;
      cursor: pointer;

      .swatch {
        width: 18px;
        height: 18px;
        position: relative;

        .cross-out {
          visibility: hidden;
          font-size: 13px;
          position: absolute;
          text-align: center;
          width: 100%;
        }
      }

      .label {
        padding-left: 5px;
        font-size: 12px;
      }
    }
  }
}

.full-height {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

h4 {
  color: $blue;
  flex: unset;
}
</style>
