var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"availabilityHourByHourTable"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("Availability")))]),_vm._v(" "),_c('generic-table',{attrs:{"columns":[
      { title: _vm.$t('Time'), key: 'time' },
      { title: _vm.$t('Part Number'), key: 'part_number' },
      { title: _vm.$t('Target'), key: 'target' },
      { title: _vm.$t('Actual'), key: 'actual' }
    ],"nested_columns":[{ key: '' }, { key: 'part_number' }, { key: 'target' }, { key: 'actual' }],"footer":[
      { type: 'csv', csv: _vm.downloadCsv },
      { type: 'text', text: _vm.$t('Total') },
      { type: 'text', text: this.seconds(_vm.totalTarget) },
      { type: 'text', text: this.seconds(_vm.totalRunning) }
    ],"data":_vm.tableData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }