<template>
  <v-dialog
    v-model="showDialogue"
    persistent
    width="70%"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <div class="dialogBtn">
          <v-btn
            class="edit-btn"
            v-bind="attrs"
            v-on="on"
            color="#f7bd59"
            light
            fab
            small
          >
            <i
              class="fa fa-plus"
              aria-hidden="true"
            ></i>
          </v-btn>
        </div>
      </v-hover>
    </template>
    <v-card>
      <DialogCloseButton :onClick="clearData" />
      <v-card-title class="headline pb-5">
        {{ $t("Submit Safety Event") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <HumanDiagram
            type="event"
            :affectedAreas="affectedAreas"
            @pick-point="handleBodyPartClick"
          />

          <v-col cols="4">
            <v-row>
              <div>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="incidentDate"
                      :label="$t('Incident Date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="incidentDate"
                    :max="today"
                    :locale="$i18n.locale()"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-row>
            <v-row>
              <h3 style="display: inline">
                {{ $t("Machine Group") }}
              </h3>
              <button
                class="selectButton"
                @click="showGroupSelectionDialog = true"
              >
                <i class="fa fa-plus-circle" />
              </button>
              <v-dialog
                v-model="showGroupSelectionDialog"
                width="33%"
                persistent
              >
                <v-card>
                  <v-card-title>{{ $t("Machine Group") }}</v-card-title>
                  <v-card-text>
                    <tree-radio
                      :groups="machineGroups"
                      :safety-group-level="safetyGroupLevel"
                      :selected-group="machineGroupId"
                      :handle-machine-group-change="handleMachineGroupChange"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="showGroupSelectionDialog = false"
                      color="error"
                      text
                      >{{ $t("Close") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            <v-row
              class="mb-1 mt-5"
              v-if="machineGroupName"
            >
              <v-chip
                closeable
                @click="clearMachineGroup"
                >{{ machineGroupName }}</v-chip
              >
            </v-row>
            <v-row>
              <h3 style="display: inline">
                {{ $t("Shift") }}
              </h3>
              <button
                v-if="shifts.length > 0"
                class="selectButton"
                @click="showShiftSelectionDialog = true"
              >
                <i class="fa fa-plus-circle" />
              </button>
              <v-dialog
                v-model="showShiftSelectionDialog"
                width="33%"
                persistent
              >
                <v-card>
                  <v-card-title>Shift</v-card-title>
                  <v-card-text>
                    <v-radio-group v-model="shift.id">
                      <v-radio
                        v-for="_shift in shifts"
                        :key="_shift.pk"
                        :label="_shift.name"
                        :value="_shift.pk"
                        @click="shift.name = _shift.name"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="showShiftSelectionDialog = false"
                      color="error"
                      text
                      >{{ $t("Close") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            <v-row
              v-if="shift.name"
              class="mt-5"
            >
              <v-chip>{{ shift.name }}</v-chip>
            </v-row>
            <v-row>
              <v-textarea
                :label="$t('Description')"
                v-model="incidentDescription"
                maxlength="500"
                counter
                auto-grow
                rows="3"
              />
            </v-row>
            <v-row>
              <v-textarea
                :label="$t('Corrective Action')"
                v-model="correctiveAction"
                maxlength="500"
                auto-grow
                rows="3"
              />
            </v-row>
            <v-row>
              <v-radio-group
                v-model="selectedOption"
                @change="onRadioChange"
              >
                <v-radio
                  :label="$t('First Aid')"
                  value="firstAid"
                />
                <v-radio
                  :label="$t('Recordable')"
                  value="recordable"
                />
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row width="100%">
          <p>
            {{ $t("Selected body parts:") }}
            <span v-if="affectedAreas.length === 0">({{ $t("None") }})</span>
          </p>
          <ul>
            <li v-for="bp in affectedAreas">
              <p>{{ $t(camelCaseToTitleCase(bp)) }}</p>
            </li>
          </ul>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="
            showDialogue = false;
            clearData();
          "
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          primary
          text
          :disabled="disableSubmit"
          :loading="saving"
          @click="() => createIssue(existingIssueId)"
        >
          {{ $t("Submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

import DialogCloseButton from "@/components/DialogCloseButton";

import TreeRadio from "../TreeRadio.vue";
import HumanDiagram from "../zone_control/safety_body_diagram/HumanDiagram.vue";

export default {
  name: "SafetyEvent",
  props: {
    selectedEvent: {
      type: Object,
      default: null
    }
  },
  components: { HumanDiagram, TreeRadio, DialogCloseButton },
  data() {
    return {
      affectedAreas: [],
      correctiveAction: "",
      firstAid: false,
      incidentDate: new Date().toISOString().slice(0, 10),
      incidentDescription: "",
      menu: false,
      recordable: false,
      saving: false,
      showDialogue: false,
      machineGroups: [],
      machineGroupId: null,
      machineGroupName: "",
      shifts: [],
      shift: { id: null, name: "" },
      showGroupSelectionDialog: false,
      showShiftSelectionDialog: false,
      safetyGroupLevel: null,
      selectedOption: null,
      existingIssueId: null
    };
  },
  computed: {
    today() {
      return new Date().toISOString().slice(0, 10);
    },
    disableSubmit() {
      return !(
        this.incidentDate &&
        this.incidentDescription &&
        this.correctiveAction &&
        this.machineGroupId
      );
    }
  },
  methods: {
    handleBodyPartClick(name) {
      if (this.affectedAreas.includes(name)) {
        this.affectedAreas = this.affectedAreas.filter((item) => item !== name);
      } else {
        this.affectedAreas.push(name);
      }
    },
    async createIssue(existingIssueId = null) {
      try {
        this.saving = true;
        const mutationName = existingIssueId ? "updateSafetyEvent" : "createSafetyEvent";
        const inputTypeName = existingIssueId ? "UpdateSafetyEventInput" : "SafetyEventInput";
        const query = `mutation ($input: ${inputTypeName}!) {
      ${mutationName}(safetyData: $input) {
        safetyEvent {
          id
          description
          correctiveAction
          firstAid
          recordable
          affectedBodyPart
          shiftId
          machineGroupId
        }
      }
    }`;
        let input = {
          description: this.incidentDescription,
          correctiveAction: this.correctiveAction,
          firstAid: this.firstAid,
          recordable: this.recordable,
          incidentDate: moment(this.incidentDate).toISOString(),
          affectedBodyPart: this.affectedAreas.join("|"),
          shiftId: this.shift.id,
          machineGroupId: this.machineGroupId
        };
        if (existingIssueId) {
          input.id = existingIssueId;
          input.delete = false;
        }
        let variables = { input };
        const response = await this.$http.post("graphql/", {
          query,
          variables
        });
        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }
        this.saving = false;
        const message = existingIssueId ? "Safety Ticket Updated" : "Safety Ticket Submitted";
        this.$message("ALERT", {
          text: this.$t(message),
          type: "success"
        });
        this.clearData();
      } catch (error) {
        console.error("Error creating/updating the issue:", error);
        this.errors = error.errors;
        this.saving = false;
        this.clearData();
        this.$message("ALERT", { text: "Failed", type: "error" });
      }
      setTimeout(() => {
        this.$emit("safetyEventSubmitted");
      }, 1000);
    },
    clearData() {
      this.incidentDate = new Date().toISOString().slice(0, 10);
      this.correctiveAction = "";
      this.incidentDescription = "";
      this.firstAid = false;
      this.recordable = false;
      this.affectedAreas = [];
      this.showDialogue = false;
      this.machineGroupId = null;
      this.machineGroupName = "";
      this.shiftId = null;
      this.selectedOption = null;
      this.existingIssueId = null;
      this.$emit("clear-selected-event");
    },
    /**
     * @param str the string to convert
     * @returns string
     */
    camelCaseToTitleCase(str) {
      return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },
    async getSafetyGroupLevel() {
      const response = await this.$http.get("graphql/", {
        params: {
          query: `{
           settings{
             safetyGroupLevel {
               pk
               name
               id
               level
             }
           }
         }`
        }
      });
      this.safetyGroupLevel = response.data.data.settings[0].safetyGroupLevel.level;
    },
    formatData(data) {
      const result = [];
      const mapping = {};
      data.forEach((item) => {
        mapping[item.pk] = item;
        item.children = [];
      });
      for (const item of data) {
        if (item.parentGroup === null) {
          result.push(item);
        } else {
          const parentId = item.parentGroup.pk;
          if (mapping[parentId]) {
            mapping[parentId].children.push(item);
          }
        }
      }
      return result;
    },
    async loadMachineGroups() {
      try {
        const res = await this.$http.get("graphql/", {
          params: {
            query: `{
        machineGroups {
          name
          pk
          level {
            level
          }
          parentGroup {
            pk
          }
        }
      }`
          }
        });
        this.machineGroups = this.formatData(res.data.data.machineGroups);
      } catch (error) {
        console.error(error);
      }
    },
    async loadShifts() {
      if (this.machineGroupId === undefined) {
        this.shifts = [];
        return;
      }
      try {
        const response = await this.$http.post("/graphql/", {
          query: `
            query {
              shifts(machineGroupId: ${this.machineGroupId}) {
                pk
                name
              }
            }
          `
        });
        this.shifts = response.data.data.shifts;
      } catch (error) {
        console.error(error);
      }
    },
    handleMachineGroupChange(pk, machineGroupName) {
      this.machineGroupId = pk;
      this.machineGroupName = machineGroupName;
      this.loadShifts();
    },
    clearMachineGroup() {
      this.machineGroupId = null;
      this.machineGroupName = null;
      this.shifts = [];
    },
    onRadioChange() {
      if (this.selectedOption === "firstAid") {
        this.firstAid = true;
        this.recordable = false;
      } else if (this.selectedOption === "recordable") {
        this.firstAid = false;
        this.recordable = true;
      }
    },
    loadEventData(eventData) {
      this.existingIssueId = eventData.id;
      this.incidentDate = eventData.incidentDate.slice(0, 10);
      this.machineGroupId = eventData.machineGroupId;
      this.machineGroupName = this.getMachineGroupName(eventData.machineGroupId);
      this.shiftId = eventData.shiftId;
      this.incidentDescription = eventData.description;
      this.correctiveAction = eventData.correctiveAction;
      this.selectedOption = eventData.selectedOption;
      this.affectedAreas = eventData.affectedBodyPart.split("|");
      this.firstAid = eventData.firstAid;
      this.recordable = eventData.recordable;
      this.selectedOption = this.firstAid ? "firstAid" : "recordable";
    },
    getMachineGroupName(pk) {
      const findMachineGroup = (machineGroups, pk) => {
        for (const machineGroup of machineGroups) {
          if (machineGroup.pk === pk) {
            return machineGroup;
          }
          const found = findMachineGroup(machineGroup.children, pk);
          if (found) {
            return found;
          }
        }
        return null;
      };
      const machineGroup = findMachineGroup(this.machineGroups, pk);
      return machineGroup ? machineGroup.name : "";
    }
  },
  created() {
    this.getSafetyGroupLevel();
    this.loadMachineGroups();
  },
  emits: ["safetyEventSubmitted", "clear-selected-event"],
  watch: {
    selectedEvent: {
      handler: function (newVal) {
        if (newVal) {
          this.loadEventData(newVal);
          this.showDialogue = true;
        } else {
          this.clearData();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/variables";

.dialogBtn {
  position: relative;

  .edit-btn {
    width: 26px;
    height: 26px;
    margin-left: 10px;
  }
}

.row {
  align-items: center;
}

.selectButton {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $blue;
  font-size: 18px;
  padding-left: 10px;
}
</style>
