<template>
  <div>
    <LDMSAnalyticsIssueCards
      :filters="filters"
      :clickHandler="handleIssueCardClick"
      :openClosedOrTotal="openClosedOrTotal"
    />
    <LDMSAnalyticsIssuesDetailsTable
      :ldmsIssuesDetails="issuesDetailsTable"
      :loadingIssuesDetailsData="loadingIssuesDetailsData"
      :openClosedOrTotal="openClosedOrTotal"
      :downloadCsv="downloadCsv"
      :handleUpdatePageOptions="handleUpdatePageOptions"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { TableData } from "@/datatypes/table";
import { ISSUE_CARD_TYPES } from "@/utils/LDMSAnalyticsUtils";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { seconds } from "@/utils/filters";

import LDMSAnalyticsIssueCards from "../LDMSAnalyticsIssueCards";
import LDMSAnalyticsIssuesDetailsTable from "./LDMSAnalyticsIssuesDetailsTable";

export default {
  name: "LDMSAnalyticsDataTableView",
  props: {
    filters: { type: Object, default: () => {} },
    levelP1: { type: Object, default: () => {} },
    levelP2: { type: Object, default: () => {} }
  },
  data() {
    return {
      ISSUE_CARD_TYPES: Object.freeze(ISSUE_CARD_TYPES),
      loadingIssuesDetailsData: false,
      openClosedOrTotal: ISSUE_CARD_TYPES.TOTAL,
      reasonIds: [],
      downloadingCsv: false,
      issuesDetailsTable: new TableData([
        { text: this.$t("Date"), value: "issueStartDate", minWidth: "150px" },
        { text: this.levelP1.name, value: "department", sortable: false },
        { text: this.levelP2.name, value: "zone", sortable: false },
        { text: this.$t("Equipment"), value: "machineName" },
        { text: this.$t("Ticket ID"), value: "title" },
        { text: this.$t("Issue"), value: "reason", align: "center", sortable: false },
        {
          text: this.$t("Short Term Countermeasures"),
          value: "countermeasures",
          align: "center",
          sortable: false
        },
        { text: this.$t("Root Cause"), value: "cause", align: "center", sortable: false },
        {
          text: this.$t("Long Term Solutions"),
          value: "solution",
          align: "center",
          sortable: false
        },
        { text: this.$t("Duration"), value: "totalTime", sortable: false },
        { text: this.$t("Current Status"), value: "currentStatus", sortable: false },
        { text: this.$t("Owner"), value: "respondingNames" }
      ])
    };
  },
  components: {
    LDMSAnalyticsIssueCards,
    LDMSAnalyticsIssuesDetailsTable
  },
  async created() {
    await this.getReasonIds();
    this.getLDMSIssuesDetailsData();
  },
  computed: {
    ...mapGetters({
      machineGroups: "dbCache/machineGroups"
    }),
    issuesDetailsTablePage() {
      return this.issuesDetailsTable.page;
    }
  },
  methods: {
    handleIssueCardClick(type) {
      this.openClosedOrTotal = type;
    },
    async getReasonIds() {
      const query = `{
        issueReasons {
          edges {
            node {
              pk,
            }
          }
        }
      }`;
      try {
        const response = await this.$http.get("graphql/", { params: { query } });
        if (response) {
          const reasonIds = [];
          response.data.data.issueReasons.edges.forEach((reason) => {
            reasonIds.push(reason.node.pk);
          });
          this.reasonIds = reasonIds;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getLDMSIssuesDetailsData() {
      let query = `query (
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String) {
          issues (
            filter: $filters,
            ordering: $ordering
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            facets: [automatic]
            ) {
            facets,
            pageInfo {
              startCursor,
              endCursor,
              hasNextPage,
              hasPreviousPage,
            }
            edges {
              cursor,
              node {
                id,
                title,
                machineName,
                machineId,
                issueStartDate,
                closedDate,
                totalTime,
                respondingNames,
                reason,
                cause,
                countermeasures,
                solution,
              }
            }
          }
          settings {
            zcbEscalationTime,
          }
      }`;
      let ordering = { issueStartDate: "ASC" };
      if (this.issuesDetailsTable.sortBy.length > 0) {
        ordering = {};
        this.issuesDetailsTable.sortBy.forEach((sortBy, index) => {
          ordering[sortBy] = this.issuesDetailsTable.sortDesc[index] ? "DESC" : "ASC";
        }, this);
      }
      const variables = {
        filters: {
          issueStartDate: {
            range: {
              lower: { datetime: this.filters.fromDate },
              upper: { datetime: this.filters.toDate }
            }
          },
          reasonId: { in: this.filters.reasons.length > 0 ? this.filters.reasons : this.reasonIds }
        },
        ordering: ordering,
        before: false,
        after: false
      };
      if (this.issuesDetailsTable.page.load) {
        Object.assign(variables, this.issuesDetailsTable.page.load);
      } else {
        variables["first"] = this.issuesDetailsTable.page.itemsPerPage;
      }
      if (this.filters.machines.length > 0) {
        variables["filters"]["machineId"] = { in: this.filters.machines };
      }
      if (this.filters.parts.length > 0) {
        variables["filters"]["partId"] = { in: this.filters.parts };
      }
      if (
        this.openClosedOrTotal === ISSUE_CARD_TYPES.OPEN ||
        this.openClosedOrTotal === ISSUE_CARD_TYPES.CLOSED
      ) {
        variables["filters"]["closedDate"] = {
          exists: this.openClosedOrTotal === ISSUE_CARD_TYPES.OPEN ? false : true
        };
      }
      try {
        this.loadingIssuesDetailsData = true;
        const response = await this.$http.post("graphql/", { query, variables });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.issues &&
          response.data.data.issues.edges
        ) {
          const data = response.data.data.issues.edges.map((issue) => {
            const allMachines = this.machineGroups.map((group) => group.allMachines).flat();
            const machinePks = allMachines.map((machine) => machine.pk);
            const filteredAllMachines = allMachines.filter(
              ({ pk }, index) => !machinePks.includes(pk, index + 1)
            );
            const foundMachine = filteredAllMachines.find(
              (machine) => machine.pk.toString() === issue.node.machineId
            );
            const foundLevelP1Group = this.machineGroups.find(
              (group) =>
                group.level.pk === this.levelP1.pk &&
                group.allMachines.some((machine) => machine.pk.toString() === issue.node.machineId)
            );
            const foundLevelP2Group = this.machineGroups.find(
              (group) =>
                group.level.pk === this.levelP2.pk &&
                group.allMachines.some((machine) => machine.pk.toString() === issue.node.machineId)
            );
            return {
              id: issue.node.id,
              closedDate: issue.node.closedDate,
              issueStartDate: issue.node.issueStartDate
                ? moment(issue.node.issueStartDate).format("DD-MMM-YY")
                : "",
              department:
                foundLevelP1Group && "name" in foundLevelP1Group ? foundLevelP1Group.name : "",
              zone: foundLevelP2Group && "name" in foundLevelP2Group ? foundLevelP2Group.name : "",
              machineName:
                foundMachine && "name" in foundMachine ? foundMachine.name : issue.node.machineName,
              title: issue.node.title,
              reason: issue.node.reason,
              countermeasures: issue.node.countermeasures
                ? issue.node.countermeasures
                    .replaceAll("[", "")
                    .replaceAll("]", "")
                    .replaceAll("'", "")
                : "",
              cause: issue.node.cause
                ? issue.node.cause.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "")
                : "",
              solution: issue.node.solution
                ? issue.node.solution.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "")
                : "",
              totalTime: issue.node.totalTime
                ? seconds(issue.node.totalTime, issue.node.totalTime > 59 ? false : true)
                : "",
              currentStatus:
                issue.node.issueStartDate &&
                response.data.data.settings.length > 0 &&
                "zcbEscalationTime" in response.data.data.settings[0]
                  ? moment(issue.node.issueStartDate).isBefore(
                      moment(Date.now()).subtract(
                        response.data.data.settings[0].zcbEscalationTime,
                        "h"
                      )
                    )
                    ? "ZCB"
                    : "HxH"
                  : "",
              respondingNames: issue.node.respondingNames
                ? issue.node.respondingNames
                    .replaceAll("[", "")
                    .replaceAll("]", "")
                    .replaceAll("'", "")
                : ""
            };
          });
          if (this.downloadingCsv) {
            this.loadingIssuesDetailsData = false;
            return data;
          }
          this.issuesDetailsTable.data = data;
          this.issuesDetailsTable.total = response.data.data.issues.facets.automatic.doc_count;
          this.issuesDetailsTable.page.next = response.data.data.issues.pageInfo.endCursor;
          this.issuesDetailsTable.page.prev = response.data.data.issues.pageInfo.startCursor;
          this.issuesDetailsTable.page.load = false;
          this.loadingIssuesDetailsData = false;
        }
      } catch (error) {
        console.error(error);
        this.loadingIssuesDetailsData = false;
      }
    },
    async downloadCsv() {
      this.downloadingCsv = true;
      this.issuesDetailsTable.page = {
        // TODO: Handle when total > 10000
        itemsPerPage: this.issuesDetailsTable.total,
        current: this.issuesDetailsTable.page.current,
        load: false,
        next: null,
        prev: null
      };
      const csvData = await this.getLDMSIssuesDetailsData();
      const rows = [];
      const headers = this.issuesDetailsTable.headers.map((item) => item.text);
      rows.push(headers);
      csvData.forEach((item) =>
        rows.push([
          item.issueStartDate,
          item.department,
          item.zone,
          item.machineName,
          item.title,
          item.reason,
          item.countermeasures,
          item.cause,
          item.solution,
          item.totalTime,
          item.currentStatus,
          item.respondingNames
        ])
      );
      createCSVFromRows(rows, `LDMS_Issues_Details_${new Date().toISOString()}`);
      this.downloadingCsv = false;
    },
    handleUpdatePageOptions(pageInfo) {
      if (pageInfo.itemsPerPage !== this.issuesDetailsTable.page.itemsPerPage) {
        this.issuesDetailsTable.page.itemsPerPage = pageInfo.itemsPerPage;
        this.getLDMSIssuesDetailsData();
        return;
      }
      if (pageInfo.page > this.issuesDetailsTablePage.current) {
        this.issuesDetailsTable.page.load = {
          after: this.issuesDetailsTable.page.next,
          before: false,
          first: this.issuesDetailsTable.page.itemsPerPage
        };
        this.getLDMSIssuesDetailsData();
        return;
      }
      if (pageInfo.page < this.issuesDetailsTablePage.current) {
        this.issuesDetailsTable.page.load = {
          after: false,
          before: this.issuesDetailsTable.page.prev,
          last: this.issuesDetailsTable.page.itemsPerPage
        };
        this.getLDMSIssuesDetailsData();
      }
    }
  },
  watch: {
    openClosedOrTotal() {
      this.issuesDetailsTable.page.current = 1;
      this.getLDMSIssuesDetailsData();
    },
    "issuesDetailsTable.sortDesc": function () {
      this.getLDMSIssuesDetailsData();
    }
  }
};
</script>
<style lang="scss"></style>
