<template>
  <div class="data-table-fixed">
    <v-data-table
      :item-key="itemKey"
      :headers="headers"
      :items="vw > 600 ? sorted_items : items"
      :loading="loading"
      :hide-default-header="vw > 600"
      :hide-default-footer="hideDefaultFooter"
      :showExpand="showExpand"
      :singleExpand="singleExpand"
      @item-expanded="
        (i) => {
          if (itemExpanded) {
            itemExpanded(i);
          }
        }
      "
    >
      <template
        v-slot:header="{ props }"
        v-if="vw > 600"
      >
        <thead>
          <tr>
            <th v-if="showExpand"></th>
            <th
              v-for="(head, idx) in headers"
              :key="idx"
            >
              <div
                class="header_item"
                v-bind:class="head.class"
                @click="
                  () => {
                    sortby(head);
                  }
                "
              >
                <div class="rowcontent">{{ head.text }}</div>
                <i
                  v-if="head.sort == 'asc'"
                  class="mdi mdi-arrow-up"
                />
                <i
                  v-else-if="head.sort == 'desc'"
                  class="mdi mdi-arrow-down"
                />
                <i v-else></i>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot
          :name="scopedSlotName"
          v-bind="slotData"
        />
      </template>
    </v-data-table>
  </div>
</template>
<style lang="scss">
@import "../scss/variables";

.data-table-fixed {
  position: relative;
  width: 100%;
  display: block;
  flex: 1;
  padding-bottom: 10px;

  .header_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    flex: unset;
    font-weight: lighter;
    color: $blue;
    text-align: left;
    font-size: 14px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:hover {
      color: $green;
    }

    .rowcontent {
      flex: unset;
      margin-right: 10px;
    }

    i {
      font-size: 18px;
      width: 18px;
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

  .v-data-table__mobile-row {
    border-bottom: 1px rgba(255, 255, 255, 0.05) solid;
    justify-content: flex-start;
    display: flex !important;

    .v-data-table__mobile-row__header {
      color: $blue;
      width: 150px;
      text-align: left;
      flex: unset;
    }

    .v-data-table__mobile-row__cell {
      flex: 1;
      text-align: left;

      p {
        text-align: left;
      }
      div {
        text-align: left;
      }
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      sorted_items: [],
      expandedItems: []
    };
  },
  props: [
    "itemKey",
    "headers",
    "items",
    "loading",
    "slots",
    "showExpand",
    "singleExpand",
    "itemExpanded",
    "expanded",
    "hideDefaultFooter"
  ],
  mounted: function () {
    const header = this.findHeaderWithSort();
    this.sortby(null, false);
  },
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  methods: {
    findHeader(header) {
      for (const i of this.headers) {
        if (header == i.value) {
          return i;
        }
      }
    },
    findHeaderWithSort() {
      for (const i of this.headers) {
        if (i.sort != null) {
          return i;
        }
      }
    },
    sortby(item, advanceClick = true) {
      if (item == null) {
        this.sorted_items = this.items.slice();
        return;
      }

      if (advanceClick) {
        if (item.sort == null) {
          item.sort = "desc";
        } else if (item.sort == "desc") {
          item.sort = "asc";
        } else if (item.sort == "asc") {
          item.sort = null;
        }
      }

      this.headers = this.headers.map((i) => {
        if (i == item) {
          return i;
        } else {
          i.sort = null;
          return i;
        }
      });

      const sortKey = item.value;
      const sort = item.sort;

      if (sort == null) {
        this.sorted_items = this.items.slice();
      } else {
        const unsorted = this.items.slice();
        unsorted.sort((item_a, item_b) => {
          const a = isNaN(item_a[sortKey]) ? item_a[sortKey] : Number(item_a[sortKey]);
          const b = isNaN(item_b[sortKey]) ? item_b[sortKey] : Number(item_b[sortKey]);

          if (sort == "asc") {
            if (a < b || a == null) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a > b || b == null) {
              return 1;
            } else {
              return -1;
            }
          }
        });

        this.sorted_items = unsorted.slice();
      }
    }
  },
  watch: {
    items: function () {
      const header = this.findHeaderWithSort();
      this.sortby(header, false);
    },
    expanded: function () {
      this.expandedItems = [...this.expanded];
    }
  }
};
</script>
