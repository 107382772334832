var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"realtimeTableExpand",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"key_as_string","disable-pagination":"","fixed-header":"","hide-default-footer":"","hide-default-header":"","disable-sort":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(head){return _c('th',[_c('div',{staticClass:"header_item",class:head.class},[_c('div',{staticClass:"rowcontent"}),_vm._v(" "),_c('i')])])}),0)])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_status"},[_c('div',{staticClass:"tiny-light",class:item.checked_in ? 'green' : 'red'}),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.checked_in ? _vm.$t("Checked-In") : _vm.$t("Checked-Out")))])])]}},{key:"item.machine_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_machine_status"},[_c('p',{style:({ color: item.machine_status ? item.machine_status.color : '' })},[_vm._v("\n        "+_vm._s(item.machine_status ? item.machine_status.name : "")+"\n      ")])])]}},{key:"item.target_units",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(_vm.numberWithCommas(item.target_units.toFixed()))+"\n  ")]}},{key:"item.actual",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(_vm.numberWithCommas(item.actual.toFixed()))+"\n  ")]}},{key:"item.average",fn:function(ref){
var item = ref.item;
return [_vm._v("\n    "+_vm._s(_vm.numberWithCommas(item.average))+"\n  ")]}},{key:"item.gap",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_gap",class:_vm.GetGapColor(item.gap, item.target_units, item.actual)},[_vm._v("\n      "+_vm._s(_vm.numberWithCommas(item.gap.toFixed()))+"\n    ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }