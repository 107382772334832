<template>
  <ModalDialog
    v-model="showDialog"
    persistent
    class="cause-dialog"
    content-class="issue-entry-dialog cause"
    max-width="576px"
  >
    <template #title> {{ $t("Root Cause For") }} {{ timeDisplay }} </template>

    <template #body>
      <div>
        <div class="selections">
          <div
            v-if="selectedReason"
            class="selection pb-2"
            @click="selectedReason = false"
          >
            <p
              class="selection-title text-subtitle-2 font-weight-regular color--text-secondary mr-1"
            >
              {{ $t("Reason") }}
            </p>
            <div class="reason">
              <v-btn
                class="reason-item color--text-primary text-subtitle-1"
                @click="
                  () => {
                    selectedReason = false;
                    selectedCause = null;
                  }
                "
              >
                {{ selectedReason.reason }}
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <div v-if="!selectedReason">
            <div
              v-for="issue in issues"
              :key="issue.id"
              class="reason"
            >
              <v-btn
                :class="{ selected: selectedReason.id == issue.id }"
                class="reason-item color--text-primary text-subtitle-1"
                @click="selectReason(issue)"
              >
                {{ issue.reason }}
              </v-btn>
            </div>
          </div>
          <div
            v-if="causeOptions && selectedReason"
            class="mt-4"
          >
            <div
              v-for="cause in causeOptions"
              :key="'cause' + cause.node.id"
              class="cause"
            >
              <v-btn
                :disabled="selectedReasonCauses.includes(cause.node.text)"
                :class="{ selected: selectedCause == cause }"
                class="reason-item color--text-primary text-subtitle-1"
                @click="selectedCause = cause"
              >
                {{ cause.node.text }}
              </v-btn>
            </div>
            <div
              key="other"
              class="cause"
            >
              <v-btn
                :class="{ selected: selectedCause == 'other' }"
                class="reason-item color--text-primary text-subtitle-1"
                @click="
                  selectedCause === 'other' ? (selectedCause = null) : (selectedCause = 'other')
                "
              >
                {{ $t("Other") }}
              </v-btn>
            </div>
          </div>
        </div>
        <div v-if="selectedCause == 'other'">
          <v-textarea
            v-model="otherText"
            :label="$t('Root Cause')"
            class="mt-0 pt-5"
            height="120"
            filled
            hide-details
            clearable
          ></v-textarea>
        </div>
      </div>
    </template>

    <template #actions>
      <Btn
        class="cause-dialog-btn"
        width="144"
        @click="showDialog = false"
      >
        {{ $t("Cancel") }}
      </Btn>
      <Btn
        variant="primary"
        primary
        :disabled="!selectedCause || (selectedCause == 'other' && otherText == '')"
        :loading="saving"
        class="cause-dialog-btn"
        width="144"
        @click="updateIssue"
      >
        {{ $t("Submit") }}
      </Btn>
    </template>
  </ModalDialog>
</template>
<script>
import moment from "moment-timezone";
import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";

/* TODO: https://intelycx.atlassian.net/browse/MIM-4421
 *  This is a copy-paste component. It is necessary to bring about one type with MachineHourCause
 * */
export default {
  name: "TicketCauseDialog",
  components: { ModalDialog, Btn },
  // eslint-disable-next-line vue/require-prop-types
  props: ["issues", "time", "machine_id", "open"],
  data() {
    return {
      showDialog: true,
      reasons: {},
      causeOptions: [],
      selectedReason: false,
      selectedCause: false,
      saving: false,
      otherText: ""
    };
  },
  computed: {
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    selectedReasonCauses() {
      if (this.selectedReason.cause) {
        return this.selectedReason.cause;
      }
      return [];
    },
    causes() {
      let causes = [];
      this.issues.forEach((issue) => {
        if (issue.cause) {
          let issueCause = issue.cause
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll("'", "")
            .split(",");
          if (issueCause.length > 0) {
            causes = causes.concat(issueCause);
          }
        }
      });
      return causes;
    }
  },
  watch: {
    open(val) {
      this.showDialog = val;
    },
    showDialog(val) {
      if (!val) {
        this.$emit("close");
      }
    }
  },
  methods: {
    moment,
    selectReason(issue) {
      this.selectedReason = issue;
      this.causeOptions = [];
      if (issue.reason in this.reasons) {
        this.causeOptions = this.reasons[issue.reason];
      } else {
        const query = `query($reason_Text: String, $machine: [ID]!){
          issueCauses(reason_Text: $reason_Text, machine:$machine){
            edges{
              node{
                id
                text
              }
            }
          }
        }`;
        const variables = {
          reason_Text: issue.reason,
          machine: [this.machine_id]
        };
        this.$http
          .post("graphql/", { query: query, variables: variables })
          .then((res) => {
            this.reasons[issue.reason] = res.data.data.issueCauses.edges.sort((a, b) => {
              if (a.node.text < b.node.text) {
                return -1;
              }
              if (a.node.text > b.node.text) {
                return 1;
              }
              return 0;
            });
            this.causeOptions = this.reasons[issue.reason];
          })
          .catch((res) => {
            this.errors = res.errors;
          });
      }
    },
    updateIssue() {
      this.saving = true;
      const query = `mutation ($id: ID!, $issueInput: IssueInput!){
        updateIssue(globalId:$id, issueData: $issueInput) {
          issue{
            id,
            title,
            openedDate,
            closedDate,
            issueStartDate,
            issueEndDate,
            machineId,
            machineName,
            issueTypeId,
            issueTypeName,
            reason,
            countermeasures,
            cause,
            solution,
          }
        }
      }`;
      let variables = {
        id: this.selectedReason.id,
        issueInput: {}
      };
      if (this.selectedCause == "other" && this.otherText) {
        variables["issueInput"]["cause"] = this.otherText;
      } else {
        variables["issueInput"]["cause"] = this.selectedCause.node.text;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.$message("ALERT", { text: this.$t("Reason Submitted"), type: "success" });
          this.showDialog = false;
          this.selectedReason = false;
          this.selectedCause = false;
          this.causeOptions = [];
          this.saving = false;
          this.$emit("UpdatedCause", res.data.data.updateIssue.issue);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import "~@/scss/dropdown";
@import "~@/ui/Reason/reason";
</style>

<style lang="scss">
.v-badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ::v-deep {
    .v-badge__wrapper {
      position: static;
      width: auto;

      > span {
        position: static;
        background-color: var(--btn-secondary) !important;
        color: var(--text-primary) !important;
        padding-left: 8px;
        padding-right: 8px;
        margin-left: 4px;
      }
    }
  }
}

.init-button {
  position: relative;
}

.reason ~ .reason,
.cause ~ .cause {
  margin-top: 16px;
}

.selections {
  border: none;
}

.selection {
  border-bottom: 1px solid var(--border-primary);
}

.v-dialog .selections .selection .reason {
  width: calc(100% - 75px - 75px);

  button {
    border: none !important;
  }
}

.selection-title {
  width: 75px;
}

.close-button {
  top: 13px;
  right: 13px;
}

::v-deep {
  .issue-entry-dialog {
    width: 576px;
    height: 500px;
  }
}

.dialog-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dialog-card-body {
  height: calc(100% - 69px);
  overflow-y: auto;
  padding-top: 20px !important;
}

.dialog-card-actions {
  margin-top: auto;
}

.dialog-title {
  padding: 18px 48px 18px 24px !important;
  border-bottom: 1px solid var(--border-secondary);
}
</style>
