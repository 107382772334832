var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table-fixed"},[_c('v-data-table',{attrs:{"item-key":_vm.itemKey,"headers":_vm.headers,"items":_vm.vw > 600 ? _vm.sorted_items : _vm.items,"loading":_vm.loading,"hide-default-header":_vm.vw > 600,"hide-default-footer":_vm.hideDefaultFooter,"showExpand":_vm.showExpand,"singleExpand":_vm.singleExpand},on:{"item-expanded":function (i) {
        if (_vm.itemExpanded) {
          _vm.itemExpanded(i);
        }
      }},scopedSlots:_vm._u([(_vm.vw > 600)?{key:"header",fn:function(ref){
      var props = ref.props;
return [_c('thead',[_c('tr',[(_vm.showExpand)?_c('th'):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(head,idx){return _c('th',{key:idx},[_c('div',{staticClass:"header_item",class:head.class,on:{"click":function () {
                  _vm.sortby(head);
                }}},[_c('div',{staticClass:"rowcontent"},[_vm._v(_vm._s(head.text))]),_vm._v(" "),(head.sort == 'asc')?_c('i',{staticClass:"mdi mdi-arrow-up"}):(head.sort == 'desc')?_c('i',{staticClass:"mdi mdi-arrow-down"}):_c('i')])])})],2)])]}}:null,_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }