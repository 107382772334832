export function sumValue(dataset, fields) {
  let sum = 0;
  for (const d of dataset) {
    let temp = d;
    for (const f of fields) {
      temp = temp[f];
    }
    sum += temp;
  }
  return sum;
}
