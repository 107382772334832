<template>
  <div class="radial-fab">
    <div
      :class="`fab ${isOpen && 'open'}`"
      v-on="{ click: isMachineRoute ? openMenu : openP911 }"
    >
      <i
        class="fa plus-icon"
        :class="{ 'fa-plus': isMachineRoute, 'fa-exclamation': !isMachineRoute }"
      />
      <div
        :class="`background_expand ${isOpen && 'open'}`"
        @click.stop
      >
        <div class="button-list">
          <div
            class="button-item"
            @click.stop="
              () => {
                isOpen = false;
                openScrapEntry();
              }
            "
          >
            <i class="fa fa-file" /> <span class="link">{{ $t("Scrap Data Entry") }}</span>
          </div>
          <div
            class="button-item"
            @click.stop="
              () => {
                isOpen = false;
                openStatusEntry();
              }
            "
          >
            <i class="fa fa-pencil" /> <span class="link">{{ $t("Update Status") }}</span>
          </div>
          <div
            class="button-item"
            @click.stop="
              () => {
                isOpen = false;
                openEdocs();
              }
            "
          >
            <i class="fa fa-file-text-o" /> <span class="link">{{ $t("E-Docs") }}</span>
          </div>
          <div
            class="button-item"
            @click.stop="
              () => {
                isOpen = false;
                openP911();
              }
            "
          >
            <i class="fa fa-bell-o" /> <span class="link">{{ $t("P911") }}</span>
          </div>
          <div
            class="button-item"
            @click.stop="
              () => {
                isOpen = false;
                openComment();
              }
            "
          >
            <i class="fa fa-comment-o" /> <span class="link">{{ $t("Comment") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../scss/variables";

.radial-fab {
  position: relative;
  .fab {
    background: $fab_solid;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.15s ease-in;
    position: relative;
    pointer-events: painted;

    .plus-icon {
      transition:
        color 0.15s ease-in,
        transform 0.15s ease-in;
    }

    &.open {
      background: white;

      .plus-icon {
        color: $fab_solid;
        transform: rotate(45deg);
      }

      &:hover {
        background: #dedede;
      }
    }

    .background_expand {
      width: 0%;
      height: 0%;
      position: absolute;
      background: radial-gradient(circle, $fab_solid 0%, $fab_solid 50%, $fab_transparent 60%);
      border-radius: 100%;
      z-index: -1;
      transition:
        width 0.15s ease-in,
        height 0.15s ease-in;

      .button-list {
        position: absolute;
        left: 28%;
        bottom: 26%;
        width: 25%;
        height: 25%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        white-space: nowrap;
        font-size: 17px;
        span {
          font-weight: bold;
        }

        .button-item {
          padding-bottom: 10px;
          display: block;

          &:hover {
            text-decoration: underline;
          }
        }

        .fa {
          width: 30px;
          text-align: left;
        }
      }

      &.open {
        width: 2000%;
        height: 2000%;
      }
    }
  }
}
</style>
<script>
export default {
  props: [
    "openScrapEntry",
    "openStatusEntry",
    "openEdocs",
    "openP911",
    "openComment",
    "fabOpen",
    "isMachineRoute"
  ],
  data() {
    return {
      isOpen: false
    };
  },
  created() {
    this.isOpen = this.fabOpen;
  },
  methods: {
    openMenu() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
