var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"level2-filters card"},[_c('div',{staticClass:"views card"},[_c('v-radio-group',{model:{value:(_vm.filterController.view),callback:function ($$v) {_vm.$set(_vm.filterController, "view", $$v)},expression:"filterController.view"}},[_c('v-radio',{attrs:{"label":this.$t('Data Table'),"value":"data_table"}}),_vm._v(" "),_c('v-radio',{attrs:{"label":this.$t('Timeline'),"value":"timeline"}}),_vm._v(" "),_c('v-radio',{attrs:{"label":this.$t('Analytics'),"value":"analytics"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"filter-sections"},[_c('div',{staticClass:"filter-section card"},[_c('date-range',{attrs:{"filter-controller":_vm.filterController,"include-scale":_vm.filterController.view != 'timeline'}})],1),_vm._v(" "),_c('div',{staticClass:"filter-section card dropdown-section"},[(_vm.filterController.view == 'timeline')?_c('v-autocomplete',{attrs:{"items":_vm.machines,"item-text":"name","label":_vm.$t('Machine'),"return-object":"","menu-props":{ closeOnClick: true },"hide-details":""},on:{"change":_vm.filterController.triggerReload},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.name)+"\n        ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.name)+"\n        ")]}}],null,false,2156853532),model:{value:(_vm.filterController.initialFilters.machine),callback:function ($$v) {_vm.$set(_vm.filterController.initialFilters, "machine", $$v)},expression:"filterController.initialFilters.machine"}}):_vm._e(),_vm._v(" "),(_vm.filterController.view == 'timeline' && _vm.machineCodes.length > 0)?_c('v-autocomplete',{attrs:{"items":_vm.machineCodes,"item-text":"code","label":_vm.$t('Machine code'),"filter":_vm.filterMachineCodes,"return-object":"","menu-props":{ closeOnClick: true },"hide-details":"","clearable":""},on:{"change":_vm.filterController.triggerReload},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.description)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.code)+" - "+_vm._s(item.description)+"\n        ")]}}],null,false,819184380),model:{value:(_vm.filterController.initialFilters.code),callback:function ($$v) {_vm.$set(_vm.filterController.initialFilters, "code", $$v)},expression:"filterController.initialFilters.code"}}):_vm._e(),_vm._v(" "),(_vm.filterController.view != 'timeline')?_c('machine-group-selector',{attrs:{"filter-controller":_vm.filterController,"set-machine-groups":_vm.setMachineGroups,"hide-machine-group-selector":"true"}}):_vm._e(),_vm._v(" "),_c('v-autocomplete',{attrs:{"items":['ALARM', 'EVENT', 'UNKNOWN'],"item-text":"code-event-type","label":_vm.$t('Event Type'),"return-object":"","menu-props":{ closeOnClick: true },"hide-details":"","clearable":""},on:{"change":_vm.filterController.triggerReload},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.$t(item))+"\n        ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.$t(item))+"\n        ")]}}]),model:{value:(_vm.filterController.initialFilters.codeEventType),callback:function ($$v) {_vm.$set(_vm.filterController.initialFilters, "codeEventType", $$v)},expression:"filterController.initialFilters.codeEventType"}}),_vm._v(" "),(_vm.filterController.view != 'timeline')?_c('part-selector',{attrs:{"filter-controller":_vm.filterController}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }