var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operator-analytics"},[(_vm.vw < 1200)?_c('v-btn',{staticClass:"filter-btn",attrs:{"fab":"","x-small":"","light":""},on:{"click":function($event){_vm.showSearch = !_vm.showSearch}}},[(!_vm.showSearch)?_c('i',{staticClass:"fa fa-filter"}):_vm._e(),_vm._v(" "),(_vm.showSearch)?_c('i',{staticClass:"fa fa-times"}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.vw > 1200 || _vm.showSearch)?_c('div',{staticClass:"filters card"},[_c('div',{staticClass:"view card"},[_c('v-radio-group',{model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('v-radio',{key:"table",attrs:{"label":_vm.$t('Data Table'),"value":"table"}}),_vm._v(" "),_c('v-radio',{key:"charts",attrs:{"label":_vm.$t('Analytics'),"value":"chart"}}),_vm._v(" "),_c('v-radio',{key:"scorecard",attrs:{"label":_vm.$t('Scorecard'),"value":"scorecard","disabled":!_vm.user.useDevelopmentEdge}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dates card"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Presets")))]),_vm._v(" "),_vm._l((_vm.intervals),function(btn,btnIndex){return _c('button',{key:btn.value,staticClass:"btn radioBtn",class:{ active: _vm.preset == btn.value },on:{"click":function($event){return _vm.presetClick(btn.value)}}},[_vm._v("\n        "+_vm._s(btn.name)+"\n      ")])}),_vm._v(" "),_c('datetime-picker',{attrs:{"id":"from_date","label":_vm.$t('From Date')},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}}),_vm._v(" "),_c('datetime-picker',{attrs:{"id":"to_date","label":_vm.$t('To Date')},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})],2),_vm._v(" "),_c('div',{staticClass:"searchbars card"},[_c('v-autocomplete',{attrs:{"clearable":"","placeholder":_vm.$t('Zone'),"items":Array.from(_vm.zoneSet),"value":_vm.filters.zone,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('zone', e);
          }}}),_vm._v(" "),_c('v-autocomplete',{attrs:{"clearable":"","placeholder":_vm.$t('Machine'),"items":Array.from(_vm.machineSet),"value":_vm.filters.machine,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('machine', e);
          }}}),_vm._v(" "),_c('v-autocomplete',{attrs:{"clearable":"","placeholder":_vm.$t('Part'),"items":Array.from(_vm.partSet),"value":_vm.filters.part,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('part', e);
          }}}),_vm._v(" "),_c('v-autocomplete',{attrs:{"clearable":"","placeholder":_vm.$t('Operator'),"items":Array.from(_vm.operatorSet),"value":_vm.filters.name,"menu-props":{
          closeOnClick: true,
          closeOnContentClick: true
        }},on:{"change":function (e) {
            _vm.setFilter('name', e);
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.view == 'table')?_c('div',{staticClass:"table"},[_c('v-data-table',{attrs:{"headers":_vm.table_headers,"table_headers":_vm.header_options,"items":_vm.sorted_items,"expanded":_vm.expanded,"single-expand":true,"item-key":"key","hide-default-header":_vm.vw > 1000,"loading":_vm.loading},on:{"click:row":_vm.rowClick,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(_vm.vw > 1000)?{key:"header",fn:function(ref){
          var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((_vm.table_headers),function(head){return _c('th',[_c('div',{staticClass:"header_item",class:head.class,on:{"click":function () {
                    _vm.sortby(head);
                  }}},[_c('div',{staticClass:"rowcontent"},[_vm._v(_vm._s(head.text))]),_vm._v(" "),(head.sort == 'asc')?_c('i',{staticClass:"mdi mdi-arrow-up"}):(head.sort == 'desc')?_c('i',{staticClass:"mdi mdi-arrow-down"}):_c('i')])])}),0)])]}}:null,{key:"item.expand",fn:function(ref){
                  var item = ref.item;
return [(item.machines && item.machines.length > 1 && !_vm.expanded.includes(item))?_c('v-btn',{attrs:{"x-small":"","fab":"","light":""}},[_c('i',{staticClass:"fa fa-caret-down",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),(item.machines && item.machines.length > 1 && _vm.expanded.includes(item))?_c('v-btn',{attrs:{"x-small":"","fab":"","light":""}},[_c('i',{staticClass:"fa fa-caret-up",attrs:{"aria-hidden":"true"}})]):_vm._e()]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"table_name"},[_c('div',{staticClass:"icon",style:(item.avatar
                ? {
                    background: ("url(" + (item.avatar) + ")"),
                    'background-size': 'cover',
                    'background-position-x': 'center',
                    'background-position-y': 'center'
                  }
                : null)},[(item.avatar == null)?_c('i',{staticClass:"fa fa-user"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"table_status"},[_c('div',{staticClass:"tiny-light",class:item.checked_in ? 'green' : 'red'}),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.checked_in ? _vm.$t("Checked-In") : _vm.$t("Checked-Out")))])])]}},{key:"item.machine_status",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"table_machine_status"},[_c('p',{style:({ color: item.machine_status ? item.machine_status.color : '' })},[_vm._v("\n            "+_vm._s(item.machine_status ? item.machine_status.name : "")+"\n          ")])])]}},{key:"item.target_units",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.numberWithCommas(item.target_units))+"\n      ")]}},{key:"item.actual",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.numberWithCommas(item.actual))+"\n      ")]}},{key:"item.average",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.numberWithCommas(item.average))+"\n      ")]}},{key:"item.gap",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"table_gap",class:_vm.GetGapColor(item.gap, item.target_units, item.actual)},[_vm._v("\n          "+_vm._s(_vm.numberWithCommas(item.gap))+"\n        ")])]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"nestedTable"},[_c('realtime-table-expand',{attrs:{"machines":item.machines,"table_headers":_vm.table_headers,"now":_vm.now,"role":item.role}})],1)])]}}],null,true)})],1):_vm._e(),_vm._v(" "),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):(_vm.view == 'chart')?_c('div',{staticClass:"charts"},[('name' in _vm.filters)?_c('AnalyticsTimeSeries',{staticClass:"operators chartCard card",attrs:{"DataFormatter":_vm.Shorten,"title":_vm.filters['name'] + ' - ' + this.$t('Production Trend'),"dates":{ from_date: _vm.from_date, to_date: _vm.to_date },"filters":_vm.filters,"reload":_vm.reload,"labels":[_vm.$t('Target'), _vm.$t('Produced')],"ChartType":"production","ChartName":"operator"}}):_c('PaginatedChart',{staticClass:"operators chartCard card",attrs:{"chartOptions":[
        {
          color: _vm.colors.peachPuff,
          borderColor: _vm.colors.peachPuff,
          fill: false,
          stack: false,
          keys: ['efficiencyVal'],
          uom: '%',
          label: this.$t('Efficiency'),
          type: 'line',
          yAxisID: 'percentage'
        },
        {
          color: _vm.colors.tiffanyBlue,
          keys: ['target_units'],
          label: this.$t('Target'),
          stack: false,
          type: 'bar',
          stack: 'stack1'
        },
        {
          color: _vm.colors.coralReef,
          keys: ['actual'],
          label: this.$t('Produced'),
          stack: false,
          type: 'bar',
          stack: 'stack2'
        }
      ],"secondAxis":{
        id: 'percentage',
        type: 'linear',
        position: 'right',
        gridLines: {
          color: 'rgba(0,0,0,0)',
          zeroLineColor: 'rgba(0,0,0,0)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          callback: function (item) {
            return _vm.Shorten(item) + '%';
          }
        }
      },"showLegend":"true","DataFormatter":_vm.Shorten,"label_name":"name","sortOn":"actual","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItems,"useNumberRecordsPicker":true,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":_vm.$t('Operators')}}),_vm._v(" "),('name' in _vm.filters)?_c('AnalyticsTimeSeries',{staticClass:"chartCard card",attrs:{"DataFormatter":_vm.seconds,"title":_vm.filters['name'] + ' - ' + this.$t('Production Time Trend'),"dates":{ from_date: _vm.from_date, to_date: _vm.to_date },"filters":_vm.filters,"reload":_vm.reload,"labels":[_vm.$t('Total Time'), _vm.$t('Runtime')],"ChartType":"runtime","ChartName":"operator"}}):_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: _vm.colors.tiffanyBlue,
          keys: ['event_duration'],
          label: this.$t('Production Time'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.seconds,"label_name":"name","sortOn":"event_duration","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItems,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":_vm.$t('Operators - Production Time')}}),_vm._v(" "),('part' in _vm.filters)?_c('AnalyticsTimeSeries',{staticClass:"chartCard card",attrs:{"DataFormatter":_vm.Shorten,"title":_vm.filters['part'] + ' - ' + this.$t('Production Trend'),"dates":{ from_date: _vm.from_date, to_date: _vm.to_date },"filters":_vm.filters,"reload":_vm.reload,"labels":[_vm.$t('Target'), _vm.$t('Produced')],"ChartType":"production","ChartName":"part"}}):_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: _vm.colors.tiffanyBlue,
          keys: ['production_target'],
          label: this.$t('Target'),
          type: 'scatter',
          stack: 'combined'
        },
        {
          color: _vm.colors.coralReef,
          keys: ['produced'],
          label: this.$t('Produced'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.Shorten,"label_name":"part_number","sortOn":"produced","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItemsPartNumbers,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":_vm.$t('Part Numbers')}}),_vm._v(" "),('machine' in _vm.filters)?_c('AnalyticsTimeSeries',{staticClass:"chartCard card",attrs:{"DataFormatter":_vm.Shorten,"title":_vm.filters['machine'] + ' - ' + this.$t('Production Trend'),"dates":{ from_date: _vm.from_date, to_date: _vm.to_date },"filters":_vm.filters,"reload":_vm.reload,"labels":[_vm.$t('Target'), _vm.$t('Produced')],"ChartType":"production","ChartName":"machine"}}):_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: _vm.colors.tiffanyBlue,
          keys: ['production_target'],
          label: this.$t('Target'),
          type: 'scatter',
          stack: 'combined'
        },
        {
          color: _vm.colors.coralReef,
          keys: ['produced'],
          label: this.$t('Produced'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.Shorten,"label_name":"machine_name","sortOn":"produced","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItemsMachines,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":"Machines"}}),_vm._v(" "),('zone' in _vm.filters)?_c('AnalyticsTimeSeries',{staticClass:"chartCard card",attrs:{"DataFormatter":_vm.Shorten,"title":_vm.filters['zone'] + ' - ' + this.$t('Production Trend'),"dates":{ from_date: _vm.from_date, to_date: _vm.to_date },"filters":_vm.filters,"reload":_vm.reload,"labels":[_vm.$t('Target'), _vm.$t('Produced')],"ChartType":"production","ChartName":"zone"}}):_c('PaginatedChart',{staticClass:"chartCard card",attrs:{"chartOptions":[
        {
          color: _vm.colors.tiffanyBlue,
          keys: ['production_target'],
          label: this.$t('Target'),
          type: 'scatter',
          stack: 'combined'
        },
        {
          color: _vm.colors.coralReef,
          keys: ['produced'],
          label: this.$t('Produced'),
          type: 'bar',
          stack: 'combined'
        }
      ],"DataFormatter":_vm.Shorten,"label_name":"zone","sortOn":"produced","numberRecordsPerPage":_vm.numberRecordsPerPage,"numberRecords":_vm.numberRecords,"filteredItems":_vm.filteredItemsZone,"useNumberRecordsPicker":false,"ChangeNumberRecords":_vm.ChangeNumberRecords,"title":_vm.$t('By Zone')}})],1):(_vm.view === 'scorecard')?_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('DevelopmentFeature',{scopedSlots:_vm._u([{key:"edge",fn:function(){return [(_vm.sorted_items.length === 1)?_c('OperatorScorecard',{attrs:{"sorted-items":_vm.sorted_items,"from_date":_vm.from_date,"to_date":_vm.to_date,"preset":_vm.preset}}):_vm._e()]},proxy:true}])})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }