<template>
  <v-data-table
    class="realtimeTableExpand"
    :headers="headers"
    :items="items"
    item-key="key_as_string"
    disable-pagination
    fixed-header
    hide-default-footer
    hide-default-header
    disable-sort
  >
    <template v-slot:header="{ props }">
      <thead>
        <tr>
          <th v-for="head in headers">
            <div
              class="header_item"
              v-bind:class="head.class"
            >
              <div class="rowcontent"></div>
              <i></i>
            </div>
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div class="table_status">
        <div
          class="tiny-light"
          v-bind:class="item.checked_in ? 'green' : 'red'"
        ></div>
        <div class="name">{{ item.checked_in ? $t("Checked-In") : $t("Checked-Out") }}</div>
      </div>
    </template>

    <template v-slot:[`item.machine_status`]="{ item }">
      <div class="table_machine_status">
        <p :style="{ color: item.machine_status ? item.machine_status.color : '' }">
          {{ item.machine_status ? item.machine_status.name : "" }}
        </p>
      </div>
    </template>

    <template v-slot:[`item.target_units`]="{ item }">
      {{ numberWithCommas(item.target_units.toFixed()) }}
    </template>

    <template v-slot:[`item.actual`]="{ item }">
      {{ numberWithCommas(item.actual.toFixed()) }}
    </template>

    <template v-slot:[`item.average`]="{ item }">
      {{ numberWithCommas(item.average) }}
    </template>

    <template v-slot:[`item.gap`]="{ item }">
      <div
        class="table_gap"
        v-bind:class="GetGapColor(item.gap, item.target_units, item.actual)"
      >
        {{ numberWithCommas(item.gap.toFixed()) }}
      </div>
    </template>
  </v-data-table>
</template>
<script>
import { numberWithCommas } from "../../../utils/filters";
import { FormatTime, GetAveragePerHour } from "../../../utils/timeFormat";

export default {
  props: ["machines", "table_headers", "badges", "checkedIn", "now", "role"],
  data() {
    return {
      headers: [],
      items: []
    };
  },
  methods: {
    numberWithCommas,
    GetGapColor(gap, target_units, actual) {
      if (actual < target_units) {
        return "bad";
      } else {
        return "good";
      }
    }
  },
  created() {
    this.table_headers.forEach((item) => {
      this.headers.push({ ...item });
    });

    this.headers[0].text = "";
    this.headers[0].sortable = false;

    this.machines.forEach((d, i) => {
      const item = {
        key: i,
        name: d.last_record.username,
        role: this.role,
        status: "1",
        machine: d.last_record.machine_name,
        machine_status: d.current_machine_status || "",
        end_time: d.last_record.end_time,
        zone: d.last_record.zone,
        part: d.last_record.part_number,
        production_time: FormatTime(d.event_duration),
        target_units: d.production_target,
        actual: d.produced,
        gap: d.production_target - d.produced,
        average: GetAveragePerHour(d.produced, d.event_duration),
        scrap: d.scrapped,
        efficiency: `${d.production_target > 0 ? Math.round((d.produced / d.production_target) * 100) + "%" : "--"}`,
        checked_in: 0
      };
      if (this.checkedIn && parseInt(this.checkedIn) == parseInt(d.last_record.machine_id)) {
        item.checked_in = this.checkedIn;
      }
      this.items.push(item);
    });
  }
};
</script>
<style lang="scss">
.realtimeTableExpand {
  thead {
    height: 0px !important;
  }

  tr {
    cursor: auto !important;
  }

  th {
    background: none !important;
    height: 0px !important;
    font-size: 14px;
  }
}
</style>
