<template>
  <div class="upload-container">
    <div
      class="upload-csv"
      v-for="item in items"
    >
      <v-file-input
        color="dark"
        @change="setCsv($event, item.key)"
        :label="$t(item.label)"
        :disabled="uploading || !item.url"
      ></v-file-input>
      <v-select
        v-if="item.key === 'alarmsAndEvents'"
        v-model="updatedSelectedMachines"
        :items="machines"
        item-text="name"
        item-value="pk"
        :label="$t('Select machines')"
        multiple
        clearable
        density="compact"
        @change="$emit('updateSelectedMachines', updatedSelectedMachines)"
      />
      <v-btn
        small
        color="blue"
        @click="uploadCsv($event, item.key, item.url, item.label)"
        :disabled="uploading || !item.url"
        >{{ $t("upload") }}
      </v-btn>
      <v-btn
        v-if="item.download_url"
        small
        color="blue"
        @click="downloadCsv($event, item.download_url, item.download_filename)"
        :disabled="uploading"
        >{{ $t("download") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ConfigurationUpload",
  props: {
    uploading: { type: Boolean, default: false },
    setCsv: { type: Function, default: () => null },
    uploadCsv: { type: Function, default: () => null },
    downloadCsv: { type: Function, default: () => null },
    items: { type: Array, default: () => [] },
    selectedMachines: { type: Array, default: () => [] }
  },
  data() {
    return {
      updatedSelectedMachines: []
    };
  },
  computed: {
    ...mapGetters({
      machines: "dbCache/machines"
    })
  },
  watch: {
    "selectedMachines.length": function () {
      this.updatedSelectedMachines = [...this.selectedMachines];
    }
  }
};
</script>
