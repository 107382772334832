export function getDateFormatter(scale) {
  let scaleString = "1d";
  let dateFormat = "MMM DD";

  switch (scale) {
    case 0:
      scaleString = "";
      dateFormat = "";
      break;
    case 1:
      scaleString = "1h";
      dateFormat = "D MMM hhA";
      break;
    case 2:
      scaleString = "1d";
      dateFormat = "D MMM";
      break;
    case 3:
      scaleString = "1w";
      dateFormat = "week";
      break;
    case 4:
      scaleString = "1M";
      dateFormat = "MMM YY";
      break;
    case 5:
      scaleString = "1y";
      dateFormat = "MMM YY";
      break;
  }

  return { scaleString, dateFormat };
}
