<template>
  <div class="production-chart metric-component">
    <div class="mobile-chart-title-container">
      <h2 class="card-title">{{ $t("Performance") }}</h2>
      <span class="mobile-gauge-container">
        <v-gauge
          v-if="perf"
          gaugeValueClass="gaugeValue"
          :value="perf"
          :donut="true"
          :options="gaugeOpts"
        />
      </span>
    </div>

    <v-progress-linear
      indeterminate
      v-if="!(production && production.length > 0)"
    ></v-progress-linear>
    <div
      class="charts"
      v-else
    >
      <div class="gauge-container">
        <v-gauge
          v-if="perf"
          gaugeValueClass="gaugeValue"
          :value="perf"
          :donut="true"
          :options="gaugeOpts"
        />
      </div>
      <div class="charts_container">
        <div class="chart">
          <bar-chart
            v-if="chartData"
            chartName="productionChart"
            class="productionChart"
            :chartData="chartData"
            :commas="true"
            :chartOptions="chartOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.production-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 18vh;

  .charts {
    display: flex;
    justify-content: center;
    align-items: center;
    .charts_container {
      width: calc(100% - 150px);
      .productionChart {
        width: 100%;
        height: 250px;
        padding-left: 0.5rem;
        display: grid;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import VGauge from "vgauge";
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart.js";

export default {
  components: {
    BarChart,
    VGauge
  },
  props: ["options", "production", "interactiveFilters"],
  data() {
    return {
      loaded: false,
      chartData: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      },
      perf: false
    };
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    format_data() {
      let production = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let total = 0;
      let target_total = 0;
      let targets = [];
      if (this.production) {
        this.production.forEach((interval) => {
          let production_target = 0;
          let actual_production = 0;
          let performance_percentage = 0;

          for (const m of interval.by_machine) {
            const machine = m.machine.machine_id;

            for (const p of m.by_part) {
              //filter out machines that have been not been added to interactive filters
              if (
                this.interactiveFilters.machines.length === 0 ||
                this.interactiveFilters.machines.indexOf(machine) >= 0
              ) {
                if (
                  this.interactiveFilters.parts.length === 0 ||
                  this.interactiveFilters.parts.indexOf(p.part.part_number) >= 0
                ) {
                  const perf = this.options.targets.perfByMachine[machine] || 100;
                  production_target += p.production_target;
                  performance_percentage += p.production_target * (perf / 100);
                  actual_production += p.produced;
                }
              }
            }
          }

          production.push(actual_production);
          targets.push(Math.round(production_target));

          if (actual_production >= performance_percentage) {
            gradients.push(this.$colors.chart.good_gradient);
          } else {
            gradients.push(this.$colors.chart.bad_gradient);
          }
          footers.push([
            this.$t("Target") + " : " + Math.round(production_target).toLocaleString()
          ]);
          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          total += actual_production;
          target_total += production_target;
        });
        this.perf = (total / target_total) * 100;
      }
      this.chartData = {};

      //see if this fixes our rendering glitch
      requestAnimationFrame(() => {
        this.chartData = {
          datasets: [
            {
              type: "line",
              data: targets,
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: this.theme == "dark" ? "white" : "#44B2F3",
              hoverPointRadius: 0,
              borderColor: this.theme == "dark" ? "white" : "#44B2F3",
              titles: titles,
              fill: true,
              label: this.$t("Target"),
              showLine: false
            },
            {
              data: production,
              gradients: gradients,
              footers: footers,
              titles: titles,
              label: this.$t("Produced")
            }
          ],
          labels: labels
        };
      });
    }
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      deep: true,
      handler: "format_data"
    },
    interactiveFilters: {
      handler: "format_data"
    }
  }
};
</script>
