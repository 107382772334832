<template>
  <div
    class="tag"
    :style="`background-color: ${issue.color}`"
  >
    {{ issue.name }}
  </div>
</template>

<script>
export default {
  name: "TicketTag",
  components: {},
  props: {
    issue: { type: Object, default: undefined }
  }
};
</script>

<style scoped lang="scss">
.tag {
  padding: 4px 8px;
  color: var(--text-contrast);
  border-radius: 5px;
  word-break: break-word;

  &:empty {
    display: none;
  }
}
</style>
