import { TAGS_ENDPOINT } from "./endpoints";
import { fromApiToSdk, fromSdkToApi, hexToRgb, normalize } from "./utils";

export const getMachines = async (client) => {
  const response = await client.get("graphql/", {
    params: {
      query: `{machineGroups{
                        name
                        id
                        pk
                        level{
                          name
                          level
                        }
                        machines{
                          name
                          id
                          pk
                        }
                        subGroupIds
                      }
                    }`
    }
  });
  return response.data.data.machineGroups.map((group) => group.machines).flat(1);
};
export const getTagByMachineId = async (httpClient, machineId) => {
  const response = await httpClient.get(TAGS_ENDPOINT, { params: { machine: machineId } });
  if (response.data.length) {
    return response.data[0].tag_id;
  }
  return null;
};

export const getMachineIdByTagId = async (httpClient, tagId) => {
  const response = await httpClient.get(TAGS_ENDPOINT, { params: { tag_id: tagId } });
  if (response.data.length) {
    return response.data[0].machine;
  }
  return null;
};

export const getMachine = async (httpClient, machineId) => {
  const response = await httpClient.get("fo/current/", {
    params: { machine_id: machineId }
  });
  return response.data;
};

export const saveTag = async (httpClient, data) => {
  const stemVector = fromSdkToApi(normalize(data.stemVector));
  const anchorPosition = fromSdkToApi(data.anchorPosition);

  try {
    const response = await httpClient.post(TAGS_ENDPOINT, {
      model_id: data.modelId,
      floor_id: data.floorId,
      color: "#03687d",
      label: data.label,
      description: data.description,
      anchor_position_x: anchorPosition.x,
      anchor_position_y: anchorPosition.y,
      anchor_position_z: anchorPosition.z,
      stem_normal_x: stemVector.x,
      stem_normal_y: stemVector.y,
      stem_normal_z: stemVector.z,
      stem_length: data.stemLength || 0.3,
      machine_id: data.machine_id
    });
    return {
      ...response.data,
      anchorPosition: fromApiToSdk(response.data.anchorPosition),
      stemVector: fromApiToSdk(response.data.stemNormal),
      color: hexToRgb(response.data.color)
    };
  } catch (error) {
    return {
      error: error.response.status === 400 ? error.response.data : "Something goes wrong..."
    };
  }
};

export const deleteTag = async (httpClient, tagId, modelId) => {
  try {
    const response = await httpClient.delete(`${TAGS_ENDPOINT}${tagId}/`, {
      data: {
        tag_id: tagId,
        model_id: modelId
      }
    });
    return response.data;
  } catch (error) {
    return {
      error: error.response.status === 400 ? error.response.data : "Something goes wrong..."
    };
  }
};
