<template>
  <div class="card ai-vision-realtime">{{ $t("Realtime") }}</div>
</template>

<script>
export default {
  name: "AiVisionRealtime"
};
</script>

<style scoped>
.ai-vision-realtime {
  min-height: 100%;
  flex: 1;
  padding: 15px;
}
</style>
