<template>
  <Oee
    class="auto-oee"
    :production="production"
    :options="chartOptions"
  />
</template>
<style lang="scss">
.auto-oee {
  h2 {
    display: none;
  }

  .quality-chart .charts {
    justify-content: unset;
  }

  .gauge-container {
    flex: none;
  }

  .line-container {
    flex: 1;
    height: 250px;
    .oeeChart {
      height: 250px !important;
    }
  }

  .qualityChart {
    height: 100% !important;
    padding: 0 20px !important;
  }
  .oee-chart {
    .charts {
      .line-container {
        .oeeChart {
          height: 50vh;
        }
      }
    }
  }
}
</style>
<script>
import moment from "moment";

import { chartOptions } from "../charts/chartOptions";
import Oee from "../metrics/Oee";
import { SCALE_TAB_VALUE } from "./zone_control_enum";

export default {
  props: [`metric`, "timeScale", "machine_group_pk"],
  components: {
    Oee
  },
  data() {
    return {
      chartOptions: new chartOptions(),
      production: null
    };
  },
  created() {
    this.getChartData();
  },
  computed: {},
  methods: {
    getChartData() {
      switch (this.timeScale) {
        case SCALE_TAB_VALUE.WEEK: {
          this.chartOptions.from_date = moment().startOf("week");
          this.chartOptions.to_date = moment().endOf("week");
          this.chartOptions.scale = "day";
          break;
        }
        case SCALE_TAB_VALUE.MONTH: {
          this.chartOptions.from_date = moment().startOf("month");
          this.chartOptions.to_date = moment().endOf("month");
          this.chartOptions.scale = "day";
          break;
        }
        case SCALE_TAB_VALUE.YEAR: {
          this.chartOptions.from_date = moment().startOf("year");
          this.chartOptions.to_date = moment().endOf("year");
          this.chartOptions.scale = "month";
          break;
        }
        default: {
          // handles cases when timeScale is in the form 'start_date_as_ISO_string end_date_as_ISO_string'
          let dates = this.timeScale.split(" ");
          this.chartOptions.from_date = moment(dates[0]);
          this.chartOptions.to_date = moment(dates[1]);
          this.chartOptions.scale = dates[2];
          break;
        }
      }

      this.chartOptions.machine_group_id = this.machine_group_pk;
      this.chartOptions.dateFormat = "DD MMM";
      this.chartOptions.targets.oee = this.metric.node.currentValue.value.replace(/\D/g, "");

      this.chartOptions.settings = {
        scales: {
          yAxes: [{ zeroLineColor: "#FFF", display: true }],
          xAxes: [{ ticks: { minRotation: 90, maxRotation: 90 } }]
        }
      };

      this.loadProduction();
    },
    loadProduction() {
      let params = this.chartOptions.params;

      this.$http.get("metrics/production_per_interval/", { params: params }).then((res) => {
        this.production = res.data;
      });
    }
  },
  watch: {
    timeScale: function () {
      this.production = null;
      this.getChartData();
    }
  }
};
</script>
