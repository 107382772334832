<template>
  <div class="audit-types card">
    <div class="header">
      <v-btn
        color="primary"
        class="newType hideMobile"
      >
        <router-link :to="{ name: 'new-audit-type' }">
          {{ $t("New Audit Type") }}
        </router-link>
      </v-btn>
      <v-text-field
        v-model="auditTableData.search"
        append-icon="mdi-magnify"
        :label="$t('Search')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </div>
    <v-data-table
      height="calc(100% - 3.25rem)"
      :headers="auditTableData.headers"
      item-key="node.id"
      :loading="auditTableData.loadingData"
      :items="auditTableData.data"
      :sortBy.sync="auditTableData.sortBy"
      :sortDesc.sync="auditTableData.sortDesc"
      :page.sync="auditTableData.page.current"
      :itemsPerPage.sync="auditTableData.page.itemsPerPage"
      :serverItemsLength="auditTableData.total"
      :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
      :single-expand="true"
      :expanded.sync="expanded"
      @item-expanded="loadRevisions"
      :loading-text="$t('Loading Audits... Please wait')"
      fixed-header
      show-expand
      multi-sort
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.node.name }}
      </template>
      <template v-slot:[`item.currentVersion`]="{ item }">
        {{ item.node.currentVersion }}
      </template>
      <template v-slot:[`item.description`]="{ item }">
        {{ item.node.description.substring(0, 100)
        }}{{ item.node.description.length > 100 ? "..." : "" }}
      </template>
      <template v-slot:[`item.machineRequired`]="{ item }">
        <v-icon v-if="item.node.machineRequired">fa-check-circle</v-icon>
      </template>
      <template v-slot:[`item.createRevision`]="{ item }">
        <v-btn
          small
          color="warning"
          fab
          @click.stop.prevent="createRevision(item)"
        >
          <i
            class="fa fa-edit"
            aria-hidden="true"
          ></i>
        </v-btn>
      </template>
      <template v-slot:[`item.perform`]="{ item }">
        <v-btn
          class="actionButton"
          small
          color="success"
          fab
          @click.stop.prevent="gotToAudit(item)"
        >
          <v-icon>mdi-format-list-checks</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="nestedTable">
            <v-data-table
              :headers="versionTableData.headers"
              item-key="id"
              :loading="versionTableData.loadingData"
              :items="item.revisions"
              :sortBy.sync="versionTableData.sortBy"
              :sortDesc.sync="versionTableData.sortDesc"
              :page.sync="versionTableData.page.current"
              :itemsPerPage.sync="versionTableData.page.itemsPerPage"
              :serverItemsLength="versionTableData.total"
              :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
              :loading-text="$t('Loading Audits... Please wait')"
              disable-pagination
              fixed-header
              multi-sort
            >
              <template v-slot:[`item.name`]="{ subItem }">
                {{ subItem.version }}
              </template>
              <template v-slot:[`item.currentVersion`]="{ subItem }">
                {{ subItem.currentVersion }}
              </template>
            </v-data-table>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.audit-types {
  margin-top: 0.25rem;
  height: calc(100% - 4rem);
  padding-bottom: 0.5rem;
  position: relative;

  @media screen and(max-width: 600px) {
    .v-data-table__mobile-row {
      //remove the second to last row (create revision)
      &:nth-last-child(2) {
        display: none;
      }
    }

    .nestedTable {
      width: 100% !important;
      background: transparent !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .newType {
    margin: 1rem;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .header {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
  .v-data-table {
    height: calc(100% - 4rem);
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .v-text-field {
    padding-top: 0;
    max-width: 400px;
  }
  .theme--light.v-input {
    color: black;
    max-width: 500px;
    margin: auto 1rem;
    margin-right: auto;
    .theme--light.v-label {
      color: $light-icon;
      opacity: 0.6;
    }
    input {
      color: $light-icon;
    }
  }
  .nestedTable {
    padding: 1rem;
    margin-left: -16px;
    width: calc(100% + 32px);
    background-color: rgba(0, 0, 0, 0.2);
    .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      background-color: transparent;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      background-color: transparent;
    }
  }
  .keyValue {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 2rem);
    max-width: 400px;
    margin: 0.5rem 1rem;
    p {
      min-width: 7rem;
      margin: 0;
    }
  }
}
@include light_theme() {
  .audit-types {
    .nestedTable {
      background-color: $light-background;
    }
  }
}
</style>

<script>
import moment from "moment";
import Vue from "vue";

import { TableData } from "@/datatypes/table";
import { seconds } from "@/utils/filters";

import PaginatedChart from "../analytics/operator/PaginatedChart";

export default {
  components: { PaginatedChart },
  props: ["options"],
  data() {
    return {
      lastOptions: {},
      expanded: [],
      singleExpand: false,

      auditTableData: new TableData([
        { text: this.$t("Name"), value: "name" },
        { text: this.$t("Current Version"), value: "currentVersion" },
        { text: this.$t("Description"), value: "description" },
        { text: this.$t("Machine Audit"), value: "machineRequired" },
        { text: this.$t("Versions"), value: "node.versionCount" },
        { text: this.$t("Create Revision"), value: "createRevision" },
        { text: this.$t("Perform Audit"), value: "perform" }
      ]),

      versionTableData: new TableData([
        { text: this.$t("Version"), value: "version" },
        { text: this.$t("Revision Comment"), value: "revisionComment" }
      ])
    };
  },
  created() {
    this.lastOptions = this.options;
    this.loadAudits();
  },
  methods: {
    moment,
    seconds,
    loadAudits() {
      this.auditTableData.loadingData = true;
      const query = `query(
          $search: String
          $first: Int,
          $last: Int,
          $after: String,
          $before: String
      ){
        auditTypes(
          search: $search,
          first: $first,
          last: $last,
          after: $after,
          before: $before
        ){
          edges{
            node{
              id,
              name,
              currentVersion,
              description,
              machineRequired,
              versionCount
            }
          }
          pageInfo {
            startCursor
            endCursor
          }
          totalCount
        }
      }`;
      const variables = {
        before: false,
        after: false
      };
      if (this.auditTableData.page.load) {
        Object.assign(variables, this.auditTableData.page.load);
      } else {
        variables["first"] = this.auditTableData.page.itemsPerPage;
      }
      if ("machine_pk" in this.$route.params) {
        variables["machine"] = this.$route.params.machine_pk;
      }
      if (this.auditTableData) {
        variables["search"] = this.auditTableData.search;
      }

      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        this.auditTableData.data = res.data.data.auditTypes.edges;
        this.auditTableData.total = res.data.data.auditTypes.totalCount;
        this.auditTableData.page.next = res.data.data.auditTypes.pageInfo.endCursor;
        this.auditTableData.page.prev = res.data.data.auditTypes.pageInfo.startCursor;
        this.auditTableData.page.load = false;
        this.auditTableData.loadingData = false;
      });
    },
    createRevision(item, body) {
      this.$router.push({ name: "new-audit-revision", params: { auditTypeId: item.node.id } });
    },
    gotToAudit(item, body) {
      this.$router.push({ name: "audit-form", params: { auditTypeId: item.node.id } });
    },
    loadRevisions(row) {
      this.versionTableData.loading = true;
      if (!row.item.revisions) {
        const query = `query($auditType: ID!){
          auditForms(auditType:$auditType){
            id,
            version,
            revisionComment,
            createdDate
          }
        }`;
        const variables = {
          // TODO switch back to globalid
          auditType: row.item.node.id
        };

        this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
          Vue.set(row.item, "revisions", res.data.data.auditForms);
          this.versionTableData.total = res.data.data.auditForms.length;
          this.versionTableData.loading = false;
        });
      } else {
        this.versionTableData.total = row.item.revisions.length;
        this.versionTableData.loading = false;
      }
    }
  },
  watch: {
    "auditTableData.search": function () {
      if (this.auditTableData.searchDebouncer) {
        clearTimeout(this.auditTableData.searchDebouncer);
      }
      let load = this.loadAudits;
      this.auditTableData.searchDebouncer = setTimeout(load, 500);
    },
    "auditTableData.page.itemsPerPage": function () {
      console.log("ipp");
      this.loadAudits();
    },
    "auditTableData.page.current": function (newPage, oldPage) {
      console.log("current oage");
      if (newPage > oldPage) {
        this.auditTableData.page.load = {
          after: this.auditTableData.page.next,
          before: false,
          first: this.auditTableData.page.itemsPerPage
        };
        this.loadAudits();
      } else if (newPage < oldPage) {
        this.auditTableData.page.load = {
          after: false,
          before: this.auditTableData.page.prev,
          last: this.auditTableData.page.itemsPerPage
        };
        this.loadAudits();
      }
    }
  }
};
</script>
