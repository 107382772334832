<template>
  <div class="listDiv">
    <ul :class="{ 'mobile-regions': $vuetify.breakpoint.smAndDown }">
      <li
        v-for="instance in instances"
        :key="instance.id"
      >
        <a
          v-if="instance.ready"
          :href="instance.url"
          @click="storeHelpText(instance)"
          :class="{ enabled: instance.ready, disabled: !instance.ready }"
          >{{ instance.location }}</a
        >
        <span
          v-if="!instance.ready"
          :class="{ enabled: instance.ready, disabled: !instance.ready }"
          @click="
            () => {
              showInstanceHelpText = true;
              selectedInstance = instance;
            }
          "
          >{{ instance.location }}</span
        >
      </li>
    </ul>
    <v-dialog
      v-model="showInstanceHelpText"
      width="full"
      height="screenHeight"
    >
      <v-card>
        <DialogCloseButton :onClick="() => (showInstanceHelpText = false)" />
        <v-card-title class="headline"> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <lottie-player
                :src="notLaunched"
                background="transparent"
                speed="1"
                style="height: 80vh"
                loop
                autoplay
              ></lottie-player>
            </v-col>
            <v-col>
              <span class="headline"
                >{{ selectedInstance.location }}:
                {{ $t("Baxterity has not been launched for this site.") }}</span
              >
              <div v-html="selectedInstance.helpText"></div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showInstanceHelpText = false"
          >
            {{ $t("Close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { stringify } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import "@lottiefiles/lottie-player";

import DialogCloseButton from "@/components/DialogCloseButton";

import notLaunched from "../assets/lottie/notlaunched.json";

export default {
  name: "InstanceLinks",
  props: ["selectedRegion"],
  components: { DialogCloseButton },
  data() {
    return {
      instances: [],
      loadingData: false,
      showInstanceHelpText: false,
      selectedInstance: { location: null, helpText: null },
      notLaunched: stringify(notLaunched)
    };
  },
  methods: {
    storeHelpText(instance) {
      localStorage.setItem("helpText", instance.helpText);
    },
    async loadApplicationInstances() {
      this.loadingData = true;
      const query = `
    query GetApplicationInstances($region: String!) {
      applicationInstances(region: $region) {
        edges {
          node {
            id
            location
            ready
            url
            helpText
          }
        }
      }
    }
  `;
      const variables = { region: this.selectedRegion.title };
      await this.$http
        .post("graphql/", { query, variables })
        .then(({ data }) => {
          const { edges } = data.data["applicationInstances"];
          this.instances = edges.map((edge) => edge.node);
        })
        .catch((error) => {
          console.error(error);
          this.instances = [];
        })
        .finally(() => {
          this.loadingData = false;
        });
    }
  },
  mounted() {
    this.loadApplicationInstances();
  }
};
</script>
<style scoped lang="scss">
@import "../scss/variables.scss";
.listDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
ul {
  list-style-type: none;
  padding: 0 0 75px;
  margin: 0;
  overflow-y: scroll;
  li {
    font-size: 1.5em;
  }
}
.enabled {
  color: white;
}
.enabled:hover {
  color: $blue;
}
.disabled {
  color: $lighterGrey;
  opacity: 0.5;
}
a {
  text-decoration: none;
}
.mobile-regions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  list-style: none;
  li {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
</style>
