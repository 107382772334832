<template>
  <div class="help-center">
    <div class="card-header">
      <h2>{{ $t("Help Center") }}</h2>
    </div>
    <div class="card-content">
      <button @click="setHelpCenterVisibility({ visible: true, options: options })">
        <i
          class="fa fa-bell-o"
          aria-hidden="true"
        ></i
        >{{ $t("P911") }}
      </button>
      <button @click="setRightPanel('audits')">
        <i
          class="fa fa-pencil-square-o"
          aria-hidden="true"
        ></i
        >{{ $t("Audits") }}
      </button>
      <button @click="setRightPanel('docs')">
        <i
          class="fa fa-file-text-o"
          aria-hidden="true"
        ></i
        >{{ $t("E-Docs") }}
      </button>
      <button @click="setRightPanel('video')">
        <i
          class="fa fa-video-camera"
          aria-hidden="true"
        ></i
        >{{ $t("Videos") }}
      </button>
      <button @click="setRightPanel('search')">
        <i
          class="fa fa-search"
          aria-hidden="true"
        ></i
        >{{ $t("Search") }}
      </button>
      <button
        v-if="digitalTwinIsEnabled"
        @click="() => $router.push({ name: 'digital-twin', params: { tagId: tagId } })"
        :disabled="!tagId"
      >
        <i
          class="fa fa-street-view"
          aria-hidden="true"
        ></i
        >{{ $t("3D View") }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
@import "../scss/variables";
@import "../scss/mq";

.help-center {
  height: 100%;
  .card-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 1rem;
    overflow: hidden;
    button {
      background-color: transparent;
      margin: 0.5rem 0.25rem;
      margin-bottom: 10%;
      color: white;
      border: none;
      border-radius: 1rem;
      font-size: 0.85rem;
      transition: all 0.15s ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      i {
        font-size: 1.25rem;
        height: 3rem;
        width: 3rem;
        line-height: 3rem;
        border-radius: 3rem;
        color: $cardColor;
        background-color: $white;
        margin-bottom: 0.5rem;
        transition: all 0.15s ease-in-out;
      }
      &:hover {
        outline: none;
      }
      &:active,
      &:focus {
        outline: none;
        i {
          background-color: $blue;
          color: white;
        }
      }
    }
  }
}
.theme--light.v-application {
  .card-content {
    button {
      color: $light-color-val;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { getTagByMachineId } from "../views/digital-twin/api";
import { hasUserRole, userRoles } from "../utils/user";

export default {
  props: ["options"],
  data() {
    return {
      tagId: null
    };
  },
  async created() {
    this.tagId = await getTagByMachineId(this.$http, this.options.machine.machine_pk);
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    digitalTwinIsEnabled() {
      return hasUserRole(this.user, userRoles.DigitalTwin);
    }
  },
  methods: {
    ...mapActions({
      setRightPanel: "components/SetRightPanel",
      setHelpCenterVisibility: "components/SetHelpCenterVisibility"
    })
  }
};
</script>
