<template>
  <div class="solution-dialog">
    <v-dialog
      v-model="showDialog"
      persistent
      content-class="issue-entry-dialog solution"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <div class="dialogBtn">
            <v-badge
              color="white"
              :content="solutions.length"
              offset-x="0"
              offset-y="35"
              :value="solutions.length"
            >
              <v-btn
                class="edit-btn"
                v-bind="attrs"
                v-on="on"
                color="#f7bd59"
                light
                fab
                x-small
              >
                <i
                  class="fa fa-plus"
                  aria-hidden="true"
                ></i>
              </v-btn>
            </v-badge>
            <v-card
              class="issues"
              v-if="hover && solutions.length"
            >
              <div
                class="issueText"
                v-for="sl in solutions"
                :key="'solution' + sl"
              >
                <p>{{ sl }}</p>
              </div>
            </v-card>
          </div>
        </v-hover>
      </template>
      <v-card>
        <DialogCloseButton :onClick="() => (showDialog = false)" />
        <v-card-title class="headline">
          {{ $t("Long Term Solution For") }} {{ timeDisplay }}
        </v-card-title>
        <v-card-text>
          <div class="selections">
            <div
              class="selection"
              @click="selectedReason = false"
              v-if="selectedReason"
            >
              <p class="label">{{ $t("Reason") }}</p>
              <div class="reason">
                <ReasonButtonWithTooltip
                  :text="selectedReason.reason"
                  :isSelected="false"
                  :action="
                    () => {
                      selectedReason = false;
                      selectedCause = false;
                    }
                  "
                />
              </div>
            </div>
            <div
              class="selection"
              @click="selectedCause = false"
              v-if="selectedCause"
            >
              <p class="label">{{ $t("Root Cause") }}</p>
              <div class="reason">
                <ReasonButtonWithTooltip
                  v-if="selectedCause"
                  :text="selectedCause"
                  :isSelected="false"
                  :action="
                    () => {
                      selectedReason = false;
                      selectedCause = false;
                    }
                  "
                />
              </div>
            </div>
          </div>
          <div class="flex">
            <div
              class="reasons"
              v-if="!selectedReason"
            >
              <div
                class="reason"
                v-for="issue in filteredIssues"
                :key="issue.id"
              >
                <ReasonButtonWithTooltip
                  :text="issue.reason"
                  :isSelected="selectedReason.id == issue.id"
                  :action="
                    () => {
                      selectReason(issue);
                    }
                  "
                />
              </div>
            </div>
            <div
              class="cms"
              v-if="selectedReason && causes && !selectedCause"
            >
              <div
                class="countermeasure"
                v-for="cause in causes"
                :key="'cause' + cause"
              >
                <ReasonButtonWithTooltip
                  :text="cause"
                  :isSelected="selectedCause == cause"
                  :action="
                    () => {
                      selectCause(cause);
                    }
                  "
                />
              </div>
            </div>
            <div
              class="solutions"
              v-if="selectedReason && selectedCause && solutionOptions"
            >
              <div
                class="solution"
                v-for="solution in solutionOptions"
                :key="'solution' + solution"
              >
                <ReasonButtonWithTooltip
                  :text="solution.node.text"
                  :isSelected="selectedSolution == solution"
                  :action="
                    () => {
                      selectedSolution = solution;
                    }
                  "
                  :disabled="selectedReasonSolutions.includes(solution.node.text)"
                />
              </div>
              <div
                class="solution"
                key="other"
                v-if="selectedCause"
              >
                <ReasonButtonWithTooltip
                  :text="$t('Other')"
                  :isSelected="selectedSolution == 'other'"
                  :action="
                    () => {
                      selectedSolution = 'other';
                    }
                  "
                />
              </div>
            </div>
          </div>
          <div
            class="otherText"
            v-if="selectedSolution == 'other'"
          >
            <v-text-field
              :label="$t('Solution')"
              v-model="otherText"
            >
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showDialog = false"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            primary
            text
            :disabled="!selectedSolution || (selectedSolution == 'other' && otherText == '')"
            :loading="saving"
            @click="updateIssue"
          >
            {{ $t("Submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.solution-dialog {
  .edit-btn {
    i {
      color: white;
    }
  }
  .issues {
    overflow: visible;
    width: 150px;
    min-width: 150px;
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);
    padding: 0.5rem;
    z-index: 9999;
    .issueText {
      max-height: 3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-y: auto;
    }
  }
  .dialogBtn {
    position: relative;
  }
}
.v-dialog {
  .flex {
    display: flex;
  }
  .reason {
    .v-btn {
      border: 2px solid rgba(0, 0, 0, 0);
      transition: all 0.25s;
      margin: 0.25rem;
    }
    .selected {
      border: 2px solid white;
    }
  }
  .countermeasure,
  .solution {
    .v-btn {
      transition: all 0.25s;
      margin: 0.25rem;
      border: 2px solid rgba(0, 0, 0, 0);
    }
    .selected {
      border: 2px solid white;
    }
  }
}
</style>
<script>
import moment from "moment";

import DialogCloseButton from "@/components/DialogCloseButton";
import ReasonButtonWithTooltip from "@/components/ReasonButtonWithTooltip";

export default {
  props: ["issues", "time", "machine_id"],
  components: { ReasonButtonWithTooltip, DialogCloseButton },
  data() {
    return {
      showDialog: false,
      reasons: {},
      causes: [],
      solutionOptions: [],
      selectedReason: false,
      selectedCause: false,
      selectedSolution: false,
      saving: false,
      otherText: ""
    };
  },
  created() {},
  computed: {
    timeDisplay() {
      return this.start.format("D MMM hhA") + " - " + this.start.add(1, "h").format("hhA");
    },
    start() {
      return moment(this.time).startOf("hour");
    },
    filteredIssues() {
      let issues = [];
      if (this.issues) {
        this.issues.forEach((issue) => {
          if (issue.cause) {
            issues.push(issue);
          }
        });
      }
      return issues;
    },
    selectedReasonSolutions() {
      if (this.selectedReason.solution) {
        return this.selectedReason.solution
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    solutions() {
      let solutions = [];
      if (this.issues) {
        this.issues.forEach((issue) => {
          if (issue.solution) {
            let issueSolution = issue.solution
              .replaceAll("[", "")
              .replaceAll("]", "")
              .replaceAll("'", "")
              .split(",");
            if (issueSolution.length > 0) {
              solutions = solutions.concat(issueSolution);
            }
          }
        });
      }

      return solutions;
    }
  },
  methods: {
    moment,
    selectReason(issue) {
      this.selectedReason = issue;
      this.causes = issue.cause
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("'", "")
        .split(",");
    },
    selectCause(cause) {
      this.selectedCause = cause;
      this.solutionOptions = [];
      const query = `query($cause_Text: String, $machine: [ID]!){
        issueSolutions(cause_Text: $cause_Text, machine:$machine){
          edges{
            node{
              id
              text
            }
          }
        }
      }`;
      const variables = {
        cause_Text: cause,
        machine: [this.machine_id]
      };
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.solutionOptions = res.data.data.issueSolutions.edges.sort((a, b) => {
            if (a.node.text < b.node.text) {
              return -1;
            }
            if (a.node.text > b.node.text) {
              return 1;
            }
            return 0;
          });
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    updateIssue() {
      this.saving = true;
      console.log("here");
      const query = `mutation ($id: ID!, $issueInput: IssueInput!){
        updateIssue(globalId:$id, issueData: $issueInput) {
          issue{
            id,
            title,
            openedDate,
            closedDate,
            issueStartDate,
            issueEndDate,
            machineId,
            machineName,
            issueTypeId,
            issueTypeName,
            reason,
            countermeasures,
            cause,
            solution,
          }
        }
      }`;
      let variables = {
        id: this.selectedReason.id,
        issueInput: {}
      };
      if (this.selectedSolution == "other" && this.otherText) {
        variables["issueInput"]["solution"] = this.otherText;
      } else {
        variables["issueInput"]["solution"] = this.selectedSolution.node.text;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.$message("ALERT", { text: this.$t("Solution Submitted"), type: "success" });
          this.showDialog = false;
          this.selectedReason = false;
          this.selectedCause = false;
          this.causes = [];
          this.saving = false;
          this.$emit("UpdatedSolution", res.data.data.updateIssue.issue);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed", type: "error" });
          this.saving = false;
        });
    }
  }
};
</script>
