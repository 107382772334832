import { render, staticRenderFns } from "./MiniMap.vue?vue&type=template&id=05c1cb6c&scoped=true&"
import script from "./MiniMap.vue?vue&type=script&lang=js&"
export * from "./MiniMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c1cb6c",
  null
  
)

export default component.exports