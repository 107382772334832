import { camelCase, isObject } from "lodash";
import { getComputedColors } from "../../../ui/charts/getComputedColors";

/*
 * The getComputedColors function retrieves the computed styles for the application.
 * Used for get css variable value and provide this for charts
 * */

export const useMachineStatusHexColor = (item) => {
  const colors = getComputedColors();
  const colorSet = isObject(item) ? item.colorSet || item.color_set : item;

  return colors[camelCase(`status${colorSet}`)] ? colors[camelCase(`status${colorSet}`)].hex : "";
};
