<template>
  <div class="filter_TLA_reports card">
    <div class="filters-container">
      <div class="filter-card radio-group card">
        <v-radio-group
          v-model="view_type"
          @change="setupFilters"
        >
          <v-radio
            :label="this.$t('Data Table')"
            value="data_table"
          />
          <v-radio
            :label="this.$t('Analytics')"
            value="analytics"
          />
        </v-radio-group>
      </div>
      <div class="filter-card radio-group card">
        <div class="option">
          <h4>{{ $t("Presets") }}</h4>
          <v-tabs
            class="smallFilterTabs"
            height="25"
            v-model="preset"
            @change="changePreset"
          >
            <v-tab class="notab"></v-tab>
            <v-tab class="smalltab">{{ $t("Day") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Week") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Month") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Year") }}</v-tab>
          </v-tabs>
        </div>

        <div class="option">
          <h4>{{ $t("Scale") }}</h4>
          <v-tabs
            class="smallFilterTabs"
            height="25"
            v-model="scale"
            @change="setupFilters"
          >
            <v-tab class="notab"></v-tab>
            <v-tab class="smalltab">{{ $t("Hour") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Day") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Week") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Month") }}</v-tab>
            <v-tab class="smalltab">{{ $t("Year") }}</v-tab>
          </v-tabs>
        </div>

        <div class="option">
          <h4>{{ $t("From Date") }}</h4>
          <datetime-picker
            class="date-picker"
            id="from_date"
            v-model="from_date"
            :is-dialog="true"
            @input="changeDateManually"
          />
        </div>

        <div class="option">
          <h4>{{ $t("To Date") }}</h4>
          <datetime-picker
            class="date-picker"
            id="to_date"
            v-model="to_date"
            :is-dialog="true"
            @input="changeDateManually"
          />
        </div>
      </div>
      <div class="filter-card filter-group card">
        <div class="option">
          <MachineGroupSelector
            :defaultMachines="machines"
            :defaultMachineGroups="machine_groups"
            :hideMachineGroupSelector="true"
            :setMachineGroups="setMachineGroups"
            :setFilterViewType="setFilterViewType"
          />
        </div>
        <VAutocomplete
          class="select-picker"
          :placeholder="this.$t('Part')"
          :items="parts"
          :filter="filterParts"
          clearable
          multiple
          single-line
          persistent
          v-model="part_numbers"
          item-text="name"
          :menu-props="{
            closeOnClick: true
          }"
          @change="setupFilters"
        >
          <template v-slot:item="{ item, index }">
            {{ item.name }} : {{ item.description }}
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              <span>{{ item.name }}</span>
            </span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              &nbsp;(+{{ part_numbers.length - 1 }} {{ $t("others") }})
            </span>
          </template>
        </VAutocomplete>
        <VAutocomplete
          :placeholder="$t('Major Losses')"
          :items="major_losses"
          v-model="selected_major_losses"
          clearable
          multiple
          single-line
          item-value="statuscodeSet"
          item-text="name"
          :menu-props="{
            closeOnClick: true
          }"
          @change="setupFilters"
        >
          <template v-slot:item="{ item, index }">
            {{ item.name }}
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              <span>{{ item.name }}</span>
            </span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              &nbsp;(+{{ selected_major_losses.length - 1 }}
              {{ selected_major_losses.length > 2 ? $t("others") : $t("other") }})
            </span>
          </template>
        </VAutocomplete>
        <VAutocomplete
          :placeholder="$t('Minor Losses')"
          :items="minor_losses"
          v-model="selected_minor_losses"
          clearable
          multiple
          single-line
          item-value="code"
          item-text="description"
          :menu-props="{
            closeOnClick: true
          }"
          @change="setupFilters"
        >
          <template v-slot:item="{ item, index }">
            {{ `${item.code}: ${item.description}` }}
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              <span>{{ `${item.code}: ${item.description}` }}</span>
            </span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              &nbsp;(+{{ selected_minor_losses.length - 1 }}
              {{ selected_minor_losses.length > 2 ? $t("others") : $t("other") }})
            </span>
          </template>
        </VAutocomplete>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import DatetimePicker from "@/components/form/DatetimePicker";

import PaginatedChart from "../operator/PaginatedChart";
import MachineGroupSelector from "../production_report/MachineGroupSelector";
import useTLAFilters from "./useTLAFilters";

export const VIEWTYPES = {
  DATA_TABLE: "data_table",
  ANALYTICS: "analytics"
};

export default {
  name: "filterTLAReports",
  components: {
    PaginatedChart,
    DatetimePicker,
    MachineGroupSelector
  },
  props: ["applyFilters", "defaultFilters"],
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    })
  },
  mounted() {
    this.setupFilters();
    this.setupLosses();
    if (!this.defaultFilters.preset) {
      this.preset = 1;
      this.changePreset(1);
    }
  },
  setup() {
    const { removeAllFilters } = useTLAFilters();
    return {
      removeAllFilters
    };
  },
  data() {
    return {
      view_type: VIEWTYPES.ANALYTICS,
      departments: [],
      machine_groups: [],
      machines: [],
      parts: [],
      part_numbers: [],
      major_losses: [],
      minor_losses: [],
      selected_major_losses: [],
      selected_minor_losses: [],
      preset: 0,
      presets: [null, "day", "week", "month", "year"],
      scale: null,
      scales: [null, "1h", "1d", "1w", "1M", "1y"],
      from_date: null,
      to_date: null,
      debounceFilterTimeout: null,
      ...(this.defaultFilters || {})
    };
  },
  methods: {
    filterParts(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();

      // define your custom logic of your filter
      const name = item.name.toLowerCase();
      const value = item.description.toLowerCase();

      return name.indexOf(searchText) > -1 || value.indexOf(searchText) > -1;
    },
    setMachineGroups(machineGroups, machines) {
      //we need to load zones with a list of IDs
      this.machine_groups = machineGroups;
      this.machines = machines;

      this.getMachineData(machineGroups, machines).then((data) => {
        this.machineData = data;
        const allMachines = data.map((m) => m.pk);
        this.setupFilters();
        this.loadParts(allMachines);
      });
    },
    getMachineData(groups, machines) {
      let groupsArr = [-1];
      let machinesArr = [-1];

      if (groups.length > 0) {
        groupsArr = groups;
      }

      if (machines.length > 0) {
        machinesArr = machines;
      }

      return new Promise((resolve) => {
        this.$http
          .post("graphql/", {
            query: `
         query($groupIds: [Int], $machineIds: [Int]){
          machineGroups(ids: $groupIds){
          allMachines {
            name,
            id,
            pk,
            performanceTarget,
            qualityTarget,
            oeeTarget,
            runtimeTarget
          }
        }
        machines(ids: $machineIds) {
          name,
          id,
          pk,
          performanceTarget,
          qualityTarget,
          oeeTarget,
          runtimeTarget
        }
      }`,
            variables: {
              groupIds: groupsArr,
              machineIds: machinesArr
            }
          })
          .then((res) => {
            let machineList = [];
            const machineListByMachineGroup = res.data.data.machineGroups;
            for (const m of machineListByMachineGroup) {
              machineList = machineList.concat(m.allMachines);
            }
            //filter out the duplicates that exist in both the list we got from the
            //groups and the list we sent
            machineList = machineList.filter((m) => machinesArr.indexOf(m.pk) < 0);
            //then add the data from the individual machines
            machineList = machineList.concat(res.data.data.machines);

            resolve(machineList);
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    setFilterViewType() {},
    changeDateManually() {
      this.preset = 0;
      this.setupFilters();
    },
    changePreset(e) {
      const currentPreset = this.presets[e];
      switch (currentPreset) {
        case "shift":
          {
            this.loadShift()
              .then((result) => {
                if (result.length > 0 && result[0].shiftDays.length > 0) {
                  const shiftDay = result[0].shiftDays[0];
                  const from = new Date(shiftDay.lastStartDatetime);
                  const to = new Date(shiftDay.nextEndDatetime);

                  this.from_date = from;
                  this.to_date = to;
                } else {
                  this.from_date = moment().startOf("day").toDate();
                  this.to_date = moment().endOf("day").toDate();
                }
                //we finished loading the shift
                this.presetChanged = true;
                this.setupFilters();
              })
              .catch((e) => {
                console.log(e);
                this.from_date = moment().startOf("day").toDate();
                this.to_date = moment().endOf("day").toDate();

                //we failed loading the shift.  Just use the day
                this.presetChanged = true;
                this.setupFilters();
              });
            this.scale = 1;
          }
          break;
        case "day":
          {
            this.from_date = moment().startOf("day").toDate();
            this.to_date = moment().endOf("day").toDate();
            this.scale = 1;
          }
          break;
        case "week":
          {
            this.from_date = moment().startOf("week").toDate();
            this.to_date = moment().endOf("week").toDate();
            this.scale = 2;
          }
          break;
        case "month":
          {
            this.from_date = moment().startOf("month").toDate();
            this.to_date = moment().endOf("month").toDate();
            this.scale = 3;
          }
          break;
        case "year":
          {
            this.from_date = moment().startOf("year").toDate();
            this.to_date = moment().endOf("year").toDate();
            this.scale = 4;
          }
          break;
      }

      this.presetChanged = true;
      this.setupFilters();
    },
    loadShift() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{shifts(current:true){
            name
            shiftDays(current:true){
              lastStartDatetime
              nextStartDatetime
              nextEndDatetime
            }
          }
        }`
            }
          })
          .then((res) => {
            resolve(res.data.data.shifts);
          })
          .catch(() => {
            reject();
          });
      });
    },
    filtersChanged() {
      if (!this.filters) return true;

      return (
        this.part_numbers !== this.filters.part_numbers ||
        this.machine_groups !== this.filters.machine_groups ||
        this.machines !== this.filters.machines ||
        this.status_codes !== this.filters.status_codes
      );
    },
    setupFilters() {
      if (this.debounceFilterTimeout) {
        clearTimeout(this.debounceFilterTimeout);
      }
      this.debounceFilterTimeout = setTimeout(() => {
        if (this.filtersChanged()) {
          this.removeAllFilters([], -1);
        }

        const status_codes = new Set(this.selected_minor_losses);
        const loss_name_string = new Set();
        const codesMap = {};
        for (const s of this.major_losses) {
          for (const sc of s.statuscodeSet) {
            codesMap[sc.code] = { name: s.name, description: sc.description, code: sc.code };
          }
        }

        for (const selected_ml of this.selected_major_losses) {
          for (const ml of selected_ml) {
            status_codes.add(ml.code);
            loss_name_string.add(codesMap[ml.code].name);
          }
        }

        for (const ml of this.selected_minor_losses) {
          loss_name_string.add(`${codesMap[ml].code}:${codesMap[ml].description}`);
        }

        const filters = {
          view_type: this.view_type,
          part_numbers: this.part_numbers,
          machine_groups: this.machine_groups,
          machines: this.machines,
          status_codes: Array.from(status_codes),
          from_date: this.from_date,
          to_date: this.to_date,
          scale: this.scales[this.scale],
          scaleKey: this.scale,
          loss_name_string: Array.from(loss_name_string)
        };

        this.applyFilters(filters);
        this.debounceFilterTimeout = null;
      }, 1000);
    },
    loadParts: async function (machineIds) {
      let machines = [-1];
      if (machineIds.length > 0) {
        machines = machineIds;
      }

      const parts = await this.getParts(machines);
      this.parts = parts;
    },
    getParts(ids) {
      return new Promise((resolve) => {
        this.$http
          .post("graphql/", {
            query: `
          query($ids: [Int]){
              partConfigurations(machinePks: $ids){
                  part {
                      name,
                      id,
                      description,
                      pk
                  }
              }
          }
        `,
            variables: {
              ids: ids
            }
          })
          .then((res) => {
            const partConfigs = res.data.data.partConfigurations;
            const result = [];
            for (let p of partConfigs) {
              result.push(p.part);
            }
            resolve(result);
          })
          .catch(() => {
            resolve([]);
          });
      });
    },
    setupLosses() {
      const major_losses = [];
      const minor_losses = [];
      for (const s of this.statuses) {
        if (!s.running) {
          major_losses.push(s);
          for (const set of s.statuscodeSet) {
            minor_losses.push(set);
          }
        }
      }
      this.major_losses = major_losses;
      this.minor_losses = minor_losses;
    }
  },
  watch: {
    statuses: function () {
      this.setupLosses();
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.filter_TLA_reports {
  height: 100%;
  padding-bottom: 10px;

  .v-time-picker-custom .v-input {
    padding-top: 0;
  }

  .card {
    padding: 5px;
  }

  .option {
    padding-bottom: 20px;
  }

  .filter-group {
    .v-input {
      padding: 0 10px;
    }
  }

  .date-picker {
    padding: 0 10px;
  }

  .filters-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: unset;
    padding: 10px;
  }

  .radio-group {
    min-height: unset;

    .v-input--selection-controls {
      margin-top: 0px;
      padding-top: 16px;
    }
  }

  .filter-card {
    margin-bottom: 15px;

    h4 {
      padding: 0 10px;
      color: $blue;
    }
  }

  .smallFilterTabs {
    min-height: unset;
    padding-left: 15px;

    .notab {
      padding: 0;
      margin: 0;
      min-width: unset;
      height: 20px;
      font-size: 10px;
      width: 0;
    }

    .smalltab {
      padding: 0 8px;
      margin: 0;
      min-width: unset;
      height: 20px;
      font-size: 10px;
    }
  }
}
</style>
