<template>
  <div class="login">
    <div
      class="login-card"
      :class="{ submitting: submitting }"
    >
      <div class="login-right">
        <img
          src="../assets/img/LP Machines-01.png"
          width="100%"
        />
      </div>
      <form
        @submit.prevent="submit"
        id="login"
      >
        <div class="logo">
          <img
            v-if="logo"
            class="logoImg"
            width="100%"
            :src="logo"
          />
        </div>
        <img
          class="animated"
          src="../assets/logos/icon.png"
          v-if="submitting"
        />
        <transition name="slide-fade">
          <p
            class="success"
            v-if="success"
          >
            {{ $t("Welcome to IntelyCX") }}
          </p>
        </transition>
        <div class="inputs">
          <div class="input-field">
            <i class="fa fa-user"></i>
            <input
              id="username"
              type="text"
              v-model="username"
              :placeholder="$t('Username')"
              tabindex="1"
            />
          </div>
          <div class="input-field">
            <i
              class="fa fa-lock"
              aria-hidden="true"
            ></i>
            <input
              id="password"
              type="password"
              v-model="password"
              :placeholder="$t('Password')"
              tabindex="2"
            />
          </div>
          <button
            class="btn btn-block"
            tabindex="3"
          >
            {{ $t("Login") }}
          </button>
          <button
            class="btn btn-block okta"
            v-if="hasOkta"
            @click.stop.prevent="oktaLogin"
            id="login-button"
          >
            {{ $t("Login With SSO") }}
          </button>
        </div>
        <div>
          <p v-if="error_message">{{ error_message }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
@import "../scss/mq";
@import "../scss/variables";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-card {
    position: relative;
    width: calc(100% + 1rem);
    max-width: 2500px;
    height: calc(100% + 1rem);
    max-height: 1500px;
    border-radius: 1rem;
    overflow: hidden;
    background: $darkIndigoGradient;
    transition: all 0.75s ease-in-out;
    box-shadow:
      2px 2px 2px #1d252fc7,
      -2px -2px 3px 2px #3a4452b8,
      4px 4px 8px #1d252fad,
      -4px -4px 8px 5px #3a4452b3,
      8px 8px 22px #0a0e13ab,
      -8px -8px 45px #4e607761;
    &:hover {
      outline: none;
    }
    .animated {
      animation: rotation 1s infinite linear;
      max-width: 300px;
    }
    .login-right {
      z-index: 2;
      position: absolute;
      left: 30px;
      top: 0;
      bottom: 0;
      right: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: all 0.75s ease-in-out;
      img {
        width: 115%;
        margin-left: 15%;
      }
    }
    form {
      z-index: 1;
      position: absolute;
      left: 60%;
      top: 0;
      bottom: 0;
      right: 0%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: white;
      transition: all 0.75s ease-in-out;
      .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          max-width: 400px;
          object-fit: scale-down;
          margin-bottom: 50px;
          transition: all 0.75s ease-in-out;
        }
      }
      p {
        transition: all 0.75s ease-in-out;
        bottom: 6.5rem;
        font-size: 1.5rem;
        left: 0;
        right: 0;
        text-align: center;
      }
      .success {
        position: absolute;
      }
      .inputs {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .input-field {
        transition: all 0.75s ease-in-out;
        margin: 0.5rem;
        input {
          border-color: #5fd3cc;
        }
        i {
          margin-right: 0.5rem;
          font-size: 1.5rem;
          color: white;
        }
      }
      .btn {
        transition: all 0.25s ease-in-out;
        margin: 1rem;
        color: white;
        width: calc(100% - 4rem);
        margin-left: 3rem;
        padding: 0.5rem;
        height: 50px;
        background: #d64b1a;
        box-shadow:
          -1px 2px 4px #00000057,
          1px -2px 4px #00000000;
        font-size: 1.5rem;
        font-weight: 400;
        border: 2px solid #d64b1a;
        &:focus {
          box-shadow:
            -2px 2px 5px #fe9c284a,
            2px -2px 5px #fe9c284a;
          border: 2px solid #d64b1a;
        }
        &.okta {
          margin-top: 0.25rem;
          background-color: #5fd3cc;
          border: 2px solid #5fd3cc;
        }
      }
      p {
        margin: 0.5rem;
      }
    }
    &.submitting {
      max-width: 500px;
      max-height: 750px;
      .login-right {
        right: 0;
        opacity: 0;
      }
      form {
        left: 0;
        img,
        .input-field,
        .fa,
        .btn {
          opacity: 0;
        }
        p,
        .animation {
          opacity: 1;
        }
        .animated {
          opacity: 1;
          margin-bottom: 150px;
        }
      }
    }
  }
}
@include media_below(1000px) {
  .login {
    .login-card {
      overflow: scroll;
      border-radius: 0;
      .login-right {
        display: none;
      }
      form {
        left: 0%;
        .logo {
          width: 75%;
        }
        .btn {
          margin-left: 2rem;
          margin-right: 2rem;
        }
      }
      &.submitting {
        max-width: 100%;
        max-height: 100%;
        .logo {
          bottom: 0;
        }
        form {
          top: 0;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

import animatedLogo from "@/components/animatedLogo";

export default {
  data() {
    return {
      username: null,
      password: null,
      submitting: false,
      success: false,
      error: false,
      error_message: false,
      sample_data: null,
      restartAnimation: false,
      logo: false
    };
  },
  created() {
    this.logout();
  },
  mounted() {
    this.$http
      .get("graphql/", {
        params: {
          query: `{settings{
        bannerLogo
      }
    }`
        }
      })
      .then((res) => {
        if (res.data.data.settings.length > 0) {
          this.logo = res.data.data.settings[0].bannerLogo;
        }
      });
    if (this.$route.query.login_with_okta === "true") {
      if (this.profile) {
        this.logout().then(() => {
          this.oktaLogin();
        });
      } else {
        this.oktaLogin();
      }
    }
  },
  components: {
    animatedLogo
  },
  computed: {
    ...mapGetters({
      profile: "session/User"
    }),
    hasOkta() {
      return this.$auth ? true : false;
    }
  },
  methods: {
    ...mapActions({
      login: "session/Login",
      logout: "session/Logout"
    }),
    async oktaLogin() {
      await this.$auth.signInWithRedirect({
        state: { next: this.$route.query.next }
      });
    },
    submit() {
      this.restartAnimation = !this.restartAnimation;
      this.submitting = true;
      this.error_message = false;
      this.success = false;
      this.login({
        username: this.username,
        password: this.password
      }).then(
        (user) => {
          this.$http
            .get("graphql/", {
              params: {
                query: `{settings{
          globalConfiguration
        }}`
              }
            })
            .then(
              (res) => {
                const next = this.$route.query.next;
                if (next) {
                  this.$router.push(next); // Redireciona para a URL do parâmetro 'next'
                } else if (
                  res.data.data.settings.length > 0 &&
                  res.data.data.settings[0].globalConfiguration
                ) {
                  setTimeout(() => {
                    this.success = true;
                    setTimeout(() => {
                      this.$router.push({ name: "new-map" });
                    });
                  });
                } else {
                  setTimeout(() => {
                    this.success = true;
                    setTimeout(() => {
                      this.$router.push({ name: "map" });
                    }, 2000);
                  }, 2500);
                }
              },
              (error) => {
                setTimeout(() => {
                  this.submitting = false;
                  this.error_message = this.$t("Incorrect Username/Password");
                }, 5000);
              }
            );
        },
        (error) => {
          setTimeout(() => {
            this.submitting = false;
            this.error_message = this.$t("Incorrect Username/Password");
          }, 5000);
        }
      );
    }
  }
};
</script>
