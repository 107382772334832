var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DynamicTable',{class:['root', { expand: _vm.enableExpand }],attrs:{"items":_vm.items,"headers":_vm.headers,"loading-text":_vm.$t('Loading Help Tickets... Please wait'),"dynamic-slots":['id', 'total' ].concat( _vm.dynamicSlots),"disable-pagination":true,"hide-default-footer":true},scopedSlots:_vm._u([(_vm.enableExpand)?{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item && item.id === _vm.ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID)?_c('div',[_c('Btn',{attrs:{"icon":"","ghost":"","small":"","transparent":""},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_c('div')]}}:null,{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',[(item)?_c('ZoneControlWeeklyTableCell',{attrs:{"actual":item.total_actual,"target":item.total_target,"shift-name":item.id === _vm.ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID
            ? _vm.$t('TOTAL')
            : (_vm.shifts[item.id] && _vm.shifts[item.id].name) || _vm.$t('UNKNOWN'),"is-total":item.id === _vm.ZONE_CONTROL_WEEKLY_TOTAL_SHIFT_ID}}):_vm._e()],1)]}},_vm._l((_vm.dynamicSlots),function(slotName){return {key:("item." + slotName),fn:function(ref){
            var item = ref.item;
return [(item && item.days[slotName])?_c('ZoneControlWeeklyTableCell',{key:slotName,attrs:{"actual":item.days[slotName].actual,"target":item.days[slotName].target}}):_vm._e()]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }