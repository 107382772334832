<template>
  <div class="analytics">
    <DevelopmentFeature>
      <template v-slot:edge>
        <router-link
          v-for="(card, idx) in cards"
          :key="idx"
          :to="card.link"
          style="text-decoration: none; color: inherit"
        >
          <v-card
            elevation="2"
            class="card analytics_card"
          >
            <div class="logo">
              <i :class="card.icon_class" />
            </div>
            <div class="info">{{ card.text }}</div>
          </v-card>
        </router-link>
      </template>
      <template v-slot:stable>
        <router-link
          v-for="(card, idx) in cards.filter((c) => !c.developer)"
          :key="idx"
          :to="card.link"
          style="text-decoration: none; color: inherit"
        >
          <v-card
            elevation="2"
            class="card analytics_card"
          >
            <div class="logo">
              <i :class="card.icon_class" />
            </div>
            <div class="info">{{ card.text }}</div>
          </v-card>
        </router-link>
      </template>
    </DevelopmentFeature>
  </div>
</template>
<style lang="scss">
@import "../scss/variables";

.analytics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  gap: 1rem 1rem;
  padding: 0.5rem 1rem;
  padding-bottom: 0rem;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .analytics_card {
    height: 150px;
    padding: 20px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    .logo {
      background: #10aaf2;
      padding: 10px;
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      border-radius: 100% !important;
    }
    .info {
      flex: 1;
      padding: 10px;
      display: flex;
      background: none !important;
    }
  }
}
</style>
<script>
import { mapActions } from "vuex";

import DevelopmentFeature from "@/components/DevelopmentFeature";

export default {
  data() {
    return {
      cards: [
        {
          link: "/analytics/operator/realtime",
          icon_class: "fa fa-user",
          text: this.$t("Operator Performance Analytics"),
          developer: false
        },
        {
          link: "/analytics/production_report",
          icon_class: "fa fa-file-text-o",
          text: this.$t("Production Reports"),
          developer: false
        },
        {
          link: "/analytics/iot",
          icon_class: "fa fa-cloud",
          text: this.$t("IOT Analytics"),
          developer: false
        },
        {
          link: "/analytics/cycles",
          icon_class: "mdi mdi-clock-fast",
          text: this.$t("Cycle Analysis"),
          developer: false
        },
        {
          link: "/analytics/tla_report",
          icon_class: "mdi mdi-trending-down",
          text: this.$t("Total Loss Analysis"),
          developer: false
        },
        {
          link: "/analytics/ldms-analytics",
          icon_class: "fa fa-ticket",
          text: this.$t("LDMS Analytics"),
          developer: false
        },
        {
          link: "/analytics/level2",
          icon_class: "fa fa-bell",
          text: this.$t("Machine Alarms and Event History"),
          developer: false
        },
        {
          link: "/analytics/p911/p911-analytics",
          icon_class: "fa fa-ticket",
          text: this.$t("P911 Analytics"),
          developer: false
        },
        {
          link: "/analytics/aivision/aivision-analytics",
          icon_class: "fa fa-eye",
          text: this.$t("AI Vision"),
          developer: true
        },
        {
          link: "/analytics/scrap/scrap-analytics",
          icon_class: "fa fa-trash",
          text: this.$t("Scrap Analytics"),
          developer: true
        }
      ]
    };
  },
  components: {
    DevelopmentFeature
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    })
  },
  mounted() {
    this.setTitles({
      title: this.$t("Analytics"),
      mobile: this.$t("Analytics")
    });
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  }
};
</script>
