<template>
  <div class="media-viewer">
    <div class="media-card">
      <div class="header py-5 px-6">
        <DialogClose :onClick="close" />

        <h6 class="text-h6 color--text-primary">{{ media.name }}</h6>
        <div class="options"></div>
      </div>
      <div class="body pt-3 pb-6 px-6">
        <p class="description text-body-2 color--text-secondary mb-3">{{ media.description }}</p>
        <a
          v-if="media.latestVersion.image"
          :href="media.latestVersion.fileUrl"
          target="_blank"
        >
          <img :src="media.latestVersion.fileUrl" />
        </a>
        <iframe
          v-if="media.latestVersion.pdf"
          :src="googleDocsViewerUrl"
        ></iframe>
        <video-player
          v-if="media.latestVersion.video"
          :file="media.latestVersion.fileUrl"
        />
        <div></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/variables";

.media-viewer {
  background-color: var(--bg-secondary);

  img {
    max-width: 100%;
  }
  iframe {
    width: calc(100%);
    min-height: 300px;
    height: calc(75vh);
  }
}

.media-card {
  background-color: var(--bg-secondary);
  max-height: 95vh;
  overflow: visible;
  position: relative;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  border-bottom: 1px solid var(--border-secondary);
}

.body {
  background-color: var(--bg-secondary);
}
</style>

<script>
import { mapActions } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import VideoPlayer from "@/components/VideoPlayer";
import DialogClose from "../ui/DialogClose/DialogClose.vue";

export default {
  props: ["media"],
  data() {
    return {};
  },
  components: {
    DialogClose,
    VideoPlayer,
    DialogCloseButton
  },
  created() {},
  computed: {
    googleDocsViewerUrl() {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(this.media.latestVersion.fileUrl)}&embedded=true`;
    }
  },
  methods: {
    ...mapActions({
      setRightPanel: "components/SetRightPanel"
    }),
    close() {
      this.$emit("close");
    }
  }
};
</script>
