<template>
  <div class="tableDiv">
    <h2 class="card-title">{{ $t("Runtime") }}</h2>
    <generic-table
      :columns="columns"
      :nested_columns="nestedColumns"
      :footer="footer"
      :data="tableData"
    />
  </div>
</template>
<script>
import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { seconds } from "@/utils/filters";

export default {
  name: "RuntimeQuantityTable",
  props: ["chartOptions", "production"],
  components: {
    GenericTable
  },
  data() {
    return {
      tableData: [],
      totalTime: 0,
      runtime: 0
    };
  },
  created() {
    this.formatData();
  },
  computed: {
    columns() {
      return [
        { title: this.$t("Time"), key: "time" },
        { title: this.$t("Part Number"), key: "partNumber" },
        { title: this.$t("Total Time"), key: "totalTime" },
        { title: this.$t("Runtime"), key: "runtime" }
      ];
    },
    nestedColumns() {
      return [{ key: "" }, { key: "partNumber" }, { key: "totalTime" }, { key: "runtime" }];
    },
    footer() {
      return [
        { type: "csv", csv: this.downloadCsv },
        { type: "text", text: this.$t("Total") },
        { type: "text", text: seconds(this.totalTime) },
        { type: "text", text: seconds(this.runtime) }
      ];
    }
  },
  methods: {
    downloadCsv() {
      const rows = [];
      rows.push([
        this.$t("Time"),
        this.$t("Part Number"),
        this.$t("Total Time"),
        this.$t("Runtime")
      ]);
      this.tableData.forEach((item) =>
        item._nested.length === 0
          ? rows.push([item.time, item.partNumber, item.totalTime, item.runtime])
          : item._nested.forEach((nestedItem) =>
              rows.push([
                item.time,
                nestedItem.partNumber,
                nestedItem.totalTime,
                nestedItem.runtime
              ])
            )
      );
      createCSVFromRows(rows, `runtime_${new Date().toISOString()}`);
    },
    renderPartsLabel(parts) {
      return parts.length > 1 ? `${parts[0]} +${parts.length - 1} more` : parts[0];
    },
    formatData() {
      const data = [];
      this.production.forEach((interval, intervalIndex) => {
        interval.by_machine.forEach((machine) => {
          machine.by_part.forEach((part) => {
            if (!data[intervalIndex]) {
              data[intervalIndex] = {
                time: this.chartOptions.labelFromString(interval.key),
                partNumber: part.part.part_number,
                partNumberUnformatted: part.part.part_number,
                totalTime: seconds(interval.interval_duration.value),
                timeUnformatted: interval.interval_duration.value,
                runtime: seconds(interval.runtime.duration.value),
                runtimeUnformatted: interval.runtime.duration.value,
                _nested: []
              };
            } else {
              data[intervalIndex]["timeUnformatted"] += interval.interval_duration.value;
              data[intervalIndex]["runtimeUnformatted"] += interval.runtime.duration.value;
              data[intervalIndex]["totalTime"] = seconds(data[intervalIndex]["timeUnformatted"]);
              data[intervalIndex]["runtime"] = seconds(data[intervalIndex]["runtimeUnformatted"]);
              data[intervalIndex]._nested.push({
                time: "",
                partNumber: part.part.part_number,
                totalTime: seconds(part.interval_duration),
                runtime: seconds(part.runtime.duration)
              });
            }
          });
        });
      });
      data.forEach((item) => {
        item.partNumber = this.renderPartsLabel(
          item._nested.map((nestedItem) => nestedItem.partNumber)
        );
      });
      this.tableData = data.filter((item) => item);
      this.totalTime = this.tableData.reduce(
        (acc, { timeUnformatted }) => acc + timeUnformatted,
        0
      );
      this.runtime = this.tableData.reduce(
        (acc, { runtimeUnformatted }) => acc + runtimeUnformatted,
        0
      );
    }
  }
};
</script>
<style lang="scss"></style>
