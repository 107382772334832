var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operator_tracking_leaderboard"},[(_vm.vw < 1000)?_c('v-tabs',{attrs:{"fixed-tab":"","centered":""},model:{value:(_vm.mobileTab),callback:function ($$v) {_vm.mobileTab=$$v},expression:"mobileTab"}},[_c('v-tab',{staticClass:"smalltab"},[_vm._v(_vm._s(_vm.$t("Leaderboard")))]),_vm._v(" "),_c('v-tab',{staticClass:"smalltab"},[_vm._v(_vm._s(_vm.$t("Reactions")))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"leaderboard-content"},[_c('div',{staticClass:"tableView",class:{ active: _vm.mobileTab == 0 }},[_c('v-card',{staticClass:"tableHeader"},[_c('div',{staticClass:"dd"},[_c('v-autocomplete',{attrs:{"items":_vm.years,"label":_vm.$t('Year'),"value":_vm.selectyear,"hide-details":""},on:{"change":function (value) {
                this$1.selectyear = value;
              }}})],1),_vm._v(" "),_c('div',{staticClass:"dd"},[_c('v-autocomplete',{attrs:{"items":_vm.months,"label":_vm.$t('Month'),"value":_vm.selectmonth,"hide-details":""},on:{"change":function (value) {
                this$1.selectmonth = value;
              }}})],1)]),_vm._v(" "),_c('div',{staticClass:"tableContainer"},[_c('v-data-table',{attrs:{"headers":_vm.table_headers,"items":_vm.sorted_items,"loading":_vm.loading,"hide-default-header":_vm.vw > 1000},scopedSlots:_vm._u([(_vm.vw > 1000)?{key:"header",fn:function(ref){
              var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((_vm.table_headers),function(head){return _c('th',[_c('div',{staticClass:"header_item",class:head.class,on:{"click":function () {
                        _vm.sortby(head);
                      }}},[_c('div',{staticClass:"rowcontent"},[_vm._v(_vm._s(head.text))]),_vm._v(" "),(head.sort == 'asc')?_c('i',{staticClass:"mdi mdi-arrow-up"}):(head.sort == 'desc')?_c('i',{staticClass:"mdi mdi-arrow-down"}):_c('i')])])}),0)])]}}:null,{key:"item.name",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"table_name"},[_c('div',{staticClass:"icon",style:(item.avatar
                    ? {
                        background: ("url(" + (item.avatar) + ")"),
                        'background-size': 'cover',
                        'background-position-x': 'center',
                        'background-position-y': 'center'
                      }
                    : null)},[(item.avatar == null)?_c('i',{staticClass:"fa fa-user"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.target_units",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.numberWithCommas(item.target_units.toFixed()))+"\n          ")]}},{key:"item.actual",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.numberWithCommas(item.actual.toFixed()))+"\n          ")]}},{key:"item.best_units_per_hour",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.numberWithCommas(item.best_units_per_hour))+"\n          ")]}},{key:"item.efficiency",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n            "+_vm._s(((item.efficiency) + "%"))+"\n          ")]}},{key:"item.reactions",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n            "+_vm._s(((item.reactions) + " Reactions"))+"\n          ")]}},{key:"item.reactions_node",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"reaction_icon_container"},_vm._l((_vm.reactionTypes),function(rt){return _c('div',{staticClass:"reaction_icon",style:({ background: ("url(" + (rt.image) + ")") }),on:{"click":function () {
                    _vm.AddReaction(rt, item.userId);
                  }}})}),0)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-card',{staticClass:"feedView",class:{ active: _vm.mobileTab == 1 }},[_c('div',{staticClass:"new-reactions"},[_c('transition-group',{attrs:{"name":"rain","tag":"ul","css":false},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"before-leave":_vm.beforeLeave,"leave":_vm.leave}},_vm._l((_vm.newReactions),function(reaction,index){return _c('div',{key:String(reaction.index),staticClass:"new-reaction",attrs:{"data-index":reaction.delay}},[_c('img',{attrs:{"src":reaction.reaction.image}})])}),0)],1),_vm._v(" "),_c('div',{staticClass:"feedTitle"},[_c('h3',[_vm._v("Reactions")])]),_vm._v(" "),_c('div',{staticClass:"feed"},_vm._l((_vm.reactionFeed),function(reaction){return _c('div',[_c('div',{staticClass:"table_name"},[_c('div',{staticClass:"icon"},[(reaction.user.avatar)?_c('img',{staticClass:"user-image",attrs:{"src":reaction.user.avatar}}):_vm._e(),_vm._v(" "),(!reaction.user.avatar)?_c('div',{staticClass:"default-user"},[_c('i',{staticClass:"fa fa-user"})]):_vm._e(),_vm._v(" "),_c('img',{staticClass:"tinyicon",attrs:{"src":reaction.reaction.image}})]),_vm._v(" "),_c('div',{staticClass:"name name_feed"},[_c('div',[_vm._v(_vm._s(reaction.fromUser ? reaction.fromUser.username : "---"))]),_vm._v(" "),_c('div',[_vm._v("for "+_vm._s(reaction.user.username))])])])])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }