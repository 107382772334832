<template>
  <div class="new-audit">
    <div class="card">
      <div class="card-header">
        <p></p>
        <DialogCloseButton :onClick="closeForm" />
      </div>
      <div class="card-content">
        <v-text-field
          v-model="newAudit.name"
          :rules="rules.name"
          :label="$t('Name')"
          counter="250"
          outlined
        ></v-text-field>
        <v-textarea
          outlined
          hide-details
          rows="3"
          name="input-7-4"
          :label="$t('Description')"
          class="description"
          v-model="newAudit.description"
        >
        </v-textarea>
        <div class="input-row">
          <v-checkbox
            v-model="newAudit.machineRequired"
            :label="$t('Machine Audit')"
            class="checkbox"
            :value="true"
            hide-details
          >
          </v-checkbox>
          <v-select
            v-model="newAudit.machines"
            v-if="newAudit.machineRequired"
            :items="machines"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('Select Machines')"
            item-text="name"
            item-value="pk"
            multiple
            outlined
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="toggle"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ selectAllIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("Select All") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
          <v-checkbox
            v-model="createFindings"
            :label="$t('Create Tickets for Bad Responses')"
            class="checkbox"
            :value="true"
            hide-details
          >
          </v-checkbox>
          <v-select
            v-model="newAudit.issueType"
            v-if="createFindings"
            :items="issueTypes"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('Select an Issue Type')"
            item-text="name"
            item-value="pk"
            outlined
          >
          </v-select>
          <v-select
            v-model="newAudit.notificationGroup"
            v-if="createFindings"
            :items="notificationGroups"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('Select an Notification Group')"
            item-text="name"
            item-value="pk"
            outlined
          >
          </v-select>
        </div>
      </div>
      <v-btn
        color="primary"
        class="createBtn"
        v-if="!edit"
        @click="createAudit"
        :loading="savingAudit"
      >
        {{ $t("Create Audit") }}
      </v-btn>
      <div class="card-action">
        <v-btn
          color="primary"
          class="createBtn"
          v-if="edit"
          @click="updateAudit"
          :loading="savingAudit"
        >
          {{ $t("Update Audit") }}
        </v-btn>
        <p>* {{ $t("These changes are not tracked") }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.new-audit {
  margin: 1rem;
  .card {
    padding: 1rem;
    .description {
      margin-bottom: 1rem;
    }
    .checkbox {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
    .createBtn {
      margin-top: 1rem;
    }
    .card-action {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .v-btn {
        margin-top: 0;
      }
      p {
        margin: 0.5rem 1rem;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";

export default {
  props: {
    edit: {
      default: false
    },
    auditType: {
      default: false
    }
  },
  data() {
    return {
      newAudit: {
        name: "",
        currentVersion: 1,
        description: "",
        machineRequired: false,
        machineGroupRequired: false,
        machines: [],
        machineGroups: [],
        issueType: null,
        notificationGroup: null
      },
      rules: {
        name: [(v) => v.length <= 250 || "Max 250 characters"]
      },
      createFindings: false,
      machines: [],
      issueTypes: [],
      notificationGroups: [],
      savingAudit: false,
      errors: false
    };
  },
  created() {
    if (this.auditType) {
      this.newAudit = this.auditType;
      this.newAudit.machines = this.auditType.machines.map((machine) => {
        return machine.pk;
      });
      if (this.auditType.issueType) {
        this.createFindings = true;
        this.newAudit.issueType = this.auditType.issueType.pk;
      }
    }
    this.loadMachines();
  },
  components: {
    DialogCloseButton
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    selectAllIcon() {
      if (this.machines.length === this.newAudit.machines.length) return "mdi-close-box";
      else if (this.newAudit.machines.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    moment,
    ...mapActions({}),
    closeForm() {
      this.$router.push({ name: "audit-types" });
    },
    loadMachines() {
      const query = `query{
        machines{
          pk,
          name,
        }
        issueTypes{
          name,
          pk,
          id
        },
        notificationGroups{
          pk
          id,
          name,
          users{
            id,
            fullName
          }
        }
      }`;
      let variables = { auditData: _.clone(this.newAudit) };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        this.machines = res.data.data.machines;
        this.issueTypes = res.data.data.issueTypes;
        this.notificationGroups = res.data.data.notificationGroups;
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.machines.length === this.newAudit.machines.length) {
          this.newAudit.machines = [];
        } else {
          this.newAudit.machines = this.machines.map((m) => m.pk);
        }
      });
    },
    close() {
      this.$emit("close");
    },
    createAudit() {
      this.errors = false;
      this.savingAudit = true;
      const query = `mutation ($auditData: AuditTypeMutationInput!){
        createAudit(input: $auditData) {
          auditType{
            id
          }
        }
      }`;
      if (!this.createFindings) {
        this.newAudit.issueType = null;
      }
      let variables = { auditData: _.clone(this.newAudit) };
      variables["auditData"]["clientMutationId"] = "auditCreate";
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.savingAudit = false;
            context.$router.push({
              name: "new-audit-revision",
              params: { auditTypeId: res.data.data.createAudit.auditType.id }
            });
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.savingAudit = false;
        });
    },
    updateAudit() {
      this.errors = false;
      this.savingAudit = true;
      const query = `mutation ($auditData: AuditTypeMutationInput!){
        createAudit(input: $auditData) {
          auditType{
            id
          }
        }
      }`;
      if (!this.createFindings) {
        this.newAudit.issueType = null;
      }
      delete this.newAudit.latestVersion;
      let variables = { auditData: _.clone(this.newAudit) };
      variables["auditData"]["clientMutationId"] = "auditUpdate";
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.savingAudit = false;
            context.close();
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.savingAudit = false;
        });
    }
  },
  watch: {}
};
</script>
