<template>
  <v-form class="d-flex flex-column align-center justify-center">
    <v-container>
      <v-autocomplete
        :search-input.sync="search"
        :items="autoCompleteChoices"
        item-text="name"
        item-value="name"
        :loading="isLoading"
        :disabled="isLoading"
        :active="true"
        :autofocus="true"
        :label="$t('Start by searching for a model')"
        :placeholder="$t('Search')"
        menu-icon=""
        class="autocomplete justify-center"
      >
        <template v-slot:selection="item">
          {{ item.item.name }}
        </template>
        <template
          v-slot:item="item"
          @click="redirectToAboutPage(item.item.id)"
        >
          <router-link :to="`/lighthouse/optimizationModels/${item.item.id}`">
            {{ item.item.name }}
          </router-link>
        </template>
      </v-autocomplete>
    </v-container>
  </v-form>
</template>

<script>
import { fetchAutoCompleteChoices } from "@lighthouse/shared/api/autocomplete";

export default {
  name: "LightHouseSearchForm",
  data() {
    return {
      isLoading: true,
      search: "",
      autoCompleteChoices: []
    };
  },
  watch: {
    search: function () {
      if (this.search && this.search.length >= 3) {
        this.isLoading = true;
        fetchAutoCompleteChoices(this.$http, this.search).then(
          (data) => (this.autoCompleteChoices = data.results)
        );
      } else {
        this.autoCompleteChoices = [];
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped lang="scss">
autocomplete > label {
  color: grey;
}
</style>
