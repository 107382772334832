<template>
  <div class="card issue-table">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("LDMS Issues") }}</span>
    </div>
    <v-data-table
      item-key="id"
      :headers="issueTableHeaders"
      :items="issueData"
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "LDMSIssueTable",
  props: {
    issueData: { type: Array, default: () => [] }
  },
  data() {
    return {
      issueTableHeaders: [
        { text: this.$t("Date"), value: "issueStartDate" },
        { text: this.$t("Issue"), value: "reason" },
        { text: this.$t("CM"), value: "countermeasures" },
        { text: this.$t("Root Cause"), value: "cause" },
        { text: this.$t("LT Solution"), value: "solution" }
      ]
    };
  }
};
</script>
<style lang="scss"></style>
