<template>
  <div class="zone-control-summary">
    <div class="table-section">
      <div class="table-btn-container d-flex align-center mb-4">
        <BtnToggle
          :value="tab"
          class="d-inline-block"
        >
          <v-btn @click="tab = 0">
            {{ $t("By machines") }}
          </v-btn>

          <v-btn @click="tab = 1">
            {{ $t("By issues") }}
          </v-btn>
        </BtnToggle>

        <v-text-field
          v-model="searchText"
          prepend-inner-icon="mdi-magnify"
          :label="$t('Search')"
          class="text-search py-0 ma-0 color--text-secondary text-subtitle-1"
          single-line
          hide-details
          clearable
        />
      </div>

      <div class="table-content">
        <v-data-table
          v-if="tab === 0"
          :headers="orderByMachinesHeaders"
          :items="filteredIssuesByMachine"
          :loading="loading"
          item-key="machineOrGroupName"
          show-expand
          fixed-header
          fixed-footer
        >
          <template #[`item.data-table-expand`]="{ isExpanded, expand }">
            <v-icon @click="expand(!isExpanded)">
              {{ isExpanded ? icons.mdiMenuUp : icons.mdiMenuDown }}
            </v-icon>
          </template>
          <template #[`item.machineOrGroupName`]="{ item }">
            <div class="machine-name">
              <Btn
                icon
                class="mr-2 color--primary"
              >
                <v-icon
                  size="24"
                  @click="handleMachineIconClick(item)"
                >
                  mdi-chart-line
                </v-icon>
              </Btn>
              <span class="text-body-2">{{ item.machineOrGroupName }}</span>
            </div>
          </template>
          <template #[`item.reason`]>
            <span class="reason test-body-2 font-weight-medium text-uppercase">{{
              $t("Total")
            }}</span>
          </template>
          <template #[`item.issueTotal`]="{ item }">
            <div class="total">
              <Btn
                icon
                class="mr-2 color--primary"
              >
                <v-icon
                  size="24"
                  @click="handleIssuesPerIntervalIconClick(item, false, item.key, null)"
                >
                  mdi-chart-line
                </v-icon>
              </Btn>
              <span class="total-text text-body-2 font-weight-medium">{{ item.issueTotal }}</span>
            </div>
          </template>
          <template #[`item.closedTotal`]="{ item }">
            <div class="closed">
              <Btn
                icon
                class="mr-2 color--primary"
              >
                <v-icon
                  size="24"
                  @click="handleIssuesPerIntervalIconClick(item, true, item.key, null)"
                >
                  mdi-chart-line
                </v-icon>
              </Btn>
              <span class="closed-text text-body-2 font-weight-medium">{{ item.closedTotal }}</span>
            </div>
          </template>
          <template #[`expanded-item`]="{ item }">
            <td></td>
            <td></td>
            <td>
              <ul class="expanded">
                <li
                  v-for="(reason, reasonIndex) in item.reasons.buckets"
                  :key="reasonIndex"
                  class="reason"
                >
                  <TextOverflowTooltip
                    :text="reason.issueName"
                    :text-length="44"
                  />
                </li>
              </ul>
            </td>
            <td class="expanded">
              <div>
                <div
                  v-for="(reason, reasonIndex) in item.reasons.buckets"
                  :key="reasonIndex + 'total'"
                  class="total"
                >
                  <Btn
                    icon
                    class="mr-2 color--primary"
                  >
                    <v-icon
                      size="24"
                      @click="handleIssuesPerIntervalIconClick(item, false, item.key, reason.key)"
                    >
                      mdi-chart-line
                    </v-icon>
                  </Btn>
                  <span class="text-body-2 font-weight-medium">{{ reason.doc_count }}</span>
                </div>
              </div>
            </td>
            <td class="expanded">
              <div>
                <div
                  v-for="(reason, reasonIndex) in item.reasons.buckets"
                  :key="reasonIndex + 'closed'"
                  class="closed"
                >
                  <Btn
                    icon
                    class="mr-2 color--primary"
                  >
                    <v-icon
                      size="24"
                      @click="handleIssuesPerIntervalIconClick(item, true, item.key, reason.key)"
                    >
                      mdi-chart-line
                    </v-icon>
                  </Btn>
                  <span>{{ reason.closed.doc_count }}</span>
                </div>
              </div>
            </td>
          </template>
        </v-data-table>

        <v-data-table
          v-if="tab === 1"
          :headers="orderByIssuesHeaders"
          :items="filteredMachinesByIssue"
          item-key="key"
          show-expand
          fixed-header
        >
          <template #[`item.data-table-expand`]="{ isExpanded, expand }">
            <v-icon @click="expand(!isExpanded)">
              {{ isExpanded ? icons.mdiMenuUp : icons.mdiMenuDown }}
            </v-icon>
          </template>
          <template #[`item.issueName`]="{ item }">
            <div class="issue-name">
              <Btn
                icon
                class="mr-2 color--primary"
              >
                <v-icon
                  size="24"
                  @click="handleIssueIconClick(item)"
                >
                  mdi-chart-line
                </v-icon>
              </Btn>
              <p>{{ item.issueName }}</p>
            </div>
          </template>
          <template #[`item.relation`]>
            <span class="machine text-body-2 font-weight-medium">{{ $t("Total") }}</span>
          </template>
          <template #[`item.machineTotal`]="{ item }">
            <div class="total">
              <!-- Order by Issues / Bold Total -->
              <Btn
                icon
                class="mr-2 color--primary"
              >
                <v-icon
                  size="24"
                  @click="handleIssuesPerIntervalIconClick(item, false, null, item.key)"
                >
                  mdi-chart-line
                </v-icon>
              </Btn>
              <span class="total-text text-body-2 font-weight-medium">{{ item.machineTotal }}</span>
            </div>
          </template>
          <template #[`item.closedTotal`]="{ item }">
            <div class="closed">
              <Btn
                icon
                class="mr-2 color--primary"
              >
                <v-icon
                  size="24"
                  @click="handleIssuesPerIntervalIconClick(item, true, null, item.key)"
                >
                  mdi-chart-line
                </v-icon>
              </Btn>
              <span class="closed-text text-body-2 font-weight-medium">{{ item.closedTotal }}</span>
            </div>
          </template>
          <template #[`expanded-item`]="{ item }">
            <td></td>
            <td></td>
            <td>
              <ul class="expanded">
                <li
                  v-for="(machine, machineIndex) in item.machines.buckets"
                  :key="machineIndex + 'machine'"
                  class="machine"
                >
                  {{ machine.machineName }}
                </li>
              </ul>
            </td>
            <td class="expanded">
              <div
                v-for="(machine, machineIndex) in item.machines.buckets"
                :key="machineIndex + 'total'"
                class="total"
              >
                <!-- Order by Issues / Normal Total -->
                <Btn
                  icon
                  class="mr-2 color--primary"
                >
                  <v-icon
                    size="24"
                    @click="handleIssuesPerIntervalIconClick(item, false, machine.key, item.key)"
                  >
                    mdi-chart-line
                  </v-icon>
                </Btn>
                <span>{{ machine.doc_count }}</span>
              </div>
            </td>
            <td class="expanded">
              <div
                v-for="(machine, machineIndex) in item.machines.buckets"
                :key="machineIndex + 'closed'"
                class="closed"
              >
                <Btn
                  icon
                  class="mr-2 color--primary"
                >
                  <v-icon
                    size="24"
                    @click="handleIssuesPerIntervalIconClick(item, true, machine.key, item.key)"
                  >
                    mdi-chart-line
                  </v-icon>
                </Btn>
                <span>{{ machine.closed.doc_count }}</span>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="chart-section flex-grow-1 d-flex flex-column">
      <div class="chart-header text-right mb-4">
        <Btn
          large
          :disabled="!hasCharts"
          @click="handleRemoveCharts"
        >
          {{ $t("Delete all") }}
        </Btn>
      </div>
      <div
        v-if="hasCharts"
        class="chart-container px-6 pt-5 pb-4"
      >
        <div
          v-for="(group, groupInex) in groupCharts"
          :key="groupInex"
          class="chart-group"
        >
          <h2 class="chart-group-title text-h6 color--text-primary mb-5">{{ group.name }}</h2>
          <div class="charts">
            <div
              v-if="group.pieChart"
              class="chart-pie chart-item"
            >
              <PieChart
                :chart-data="group.pieChart"
                :chart-options="pieChartOptions"
                :height="250"
                class="chart-type"
              />
            </div>
            <Fragment v-if="group.lineChartData">
              <div
                v-for="(data, linearIndex) in group.lineChartData"
                :key="linearIndex"
                class="lineContainer chart-item"
              >
                <div class="chart-title text-body-2 mb-4 color--text-primary">
                  {{ data.title }}
                </div>
                <LineChart
                  :chart-data="data"
                  :chart-options="lineChartOptions"
                  :height="250"
                  :decimal="0"
                  class="chart-type"
                />
              </div>
            </Fragment>
            <Fragment v-if="group.barChartData">
              <div
                v-for="(data, barIndex) in group.barChartData"
                :key="barIndex"
                class="barContainer chart-item"
              >
                <div class="chart-title text-body-2 mb-4 color--text-primary">
                  {{ data.title }}
                </div>
                <BarChart
                  :chart-data="data"
                  :chart-options="barChartOptions"
                  :height="250"
                  :decimal="0"
                  class="chart-type"
                />
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { Fragment } from "vue-fragment";

import Btn from "@/ui/Btn";
import BtnToggle from "@/components/BtnToggle.vue";
import PieChart from "@/components/charts/PieChart";

import BarChart from "@/ui/charts/old/BarChart";
import LineChart from "@/ui/charts/old/LineChart";
import TextOverflowTooltip from "@/ui/ToolTip";

export default {
  components: {
    Btn,
    BtnToggle,
    LineChart,
    BarChart,
    PieChart,
    Fragment,
    TextOverflowTooltip
  },
  props: {
    enlargedTable: { type: Object, default: () => {} },
    reasons: { type: Array, default: () => [] },
    machineGroupPk: { type: Number, default: undefined },
    machines: { type: Array, default: () => [] },
    selectedInterval: { type: String, default: "" },
    setPresetDates: { type: Function, default: () => null },
    setInterval: { type: Function, default: () => null }
  },
  data() {
    return {
      searchText: "",
      loading: false,
      tab: 0,
      issuesByMachine: [],
      machinesByIssue: [],
      issuesPerInterval: [],
      orderByMachinesHeaders: [
        { text: "", value: "data-table-expand", cellClass: "first-expand-icon" },
        {
          text: this.$t("Machine"),
          value: "machineOrGroupName",
          sortable: true,
          width: "30%"
        },
        {
          text: this.$t("Issues"),
          value: "reason",
          sortable: false,
          cellClass: "reason-text",
          width: "34%"
        },
        {
          text: this.$t("Total"),
          value: "issueTotal",
          sortable: true,
          cellClass: "total-text",
          width: "18%"
        },
        {
          text: this.$t("Closed"),
          value: "closedTotal",
          sortable: true,
          cellClass: "closed-text",
          width: "18%"
        }
      ],
      orderByIssuesHeaders: [
        { text: "", value: "data-table-expand", cellClass: "first-expand-icon" },
        {
          text: this.$t("Issues"),
          value: "issueName",
          sortable: true,
          cellClass: "reason-text",
          width: "25%"
        },
        { text: this.$t("Machine"), value: "relation", sortable: false, width: "25%" },
        {
          text: this.$t("Total"),
          value: "machineTotal",
          sortable: true,
          cellClass: "total-text",
          width: "30%"
        },
        {
          text: this.$t("Closed"),
          value: "closedTotal",
          sortable: true,
          cellClass: "closed-text",
          width: "30%"
        }
      ],
      groupCharts: [],
      icons: {
        mdiMenuUp: "mdi-menu-up",
        mdiMenuDown: "mdi-menu-down"
      }
    };
  },
  computed: {
    filteredIssuesByMachine() {
      if (this.searchText && this.issuesByMachine.length > 0) {
        return this.issuesByMachine.filter((machine) =>
          machine.machineOrGroupName.toUpperCase().includes(this.searchText.toUpperCase())
        );
      }
      return this.issuesByMachine;
    },
    filteredMachinesByIssue() {
      if (this.searchText && this.machinesByIssue.length > 0) {
        return this.machinesByIssue.filter((issue) =>
          issue.issueName.toUpperCase().includes(this.searchText.toUpperCase())
        );
      }
      return this.machinesByIssue;
    },
    reasonIds() {
      let reasonIds = [];
      this.reasons.forEach((reason) => {
        if (reason.node.pk && !(reason.node.pk in reasonIds)) {
          reasonIds.push(reason.node.pk.toString());
        }
      });
      return reasonIds;
    },
    pieChartOptions() {
      let defaults = {
        legend: {
          maxWidth: "150px"
        },
        layout: {
          padding: 16
        },
        responsive: false,
        maintainAspectRatio: true
      };
      return defaults;
    },
    barChartOptions() {
      let defaults = {};
      return defaults;
    },
    lineChartOptions() {
      let defaults = {};
      return defaults;
    },
    hasCharts() {
      return this.groupCharts.length > 0;
    }
  },
  watch: {
    tab() {
      this.handleRemoveCharts();
      this.setInterval("card");
    },
    selectedInterval() {
      this.groupCharts = [];
      this.groupCharts.forEach((group) => {
        group.lineChartData.forEach((chart) => {
          this.updateChart(this.selectedInterval, chart);
        });
        group.barChartData.forEach((chart) => {
          this.updateChart(this.selectedInterval, chart);
        });
      });
    }
  },
  created() {
    if (this.enlargedTable.dates.start) {
      this.setPresetDates(
        moment(this.enlargedTable.dates.start).format("D MMM"),
        moment(this.enlargedTable.dates.end).format("D MMM, YYYY")
      );
    } else {
      this.setPresetDates(
        this.$t("Before"),
        moment(this.enlargedTable.dates.end).format("D MMM, YYYY")
      );
    }
    this.getIssues();
    // determine which card and set preset accordingly
  },
  beforeDestroy() {
    this.handleRemoveCharts();
  },
  methods: {
    getIssues(fromDate = null, toDate = null) {
      const params = {
        reason_ids: this.reasonIds,
        machine_group_id: this.machineGroupPk,
        from_date: fromDate
          ? fromDate.toISOString()
          : this.enlargedTable.dates.start
            ? this.enlargedTable.dates.start.toISOString()
            : "",
        to_date: toDate ? toDate.toISOString() : this.enlargedTable.dates.end.toISOString()
      };
      this.loading = true;
      this.$http.get("exceptions/issues_by_machine/", { params }).then((response) => {
        this.issuesByMachine = response.data;
        this.issuesByMachine.forEach((item) => {
          item.issueTotal = 0;
          item.closedTotal = 0;
          const foundMachine = this.machines.find((machine) => machine.pk.toString() === item.key);
          item.machineOrGroupName = foundMachine && foundMachine.name ? foundMachine.name : "";
          item.reasons.buckets.forEach((type) => {
            const foundReason = this.reasons.find(
              (reason) => reason.node.pk.toString() === type.key
            );
            type.issueName =
              foundReason && foundReason.node.text ? foundReason.node.text : this.$t("Other");
            item.issueTotal += type.doc_count;
            item.closedTotal += type.closed.doc_count;
          });
        });
        this.loading = false;
      });
      this.getMachinesByIssue(params);
    },
    getMachinesByIssue(params) {
      this.loading = true;
      this.$http.get("exceptions/machines_per_issue_type/", { params }).then((response) => {
        this.machinesByIssue = response.data;
        this.machinesByIssue.forEach((item) => {
          item.machineTotal = 0;
          item.closedTotal = 0;
          const foundIssue = this.reasons.find((reason) => reason.node.pk.toString() === item.key);
          item.issueName =
            foundIssue && foundIssue.node.text ? foundIssue.node.text : this.$t("Other") + " ";
          item.machines.buckets.forEach((bucket) => {
            const foundMachine = this.machines.find(
              (machine) => machine.pk.toString() === bucket.key
            );
            bucket.machineName = foundMachine && foundMachine.name ? foundMachine.name : "";
            item.machineTotal += bucket.doc_count;
            item.closedTotal += bucket.closed.doc_count;
          });
        });
        this.loading = false;
      });
    },
    async getIssuesPerInterval(chartData) {
      const params = {
        from_date: chartData.from_date.toISOString(),
        to_date: chartData.to_date.toISOString(),
        interval: chartData.scale,
        machine_id: chartData.machine_id,
        closed: chartData.closed ? true : undefined
      };
      if (chartData.reason_id) {
        params["reason_id"] = chartData.reason_id;
      } else {
        params["reason_ids"] = this.reasonIds;
      }
      this.loading = true;
      const response = await this.$http.get("exceptions/issues_per_interval/", { params });
      chartData.response = response.data;
      this.loading = false;
    },
    orderCharts(item) {
      let groupNameField = this.tab == 0 ? "machineOrGroupName" : "issueName";
      let itemIndex = this.groupCharts.findIndex((group) => group.name === item[groupNameField]);
      if (itemIndex) {
        const elm = this.groupCharts.splice(itemIndex, 1)[0];
        this.groupCharts.unshift(elm);
      }
    },
    handleMachineIconClick(item) {
      let group = this.groupCharts.find((group) => group.name === item.machineOrGroupName);
      if (!group) {
        group = {
          name: item.machineOrGroupName,
          pieChart: false,
          lineChartData: [],
          barChartData: []
        };
        this.groupCharts.unshift(group);
      }
      if (!group.pieChart) {
        const chartData = {};
        chartData.datasets = [{}];
        chartData.title = item.machineOrGroupName;
        chartData.labels = [];
        chartData.datasets[0].data = [];
        item.reasons.buckets.forEach((bucket) => {
          if (bucket.issueName.length > 20) {
            chartData.labels.push(bucket.issueName.substring(0, 20) + "...");
          } else {
            chartData.labels.push(bucket.issueName);
          }

          chartData.datasets[0].data.push(bucket.doc_count);
        });
        group["pieChart"] = chartData;
        this.showPieCharts = true;
      }
      this.orderCharts(item);
    },
    handleIssueIconClick(item) {
      let group = this.groupCharts.find((group) => group.name === item.issueName);
      if (!group) {
        group = {
          name: item.issueName,
          pieChart: false,
          lineChartData: [],
          barChartData: []
        };
        this.groupCharts.unshift(group);
      }
      if (!group.pieChart) {
        const chartData = {};
        chartData.datasets = [{}];
        chartData.title = item.issueName;
        chartData.labels = [];
        chartData.datasets[0].data = [];
        item.machines.buckets.forEach((bucket) => {
          chartData.labels.push(bucket.machineName);
          chartData.datasets[0].data.push(bucket.doc_count);
        });
        group["pieChart"] = chartData;
        this.showPieCharts = true;
      }
      this.orderCharts(item);
    },
    handleRemoveCharts() {
      this.groupCharts = [];
    },
    async handleIssuesPerIntervalIconClick(
      item,
      closed = false,
      machine_id = null,
      reason_id = null
    ) {
      let groupNameField = this.tab == 0 ? "machineOrGroupName" : "issueName";
      let group = this.groupCharts.find((group) => group.name === item[groupNameField]);
      if (!group) {
        group = {
          name: item[groupNameField],
          pieChart: false,
          lineChartData: [],
          barChartData: []
        };
        this.groupCharts.unshift(group);
      }
      let title = "";
      let chartType = closed ? "line" : "bar";
      let dataset = {
        data: [],
        label: this.$t("Issues")
      };

      if (closed) {
        dataset["pointBackgroundColor"] = "#AD84C6";
        dataset["backgroundColor"] = "transparent";
        dataset["borderColor"] = "#ae84c67d";
      } else {
        dataset["backgroundColor"] = "#784a92";
      }
      let chartData = {
        labels: [],
        datasets: [dataset],
        machine_id: machine_id,
        reason_id: reason_id,
        closed: closed
      };
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty("machineOrGroupName")) {
        if (reason_id !== null) {
          title += `${item.reasons.buckets.find((reason) => reason.key === reason_id).issueName} - `;
        }
        // eslint-disable-next-line no-prototype-builtins
      } else if (item.hasOwnProperty("issueName")) {
        if (machine_id !== null) {
          title += `${item.machines.buckets.find((machine) => machine.key === machine_id).machineName} - `;
        }
      }
      if (closed) {
        title += this.$t("Closed Issues");
      } else {
        title += this.$t("Total Issues");
      }
      this.setPreset(this.selectedInterval, chartData);
      await this.getIssuesPerInterval(chartData);
      chartData.response.forEach((issue) => {
        chartData.labels.push(this.getTimeLabel(issue.key_as_string, chartData.dateFormat));
        chartData.datasets[0].data.push(issue.doc_count);
      });
      chartData.title = title;
      this.addChart(chartType, chartData, group);
      this.orderCharts(item);
    },
    addChart(chartType, chartData, group) {
      if (
        group[`${chartType}ChartData`].filter((chart) => chart.title === chartData.title).length > 0
      ) {
        return;
      }
      group[`${chartType}ChartData`].push(chartData);
      group[`show${chartType.charAt(0).toUpperCase() + chartType.slice(1)}Charts`] = true;
    },
    setPreset(preset, chartData = null) {
      let from_date = moment();
      let to_date = moment();
      let dateFormat = "D MMM hhA";
      let scale = "1h";
      switch (preset) {
        case "card":
          from_date = this.enlargedTable.dates.start;
          to_date = this.enlargedTable.dates.end;
          dateFormat = "D MMM";
          scale = "1d";
          if (!from_date) {
            from_date = moment().endOf("month").subtract(12, "months");
            dateFormat = "MMM YY";
            scale = "1M";
          }
          break;
        case "shift":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "day":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "week":
          from_date = moment().startOf("week");
          to_date = moment().endOf("week");
          dateFormat = "D MMM";
          scale = "1d";
          break;
        case "month":
          from_date = moment().startOf("month");
          to_date = moment().endOf("month");
          dateFormat = "week";
          scale = "1w";
          break;
        case "year":
          from_date = moment().endOf("month").subtract(12, "months");
          to_date = moment().endOf("month");
          dateFormat = "MMM YY";
          scale = "1M";
          break;
      }
      if (chartData) {
        Object.assign(chartData, {
          scale,
          preset,
          dateFormat,
          from_date,
          to_date
        });
      }
      this.setPresetDates(
        moment(from_date).format(dateFormat === "week" ? "D MMM" : dateFormat),
        moment(to_date).format(dateFormat === "week" ? "D MMM, YYYY" : dateFormat)
      );
      this.getIssues(from_date, to_date);
    },
    async updateChart(preset, chartData) {
      this.setPreset(preset, chartData);
      await this.getIssuesPerInterval(chartData);
      chartData.labels = [];
      let dataset = {
        data: [],
        label: this.$t("Issues")
      };
      if (chartData.closed) {
        dataset["pointBackgroundColor"] = "#AD84C6";
        dataset["backgroundColor"] = "transparent";
        dataset["borderColor"] = "#ae84c67d";
      } else {
        dataset["backgroundColor"] = "#784a92";
      }
      chartData.datasets = [dataset];
      chartData.response.forEach((issue) => {
        chartData.labels.push(this.getTimeLabel(issue.key_as_string, chartData.dateFormat));
        chartData.datasets[0].data.push(issue.doc_count);
      });
    },
    getTimeLabel(timeString, dateFormat) {
      if (dateFormat == "week") {
        let start = moment(timeString);
        let end = moment(timeString).add(7, "d");
        if (start.month() != end.month()) {
          return start.format("D MMM") + " - " + end.format("D MMM");
        } else {
          return start.format("D") + " - " + end.format("D MMM");
        }
      } else {
        return moment(timeString).format(dateFormat);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "~@/scss/table.scss";
@import "~@/scss/mq.scss";

.zone-control-summary {
  --horizontal-space: 48px;
  --summary-table-width: 603px;

  display: flex;
  height: calc(100% - 1rem);
  gap: 24px var(--horizontal-space);

  display: grid;
  grid-template-columns: var(--summary-table-width) calc(
      100% - var(--summary-table-width) - var(--horizontal-space)
    );
}

.text-search {
  align-self: flex-end;

  ::v-deep {
    .v-icon,
    .v-label {
      color: var(--text-secondary) !important;
    }

    .v-input__slot:before {
      border-color: var(--border-primary) !important;
    }
  }
}

.table-btn-container {
  gap: 40px;
}

.total,
.issue-name,
.machine-name {
  display: flex;
  align-items: center;
}

.closed,
.machine,
.total,
.reason {
  height: 68px;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: left;
}

.zone-control-summary ::v-deep .v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none;
}

.v-data-table {
  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }

  ::v-deep td {
    height: 68px !important;
  }
}

.chart-section {
  flex-basis: 55%;
}

.chart-container {
  background-color: var(--bg-card);
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.charts {
  overflow: auto;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: -16px;
}

.chart-title {
  margin-bottom: 0.5rem;
}

.chart-item {
  position: relative;
  max-width: 100%;
  width: 50%;
  margin: 0;
  padding-top: 16px;
  padding-bottom: 0;
  padding-right: 24px;

  &:first-child:last-child {
    width: 100%;
  }
}

.chart-type {
  transform: translateX(-4px);
}

.chart-pie .chart-type {
  transform: translateX(0);
  overflow-x: auto;
}

.chart-group {
  & ~ .chart-group {
    margin-top: 24px;
  }
}

@include media_below(1025px) {
  .chart-item {
    width: 100%;
    padding-right: 0;
  }

  @media screen and (orientation: portrait) {
    .zone-control-summary {
      display: block;
    }
    .table-btn-container {
      justify-content: space-between;
    }
    .text-search {
      width: 303px;
    }

    .table-btn-container {
      justify-self: flex-start;
    }

    .table-section {
      margin-bottom: 16px;
    }
  }
}

@include media_below(825px) {
  @media screen and (orientation: portrait) {
    .chart-pie.chart-item {
      width: 100%;
    }
  }
}
</style>
