<template>
  <div
    class="pa-5 d-flex align-center mt-4 item"
    v-on="$listeners"
  >
    <div class="mr-2">
      <v-icon
        size="32"
        :icon="icon"
        >{{ icon }}</v-icon
      >
    </div>
    <div class="name">
      <p class="text-subtitle-1">{{ name }}</p>
      <p class="text-caption color--text-secondary">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  background: var(--bg-card-white);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: color-mix(in srgb, var(--bg-card-white), #fff 15%);
  }
}
</style>
