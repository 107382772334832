<template>
  <v-data-table
    fixed-header
    v-bind="$attrs"
    :disable-pagination="disablePagination"
    :hide-default-footer="hideDefaultFooter"
    :loading="isLoading"
    :items="items"
    :headers="headers"
    v-on="$listeners"
  >
    <template #default>
      <slot name="default"></slot>
    </template>
    <template
      v-for="slotName in dynamicSlots"
      #[`item.${slotName}`]="{ item }"
    >
      <slot
        :name="`item.${slotName}`"
        :item="item"
        >{{ item[slotName] }}</slot
      >
    </template>
    <template #no-data>
      <div class="no-data-wrapper">
        <p class="font-weight-bold">{{ $t("No data") }}</p>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "DynamicTable",
  props: {
    dynamicSlots: {
      type: Array,
      default: () => ["name"]
    },
    disablePagination: { type: Boolean, default: false },
    hideDefaultFooter: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false, required: false },
    headers: { type: Array, default: () => [], required: false },
    items: { type: Array, default: () => [], required: false }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables.scss";
@import "~@/scss/theme.scss";
@import "~@/scss/table.scss";
</style>
