<template>
  <div class="rejectTrendChart">
    <h4 class="rejectTrendTitle">
      {{ `${rejectTrend.machine_name}; ${rejectTrend.part_name}; ${rejectTrend.failure_mode}` }}
    </h4>
    <div
      ref="barchart"
      class="chart"
    ></div>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { mapGetters } from "vuex";

import { getDateDisplay } from "@/utils/datetime";

export default {
  name: "AiVisionRejectTrendChart",
  props: {
    rejectTrend: Object,
    filters: Object
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    })
  },
  mounted() {
    this.loadTrend();
  },
  methods: {
    getDateDisplay,
    loadTrend() {
      const filters = {
        asset_id: this.filters.asset && this.filters.asset.asset_id,
        machine_id: this.filters.machine && this.filters.machine.machine_id,
        part_id: this.filters.part && this.filters.part.part_id,
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        process_type: this.filters.process_types,
        inspection_result: this.filters.inspection_result,
        scale: this.filters.scale || "1d",
        ...this.rejectTrend
      };

      this.$http.get("/aivision/defectTrend/", { params: filters }).then((res) => {
        this.createChart(res.data);
      });
    },
    createChart(data) {
      const d = [];
      const scales = {
        "1h": "hour",
        "1d": "day",
        "1w": "week",
        "1M": "month",
        "1y": "year"
      };
      const scale = scales[this.filters.scale || "1d"];

      let index = 1;
      let minPercent = 0;
      let maxPercent = 0;

      for (const row of data.trend.buckets) {
        let total = 0;
        let failed = 0;
        let passed = 0;
        for (const inspection_result of row.inspection_result.buckets) {
          if (inspection_result.key.toLowerCase() === "pass") {
            passed = inspection_result.count.value;
          } else {
            failed += inspection_result.failure_mode.count.value;
          }

          total += inspection_result.count.value;
        }

        let percentFail = total > 0 ? ((failed / total) * 100).toFixed(2) : 0;
        minPercent = Math.min(percentFail, minPercent);
        maxPercent = Math.max(percentFail, maxPercent);

        d.push({
          time: this.getDateDisplay(row.key_as_string, scale),
          date: row.key_as_string,
          total: total,
          passed: passed,
          percentFail: total > 0 ? ((failed / total) * 100).toFixed(2) : 0,
          part_name: this.rejectTrend.part_name,
          part_id: this.rejectTrend.part_id,
          failure_mode: this.rejectTrend.failure_mode,
          machine_name: this.rejectTrend.machine_name,
          machine_id: this.rejectTrend.machine_id
        });
        index++;
      }

      if (this.root) {
        this.root.dispose();
      }

      this.root = am5.Root.new(this.$refs.barchart);
      this.chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          layout: this.root.verticalLayout
        })
      );

      this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}));

      if (this.theme === "dark") {
        this.root.interfaceColors.set("text", am5.color(0xffffff));
      }

      this.chart
        .get("colors")
        .set("colors", [
          am5.Color.fromString("#6E3738"),
          am5.Color.fromString("#cb3c3c"),
          am5.Color.fromString("#2f9ca4")
        ]);

      const xRenderer = am5xy.AxisRendererX.new(this.root, {
        minGridDistance: 0
      });

      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.percent(50),
        fontSize: 11
      });
      xRenderer.grid.template.set("strokeOpacity", 0);

      const xAxis = this.chart.xAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          categoryField: "time",
          oversizedBehavior: "truncate",
          renderer: xRenderer
        })
      );

      xAxis.data.setAll(d);
      const yRenderer = am5xy.AxisRendererY.new(this.root, {});
      yRenderer.labels.template.set("fontSize", 11);
      yRenderer.grid.template.set("strokeOpacity", 0);

      const yAxis = this.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          min: 0,
          renderer: yRenderer,
          numberFormat: "#a"
        })
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      const series0 = this.chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: `[fontSize: 12px]${this.$t("Total Processed")}[/]`,
          xAxis: xAxis,
          yAxis: yAxis,
          oversizedBehavior: "truncate",
          valueYField: "total",
          categoryXField: "time",
          clustered: false,
          cursorTooltipEnabled: true,
          tooltip: am5.Tooltip.new(this.root, {
            labelText: `${this.$t("Processed")}: {valueY}`,
            keepTargetHover: true
          })
        })
      );

      series0.columns.template.setAll({
        width: am5.percent(80),
        tooltipY: 0,
        strokeOpacity: 0
      });

      series0.data.setAll(d);

      const series1 = this.chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: `[fontSize: 12px]${this.$t("Passed")}[/]`,
          xAxis: xAxis,
          yAxis: yAxis,
          oversizedBehavior: "truncate",
          valueYField: "passed",
          categoryXField: "time",
          clustered: false,
          cursorTooltipEnabled: true,
          tooltip: am5.Tooltip.new(this.root, {
            labelText: `${this.$t("Passed")}: {valueY}`,
            keepTargetHover: true
          })
        })
      );

      series1.columns.template.setAll({
        width: am5.percent(50),
        tooltipY: 0,
        strokeOpacity: 0
      });
      series1.data.setAll(d);

      const yAxisPercentRenderer = am5xy.AxisRendererY.new(this.root, {
        opposite: true
      });
      yAxisPercentRenderer.labels.template.set("fontSize", 11);
      yAxisPercentRenderer.grid.template.set("strokeOpacity", 0);

      const yAxisPercent = this.chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          min: 0,
          max: 100,
          align: "right",
          numberFormat: "#'%'",
          renderer: yAxisPercentRenderer
        })
      );

      const series2Percent = this.chart.series.push(
        am5xy.LineSeries.new(this.root, {
          name: `[fontSize: 12px]${this.$t("Reject %")}[/]`,
          xAxis: xAxis,
          yAxis: yAxisPercent,
          valueYField: "percentFail",
          categoryXField: "time",
          cursorTooltipEnabled: true,
          seriesTooltipTarget: "bullet",
          tooltip: am5.Tooltip.new(this.root, {
            labelText: `${this.$t("Reject")}: {valueY}%`,
            keepTargetHover: true
          })
        })
      );

      series2Percent.bullets.push(() => {
        return am5.Bullet.new(this.root, {
          locationY: 0,
          sprite: am5.Circle.new(this.root, {
            radius: 2,
            stroke: series2Percent.get("fill"),
            strokeWidth: 1,
            fill: series2Percent.get("fill")
          })
        });
      });

      series2Percent.events.on("datavalidated", function () {
        yAxisPercent.setAll({
          min: minPercent,
          max: maxPercent,
          start: 0,
          end: 1
        });
      });
      series2Percent.data.setAll(d);

      const legend = this.chart.children.push(
        am5.Legend.new(this.root, {
          centerX: am5.percent(50),
          x: am5.percent(50)
        })
      );
      legend.data.setAll(this.chart.series.values);
    }
  },
  watch: {
    filters: function () {
      this.loadTrend();
    },
    rejectTrend: function () {
      this.loadTrend();
    }
  }
};
</script>

<style scoped lang="scss">
.rejectTrendChart {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;

  .rejectTrendTitle {
    flex: unset;
    padding: 10px;
  }

  .chart {
    flex: 1;
  }
}
</style>
