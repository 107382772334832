<template>
  <div
    class="card countermeasure"
    :class="{ 'only-chart': !showRootCausesChart }"
  >
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Countermeasure") }}</span>
    </div>
    <div class="analytics-card-header">
      <AnalyticsPagination :handleTabChange="handleTabChange" />
    </div>
    <div class="analytics-card-content">
      <HBarChart
        class="bar-chart"
        :chartData="countermeasuresBarChartData"
        :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
      />
    </div>
  </div>
</template>
<script>
import AnalyticsPagination from "@/components/AnalyticsPagination";
import HBarChart from "@/components/charts/HBarChart";
import { apricot } from "@/scss/_variables.scss";
import {
  handleBackgroundColorsNoOthersPresent,
  handleDataClickColors,
  handleOtherClickColors
} from "@/utils/LDMSAnalyticsUtils";
import { hexToRGB } from "@/utils/colors";

export default {
  name: "LDMSAnalyticsCountermeasures",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    showRootCausesChart: { type: Boolean, default: false },
    predefinedCountermeasures: { type: Array, default: () => [] }
  },
  components: {
    AnalyticsPagination,
    HBarChart
  },
  data() {
    return {
      countermeasuresBarChartData: {},
      countermeasureKeys: [],
      pageSize: 10,
      otherCountermeasures: []
    };
  },
  methods: {
    isOtherCountermeasures(elementIndex) {
      return (
        this.otherCountermeasures.length > 0 &&
        elementIndex === this.countermeasuresBarChartData.datasets[0].data.length - 1
      );
    },
    otherCountermeasuresExistInFilter() {
      return this.otherCountermeasures.some(
        (otherCountermeasure) =>
          this.interactiveFilters.countermeasures.findIndex(
            (countermeasure) => otherCountermeasure.key === countermeasure.key
          ) >= 0
      );
    },
    allNonOtherCountermeasuresExistInFilter() {
      return this.countermeasureKeys.every(
        (key) =>
          this.interactiveFilters.countermeasures.findIndex(
            (countermeasure) => key === countermeasure.key
          ) >= 0
      );
    },
    updateDataBackgroundColors() {
      this.countermeasuresBarChartData.datasets[0].backgroundColor = handleDataClickColors(
        this.countermeasuresBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.countermeasures
      );
    },
    updateOtherBackgroundColors() {
      const allNonOtherCountermeasuresExistInFilter =
        this.allNonOtherCountermeasuresExistInFilter();
      this.countermeasuresBarChartData.datasets[0].backgroundColor = handleOtherClickColors(
        this.countermeasuresBarChartData.datasets[0].backgroundColor,
        allNonOtherCountermeasuresExistInFilter
      );
    },
    updateBackgroundColorsNoOthersPresent() {
      this.countermeasuresBarChartData.datasets[0].backgroundColor =
        handleBackgroundColorsNoOthersPresent(
          this.countermeasuresBarChartData.datasets[0].backgroundColor,
          this.interactiveFilters.countermeasures
        );
    },
    async handleTabChange(pageSize) {
      this.pageSize = pageSize;
      await this.getCountermeasureData();
      if (this.interactiveFilters.countermeasures.length === 0) {
        return;
      }
      if (this.otherCountermeasuresExistInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      if (this.isOtherCountermeasures(elementIndex)) {
        this.setInteractiveFilterForOther();
        return;
      }
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilterForOther() {
      if (!this.otherCountermeasuresExistInFilter()) {
        this.interactiveFilters.countermeasures.push(...this.otherCountermeasures);
      } else {
        const filterCopy = this.interactiveFilters.countermeasures.slice();
        this.otherCountermeasures.forEach((otherCountermeasure) => {
          const foundCountermeasureIndex = filterCopy.findIndex(
            (countermeasure) => otherCountermeasure.key === countermeasure.key
          );
          if (foundCountermeasureIndex >= 0) {
            filterCopy.splice(foundCountermeasureIndex, 1);
          }
        });
        this.interactiveFilters.countermeasures = filterCopy.slice();
      }
      this.updateOtherBackgroundColors();
    },
    setInteractiveFilter(elementIndex) {
      const countermeasureKey = this.countermeasureKeys[elementIndex];
      const foundCountermeasureIndex = this.interactiveFilters.countermeasures.findIndex(
        (countermeasure) => countermeasure.key === countermeasureKey
      );
      if (foundCountermeasureIndex >= 0) {
        this.interactiveFilters.countermeasures.splice(foundCountermeasureIndex, 1);
      } else {
        this.interactiveFilters.countermeasures.push({
          key: countermeasureKey,
          index: elementIndex
        });
      }
      if (this.otherCountermeasuresExistInFilter()) {
        this.updateDataBackgroundColors();
        return;
      }
      this.updateBackgroundColorsNoOthersPresent();
    },
    async getCountermeasureData() {
      const machines = this.interactiveFilters.machines.map((machine) => machine.id);
      const parts = this.interactiveFilters.parts.map((part) => part.id);
      const reasons = this.interactiveFilters.reasons.map((reason) => reason.id);
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_group: this.filters.machineGroups,
        machine_ids: machines.length > 0 ? machines : this.filters.machines,
        part_numbers: parts.length > 0 ? parts : this.filters.parts,
        reason_ids: reasons.length > 0 ? reasons : this.filters.reasons
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("exceptions/countermeasures/", { params });
        if (response && response.data) {
          this.otherCountermeasures = response.data.filter(
            (item) =>
              this.predefinedCountermeasures.findIndex(
                (countermeasure) => countermeasure.text === item.key
              ) === -1
          );
          const data = response.data.filter(
            (item) =>
              this.predefinedCountermeasures.findIndex(
                (countermeasure) => countermeasure.text === item.key
              ) >= 0
          );
          this.countermeasureKeys = data.map((countermeasure) => countermeasure.key);
          const colors = [
            ...Array(this.otherCountermeasures.length > 0 ? data.length + 1 : data.length)
              .fill(0)
              .map(() => hexToRGB(apricot, "0.9"))
          ];
          this.countermeasuresBarChartData = {
            labels: data.map((countermeasure) => countermeasure.key),
            datasets: [
              {
                data: data.map((item) => item.doc_count),
                label: this.$t("Number of Issues"),
                backgroundColor: colors
              }
            ]
          };
          if (this.otherCountermeasures.length > 0) {
            let otherIssueTotal = 0;
            this.otherCountermeasures.forEach(
              (countermeasure) => (otherIssueTotal += countermeasure.doc_count)
            );
            this.countermeasuresBarChartData.labels.push(this.$t("Other"));
            this.countermeasuresBarChartData.datasets[0].data.push(otherIssueTotal);
          }
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
<style lang="scss"></style>
