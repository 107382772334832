<template>
  <div class="navigation">
    <div
      class="overlay"
      @click="
        close();
        submenu = null;
      "
      :class="{ show: status == 'open' }"
    ></div>
    <nav
      id="sidenav"
      ref="sidenav"
      :class="{ active: activeMenu.includes('sidenav'), 'active-sm': submenu }"
    >
      <div
        class="nav-main"
        :style="{ left: activeMenu.length == 0 ? '0px' : '-400px' }"
      >
        <div class="top">
          <div
            class="nav-item"
            v-if="!disabled.includes('group')"
          >
            <a
              class="expandable"
              @click="open('groupnav')"
            >
              <i class="icon fa fa-map"></i>
              <label>{{ $t("Machine Groups") }}</label>
              <i class="fa fa-chevron-right" />
            </a>
          </div>

          <div
            class="nav-item"
            v-if="!disabled.includes('audits')"
          >
            <router-link
              to="/audits/types"
              @click.stop="close"
            >
              <i class="icon fa fa-tasks" /><label>{{ $t("Audits") }}</label>
            </router-link>
          </div>

          <div
            class="nav-item"
            v-if="!disabled.includes('iot')"
          >
            <router-link
              to="/iot/realtime"
              @click.stop="close"
            >
              <i class="icon fa fa-cloud" /><label>{{ $t("IoT") }}</label>
            </router-link>
          </div>

          <div
            class="nav-item"
            v-if="!disabled.includes('analytics')"
          >
            <router-link
              to="/analytics"
              @click.stop="close"
            >
              <i class="icon fa fa-bar-chart" /><label>{{ $t("Analytics") }}</label>
            </router-link>
          </div>

          <div
            class="nav-item"
            v-if="!disabled.includes('aeyevision/asset')"
          >
            <a
              class="expandable"
              @click="open('ainav')"
            >
              <i class="icon fa fa-eye"></i>
              <label>{{ $t("Bax AEYE") }}</label>
              <i class="fa fa-chevron-right" />
            </a>
          </div>

          <development-feature>
            <template v-slot:edge>
              <div
                class="nav-item"
                v-if="!disabled.includes('iris')"
              >
                <router-link
                  to="/iris"
                  @click.stop="close"
                >
                  <v-icon class="icon">mdi-creation</v-icon>
                  <label>{{ $t("Iris") }}</label>
                </router-link>
              </div>
            </template>
          </development-feature>

          <development-feature>
            <template v-slot:edge>
              <div
                class="nav-item"
                v-if="accessToConfig"
              >
                <a
                  class="expandable"
                  @click="open('applicationMonitoring')"
                >
                  <i class="icon fa fa-medkit"></i>
                  <label>{{ $t("Application Monitoring") }}</label>
                  <i class="fa fa-chevron-right" />
                </a>
              </div>
            </template>
          </development-feature>

          <development-feature>
            <template v-slot:edge>
              <div
                class="nav-item"
                v-if="accessToLighthouse"
              >
                <router-link
                  to="/lighthouse"
                  @click.stop="close"
                >
                  <i class="icon fa fa-search" /><label>{{ $t("Lighthouse") }}</label>
                </router-link>
              </div>
            </template>
          </development-feature>

          <development-feature>
            <template v-slot:edge>
              <div
                class="nav-item"
                v-if="accessToFastResponseBoard"
              >
                <router-link
                  to="/raw-data"
                  @click.stop="close"
                >
                  <i class="icon fa fa-search" /><label>{{ $t("Raw Data") }}</label>
                </router-link>
              </div>
            </template>
          </development-feature>

          <development-feature>
            <template v-slot:edge>
              <div
                class="nav-item"
                v-if="accessToDigitalForms"
              >
                <a
                  class="expandable"
                  @click.stop="open('digitalFormSubNav')"
                >
                  <i class="icon fa fa-th-large" />
                  <label>{{ $t("Forms") }}</label>
                  <i class="fa fa-chevron-right" />
                </a>
              </div>
            </template>
          </development-feature>

          <development-feature>
            <template v-slot:edge>
              <div
                class="nav-item"
                v-if="accessToDigitalTwin"
              >
                <router-link
                  to="/digital-twin"
                  @click.stop="close"
                >
                  <i class="icon fa fa-search" /><label>{{ $t("Digital Twin") }}</label>
                </router-link>
              </div>
            </template>
          </development-feature>
        </div>
        <div class="bottom">
          <!-- <div class="nav-item">
            <router-link :to="{name:'home'}" @click.stop="close">
              <i class="icon fa fa-user"/><label>{{$t('Profile')}}</label>
            </router-link>
          </div> -->
          <div class="nav-item">
            <div
              class="logout"
              @click.stop="logout"
            >
              <i class="icon fa fa-power-off" /><label>{{ $t("Log out") }}</label>
            </div>
          </div>
        </div>
      </div>

      <div
        id="ainav"
        class="subnav groupnav"
        key="ainav_menu"
        :class="{ active: activeMenu.includes('ainav') }"
        :style="{ left: activeMenu.indexOf('ainav') >= 0 ? '0px' : '400px' }"
      >
        <div
          class="back"
          @click.stop.prevent="activeMenu = []"
        >
          <i class="fa fa-chevron-left" />
          <p>{{ $t("Back") }}</p>
        </div>
        <transition-group
          name="group-level"
          tag="div"
          class="groups"
        >
          <div
            class="selectedGroup"
            :key="'level-' + topLevel.idx"
            :id="'level-' + topLevel.idx"
            v-for="(topLevel, level_index) in [
              { type: 'loadingIndicator', idx: 0, visible: assetsLoading },
              { type: 'openLocations', locations: openAssetLocations, idx: 1 },
              { type: 'title', name: $t('Groups'), idx: 2, visible: assetLocations.length > 0 },
              { type: 'locations', locations: assetLocations, idx: 3 },
              {
                type: 'childAssets',
                assets: [
                  ...assetLocations
                    .map((asset) => asset.childAssets)
                    .reduce((a, b) => a.concat(b), []),
                  ...assets
                ].reduce((a, b) => a.concat(b), []),
                dx: 7
              }
            ]"
          >
            <div
              v-if="topLevel.type == 'loadingIndicator' && topLevel.visible"
              class="loadingIndicator"
            >
              <v-progress-linear indeterminate />
            </div>

            <div
              class="machineGroup hoverItem selected"
              v-bind:class="location.hover === 'close' ? 'close' : null"
              v-if="topLevel.type == 'openLocations'"
              v-for="location in topLevel.locations"
              @click="navigateToAssetGroup(location)"
            >
              <i
                class="fa fa-times"
                @mouseover="
                  () => {
                    location.hover = 'close';
                    openAssetLocations = [...openAssetLocations];
                  }
                "
                @mouseout="
                  () => {
                    location.hover = false;
                    openAssetLocations = [...openAssetLocations];
                  }
                "
                @click.stop="removeAssetLocation(location)"
              />
              <a class="innerSelectedGroup">
                <p>{{ $t(location.name) }}</p>
              </a>
              <i class="fa fa-chevron-right" />
            </div>

            <div
              class="machineGroup"
              v-if="topLevel.type == 'title' && topLevel.visible"
            >
              <h2 class="groupLevelName">{{ $t(topLevel.name) }}</h2>
            </div>

            <div
              class="machineGroup hoverItem"
              v-if="topLevel.type == 'locations'"
              v-for="location in topLevel.locations"
              @click="navigateToAssetLocation(location)"
            >
              <a class="innerSelectedGroup">
                <p>{{ $t(location.name) }}</p>
                <i
                  class="fa fa-chevron-right"
                  @click.stop="navigateToAssetGroup(location)"
                />
              </a>
            </div>

            <Fragment v-if="topLevel.type == 'childAssets' && topLevel.assets.length > 0">
              <div class="machineGroup">
                <h2 class="groupLevelName">{{ $t("Assets") }}</h2>
                <div
                  key="search"
                  class="searchSelect"
                  @click="unhideSearchbar"
                >
                  <i class="fa fa-search" />
                </div>
              </div>

              <v-text-field
                prepend-inner-icon="fa fa-search"
                class="search-box"
                clearable
                filled
                dense
                v-model="assetSearchBox"
                v-if="searchVisible"
                ref="searchElement"
              />

              <div
                class="machineLink"
                :key="topLevel.idx"
                v-for="(asset, index) in topLevel.assets.filter(
                  (a) =>
                    a.name
                      .toLowerCase()
                      .indexOf(assetSearchBox ? assetSearchBox.toLowerCase() : '') >= 0
                )"
              >
                <router-link
                  :to="{
                    name: 'aeyevision/asset',
                    params: {
                      assetId: asset.pk || asset.id,
                      asset: { ...asset, pk: asset.pk || asset.id },
                      type: 'asset'
                    }
                  }"
                  @click="close"
                  active-class="inactiveMachineLink"
                >
                  <p>{{ $t(asset.name) }}</p>
                </router-link>
              </div>
            </Fragment>
          </div>
        </transition-group>
      </div>

      <div
        id="application-monitoring"
        class="subnav groupnav monitoring"
        key="application_monitoring_menu"
        :class="{ active: activeMenu.includes('applicationMonitoring') }"
        :style="{ left: activeMenu.indexOf('applicationMonitoring') >= 0 ? '0px' : '400px' }"
      >
        <div
          class="back"
          @click.stop.prevent="activeMenu = []"
        >
          <i class="fa fa-chevron-left" />
          <p>{{ $t("Back") }}</p>
        </div>
        <transition-group
          name="group-level"
          tag="div"
          class="groups"
        >
          <div
            :key="'ticket-group'"
            class="selectedGroup"
          >
            <div
              :key="'tickets'"
              class="machineLink ticket-link"
            >
              <router-link
                :to="{ name: 'application-issue-tickets' }"
                @click="close"
                active-class="inactiveMachineLink"
                ><i
                  class="fa fa-ticket"
                  :style="{ paddingRight: '1rem' }"
                />{{ $t("Issue Tickets") }}</router-link
              >
            </div>
          </div>
        </transition-group>
      </div>

      <div
        id="groupnav"
        class="subnav groupnav"
        key="group_menu"
        :class="{ active: activeMenu.includes('groupnav') }"
        :style="{ left: activeMenu.indexOf('groupnav') >= 0 ? '0px' : '400px' }"
      >
        <div
          class="back"
          @click.stop.prevent="activeMenu = []"
        >
          <i class="fa fa-chevron-left" />
          <p>{{ $t("Back") }}</p>
        </div>
        <i
          @click.stop.prevent="goToMap()"
          class="fa fa-globe"
          aria-hidden="true"
        ></i>
        <i
          @click.stop.prevent="selectLocation()"
          class="fa fa-map-marker"
          aria-hidden="true"
        ></i>
        <transition-group
          name="group-level"
          tag="div"
          class="groups"
        >
          <div
            :class="['selectedGroup', 'level-' + group.id]"
            v-for="(group, level_index) in selectedGroups"
            :key="'level-' + group.id"
            :id="'level-' + group.id"
            :style="{ 'max-height': group.height }"
          >
            <transition-group name="sub-groups">
              <div
                class="machineGroup"
                v-if="!group.selected && group.subgroups.length > 0"
                :key="'groupName' + group.level.level"
              >
                <h2 class="groupLevelName">{{ group.subgroups[0].level.name }}</h2>
              </div>
              <div
                class="machineGroup hoverItem"
                v-if="!group.selected || group.selected.id == sub.id"
                v-for="(sub, sub_index) in group.subgroups"
                :id="'sub' + group.id + '-' + sub.pk"
                :key="'sub' + group.id + '-' + sub.pk"
                :class="{
                  selected: group.selected && group.selected.id == sub.id,
                  close: group.hover == 'close'
                }"
              >
                <i
                  v-if="group.selected && group.selected.id == sub.id"
                  @click.stop.prevent="removeGroup(group, level_index, sub)"
                  class="fa fa-times"
                  @mouseover="group.hover = 'close'"
                  @mouseout="group.hover = false"
                />

                <development-feature v-if="group.selected && group.selected.pk == sub.pk">
                  <template #edge>
                    <router-link
                      :to="{
                        name: AppRoutes.groupSummary.name,
                        params: {
                          level: sub.level.level,
                          machine_group_id: sub.id,
                          machine_group_pk: sub.pk
                        }
                      }"
                      @click="close"
                    >
                      {{ sub.name }}
                    </router-link>
                  </template>

                  <template #stable>
                    <router-link
                      v-if="group.selected && group.selected.pk == sub.pk"
                      :to="{
                        name: AppRoutes.groupSummaryOld.name,
                        params: {
                          level: sub.level.level,
                          machine_group_id: sub.id,
                          machine_group_pk: sub.pk
                        }
                      }"
                      @click="close"
                    >
                      {{ sub.name }}
                    </router-link>
                  </template>
                </development-feature>

                <a
                  v-else
                  @click="selectGroup(group, sub)"
                  ><p>{{ $t(sub.name) }}</p></a
                >
                <i
                  v-if="group.selected && group.selected.id == sub.id"
                  class="fa fa-chevron-right"
                />
              </div>
            </transition-group>
          </div>
          <div
            class="machine-container"
            :key="'machine-container'"
          >
            <div
              class="machine-text-search"
              :key="'machine-text-search'"
            >
              <h2 class="machines-text">{{ $t("Machines") }}</h2>
              <div
                key="search"
                class="machine-search"
                @click="unhideMachineSearchbar"
              >
                <i class="fa fa-search" />
              </div>
            </div>
            <v-text-field
              prepend-inner-icon="fa fa-search"
              class="search-box"
              clearable
              filled
              dense
              v-model="machineSearchText"
              v-if="machineSearchVisible"
              ref="machineSearchElement"
              :key="'machineTextField'"
            />
            <div
              class="machineLink"
              v-for="(machine, machine_index) in filteredMachines"
              :id="'machine' + machine.id"
              :key="'machine' + machine.id"
            >
              <development-feature>
                <template #edge>
                  <router-link
                    :to="{
                      name: AppRoutes.machineSummary.name,
                      params: { machine_id: machine.id, machine_pk: machine.pk }
                    }"
                    @click="close"
                  >
                    {{ machine.name }}
                  </router-link>
                </template>

                <template #stable>
                  <router-link
                    :to="{
                      name: AppRoutes.machine.name,
                      params: { machine_id: machine.id, machine_pk: machine.pk }
                    }"
                    @click="close"
                  >
                    {{ machine.name }}
                  </router-link>
                </template>
              </development-feature>
            </div>
          </div>
        </transition-group>
      </div>

      <div
        id="digital-forms"
        class="subnav groupnav"
        key="digital-forms_menu"
        :class="{ active: activeMenu.includes('digitalFormSubNav') }"
        :style="{ left: activeMenu.indexOf('digitalFormSubNav') >= 0 ? '0px' : '400px' }"
      >
        <div
          class="back"
          @click.stop.prevent="activeMenu = []"
        >
          <i class="fa fa-chevron-left" />
          <p>{{ $t("Back") }}</p>
        </div>
        <transition-group
          name="group-level"
          tag="div"
          class="groups"
        >
          <div
            :key="'form-group'"
            class="selectedGroup"
          >
            <div
              :key="'form-route'"
              class="forms-sub-nav"
            >
              <router-link
                :to="{ name: 'user-progressions' }"
                @click="close"
                class="forms-sub-nav__item"
              >
                <v-icon>mdi-file-document-multiple-outline</v-icon>
                {{ $t("My Forms") }}
              </router-link>
              <router-link
                :to="{ name: 'templates' }"
                @click="close"
                class="forms-sub-nav__item"
              >
                <v-icon>mdi-file-cabinet</v-icon>
                {{ $t("Templates") }}
              </router-link>
            </div>
          </div>
        </transition-group>
      </div>
    </nav>
  </div>
</template>
<script>
import Vue from "vue";
import { Fragment } from "vue-fragment";
import { mapActions, mapGetters } from "vuex";

import animated from "@/components/animated";
import { Dispatcher } from "@/utils/eventbus";
import { hasUserRole, userRoles } from "@/utils/user";

import { AppRoutes } from "../shared/app-routes";
import DevelopmentFeature from "./DevelopmentFeature";
import PieChart from "./charts/PieChart";

export default {
  props: ["status"],
  components: {
    PieChart,
    DevelopmentFeature,
    animated,
    Fragment
  },
  data() {
    return {
      activeMenu: [],
      fullscreen: false,
      submenu: null,
      groups: [],
      groupLevels: [],
      groupLevel: 0,
      highest_level: 0,
      lowest_level: 0,
      selectedGroups: [],
      disabled: [],
      accessToConfig: false,
      accessToLighthouse: false,
      accessToDigitalForms: true,
      accessToFastResponseBoard: false,
      accessToDigitalTwin: false,
      vw: window.innerWidth,
      assets: [],
      assetsLoading: false,
      assetLocationDepth: 1,
      assetLocationTreeId: 0,
      assetLocations: [],
      openAssetLocations: [],
      assetSearchBox: "",
      searchVisible: false,
      machineSearchVisible: false,
      machineSearchText: "",
      selectedGroup: {},
      machines: [],
      filteredMachines: []
    };
  },
  created() {
    this.getAssetLocations(1, 0);
    this.accessToLighthouse = hasUserRole(this.user, userRoles.Lighthouse);
    this.accessToDigitalTwin = hasUserRole(this.user, userRoles.DigitalTwin);
    this.accessToFastResponseBoard = hasUserRole(this.user, userRoles.FastResponseBoard);
    this.$http
      .get("graphql/", {
        params: {
          query: `{machineGroups{
        name
        id
        pk
        level{
          name
          level
        }
        machines{
          name
          id
          pk
        }
        subGroupIds
      }
    }`
        }
      })
      .then((res) => {
        this.groups = res.data.data.machineGroups;
        this.groupLevels = [];
        this.lowest_level = 99;
        this.highest_level = 0;
        this.groups.forEach((group) => {
          Vue.set(group, "selected", null);
          if (group.level.level > this.highest_level) {
            this.highest_level = group.level.level;
          }
          if (group.level.level < this.lowest_level) {
            this.lowest_level = group.level.level;
          }
          if (group.level.level in this.groupLevels) {
            this.groupLevels[group.level.level].push(group);
          } else {
            this.groupLevels[group.level.level] = [group];
          }
        });
        this.groupLevel = this.highest_level;
        this.selectGroup("initial", "initial");
        if (this.location.id) {
          this.selectLocation();
        }
        this.loadRoutes();
        this.accessToConfig = hasUserRole(this.user, userRoles.ApplicationAdmin);
      })
      .catch((res) => {
        this.errors = res.errors;
      });
  },
  computed: {
    AppRoutes() {
      return AppRoutes;
    },
    ...mapGetters({
      navClass: "app/Header",
      titles: "app/Titles",
      user: "session/User",
      location: "session/Location",
      sharedDevice: "session/SharedDevice",
      disabledRoutes: "routes/Disabled",
      machineGroupFromId: "dbCache/machineGroupFromId"
    }),
    menuItems: function () {
      return [];
    }
  },
  methods: {
    ...mapActions({
      setDisableRoutes: "routes/SetDisabledRoutes",
      logoutUser: "session/Logout"
    }),
    getMachines() {
      if (Object.keys(this.selectedGroup).length === 0) {
        this.machines = [];
        this.filterMachines();
        return;
      }
      if (this.selectedGroup.subgroups.length === 0) {
        this.machines = [...this.selectedGroup.machines];
        this.filterMachines();
        return;
      }
      let machines = [];
      machines.push(...this.selectedGroup.machines);
      this.selectedGroup.subgroups.forEach((group) => {
        const machineGroup = this.machineGroupFromId(group.pk);
        if (machineGroup) {
          machines.push(...machineGroup.allMachines);
        }
      });
      machines = machines.filter(
        (machine, index, arr) => arr.findIndex((item) => item.id === machine.id) === index
      );
      this.machines = machines;
      this.filterMachines();
    },
    filterMachines() {
      if (this.machineSearchText) {
        this.filteredMachines = this.machines.filter(
          (machine) =>
            machine.name
              .toLowerCase()
              .indexOf(this.machineSearchText ? this.machineSearchText.toLowerCase() : "") >= 0
        );
        return;
      }
      this.filteredMachines = [...this.machines];
    },
    unhideMachineSearchbar() {
      this.machineSearchVisible = !this.machineSearchVisible;
      if (this.machineSearchVisible) {
        Vue.nextTick(() => {
          if (this.$refs.machineSearchElement && this.$refs.machineSearchElement[0]) {
            this.$refs.machineSearchElement[0].focus();
          }
        });
      } else {
        this.machineSearchText = "";
      }
    },
    unhideSearchbar() {
      this.searchVisible = !this.searchVisible;
      if (this.searchVisible) {
        Vue.nextTick(() => {
          if (this.$refs.searchElement && this.$refs.searchElement[0]) {
            this.$refs.searchElement[0].focus();
          }
        });
      } else {
        this.assetSearchBox = "";
      }
    },
    navigateToAssetGroup(location) {
      this.$router.push({
        name: "aeyevision/group",
        params: { groupId: location.pk, group: { ...location }, type: "group" }
      });
    },
    getAssetLocations(depth, treeId, lft, rgt) {
      this.assetsLoading = true;
      this.$http
        .post("graphql/", {
          query: `query($depth:Int, $treeId:Int, $lft:Int, $rgt:Int){
      assetLocations(depth:$depth, treeId:$treeId, lft:$lft, rgt:$rgt) {
        name,
        id,
        pk,
        depth,
        children,
        childAssets,
        treeId,
        lft,
        rgt,
        assets {
          name,
          pk,
        }
      }
     }`,
          variables: { depth: depth, treeId: treeId, lft: lft, rgt: rgt }
        })
        .then((res) => {
          this.assetLocations = res.data.data.assetLocations;
          for (const a of this.assetLocations) {
            a.childAssets = JSON.parse(a.childAssets);
          }

          setTimeout(() => {
            this.assetsLoading = false;
          }, 200);
        })
        .catch((e) => {
          console.log(e);
          setTimeout(() => {
            this.assetsLoading = false;
          }, 200);
        });
    },
    removeAssetLocation(location) {
      this.openAssetLocations = this.openAssetLocations.filter((loc) => {
        return loc.depth < location.depth;
      });

      this.assetLocations = [];

      if (this.openAssetLocations.length > 0) {
        const lastLocation = this.openAssetLocations[this.openAssetLocations.length - 1];
        this.getAssetLocations(
          lastLocation.depth + 1,
          lastLocation.treeId,
          lastLocation.lft,
          lastLocation.rgt
        );
        this.assets = lastLocation.assets;
      } else {
        this.getAssetLocations(1, 0, 0, 0);
      }
    },
    navigateToAssetLocation(location) {
      this.openAssetLocations.push(location);
      this.assetLocations = [];
      this.getAssetLocations(location.depth + 1, location.treeId, location.lft, location.rgt);
      this.assets = location.assets;
    },
    toggle() {
      if (this.activeMenu.length > 0) {
        this.back();
      } else {
        console.log("open sidenav");
        this.open("sidenav");
      }
    },
    open(subnav) {
      Dispatcher.$message("NAVIGATION", "open");
      this.activeMenu.push(subnav);
      this.submenu = null;
    },
    back() {
      this.activeMenu.pop();
    },
    logout() {
      if (this.sharedDevice) {
        if (this.$auth) {
          this.$auth.isAuthenticated().then((authenticated) => {
            if (authenticated) {
              this.$auth.signOut();
            } else {
              this.logoutUser();
            }
          });
        }
      } else {
        this.logoutUser();
      }
      this.close();
    },
    close() {
      Dispatcher.$message("NAVIGATION", "close");
      // this.activeMenu = [];
      // this.selectedGroups = []
    },
    getParent(group) {
      let parent = false;
      this.groups.forEach((g) => {
        if (g.subGroupIds.includes(group.pk)) {
          parent = g;
        }
      });
      if (parent) {
        let parentPath = this.getParent(parent);
        parentPath.push(group);
        return parentPath;
      } else {
        return [group];
      }
    },
    selectLocation() {
      if (this.location.id) {
        let group = false;
        this.selectedGroups = [
          {
            id: -1,
            height: (this.groupLevels[this.highest_level].length + 1) * 63 + "px",
            name: this.$t("Back"),
            level: { name: this.$t("Back") },
            subgroups: this.groupLevels[this.highest_level],
            machines: []
          }
        ];
        this.groups.forEach((g) => {
          if (this.location.id == g.pk) {
            group = g;
          }
        }, this);
        let parents = this.getParent(group);
        let selectGroup = this.selectGroup;
        let parentGroup = this.selectedGroups[0];
        parents.forEach((g) => {
          this.selectGroup(parentGroup, g);
          parentGroup = g;
        });
      }
    },
    goToMap() {
      this.$router.push({ name: "map" });
    },
    selectGroup(parentGroup, selectedGroup) {
      this.subnav = "group";
      if (!parentGroup.selected) {
        if (selectedGroup == "initial") {
          if (this.selectedGroups.length == 0) {
            this.selectedGroups = [
              {
                id: -1,
                height: (this.groupLevels[this.highest_level].length + 1) * 63 + "px",
                name: this.$t("Back"),
                level: { name: this.$t("Back") },
                subgroups: this.groupLevels[this.highest_level],
                machines: []
              }
            ];
            this.selectedGroup = { ...this.selectedGroups[0] };
          }
        } else {
          parentGroup.selected = selectedGroup;
          (parentGroup.height = "63px"), Vue.set(parentGroup, "hover", false);
          selectedGroup.selected = null;
          selectedGroup.subgroups = [];
          if (selectedGroup.level.level > this.lowest_level) {
            this.groupLevels[selectedGroup.level.level - 1].forEach((group, i) => {
              group.selected = null;
              Vue.set(group, "hover", false);
              if (selectedGroup.subGroupIds.includes(group.pk)) {
                selectedGroup.subgroups.push(group);
              }
            });
          }
          (selectedGroup.height = (selectedGroup.subgroups.length + 1) * 63 + "px"),
            this.selectedGroups.push(selectedGroup);
          this.selectedGroup = { ...selectedGroup };
        }
      }
    },
    removeGroup(parentGroup, parentIndex, group) {
      Vue.set(parentGroup, "hover", false);
      parentGroup.selected = null;
      parentGroup.height = (parentGroup.subgroups.length + 1) * 63 + "px";
      group.selected = null;
      this.selectedGroups.splice(parentIndex + 1, this.selectedGroups.length);
      this.selectedGroup = { ...parentGroup };
    },
    loadRoutes() {
      const query = `query ($userId: Int) { disabledRoutes (userId: $userId) { routeName, id, pk} }`;
      const variables = {
        userId: this.user.id
      };

      this.$http
        .post("graphql/", {
          query: query,
          variables: variables
        })
        .then((res) => {
          this.setDisableRoutes(res.data.data.disabledRoutes);
          this.setDisabled();
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    setDisabled() {
      this.disabled = [];
      console.log("disabledRoutes");
      console.log(this.disabledRoutes.disabledRoutes);
      this.disabledRoutes.disabledRoutes.forEach((route) => {
        this.disabled.push(route.routeName);
      });
    }
  },
  watch: {
    disabledRoutes: {
      deep: true,
      handler: "setDisabled"
    },
    location: {
      deep: true,
      handler: "selectLocation"
    },
    selectedGroup() {
      this.getMachines();
    },
    machineSearchText() {
      this.filterMachines();
    }
  }
};
</script>
<style lang="scss">
@import "./NavBar.scss";

.forms-sub-nav {
  margin: auto;
  padding-inline: 1rem;
  display: grid !important;
  justify-items: center;
  gap: 0.5rem;

  &__item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 1rem;

    margin: 0 !important;
    padding: 1rem;

    border-radius: 4rem;
    width: 100%;

    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.0015em;
    font-weight: 500;

    text-align: center;
    text-decoration: none;
    color: $black !important;
  }

  &__item:hover,
  &__item:focus,
  &__item:target,
  &__item:focus-visible {
    border: 1px solid rgba(84, 221, 255, 1);
  }
}

.theme--dark {
  .forms-sub-nav {
    .v-icon {
      color: $white !important;
    }

    &__item {
      color: $white !important;
    }

    &__item:hover,
    &__item:focus,
    &__item:target,
    &__item:focus-visible {
      color: $black !important;
      background: rgba(250, 250, 250, 1);
      .v-icon {
        color: $black !important;
      }
    }
  }
}
</style>
