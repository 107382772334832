import { OptimizationModelView, SearchView } from "./views";

export const routes = [
  {
    name: "lighthouse",
    path: "/lighthouse",
    component: SearchView,
    children: []
  },
  {
    name: "optimizationModel",
    path: "/lighthouse/optimizationModels/:id",
    component: OptimizationModelView
  }
];
