<template>
  <circle
    v-if="type === 'event'"
    :r="circleRadius"
    :cx="cx"
    :cy="cy"
    :class="circleClassEvent"
    @click="selectBodyPart(pointName)"
  />
  <g v-else-if="type === 'card'">
    <!--    First Aid-->
    <circle
      :r="circleRadius"
      :cx="firstAidCx"
      :cy="cy"
      :class="circleClassFirstAid"
    />
    <text
      :x="firstAidCx"
      :y="cy"
      text-anchor="middle"
      dominant-baseline="central"
      fill="black"
      font-size="4"
    >
      {{ totalCountFirstAid }}
    </text>
    <!--    Recordable-->
    <circle
      :r="circleRadius"
      :cx="recordableCx"
      :cy="cy"
      :class="circleClassRecordable"
    />
    <text
      :x="recordableCx"
      :y="cy"
      text-anchor="middle"
      dominant-baseline="central"
      fill="black"
      font-size="4"
    >
      {{ totalCountRecordable }}
    </text>
  </g>
</template>

<script>
export default {
  props: ["type", "cx", "cy", "pointName", "affectedAreas", "safetyTickets"],
  data() {
    return {
      circleRadius: 3,
      firstAidAffectedBodyParts: [],
      recordableAffectedBodyParts: []
    };
  },
  created() {
    this.calculateAffectedBodyParts();
  },
  emits: ["pick-point"],
  methods: {
    selectBodyPart(name) {
      this.$parent.$emit("pick-point", name);
    },
    calculateAffectedBodyParts() {
      this.firstAidAffectedBodyParts = this.safetyTickets
        .filter((ticket) => ticket.firstAid)
        .map((ticket) => ticket.affectedBodyPart.split("|"))
        .flat();

      this.recordableAffectedBodyParts = this.safetyTickets
        .filter((ticket) => ticket.recordable)
        .map((ticket) => ticket.affectedBodyPart.split("|"))
        .flat();
    }
  },
  computed: {
    circleClassEvent() {
      if (this.affectedAreas.includes(this.pointName)) {
        return "body-part-circle circle-selected";
      } else {
        return "body-part-circle circle-unselected";
      }
    },
    circleClassFirstAid() {
      if (this.firstAidAffectedBodyParts.includes(this.pointName)) {
        return "first-aid";
      } else {
        return "na";
      }
    },
    circleClassRecordable() {
      if (this.recordableAffectedBodyParts.includes(this.pointName)) {
        return "recordable";
      } else {
        return "na";
      }
    },
    firstAidCx() {
      if (this.recordableAffectedBodyParts.includes(this.pointName)) {
        return this.cx - 2.5;
      } else {
        return this.cx;
      }
    },
    recordableCx() {
      if (this.firstAidAffectedBodyParts.includes(this.pointName)) {
        return this.cx + 2.5;
      } else {
        return this.cx;
      }
    },
    totalCountFirstAid() {
      const totalCount = this.safetyTickets.filter(
        (ticket) => ticket.affectedBodyPart.split("|").includes(this.pointName) && ticket.firstAid
      ).length;
      if (totalCount > 0) {
        return totalCount;
      } else {
        return "";
      }
    },
    totalCountRecordable() {
      const totalCount = this.safetyTickets.filter(
        (ticket) => ticket.affectedBodyPart.split("|").includes(this.pointName) && ticket.recordable
      ).length;
      if (totalCount > 0) {
        return totalCount;
      } else {
        return "";
      }
    }
  },
  watch: {
    safetyTickets() {
      this.calculateAffectedBodyParts();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables";
.body-part-circle:hover {
  cursor: pointer;
}

.circle-unselected {
  fill: rgba(white, 0.5);
}

.circle-selected {
  fill: $orange;
}

.recordable {
  fill: $red;
}
.first-aid {
  fill: $yellow;
}

.na {
  fill: rgba(0, 0, 0, 0);
}
</style>
