const VueConsole = {
  install(Vue) {
    if (process.env.NODE_ENV === "development") {
      Vue.prototype.$console = function (v, t = "---clg: ") {
        return console.log(t, v);
      };
    }
  }
};

export default VueConsole;
