<template>
  <div class="machine-group-quality card">
    <div class="quality-tabs-container">
      <v-tabs
        class="quality-tabs"
        v-model="selectedQualityTab"
        key="quality-tabs"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="summary">
          <p>
            <span class="button_text">{{ $t("Summary") }}</span>
            <span class="tiny_button_text">{{ $t("Summary") }}</span>
          </p>
        </v-tab>
        <v-tab key="details">
          <p>
            <span class="button_text">{{ $t("Details") }}</span>
            <span class="tiny_button_text">{{ $t("Details") }}</span>
          </p>
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="selectedQualityTab">
      <v-tab-item eager>
        <div class="card summary-container">
          <v-tabs
            class="summary-tabs"
            v-model="selectedSummaryTab"
            key="summary-tabs"
          >
            <v-tab key="machine">
              <p>
                <span class="button_text">{{ $t("Order by Machine") }}</span>
                <span class="tiny_button_text">{{ $t("Order by Machine") }}</span>
              </p>
            </v-tab>
            <v-tab-item eager>
              <QualityOrderByMachine
                ref="orderByMachineRef"
                :machineGroupPk="machine_group_pk"
                :from_date="from_date"
                :to_date="to_date"
                :shift="selectedShift"
              />
            </v-tab-item>
            <v-tab key="scrap-type">
              <p>
                <span class="button_text">{{ $t("Order by Scrap Type") }}</span>
                <span class="tiny_button_text">{{ $t("Order by Scrap Type") }}</span>
              </p>
            </v-tab>
            <v-tab-item eager>
              <QualityOrderByScrapType
                ref="orderByTypeRef"
                :machineGroupPk="machine_group_pk"
                :from_date="from_date"
                :to_date="to_date"
                :shift="selectedShift"
              />
            </v-tab-item>
          </v-tabs>
          <div class="dateWrapper">
            <v-select
              class="shiftSelect"
              :items="shifts"
              item-text="name"
              item-value="pk"
              v-model="selectedShift"
              :label="$t('Shift')"
              clearable
              @clear="selectedShift = null"
            />
            <button
              class="btn radioBtn"
              v-for="(btn, btnIndex) in intervals"
              :key="btn.value"
              :class="{ active: preset == btn.value }"
              @click="presetClick(btn.value)"
            >
              {{ btn.name }}
            </button>
            <div class="dates">
              <datetime-picker
                class="col-xs-6"
                id="from_date"
                :label="$t('From Date')"
                :is-dialog="true"
                v-model="from_date.toDate()"
                position="top"
              ></datetime-picker>
              <datetime-picker
                class="col-xs-6"
                id="to_date"
                :label="$t('To Date')"
                :is-dialog="true"
                v-model="to_date.toDate()"
                position="top right"
              ></datetime-picker>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item eager>
        <QualityDetails ref="qualityDetailsRef" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import moment from "moment";

import DatetimePicker from "@/components/form/DatetimePicker";

import QualityDetails from "./QualityDetails.vue";
import QualityOrderByMachine from "./QualityOrderByMachine.vue";
import QualityOrderByScrapType from "./QualityOrderByScrapType.vue";

export default {
  name: "Quality",
  props: ["machine_group_pk"],
  components: {
    QualityOrderByMachine,
    QualityOrderByScrapType,
    QualityDetails,
    DatetimePicker
  },
  data() {
    return {
      selectedQualityTab: 0,
      selectedSummaryTab: 0,
      shifts: [],
      selectedShift: null,
      from_date: moment().startOf("day"),
      to_date: moment().endOf("day"),
      preset: "day",
      intervals: [
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ]
    };
  },
  created() {
    this.getShifts();
  },
  methods: {
    getShifts() {
      this.$http
        .get("graphql/", {
          params: {
            query: `{
      shifts {
        name,
        erpShiftName,
        pk
      }
    }`
          }
        })
        .then((res) => {
          const uniqueShifts = new Set(this.shifts.map((shift) => shift.pk));
          res.data.data.shifts.forEach((shift) => {
            if (!uniqueShifts.has(shift.pk)) {
              this.shifts.push(shift);
              uniqueShifts.add(shift.pk);
            }
          });
          this.getCurrentShift();
        })
        .catch((res) => {
          console.error(res.error);
        });
    },
    getCurrentShift() {
      this.$http
        .get("graphql/", {
          params: {
            query: `{
      shifts(current: true) {
        name,
        erpShiftName,
        pk
      }
    }`
          }
        })
        .then((res) => {
          if (res.data.data.shifts.length === 0) {
            return;
          }
          const currentShift = res.data.data.shifts[0];
          this.selectedShift = currentShift.pk;
        })
        .catch((res) => {
          console.error(res.error);
        });
    },
    presetClick(preset) {
      let from_date = moment();
      let to_date = moment();
      let dateFormat = "D MMM hhA";
      let scale = "1h";
      switch (preset) {
        case "day":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "week":
          from_date = moment().startOf("week");
          to_date = moment().endOf("week");
          dateFormat = "D MMM";
          scale = "1d";
          break;
        case "month":
          from_date = moment().startOf("month");
          to_date = moment().endOf("month");
          dateFormat = "week";
          scale = "1w";
          break;
        case "year":
          from_date = moment().endOf("month").subtract(12, "months");
          to_date = moment().endOf("month");
          dateFormat = "MMM YY";
          scale = "1M";
          break;
      }
      this.preset = preset;
      this.from_date = from_date;
      this.to_date = to_date;
    }
  },
  watch: {
    selectedQualityTab() {
      if (this.selectedQualityTab === 0) {
        if (this.selectedSummaryTab === 0) {
          this.$refs.orderByMachineRef.expanded = [];
          this.$refs.orderByMachineRef.getSummaryTableData();
        } else {
          this.$refs.orderByTypeRef.expanded = [];
          this.$refs.orderByTypeRef.getSummaryTableData();
        }
        return;
      }
      if (this.selectedQualityTab === 1) {
        this.$refs.qualityDetailsRef.getDetailsTableScrap();
      }
    },
    selectedSummaryTab() {
      if (this.selectedSummaryTab === 0) {
        this.$refs.orderByMachineRef.expanded = [];
        this.$refs.orderByMachineRef.getSummaryTableData();
      } else {
        this.$refs.orderByTypeRef.expanded = [];
        this.$refs.orderByTypeRef.getSummaryTableData();
      }
    }
  }
};
</script>
<style lang="scss">
@import "./Quality.scss";
</style>
