var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticClass:"root",attrs:{"max-width":"576px","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',[_c('div',{staticClass:"dialogBtn"},[_c('Btn',_vm._g(_vm._b({staticClass:"mr-3 button-issue",attrs:{"large":"","variant":"primary","primary":""}},'Btn',attrs,false),on),[_vm._v("\n            "+_vm._s(_vm.$t("Add Issue"))+"\n          ")])],1)])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_vm._v(" "),_c('v-card',{staticClass:"dialog-wrapper"},[_c('DialogClose',{staticClass:"dialog-close",attrs:{"on-click":function () { return (_vm.showDialog = false); }}}),_vm._v(" "),_c('v-card-title',{staticClass:"px-6 py-5 text-h6 dialog-title"},[_vm._v("\n        "+_vm._s(_vm.$t("Submit LDMS Issue"))+"\n      ")]),_vm._v(" "),_c('v-card-text',{staticClass:"pt-6 pb-0 body"},[_c('v-autocomplete',{attrs:{"value":_vm.machine,"items":_vm.machines,"disabled":Object.keys(_vm.machineGroup).length > 0,"menu-props":{ maxHeight: '400', closeOnClick: true, closeOnContentClick: true },"label":_vm.$t('Machines'),"clearable":"","item-text":"name","return-object":"","hide-details":"","filled":""},on:{"input":function($event){_vm.machine = $event || {}}}}),_vm._v(" "),_c('div',{staticClass:"mt-5"},[_c('v-autocomplete',{attrs:{"items":_vm.issueTypes,"label":_vm.$t('Issue Type'),"clearable":"","item-text":"name","return-object":"","hide-details":"","filled":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.$t(item.name))+"\n            ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.$t(item.name))+"\n            ")]}}]),model:{value:(_vm.issueType),callback:function ($$v) {_vm.issueType=$$v},expression:"issueType"}})],1),_vm._v(" "),_c('div',{staticClass:"button-column mt-5 d-flex flex-column"},[_vm._l((_vm.reasonsForSelected),function(reason){return _c('ZoneControlReason',{key:reason.node.id,staticClass:"reason long-reason",attrs:{"text":reason.node.text,"is-selected":_vm.selected === reason,"action":function () {
                _vm.selected = reason;
              }}})}),_vm._v(" "),_c('ZoneControlReason',{staticClass:"reason long-reason",attrs:{"text":_vm.$t('Other'),"is-selected":_vm.selected === 'other',"action":function () {
                _vm.selected = 'other';
              }}})],2),_vm._v(" "),(_vm.selected === 'other')?_c('div',{staticClass:"dialog-textarea mt-6"},[_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('Issue')},model:{value:(_vm.otherText),callback:function ($$v) {_vm.otherText=$$v},expression:"otherText"}})],1):_vm._e()],1),_vm._v(" "),_c('v-card-actions',{staticClass:"pb-5 px-6 mt-9"},[_c('v-spacer'),_vm._v(" "),_c('Btn',{staticClass:"dialog-btn",on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t("Cancel"))+"\n        ")]),_vm._v(" "),_c('Btn',{staticClass:"dialog-btn",attrs:{"variant":"primary","disabled":_vm.disableSubmit,"loading":_vm.saving},on:{"click":_vm.createIssue}},[_vm._v("\n          "+_vm._s(_vm.$t("Submit"))+"\n        ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }