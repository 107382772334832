<template>
  <div>
    <ul class="node-tree icon-list">
      <li
        v-for="group in groups"
        :key="group.pk"
      >
        <span class="label">
          <input
            v-if="group.level.level === safetyGroupLevel"
            type="radio"
            :name="groupName"
            :value="group.pk"
            @change="handleMachineGroupChange(group.pk, group.name)"
          />
          <v-btn
            @click="expand(group.pk)"
            :disabled="!group.children || !group.children.length"
          >
            <p>{{ group.name }}</p>
            <i
              v-if="group.children && group.children.length"
              :class="`expand-icon fa fa-chevron-right ${
                isExpanded(group.pk) ? 'expanded' : 'unexpanded'
              }`"
            />
          </v-btn>
        </span>
        <ul v-show="group.children && group.children.length">
          <tree-radio
            v-show="isExpanded(group.pk)"
            :groups="group.children"
            :selected-group="selectedGroup"
            :group-name="groupName"
            :safety-group-level="safetyGroupLevel"
            :handle-machine-group-change="handleMachineGroupChange"
          ></tree-radio>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TreeRadio",
  props: {
    groups: {
      type: Array,
      required: true
    },
    selectedGroup: {
      type: Number,
      default: null
    },
    groupName: {
      type: String,
      default: "group"
    },
    safetyGroupLevel: {
      type: Number,
      required: true
    },
    handleMachineGroupChange: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      expandedGroups: []
    };
  },
  methods: {
    expand(groupPk) {
      if (this.expandedGroups.includes(groupPk)) {
        this.expandedGroups = this.expandedGroups.filter((pk) => pk !== groupPk);
      } else {
        this.expandedGroups.push(groupPk);
      }
    },
    isExpanded(groupPk) {
      return this.expandedGroups.includes(groupPk);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../src/scss/_variables.scss";
@import "../../src/scss/mq";

.icon-list {
  list-style-type: none;
}

.node-tree {
  border-radius: 0;
  margin: 10px;
  padding-left: 10px;

  ul {
    padding-left: 20px;
    margin-left: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  .label {
    flex: unset;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-bottom: 5px;

    .expand-icon {
      transition: rotate 100ms;
      cursor: pointer;
      margin: 2px 10px;

      &.expanded {
        rotate: 90deg;
      }

      &.unexpanded {
        rotate: 0deg;
      }
    }
  }
}

input[type="radio"] {
  margin-right: 1rem;
}
</style>
