<template>
  <div class="error-logs">Error Logs</div>
</template>
<script>
export default {
  name: "ErrorLogs"
};
</script>
<style lang="scss">
@import "./ErrorLogs.scss";
</style>
