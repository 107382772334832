var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-7 ticket-timeline"},[_c('v-timeline',[(_vm.ticket.openedDate)?_c('v-timeline-item',{attrs:{"color":"primary"}},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.moment(_vm.ticket.openedDate).format(_vm.$datetime.default)))]),_vm._v(" "),_c('div',[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Anomaly Detected")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.ticket.openedByName))])])]):_vm._e(),_vm._v(" "),(_vm.ticket.openedDate)?_c('v-timeline-item',{staticClass:"duration",attrs:{"hide-dot":""}},[(_vm.ticket.ticketedDate)?_c('div',{staticClass:"itemDetails"},[_c('p',{staticClass:"duration"},[_vm._v("\n          "+_vm._s(_vm.seconds(
              _vm.moment(_vm.ticket.ticketedDate).diff(_vm.moment(_vm.ticket.openedDate), "seconds"),
              (_vm.precise = "somewhat")
            ))+"\n        ")])]):_vm._e()]):_vm._e(),_vm._v(" "),_c('v-timeline-item',{attrs:{"color":"green"}},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.moment(_vm.ticket.ticketedDate).format(_vm.$datetime.default)))]),_vm._v(" "),_c('div',[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Ticket Created")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.ticket.ticketedByName))])])]),_vm._v(" "),_c('v-timeline-item',{staticClass:"duration",attrs:{"hide-dot":""}},[(_vm.ticket.firstResponseDate)?_c('div',{staticClass:"itemDetails"},[_c('p',{staticClass:"duration"},[_vm._v("\n          "+_vm._s(_vm.seconds(
              _vm.moment(_vm.ticket.firstResponseDate).diff(_vm.moment(_vm.ticket.ticketedDate), "seconds"),
              (_vm.precise = "somewhat")
            ))+"\n        ")])]):_vm._e()]),_vm._v(" "),_c('v-timeline-item',{attrs:{"color":"orange"}},[(_vm.ticket.firstResponseDate)?_c('div',{staticClass:"itemDetails"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.moment(_vm.ticket.firstResponseDate).format(_vm.$datetime.default)))]),_vm._v(" "),_c('div',[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("First Response")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.ticket.firstResponseByName))])])]):_vm._e()]),_vm._v(" "),_c('v-timeline-item',{staticClass:"duration",attrs:{"hide-dot":""}},[(_vm.ticket.firstResponseDate && _vm.ticket.closedDate)?_c('div',{staticClass:"itemDetails"},[_c('p',{staticClass:"duration"},[_vm._v("\n          "+_vm._s(_vm.seconds(
              _vm.moment(_vm.ticket.closedDate).diff(_vm.moment(_vm.ticket.firstResponseDate), "seconds"),
              (_vm.precise = "somewhat")
            ))+"\n        ")])]):_vm._e()]),_vm._v(" "),_c('v-timeline-item',{attrs:{"color":"red"}},[(_vm.ticket.closedDate)?_c('div',{staticClass:"itemDetails"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.moment(_vm.ticket.closedDate).format(_vm.$datetime.default)))]),_vm._v(" "),_c('div',[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Closed")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.ticket.closedByName))])])]):_c('div',{staticClass:"itemButton"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},on:{"input":_vm.clearErrorMessage},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v("\n              "+_vm._s(_vm.$t("Close Ticket"))+"\n            ")])]}}]),model:{value:(_vm.closeTicketDialog),callback:function ($$v) {_vm.closeTicketDialog=$$v},expression:"closeTicketDialog"}},[_vm._v(" "),_c('v-card',[_c('DialogCloseButton',{attrs:{"onClick":function () { return (_vm.closeTicketDialog = false); }}}),_vm._v(" "),_c('v-card-title',{staticClass:"headline"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Close this ticket?")))]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(_vm.ticket.title))]),_vm._v(" "),(_vm.timelineErrorMessage)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.timelineErrorMessage))]):_vm._e()]),_vm._v(" "),_c('v-card-text'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.closeTicketDialog = false}}},[_vm._v("\n                "+_vm._s(_vm.$t("Cancel"))+"\n              ")]),_vm._v(" "),(_vm.closeFunc)?_c('v-btn',{attrs:{"color":"primary","primary":"","text":"","loading":_vm.closingTicket},on:{"click":function($event){return _vm.closeTicket()}}},[_vm._v("\n                "+_vm._s(_vm.$t("OK"))+"\n              ")]):_vm._e()],1)],1)],1)],1)])],1),_vm._v(" "),_c('h3',[_vm._v(_vm._s(_vm.$t("Elapsed Time"))+" : "+_vm._s(_vm.elapsedTime))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }