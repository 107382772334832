<template>
  <div class="issue-summary-cards">
    <div
      class="item issues"
      @click="clickHandler(ISSUE_CARD_TYPES.TOTAL)"
      :class="{ active: openClosedOrTotal === ISSUE_CARD_TYPES.TOTAL }"
    >
      <p class="label">{{ $t("Total Issues") }}</p>
      <span class="total">{{ totalIssues }}</span>
    </div>
    <div
      class="item closed"
      @click="clickHandler(ISSUE_CARD_TYPES.CLOSED)"
      :class="{ active: openClosedOrTotal === ISSUE_CARD_TYPES.CLOSED }"
    >
      <p class="label">{{ $t("Total Closed") }}</p>
      <div class="total-percentage-container">
        <span class="total">{{ totalClosed }}</span>
        <span class="percentage">({{ closedPercentage }}%)</span>
      </div>
    </div>
    <div
      class="item open"
      @click="clickHandler(ISSUE_CARD_TYPES.OPEN)"
      :class="{ active: openClosedOrTotal === ISSUE_CARD_TYPES.OPEN }"
    >
      <p class="label">{{ $t("Total Open") }}</p>
      <div class="total-percentage-container">
        <span class="total">{{ totalOpen }}</span>
        <span class="percentage">({{ openPercentage }}%)</span>
      </div>
    </div>
    <div class="item average-open-time">
      <p class="label">{{ $t("Avg Open Time") }}</p>
      <p class="total">{{ averageOpenTime }}</p>
    </div>
  </div>
</template>
<script>
import { ISSUE_CARD_TYPES } from "@/utils/LDMSAnalyticsUtils";
import { seconds } from "@/utils/filters";

export default {
  name: "LDMSAnalyticsIssueCards",
  props: {
    filters: { type: Object, default: () => {} },
    clickHandler: { type: Function, default: () => null },
    openClosedOrTotal: { type: String, default: () => "" }
  },
  data() {
    return {
      ISSUE_CARD_TYPES: Object.freeze(ISSUE_CARD_TYPES),
      summaryData: {}
    };
  },
  created() {
    this.getPerformanceSummaryData();
  },
  computed: {
    totalIssues() {
      return this.summaryData.total || 0;
    },
    totalClosed() {
      return this.summaryData.closed || 0;
    },
    closedPercentage() {
      if (this.summaryData.total && this.summaryData.closed) {
        return Math.ceil((this.summaryData.closed / this.summaryData.total) * 100);
      }
      return 0;
    },
    totalOpen() {
      return this.summaryData.open || 0;
    },
    openPercentage() {
      if (this.summaryData.total && this.summaryData.open) {
        return Math.ceil((this.summaryData.open / this.summaryData.total) * 100);
      }
      return 0;
    },
    averageOpenTime() {
      if (this.summaryData.avg_open_time) {
        return seconds(
          this.summaryData.avg_open_time,
          this.summaryData.avg_open_time > 59 ? false : true
        );
      }
      return 0;
    }
  },
  methods: {
    async getPerformanceSummaryData() {
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        machine_ids: this.filters.machines,
        part_numbers: this.filters.parts,
        reason_ids: this.filters.reasons
      };
      try {
        const response = await this.$http.get("exceptions/ldms_summary/", { params });
        if (response && response.data) {
          this.summaryData = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="scss"></style>
