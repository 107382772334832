import { useQuery } from "@tanstack/vue-query";
import { helpCenterApi } from "../helpCenterApi";

export const useHelpCenterAudits = (params) => {
  const query = useQuery({
    queryKey: ["helpCenterAudits", params],
    queryFn: () => helpCenterApi.loadAudits(params.value),
    keepPreviousData: true
  });

  return query;
};
