export const AUTOCOMPLETE_ENDPOINT = "lighthouse/api/autocomplete";

export const fetchAutoCompleteChoices = async (client, searchPattern) => {
  try {
    const response = await client.get(AUTOCOMPLETE_ENDPOINT, {
      params: { name: searchPattern }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
