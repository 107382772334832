import { useQuery } from "@tanstack/vue-query";
import { helpCenterApi } from "../helpCenterApi";

export const useHelpCenterDocs = (machine_pk, search) => {
  const query = useQuery({
    queryKey: ["helpCenterDocs", machine_pk, search],
    queryFn: () => helpCenterApi.loadDocs(machine_pk.value, search.value),
    keepPreviousData: true
  });

  return query;
};
