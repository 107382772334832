<template>
  <div class="availabilityMajorLossesTable">
    <generic-table
      :columns="[
        { title: $t('Major Losses'), key: 'name' },
        { title: $t('Actual'), key: 'duration', format: this.seconds }
      ]"
      :data="lossesList"
      :footer="[
        { type: 'csv', csv: downloadCsv },
        { type: 'text', text: this.seconds(sumValue(lossesList, ['duration'])) }
      ]"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { seconds } from "@/utils/filters";
import { sumValue } from "@/utils/sumValue";

export default {
  name: "AvailabilityMajorLosses.vue",
  components: { GenericTable },
  props: ["chartOptions", "production"],
  data() {
    return {
      lossesList: []
    };
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    })
  },
  methods: {
    sumValue,
    seconds,
    createCSVFromRows,
    downloadCsv() {
      const rows = [];
      rows.push([this.$t("Major Losses"), this.$t("Actual (Mins)")]);
      for (const loss of this.lossesList) {
        rows.push([loss.name, Math.round(loss.duration / 60)]);
      }
      this.createCSVFromRows(rows, `majorLosses_${new Date().toISOString()}`);
    }
  },
  created() {
    const statusMap = {};
    for (const status of this.statuses) {
      for (const set of status.statuscodeSet) {
        statusMap[set.code] = {
          ...status,
          ...set
        };
      }
    }

    const lossesBucket = {};
    for (const interval of this.production) {
      for (const status of interval.status_codes.buckets) {
        const code = status.key;
        const duration = status.duration.value;
        //console.log(code, duration);
        const statusInfo = statusMap[code];
        if (!statusInfo || !statusInfo.running) {
          if (lossesBucket[statusInfo ? statusInfo.name : status.key] == null) {
            lossesBucket[statusInfo ? statusInfo.name : status.key] = {
              duration: 0,
              name: statusInfo ? statusInfo.name : status.key //statusInfo.name
            };
          }
          lossesBucket[statusInfo ? statusInfo.name : status.key].duration += duration;
        }
      }
    }
    for (const loss of Object.keys(lossesBucket)) {
      this.lossesList.push(lossesBucket[loss]);
    }

    this.lossesList.sort((a, b) => {
      return b.duration - a.duration;
    });
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.availabilityMajorLossesTable {
  flex: 1;
  display: flex;
  flex-direction: column;

  .v-data-table__wrapper {
    overflow-x: hidden;
  }

  thead {
    tr {
      white-space: nowrap;
    }
  }

  tbody {
    overflow: unset;
  }

  td {
    text-align: left !important;
  }

  .badColor {
    background: $green;
  }

  .goodColor {
    background: $red;
  }

  tfoot {
    position: sticky;
    bottom: 0;

    .theme--dark & {
      background: $grey;
    }

    .theme--light & {
      background: $light-background;
    }
  }
}
</style>
