<template>
  <v-card>
    <v-card-text class="text-h5 text-center error">
      <span v-if="error === 404">
        {{ $t("Optimization model does not exist.") }}
      </span>
      <span v-else>
        {{ $t("Something went wrong. Please refresh the page.") }}
      </span>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "ModelOptimizationAboutError",
  props: ["error"]
};
</script>
