import Vue from "vue";

import { md5 } from "./md5";

export default function () {
  Vue.prototype.$colors = {
    blue: "#44B2F3",
    chart: {
      neutral_gradient: ["#006370", "#0097A7"],
      good_gradient: ["#1DE9B6", "#0097A7"],
      good_gradient_light: ["rgba(29,233,182,0.2)", "rgba(0,151,167,0.2)"],
      bad_gradient: ["#F86872", "#F48FB1"],
      bad_gradient_light: ["rgba(248,104,114,0.2)", "rgba(244,143,177,0.2)"],
      good: "#00BC6C",
      bad: "#EA4D55"
    }
  };
}

export function randomSaturation(color, string, saturationRange, alpha = 1) {
  let rnd = Math.random();
  if (string) {
    const hashCode = string.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

    //we're just expecting a decimal portion
    const decimal = hashCode / 100;
    rnd = decimal - Math.floor(decimal);
  }
  const hsl = hexTohsl(color);
  const saturation = rnd * (saturationRange.max - saturationRange.min) + saturationRange.min;
  return hslToHex(hsl.h * 360, saturation, hsl.l * 100, alpha);
}

export function randomColor(
  string = null,
  alpha = 100,
  baseColorHex = "#f07165",
  range = { start: 25, end: 330 }
) {
  let rnd = Math.random();

  let s_val = 100;
  let l_val = 80;
  if (baseColorHex) {
    const v = hexTohsl(baseColorHex);
    s_val = v.s * 100;
    l_val = v.l * 100;
  }

  if (string) {
    const hash = md5(string);
    const hashCode = hash.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

    //we're just expecting a decimal portion
    const decimal = hashCode / 100;
    rnd = decimal - Math.floor(decimal);
  }

  const hue = (Math.floor(rnd * 360) % (range.end - range.start)) + range.start;
  return `hsl(${hue}, ${s_val}%, ${l_val}%, ${alpha}%)`;
}

export function hslaToRgba(hsla) {
  hsla = hsla.substring(4).split(")")[0].split(",");
  let h = hsla[0];
  let s = hsla[1].substring(0, hsla[1].length - 1) / 100;
  let l = hsla[2].substring(0, hsla[2].length - 1) / 100;
  let a = hsla[3].substring(0, hsla[3].length - 1) / 100;
  if (h >= 360) {
    h %= 360;
  }
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);
  return `rgba(${r},${g},${b},${a === 1 ? "0.9" : a.toFixed(1)})`;
}

export function hexToRGB(hex, alpha) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return "rgb(0,0,0)";
  }

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return `rgba(${r},${g},${b},${alpha})`;
}

function hslToHex(h, s, l, alpha) {
  const alphaHex = Math.floor(alpha * 255).toString(16);
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}${alphaHex}`;
}

function hexTohsl(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);

  let h,
    s,
    l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        {
          h = (g - b) / d + (g < b ? 6 : 0);
        }
        break;
      case g:
        {
          h = (b - r) / d + 2;
        }
        break;
      case b:
        {
          h = (r - g) / d + 4;
        }
        break;
    }
    h /= 6;
  }

  return { h: h, s: s, l: l };
}

export function PickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
  if (bgColor) {
    let color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB
    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
  } else {
    return lightColor;
  }
}
