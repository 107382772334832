<template>
  <div class="application-monitoring">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "ApplicationMonitoring"
};
</script>
<style lang="scss">
@import "./ApplicationMonitoring.scss";
</style>
