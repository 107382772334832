<template>
  <div style="height: 100%; width: 100%">
    <div
      ref="minimap"
      style="height: 90%; width: 90%"
      class="mx-auto"
    ></div>
  </div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
  name: "MiniMap",
  props: {
    selectedRegion: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      miniMap: null
    };
  },
  mounted() {
    this.createMiniMap();
  },
  beforeUnmount() {
    if (this.miniMap) {
      this.miniMap.dispose();
    }
  },
  methods: {
    createMiniMap() {
      let root = am5.Root.new(this.$refs.minimap);
      root.setThemes([am5themes_Animated.new(root)]);
      let chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: "none",
          panY: "none",
          wheelY: "none",
          projection: am5map.geoEqualEarth(),
          zoomLevel: 0.5
        })
      );
      const excluded = this.selectedRegion.title === "Asia" ? ["RU"] : [];
      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: this.selectedRegion.regionGeoData,
          exclude: excluded,
          fill: am5.color(0x7a787d)
        })
      );
      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}",
        interactive: true
      });
      polygonSeries.mapPolygons.template.states.create("hover", {
        fill: am5.color(0x44b2f3)
      });
      setTimeout(function () {
        chart.goHome(400);
      }, 10);
      this.miniMap = chart;
    }
  }
};
</script>

<style scoped></style>
