<template>
  <Fragment>
    <slot
      name="edge"
      v-if="user.useDevelopmentEdge"
    />
    <slot
      name="stable"
      v-if="!user.useDevelopmentEdge"
    />
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { mapGetters } from "vuex";

export default {
  components: {
    Fragment
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    })
  }
};
</script>
