<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { onBeforeUnmount, onMounted, provide, ref } from "@vue/composition-api";
import {
  useIrisIngestionMarkFailedMutation,
  useIrisIngestionUploadCompleteMutation,
  useIrisIngestionUploadMutation
} from "./hooks/useIrisIngestionFilesMutation";
import {
  APPLY_UPPY_MUTATION,
  MAX_FILES_SIZE_GB,
  POSSIBLE_UPLOAD_STATUSES,
  UPLOAD_STATUSES,
  UPPY
} from "./config";

export default {
  setup() {
    const uppy = ref(null);
    const filesToUpload = ref([]);
    const uploadStatuses = ref([]);

    const uploadMutation = useIrisIngestionUploadMutation();
    const completeMutation = useIrisIngestionUploadCompleteMutation();
    const markFailedMutation = useIrisIngestionMarkFailedMutation();

    const onUploadSuccess = (mutationData) => {
      mutationData.documents.forEach((doc, index) => {
        const file = filesToUpload.value[index];
        uppy.value.addFile({
          name: doc.s3_path,
          type: file.type,
          data: new Blob([file], { type: file.type }),
          meta: {
            presignedUrl: doc.presigned_url,
            documentId: doc.id,
            uploadId: doc.upload_id,
            s3Path: doc.s3_path,
            multipart: doc.multipart,
            numberOfParts: doc.number_of_parts
          }
        });
        uploadStatuses.value.push({ id: doc.id, status: POSSIBLE_UPLOAD_STATUSES.initiated });
      });

      uppy.value.upload();
    };

    const applyUppyMutation = (data, files) => {
      filesToUpload.value = files;
      uploadMutation.mutate(data, { onSuccess: onUploadSuccess });
    };

    onMounted(() => {
      uppy.value = new Uppy({
        id: "irisUpload",
        autoProceed: false,
        debug: true,
        restrictions: {
          maxTotalFileSize: MAX_FILES_SIZE_GB * Math.pow(1024, 3),
          allowedFileTypes: [".pdf", ".csv", ".xlsx", ".xls"]
        }
      })
        .use(AwsS3, {
          getUploadParameters(file) {
            return {
              method: "PUT",
              url: file.meta.presignedUrl,
              headers: {
                "Content-Type": file.type
              },
              fields: {}
            };
          }
        })
        .on("upload-progress", (file) => {
          uploadStatuses.value.map((doc) => {
            if (doc.id === file.meta.documentId) {
              doc.status = POSSIBLE_UPLOAD_STATUSES.uploadProgress;
            }
          });
        })
        .on("upload-success", (file) => {
          uploadStatuses.value.map((doc) => {
            if (doc.id === file.meta.documentId) {
              doc.status = POSSIBLE_UPLOAD_STATUSES.uploadSuccess;
            }
          });
        })
        .on("upload-error", (file) => {
          uploadStatuses.value.map((doc) => {
            if (doc.id === file.meta.documentId) {
              doc.status = POSSIBLE_UPLOAD_STATUSES.uploadFailed;
              //report backend about the failed upload
              markFailedMutation.mutate(file.meta.documentId);
            }
          });
        })
        .on("complete", (result) => {
          if (result.successful.length) {
            // eslint-disable-next-line no-console
            console.log("Uppy upload success", result.successful);
            result.successful.forEach((doc) => {
              //report to the backend about successful uploading
              completeMutation.mutate(doc.meta.documentId);
              //remove file that has already been uploaded
              uppy.value.removeFile(doc.id);
            });
          } else {
            // eslint-disable-next-line no-console
            console.log("Uppy upload failed", result.failed);
            result.failed.forEach((doc) => {
              uppy.value.removeFile(doc.id);
            });
          }
        });
    });

    onBeforeUnmount(() => {
      uppy.value.close();
    });

    provide(UPPY, uppy);
    provide(APPLY_UPPY_MUTATION, applyUppyMutation);
    provide(UPLOAD_STATUSES, uploadStatuses);
  }
};
</script>

<style scoped lang="scss"></style>
