<template>
  <div
    class="production-reports-chart-loading"
    v-if="isLoading"
  >
    <div class="card loading-card">
      <v-progress-linear indeterminate />
    </div>
  </div>
  <div
    class="production-reports-chart-loading"
    v-else-if="isLoading === null"
  >
    <div class="card loading-card">
      {{ $t("No Data") }}
    </div>
  </div>
  <div
    class="production-reports-chart"
    v-else-if="!isLoading"
  >
    <div class="card graph-card summary-graph-card">
      <summary-table
        :production="production"
        :machineGroupData="machineGroupData"
        :filters="filtersCopy"
        :options="chartOptions"
        :setFilters="setFilters"
        :filterHistory="filterHistory"
        :popFilterHistory="popFilterHistory"
        :chartType="chartType"
        :set-summary-chart-type="setSummaryChartType"
        :setInteractiveFilter="setInteractiveFilter"
        :interactiveFilters="interactiveFilters"
      />
    </div>

    <div class="card graph-card">
      <div class="toggleIcons">
        <i
          class="fa fa-bar-chart"
          v-bind:class="oee_type == 'graph' ? 'selected' : null"
          @click="oee_type = 'graph'"
        />
        <i
          class="fa fa-table"
          v-bind:class="oee_type == 'table' ? 'selected' : null"
          @click="oee_type = 'table'"
        />
      </div>
      <div class="graph-card-content">
        <h2>{{ $t("OEE") }}</h2>
        <o-e-e-hour-by-hour
          v-if="chartOptions"
          :options="chartOptions"
          :filters="filters"
          :interactiveFilters="interactiveFilters"
          :type="oee_type"
        />
      </div>
    </div>

    <div class="card graph-card">
      <div class="toggleIcons">
        <i
          class="fa fa-bar-chart"
          v-bind:class="performance_type == 'graph' ? 'selected' : null"
          @click="performance_type = 'graph'"
        />
        <i
          class="fa fa-table"
          v-bind:class="performance_type == 'table' ? 'selected' : null"
          @click="performance_type = 'table'"
        />
      </div>
      <div class="graph-card-content">
        <h2>{{ $t("Performance") }}</h2>
        <production-quantity
          v-if="production && chartOptions && performance_type == 'graph'"
          :options="chartOptions"
          :production="production"
          :interactiveFilters="interactiveFilters"
        />
        <performance-quantity-table
          v-if="production && chartOptions && performance_type == 'table'"
          :chartOptions="chartOptions"
          :production="production"
          :filters="filtersCopy"
          :interactiveFilters="interactiveFilters"
        />
      </div>
    </div>
    <div class="card graph-card">
      <div class="toggleIcons">
        <i
          class="fa fa-bar-chart"
          v-bind:class="quality_type == 'graph' ? 'selected' : null"
          @click="quality_type = 'graph'"
        />
        <i
          class="fa fa-table"
          v-bind:class="quality_type == 'table' ? 'selected' : null"
          @click="quality_type = 'table'"
        />
      </div>
      <div class="graph-card-content">
        <h2>{{ $t("Quality") }}</h2>
        <quality-quantity
          v-if="production && chartOptions && quality_type == 'graph'"
          :options="chartOptions"
          :production="production"
          :interactiveFilters="interactiveFilters"
        />
        <div
          class="qualityTables"
          v-if="production && chartOptions && quality_type == 'table'"
        >
          <quality-quantity-table
            class="card qualityQuantityTableContainer"
            :chartOptions="chartOptions"
            :production="production"
            :filters="filtersCopy"
            :interactiveFilters="interactiveFilters"
          />
          <scrap-reason-table
            class="card scrapReasonTableContainer"
            :filters="filtersCopy"
            :interactiveFilters="interactiveFilters"
          />
        </div>
      </div>
    </div>
    <div class="card graph-card">
      <div class="toggleIcons">
        <i
          class="fa fa-bar-chart"
          v-bind:class="availability_type == 'graph' ? 'selected' : null"
          @click="availability_type = 'graph'"
        />
        <i
          class="fa fa-table"
          v-bind:class="availability_type == 'table' ? 'selected' : null"
          @click="availability_type = 'table'"
        />
      </div>
      <div class="graph-card-content">
        <h2>{{ $t("Availability") }}</h2>
        <availability-quantity
          v-if="production && chartOptions && availability_type == 'graph'"
          :options="chartOptions"
          :production="production"
          :interactiveFilters="interactiveFilters"
        />
        <div
          class="availabilityTables"
          v-if="production && chartOptions && availability_type == 'table'"
        >
          <availability-hour-by-hour
            class="card availablityTableHourByHour"
            :chart-options="chartOptions"
            :production="production"
            :interactiveFilters="interactiveFilters"
          />
          <availability-major-losses
            class="card availablityTableMajorLosses"
            :chart-options="chartOptions"
            :production="production"
            :interactiveFilters="interactiveFilters"
          />
          <availability-reason-code
            class="card availabilityTableReasonCode"
            :chart-options="chartOptions"
            :production="production"
            :interactiveFilters="interactiveFilters"
          />
        </div>
      </div>
    </div>
    <div class="card graph-card">
      <div class="toggleIcons">
        <i
          class="fa fa-bar-chart"
          v-bind:class="runtime_type === 'graph' ? 'selected' : null"
          @click="runtime_type = 'graph'"
        />
        <i
          class="fa fa-table"
          v-bind:class="runtime_type === 'table' ? 'selected' : null"
          @click="runtime_type = 'table'"
        />
      </div>
      <div class="graph-card-content">
        <h2>{{ $t("Runtime") }}</h2>
        <runtime-quantity
          v-if="production && chartOptions && runtime_type === 'graph'"
          :options="chartOptions"
          :production="production"
          :interactiveFilters="interactiveFilters"
        />
        <runtime-quantity-table
          v-if="production && chartOptions && runtime_type === 'table'"
          :chartOptions="chartOptions"
          :production="production"
          :interactiveFilters="interactiveFilters"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { chartOptions } from "@/components/charts/chartOptions";
import Availability from "@/components/metrics/Availability";
import AvailabilityQuantity from "@/components/metrics/AvailabilityQuantity";
import PerformanceQuantityTable from "@/components/metrics/PerformanceQuantityTable";
import ProductionQuantity from "@/components/metrics/ProductionQuantity";
import QualityQuantity from "@/components/metrics/QualityQuantity";
import QualityQuantityTable from "@/components/metrics/QualityQuantityTable";
import RuntimeQuantity from "@/components/metrics/RuntimeQuantity";
import RuntimeQuantityTable from "@/components/metrics/RuntimeQuantityTable";
import { numberWithCommas } from "@/utils/filters";
import { getDateFormatter } from "@/utils/getDateFormatter";

import AvailabilityHourByHour from "./AvailabilityHourByHour";
import AvailabilityMajorLosses from "./AvailabilityMajorLosses";
import AvailabilityReasonCode from "./AvailabilityReasonCode";
import OEEHourByHour from "./OEEHourByHour";
import ScrapReasonTable from "./ScrapReasonTable";
import SummaryTable, { SUMMARYCHARTTYPE } from "./SummaryTable";

export default {
  name: "ProductionReportsCharts.vue",
  components: {
    OEEHourByHour,
    AvailabilityReasonCode,
    AvailabilityMajorLosses,
    AvailabilityHourByHour,
    AvailabilityQuantity,
    Availability,
    ScrapReasonTable,
    QualityQuantityTable,
    QualityQuantity,
    PerformanceQuantityTable,
    SummaryTable,
    ProductionQuantity,
    RuntimeQuantity,
    RuntimeQuantityTable
  },
  props: ["filters"],
  data() {
    return {
      production: null,
      chartOptions: {},
      performance_type: "graph",
      quality_type: "graph",
      availability_type: "graph",
      oee_type: "graph",
      runtime_type: "graph",
      machineGroupData: [],
      isLoading: null,
      filtersCopy: {},
      filterHistory: [],
      chartType: SUMMARYCHARTTYPE.MACHINE,
      interactiveFilters: { machines: [], parts: [] }
    };
  },
  mounted() {
    this.filterHistory = [];
    this.filtersCopy = { ...this.filters };
    this.loadData();
  },
  computed: {},
  methods: {
    getDateFormatter,
    numberWithCommas,
    setInteractiveFilter(filter, filterType) {
      switch (filterType) {
        case SUMMARYCHARTTYPE.MACHINE: {
          const index = this.interactiveFilters.machines.indexOf(filter);
          if (index >= 0) {
            this.interactiveFilters.machines.splice(index, 1);
          } else {
            this.interactiveFilters.machines.push(filter);
          }
          break;
        }
        case SUMMARYCHARTTYPE.PART: {
          const index = this.interactiveFilters.parts.indexOf(filter);
          if (index >= 0) {
            this.interactiveFilters.parts.splice(index, 1);
          } else {
            this.interactiveFilters.parts.push(filter);
          }
          break;
        }
      }

      this.interactiveFilters = { ...this.interactiveFilters };
    },
    setSummaryChartType(chartType) {
      //if we switched chart types, wipe our interactive filters
      if (this.chartType != chartType) {
        this.interactiveFilters = { machines: [], parts: [] };
      }
      this.chartType = chartType;
    },
    popFilterHistory() {
      const history = this.filterHistory.pop();
      this.filtersCopy = { ...history.filters };
      this.loadData();
    },
    setFilters(newFilters, name) {
      this.filterHistory.push({ name: name, filters: { ...this.filtersCopy } });
      this.filtersCopy = { ...this.filtersCopy, ...newFilters };
      this.loadData();
    },
    loadData() {
      this.isLoading = null;
      this.production = null;
      this.chartOptions = [];
      const {
        scale,
        from_date,
        to_date,
        zone,
        machine,
        part,
        shift,
        machineData,
        reportZoneLevel
      } = this.filtersCopy;
      let { dateFormat, scaleString } = this.getDateFormatter(scale);

      //it's possible we don't have a valid shift on load
      //if a report zone level is set, use this to determine where we should start
      if (from_date && to_date && scale) {
        this.isLoading = true;

        let params = {
          scale: scaleString,
          from_date: from_date.toISOString(),
          to_date: to_date.toISOString()
        };

        if (machine && machine.length > 0) {
          params.machine_id = machine;
        }

        if (zone && zone.length > 0) {
          params.machine_group_id = zone;
        }

        if (part) {
          params.part_numbers = part;
        }
        if (shift) {
          params.shift_ids = shift;
        }

        //we might need to drill up and down
        if (reportZoneLevel && reportZoneLevel.level) {
          //Select one level above our currently selected level
          //this might need to be a separate endpoint
          const zoneLevel = reportZoneLevel.level;
          params.group_level = zoneLevel;
        }

        this.chartOptions = new chartOptions();
        this.chartOptions.scale = scaleString;
        this.chartOptions.dateFormat = dateFormat;

        if (machine && machine.pk) {
          //our filters have a list of machines with targets in them
          //filter on the selected machine, and grab the only element.
          //use that to set the filters appropriately
          const filteredMachine = machines.filter((m) => m.pk == machine.pk);

          if (filteredMachine.length == 1) {
            this.chartOptions.targets.perf = filteredMachine[0].performanceTarget;
            this.chartOptions.targets.quality = filteredMachine[0].qualityTarget;
            this.chartOptions.targets.runtime = filteredMachine[0].runtimeTarget;
          }
        }

        const performanceTargets = {};
        const qualityTargets = {};
        const runtimeTargets = {};

        for (const m of machineData) {
          performanceTargets[m.pk] = m.performanceTarget;
          qualityTargets[m.pk] = m.qualityTarget;
          runtimeTargets[m.pk] = m.runtimeTarget;
        }

        //store your target per machine
        this.chartOptions.targets.perfByMachine = performanceTargets;
        this.chartOptions.targets.qualityByMachine = qualityTargets;
        this.chartOptions.targets.runtimeTargets = runtimeTargets;

        this.$http.get("metrics/production_interval_part/", { params: params }).then((res) => {
          this.production = res.data.interval;
          this.machineGroupData = res.data.machine_group;
          this.isLoading = false;
        });
      }
    }
  },
  watch: {
    filters: function () {
      this.filtersCopy = { ...this.filters };
      //the parent sent a new filter
      //clear the history, we're starting from scratch.
      this.filterHistory = [];
      this.loadData();
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.production-reports-chart-loading {
  padding: 0 10px;
  .loading-card {
    padding: 10px;
    height: 100%;
  }
}

.production-reports-chart {
  padding: 2px 15px;
  margin-top: -2px;
  overflow: scroll;

  h2 {
    color: $blue !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    padding-top: 0 !important;
    padding-bottom: 5px;

    &.summaryProduction {
      padding-top: 10px !important;
    }

    &.card-title {
      display: none;
    }
  }

  .qualityTables {
    display: flex;
    flex: 1;
    overflow-y: hidden;

    .qualityQuantityTableContainer {
      flex: 2;
      margin: 4px 8px;
      padding: 3px;
    }

    .scrapReasonTableContainer {
      flex: 1;
      margin: 4px 8px;
      padding: 3px;
    }
  }

  .flex-table {
    flex: 1;

    tbody > tr > td:first-child {
      color: $blue;
    }
    thead {
      & > tr > td {
        border-top: solid $white 2px !important;
      }
    }
    tfoot {
      & > tr > td {
        font-weight: bold;
        border-top: solid $darkGrey 2px !important;

        &:first-child {
          color: $blue;
        }
      }
    }
  }

  .availability-chart,
  .quality-chart,
  .production-chart {
    padding: 0;
    overflow: hidden;
  }

  .availabilityTables {
    display: flex;
    flex: 1;
    overflow-y: hidden;

    .availablityTableHourByHour {
      flex: 5;
      margin: 4px 8px;
      padding: 3px;
    }

    .availablityTableMajorLosses {
      flex: 3;
      margin: 4px 8px;
      padding: 3px;
    }

    .availabilityTableReasonCode {
      flex: 3;
      margin: 4px 8px;
      padding: 3px;
    }
  }

  .tableDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  .production_table {
    margin-top: 5px;
    flex: 1;
    overflow-y: auto;

    tbody > tr > td:first-child {
      color: $blue;
    }
    tfoot {
      & > tr > td {
        font-weight: bold;
        border-top: solid $darkGrey 2px !important;

        &:first-child {
          color: $blue;
        }
      }
    }

    td {
      text-align: left !important;
    }

    tfoot {
      position: sticky;
      bottom: 0;

      .theme--dark & {
        background: $grey;
      }

      .theme--light & {
        background: $light-background;
      }
    }
  }

  .graph-card {
    height: 400px;
    margin-bottom: 15px;
    padding: 10px;
    display: flex;

    &.summary-graph-card {
      height: 682px;
    }

    .graph-card-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .metric-component {
      flex: 1;
      display: flex;
      flex-direction: column;

      .charts {
        justify-content: left !important;

        .bar-container {
          padding: 0 20px !important;
          align-items: baseline;
          display: flex;
          flex-direction: column;

          .availabilityChart {
            flex: 1;
          }

          .qualityChart {
            flex: 1;
          }
        }
      }
      //fix for production chart
      //should fix this in the component.
      .charts {
        display: flex;
        .charts_container {
          flex: 1;
          height: 100%;
          .chart {
            height: unset;
            .productionChart {
              height: 100%;
            }
          }
        }
      }
    }

    .toggleIcons {
      position: absolute;
      right: 30px;
      top: 5px;
      font-size: 20px;

      i {
        padding: 0 3px;
        cursor: pointer;

        &.selected {
          color: $blue;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
