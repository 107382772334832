<template>
  <div class="issue-summary-cards">
    <div
      class="item issues"
      @click="clickHandler(ISSUE_CARD_TYPES.TOTAL)"
      :class="{ active: openClosedOrTotal === ISSUE_CARD_TYPES.TOTAL }"
    >
      <p class="label">{{ $t("Total Issues") }}</p>
      <span class="total">{{ totalIssues }}</span>
    </div>
    <div
      class="item closed"
      @click="clickHandler(ISSUE_CARD_TYPES.CLOSED)"
      :class="{ active: openClosedOrTotal === ISSUE_CARD_TYPES.CLOSED }"
    >
      <p class="label">{{ $t("Total Closed") }}</p>
      <div class="total-percentage-container">
        <span class="total">{{ totalClosed }}</span>
        <span class="percentage">({{ closedPercentage }}%)</span>
      </div>
    </div>
    <div
      class="item open"
      @click="clickHandler(ISSUE_CARD_TYPES.OPEN)"
      :class="{ active: openClosedOrTotal === ISSUE_CARD_TYPES.OPEN }"
    >
      <p class="label">{{ $t("Total Open") }}</p>
      <div class="total-percentage-container">
        <span class="total">{{ totalOpen }}</span>
        <span class="percentage">({{ openPercentage }}%)</span>
      </div>
    </div>
    <div class="item average-response-time">
      <p class="label">{{ $t(averageTimeLabel) }}</p>
      <p class="total">{{ averageResponseTime }}</p>
    </div>
  </div>
</template>
<script>
import { seconds } from "@/utils/filters";
import { ISSUE_CARD_TYPES } from "@/utils/p911AnalyticsUtils";

export default {
  name: "P911AnalyticsIssueCards",
  props: {
    filters: { type: Object, default: () => {} },
    clickHandler: { type: Function, default: () => null },
    openClosedOrTotal: { type: String, default: () => "" },
    averageTimeLabel: { type: String, default: "Avg Response Time" }
  },
  data() {
    return {
      ISSUE_CARD_TYPES: Object.freeze(ISSUE_CARD_TYPES),
      summaryData: {}
    };
  },
  created() {
    this.getSummaryData();
  },
  computed: {
    totalIssues() {
      return "open" in this.summaryData && "closed" in this.summaryData
        ? this.summaryData.open + this.summaryData.closed
        : 0;
    },
    totalClosed() {
      return this.summaryData.closed || 0;
    },
    closedPercentage() {
      return "open" in this.summaryData && "closed" in this.summaryData
        ? this.summaryData.open + this.summaryData.closed !== 0
          ? Math.ceil(
              (this.summaryData.closed / (this.summaryData.open + this.summaryData.closed)) * 100
            )
          : 0
        : 0;
    },
    totalOpen() {
      return this.summaryData.open || 0;
    },
    openPercentage() {
      return "open" in this.summaryData && "closed" in this.summaryData
        ? this.summaryData.open + this.summaryData.closed !== 0
          ? Math.ceil(
              (this.summaryData.open / (this.summaryData.closed + this.summaryData.open)) * 100
            )
          : 0
        : 0;
    },
    averageResponseTime() {
      return seconds(
        this.summaryData.avg_response_time,
        this.summaryData.avg_response_time > 59 ? false : true
      );
    }
  },
  methods: {
    async getSummaryData() {
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        machine_ids: this.filters.machines ? this.filters.machines.map((machine) => machine.pk) : []
      };
      try {
        const response = await this.$http.get("help/summary/", { params });
        if (response && response.data) {
          this.summaryData = {
            ...response.data,
            open: response.data.open.doc_count,
            closed: response.data.closed.doc_count,
            avg_response_time: response.data.avg_response_time.value
          };
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  watch: {
    filters() {
      if (!this.filters.fromDate || !this.filters.toDate) {
        return;
      }
      this.getSummaryData();
    }
  }
};
</script>
<style lang="scss"></style>
