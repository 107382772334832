<template>
  <div class="ai-vision-list-view">
    <div class="top-cards">
      <div class="summary-card total-processed">
        <div>
          <h3>{{ $t("Total Processed (pcs)") }}</h3>
        </div>
        <div class="value">
          <h1 class="right">{{ numPass + numFail }}</h1>
        </div>
      </div>
      <div class="summary-card total-passed">
        <div>
          <h3>{{ $t("Total Passed (pcs)") }}</h3>
        </div>
        <div class="value">
          <h1 class="right">{{ numPass }}</h1>
        </div>
      </div>
      <div class="summary-card total-failed">
        <div>
          <h3>{{ $t("Total Failed (pcs)") }}</h3>
        </div>
        <div class="value">
          <h1 class="right">{{ numFail }}</h1>
        </div>
      </div>
      <div class="summary-card first-pass-yield">
        <div>
          <h3>{{ $t("First Pass Yield") }}</h3>
        </div>
        <div class="value">
          <h1 class="right">
            {{
              numPass + numFail !== 0
                ? `${((numPass / (numPass + numFail)) * 100).toFixed(2)}%`
                : "---"
            }}
          </h1>
        </div>
      </div>
    </div>
    <div class="bottom-table">
      <generic-table
        :columns="[
          { title: $t('Time'), key: 'time_stamp', format: formatDate },
          { title: $t('Model Name'), key: 'part_name' },
          { title: $t('Model Version'), key: 'model_version_number' },
          { title: $t('Result'), key: 'inspection_result' },
          { title: $t('Inferencing Time (secs)'), key: 'inferencing_time' },
          { title: $t('Accuracy'), key: 'confidence', format: (val) => val.toFixed(2) },
          { title: $t('Image Link'), html: 'image_location_html', click: openImage }
        ]"
        :data="data"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import GenericTable from "../../components/GenericTable";

export default {
  name: "AiVisionListView",
  props: ["documents", "openImage", "filters"],
  data() {
    return {
      data: [],
      numPass: 0,
      numFail: 0
    };
  },
  created() {
    this.setupData();
    this.getSummary();
  },
  components: {
    GenericTable
  },
  methods: {
    moment,
    formatDate(time_stamp) {
      return this.moment(time_stamp).format(this.$datetime.iot);
    },
    setupData() {
      this.data = [];
      for (const d of this.documents) {
        const html = d._source.image_location ? `<i class="fa fa-image"/>` : null;
        d._source.image_location_html = html;
        this.data.push(d._source);
      }
    },
    getSummary() {
      const { assetId, groupId } = this.$router.history.current.params;
      const query = {
        ...this.filters,
        location: groupId,
        asset_id: assetId
      };
      this.numFail = 0;
      this.numPass = 0;
      this.$http.get("aivision/failsummary/", { params: query }).then((result) => {
        for (const b of result.data.buckets) {
          if (b.key.toLowerCase() === "pass") {
            this.numPass = b.count.value;
          } else {
            this.numFail = b.count.value;
          }
        }
      });
    }
  },
  watch: {
    documents: function () {
      this.setupData();
      this.getSummary();
    }
  }
};
</script>

<style scoped lang="scss">
.ai-vision-list-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .top-cards {
    height: 90px !important;
    flex: unset;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;

    .summary-card {
      border-radius: 5px;
      color: black;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 90px;
      flex: 1;
      div {
        flex: 1;
      }

      h1 {
        font-size: 22px;
      }

      .value {
        flex: unset;
        bottom: 5px;
        right: 10px;
      }

      .right {
        text-align: right;
      }

      &.total-processed {
        background: rgb(254, 217, 182);
      }
      &.total-passed {
        background: rgb(0, 174, 184);
      }
      &.total-failed {
        background: rgb(240, 113, 102);
      }
      &.first-pass-yield {
        background: rgb(0, 129, 166);
      }
    }
  }

  .bottom-table {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
