<template>
  <div
    :style="`background: ${color}`"
    class="summary-card"
  >
    <div>
      <h3>{{ name }}</h3>
      <h4>{{ subtitle }}</h4>
    </div>
    <div class="value">
      <h1 class="right">{{ calculateValue() }}</h1>
    </div>
  </div>
</template>
<script>
import { numberWithCommas, seconds } from "@/utils/filters";

export default {
  name: "summaryTLACard",
  props: ["name", "subtitle", "value", "color", "summary", "calculation"],
  methods: {
    calculateValue() {
      if (!this.summary.duration) {
        return 0;
      }

      switch (this.calculation) {
        case "totalDuration":
          {
            return seconds(Math.round(this.summary.duration.value));
          }
          break;
        case "totalRuntime":
          {
            return seconds(Math.round(this.summary.runtime.duration.value));
          }
          break;
        case "totalDowntime":
          {
            const duration = this.summary.duration.value;
            const runtime = this.summary.runtime.duration.value;
            return seconds(Math.round(duration - runtime));
          }
          break;
        case "assetUtilization":
          {
            const duration = this.summary.duration.value;
            const runtime = this.summary.runtime.duration.value;
            if (duration === 0) {
              return "0%";
            }
            return `${numberWithCommas(((runtime / duration) * 100).toFixed(2))}%`;
          }
          break;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.summary-card {
  border-radius: 5px;
  height: 100%;
  color: black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  div {
    flex: 1;
  }

  h1 {
    font-size: 22px;
  }

  .value {
    flex: unset;
    position: absolute;
    bottom: 5px;
    right: 10px;
  }

  .right {
    text-align: right;
  }
}
</style>
