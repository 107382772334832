<template>
  <div class="d-flex mt-5 mb-4 align-end justify-space-between">
    <div v-if="!$slots.leftNavigation">
      <btn-toggle :value="activeTab">
        <Btn
          text
          class="text-uppercase text-button type-button"
          :value="MACHINE_TABS.GRID"
          @click="onClickTab(MACHINE_TABS.GRID)"
        >
          {{ $t("Grid") }}
          <v-icon
            size="20"
            class="ml-1 font-weight-bold type-button-icon"
            >mdi-table</v-icon
          >
        </Btn>
        <Btn
          text
          class="text-uppercase text-button type-button"
          :value="MACHINE_TABS.CHARTS"
          @click="onClickTab(MACHINE_TABS.CHARTS)"
        >
          {{ $t("Chart") }}
          <v-icon
            size="20"
            class="ml-1 font-weight-bold"
            >mdi-chart-line</v-icon
          >
        </Btn>
      </btn-toggle>
    </div>
    <div
      v-else
      class="d-flex align-end"
    >
      <slot name="leftNavigation"></slot>
    </div>

    <div
      v-if="onInputSearch"
      class="ml-6 flex-grow-1"
    >
      <SearchField :on-input-search="onInputSearch" />
    </div>

    <div class="d-flex align-center gap-2">
      <slot name="leftBlock"></slot>

      <btn
        v-if="onClickUpdate"
        class="mr-3"
        large
        @click="onClickUpdate"
      >
        <v-icon
          size="20"
          class="mr-1"
          >mdi-update</v-icon
        >{{ $t("Update") }}</btn
      >
      <btn
        v-if="onClickDownload"
        color="primary"
        class="mr-3"
        large
        @click="onClickDownload"
      >
        <v-icon
          size="20"
          class="mr-1"
          >mdi-file-download-outline</v-icon
        >
        {{ $t("CSV") }}
      </btn>

      <v-badge
        v-if="onClickFilters"
        :content="activeFiltersLength"
        :value="activeFiltersLength"
        overlap
      >
        <btn
          large
          @click="onClickFilters"
        >
          <v-icon
            size="20"
            class="mr-1"
            >mdi-filter-variant</v-icon
          >
          {{ $t("Filters") }}
        </btn>
        <template #badge>
          <span class="badge font-weight-medium color--text-black">{{ activeFiltersLength }}</span>
        </template>
      </v-badge>

      <slot name="rightBlock"></slot>
    </div>
  </div>
</template>

<script>
import Btn from "@/ui/Btn/Btn.vue";
import { MACHINE_TABS } from "@/features/machine/constants";
import BtnToggle from "@/components/BtnToggle.vue";
import SearchField from "@/ui/Search/SearchField.vue";

export default {
  name: "MachineTableControl",
  components: {
    SearchField,
    Btn,
    BtnToggle
  },
  slots: ["leftBlock", "rightBlock", "leftNavigation"],
  props: {
    onClickFilters: { type: Function, default: undefined },
    onClickDownload: { type: Function, default: undefined },
    onClickUpdate: { type: Function, default: undefined },
    onClickTab: { type: Function, default: undefined },
    onInputSearch: { type: Function, default: undefined },
    activeFiltersLength: { type: Number, default: undefined },
    activeTab: { type: String, default: "" }
  },
  setup() {
    return {
      MACHINE_TABS
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .search {
    width: 300px;
  }
}

.badge {
  color: var(--gray-800);
}

.type-button {
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);

  ::v-deep {
    span {
      font-weight: 500;
    }
  }
}

.type-button-icon {
  color: inherit;
}

.type-button-active {
  border-bottom-color: var(--btn-primary);
  color: var(--btn-primary);
}
</style>
