<template>
  <div>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
    ></v-progress-linear>
    <v-container>
      <about-tab-error
        :error="error"
        v-if="error"
      />
      <main
        v-if="data && error === null"
        class="mo-about-tab"
      >
        <section class="mo-about-tab__fields">
          <about-tab-field
            :name="$t('Name')"
            :value="data.name"
          />
          <about-tab-field
            :name="$t('Creation Date')"
            :value="new Date(data.createdAt).toLocaleString()"
          />
          <about-tab-field
            :name="$t('Created By')"
            :value="data.createdBy"
          />
          <about-tab-field
            :name="$t('Model Owner')"
            :value="data.owner"
          />
          <about-tab-field
            :name="$t('Current Version')"
            :value="data.version"
          />
        </section>
        <section>
          <about-tab-field
            :name="$t('Revision Comments')"
            :value="data.revisionComment"
          />
        </section>
        <div class="mo-about-tab__row-wrapper">
          <section class="mo-about-tab__description">
            <about-tab-field
              :name="$t('Overview')"
              :value="data.overview"
            />
            <about-tab-field
              :name="$t('Model Usage')"
              :value="data.usage"
            />
          </section>
          <div class="mo-about-tab__history">
            <about-tab-history :items="data.scenarios" />
          </div>
        </div>
      </main>
    </v-container>
  </div>
</template>

<script>
import { getOptimizationModel } from "@lighthouse/shared/api";

import { AboutTabError, AboutTabField, AboutTabHistory } from "./components";

export default {
  name: "AboutTab",
  components: {
    AboutTabHistory,
    AboutTabField,
    AboutTabError
  },
  created() {
    getOptimizationModel(this.$http, this.$route.params.id).then(
      (response) => {
        this.data = response.data;
      },
      (error) => {
        console.error(error);
        this.error = error.response.status;
      }
    );

    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      error: null,
      data: null
    };
  }
};
</script>

<style lang="scss" scoped>
.mo-about-tab {
  $table-gap: 1rem;

  display: grid;
  gap: $table-gap;

  &__fields {
    display: flex;
    justify-content: space-evenly;
    align-content: start;
    gap: $table-gap / 2;

    & > * {
      flex: 1 1 100%;
    }
  }

  &__row-wrapper {
    position: relative;

    display: grid;
    align-items: start;
    grid-template-columns: 1fr minmax(12rem, auto);
    gap: $table-gap;
  }

  &__description {
    display: grid;
    gap: $table-gap;
  }

  &__history {
    height: 100%;
    position: absolute;
    grid-column: 2;
  }
}
</style>
