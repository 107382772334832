var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"findings-table card"},[_c('v-data-table',{attrs:{"height":"calc(100% - 3.25rem)","headers":_vm.findingsTableData.headers,"item-key":"id","loading":_vm.findingsTableData.loading,"items":_vm.findingsTableData.data,"sortBy":_vm.findingsTableData.sortBy,"sortDesc":_vm.findingsTableData.sortDesc,"page":_vm.findingsTableData.page.current,"itemsPerPage":_vm.findingsTableData.page.itemsPerPage,"serverItemsLength":_vm.findingsTableData.total,"footer-props":{ 'items-per-page-options': [10, 20, 50] },"loading-text":_vm.$t('Loading Findings... Please wait'),"disable-pagination":"","fixed-header":"","multi-sort":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.findingsTableData, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.findingsTableData, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.findingsTableData, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.findingsTableData, "sortDesc", $event)},"update:page":function($event){return _vm.$set(_vm.findingsTableData.page, "current", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.findingsTableData.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.findingsTableData.page, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.title)+"\n    ")]}},{key:"item.ticketedDate",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.moment(item.node.ticketedDate).format(_vm.$datetime.default))+"\n    ")]}},{key:"item.ticketedByName",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.ticketedByName)+"\n    ")]}},{key:"item.machineName",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.node.machineName)+"\n    ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"actionButton",attrs:{"small":"","color":"success","fab":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotToFinding(item)}}},[_c('v-icon',[_vm._v("mdi-format-list-checks")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }