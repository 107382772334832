<template>
  <Fragment>
    <div class="picker">
      <div>
        {{ $t("items per page") }}
        <select @change="changeNumberItems">
          <option
            value="5"
            :selected="numberSelected === 5"
          >
            5
          </option>
          <option
            value="10"
            :selected="numberSelected === 10"
          >
            10
          </option>
          <option
            value="25"
            :selected="numberSelected === 25"
          >
            25
          </option>
          <option
            value="50"
            :selected="numberSelected === 50"
          >
            50
          </option>
        </select>
      </div>
      <div>
        <i
          class="fa fa-chevron-left"
          @click="previousPage"
          v-bind:class="currentPage <= 0 && 'disabled'"
        />
        {{ `${currentPage + 1}` }} <span>of {{ numberPages }}</span>
        <i
          class="fa fa-chevron-right"
          @click="nextPage"
          v-bind:class="currentPage >= numberPages - 1 && 'disabled'"
        />
      </div>
    </div>
    <i
      class="fa sort"
      @click="toggleSort"
      v-bind:class="sortOrder === 'desc' ? 'fa-sort-amount-desc' : 'fa-sort-amount-asc'"
    />
  </Fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "BarChartPaginator",
  components: {
    Fragment
  },
  props: ["refresh", "rawData", "buildChart", "sortMethod", "defaultSort", "defaultQuantity"],
  data() {
    return {
      sortOrder: "desc",
      currentPage: 0,
      numberSelected: 5,
      numberPages: 0,
      sortedData: []
    };
  },
  mounted() {
    this.sortOrder = this.defaultSort;
    this.numberSelected = Number(this.defaultQuantity) || 5;

    this.sortAndSlice();
  },
  methods: {
    sortAndSlice() {
      this.numberPages = Math.ceil(this.rawData.length / this.numberSelected);
      this.sortedData = this.sortMethod([...this.rawData], this.sortOrder);
      const slicedData = this.sliceData(this.sortedData);
      this.buildChart(slicedData);
    },
    nextPage() {
      if (this.currentPage < this.numberPages - 1) {
        this.currentPage++;
        const slicedData = this.sliceData(this.sortedData);
        this.buildChart(slicedData);
      }
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        const slicedData = this.sliceData(this.sortedData);
        this.buildChart(slicedData);
      }
    },
    toggleSort() {
      this.sortOrder = this.sortOrder === "desc" ? "asc" : "desc";
      this.sortedData = this.sortMethod(this.rawData, this.sortOrder);
      const slicedData = this.sliceData(this.sortedData);
      this.buildChart(slicedData);
    },
    changeNumberItems(e) {
      this.numberSelected = parseInt(e.target.value);
      this.currentPage = 0;
      const slicedData = this.sliceData(this.sortedData);
      this.buildChart(slicedData);
    },
    sliceData(sortedData) {
      return sortedData.slice(
        this.numberSelected * this.currentPage,
        this.numberSelected * this.currentPage + this.numberSelected
      );
    }
  },
  watch: {
    rawData: function () {
      this.currentPage = 0;
      this.sortAndSlice();
    },
    refresh: function () {
      this.sortAndSlice();
    }
  }
};
</script>

<style scoped lang="scss">
.sort {
  padding: 0 10px;
  transform: rotate(-90deg);
  cursor: pointer;
}

.picker {
  font-size: 12px;
  flex: unset;
  justify-content: flex-end;
  display: flex;

  i {
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }
  }

  div {
    padding: 0 10px;
    flex: unset;
  }

  select {
    color: white;
    text-decoration: underline;

    .theme--light & {
      color: black;
    }

    option {
      color: black;
    }
  }
}
</style>
