<template>
  <div class="issue-ticket">
    <ticket
      v-if="ticket"
      :ticket_id="ticket_id"
      :ticket="ticket"
      :users="users"
      :assignFunc="issueAssign"
      :respondFunc="issueRespond"
      :subscribeFunc="issueSubscribe"
      :unsubscribeFunc="issueUnsubscribe"
      :closeFunc="issueClose"
    >
      <template v-slot:extraDetails>
        <div class="issue-details">
          <div class="card">
            <div class="card-header">
              <h2>{{ $t("Issues Details") }}</h2>
            </div>
            <div
              class="card-content"
              v-if="ticket"
            >
              <div class="detail">
                <label class="label">{{ $t("Issue Type:") }}</label>
                <p>{{ ticket.issueTypeName }}</p>
              </div>
              <div class="detail">
                <label class="label">{{ $t("Reason:") }}</label>
                <p>{{ ticket.reason }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ticket>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";
@import "../../scss/mq";
.issue-ticket {
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  .issue-details {
    height: 100%;
    .card-header {
      color: $blue;
    }
    .card-content {
      display: flex;
    }
    .detail {
      margin: 0.5rem 1rem;
      label,
      p,
      pre {
        font-size: 1.15rem;
        font-family: $regular;
        margin: 0.25rem;
        line-height: 1rem;
        tab-size: 0;
        white-space: pre-line;
      }
    }
  }
}
@include media_below(1000px) {
  .issue-ticket {
    width: 100%;
  }
}
</style>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import { Dispatcher } from "@/utils/eventbus";
import { seconds } from "@/utils/filters";

import Ticket from "./Ticket.vue";

export default {
  props: ["ticket_id"],
  data() {
    return {
      loadingTicket: false,
      ticket: null,
      filters: {
        ticketId: { value: this.ticket_id }
      },
      users: []
    };
  },
  created() {
    this.loadTicket();
    Dispatcher.$listen("TICKET_UPDATE", this.loadTicket);
  },
  beforeDestroy() {
    Dispatcher.$silence("TICKET_UPDATE");
    this.setTitles({
      title: "",
      mobile: "",
      clickFunction: null
    });
  },
  components: {
    Ticket
  },
  computed: {},
  methods: {
    moment,
    seconds,
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    loadTicket() {
      this.loadingTicket = true;
      const query = `query getByID($id: ID!){
        issue(id: $id){
          id,
          ... on IssueSearch{
            id,
            issueTypeName,
            issueStartDate,
            issueEndDate,
            subscribed,
            unsubscribed,
            openedBy,
            openedByName,
            openedDate,
            ticketedBy,
            ticketedByName,
            ticketedDate
            firstResponseBy,
            firstResponseByName,
            firstResponseDate,
            closedBy,
            closedByName,
            closedDate
            factoryOrder,
            machineId,
            machineName,
            partId,
            partName,
            assetId,
            assetName,
            reason,
            title,
            assignedTo,
            assignedToNames,
            responding,
            respondingNames,
            totalTime,
          }
        }
        users{
          id,
          firstName,
          lastName,
        }
      }`;
      const variables = {
        id: this.ticket_id
      };
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.ticket = res.data.data.issue;
          this.users = res.data.data.users;
          this.loadingTicket = false;
          console.log(this.ticket.reason);
          this.setTitles({
            title: `<h3 class="backBtn"><i class="fa fa-chevron-left"></i> ${back} </h3>`,
            mobile: `<i class="fa fa-chevron-left"/> ${this.ticket.title}`,
            clickFunction: this.back
          });
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    back() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.close();
      }
    },
    issueAssign(users) {
      let newUserIds = [];
      users.forEach((user) => {
        newUserIds.push(user.id);
      });
      const query = `mutation ($id: ID!, $issueData: IssueInput!){
        updateIssue(globalId:$id, issueData:$issueData){
          ok,
          issue{
            assignedTo,
            assignedToNames,
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        issueData: {
          assignNew: newUserIds
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.assignedTo = res.data.data.updateIssue.issue.assignedTo;
            context.ticket.assignedToNames = res.data.data.updateIssue.issue.assignedToNames;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    issueRespond(pinJwt) {
      let oldJwt = this.$http.defaults.headers["Authorization"];
      if (pinJwt) {
        this.$http.defaults.headers["Authorization"] = `JWT ${pinJwt}`;
      }

      const query = `mutation ($id: ID!, $issueData: IssueInput!){
                        updateIssue(globalId:$id, issueData:$issueData){
                          ok,
                          issue{
                            firstResponseBy,
                            firstResponseByName,
                            firstResponseDate,
                            responding,
                            respondingNames,
                          }
                        }
                      }`;
      const variables = {
        id: this.ticket_id,
        issueData: {
          responding: true
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.firstResponseBy = res.data.data.updateIssue.issue.firstResponseBy;
            context.ticket.firstResponseByName =
              res.data.data.updateIssue.issue.firstResponseByName;
            context.ticket.firstResponseDate = res.data.data.updateIssue.issue.firstResponseDate;
            context.ticket.responding = res.data.data.updateIssue.issue.responding;
            context.ticket.respondingNames = res.data.data.updateIssue.issue.respondingNames;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        })
        .finally(() => {
          this.$http.defaults.headers["Authorization"] = oldJwt;
          this.pinJwt = null; // Clear pinJwt after use
        });
    },
    issueClose() {
      const query = `mutation ($id: ID!, $issueData: IssueInput!){
        updateIssue(globalId:$id, issueData:$issueData){
          ok,
          issue{
            closedDate,
            closedBy,
            closedByName
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        issueData: {
          close: true
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.closedBy = res.data.data.updateIssue.issue.closedBy;
            context.ticket.closedDate = res.data.data.updateIssue.issue.closedDate;
            context.ticket.closedByName = res.data.data.updateIssue.issue.closedByName;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    issueSubscribe() {
      const query = `mutation ($id: ID!, $issueData: IssueInput!){
        updateIssue(globalId:$id, issueData:$issueData){
          ok,
          issue{
            subscribed,
            unsubscribed
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        issueData: {
          subscribing: true
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.subscribed = res.data.data.updateIssue.issue.subscribed;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    issueUnsubscribe() {
      const query = `mutation ($id: ID!, $issueData: IssueInput!){
        updateIssue(globalId:$id, issueData:$issueData){
          ok,
          issue{
            subscribed,
            unsubscribed,
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        issueData: {
          unsubscribing: true
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.unsubscribed = res.data.data.updateIssue.issue.unsubscribed;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    }
  }
};
</script>
