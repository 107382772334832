var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tla-data-table"},[_c('div',{staticClass:"card data-table-card"},[_c('GenericTable',{attrs:{"columns":[
        { title: '', key: 'name' },
        { title: _vm.$t('Total Duration'), key: 'duration', format: _vm.seconds, sortable: true },
        { title: _vm.$t('Total Downtime'), key: 'total_downtime', format: _vm.seconds, sortable: true },
        { title: _vm.$t('Downtime Percent'), key: 'downtime_percentage', sortable: false },
        { title: _vm.$t('Production Loss'), key: 'loss', format: _vm.percent, sortable: true }
      ],"nested_columns":[
        { key: 'status_name' },
        { key: 'status_duration' },
        { key: 'status_downtime', format: _vm.seconds },
        { key: 'status_downtime_percentage', format: _vm.percent },
        { key: 'status_loss', format: _vm.percent }
      ],"data":_vm.tableData}}),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('v-btn',{staticClass:"ma-2",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","small":""},on:{"click":_vm.downloadCsv}},[_c('v-icon',{staticStyle:{"font-size":"12px"}},[_vm._v("mdi-file-download")]),_vm._v(" CSV\n      ")],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }