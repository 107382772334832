import moment from "moment";
import Vue from "vue";

import { hslaToRgba, randomColor } from "@/utils/colors";

const FILTER_TYPES = {
  DATA_TABLE: "DATA_TABLE",
  IMPACT_SUMMARY: "IMPACT_SUMMARY",
  PERFORMANCE_SUMMARY: "PERFORMANCE_SUMMARY"
};

const ISSUE_CARD_TYPES = {
  TOTAL: "Total",
  OPEN: "Open",
  CLOSED: "Closed"
};

const getColor = (seed) => {
  return hslaToRgba(randomColor(seed));
};

const handleOtherClickColors = (backgroundColors, allNonOthersExistInFilter) => {
  const everyColorIsOpaque = backgroundColors.every(
    (color) => color.substring(color.length - 2, color.length - 1) === "9"
  );
  const otherBackgroundColor = backgroundColors[backgroundColors.length - 1];
  const otherColorString = otherBackgroundColor.substring(0, otherBackgroundColor.length - 2);
  const otherColorOpacity = otherBackgroundColor.substring(
    otherBackgroundColor.length - 2,
    otherBackgroundColor.length - 1
  );
  if (everyColorIsOpaque && !allNonOthersExistInFilter) {
    backgroundColors = backgroundColors.map((color, index) => {
      return index !== backgroundColors.length - 1
        ? color.substring(0, color.length - 2) + "1)"
        : color;
    });
  } else {
    const newBackgroundColor = otherColorString + (otherColorOpacity === "9" ? "1)" : "9)");
    backgroundColors.splice(backgroundColors.length - 1, 1, newBackgroundColor);
  }
  const everyColorIsTransparent = backgroundColors.every(
    (color) => color.substring(color.length - 2, color.length - 1) === "1"
  );
  if (everyColorIsTransparent) {
    backgroundColors = backgroundColors.map((color) => color.substring(0, color.length - 2) + "9)");
  }
  return backgroundColors;
};

const handleDataClickColors = (backgroundColors, filter) => {
  let newBackgroundColors = backgroundColors.map((color, index) => {
    const foundItem = filter.find((item) => item.index === index);
    const colorString = color.substring(0, color.length - 2);
    if (index !== backgroundColors.length - 1) {
      return foundItem ? colorString + "9)" : colorString + "1)";
    }
    return color;
  });
  const nonOtherBackgroundColors = newBackgroundColors.slice(0, backgroundColors.length - 1);
  const everyNonOtherIsTransparent = nonOtherBackgroundColors.every((color) => {
    color.substring(color.length - 2, color.length - 1) === "1";
  });
  const otherBackgroundColor = newBackgroundColors[newBackgroundColors.length - 1];
  const otherOpacity = otherBackgroundColor.substring(
    otherBackgroundColor.length - 2,
    otherBackgroundColor.length - 1
  );
  if (everyNonOtherIsTransparent && otherOpacity === "1") {
    newBackgroundColors = newBackgroundColors.map((color) => {
      return color.substring(0, color.length - 2) + "9)";
    });
  }
  return newBackgroundColors;
};

const handleBackgroundColorsNoOthersPresent = (backgroundColors, filter) => {
  if (filter.length === 0) {
    return backgroundColors.map((color) => color.substring(0, color.length - 2) + "9)");
  }
  const newBackgroundColors = backgroundColors.map((color, index) => {
    const foundItem = filter.find((item) => item.index === index);
    const colorString = color.substring(0, color.length - 2);
    if (!foundItem) {
      return colorString + "1)";
    }
    return colorString + "9)";
  });
  return newBackgroundColors;
};

const getDatasets = (data, colors) => {
  return [
    {
      data: data.map((item) => item.number_of_issues),
      label: Vue.i18n.translate("Number of Issues"),
      backgroundColor: colors
    }
  ];
};

const getTimeLabel = (timeString, dateFormat) => {
  if (dateFormat === "week") {
    const start = moment(timeString);
    const end = moment(timeString).add(7, "d");
    if (start.month() !== end.month()) {
      return start.format("D MMM") + " - " + end.format("D MMM");
    } else {
      return start.format("D") + " - " + end.format("D MMM");
    }
  } else {
    return moment(timeString).format(dateFormat);
  }
};

export {
  FILTER_TYPES,
  ISSUE_CARD_TYPES,
  getColor,
  handleDataClickColors,
  handleBackgroundColorsNoOthersPresent,
  handleOtherClickColors,
  getDatasets,
  getTimeLabel
};
