<template>
  <div class="metric-input-dialog">
    <v-dialog
      v-model="showDialog"
      persistent
      :scrollable="false"
      max-width="972px"
    >
      <template #activator="{ on, attrs }">
        <v-hover>
          <div class="dialog-btn">
            <Btn
              large
              class="edit-btn color--primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                class="mr-2"
                size="24"
                >mdi-pencil-outline</v-icon
              >
              {{ $t("Edit") }}
            </Btn>
          </div>
        </v-hover>
      </template>

      <v-card class="metric-input-card">
        <DialogClose
          class="dialog-close"
          size="24"
          icon="mdi-close"
          :on-click="handleClose"
        />
        <v-card-title class="headline text-h6 color--text-primary font-weight-medium px-6 py-5">
          {{ dialogTitle }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="metrics">
            <div class="date-selectors my-5">
              <v-menu
                ref="date_select_from"
                v-model="date_select_from"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                width="167"
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="from_date"
                    :label="$t('From Date')"
                    append-icon="mdi-calendar-range-outline"
                    filled
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="from_date"
                  no-title
                  scrollable
                  :locale="$i18n.locale()"
                  @input="date_select_from = false"
                />
              </v-menu>

              <v-menu
                ref="date_select_to"
                v-model="date_select_to"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="to_date"
                    :label="$t('To Date')"
                    append-icon="mdi-calendar-range-outline"
                    filled
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="to_date"
                  no-title
                  scrollable
                  :locale="$i18n.locale()"
                  @input="date_select_to = false"
                />
              </v-menu>
            </div>

            <v-data-table
              ref="zoneControlMetricDataTable"
              :headers="headers"
              :items="dates"
              :loading="loading"
              disable-pagination
              fixed-header
              hide-default-footer
              multi-sort
            >
              <template #[`item.date`]="{ item }">
                <p class="text-body-2">{{ item.date.format($datetime.date) }}</p>
              </template>
              <template
                v-for="(metric, metricIndex) in trend.node.metrics.edges"
                #[`item.${metric.node.name}`]="{ item }"
              >
                <v-text-field
                  :id="metric.node.id"
                  ref="textField"
                  :key="metricIndex"
                  :value="item[metric.node.name]"
                  :class="metric.node.automatic ? 'disabled' : null"
                  :data-time="item.date.toISOString()"
                  outlined
                  hide-details
                  label=""
                  height="40px"
                >
                </v-text-field>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions class="py-5 px-6 justify-end">
          <Btn
            width="149"
            variant="secondary"
            @click="handleClose"
          >
            {{ $t("Cancel") }}
          </Btn>
          <Btn
            width="149"
            class="ml-3"
            variant="primary"
            :disabled="loadingMetrics > 0"
            @click="updateMetrics"
          >
            {{ $t("Submit") }}
          </Btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Vue from "vue";

import Btn from "@/ui/Btn";
import DialogClose from "@/ui/DialogClose";

export default {
  components: { Btn, DialogClose },
  props: {
    trend: {
      type: Object,
      required: true
    },
    machine_group_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      date_select_from: false,
      date_select_to: false,
      showDialog: false,
      headers: [{ text: this.$t("Date"), value: "date", sortable: false, align: "start" }],
      dates: [],
      loading: false,
      loadingMetrics: 0,
      metric_ids: []
    };
  },
  computed: {
    dialogTitle() {
      return `${this.$t("Update")} ${this.trend.node.name.toLowerCase()} ${this.$t("metrics")}`;
    }
  },
  watch: {
    from_date() {
      this.renderDates();
    },
    to_date() {
      this.renderDates();
    }
  },
  created() {
    let moment_from_date = moment().subtract(7, "d").startOf("day");
    let moment_to_date = moment().startOf("day");

    this.from_date = moment_from_date.format("YYYY-MM-DD");
    this.to_date = moment_to_date.format("YYYY-MM-DD");

    this.trend.node.metrics.edges.forEach((metric) => {
      this.metric_ids.push(metric.node.id);
      this.headers.push({
        text: metric.node.name,
        value: metric.node.name,
        align: "center",
        sortable: false
      });
      this.dates.forEach((date) => {
        Vue.set(date, metric.node.name, "");
      });
    });
  },
  methods: {
    loadMetrics() {
      this.loading = true;
      let query = `query ($metricIds: [ID]!, $dateRange: String!, $machineGroupId: ID!){
        metricRecords(metricId_In: $metricIds, dateRange: $dateRange, machineGroupId: $machineGroupId){
          edges{
            node{
              metric{
                name
              }
              date
              value
            }
          }
        }
      }`;

      let variables = {
        metricIds: this.metric_ids,
        dateRange: this.from_date + "|" + this.to_date,
        machineGroupId: this.machine_group_id
      };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        if (
          res.data &&
          res.data.data &&
          res.data.data.metricRecords &&
          res.data.data.metricRecords.edges
        ) {
          res.data.data.metricRecords.edges.forEach((record) => {
            this.trend.node.metrics.edges.forEach((metric) => {
              this.dates.forEach((date) => {
                if (
                  record.node.metric.name == metric.node.name &&
                  date.date.format("YYYY-MM-DD") == moment(record.node.date).format("YYYY-MM-DD")
                ) {
                  date[metric.node.name] = record.node.value;
                }
              });
            });
          });
        }
        this.loading = false;
      });
    },
    updateMetrics() {
      this.$refs.textField.forEach((metric) => {
        if (metric.lazyValue || metric.value) {
          this.loadingMetrics++;
          const query = `mutation ($input: MetricRecordMutationInput!){
              updateMetric(input:$input) {
                metricRecord{
                  value,
                }
              }
            }`;
          const variables = {
            input: {
              metric: metric.id,
              machineGroupId: this.machine_group_id,
              value: metric.lazyValue,
              date: metric.$attrs["data-time"]
            }
          };
          this.$http
            .post("graphql/", { query, variables })
            .then(() => {
              this.showDialog = false;
              this.loadingMetrics--;
              if (this.loadingMetrics === 0) {
                this.$emit("metric_update");
              }
            })
            .catch((res) => {
              this.errors = res.errors;
              this.loadingMetrics--;
              if (this.loadingMetrics === 0) {
                this.$emit("metric_update");
              }
            });
        }
      });
    },
    renderDates: function () {
      let moment_from_date = moment(this.from_date);
      let moment_to_date = moment(this.to_date);

      this.dates = [];
      let date_copy = moment_from_date.clone();

      while (moment_to_date.isSameOrAfter(date_copy)) {
        this.dates.push({ date: date_copy });
        date_copy = date_copy.clone().add(1, "d");
      }

      this.loadMetrics();
    },
    handleClose() {
      this.showDialog = false;
      this.$refs.textField.forEach((field) => {
        if (field.hasFocused) {
          field.lazyValue = field.initialValue;
        }
      });
      this.scrollToTop();
    },
    scrollToTop() {
      const dataTable =
        this.$refs.zoneControlMetricDataTable.$el.querySelector(".v-data-table__wrapper");
      if (dataTable) {
        dataTable.scrollTop = 0;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/scss/_table.scss";
@import "~@/scss/_dropdown.scss";

.dialog-btn {
  position: relative;
}

.dialog-close {
  top: 16px;
  right: 16px;
  color: var(--text-secondary);
}

.metric-input-card {
  .disabled {
    display: block;
    pointer-events: unset;

    div {
      cursor: not-allowed;
    }

    fieldset {
      cursor: not-allowed;
    }

    input {
      cursor: not-allowed;
    }
  }

  .date-selectors {
    display: grid;
    grid-template-columns: 167px 167px;
    gap: 12px;
  }

  ::v-deep {
    .theme--light.v-data-table thead th,
    .theme--dark.v-data-table thead th {
      background-color: var(--bg-tertiary) !important;
      text-align: left !important;
    }

    .v-card__text {
      overflow: hidden;
    }

    .v-data-table__wrapper {
      overflow: auto;
      max-height: 375px;
    }

    table {
      .v-input {
        margin: 0;
        height: 100%;
        border-radius: 0;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        border: 1px solid transparent;
      }

      .v-input--is-focused {
        border: 1px solid var(--btn-primary);
      }

      .v-input__slot {
        padding: 0 16px !important;
      }

      .v-input__control,
      .v-text-field__slot,
      .v-input__slot {
        min-height: unset;
        height: 100%;
        margin: auto 0;
      }

      td:not(:first-child) {
        padding: 0;
      }

      td:not(:last-child) {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 10px;
          bottom: 10px;
          width: 1px;
          background-color: var(--border-secondary);
        }
      }

      fieldset {
        border: none !important;
      }

      input {
        text-align: left;
        padding: 0;
        height: 100%;
      }
    }
  }
}
</style>
