<template>
  <transition name="slide-fade">
    <div class="oee-chart metric-component">
      <div class="mobile-chart-title-container">
        <h2 class="card-title">{{ $t("OEE") }}</h2>
        <span class="mobile-gauge-container">
          <v-gauge
            v-if="oee"
            gaugeValueClass="gaugeValue"
            :value="oee"
            :donut="true"
            :options="gaugeOpts"
          />
        </span>
      </div>
      <v-progress-linear
        indeterminate
        v-if="!(production && production.length > 0)"
      ></v-progress-linear>
      <div
        class="charts"
        v-else
      >
        <div class="gauge-container">
          <v-gauge
            v-if="oee"
            gaugeValueClass="gaugeValue"
            :value="oee"
            :donut="true"
            :options="gaugeOpts"
          />
        </div>
        <div class="charts_container">
          <div class="chart">
            <line-chart
              v-if="chartData"
              chartName="oeeChart"
              class="dynamicChart"
              uom="%"
              :decimal="2"
              :chartData="chartData"
              :chartOptions="chartOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.oee-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 18vh;
  flex: 1;
  justify-self: normal;
  align-self: normal;

  .charts {
    display: flex;
    justify-content: center;
    align-items: center;
    .line-container {
      width: calc(100% - 150px);
      .oeeChart {
        width: 100%;
        height: 18vh;
        padding-left: 0.5rem;
      }
    }
  }

  .charts_container {
    .chart {
      height: 250px;

      .dynamicChart {
        height: 250px;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import VGauge from "vgauge";

import LineChart from "@/components/charts/LineChart.js";

export default {
  components: {
    LineChart,
    VGauge
  },
  props: ["options", "production"],
  data() {
    return {
      loaded: false,
      oeeData: [],
      chartData: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      },
      oee: false,
      lastParams: {}
    };
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    format_data() {
      let percentage = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let total_produced = 0;
      let total_production_target = 0;
      let total_scrapped = 0;
      let total_runtime = 0;
      let total_time = 0;
      let total_planned = 0;
      if (this.production) {
        this.production.forEach((interval) => {
          let produced = interval.produced.value;
          let production_target = interval.production_target.value;
          let scrapped = interval.scrapped.value;
          let runtime = interval.runtime.duration.value;
          let planned = interval.planned.duration.value;
          let duration = interval.duration.value;
          let perf = (produced / production_target) * 100;
          let quality = ((produced - scrapped) / produced) * 100;
          let runtime_per = (runtime / duration) * 100;
          let availability = (runtime / (duration - planned)) * 100;
          let oee = interval.oee ? interval.oee.value * 100 : null;
          percentage.push(oee);
          footers.push([
            this.$t("Performance") + " : " + perf.toFixed(2) + "%",
            this.$t("Quality") + " : " + quality.toFixed(2) + "%",
            this.$t("Availability") + " : " + availability.toFixed(2) + "%"
          ]);
          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          total_produced += produced;
          total_production_target += production_target;
          total_scrapped += scrapped;
          total_runtime += runtime;
          total_planned += planned;
          total_time += duration;
        });
        this.oee =
          (((((total_produced / total_production_target) * (total_produced - total_scrapped)) /
            total_produced) *
            total_runtime) /
            (total_time - total_planned)) *
          100;
        if (this.oee < 0) {
          this.oee = 0;
        }
      }
      this.chartData = {
        threshold: this.options.targets.oee,
        datasets: [
          {
            data: percentage,
            titles: titles,
            footers: footers,
            label: this.$t("OEE"),
            fill: false,
            pointRadius: 3
          }
        ],
        labels: labels
      };
    }
  },
  computed: {
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [
            {
              display: false,
              ticks: {
                suggestedMin: 0,
                suggestedMax: 100
              }
            }
          ],
          xAxes: [
            {
              offset: true
            }
          ]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      handler: "format_data",
      deep: true
    }
  }
};
</script>
