<template>
  <div class="parts-autocomplete">
    <VAutocomplete
      class="select-picker"
      :placeholder="$t(label)"
      :items="options"
      :filter="filterOptions"
      clearable
      multiple
      single-line
      v-model="selected"
      item-text="name"
      :menu-props="{ closeOnClick: true }"
      @change="filterController.setFilter('parts', selected)"
    >
      <template v-slot:item="{ item, index }"> {{ item.name }} : {{ item.description }} </template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">
          <span>{{ item.name }}</span>
        </span>
        <span
          v-if="index === 1"
          class="grey--text text-caption"
        >
          &nbsp;(+{{ selected.length - 1 }} {{ $t("others") }})
        </span>
      </template>
    </VAutocomplete>
  </div>
</template>

<script>
import { AnalyticsFilterController } from "./AnalyticsFilterController";

export default {
  props: {
    filterController: AnalyticsFilterController,
    value: Array
  },
  data() {
    return {
      label: this.$t("Parts"),
      searchText: "",
      selected: [],
      options: []
    };
  },
  created() {
    this.loadOptions();
  },
  methods: {
    filterOptions(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();

      // define your custom logic of your filter
      const name = item.name.toLowerCase();
      const value = item.description.toLowerCase();

      return name.indexOf(searchText) > -1 || value.indexOf(searchText) > -1;
    },
    loadOptions: async function () {
      let machinePks = [];
      if (this.filterController.hasFilter("machines")) {
        machinePks = this.filterController.getFilterPks("machines");
      }
      this.options = await this.getOptions(machinePks);
    },
    getOptions(ids) {
      return new Promise((resolve) => {
        this.$http
          .post("graphql/", {
            query: `
          query($ids: [Int]){
              partConfigurations(machinePks: $ids){
                  part {
                      name,
                      id,
                      description,
                      pk
                  }
              }
          }
        `,
            variables: {
              ids: ids
            }
          })
          .then((res) => {
            const partConfigs = res.data.data.partConfigurations;
            const result = [];
            for (let p of partConfigs) {
              if (p.part) {
                result.push(p.part);
              }
            }
            resolve(result);
          })
          .catch(() => {
            resolve([]);
          });
      });
    }
  }
};
</script>
