<template>
  <div class="heatmap">
    <div class="heatmap-x-axis">
      <div
        class="yLabel"
        :style="{ width: yLabelWidth }"
      ></div>
      <div
        class="data-row"
        :style="{ width: 'calc(100% - ' + yLabelWidth + ')' }"
      >
        <div
          class="heatmap-x-axis-label label"
          v-for="(x_coord, x_index) in xCoords"
          :style="{ width: width + '%' }"
        >
          <slot
            name="xLabels"
            :x="x_coord"
          ></slot>
        </div>
      </div>
    </div>
    <div class="heatmap-rows">
      <transition-group>
        <div
          class="heatmap-row"
          v-for="(y_coord, y_index) in yCoords"
          :style="{ height: height + '%' }"
          :key="y_coord"
        >
          <div
            class="heatmap-column ylabel label"
            :style="{ width: yLabelWidth }"
          >
            <slot
              name="yLabels"
              :y="y_coord"
            ></slot>
          </div>
          <div
            class="data-row"
            :style="{ width: 'calc(100% - ' + yLabelWidth + ')' }"
          >
            <div
              class="heatmap-cell"
              v-for="(x_coord, x_index) in xCoords"
              :style="{ width: width + '%' }"
            >
              <slot
                name="cells"
                :x="x_coord"
                :y="y_coord"
              ></slot>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<style lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.heatmap {
  height: 100%;
  .heatmap-rows {
    height: calc(100% - 4rem);
    overflow-y: auto;
  }

  .heatmap-x-axis,
  .heatmap-row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 3rem;
    transition: all 0.5s;
    .data-row {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 3rem;
      height: 100%;
      .heatmap-cell {
        height: 100%;
        padding: 0.15rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ylabel {
      cursor: pointer;
    }
  }
}
</style>

<script>
export default {
  props: ["xCoords", "yCoords"],
  data() {
    return {
      yLabelWidth: "5rem"
    };
  },
  components: {},
  created() {},
  beforeDestroy() {},
  computed: {
    width() {
      if (this.xCoords.length > 0) {
        return 100 / this.xCoords.length;
      }
    },
    height() {
      if (this.yCoords.length > 0) {
        return 100 / this.yCoords.length;
      }
    }
  },
  methods: {}
};
</script>
