var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card ai-vision-anomalies"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.createTicketOpen),callback:function ($$v) {_vm.createTicketOpen=$$v},expression:"createTicketOpen"}},[_c('v-card',[_c('DialogCloseButton',{attrs:{"onClick":function () {
            _vm.createTicketOpen = false;
            _vm.openItem = null;
          }}}),_vm._v(" "),_c('v-card-title',{staticClass:"headline"},[_vm._v("\n        "+_vm._s(_vm.$t("Create ticket from this anomaly?"))+"\n      ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.openItem ? _vm.openItem.reason : "")+"\n      ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),(!_vm.ticketingAnomalyPending)?_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function () {
              _vm.createTicketOpen = false;
              _vm.openItem = null;
            }}},[_vm._v("\n          "+_vm._s(_vm.$t("Cancel"))+"\n        ")]):_vm._e(),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","primary":"","text":"","loading":_vm.ticketingAnomalyPending},on:{"click":function($event){return _vm.ticketAnomaly(_vm.openItem)}}},[_vm._v("\n          "+_vm._s(_vm.$t("OK"))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"tablecontainer"},[_c('generic-table',{attrs:{"columns":[
        { title: _vm.$t('Title'), key: 'title', sortable: true, sort_method: _vm.sort_request },
        {
          title: _vm.$t('Detected'),
          key: 'detected',
          sortable: true,
          sort_method: _vm.sort_request,
          style: { textAlign: 'left' }
        },
        {
          title: _vm.$t('Issue Window'),
          key: 'issueWindow',
          sortable: true,
          sort_method: _vm.sort_request,
          style: { textAlign: 'left' }
        },
        {
          hidden: !_vm.ticketed,
          title: _vm.$t('Ticketed'),
          key: 'ticketedDate',
          sortable: true,
          sort_method: _vm.sort_request,
          style: { textAlign: 'left' }
        },
        { title: _vm.$t('Type'), key: 'type', sortable: true, sort_method: _vm.sort_request },
        { title: _vm.$t('Asset'), key: 'asset', sortable: true, sort_method: _vm.sort_request },
        {
          title: _vm.$t('Assigned'),
          key: 'assigned',
          sortable: true,
          sort_method: _vm.sort_request,
          style: { textAlign: 'left' }
        },
        { title: _vm.$t('Reason'), key: 'reason', sortable: true, style: { textAlign: 'left' } },
        {
          title: _vm.$t(''),
          key: 'item',
          html: 'image_link',
          click: _vm.ticketed ? _vm.launchTicket : _vm.createTicket
        }
      ],"data":_vm.records}})],1),_vm._v(" "),_c('div',{staticClass:"tablefooter"},[_c('div',{staticClass:"paginator_container"},[_c('div',{staticClass:"paginator"},[_c('div',{staticClass:"selector"},[_c('div',{staticClass:"rows-title"},[_vm._v(_vm._s(_vm.$t("Rows per page")))]),_vm._v(" "),_c('select',{on:{"change":_vm.handleRequestCountChange}},[_c('option',{attrs:{"value":"10"},domProps:{"selected":_vm.count === 10}},[_vm._v("\n              10\n            ")]),_vm._v(" "),_c('option',{attrs:{"value":"50"},domProps:{"selected":_vm.count === 50}},[_vm._v("\n              50\n            ")]),_vm._v(" "),_c('option',{attrs:{"value":"100"},domProps:{"selected":_vm.count === 100}},[_vm._v("\n              100\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"range-indicator"},[_c('div',[_c('span',[_vm._v("\n              "+_vm._s(_vm.currentStart !== null ? _vm.currentStart + 1 : "---")+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v(" - ")]),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.currentStart !== null ? Math.min(_vm.currentStart + Number(_vm.count), _vm.totalcount) : "---")+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v(" of "+_vm._s(_vm.totalcount || "---")+" ")])])]),_vm._v(" "),_c('div',{staticClass:"back-next-select"},[_c('i',{staticClass:"fa fa-chevron-left",class:("" + (this.currentStart <= 0 && 'disabled')),on:{"click":function () {
                this$1.handlePage(-1, this$1.currentStart <= 0);
              }}}),_vm._v(" "),_c('i',{staticClass:"fa fa-chevron-right",class:("" + (this.currentStart + Number(_vm.count) >= _vm.totalcount && 'disabled')),on:{"click":function () {
                this$1.handlePage(1, this$1.currentStart + Number(_vm.count) >= _vm.totalcount);
              }}})])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }