import Chart from "chart.js";
import { HorizontalBar } from "vue-chartjs";
import { mapGetters } from "vuex";

import { Dispatcher } from "@/utils/eventbus";

export default {
  extends: HorizontalBar,
  props: {
    chartName: { default: "hbar-chart" },
    chartData: { default: false },
    clickFunction: { default: false },
    uom: { default: false },
    decimal: { default: 0 },
    chartOptions: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      options: {
        cornerRadius: 6,
        stackedRounded: 8,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          display: false,
          labels: {
            filter: function (item) {
              return item.text ? !item.text.includes("exclude") : false;
            }
          }
        },
        tooltips: {
          titleFontSize: 14,
          bodyFontSize: 14,
          footerFontSize: 14,
          footerFontStyle: 300,
          callbacks: {
            label: this.drawLabel,
            footer: this.drawFooter
          }
        },
        onClick: this.clickFunction ? this.clickFunction : null,
        scales: {
          yAxes: [
            {
              gridLines: {
                color: this.gridColor,
                zeroLineColor: this.gridColor,
                zeroLineWidth: 1,
                drawBorder: false,
                borderColor: this.gridColor,
                borderDash: [5, 5],
                borderDashOffset: 0.0,
                borderWidth: 1,
                lineWidth: 1,
                drawOnChartArea: true,
                drawTicks: false
              },
              ticks: {
                padding: 8
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: this.gridColor,
                borderColor: this.gridColor,
                zeroLineColor: this.gridColor,
                zeroLineWidth: 1,
                drawBorder: false,
                borderDash: [5, 5],
                borderDashOffset: 0.0,
                borderWidth: 1,
                lineWidth: 1,
                drawOnChartArea: true,
                drawTicks: false
              },
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 6,
                padding: 8
              }
            }
          ]
        },
        animation: {
          show: {
            x: { from: 0 },
            y: { from: 0 }
          },
          hide: {
            x: { to: 0 },
            y: { to: 0 }
          }
        }
      }
    };
  },
  created() {
    this.options.scales.xAxes[0].gridLines.zeroLineColor = this.gridColor;
    this.options.scales.xAxes[0].gridLines.color = this.gridColor;
    this.options.scales.yAxes[0].gridLines.zeroLineColor = this.gridColor;
    this.options.scales.yAxes[0].gridLines.color = this.gridColor;
    this.options = _.merge(this.options, this.chartOptions);

    // this.addPlugin(StylePlugin)
    Chart.defaults.global.defaultFontColor = this.fontColor;
    Chart.defaults.global.defaultFontFamily = "Encoded Sans, Verdana";
    // Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);
    // Chart.controllers.roundedBar = Chart.controllers.bar.extend({
    //   dataElementType: Chart.elements.RoundedTopRectangle
    // });
    Dispatcher.$listen(this.chartName, this.redrawChart);
  },
  mounted() {
    this.renderChart(this.formattedChartData, this.options);
  },
  beforeDestroy() {
    Dispatcher.$silence(this.chartName, this.redrawChart);
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    isLightTheme() {
      return this.theme === "light";
    },
    gridColor() {
      return this.isLightTheme ? "#0000001A" : "#FFFFFF33";
    },
    fontColor() {
      return this.isLightTheme ? "#6F6F6F" : "#8D8D8D";
    },
    formattedChartData() {
      let chartData = _.merge({}, this.chartData);
      if (chartData && chartData.datasets) {
        chartData.datasets.forEach((dataset) => {
          Object.assign(dataset, {
            borderRadius: 10
          });
          if (dataset.gradients) {
            let backgroundColors = [];
            if (Array.isArray(dataset.gradients)) {
              dataset.gradients.forEach((gradientSet) => {
                let gradient = this.$refs.canvas
                  .getContext("2d")
                  .createLinearGradient(0, 0, 0, 150);
                gradientSet.forEach((color, index) => {
                  gradient.addColorStop(index / (gradientSet.length - 1), color);
                });
                backgroundColors.push(gradient);
              });
            }
            dataset["backgroundColor"] = backgroundColors;
          }
        });
      }
      return chartData;
    }
  },
  methods: {
    redrawChart() {
      Chart.defaults.global.defaultFontColor = this.fontColor;
      this.options.scales.xAxes[0].gridLines.zeroLineColor = this.gridColor;
      this.options.scales.xAxes[0].gridLines.color = this.gridColor;
      this.options.scales.yAxes[0].gridLines.zeroLineColor = this.gridColor;
      this.options.scales.yAxes[0].gridLines.color = this.gridColor;
      this.options = _.merge(this.options, this.chartOptions);
      this.renderChart(this.formattedChartData, this.options);
      if (this.$data._chart) {
        this.$data._chart.update();
      }
    },
    drawFooter(item) {
      if ("footers" in this.chartData.datasets[item[0].datasetIndex]) {
        return this.chartData.datasets[item[0].datasetIndex].footers[item[0].index];
      }
    },
    drawLabel(item) {
      let value = parseFloat(item.xLabel);
      if ("tooltips" in this.chartData.datasets[item.datasetIndex]) {
        return this.chartData.datasets[item.datasetIndex].tooltips[item.index];
      }
      return this.chartData.datasets[item.datasetIndex].label + " : " + this.formatValue(value);
    },
    formatValue(value) {
      value = value.toFixed(this.decimal);
      if (this.uom) {
        if (this.uom == "%") {
          return value + "%";
        }
      } else {
        return value;
      }
    }
  },
  watch: {
    chartOptions: {
      handler: "redrawChart",
      deep: true
    },
    chartData: {
      handler: "redrawChart",
      deep: true
    },
    theme: {
      handler: "redrawChart",
      deep: true
    }
  }
};
