<template>
  <li class="node-tree">
    <span class="label">
      <v-checkbox
        :indeterminate="isIndeterminate()"
        :value="isChecked()"
        @change="
          (e) => {
            handleSelectMachineGroups(e, node);
          }
        "
      />
      <v-btn @click="expand">
        <p>{{ node.name }}</p>
        <i :class="`expand-icon fa fa-chevron-right ${expanded ? 'expanded' : 'unexpanded'}`" />
      </v-btn>
    </span>
    <ul
      class="machines"
      v-show="
        node.machines &&
        node.machines.length > 0 &&
        ((!searchText && expanded) || (searchText && (machineMatch || node.searchMatch)))
      "
    >
      <span class="label">
        {{ $t("Machines") }}
      </span>
      <li
        v-for="(machine, m_index) in node.machines"
        v-if="!searchText || machine.searchMatch || (!machineMatch && node.searchMatch)"
      >
        <span class="label">
          <v-checkbox
            :label="machine.name"
            ref="machines"
            :value="machineChecked(machine.pk)"
            @change="
              (e) => {
                handleSelectMachine(e, machine.pk, node);
              }
            "
          />
        </span>
      </li>
    </ul>
    <ul v-show="node.children && node.children.length">
      <listNode
        v-show="(!searchText && expanded) || (searchText && child.searchMatch)"
        :searchText="searchText"
        v-for="(child, idx) in node.children"
        :key="idx"
        :node="child"
        ref="children"
        :changeMachineGroups="
          (e) => {
            handleSelectMachineGroups(e, child);
          }
        "
        :changeMachines="handleSelectMachine"
      ></listNode>
    </ul>
  </li>
</template>

<script>
export default {
  name: "listNode",
  props: ["node", "changeMachineGroups", "changeMachines", "searchText"],
  data() {
    return {
      expanded: false,
      selected: SELECTSTATE.UNSELECTED,
      machineValues: {}
    };
  },
  created() {
    for (const m of this.node.machines) {
      this.machineValues[m.pk] = false;
    }
  },
  computed: {
    machineMatch() {
      let match = false;
      console.log("machines comp");
      if (this.node.machines) {
        this.node.machines.forEach((machine) => {
          if (machine.searchMatch) {
            match = true;
          }
        });
      }
      return match;
    }
  },
  methods: {
    getChildrenRefs() {
      return this.$refs.children || [];
    },
    getId() {
      return this.node.pk;
    },
    isChecked() {
      return this.selected == SELECTSTATE.SELECTED;
    },
    machineChecked(machineId) {
      return this.machineValues[machineId];
    },
    checkChecked(node) {
      let childrenChecked = 0;
      if ("children" in node.$refs && node.$refs.children.length > 0) {
        for (const child of node.$refs.children) {
          if ("children" in child.$refs && child.$refs.children.length > 0) {
            for (const grandchild of child.$refs.children) {
              node.checkChecked(grandchild);
            }
          }
          if (child.selected == SELECTSTATE.SELECTED) {
            childrenChecked++;
          } else if (child.selected == SELECTSTATE.PARTIAL) {
            //if we have a partial selection
            //we can in turn only be partially
            //selected
            node.selected = SELECTSTATE.PARTIAL;
            return;
          }
        }
      }

      //we need to select for machines
      //because they are stored per node
      let machinesSelected = 0;
      let totalMachines = 0;
      for (const m of Object.keys(node.machineValues)) {
        totalMachines++;
        if (node.machineValues[m]) {
          machinesSelected++;
        }
      }

      if (totalMachines > 0) {
        if (machinesSelected === 0 && childrenChecked === 0) {
          node.selected = SELECTSTATE.UNSELECTED;
        } else if (machinesSelected == totalMachines && !("children" in node.$refs)) {
          node.selected = SELECTSTATE.SELECTED;
        } else if (
          machinesSelected == totalMachines &&
          "children" in node.$refs &&
          childrenChecked == node.$refs.children.length
        ) {
          node.selected = SELECTSTATE.SELECTED;
        } else {
          node.selected = SELECTSTATE.PARTIAL;
        }
      } else {
        if (childrenChecked == 0 && !("children" in node.$refs)) {
          node.selected = SELECTSTATE.UNSELECTED;
        } else if (childrenChecked == node.$refs.children.length) {
          node.selected = SELECTSTATE.SELECTED;
        } else {
          node.selected = SELECTSTATE.PARTIAL;
        }
      }
    },
    handleSelectMachine(add, machine, machineGroup) {
      if (machineGroup.pk == this.node.pk) {
        this.machineValues[machine] = add;
      }
      window.requestAnimationFrame(() => {
        this.changeMachines(add, machine, machineGroup);
        this.checkChecked(this);
      });
    },
    handleSelectMachineGroups(add, group) {
      if (group.pk == this.node.pk) {
        if (add) {
          this.selected = SELECTSTATE.SELECTED;
        } else {
          this.selected = SELECTSTATE.UNSELECTED;
        }
      }

      window.requestAnimationFrame(() => {
        //we need to wait fo the state to update
        this.changeMachineGroups(add, this.node);
        //don't update our state at the top level
        //assume we're good to select
        if (group.pk != this.node.pk) {
          this.checkChecked(this);
          return;
        }
        if (add) {
          this.selectChildren(this);
        } else {
          this.unselectChildren(this);
        }
      });
    },
    selectAllMachinesFromChildren(node) {
      for (const m of this.node.machines) {
        this.machineValues[m.pk] = true;
      }
    },
    selectChildren(node) {
      if (node.$refs.children) {
        for (const n of node.$refs.children) {
          n.selected = SELECTSTATE.SELECTED;
          for (const m of Object.keys(n.machineValues)) {
            n.machineValues[m] = true;
            n.machineValues = { ...n.machineValues };
          }
          this.selectChildren(n);
        }
      }
      for (const m of Object.keys(node.machineValues)) {
        node.machineValues[m] = true;
        node.machineValues = { ...node.machineValues };
      }
    },
    unselectChildren(node) {
      for (const n of node.getChildrenRefs()) {
        n.selected = SELECTSTATE.UNSELECTED;
        this.unselectChildren(n);
      }
      for (const m of Object.keys(node.machineValues)) {
        node.machineValues[m] = false;
        node.machineValues = { ...node.machineValues };
      }
    },
    expand() {
      this.expanded = !this.expanded;
    },
    isIndeterminate() {
      return this.selected == SELECTSTATE.PARTIAL;
    }
  }
};
export const SELECTSTATE = {
  UNSELECTED: 0,
  SELECTED: 1,
  PARTIAL: 2
};
</script>
<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.node-tree {
  list-style-type: none;
  border-radius: 0px;
  margin: 10px;
  padding-left: 10px;

  .partial-select {
    font-size: 24px;
    color: $blue;
  }

  ul {
    padding-left: 20px;
    margin-left: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  .machines {
    list-style-type: none;
    padding-left: 50px;
    padding-bottom: 10px;
  }

  .label {
    flex: unset;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-bottom: 5px;

    .v-messages {
      display: none;
    }

    .v-input {
      i {
        &.mdi-minus-box,
        &.mdi-checkbox-marked {
          color: $blue !important;
        }
      }

      margin: 0;
      .v-input__slot {
        margin: 0;
        .v-input__control {
          flex-direction: row;
        }
      }
    }

    .expand-icon {
      transition: rotate 100ms;
      cursor: pointer;
      margin: 2px 10px;

      &.expanded {
        rotate: 90deg;
      }

      &.unexpanded {
        rotate: 0deg;
      }
    }
  }
}
</style>
