import Chart from "chart.js";
import { HorizontalBar, mixins } from "vue-chartjs";
import { mapGetters } from "vuex";

import { Dispatcher } from "@/utils/eventbus";

const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  // mixins: [reactiveProp],
  props: {
    chartName: { default: "hbar-chart" },
    chartData: { default: false },
    clickFunction: { default: false },
    uom: { default: false },
    decimal: { default: 0 },
    chartOptions: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      options: {
        cornerRadius: 6,
        stackedRounded: 8,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          display: false,
          labels: {
            filter: function (item, chart) {
              return item.text ? !item.text.includes("exclude") : false;
            }
          }
        },
        tooltips: {
          titleFontSize: 14,
          bodyFontSize: 14,
          footerFontSize: 14,
          footerFontStyle: 300,
          callbacks: {
            label: this.drawLabel,
            footer: this.drawFooter
          }
        },
        onClick: this.clickFunction ? this.clickFunction : null,
        scales: {
          yAxes: [
            {
              gridLines: {
                color: "rgba(0,0,0,0)",
                zeroLineColor: "rgba(0,0,0,0)",
                drawBorder: false
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: "rgba(0,0,0,0)",
                zeroLineColor: "rgba(0,0,0,0)",
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 6
              }
            }
          ]
        },
        animation: {
          show: {
            x: { from: 0 },
            y: { from: 0 }
          },
          hide: {
            x: { to: 0 },
            y: { to: 0 }
          }
        }
      }
    };
  },
  created() {
    this.options = _.merge(this.options, this.chartOptions);

    // this.addPlugin(StylePlugin)
    Chart.defaults.global.defaultFontColor = this.theme == "dark" ? "white" : "#342c50";
    Chart.defaults.global.defaultFontFamily = "Encoded Sans";
    Chart.defaults.global.defaultFontFamily = "Verdana";
    // Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);
    // Chart.controllers.roundedBar = Chart.controllers.bar.extend({
    //   dataElementType: Chart.elements.RoundedTopRectangle
    // });
    Dispatcher.$listen(this.chartName, this.redrawChart);
  },
  mounted() {
    this.renderChart(this.formattedChartData, this.options);
  },
  beforeDestroy() {
    Dispatcher.$silence(this.chartName, this.redrawChart);
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    formattedChartData() {
      let chartData = _.merge({}, this.chartData);
      if (chartData && chartData.datasets) {
        let datasets = chartData.datasets;
        chartData.datasets.forEach((dataset) => {
          Object.assign(dataset, {
            shadowOffsetX: 2,
            shadowOffsetY: 2,
            shadowBlur: 5,
            shadowColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: 10
          });
          if (dataset.gradients) {
            let backgroundColors = [];
            if (Array.isArray(dataset.gradients)) {
              dataset.gradients.forEach((gradientSet) => {
                // console.log(this.$refs.canvas.getContext('2d').canvas.clientHeight)
                let gradient = this.$refs.canvas
                  .getContext("2d")
                  .createLinearGradient(0, 0, 0, 150);
                gradientSet.forEach((color, index) => {
                  gradient.addColorStop(index / (gradientSet.length - 1), color);
                });
                backgroundColors.push(gradient);
              });
            }
            dataset["backgroundColor"] = backgroundColors;
          }
        });
      }
      return chartData;
    }
  },
  methods: {
    redrawChart(chartName = false) {
      this.options = _.merge(this.options, this.chartOptions);
      this.renderChart(this.formattedChartData, this.options);
      if (this.$data._chart) {
        this.$data._chart.update();
      }
    },
    drawFooter(item) {
      if ("footers" in this.chartData.datasets[item[0].datasetIndex]) {
        return this.chartData.datasets[item[0].datasetIndex].footers[item[0].index];
      }
    },
    drawLabel(item) {
      let value = parseFloat(item.xLabel);
      console.log(this.chartData.datasets[item.datasetIndex]);
      if ("tooltips" in this.chartData.datasets[item.datasetIndex]) {
        return this.chartData.datasets[item.datasetIndex].tooltips[item.index];
      }
      return this.chartData.datasets[item.datasetIndex].label + " : " + this.formatValue(value);
    },
    formatValue(value) {
      value = value.toFixed(this.decimal);
      if (this.uom) {
        if (this.uom == "%") {
          return value + "%";
        }
      } else {
        return value;
      }
    }
  },
  watch: {
    chartOptions: {
      handler: "redrawChart",
      deep: true
    },
    chartData: {
      handler: "redrawChart",
      deep: true
    }
  }
};
