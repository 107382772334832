<template>
  <div class="help-ticket">
    <ticket
      v-if="ticket"
      :ticket_id="ticket_id"
      :ticket="ticket"
      :users="users"
      :respondFunc="helpRespond"
      :assignFunc="helpAssign"
      :saveTicketFunc="helpSave"
      :closeFunc="helpClose"
      :timeline-error-message="timelineErrorMessage"
    >
      <template v-slot:extraDetails>
        <div class="issue-details">
          <div class="card">
            <div class="card-header">
              <h2>{{ $t("Help Details") }}</h2>
            </div>
            <div
              class="card-content"
              v-if="ticket"
            >
              <div class="detail">
                <label class="label">{{ $t("Tags") }}</label>
                <p>{{ ticket.tagSearch }}</p>
              </div>
              <div class="detail">
                <label class="label">{{ $t("Reason") }}</label>
                <p>{{ ticket.details }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ticket>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";
.help-ticket {
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  .issue-details {
    height: 100%;
    .detail {
      margin: 1rem;
      .label,
      p {
        font-size: 1.25rem;
        margin: 0.5rem 0;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import { Dispatcher } from "@/utils/eventbus";
import { seconds } from "@/utils/filters";

import Ticket from "./Ticket.vue";

export default {
  props: ["ticket_id"],
  data() {
    return {
      users: [],
      loadingTicket: false,
      ticket: null,
      filters: {
        ticketId: { value: this.ticket_id }
      },
      timelineErrorMessage: null
    };
  },
  created() {
    this.loadTicket();
    Dispatcher.$listen("TICKET_UPDATE", this.loadTicket);
  },
  beforeDestroy() {
    Dispatcher.$silence("TICKET_UPDATE");
    this.setTitles({
      title: "",
      mobile: "",
      clickFunction: null
    });
  },
  components: {
    Ticket
  },
  computed: {},
  methods: {
    moment,
    seconds,
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    loadTicket() {
      this.loadingTicket = true;
      const query = `query getByID($id: ID!){
        helpTicket(id: $id){
          id,
          ... on HelpTicketSearch{
            id,
            openedDate,
            openedBy,
            openedByName,
            ticketedDate,
            ticketedBy,
            ticketedByName,
            firstResponseDate,
            firstResponseBy,
            firstResponseByName,
            closedDate,
            closedBy,
            closedByName,
            factoryOrder,
            machineId,
            machineName,
            partId,
            partName,
            assetId,
            assetName,
            details,
            title,
            reference,
            tagSearch,
            assignedTo,
            assignedToNames,
            responding,
            respondingNames,
          }
        }
        users{
          id,
          firstName,
          lastName,
        }
      }`;
      const variables = {
        id: this.ticket_id
      };
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.ticket = res.data.data.helpTicket;
          this.users = res.data.data.users;
          this.loadingTicket = false;
          let back = this.$t("Back");
          this.setTitles({
            title: `<h3 class="backBtn"><i class="fa fa-chevron-left"></i> ${back} </h3>`,
            mobile: `<i class="fa fa-chevron-left"/> ${this.ticket.title}`,
            clickFunction: this.back
          });
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    back() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.close();
      }
    },
    helpAssign(users) {
      let newUserIds = [];
      users.forEach((user) => {
        newUserIds.push(user.id);
      });
      const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
        updateHelpTicket(globalId:$id, helpData:$helpTicketData){
          ok,
          helpTicket{
            assignedTo,
            assignedToNames,
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        helpTicketData: {
          assignNew: newUserIds
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.assignedTo = res.data.data.updateHelpTicket.helpTicket.assignedTo;
            context.ticket.assignedToNames =
              res.data.data.updateHelpTicket.helpTicket.assignedToNames;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    helpRespond(pinJwt) {
      let oldJwt = this.$http.defaults.headers["Authorization"];
      if (pinJwt) {
        this.$http.defaults.headers["Authorization"] = `JWT ${pinJwt}`;
      }
      const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
        updateHelpTicket(globalId:$id, helpData:$helpTicketData){
          ok,
          helpTicket{
            firstResponseBy,
            firstResponseByName,
            firstResponseDate,
            responding,
            respondingNames,
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        helpTicketData: {
          responding: true
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.firstResponseBy =
              res.data.data.updateHelpTicket.helpTicket.firstResponseBy;
            context.ticket.firstResponseByName =
              res.data.data.updateHelpTicket.helpTicket.firstResponseByName;
            context.ticket.firstResponseDate =
              res.data.data.updateHelpTicket.helpTicket.firstResponseDate;
            context.ticket.responding = res.data.data.updateHelpTicket.helpTicket.responding;
            context.ticket.respondingNames =
              res.data.data.updateHelpTicket.helpTicket.respondingNames;
            context.respondingToTicket = false;
            context.respondingToDialog = false;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        })
        .finally(() => {
          this.$http.defaults.headers["Authorization"] = oldJwt;
          this.pinJwt = null; // Clear pinJwt after use
        });
    },
    helpSave() {
      const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
        updateHelpTicket(globalId:$id, helpData:$helpTicketData){
          ok,
          helpTicket{
            reference
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        helpTicketData: {
          reference: this.ticket.reference
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {})
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    helpClose(onDone = null) {
      this.timelineErrorMessage = null;
      const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
        updateHelpTicket(globalId:$id, helpData:$helpTicketData){
          ok,
          helpTicket{
            closedDate,
            closedBy,
            closedByName
          }
        }
      }`;
      const variables = {
        id: this.ticket_id,
        helpTicketData: {
          close: true
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.closedBy = res.data.data.updateHelpTicket.helpTicket.closedBy;
            context.ticket.closedDate = res.data.data.updateHelpTicket.helpTicket.closedDate;
            context.ticket.closedByName = res.data.data.updateHelpTicket.helpTicket.closedByName;
            context.closingTicket = false;
            context.closeTicketDialog = false;
          }, 500);
          if (onDone) onDone();
          if (res.data.errors) {
            this.timelineErrorMessage = res.data.errors[0].message;
          }
        })
        .catch((res) => {
          this.errors = res.errors;
          if (onDone) onDone();
        });
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === "help_ticket") {
        this.loadTicket();
      }
    }
  }
};
</script>
