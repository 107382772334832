export const WORKFLOW_EVENTS_STATUSES = {
  info: "info",
  success: "success",
  error: "error",
  waiting: "waiting",
  progress: "progress"
};

const WORKFLOW_EVENTS_STATUSES_CONFIG = {
  info: ["primary", "mdi-clock-outline"],
  success: ["green", "mdi-check-circle-outline"],
  error: ["red", "mdi-cancel"],
  waiting: ["yellow", "mdi-refresh"],
  progress: ["green", "mdi-update"]
};

const DEFAULT_EVENT_STATUS = WORKFLOW_EVENTS_STATUSES.info;

export const getEventStatusColor = (status) => {
  return WORKFLOW_EVENTS_STATUSES_CONFIG[status]
    ? WORKFLOW_EVENTS_STATUSES_CONFIG[status][0]
    : DEFAULT_EVENT_STATUS[0];
};
export const getEventStatusIcon = (status) => {
  return WORKFLOW_EVENTS_STATUSES_CONFIG[status]
    ? WORKFLOW_EVENTS_STATUSES_CONFIG[status][1]
    : DEFAULT_EVENT_STATUS[1];
};
