<template>
  <div class="mt-4 d-flex align-center">
    <div
      v-for="(group, index) in groupsTree"
      :key="group.id"
      class="d-flex align-center"
    >
      <div
        v-if="index + 1 !== groupsTree.length"
        class="text-subtitle-2 text-decoration-none color--primary weekly-pagination"
        @click="$emit('click', group)"
      >
        {{ group.name }} {{ $t("Drill-in") }}
      </div>

      <div
        v-else
        class="text-subtitle-2 weekly-pagination"
      >
        {{ group.name }} {{ $t("Drill-in") }}
      </div>

      <span
        v-if="index + 1 !== groupsTree.length"
        class="mx-3 color--border-primary"
      >
        /
      </span>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "@vue/composition-api";
import { useGroupsHierarchy } from "@/features/group/useGroupsHierarchy";
import { AppRoutes } from "@/shared/app-routes";

export default {
  name: "ZoneControlWeeklyPagination",
  components: {},
  props: {
    group_id: { type: [Number, String], required: true }
  },
  emits: ["click"],

  setup(props) {
    const { group_id } = toRefs(props);
    const { getGroupsTree } = useGroupsHierarchy();

    const groupsTree = computed(() => getGroupsTree(group_id.value));

    return {
      groupsTree,
      AppRoutes
    };
  }
};
</script>

<style lang="scss" scoped>
.weekly-pagination.weekly-pagination {
  line-height: 24px !important;
}
</style>
