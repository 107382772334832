<template>
  <div class="findings-table card">
    <v-data-table
      height="calc(100% - 3.25rem)"
      :headers="findingsTableData.headers"
      item-key="id"
      :loading="findingsTableData.loading"
      :items="findingsTableData.data"
      :sortBy.sync="findingsTableData.sortBy"
      :sortDesc.sync="findingsTableData.sortDesc"
      :page.sync="findingsTableData.page.current"
      :itemsPerPage.sync="findingsTableData.page.itemsPerPage"
      :serverItemsLength="findingsTableData.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
      :loading-text="$t('Loading Findings... Please wait')"
      disable-pagination
      fixed-header
      multi-sort
    >
      <template v-slot:[`item.title`]="{ item }">
        {{ item.node.title }}
      </template>
      <template v-slot:[`item.ticketedDate`]="{ item }">
        {{ moment(item.node.ticketedDate).format($datetime.default) }}
      </template>
      <template v-slot:[`item.ticketedByName`]="{ item }">
        {{ item.node.ticketedByName }}
      </template>
      <template v-slot:[`item.machineName`]="{ item }">
        {{ item.node.machineName }}
      </template>
      <template v-slot:[`item.link`]="{ item }">
        <v-btn
          class="actionButton"
          small
          color="success"
          fab
          @click.stop.prevent="gotToFinding(item)"
        >
          <v-icon>mdi-format-list-checks</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { TableData } from "@/datatypes/table";

export default {
  data() {
    return {
      findingsTableData: new TableData([
        { text: this.$t("Title"), value: "title" },
        { text: this.$t("Ticketed"), value: "ticketedDate" },
        { text: this.$t("Auditor"), value: "ticketedByName" },
        { text: this.$t("Machine"), value: "machineName" },
        { text: "", value: "link" }
      ]),
      issueTypes: []
    };
  },
  created() {
    this.findingsTableData.loading = true;
    this.loadAuditIssueTypes();
    this.findingsTableData.page.itemsPerPage = 10;
  },
  computed: {
    ...mapGetters({
      titles: "app/Titles"
    })
  },
  methods: {
    moment,
    loadAuditIssueTypes() {
      this.issueTypes = [];
      const query = `query{
        auditTypes(findingsEnabled: true){
          edges{
            node{
              issueType{
                pk
              }
            }
          }
        }
      }`;
      this.$http.post("graphql/", { query }).then((res) => {
        res.data.data.auditTypes.edges.forEach((type) => {
          if (!this.issueTypes.includes(type.node.issueType.pk)) {
            this.issueTypes.push(String(type.node.issueType.pk));
          }
        });
        this.loadData();
      });
    },
    loadData() {
      this.findingsTableData.loading = true;
      const query = `query (
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        issues (
          simpleQueryString: $search,
          filter: $filters,
          ordering: $ordering,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [automatic]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              id,
              title,
              reason,
              openedDate,
              ticketedDate,
              ticketedByName,
              machineId,
              machineName,
              issueTypeId,
              issueTypeName,
            }
          }
        }
      }`;
      let ordering = { ticketedDate: "DESC" };
      if (this.findingsTableData.sortBy.length > 0) {
        ordering = {};
        this.findingsTableData.sortBy.forEach((sortBy, index) => {
          ordering[sortBy] = this.findingsTableData.sortDesc[index] ? "DESC" : "ASC";
        }, this);
      }
      const variables = {
        filters: {
          issueTypeId: { terms: this.issueTypes },
          closedDate: { exists: false }
        },
        ordering: ordering,
        before: false,
        after: false,
        search: ""
      };
      if (this.ticketView == "closed") {
        variables["filters"]["closedDate"]["exists"] = true;
      }
      if (this.findingsTableData.page.load) {
        Object.assign(variables, this.findingsTableData.page.load);
      } else {
        variables["first"] = this.findingsTableData.page.itemsPerPage;
      }
      if (this.findingsTableData.search) {
        variables["search"] = this.findingsTableData.search + "*";
      }
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.findingsTableData.data = res.data.data.issues.edges;
          this.findingsTableData.total = res.data.data.issues.facets.automatic.doc_count;
          this.findingsTableData.page.next = res.data.data.issues.pageInfo.endCursor;
          this.findingsTableData.page.prev = res.data.data.issues.pageInfo.startCursor;
          this.findingsTableData.page.load = false;
          this.findingsTableData.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    gotToFinding(item, body) {
      this.$router.push({ name: "ticket", params: { ticket_id: item.node.id } });
    }
  },
  watch: {
    "findingsTableData.sortBy": function () {
      this.loadData();
    },
    "findingsTableData.sortDesc": function () {
      this.loadData();
    },
    "findingsTableData.page.itemsPerPage": function () {
      this.loadData();
    },
    "findingsTableData.page.current": function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.findingsTableData.page.load = {
          after: this.findingsTableData.page.next,
          before: false,
          first: this.findingsTableData.page.itemsPerPage
        };
        this.loadData();
      } else if (newPage < oldPage) {
        this.findingsTableData.page.load = {
          after: false,
          before: this.findingsTableData.page.prev,
          last: this.findingsTableData.page.itemsPerPage
        };
        this.loadData();
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/variables";
.findings-table {
  margin-top: 0.25rem;
  height: calc(100% - 4rem);
  padding-bottom: 0.5rem;
  .v-data-table {
    height: 100%;
  }
}
</style>
