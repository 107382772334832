<template>
  <div class="col-md-7 ticket-timeline">
    <v-timeline>
      <v-timeline-item
        color="primary"
        v-if="ticket.openedDate"
      >
        <p class="time">{{ moment(ticket.openedDate).format($datetime.default) }}</p>
        <div>
          <label class="label">{{ $t("Anomaly Detected") }}</label>
          <p>{{ ticket.openedByName }}</p>
        </div>
      </v-timeline-item>
      <v-timeline-item
        hide-dot
        class="duration"
        v-if="ticket.openedDate"
      >
        <div
          class="itemDetails"
          v-if="ticket.ticketedDate"
        >
          <p class="duration">
            {{
              seconds(
                moment(ticket.ticketedDate).diff(moment(ticket.openedDate), "seconds"),
                (precise = "somewhat")
              )
            }}
          </p>
        </div>
      </v-timeline-item>
      <v-timeline-item color="green">
        <p class="time">{{ moment(ticket.ticketedDate).format($datetime.default) }}</p>
        <div>
          <label class="label">{{ $t("Ticket Created") }}</label>
          <p>{{ ticket.ticketedByName }}</p>
        </div>
      </v-timeline-item>
      <v-timeline-item
        hide-dot
        class="duration"
      >
        <div
          class="itemDetails"
          v-if="ticket.firstResponseDate"
        >
          <p class="duration">
            {{
              seconds(
                moment(ticket.firstResponseDate).diff(moment(ticket.ticketedDate), "seconds"),
                (precise = "somewhat")
              )
            }}
          </p>
        </div>
      </v-timeline-item>
      <v-timeline-item color="orange">
        <div
          class="itemDetails"
          v-if="ticket.firstResponseDate"
        >
          <p class="time">{{ moment(ticket.firstResponseDate).format($datetime.default) }}</p>
          <div>
            <label class="label">{{ $t("First Response") }}</label>
            <p>{{ ticket.firstResponseByName }}</p>
          </div>
        </div>
      </v-timeline-item>
      <v-timeline-item
        hide-dot
        class="duration"
      >
        <div
          class="itemDetails"
          v-if="ticket.firstResponseDate && ticket.closedDate"
        >
          <p class="duration">
            {{
              seconds(
                moment(ticket.closedDate).diff(moment(ticket.firstResponseDate), "seconds"),
                (precise = "somewhat")
              )
            }}
          </p>
        </div>
      </v-timeline-item>
      <v-timeline-item color="red">
        <div
          class="itemDetails"
          v-if="ticket.closedDate"
        >
          <p class="time">{{ moment(ticket.closedDate).format($datetime.default) }}</p>
          <div>
            <label class="label">{{ $t("Closed") }}</label>
            <p>{{ ticket.closedByName }}</p>
          </div>
        </div>
        <div
          class="itemButton"
          v-else
        >
          <v-dialog
            v-model="closeTicketDialog"
            persistent
            max-width="400px"
            @input="clearErrorMessage"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="error"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t("Close Ticket") }}
              </v-btn>
            </template>
            <v-card>
              <DialogCloseButton :onClick="() => (closeTicketDialog = false)" />
              <v-card-title class="headline">
                <h3>{{ $t("Close this ticket?") }}</h3>
                <h3>{{ ticket.title }}</h3>
                <span
                  v-if="timelineErrorMessage"
                  class="error-message"
                  >{{ timelineErrorMessage }}</span
                >
              </v-card-title>
              <v-card-text></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="closeTicketDialog = false"
                >
                  {{ $t("Cancel") }}
                </v-btn>
                <v-btn
                  color="primary"
                  primary
                  text
                  v-if="closeFunc"
                  :loading="closingTicket"
                  @click="closeTicket()"
                >
                  {{ $t("OK") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-timeline-item>
    </v-timeline>
    <h3>{{ $t("Elapsed Time") }} : {{ elapsedTime }}</h3>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";
@import "../../scss/mq";
.error-message {
  color: indianred;
  font-size: 1rem;
}
.ticket-timeline {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-top: -1rem;
  h3 {
    margin-left: 4rem;
  }
  .theme--dark.v-timeline .v-timeline-item__dot {
    background: none;
  }
  .v-timeline {
    height: calc(100% - 5rem);
    width: 100%;
    .v-timeline-item {
      align-items: center;
      height: 20%;
      &.duration {
        height: 0%;
        text-align: right;
      }
      .v-timeline-item__body,
      .itemDetails {
        display: flex;
        align-items: center;
        .time {
          min-width: 10rem;
          margin-right: 2rem;
        }
        .duration {
          min-width: 8rem;
        }
      }
    }
  }
  .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse):before {
    left: calc(30% - 1px) !important;
  }
  .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)
    .v-timeline-item:nth-child(odd):not(.v-timeline-item--before)
    .v-timeline-item__body {
    max-width: calc(70% - 48px);
  }
}
@include media_below(1000px) {
  .ticket-timeline {
    .v-timeline {
      .v-timeline-item {
        .v-timeline-item__body,
        .itemDetails {
          flex-direction: column;
          align-items: flex-start;
          .time {
            min-width: 40%;
            margin-right: 0.5rem;
          }
          .duration {
            min-width: unset;
          }
        }
      }
    }
    .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)
      .v-timeline-item:nth-child(odd):not(.v-timeline-item--before)
      .v-timeline-item__body {
      flex-direction: column;
    }
  }
}
</style>

<script>
import moment from "moment";

import DialogCloseButton from "@/components/DialogCloseButton";
import { seconds } from "@/utils/filters";

export default {
  props: ["ticket_id", "ticket", "closeFunc", "timelineErrorMessage"],
  components: { DialogCloseButton },
  data() {
    return {
      closeTicketDialog: false,
      closingTicket: false
    };
  },
  computed: {
    elapsedTime() {
      if (this.ticket.openedDate) {
        if (this.ticket.closedDate) {
          return this.seconds(
            moment(this.ticket.closedDate).diff(moment(this.ticket.openedDate), "seconds"),
            "somewhat"
          );
        } else {
          return this.seconds(moment().diff(moment(this.ticket.openedDate), "seconds"), "somewhat");
        }
      } else {
        if (this.ticket.closedDate) {
          return this.seconds(
            moment(this.ticket.closedDate).diff(moment(this.ticket.ticketedDate), "seconds"),
            "somewhat"
          );
        } else {
          return this.seconds(
            moment().diff(moment(this.ticket.ticketedDate), "seconds"),
            "somewhat"
          );
        }
      }
    }
  },
  methods: {
    moment,
    seconds,
    clearErrorMessage() {
      this.timelineErrorMessage = null;
    },
    onDone() {
      this.closingTicket = false;
    },
    closeTicket() {
      this.closingTicket = true;
      this.closeFunc(this.onDone);
    }
  }
};
</script>
