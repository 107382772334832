import Chart from "chart.js";
import StylePlugin from "chartjs-plugin-style";
import { Line, mixins } from "vue-chartjs";
import { mapGetters } from "vuex";

import { Dispatcher } from "@/utils/eventbus";

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartName: { default: "line-chart" },
    chartData: { default: false },
    clickFunction: { default: false },
    uom: { default: false },
    decimal: { default: 0 },
    chartOptions: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      options: {
        cornerRadius: 100,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          display: false,
          labels: {
            filter: function (item) {
              return item.text ? !item.text.includes("exclude") : false;
            },
            titleFontFamily: "IBM Plex Sans Condensed"
          }
        },
        tooltips: {
          mode: "index",
          intersect: false,
          titleFontFamily: "IBM Plex Sans Condensed",
          titleFontSize: 14,
          bodyFontSize: 14,
          footerFontSize: 14,
          footerFontStyle: 300,
          callbacks: {
            label: this.drawLabel,
            footer: this.drawFooter
          }
        },
        onClick: this.clickFunction ? this.clickFunction : null,
        scales: {
          yAxes: [
            {
              type: "linear",
              position: "left",
              gridLines: {
                color: this.gridColor,
                borderColor: this.gridColor,
                zeroLineColor: this.gridColor,
                zeroLineWidth: 1,
                drawBorder: false,
                borderDash: [5, 5],
                borderDashOffset: 0.0,
                borderWidth: 1,
                lineWidth: 1,
                drawOnChartArea: true,
                drawTicks: false
              },
              ticks: {
                beginAtZero: true,
                titleFontFamily: "IBM Plex Sans Condensed",
                maxTicksLimit: 6,
                padding: 8
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: this.gridColor,
                borderColor: this.gridColor,
                zeroLineColor: this.gridColor,
                zeroLineWidth: 1,
                drawBorder: false,
                borderDash: [5, 5],
                borderDashOffset: 0.0,
                borderWidth: 1,
                lineWidth: 1,
                drawOnChartArea: true,
                drawTicks: false
              },
              ticks: {
                padding: 8,
                titleFontFamily: "IBM Plex Sans Condensed"
              }
            }
          ]
        },
        animation: {
          show: {
            x: { from: 0 },
            y: { from: 0 }
          },
          hide: {
            x: { to: 0 },
            y: { to: 0 }
          }
        }
      }
    };
  },
  created() {
    this.options.scales.xAxes[0].gridLines.zeroLineColor = this.gridColor;
    this.options.scales.xAxes[0].gridLines.color = this.gridColor;
    this.options.scales.yAxes[0].gridLines.zeroLineColor = this.gridColor;
    this.options.scales.yAxes[0].gridLines.color = this.gridColor;
    this.options = _.merge(this.options, this.chartOptions);
    this.addPlugin(StylePlugin);
    this.addPlugin({
      afterLayout: (chart) => {
        if (this.chartData.threshold) {
          let ctx = chart.chart.ctx;
          ctx.save();
          let yAxis = chart.scales["y-axis-0"];
          let yThreshold = yAxis.getPixelForValue(this.chartData.threshold);
          let gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
          gradient.addColorStop(0, "green");
          let offset = (1 / yAxis.bottom) * yThreshold;
          gradient.addColorStop(offset, this.$colors.chart.good);
          gradient.addColorStop(offset, this.$colors.chart.bad);
          gradient.addColorStop(1, this.$colors.chart.bad);
          chart.data.datasets[0].borderColor = gradient;
          ctx.restore();
        }
      }
    });
    Chart.defaults.global.defaultFontColor = this.fontColor;
    Chart.defaults.global.defaultFontFamily = "Encoded Sans, Verdana";
    Dispatcher.$listen(this.chartName, this.redrawChart);
  },
  mounted() {
    this.renderChart(this.formattedChartData, this.options);
  },
  beforeDestroy() {
    Dispatcher.$silence(this.chartName, this.redrawChart);
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    isLightTheme() {
      return this.theme === "light";
    },
    gridColor() {
      return this.isLightTheme ? "#0000001A" : "#FFFFFF33";
    },
    fontColor() {
      return this.isLightTheme ? "#6F6F6F" : "#8D8D8D";
    },
    formattedChartData() {
      let chartData = _.merge({}, this.chartData);
      if (chartData && chartData.datasets) {
        // let datasets = chartData.datasets;
        chartData.datasets.forEach((dataset) => {
          Object.assign(dataset, {
            // shadowOffsetX: this.theme == "dark" ? 3 : 1,
            // shadowOffsetY: this.theme == "dark" ? 3 : 1
            // shadowBlur: this.theme == "dark" ? 10 : 2
            // shadowColor: this.theme == "dark" ? "rgba(0, 0, 0, 0.5)" : "#d1d9e6"
          });
        });
      }
      return chartData;
    }
  },
  methods: {
    redrawChart() {
      Chart.defaults.global.defaultFontColor = this.fontColor;
      this.options.scales.xAxes[0].gridLines.zeroLineColor = this.gridColor;
      this.options.scales.xAxes[0].gridLines.color = this.gridColor;
      this.options.scales.yAxes[0].gridLines.zeroLineColor = this.gridColor;
      this.options.scales.yAxes[0].gridLines.color = this.gridColor;
      this.options = _.merge(this.options, this.chartOptions);
      this.renderChart(this.formattedChartData, this.options);
      if (this.$data._chart) {
        this.$data._chart.update();
      }
    },
    drawFooter(item) {
      if ("footers" in this.chartData.datasets[item[0].datasetIndex]) {
        return this.chartData.datasets[item[0].datasetIndex].footers[item[0].index];
      }
    },
    drawLabel(item) {
      let value = parseFloat(item.yLabel);
      if ("tooltips" in this.chartData.datasets[item.datasetIndex]) {
        return this.chartData.datasets[item.datasetIndex].tooltips[item.index];
      }
      return this.chartData.datasets[item.datasetIndex].label + " : " + this.formatValue(value);
    },
    formatValue(value) {
      value = value.toFixed(this.decimal);
      if (this.uom) {
        if (this.uom == "%") {
          return value + "%";
        }
      } else {
        return value;
      }
    }
  },
  watch: {
    chartOptions: {
      handler: "redrawChart",
      deep: true
    },
    chartData: {
      handler: "redrawChart",
      deep: true
    },
    theme: {
      handler: "redrawChart",
      deep: true
    }
  }
};
