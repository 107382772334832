<template>
  <div class="summary-content">
    <div class="card table-card">
      <div class="table-card-header">
        <v-tabs
          class="summary-tabs"
          fixed-tabs
          v-model="tab"
          align-with-title
          key="tabs"
        >
          <v-tab
            key="order-by-machines"
            :show-arrows="false"
          >
            <p>
              <span class="button_text">{{ $t("Order by Machines") }}</span>
              <span class="tiny_button_text">{{ $t("Order by Machines") }}</span>
            </p>
          </v-tab>
          <v-tab key="order-by-issues">
            <p>
              <span class="button_text">{{ $t("Order by Issues") }}</span>
              <span class="tiny_button_text">{{ $t("Order by Issues") }}</span>
            </p>
          </v-tab>
        </v-tabs>
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          class="text-search"
          single-line
          hide-details
          clearable
        >
        </v-text-field>
      </div>
      <div class="card-content">
        <v-data-table
          v-if="tab === 0"
          :headers="orderByMachinesHeaders"
          item-key="machineOrGroupName"
          :items="filteredIssuesByMachine"
          :loading="loading"
          show-expand
          fixed-header
        >
          <template v-slot:item.machineOrGroupName="{ item }">
            <div class="machine-name">
              <p>{{ item.machineOrGroupName }}</p>
              <v-icon
                class="chart-icon"
                size="20"
                @click="handleMachineIconClick(item)"
              >
                mdi-chart-line
              </v-icon>
            </div>
          </template>
          <template v-slot:item.reason="{ item }">
            <span class="reason">{{ $t("Total") }}</span>
          </template>
          <template v-slot:item.issueTotal="{ item }">
            <div class="total">
              <span class="total-text">{{ item.issueTotal }}</span>
              <v-icon
                class="chart-icon"
                size="20"
                @click="handleIssuesPerIntervalIconClick(item, false, item.key, null)"
                >mdi-chart-line</v-icon
              >
            </div>
          </template>
          <template v-slot:item.closedTotal="{ item }">
            <div class="closed">
              <span class="closed-text">{{ item.closedTotal }}</span>
              <v-icon
                class="chart-icon"
                size="20"
                @click="handleIssuesPerIntervalIconClick(item, true, item.key, null)"
                >mdi-chart-line</v-icon
              >
            </div>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td></td>
            <td></td>
            <td>
              <ul class="expanded">
                <li
                  class="reason"
                  v-for="reason in item.reasons.buckets"
                >
                  {{ reason.issueName }}
                </li>
              </ul>
            </td>
            <td class="expanded">
              <ul>
                <div
                  class="total"
                  v-for="reason in item.reasons.buckets"
                >
                  <li>{{ reason.doc_count }}</li>
                  <v-icon
                    class="chart-icon"
                    size="20"
                    @click="handleIssuesPerIntervalIconClick(item, false, item.key, reason.key)"
                    >mdi-chart-line
                  </v-icon>
                </div>
              </ul>
            </td>
            <td class="expanded">
              <ul>
                <div
                  class="closed"
                  v-for="reason in item.reasons.buckets"
                >
                  <li>{{ reason.closed.doc_count }}</li>
                  <v-icon
                    class="chart-icon"
                    size="20"
                    @click="handleIssuesPerIntervalIconClick(item, true, item.key, reason.key)"
                    >mdi-chart-line</v-icon
                  >
                </div>
              </ul>
            </td>
          </template>
        </v-data-table>
        <v-data-table
          v-if="tab === 1"
          :headers="orderByIssuesHeaders"
          item-key="issueName"
          :items="filteredMachinesByIssue"
          show-expand
          fixed-header
        >
          <template v-slot:item.issueName="{ item }">
            <div class="issue-name">
              <p>{{ item.issueName.slice(0, -1) }}</p>
              <v-icon
                class="chart-icon"
                size="20"
                @click="handleIssueIconClick(item)"
              >
                mdi-chart-line
              </v-icon>
            </div>
          </template>
          <template v-slot:item.relation="{ item }">
            <span class="machine">{{ $t("Total") }}</span>
          </template>
          <template v-slot:item.machineTotal="{ item }">
            <div class="total">
              <span class="total-text">{{ item.machineTotal }}</span>
              <!-- Order by Issues / Bold Total -->
              <v-icon
                class="chart-icon"
                size="20"
                @click="handleIssuesPerIntervalIconClick(item, false, null, item.key)"
              >
                mdi-chart-line
              </v-icon>
            </div>
          </template>
          <template v-slot:item.closedTotal="{ item }">
            <div class="closed">
              <span class="closed-text">{{ item.closedTotal }}</span>
              <v-icon
                class="chart-icon"
                size="20"
                @click="handleIssuesPerIntervalIconClick(item, true, null, item.key)"
                >mdi-chart-line</v-icon
              >
            </div>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td></td>
            <td></td>
            <td>
              <ul class="expanded">
                <li
                  class="machine"
                  v-for="machine in item.machines.buckets"
                >
                  {{ machine.machineName }}
                </li>
              </ul>
            </td>
            <td class="expanded">
              <ul>
                <div
                  class="total"
                  v-for="machine in item.machines.buckets"
                >
                  <li>{{ machine.doc_count }}</li>
                  <!-- Order by Issues / Normal Total -->
                  <v-icon
                    class="chart-icon"
                    size="20"
                    @click="handleIssuesPerIntervalIconClick(item, false, machine.key, item.key)"
                  >
                    mdi-chart-line
                  </v-icon>
                </div>
              </ul>
            </td>
            <td class="expanded">
              <ul>
                <div
                  class="closed"
                  v-for="machine in item.machines.buckets"
                >
                  <li>{{ machine.closed.doc_count }}</li>
                  <v-icon
                    class="chart-icon"
                    size="20"
                    @click="handleIssuesPerIntervalIconClick(item, true, machine.key, item.key)"
                  >
                    mdi-chart-line
                  </v-icon>
                </div>
              </ul>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="card chart-card">
      <div class="card-content">
        <div class="header">
          <v-btn icon>
            <v-icon
              class="eraser-icon"
              large
              @click="handleRemoveCharts"
            >
              mdi-eraser
            </v-icon>
          </v-btn>
        </div>
        <div
          class="group"
          v-for="(group, groupIndex) in groupCharts"
        >
          <h2>{{ group.name }}</h2>
          <div class="charts">
            <div
              class="pieContainer chartContainer"
              v-if="group.pieChart"
            >
              <PieChart
                :chartData="group.pieChart"
                :chart-options="pieChartOptions"
                :height="250"
              />
            </div>
            <div
              class="lineContainer chartContainer"
              v-if="group.lineChartData"
              v-for="data in group.lineChartData"
            >
              <div class="chart-title">{{ $t(data.title) }}</div>
              <LineChart
                :chart-data="data"
                :chart-options="lineChartOptions"
                :height="250"
                :decimal="0"
              />
            </div>
            <div
              class="barContainer chartContainer"
              v-if="group.barChartData"
              v-for="data in group.barChartData"
            >
              <div class="chart-title">{{ $t(data.title) }}</div>
              <BarChart
                :chart-data="data"
                :chart-options="barChartOptions"
                :height="250"
                :decimal="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import PieChart from "@/components/charts/PieChart";

import BarChart from "../../../components/charts/BarChart";
import LineChart from "../../../components/charts/LineChart";

export default {
  props: {
    enlargedTable: { type: Object, default: () => {} },
    reasons: { type: Array, default: () => [] },
    machineGroupPk: { type: Number },
    machines: { type: Array, default: () => [] },
    selectedInterval: { type: String, default: "" },
    setPresetDates: { type: Function, default: () => null },
    setInterval: { type: Function, default: () => null }
  },
  components: {
    LineChart,
    BarChart,
    PieChart
  },
  data() {
    return {
      searchText: "",
      loading: false,
      tab: 0,
      issuesByMachine: [],
      machinesByIssue: [],
      issuesPerInterval: [],
      orderByMachinesHeaders: [
        { text: "", value: "data-table-expand", cellClass: "first-expand-icon" },
        {
          text: this.$t("Machine or Group"),
          value: "machineOrGroupName",
          sortable: true,
          width: "33%"
        },
        {
          text: this.$t("Issues"),
          value: "reason",
          sortable: false,
          cellClass: "reason-text",
          width: "10%"
        },
        {
          text: this.$t("Total"),
          value: "issueTotal",
          sortable: true,
          cellClass: "total-text",
          width: "30%"
        },
        {
          text: this.$t("Closed"),
          value: "closedTotal",
          sortable: true,
          cellClass: "closed-text",
          width: "30%"
        },
        { text: "", value: "data-table-expand", cellClass: "second-expand-icon", sortable: false }
      ],
      orderByIssuesHeaders: [
        { text: "", value: "data-table-expand", cellClass: "first-expand-icon" },
        {
          text: this.$t("Issues"),
          value: "issueName",
          sortable: true,
          cellClass: "reason-text",
          width: "25%"
        },
        { text: this.$t("Machine or Group"), value: "relation", sortable: false, width: "25%" },
        {
          text: this.$t("Total"),
          value: "machineTotal",
          sortable: true,
          cellClass: "total-text",
          width: "30%"
        },
        {
          text: this.$t("Closed"),
          value: "closedTotal",
          sortable: true,
          cellClass: "closed-text",
          width: "30%"
        },
        { text: "", value: "data-table-expand", cellClass: "second-expand-icon", sortable: false }
      ],
      groupCharts: []
    };
  },
  created() {
    if (this.enlargedTable.dates.start) {
      this.setPresetDates(
        moment(this.enlargedTable.dates.start).format("D MMM"),
        moment(this.enlargedTable.dates.end).format("D MMM")
      );
    } else {
      this.setPresetDates(this.$t("Before"), moment(this.enlargedTable.dates.end).format("D MMM"));
    }
    this.getIssues();
    // determine which card and set preset accordingly
  },
  beforeDestroy() {
    this.handleRemoveCharts();
  },
  computed: {
    filteredIssuesByMachine() {
      if (this.searchText && this.issuesByMachine.length > 0) {
        return this.issuesByMachine.filter((machine) =>
          machine.machineOrGroupName.toUpperCase().includes(this.searchText.toUpperCase())
        );
      }
      return this.issuesByMachine;
    },
    filteredMachinesByIssue() {
      if (this.searchText && this.machinesByIssue.length > 0) {
        return this.machinesByIssue.filter((issue) =>
          issue.issueName.toUpperCase().includes(this.searchText.toUpperCase())
        );
      }
      return this.machinesByIssue;
    },
    reasonIds() {
      let reasonIds = [];
      this.reasons.forEach((reason) => {
        if (reason.node.pk && !(reason.node.pk in reasonIds)) {
          reasonIds.push(reason.node.pk.toString());
        }
      });
      return reasonIds;
    },
    pieChartOptions() {
      let defaults = {
        legend: {
          maxWidth: "50px"
        }
      };
      return defaults;
    },
    barChartOptions() {
      let defaults = {
        /* scales: {
          scaleLineColor: 'transparent',
          yAxes: [{display: false}],
        } */
      };
      return defaults;
    },
    lineChartOptions() {
      let defaults = {
        /* scales: {
          scaleLineColor: 'transparent',
          yAxes: [{display: false}],
        } */
      };
      return defaults;
    }
  },
  methods: {
    getIssues(fromDate = null, toDate = null) {
      const params = {
        reason_ids: this.reasonIds,
        machine_group_id: this.machineGroupPk,
        from_date: fromDate
          ? fromDate.toISOString()
          : this.enlargedTable.dates.start
            ? this.enlargedTable.dates.start.toISOString()
            : "",
        to_date: toDate ? toDate.toISOString() : this.enlargedTable.dates.end.toISOString()
      };
      this.loading = true;
      this.$http.get("exceptions/issues_by_machine/", { params }).then((response) => {
        this.issuesByMachine = response.data;
        this.issuesByMachine.forEach((item) => {
          item.issueTotal = 0;
          item.closedTotal = 0;
          const foundMachine = this.machines.find((machine) => machine.pk.toString() === item.key);
          item.machineOrGroupName = foundMachine && foundMachine.name ? foundMachine.name : "";
          item.reasons.buckets.forEach((type) => {
            const foundReason = this.reasons.find(
              (reason) => reason.node.pk.toString() === type.key
            );
            type.issueName =
              foundReason && foundReason.node.text ? foundReason.node.text : this.$t("Other");
            item.issueTotal += type.doc_count;
            item.closedTotal += type.closed.doc_count;
          });
        });
        this.loading = false;
      });
      this.loading = true;
      this.$http.get("exceptions/machines_per_issue_type/", { params }).then((response) => {
        this.machinesByIssue = response.data;
        this.machinesByIssue.forEach((item, index) => {
          item.machineTotal = 0;
          item.closedTotal = 0;
          const foundIssue = this.reasons.find((reason) => reason.node.pk.toString() === item.key);
          item.issueName =
            foundIssue && foundIssue.node.text
              ? foundIssue.node.text + index
              : this.$t("Other") + " ";
          item.machines.buckets.forEach((bucket) => {
            const foundMachine = this.machines.find(
              (machine) => machine.pk.toString() === bucket.key
            );
            bucket.machineName = foundMachine && foundMachine.name ? foundMachine.name : "";
            item.machineTotal += bucket.doc_count;
            item.closedTotal += bucket.closed.doc_count;
          });
        });
      });
      this.loading = false;
    },
    async getIssuesPerInterval(chartData) {
      const params = {
        from_date: chartData.from_date.toISOString(),
        to_date: chartData.to_date.toISOString(),
        interval: chartData.scale,
        machine_id: chartData.machine_id,
        closed: chartData.closed ? true : undefined
      };
      if (chartData.reason_id) {
        params["reason_id"] = chartData.reason_id;
      } else {
        params["reason_ids"] = this.reasonIds;
      }
      this.loading = true;
      const response = await this.$http.get("exceptions/issues_per_interval/", { params });
      chartData.response = response.data;
      this.loading = false;
    },
    orderCharts(item) {
      let groupNameField = this.tab == 0 ? "machineOrGroupName" : "issueName";
      let itemIndex = this.groupCharts.findIndex((group) => group.name === item[groupNameField]);
      if (itemIndex) {
        const elm = this.groupCharts.splice(itemIndex, 1)[0];
        this.groupCharts.unshift(elm);
      }
    },
    handleMachineIconClick(item) {
      let group = this.groupCharts.find((group) => group.name === item.machineOrGroupName);
      if (!group) {
        group = {
          name: item.machineOrGroupName,
          pieChart: false,
          lineChartData: [],
          barChartData: []
        };
        this.groupCharts.unshift(group);
      }
      if (!group.pieChart) {
        const chartData = {};
        chartData.datasets = [{}];
        chartData.title = item.machineOrGroupName;
        chartData.labels = [];
        chartData.datasets[0].data = [];
        item.reasons.buckets.forEach((bucket) => {
          if (bucket.issueName.length > 20) {
            chartData.labels.push(bucket.issueName.substring(0, 20) + "...");
          } else {
            chartData.labels.push(bucket.issueName);
          }

          chartData.datasets[0].data.push(bucket.doc_count);
        });
        group["pieChart"] = chartData;
        this.showPieCharts = true;
      }
      this.orderCharts(item);
    },
    handleIssueIconClick(item) {
      let group = this.groupCharts.find((group) => group.name === item.issueName);
      if (!group) {
        group = {
          name: item.issueName,
          pieChart: false,
          lineChartData: [],
          barChartData: []
        };
        this.groupCharts.unshift(group);
      }
      if (!group.pieChart) {
        const chartData = {};
        chartData.datasets = [{}];
        chartData.title = item.issueName;
        chartData.labels = [];
        chartData.datasets[0].data = [];
        item.machines.buckets.forEach((bucket) => {
          chartData.labels.push(bucket.machineName);
          chartData.datasets[0].data.push(bucket.doc_count);
        });
        group["pieChart"] = chartData;
        this.showPieCharts = true;
      }
      this.orderCharts(item);
    },
    handleRemoveCharts() {
      this.groupCharts = [];
    },
    async handleIssuesPerIntervalIconClick(
      item,
      closed = false,
      machine_id = null,
      reason_id = null
    ) {
      let groupNameField = this.tab == 0 ? "machineOrGroupName" : "issueName";
      let group = this.groupCharts.find((group) => group.name === item[groupNameField]);
      if (!group) {
        group = {
          name: item[groupNameField],
          pieChart: false,
          lineChartData: [],
          barChartData: []
        };
        this.groupCharts.unshift(group);
      }
      let title = "";
      let chartType = closed ? "line" : "bar";
      let dataset = {
        data: [],
        label: this.$t("Issues")
      };

      if (closed) {
        dataset["pointBackgroundColor"] = "#AD84C6";
        dataset["backgroundColor"] = "transparent";
        dataset["borderColor"] = "#ae84c67d";
      } else {
        dataset["backgroundColor"] = "#784a92";
      }
      let chartData = {
        labels: [],
        datasets: [dataset],
        machine_id: machine_id,
        reason_id: reason_id,
        closed: closed
      };
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty("machineOrGroupName")) {
        if (reason_id !== null) {
          title += `${item.reasons.buckets.find((reason) => reason.key === reason_id).issueName} - `;
        }
        // eslint-disable-next-line no-prototype-builtins
      } else if (item.hasOwnProperty("issueName")) {
        if (machine_id !== null) {
          title += `${item.machines.buckets.find((machine) => machine.key === machine_id).machineName} - `;
        }
      }
      if (closed) {
        title += this.$t("Closed Issues");
      } else {
        title += this.$t("Total Issues");
      }
      this.setPreset(this.selectedInterval, chartData);
      await this.getIssuesPerInterval(chartData);
      chartData.response.forEach((issue) => {
        chartData.labels.push(this.getTimeLabel(issue.key_as_string, chartData.dateFormat));
        chartData.datasets[0].data.push(issue.doc_count);
      });
      chartData.title = title;
      this.addChart(chartType, chartData, group);
      this.orderCharts(item);
    },
    addChart(chartType, chartData, group) {
      if (
        group[`${chartType}ChartData`].filter((chart) => chart.title === chartData.title).length > 0
      ) {
        return;
      }
      group[`${chartType}ChartData`].push(chartData);
      group[`show${chartType.charAt(0).toUpperCase() + chartType.slice(1)}Charts`] = true;
    },
    setPreset(preset, chartData = null) {
      let from_date = moment();
      let to_date = moment();
      let dateFormat = "D MMM hhA";
      let scale = "1h";
      switch (preset) {
        case "card":
          from_date = this.enlargedTable.dates.start;
          to_date = this.enlargedTable.dates.end;
          dateFormat = "D MMM";
          scale = "1d";
          if (!from_date) {
            from_date = moment().endOf("month").subtract(12, "months");
            dateFormat = "MMM YY";
            scale = "1M";
          }
          break;
        case "shift":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          // if (this.shift.length===1 && this.shift[0].shiftDays.length===1){
          //   from_date = moment(this.shift[0].shiftDays[0].nextStartDatetime)
          //   to_date = moment(this.shift[0].shiftDays[0].nextEndDatetime)
          // }
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "day":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "week":
          from_date = moment().startOf("week");
          to_date = moment().endOf("week");
          dateFormat = "D MMM";
          scale = "1d";
          break;
        case "month":
          from_date = moment().startOf("month");
          to_date = moment().endOf("month");
          dateFormat = "week";
          scale = "1w";
          break;
        case "year":
          from_date = moment().endOf("month").subtract(12, "months");
          to_date = moment().endOf("month");
          dateFormat = "MMM YY";
          scale = "1M";
          break;
      }
      if (chartData) {
        Object.assign(chartData, {
          scale: scale,
          preset: preset,
          dateFormat: dateFormat,
          from_date: from_date,
          to_date: to_date
        });
      }
      this.setPresetDates(
        moment(from_date).format(dateFormat === "week" ? "D MMM" : dateFormat),
        moment(to_date).format(dateFormat === "week" ? "D MMM" : dateFormat)
      );
      this.getIssues(from_date, to_date);
    },
    async updateChart(preset, chartData) {
      this.setPreset(preset, chartData);
      await this.getIssuesPerInterval(chartData);
      chartData.labels = [];
      let dataset = {
        data: [],
        label: this.$t("Issues")
      };
      if (chartData.closed) {
        dataset["pointBackgroundColor"] = "#AD84C6";
        dataset["backgroundColor"] = "transparent";
        dataset["borderColor"] = "#ae84c67d";
      } else {
        dataset["backgroundColor"] = "#784a92";
      }
      chartData.datasets = [dataset];
      chartData.response.forEach((issue) => {
        chartData.labels.push(this.getTimeLabel(issue.key_as_string, chartData.dateFormat));
        chartData.datasets[0].data.push(issue.doc_count);
      });
    },
    getTimeLabel(timeString, dateFormat) {
      if (dateFormat == "week") {
        let start = moment(timeString);
        let end = moment(timeString).add(7, "d");
        if (start.month() != end.month()) {
          return start.format("D MMM") + " - " + end.format("D MMM");
        } else {
          return start.format("D") + " - " + end.format("D MMM");
        }
      } else {
        return moment(timeString).format(dateFormat);
      }
    }
  },
  watch: {
    tab() {
      this.handleRemoveCharts();
      this.setInterval("card");
    },
    selectedInterval() {
      this.groupCharts.forEach((group) => {
        group.lineChartData.forEach((chart) => {
          this.updateChart(this.selectedInterval, chart);
        });
        group.barChartData.forEach((chart) => {
          this.updateChart(this.selectedInterval, chart);
        });
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
.theme--dark .table-card,
.theme--dark .chart-card {
  background: $background;
  box-shadow: none;
  tr:hover {
    background-color: transparent !important;
    color: white !important;
  }
}
.theme--dark .table-card .v-data-table__wrapper > table > thead > tr > th {
  background-color: $background !important;
}
.theme--light {
  .summary-tabs {
    .v-tab--active {
      color: $darkGrey;
    }
    .v-tabs-slider-wrapper {
      color: $darkGrey;
    }
  }
}
.summary-content {
  display: flex;
  margin-top: 1rem;
  height: calc(100% - 2rem);
  min-height: 95%;
  .card {
    width: 50%;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    .table-card-header {
      display: flex;
      align-items: center;
      .summary-tabs {
        margin: 1rem;
      }
      .v-text-field {
        margin: 0 1rem;
      }
    }
    .card-content {
      margin: 0 1rem;
      padding: 0;
      &:after {
        display: none;
      }
      .v-data-table {
        height: calc(100% - 3.5rem);
        thead {
          font-family: "$condensed";
        }
        tbody {
          tr {
            td {
              border: none !important;
            }
          }
        }
        .v-icon:hover {
          cursor: pointer;
        }
        .machine-name,
        .issue-name,
        .total,
        .closed {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        .reason,
        .machine {
          font-weight: 700;
        }
        .v-data-table__expanded__content {
          td {
            .expanded {
              text-align: left;
              .reason,
              .machine,
              .total,
              .closed {
                font-weight: 400;
                padding-bottom: 0.5rem;
              }
            }
          }
          td:nth-of-type(4) {
            .total {
              padding-bottom: 0.5rem;
            }
          }
          td:nth-of-type(5) {
            .closed {
              padding-bottom: 0.5rem;
            }
          }
        }
        .total-text,
        .closed-text {
          font-weight: 700;
        }
        ul {
          list-style: none;
          padding: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .chart-icon {
          color: $blue;
          margin-left: 15px;
          margin-right: auto;
        }
      }
      .group {
        margin-bottom: 2rem;
        h2 {
          color: $blue;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .charts {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          max-width: 100%;
          overflow-x: auto;
          background-color: rgba(0, 0, 0, 0.2);
          padding: 1rem;
          border-radius: 10px;
          .chart-title {
            color: $blue;
            margin-bottom: 0.5rem;
          }
          .chartContainer {
            margin: 0;
            flex-basis: 50%;
            width: 50%;
            padding: 0.5rem 0.25rem;
          }
          .pieContainer {
            max-height: 250px;
          }
        }
      }
    }
  }
  .chart-card {
    margin-left: 1.5rem;
    .header {
      margin: 1rem;
      display: flex;
      height: 48px;
      justify-content: flex-end;
      .v-icon:hover {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .v-slide-group__prev {
    display: none !important;
  }
  .theme--dark .summary-content {
    background: transparent;
  }
  .summary-content {
    flex-wrap: wrap;
    min-height: 0;
    .table-card td {
      align-items: flex-start;
    }
    .card {
      width: 100%;
      .card-content {
        margin: 0;
        padding: 0 0.25rem !important;
        .v-data-table {
          button {
            position: relative !important;
            width: auto !important;
          }
        }
      }
    }
    .chart-card {
      margin-left: 0.5rem;
      .card-content {
        .header {
          margin-right: 0;
          margin-bottom: 0;
        }
        .chart-row {
          padding: 0 1rem;
          flex-wrap: wrap;
          .chart {
            width: 100%;
          }
        }
        .chartContainer {
          flex-basis: 50%;
          width: 50%;
        }
      }
    }
  }
}
@media screen and (min-width: 601px) {
  .v-data-table {
    height: 100%;
    tr {
      .second-expand-icon {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .summary-tabs {
    font-size: 0.2rem;
  }
  .card-header {
    flex-wrap: wrap;
    justify-content: flex-start;
    .preset-container .preset-title-buttons-container {
      width: 100%;
      margin-top: 0.5rem;
    }
  }
  .card-content {
    .summary-content {
      margin-top: 2.5rem;
      .table-card-header {
        flex-wrap: wrap;
        .v-text-field {
          margin: 0 !important;
        }
        .text-search {
          .v-input__slot {
            border-bottom: 1px solid;
          }
        }
      }
      .v-data-table {
        height: 100%;
        tr {
          padding-bottom: 0 !important;
          padding-top: 1rem;
          .v-data-table__mobile-row {
            min-height: 0 !important;
          }
          td:first-of-type {
            // Can't set display none to hide expand icon because
            // v-data-table__mobile-row has display: flex !important
            // in ZoneControl.vue;
            visibility: hidden;
            height: 0px !important;
          }
          td:last-of-type {
            .v-data-table__mobile-row__header {
              display: none;
            }
            .v-data-table__mobile-row__cell {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .v-data-table__expanded__content {
    border-bottom: solid 2px $blue !important;
    padding-bottom: 1rem !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    td {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    td:last-of-type {
      padding-left: 1.5rem !important;
      height: 28px !important;
    }
    .expanded {
      padding-left: 1.5rem !important;
      button {
        margin-left: 0.5rem;
      }
    }
  }
  .chartContainer {
    flex-basis: auto !important;
    width: 100% !important;
  }
}
</style>
