<template>
  <div class="zone-control-enlarged">
    <div class="d-flex justify-space-between mb-7">
      <div>
        <h6 class="text-uppercase text-subtitle-1 mb-2">{{ issuesTitle }}</h6>
        <div class="text-h6 font-weight-medium">{{ fromDate }} - {{ toDate }}</div>
      </div>

      <div class="d-flex align-center">
        <BtnToggle class="d-inline-block preset-container">
          <v-btn
            v-for="btn in intervals"
            :key="btn.value"
            :class="{ active: btn.value == selectedInterval }"
            @click="handlePresetClick(btn.value)"
          >
            {{ btn.name }}
          </v-btn>
        </BtnToggle>

        <Btn
          class="ml-4 color--primary"
          icon
          large
          @click.stop.prevent="closeTable"
        >
          <v-icon color="primary">mdi-arrow-collapse</v-icon>
        </Btn>
      </div>
    </div>

    <ZoneControlSummary
      ref="summary"
      :enlarged-table="enlargedTable"
      :reasons="reasons"
      :machine-group-pk="machineGroupPk"
      :machines="machines"
      :selected-interval="selectedInterval"
      :set-preset-dates="setPresetDates"
      :set-interval="setInterval"
    />
  </div>
</template>

<script>
import Btn from "@/ui/Btn";
import BtnToggle from "@/components/BtnToggle.vue";
import ZoneControlSummary from "./ZoneControlSummary.vue";

export default {
  name: "ZoneControlEnlargedTable",
  components: {
    Btn,
    BtnToggle,
    ZoneControlSummary
  },
  props: {
    issuesTitle: {
      type: String,
      default: "",
      required: true
    },
    enlargedTable: { type: Object, default: () => {} },
    reasons: { type: Array, default: () => [] },
    machineGroupPk: { type: Number, default: undefined },
    machines: { type: Array, default: () => [] },
    selectedInterval: { type: String, default: "" },
    setPresetDates: { type: Function, default: () => null },
    getInprocess: { type: Function, default: () => null },
    getLatest: { type: Function, default: () => null },
    getOldest: { type: Function, default: () => null },
    enlargedIssueHeaders: { type: Array, default: () => [] },
    loadIssuePage: { type: Function, default: () => null },
    handleItemsPerPageChange: { type: Function, default: () => null },
    getStatusIcon: { type: Function, default: () => null },
    closeTable: { type: Function, default: () => null },
    fromDate: { type: String, default: "" },
    toDate: { type: String, default: "" },
    handlePresetClick: { type: Function, default: () => null },
    intervals: { type: Array, default: () => [] },
    setInterval: { type: Function, default: () => null },
    loadIssues: { type: Function, default: () => null }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/mq";

@include media_below(1025px) {
  @media screen and (orientation: portrait) {
    .preset-container {
      max-width: 50vw;
    }
  }
}
</style>
