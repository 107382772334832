import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import component from "./modules/component";
import dbCache from "./modules/dbCache";
import routes from "./modules/routes";
// import parts from modules
import session from "./modules/session";

Vue.use(Vuex);

const store = new Vuex.Store({
  // combine sub modules
  modules: {
    session,
    app,
    routes,
    component,
    dbCache
  },
  strict: process.env.NODE_ENV !== "production"
});

export default store;
