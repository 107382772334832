<template>
  <div class="production_report">
    <FilterProductionReports
      class="filter"
      :default-filters="defaultFilters"
      :apply-filters="applyFilters"
    />
    <ProductionReportsCharts
      class="reports"
      :filters="{ ...filters }"
      v-if="filters.filter_type === 'production'"
    />
    <ProductionReportDataTable
      v-if="filters.filter_type === 'data_table'"
      :filters="{ ...filters }"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { numberWithCommas } from "@/utils/filters";

import FilterProductionReports from "./production_report/FilterProductionReports";
import ProductionReportDataTable from "./production_report/ProductionReportDataTable.vue";
import ProductionReportsCharts from "./production_report/ProductionReportsCharts";
import { queryArrayToNumber } from "../../utils/queryArrayToNumber";

export default {
  name: "production_report.vue",
  components: {
    ProductionReportDataTable,
    FilterProductionReports,
    ProductionReportsCharts
  },
  created() {
    this.setTitles({
      title: this.$t("Production Report"),
      mobile: this.$t("Production Report")
    });
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  },
  data() {
    const query = this.$route.query || {};
    const defaultFilters = {
      ...query,
      preset: query.preset ? Number(query.preset) : undefined,
      scale: query.preset ? Number(query.scale) : undefined,
      from_date: query.preset ? new Date(query.from_date) : undefined,
      to_date: query.preset ? new Date(query.to_date) : undefined,
      machine: queryArrayToNumber(query.machine),
      zones: queryArrayToNumber(query.zones)
    };

    return {
      defaultFilters,
      filters: {
        preset: null,
        scale: null,
        filter_type: null,
        from_date: null,
        to_date: null,
        zone: null,
        machine: null,
        part: null,
        shift: null,
        machineData: [],
        parts: [],
        reportZoneLevel: { level: 0 }
      }
    };
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    numberWithCommas,
    applyFilters(filters) {
      Object.assign(this.filters, filters);
    }
  }
};
</script>

<style lang="scss">
.production_report {
  padding: 0.5rem 1rem;
  padding-bottom: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: row;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  right: 0;
  justify-content: space-between;

  .charts {
    flex: 1;
    overflow: hidden;

    .bar-container {
      height: 100%;
      .qualityChart {
        height: 100%;
        position: relative;
      }
    }
  }

  .filter {
    width: 300px;
    flex: unset;
  }

  .reports {
    flex: 1;
  }

  .mobile-gauge-container {
    display: none;
  }
}
</style>
