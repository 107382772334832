<template>
  <div class="oa-trendChart">
    <div class="flex-firstrow">
      <h3>{{ title }}</h3>
    </div>
    <v-progress-linear
      indeterminate
      v-if="loading"
    ></v-progress-linear>
    <bar-chart
      v-else
      title=""
      class="bar_chart"
      :chart-data="chartData"
      :chart-options="options"
      :height="vw > 1000 ? 400 : 250"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart";

export default {
  components: { BarChart },
  props: [
    "title",
    "DataFormatter",
    "secondAxis",
    "showLegend",
    "dates",
    "filters",
    "reload",
    "ChartType",
    "ChartName",
    "labels"
  ],
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  data() {
    return {
      data: false,
      chartData: {},
      loading: true,
      dateFormat: "D MMM hhA",
      options: {
        scales: {
          yAxes: [
            {
              zeroLineColor: "#FFF",
              stacked: true,
              ticks: {
                callback: (item) => {
                  if (this.DataFormatter) {
                    return this.DataFormatter(item);
                  }
                  return item;
                }
              }
            }
          ],
          xAxes: [
            {
              ticks: { minRotation: 45, maxRotation: 45 },
              stacked: true
            }
          ]
        },
        legend: {
          display: this.showLegend
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              let label = "";
              if (this.chartData["datasets"][tooltipItem.datasetIndex].label) {
                label = this.chartData["datasets"][tooltipItem.datasetIndex].label;
              }
              let uom = "";
              if (this.chartData["datasets"][tooltipItem.datasetIndex].uom) {
                uom = this.chartData["datasets"][tooltipItem.datasetIndex].uom;
              }
              if (this.DataFormatter) {
                return label + " : " + this.DataFormatter(tooltipItem.value) + uom;
              }
              return label + " " + tooltipItem.value + uom;
            }
          },
          mode: "index",
          intersect: false
        },
        hover: {
          mode: "index",
          intersect: false
        }
      }
    };
  },
  created() {
    if (this.secondAxis) {
      this.options["scales"]["yAxes"].push(this.secondAxis);
    }
    this.loadData();
  },
  methods: {
    moment,
    loadData: async function (chart) {
      this.loading = true;
      const daysBetween =
        (this.dates.to_date.getTime() - this.dates.from_date.getTime()) / (1000 * 3600 * 24);
      let interval = "1h";
      let dateFormat = "D MMM hhA";
      switch (true) {
        case daysBetween <= 1:
          interval = "1h";
          dateFormat = "D MMM hhA";
          break;
        case daysBetween <= 30:
          interval = "1d";
          dateFormat = "D MMM";
          break;
        case daysBetween <= 90:
          interval = "1w";
          dateFormat = "week";
          break;
        case daysBetween > 90:
          interval = "1M";
          dateFormat = "MMM YY";
          break;
      }
      const from = this.dates.from_date ? this.dates.from_date.toISOString() : null;
      const to = this.dates.to_date ? this.dates.to_date.toISOString() : null;
      let params = {
        from_date: from,
        to_date: to,
        interval: interval
      };
      if ("name" in this.filters) {
        params["username"] = this.filters["name"];
      }
      if ("part" in this.filters) {
        params["part_number"] = this.filters["part"];
      }
      if ("machine" in this.filters) {
        params["machine_name"] = this.filters["machine"];
      }
      if ("zone" in this.filters) {
        params["zone"] = this.filters["zone"];
      }
      /* add filter params */
      const result = await this.$http.get("/operator/metrics_trend/", { params }).then((res) => {
        this.dateFormat = dateFormat;
        this.data = res.data;
        this.generateChart(chart);
      });
    },
    generateChart() {
      let actuals = [];
      let targets = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let actual = 0;
      let target = 0;
      if (this.data) {
        this.data.forEach((interval) => {
          if (this.ChartType == "production") {
            actual = interval.produced.value;
            target = interval.production_target.value;
          }
          if (this.ChartType == "runtime") {
            actual = interval.runtime_per_interval.interval_duration.value;
            target = interval.interval_duration.value;
          }
          actuals.push(actual);
          targets.push(target);

          if (actual >= target) {
            gradients.push(this.$colors.chart.good_gradient);
          } else {
            gradients.push(this.$colors.chart.bad_gradient);
          }
          footers.push([
            this.$t("Produced") + " : " + this.DataFormatter(actual),
            this.$t("Target") + " : " + this.DataFormatter(Math.round(target))
          ]);
          let label = "";
          if (this.dateFormat == "week") {
            let start = moment(interval.key_as_string);
            let end = moment(interval.key_as_string).add(7, "d");
            if (start.month() != end.month()) {
              label = start.format("D MMM") + " - " + end.format("D MMM");
            } else {
              label = start.format("D") + " - " + end.format("D MMM");
            }
          } else {
            label = moment(interval.key_as_string).format(this.dateFormat);
          }
          labels.push(label);
          titles.push(label);
        });
      }
      this.chartData = {
        datasets: [
          {
            type: "line",
            data: targets,
            borderWidth: 2,
            pointRadius: 0,
            hoverPointRadius: 0,
            borderColor: this.theme == "dark" ? "white" : "#44B2F3",
            titles: titles,
            label: this.labels.length > 0 ? this.labels[0] : "",
            fill: false
          },
          {
            data: actuals,
            gradients: gradients,
            footers: footers,
            titles: titles,
            label: this.labels.length > 1 ? this.labels[1] : ""
          }
        ],
        labels: labels
      };
      this.loading = false;
    }
  },
  watch: {
    reload: function () {
      this.loadData();
    }
  }
};
</script>
<style lang="scss">
@import "../../../scss/variables";
.oa-trendChart {
  width: 50%;
  &.operators {
    width: 100%;
  }
  h3 {
    color: $blue;
    font-weight: 600;
  }
}
</style>
