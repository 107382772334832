<template>
  <div class="announcement">
    <div class="card details">
      <div class="card-header">
        <h2>{{ "Announcement" }}</h2>
      </div>

      <!-- <v-card
      elevation='2'
      outlined
      > -->
      <div
        class="card-content"
        v-if="announcement"
      >
        <v-divider class="mx-.01"></v-divider>
        <br />
        <h2>{{ announcement.title }}</h2>
        <br />
        <p>{{ announcement.description }}</p>
        <br />
        <v-img :src="announcement.imageUrl" />
      </div>
    </div>
    <!-- </v-card> -->

    <div class="comment-feed">
      <div class="card">
        <comment-feed
          :filters="commentFilters"
          :newCommentParams="commentParams"
          :reload="reloadComments"
          @commentSaved="reload"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.announcement {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0.5rem;
  height: calc(100% - 1rem);
  .details,
  .comment-feed {
    margin: 0.5rem;
    .card {
      height: 100%;
    }
  }
  .details {
    flex-grow: 1;
  }
  .comment-feed {
    min-width: 350px;
  }
}
</style>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import CommentFeed from "@/components/CommentFeed";
import { seconds } from "@/utils/filters";

export default {
  props: ["announcement_id"],
  data() {
    return {
      image: false,
      loadingAnouncement: false,
      announcement: null,

      /* Not on CommentFilters and CommentParams
      not following best practices here
      since we are using graphene global ids and
      comments are store in a non relation db (Elasticsearch)
      we are using the ticket_id field to relate the comment to the Announcement
      even though announcements are not technically subclasses of ticket
      Filters are used to retrieve comments
      Params are user to create comments*/
      commentFilters: {
        ticketId: { value: this.announcement_id }
      },
      commentParams: {
        ticketId: this.announcement_id
      },
      reloadComments: false
    };
  },
  created() {
    this.loadAnnouncement();
  },
  // beforeDestroy(){
  //   this.setTitles({
  //     title: "",
  //     mobile: "",
  //     clickFunction: null
  //   })
  // },
  components: {
    CommentFeed
  },
  computed: {},
  methods: {
    moment,
    seconds,
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    loadAnnouncement() {
      this.loadingAnouncement = true;
      const query = `query getByID($id: ID!){
        announcement(id: $id){
          ... on AnnouncementNode{
            id
            title
            description
            imageUrl
          }
        }
      }`;
      const variables = {
        id: this.announcement_id
      };
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          console.log(res.data);
          console.log(res.data.data.announcement);
          this.announcement = res.data.data.announcement;
          console.log(this.announcement);
          this.loadingAnouncement = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    //need to include a back button in the ui
    back() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.close();
      }
    },
    //Trigger comment feed to reload when comment submitted
    //need to see if we can use ticket websockets to reload so all users on page get comments pushed ot them
    reload() {
      this.reloadComments = !this.reloadComments;
    }
  }
};
</script>
