// eslint-disable-next-line no-unused-vars
import axios from "axios";
import qs from "qs";

export const zoneControlApi = {
  getWeeklyProduction: (params) => {
    return axios
      .get("/zone_control/weekly_production_summary/", {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        }
      })
      .then((res) => {
        return res.data;
      });
  }
};
