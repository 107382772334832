<template>
  <div class="tla_report">
    <filter-t-l-a-reports
      v-if="defaultFilters"
      class="filter"
      :default-filters="defaultFilters"
      :apply-filters="applyFilters"
    />
    <t-l-a-report-charts
      class="reports"
      :filters="filters"
      v-if="filters.view_type == VIEWTYPES.ANALYTICS"
    />
    <t-l-a-data-table
      class="reports"
      :filters="filters"
      v-if="filters.view_type == VIEWTYPES.DATA_TABLE"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";

import TLADataTable from "./tla_report/TLADataTable";
import TLAReportCharts from "./tla_report/TLAReportCharts";
import FilterTLAReports, { VIEWTYPES } from "./tla_report/filterTLAReports";
import useTLAFilters from "./tla_report/useTLAFilters";
import { queryArrayToNumber } from "../../utils/queryArrayToNumber";

export default {
  components: { TLADataTable, TLAReportCharts, FilterTLAReports },
  setup() {
    const { removeAllFilters } = useTLAFilters();
    return {
      removeAllFilters
    };
  },
  data() {
    const query = this.$route.query || {};
    const defaultFilters = {
      ...query,
      preset: query.preset ? Number(query.preset) : undefined,
      scale: query.scale ? Number(query.scale) : undefined,
      from_date: query.from_date ? new Date(query.from_date) : undefined,
      to_date: query.to_date ? new Date(query.to_date) : undefined,
      machines: query.machines ? queryArrayToNumber(query.machines) : [],
      machine_groups: query.machine_groups ? queryArrayToNumber(query.machine_groups) : []
    };

    return {
      filters: defaultFilters,
      defaultFilters,
      VIEWTYPES: VIEWTYPES
    };
  },
  created() {
    this.setTitles({
      title: this.$t("Total Loss Analysis"),
      mobile: this.$t("Total Loss Analysis")
    });
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    applyFilters(filters) {
      this.filters = filters;
    }
  },
  beforeDestroy() {
    this.removeAllFilters();
    this.setTitles({
      title: "",
      mobile: ""
    });
  }
};
</script>
<style scoped lang="scss">
.tla_report {
  padding: 0.5rem 1rem;
  padding-bottom: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: row;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  right: 0;
  justify-content: space-between;

  .filter {
    width: 300px;
    flex: unset;
  }

  .reports {
    flex: 1;
  }
}
</style>
