<template>
  <div class="banner">
    <div class="banner-card">
      <div class="left">
        <i
          class="fa fa-bars"
          @click="toggleNav"
        />
        <img
          v-if="logo && vw >= 700"
          :src="logo"
          @click="toggleNav"
        />
        <img
          v-else-if="vw >= 700"
          src="../assets/logos/banner.png"
          @click="toggleNav"
        />
        <router-link :to="{ name: 'welcome' }">
          <i class="icon fa fa-home" />
        </router-link>
        <i
          class="icon fa fa-heart"
          @click="favoritesOpen = true"
          v-if="favorites.length > 0"
        />
        <v-dialog
          v-model="favoritesOpen"
          width="auto"
          :persistent="false"
        >
          <v-card class="favorite-dialog">
            <DialogCloseButton :onClick="() => (favoritesOpen = false)" />
            <v-card-title>
              <div class="headline">{{ $t("Favorites") }}</div>
            </v-card-title>
            <v-card-text>
              <div class="favorites">
                <div
                  class="favorite"
                  v-for="favorite in favorites"
                >
                  <a
                    :href="favorite.url"
                    @click.stop="close"
                  >
                    <div class="label">{{ favorite.name ? favorite.name : favorite.url }}</div>
                  </a>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="close"
                color="warning"
                @click="favoritesOpen = false"
                >{{ $t("Close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <router-link :to="{ name: 'settings' }">
          <i class="icon fa fa-cog" />
        </router-link>
        <router-link
          :to="{ name: 'config' }"
          v-if="accessToConfig && vw >= 700"
        >
          <i class="icon fa fa-upload" />
        </router-link>
        <div
          class="qualityWarning"
          v-if="showQualityWarning && !iotRoute"
        >
          <p>{{ $t("For information only.") }}</p>
          <p>{{ $t("Not for use in Quality decisions.") }}</p>
        </div>
        <div class="switchContainer">
          <i
            :class="themeIcon"
            aria-hidden="true"
          ></i>
          <v-switch
            id="switch"
            inset
            v-model="currentTheme"
            @change="updateTheme()"
            true-value="dark"
            false-value="light"
          />
        </div>
        <div class="ml-4 switchContainer">
          <v-switch
            v-model="redirectToogle"
            @change="updateRedirectToogle()"
          />
          <span class="text-subtitle-1 color--text-primary align-self-center">
            {{ $t("New system look") }}
          </span>
        </div>
      </div>
      <div
        class="middle"
        v-if="location.banner && vw >= 700"
      >
        <h3 v-for="line in location.banner.split('/n').slice(0, 2)">{{ line }}</h3>
      </div>

      <div class="right">
        <div class="rightOptions">
          <help-center />

          <div
            class="title-container"
            v-html="titles && titles.title ? $t(titles.title) : ''"
            @click.stop.prevent="titles.clickFunction"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import RadialFab from "@/components/RadialFab";
import { Dispatcher } from "@/utils/eventbus";
import { hasUserRole, userRoles } from "@/utils/user";
import HelpCenter from "../features/help-center/HelpCenter.vue";
import DevelopmentFeature from "./DevelopmentFeature.vue";
import { useRedirects } from "@/shared/useRedirects";

export default {
  components: {
    DevelopmentFeature,
    RadialFab,
    DialogCloseButton,
    HelpCenter
  },
  data() {
    const { updateRedirectToogle, isRedirectToogleEnabled } = useRedirects();
    return {
      logo: false,
      accessToConfig: false,
      showQualityWarning: false,
      favoritesOpen: false,
      favorites: [],
      currentTheme: null,
      redirectToogle: isRedirectToogleEnabled().value,
      updateRedirectToogle: updateRedirectToogle
    };
  },
  created() {
    this.getCurrentTheme();
    this.$http
      .get("graphql/", {
        params: {
          query: `{settings{
        bannerLogo
        showQualityWarning
      }
    }`
        }
      })
      .then((res) => {
        if (res.data.data.settings.length > 0) {
          this.logo = res.data.data.settings[0].bannerLogo;
          this.showQualityWarning = res.data.data.settings[0].showQualityWarning;
        }
      });
    this.accessToConfig = hasUserRole(this.user, userRoles.ApplicationAdmin);
    this.loadFavorites();
  },
  computed: {
    ...mapGetters({
      titles: "app/Titles",
      location: "session/Location",
      user: "session/User",
      vw: "app/VW"
    }),
    isMachineRoute() {
      return this.$route.name === "machine";
    },
    themeIcon() {
      if (this.currentTheme === "dark") {
        return "fa fa-moon-o";
      }
      return "fa fa-sun-o";
    },
    iotRoute() {
      return this.$route.path.includes("iot");
    }
  },
  methods: {
    ...mapActions({
      setRightPanel: "components/SetRightPanel",
      setHelpCenterVisibility: "components/SetHelpCenterVisibility",
      setTheme: "app/SetTheme",
      updateProfile: "session/UpdateProfile"
    }),
    toggleNav() {
      Dispatcher.$message("NAVIGATION", "toggle");
    },
    openScrapEntry() {
      Dispatcher.$message("OPEN_SCRAP_ENTRY");
    },
    openStatusEntry() {
      Dispatcher.$message("OPEN_STATUS_ENTRY");
    },
    openEdocs() {
      this.setRightPanel("docs");
      this.setHelpCenterVisibility({ visible: false });
    },
    openP911() {
      this.setRightPanel("");
      this.setHelpCenterVisibility({ visible: true });
    },
    openComment() {
      Dispatcher.$message("OPEN_COMMENT");
    },
    loadFavorites() {
      const query = `query($userId:Int){
            favorites(userId:$userId){
              url,
              id,
              name,
        }
      }`;
      const variables = {
        userId: this.user.id
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.favorites = res.data.data.favorites;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    getCurrentTheme() {
      const themeId = this.user.theme;
      this.currentTheme = themeId ? "dark" : "light";
    },
    updateTheme() {
      if (this.currentTheme === "dark") {
        this.setTheme("dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.setTheme("light");
        this.$vuetify.theme.dark = false;
      }
      this.saveProfile();
    },
    saveProfile() {
      const query = `mutation ($input: UserModelMutationInput!){
        updateUser(input:$input) {
          jwt
          errors {
            messages
            field
          },
          theme,
        }
      }`;
      const variables = {
        input: {
          id: this.user.id,
          theme: this.currentTheme === "dark" ? 1 : 0,
          username: this.user.username
        }
      };

      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          const updatedProfile = res.data.data.updateUser;
          this.updateProfile(updatedProfile);
          this.errors = updatedProfile.errors;
          if (this.errors) {
            this.errors.forEach((error) => {
              error.messages.forEach((message) => {
                this.$message("ALERT", { text: message, type: "error", timeout: 1000 });
              });
            }, this);
          } else {
            this.$message("ALERT", { text: this.$t("Saved"), type: "success", timeout: 1000 });
          }
        })
        .catch((res) => {
          console.log(res);
          this.errors = res.errors;
          this.errors.forEach((error) => {
            this.$message("ALERT", { text: error, type: "error", timeout: 1000 });
          }, this);
        });
    }
  },
  watch: {
    redirectToogle(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.location.reload();
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/variables";
@import "../scss/mq";
.banner {
  .banner-card {
    z-index: 99999;
    background-color: $darkGrey;
    height: $bannerHeight;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left,
    .right {
      width: 40%;
    }
    .qualityWarning {
      background-color: $blue;
      border-radius: 5px;
      padding: 5px 7px;
      min-width: 150px;
      p {
        margin: 0px;
        font-weight: 600;
        font-size: 0.85rem;
      }
    }
    .left {
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        display: block;
        margin: 1rem;
        font-size: 1.5rem;
      }
      img {
        display: block;
        max-width: 15rem;
        max-height: 3rem;
        margin: 1rem 0.5rem;
      }
      a {
        display: block;
        cursor: pointer;
        color: $white;
        text-decoration: none;
      }
    }
    .right {
      margin-right: 1rem;
      text-align: right;

      .rightOptions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .title-container {
          padding: 10px;
        }
      }

      h3 {
        font-weight: 500;
      }
      .backBtn {
        background: $blue;
        border-radius: 0.2rem;
        padding: 0.25rem 0.5rem;
        width: 15rem;
        margin-left: auto;
        text-align: center;
        cursor: pointer;
        i {
          margin-right: 0.5rem;
        }
      }
    }
    .middle {
      min-width: 225px;
      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        &:first-child {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.switchContainer {
  display: flex;
  justify-content: center;

  i {
    font-size: 1rem !important;
    top: 0.5rem;
    position: relative;
    vertical-align: middle;
  }
}

.theme--light.v-application {
  .banner {
    .banner-card {
      background-color: $light-background;
      .left {
        a {
          color: $light-icon;
        }
      }
    }
  }
}

.fa-sun-o {
  right: -3.4rem;
}

.fa-moon-o {
  right: -2.1rem;
  color: $blue;
}

@include media_below(1000px) {
  .banner {
    position: relative;
    .qualityWarning {
      position: absolute;
      left: 0;
      display: flex;
      top: -0.2rem;
      p {
        margin: 0px;
        font-size: 0.85rem;
      }
    }
  }
}
@include media_below(700px) {
  .banner {
    position: relative;
    .qualityWarning {
      position: absolute;
      left: 0;
      display: flex;
      width: 100vw;
      top: -0.2rem;
      p {
        margin: 0px;
        font-size: 0.85rem;
      }
    }
    .banner-card {
      height: $bannerHeightMobile;
      padding-top: $bannerMobileTopPadding;
      .left {
        margin-left: 0.75rem;
      }
      .right {
        width: 60%;
        h3 {
          font-size: 0.75rem;
        }
      }
    }
  }
}
</style>
