import { useQuery } from "@tanstack/vue-query";
import { computed } from "@vue/composition-api";
import { groupApi } from "@/features/group/groupApi";
import { QUERY_LONG_LIVE_STALE_TIME } from "@/shared/constants";

export const useGroupsHierarchy = () => {
  const query = useQuery({
    queryKey: ["groups_hierarchy"],
    queryFn: () => groupApi.getGroups(),
    keepPreviousData: true,
    staleTime: QUERY_LONG_LIVE_STALE_TIME
  });

  const groups = computed(() => query.data.value && query.data.value.data.machineGroups);

  const getGroupsTree = (id) => {
    const result = [];
    if (!groups.value) return;

    const fn = (id) => {
      const group = groups.value.find((group) => group.pk === Number(id));
      const parent = groups.value.find((group) => group.subGroupIds.includes(Number(id)));

      return {
        parent,
        group
      };
    };

    while (id) {
      const { parent, group } = fn(id);

      if (group) {
        result.push(group);
      }

      if (parent) {
        id = parent.pk;
      } else {
        id = null;
      }
    }

    return result.reverse();
  };

  return {
    groups,
    getGroupsTree
  };
};
