<template>
  <div class="settings">
    <v-dialog
      v-model="uploadResponseOpen"
      persistent
      max-width="500"
    >
      <v-card class="settings-error">
        <DialogCloseButton :onClick="() => (uploadResponseOpen = false)" />
        <v-card-title>
          <div
            class="error-message"
            v-if="uploadResponse && uploadResponse.text"
          >
            {{ uploadResponse.text }}
          </div>
          <div v-if="uploadResponse && !uploadResponse.text">{{ $t("Success") }}</div>
        </v-card-title>
        <div
          v-if="uploadResponse && uploadResponse.errors"
          class="error-text"
        >
          <ul
            v-for="(item, idx) in uploadResponse.errors"
            :key="idx"
          >
            <li>Row: {{ item.row }}</li>
            <ul>
              <li v-for="err in item.errors">{{ err }}</li>
            </ul>
          </ul>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            right
            color="primary"
            @click="uploadResponseOpen = false"
            >{{ $t("OK") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="identity">
      <div class="card first">
        <div class="card-header">
          <h2 class="upload-text">{{ $t("Upload CSV") }}</h2>
          <v-tabs
            right
            class="upload-tabs"
            v-model="selectedUploadTab"
            key="upload-tabs"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="general">
              <p>
                <span class="button_text">{{ $t("General") }}</span>
                <span class="tiny_button_text">{{ $t("General") }}</span>
              </p>
            </v-tab>
            <v-tab key="scrap">
              <p>
                <span class="button_text">{{ $t("Scrap") }}</span>
                <span class="tiny_button_text">{{ $t("Scrap") }}</span>
              </p>
            </v-tab>
            <v-tab key="level2">
              <p>
                <span class="button_text">{{ $t("Level2") }}</span>
                <span class="tiny_button_text">{{ $t("Level2") }}</span>
              </p>
            </v-tab>
            <v-tab key="iot">
              <p>
                <span class="button_text">{{ $t("IOT") }}</span>
                <span class="tiny_button_text">{{ $t("IOT") }}</span>
              </p>
            </v-tab>
          </v-tabs>
        </div>
        <div class="card-content">
          <div class="progress-bar">
            <v-progress-linear
              v-if="uploading"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </div>
          <v-tabs-items v-model="selectedUploadTab">
            <v-tab-item>
              <ConfigurationUpload
                :uploadCsv="uploadCsv"
                :downloadCsv="downloadCsv"
                :items="generalItems"
                :setCsv="setCsv"
                :uploading="uploading"
              />
            </v-tab-item>
            <v-tab-item>
              <ConfigurationUpload
                :uploadCsv="uploadCsv"
                :downloadCsv="downloadCsv"
                :items="scrapItems"
                :setCsv="setCsv"
                :uploading="uploading"
              />
            </v-tab-item>
            <v-tab-item>
              <ConfigurationUpload
                :uploadCsv="uploadCsv"
                :downloadCsv="downloadCsv"
                :items="level2Items"
                :setCsv="setCsv"
                :uploading="uploading"
                :selectedMachines="selectedMachines"
                @updateSelectedMachines="updateSelectedMachines"
              />
            </v-tab-item>
            <v-tab-item>
              <ConfigurationUpload
                :uploadCsv="uploadCsv"
                :downloadCsv="downloadCsv"
                :items="iotItems"
                :setCsv="setCsv"
                :uploading="uploading"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-progress-linear
            v-if="downloadingLevel2"
            :value="level2DownloadProgress"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import ConfigurationUpload from "@/components/configuration/ConfigurationUpload.vue";

export default {
  components: {
    ConfigurationUpload,
    DialogCloseButton
  },
  data() {
    return {
      uploading: false,
      uploadResponseOpen: false,
      uploadResponse: null,
      avatarImage: null,
      csvs: [],
      jobSocket: null,
      show: {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
        newPin: false
      },
      selectedMachines: [],
      downloadingLevel2: false,
      level2DownloadProgress: 0,
      selectedUploadTab: 0,
      scrapItems: [
        {
          label: this.$t("Scrap Reason Codes CSV"),
          key: "scrap_reason_code",
          url: "scrap_reason_code_upload",
          download_url: "scrap_reason_code_download",
          download_filename: "scrap_reason_code.csv"
        },
        {
          label: this.$t("Non-Process Scrap CSV"),
          key: "nonProcessScrap",
          url: "scrap/csv/upload",
          download_url: "scrap/csv/download",
          download_filename: "non_proces_scrap.csv"
        },
        {
          label: this.$t("Scrap BOM CSV"),
          key: "scrapBom",
          url: "bom/csv/upload",
          download_url: "bom/csv/download",
          download_filename: "scrap_bom.csv"
        },
        {
          label: this.$t("Scrap Transaction CSV"),
          key: "scrapTransaction",
          url: "scrap_transaction/csv/upload",
          download_url: "scrap_transaction/csv/download",
          download_filename: "scrap_transactions.csv"
        }
      ],
      level2Items: [
        {
          label: this.$t("Machine Alarms and Events CSV"),
          key: "alarmsAndEvents",
          url: "level_2_code_upload",
          download_url: "level_2_code_download",
          download_filename: "machine_alarms_events.csv"
        }
      ],
      iotItems: [
        {
          label: this.$t("IOT Configuration CSV"),
          key: "iot_configuration",
          url: `${window.location.protocol}//${window.location.host}/iotAPI/iot_configuration_upload`,
          download_url: `${window.location.protocol}//${window.location.host}/iotAPI/iot_configuration_download`,
          download_filename: "iot_configurations.csv"
        },
        {
          label: this.$t("Full Audit Log"),
          key: "iot_audit_log",
          download_url: `${window.location.protocol}//${window.location.host}/iotAPI/iot_audit_download`,
          download_filename: "iot_audit_log.csv"
        }
      ],
      generalItems: [
        {
          label: this.$t("Parts CSV"),
          key: "part",
          url: "part_upload",
          download_url: "part_download",
          download_filename: "parts.csv"
        },
        {
          label: this.$t("Machine CSV"),
          key: "machine",
          url: "machine_upload",
          download_url: "machine_download",
          download_filename: "machines.csv"
        },
        {
          label: this.$t("Part Configuration CSV"),
          key: "part_configuration",
          url: "part_configuration_upload",
          download_url: "part_configuration_download",
          download_filename: "part_configurations.csv"
        },
        {
          label: this.$t("LDMS CSV"),
          key: "ldms",
          url: "ldms_upload",
          download_url: "ldms_download",
          download_filename: "ldms.csv"
        },
        {
          label: this.$t("Users CSV"),
          key: "users",
          download_url: "exportUsersCSV",
          download_filename: "users.csv"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      machines: "dbCache/machines"
    })
  },
  methods: {
    updateSelectedMachines(machines) {
      this.selectedMachines = machines;
    },
    setCsv(file, name) {
      if (file) {
        this.csvs[name] = file;
      } else {
        this.csvs[name] = null;
      }
    },
    uploadCsv: async function (e, name, url, label) {
      const formData = new FormData();
      if (this.csvs[name] != null) {
        formData.append("attachment", this.csvs[name]);
        const config = {
          headers: {
            "content-type": "multipart/form-data"
          }
        };

        this.uploading = true;

        try {
          const response = await this.$http.post(`${url}/`, formData, config);
          this.uploading = false;
          this.uploadResponse = response.data;
          this.uploadResponseOpen = true;
          if (response.data.job_id) {
            this.$message("CSV", { name: label });
            this.jobSocket = this.$socket(`csv/${response.data.job_id}/`, (r) => {
              let data = JSON.parse(r.data);
              let type = data.type;
              let message = data.message;
              switch (type) {
                case "status":
                  if (message == "failed") {
                    this.$message("CSV", { error: message });
                  } else if (message == "completed") {
                    this.$message("CSV", { complete: message });
                    this.closeJobSocket();
                  } else {
                    this.$message("CSV", { status: message });
                  }
                  break;
                case "progress":
                  this.$message("CSV", { progress: message });
                  break;
                case "error":
                  this.$message("CSV", { error: message });
                  this.closeJobSocket();
                  break;
              }
            });
          }
        } catch (e) {
          let errorText = e;
          let errorsList = null;
          if (e.response.data && e.response.data.text) {
            errorText = e.response.data.text;
          }
          if (e.response.data && e.response.data.errors) {
            errorsList = e.response.data.errors;
          }
          this.$message("CSV", { error: errorText });

          this.uploading = false;
          this.uploadResponse = { text: errorText, errors: errorsList };
          this.uploadResponseOpen = true;
        }
      }
    },
    closeJobSocket() {
      this.jobSocket.close();
    },
    downloadCsv: async function (e, url, filename) {
      if (url === "level_2_code_download") {
        await this.downloadLevel2CSV(e, url, filename);
      } else {
        try {
          let params = new URLSearchParams();
          this.selectedMachines.forEach((machineId) => params.append("machines", machineId));
          const response = await axios({
            url: `${url}/`,
            method: "GET",
            responseType: "blob",
            params: params
          });
          const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        } catch (e) {
          this.uploadResponse = { text: e, errors: null };
          this.uploadResponseOpen = true;
        }
      }
    },
    downloadLevel2CSV: async function (e, url, filename) {
      try {
        this.downloadingLevel2 = true;
        let params = new URLSearchParams();
        this.selectedMachines.forEach((machineId) => params.append("machines", machineId));
        const response = await axios({
          url: `${url}/`,
          method: "GET",
          params: params
        });
        let jobResponse = response.data;
        while (jobResponse.status !== "completed") {
          this.level2DownloadProgress = jobResponse.progress;
          await new Promise((resolve) => setTimeout(resolve, 1000));
          let response = await axios({
            url: `csv/job/${jobResponse.job_id}/status/`,
            method: "GET"
          });
          jobResponse = response.data;
        }
        const fileResponse = await axios({
          url: `csv/job/${jobResponse.job_id}/file/download/`,
          method: "GET",
          responseType: "blob"
        });
        const objectUrl = window.URL.createObjectURL(new Blob([fileResponse.data]));
        const link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        this.uploadResponse = { text: e, errors: null };
        this.uploadResponseOpen = true;
      } finally {
        this.downloadingLevel2 = false;
        this.level2DownloadProgress = 0;
      }
    }
  },
  watch: {
    storedDisabledRoutes: {
      handler: "loadRoutes",
      deep: true
    },
    currentPassword: "validatePassword",
    newPassword: "validatePassword",
    confirmPassword: "validatePassword"
  }
};
</script>
<style lang="scss">
@import "../scss/variables.scss";
.settings-error {
  .error-text {
    max-height: 280px;
    overflow-y: scroll;
  }
  .error-message {
    word-break: break-word;
  }
}
.settings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
  padding: 0.5rem 1rem;
  padding-bottom: 0rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .fa-save {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;
    background-color: white;
    text-align: center;
    line-height: 2rem;
    color: $cardColor;
  }
  .upload-container {
    .upload-csv {
      display: flex;
      align-items: center;
      .upload-csv-file {
        flex: 1;
      }
      //i {
      //  flex: unset;
      //  background: white;
      //  padding: 8px;
      //  border-radius: 100%;
      //  margin: 4px;
      //}
    }
  }

  .identity {
    height: 100%;
    padding-top: 0.5rem;
    margin-top: -0.5rem;
    padding-left: 0.5rem;
    margin-left: -0.5rem;
    display: flex;
    flex-direction: column;
    .first {
      flex: 1;
    }
  }

  .card {
    overflow: hidden;
    margin-bottom: 1rem;
    .card-header {
      display: flex;
      justify-content: space-between;
      .upload-text {
        white-space: nowrap;
        flex-grow: 0;
      }
    }
    &.apps {
      height: -webkit-fill-available;
    }
    &.csv {
      height: 300px;
    }
    .progress-bar {
      height: 5px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .upload-csv {
      display: flex;
      align-items: center;
      button {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .main {
      .v-input__slot {
        margin-bottom: 0.75rem;
      }
      .v-text-field__details {
        display: none;
      }
    }
    .card-content {
      padding-top: 3rem;
      .v-tabs-items {
        background-color: inherit;
      }
    }
    h2 {
      font-size: 1.25rem;
    }
    h3 {
      font-size: 1rem;
      margin-bottom: -0.5rem;
    }
    .v-text-field {
      margin-top: 0;
    }
  }
}
</style>
