import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  visible: null,
  helpCenter: {
    visible: false,
    options: {}
  },
  rightPanel: null
};

const mutations = {
  "components/SetVisibleComponent": function (state, componentID) {
    state.visible = componentID;
  },
  "components/SetRightPanelComponent": function (state, componentID) {
    state.rightPanel = componentID;
  },
  "components/SetHelpCenterVisibility": function (state, options) {
    state.helpCenter = options;
  }
};

const getters = {
  "components/Visible"(state) {
    return {
      visible: state.visible
    };
  },
  "components/RightPanel"(state) {
    return state.rightPanel;
  },
  "components/HelpCenterVisible"(state) {
    return state.helpCenter.visible;
  },
  "components/HelpCenterOptions"(state) {
    return state.helpCenter.options;
  }
};

const actions = {
  "components/SetVisible"({ commit }, componentID) {
    commit("components/SetVisibleComponent", componentID);
  },
  "components/SetRightPanel"({ commit }, componentID) {
    commit("components/SetRightPanelComponent", componentID);
  },
  "components/SetHelpCenterVisibility"({ commit }, options) {
    commit("components/SetHelpCenterVisibility", options);
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
