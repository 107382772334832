var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:"ps",staticClass:"problem-solving",class:_vm.mobileTabIndex == 2 && 'tabSelected'},[_c('h2',[_vm._v(_vm._s(_vm.$t("Problem Solving")))]),_vm._v(" "),_c('div',{staticClass:"issueTables"},_vm._l((_vm.issueTables),function(issueTable,tableIndex){return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v(_vm._s(_vm.$t(issueTable.title)))]),_vm._v(" "),_c('div',{staticClass:"headerBtns"},[(tableIndex == 0)?_c('zone-issue',{attrs:{"machine_group_id":_vm.machine_group_id,"machine_group_pk":_vm.machineGroupPk},on:{"submitted":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.addIssue.apply(void 0, [ issueTable.issues ].concat( args ));
}}}):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"expand",attrs:{"small":"","light":"","color":"white","fab":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setEnlargedTable(Object.assign({}, issueTable))}}},[_c('i',{staticClass:"fa fa-expand",attrs:{"aria-hidden":"true"}})])],1)]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"values"},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Total Open")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(issueTable.total))])]),_vm._v(" "),_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("In Process")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.getInprocess(issueTable.issues)))])]),_vm._v(" "),_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Latest")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.getLatest(issueTable.issues)))])]),_vm._v(" "),_c('div',{staticClass:"item"},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("Oldest")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.getOldest(issueTable.issues)))])])]),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.issueHeaders,"item-key":"key_as_string","items":issueTable.issues,"loading":issueTable.loading,"page":issueTable.current,"itemsPerPage":issueTable.itemsPerPage,"serverItemsLength":issueTable.total,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50] },"disable-pagination":"","fixed-header":"","multi-sort":""},on:{"update:page":function($event){return _vm.$set(issueTable, "current", $event)},"update:itemsPerPage":function($event){return _vm.$set(issueTable, "itemsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(issueTable, "itemsPerPage", $event)},function($event){return _vm.handleItemsPerPageChange($event, issueTable)}],"pagination":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.loadIssuePage.apply(void 0, [ issueTable ].concat( args ));
}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"harveyBall mdi",class:_vm.getStatusIcon(item),attrs:{"aria-hidden":"true"}})]}},{key:"item.relation",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v("\n              "+_vm._s(item.node.machineName ? item.node.machineName : item.node.machineGroupName)+"\n            ")])]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v("\n              "+_vm._s(item.node.reason.substring(0, 20))+_vm._s(item.node.reason.length > 20 ? "..." : "")+"\n            ")])]}}],null,true)})],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }