<template>
  <transition name="slide-fade">
    <div class="quality-chart metric-component">
      <div class="mobile-chart-title-container">
        <h2 class="card-title">{{ $t("Quality") }}</h2>
        <span class="mobile-gauge-container">
          <v-gauge
            v-if="quality_percentage"
            gaugeValueClass="gaugeValue"
            :value="quality_percentage"
            :donut="true"
            :options="gaugeOpts"
          />
        </span>
      </div>

      <v-progress-linear
        indeterminate
        v-if="!(production && production.length > 0)"
      ></v-progress-linear>
      <div
        class="charts"
        v-else
      >
        <div class="gauge-container">
          <v-gauge
            v-if="quality_percentage"
            gaugeValueClass="gaugeValue"
            :value="quality_percentage"
            :donut="true"
            :options="gaugeOpts"
          />
        </div>
        <div class="bar-container">
          <bar-chart
            v-if="chartData"
            chartId="qualityChart"
            chartName="qualityChart"
            class="qualityChart"
            uom="%"
            :decimal="2"
            :chartData="chartData"
            :chartOptions="chartOptions"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.quality-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 18vh;
  flex: 1;

  @media screen and (max-width: 1200px) {
    align-self: normal;
    justify-self: normal;
    width: unset !important;

    .charts {
      height: unset !important;
    }

    .bar-container {
      margin-right: 0 !important;

      .qualityChart {
        padding: 0 !important;
      }
    }
  }

  .charts {
    display: flex;
    justify-content: center;
    align-items: center;
    .bar-container {
      width: calc(100% - 150px);
      .qualityChart {
        width: 100%;
        height: 18vh;
        padding-left: 0.5rem;
        display: grid;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import VGauge from "vgauge";

import BarChart from "@/components/charts/BarChart.js";

export default {
  components: {
    BarChart,
    VGauge
  },
  props: ["options", "production"],
  data() {
    return {
      chartData: false,
      loaded: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      },
      quality_percentage: false,
      lastParams: {}
    };
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    format_data() {
      let percentage = [];
      let targets = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let total_scrapped = 0;
      let total_produced = 0;
      if (this.production) {
        this.production.forEach((interval) => {
          let scrapped = interval.scrapped.value;
          let produced = interval.produced.value;
          let quality_percentage = interval.quality ? interval.quality.value * 100 : 0;
          percentage.push(quality_percentage);
          targets.push(this.options.targets.quality);
          if (quality_percentage >= this.options.targets.quality) {
            gradients.push(this.$colors.chart.good_gradient);
          } else {
            gradients.push(this.$colors.chart.bad_gradient);
          }
          footers.push([
            this.$t("Scrapped") + " : " + scrapped,
            this.$t("Produced") + " : " + produced
          ]);

          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          total_scrapped += scrapped;
          total_produced += produced;
        });
        this.quality_percentage = ((total_produced - total_scrapped) / total_produced) * 100;
      }
      this.chartData = {
        datasets: [
          {
            type: "line",
            data: targets,
            borderWidth: 2,
            pointRadius: 0,
            hoverPointRadius: 0,
            borderColor: this.theme == "dark" ? "white" : "#44B2F3",
            titles: titles,
            fill: false,
            label: this.$t("Target")
          },
          {
            data: percentage,
            gradients: gradients,
            titles: titles,
            footers: footers,
            label: this.$t("Quality")
          }
        ],
        labels: labels
      };
    }
  },
  computed: {
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      deep: true,
      handler: "format_data"
    }
  }
};
</script>
