<template>
  <div class="level2-timeline">
    <div class="card">
      <div class="card-header">
        <h4>{{ $t("Alarm and Event Timeline") }}</h4>
      </div>
      <div class="card-content">
        <p v-if="!filterController.initialFilters.machine">
          {{ $t("Select a Machine") }} {{ filterController.initialFilters.machine }}
        </p>
        <div class="chartWrapper">
          <v-progress-linear
            indeterminate
            v-if="loading"
          />
          <div id="level2-timeline-chart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import moment from "moment-timezone";
import { mapGetters, mapMutations } from "vuex";
import { setAMChartsLocale } from "@/utils/language";
import { useSettingsTimezoneQuery } from "@/common/settings/useSettingsTimezoneQuery";

export default {
  props: ["filterController"],
  components: {},
  setup() {
    const { timezone } = useSettingsTimezoneQuery();

    return {
      timezone
    };
  },
  computed: {
    ...mapGetters({
      user: "session/User",
      theme: "app/Theme"
    })
  },
  data() {
    return {
      chart: null,
      data: [],
      chartData: [],
      codes: [],
      loading: false,
      loadingSet: new Set(),
      set_date_copy: null,
      ignore_click: false,
      eventSeries: null
    };
  },
  created() {},
  mounted() {
    if (this.filterController.hasFilter("machine")) {
      this.loadData();
    }
  },
  beforeDestroy() {
    if (this.chart != null) {
      this.chart.dispose();
      this.chartData = [];
    }
    this.filterController.initialFilters.machine = null;
    this.resetMachineCodes();
  },
  methods: {
    addToLoading(n) {
      this.loadingSet.add(n);
      this.loading = this.loadingSet.size !== 0;
    },
    deleteFromLoading(n) {
      this.loadingSet.delete(n);
      this.loading = this.loadingSet.size !== 0;
    },
    ...mapMutations({
      setMachineCodes: "dbCache/SET-MACHINES-CODES"
    }),
    resetMachineCodes() {
      this.filterController.initialFilters.code = null;
      // this.filterController.initialFilters.codeEventType = null;
      this.setMachineCodes([]);
    },
    loadData() {
      this.addToLoading("loadData");
      // clear codes before load new data
      this.resetMachineCodes();
      const query = `query(
        $filters: GrapheneElasticFilterLevel2EventNodeConnectionBackendFilter!
        $machineId: Int!){
        level2Events(
          filter: $filters,) {
          edges{
            node{
              machineId,
              partNumber,
              factoryOrder,
              startTime,
              endTime,
              eventDuration,
              statusCode
              eventType
            }
          }
        }
        level2Codes(machineId: $machineId){
          id
          pk
          machine{
            pk
          }
          part{
            pk
          }
          code
          description
          eventType
        }
      }`;
      const variables = {
        filters: {
          startTime: {
            range: {
              lower: { datetime: this.filterController.initialFilters.date_range.from_date },
              upper: { datetime: this.filterController.initialFilters.date_range.to_date }
            }
          },
          eventType: { contains: this.filterController.initialFilters.codeEventType }
        }
      };
      if (this.filterController.hasFilter("machine")) {
        variables["filters"]["machineId"] = {
          terms: this.filterController.getFilterPks("machine")
        };
        variables["machineId"] = this.filterController.getFilterPks("machine");
      }

      const res = this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.data = res.data.data.level2Events.edges;
          this.codes = res.data.data.level2Codes;
          // set available filters by code
          this.setMachineCodes(
            this.data.reduce((reducer, event) => {
              reducer.push(this.codeLookup(event.node.statusCode));
              return reducer;
            }, [])
          );
          this.createSerpentineChart();
          this.deleteFromLoading("loadData");
        })
        .catch((e) => {
          console.error(e);
          this.deleteFromLoading("loadData");
        });
      return res;
    },
    codeLookup(code) {
      const match = this.codes.find((c) => c.code === code);
      if (match) {
        return match;
      } else {
        return {
          id: false,
          pk: -1,
          code: code,
          eventType: "Unknown",
          description: "Unknown Code " + String(code)
        };
      }
    },
    getCodeColor(code) {
      if (code.eventType == "ALARM") {
        return "#EA4D55";
      }
      if (code.eventType == "EVENT") {
        return "#FFC794";
      }
      return "#F37F59";
    },
    checkFilters(code, e) {
      const isCodeFilterNotNull = this.filterController.initialFilters.code != null;
      const isCodeFilterNotMatch =
        isCodeFilterNotNull && this.filterController.initialFilters.code.code !== code.code;
      const isEventTypeFilterNotNull = this.filterController.initialFilters.codeEventType != null;
      const isEventTypeFilterNotMatch =
        isEventTypeFilterNotNull &&
        this.filterController.initialFilters.codeEventType.toLowerCase() !==
          e.node.eventType.toLowerCase();
      return isCodeFilterNotMatch || isEventTypeFilterNotMatch;
    },
    createSerpentineChart() {
      this.addToLoading("createSerpentineChart");
      if (this.chart != null) {
        this.chart.dispose();
        this.chartData = [];
      }

      window.requestAnimationFrame(() => {
        //TODO: color for light mode
        this.chart = am4core.create("level2-timeline-chart", am4plugins_timeline.SerpentineChart);
        this.chart.events.on("ready", () => {
          this.deleteFromLoading("createSerpentineChart");
        });
        this.chart.logo.disabled = true;
        this.chart.orientation = "vertical";
        this.chart.levelCount = 5;
        this.chart.maskBullets = false;
        this.chart.paddingTop = 30;
        setAMChartsLocale(this.chart);

        for (let e of this.data) {
          let code = this.codeLookup(e.node.statusCode);

          if (this.checkFilters(code, e)) {
            continue;
          }
          this.chartData.push({
            category: code.eventType, //m.status_code.toString(),
            // start: moment(e.node.start_time).toDate(),
            // end: moment(e.node.end_time).toDate(),
            start: moment(e.node.startTime).toDate(),
            end: moment(e.node.endTime).toDate(),
            task: `${code.code} : ${code.description}`,
            code: code.code.toString(),
            color: am4core.color(this.getCodeColor(code)),
            startTime: moment(e.node.startTime).format("MMM DD hh:mm a"),
            endTime: moment(e.node.endTime).format("MMM DD hh:mm a")
          });
        }

        this.chart.data = this.chartData;
        this.chart.dateFormatter.dataFormat = "yyyy-MM-dd hh:mm a";
        this.chart.dateFormatter.inputDataFormat = "yyyy-MM-dd hh:mm a";
        this.chart.dateFormatter.timezone = this.timezone;

        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.disabled = false;
        categoryAxis.renderer.labels.template.paddingRight = 25;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.innerRadius = -60;
        categoryAxis.renderer.radius = 60;
        // Define fixed y-axis values as an array of objects
        const fixedYAxisValues = [
          { category: this.$t("ALARM") },
          { category: this.$t("UNKNOWN") },
          { category: this.$t("EVENT") }
        ];

        // Assign the fixed y-axis values to the categoryAxis
        categoryAxis.data = fixedYAxisValues;

        if (this.theme == "dark") {
          categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
        }

        let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.start = dateAxis.dateToPosition(
          this.filterController.initialFilters.date_range.from_date
        );
        dateAxis.end = dateAxis.dateToPosition(
          this.filterController.initialFilters.date_range.to_date
        );
        dateAxis.renderer.minGridDistance = 70;
        dateAxis.baseInterval = { count: 2, timeUnit: "minutes" };
        dateAxis.renderer.tooltipLocation = 1;
        dateAxis.startLocation = 0;
        dateAxis.renderer.line.strokeDasharray = "1,4";
        dateAxis.renderer.line.strokeOpacity = 0.5;
        dateAxis.tooltip.background.fillOpacity = 0;
        dateAxis.tooltip.background.cornerRadius = 2;
        dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor(
          "alternativeBackground"
        );
        dateAxis.tooltip.label.paddingTop = 10;
        dateAxis.dateFormats.setKey("hour", "MMM dd\nhh:mm a");

        //theme settings
        if (this.theme == "dark") {
          dateAxis.renderer.grid.template.stroke = am4core.color("#fff");
          dateAxis.renderer.line.stroke = am4core.color("#fff");
        }

        let labelTemplate = dateAxis.renderer.labels.template;
        labelTemplate.verticalCenter = "middle";
        labelTemplate.fillOpacity = 1;
        labelTemplate.fontSize = 9;

        //theme settings
        if (this.theme == "dark") {
          labelTemplate.fill = am4core.color("#fff");
        }

        const series = this.chart.series.push(new am4plugins_timeline.CurveColumnSeries());
        series.columns.template.height = am4core.percent(5);
        series.columns.template.tooltipText = "[bold]{task}[/]\n{startTime}\n{endTime}";
        series.columns.template.clickable = true;
        series.columns.template.tooltipPosition = "pointer";

        series.columns.template.events.on("over", () => {
          this.ignore_click = true;
        });
        series.columns.template.events.on("out", () => {
          this.ignore_click = false;
        });

        series.columns.template.events.on("hit", (e) => {
          const startDate = e.target.dataItem.dataContext.start;
          const endDate = e.target.dataItem.dataContext.end;
          this.$emit("setDates", startDate, endDate);
        });

        series.dataFields.openDateX = "start";
        series.dataFields.dateX = "end";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "color"; // get color from data
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.strokeOpacity = 1;
        series.columns.template.strokeWidth = 2;
        series.columns.template.zIndex = 1;

        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 4;
        bullet.circle.strokeOpacity = 1;
        bullet.circle.strokeWidth = 3;
        bullet.propertyFields.fill = "color";
        bullet.propertyFields.stroke = "color";
        bullet.locationX = 1;
        bullet.tooltipText = "[bold]{task}[/]\n{startTime}";
        bullet.clickable = true;

        // //we need two events here.  One on the down event to store the date
        // //and one on the up event.  Because we can lose focus on the
        // //text field and not have released our click
        // bullet.events.on("over", () => {
        //   this.ignore_click = true;
        // })
        // bullet.events.on("out", ()=> {
        //   this.ignore_click = false;
        // })
        //
        // bullet.events.on("down", (e) => {
        //   this.set_date_copy = this.set_date;
        // })
        // bullet.events.on("hit",(e) => {
        //   const startDate = e.target.dataItem.dataContext.start;
        //   if(this.set_date_copy == "start") {
        //     this.$emit("setDates", startDate, null);
        //   } else if(this.set_date_copy == "end") {
        //     this.$emit("setDates", null, startDate);
        //   }
        // });

        const bullet2 = series.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.radius = 4;
        bullet2.circle.strokeOpacity = 1;
        bullet2.circle.strokeWidth = 3;
        bullet2.propertyFields.fill = "color";
        bullet2.propertyFields.stroke = "color";
        bullet2.locationX = 0;
        bullet2.tooltipText = "[bold]{task}[/]\n{endTime}";
        bullet2.clickable = true;

        // //we need two events here.  One on the down event to store the date
        // //and one on the up event.  Because we can lose focus on the
        // //text field and not have released our click
        // bullet2.events.on("over", () => {
        //   this.ignore_click = true;
        // })
        // bullet2.events.on("out", ()=> {
        //   this.ignore_click = false;
        // })
        //
        // bullet2.events.on("down", (e) => {
        //   this.set_date_copy = this.set_date;
        // })
        // bullet2.events.on("hit",(e) => {
        //   const endDate = e.target.dataItem.dataContext.end;
        //   if(this.set_date_copy == "start") {
        //     this.$emit("setDates", endDate, null);
        //   } else if(this.set_date_copy == "end") {
        //     this.$emit("setDates", null, endDate);
        //   }
        // });

        const cursor = new am4plugins_timeline.CurveCursor();
        this.chart.cursor = cursor;
        cursor.xAxis = dateAxis;
        cursor.yAxis = categoryAxis;
        cursor.lineY.disabled = true;
        cursor.lineX.strokeDasharray = "1,4";
        cursor.lineX.strokeOpacity = 1;
        cursor.xAxis.opacity = 1;
        cursor.xAxis.tooltip.background.fill = am4core.color("#ffffff");
        cursor.xAxis.tooltip.background.fillOpacity = 0.5;

        this.chart.clickable = true;
        this.chart.events.on("down", (e) => {
          if (!this.ignore_click) {
            this.set_date_copy = this.set_date;
          }
        });

        // this.chart.events.on("hit",(e) => {
        //   if(!this.ignore_click) {
        //     const date = e.target.xAxes.getIndex(0).tooltipDate;
        //     if (this.set_date_copy == "start") {
        //       this.$emit("setDates", date, null);
        //     } else if (this.set_date_copy == "end") {
        //       this.$emit("setDates", null, date);
        //     }
        //   }
        // });

        this.eventSeries = this.chart.series.push(new am4plugins_timeline.CurveLineSeries());
        this.eventSeries.dataFields.dateX = "eventDate";
        this.eventSeries.dataFields.categoryY = "category";
        this.eventSeries.data = [
          {
            category: "",
            eventDate: this.start_time,
            eventString: moment(this.start_time).format("MMM DD hh:mm a"),
            name: "Start",
            description: "Something happened here"
          },
          {
            category: "",
            eventDate: this.end_time,
            eventString: moment(this.end_time).format("MMM DD hh:mm a"),
            name: "End",
            description: "Something happened here"
          }
        ];
        this.eventSeries.strokeOpacity = 0;

        let flagBullet = this.eventSeries.bullets.push(new am4plugins_bullets.FlagBullet());
        flagBullet.label.propertyFields.text = "name";
        flagBullet.locationX = 0;
        flagBullet.poleHeight = 25;
        if (this.theme == "dark") {
          flagBullet.pole.stroke = am4core.color("#FFF");
        }

        let scrollbar = new am4core.Scrollbar();
        this.chart.scrollbarX = scrollbar;
        scrollbar.align = "center";
        scrollbar.width = am4core.percent(85);
        scrollbar.dy = -25;

        if (this.theme == "dark") {
          scrollbar.startGrip.background.fill = am4core.color("#868686");
          scrollbar.startGrip.icon.stroke = am4core.color("#000000");
          scrollbar.startGrip.background.stroke = am4core.color("#000000");

          scrollbar.endGrip.background.fill = am4core.color("#868686");
          scrollbar.endGrip.icon.stroke = am4core.color("#000000");
          scrollbar.endGrip.background.stroke = am4core.color("#000000");

          scrollbar.thumb.background.fill = am4core.color("#838383");
          scrollbar.thumb.background.stroke = am4core.color("#000000");

          scrollbar.background.fill = am4core.color("#545454");

          // Applied on mouse hover
          scrollbar.startGrip.background.states.getKey("hover").properties.fill =
            am4core.color("#8f8f8f");
          scrollbar.endGrip.background.states.getKey("hover").properties.fill =
            am4core.color("#8f8f8f");
          scrollbar.thumb.background.states.getKey("hover").properties.fill =
            am4core.color("#8f8f8f");

          // Applied on mouse down
          scrollbar.startGrip.background.states.getKey("down").properties.fill =
            am4core.color("#8f8f8f");
          scrollbar.endGrip.background.states.getKey("down").properties.fill =
            am4core.color("#8f8f8f");
          scrollbar.thumb.background.states.getKey("down").properties.fill =
            am4core.color("#8f8f8f");
        }
      });
    }
  },
  watch: {
    "filterController.initialFilters.machine": function (val, oldVal) {
      if (val !== oldVal) {
        this.loadData();
      }
    },
    "filterController.initialFilters.code": function (val, oldVal) {
      if (val !== oldVal) {
        this.createSerpentineChart();
      }
    },
    "filterController.initialFilters.codeEventType": function (val, oldVal) {
      if (val !== oldVal) {
        this.loadData();
        this.createSerpentineChart();
      }
    },
    "filterController.initialFilters.date_range.from_date": function (val, oldVal) {
      if (val !== oldVal) {
        this.loadData();
      }
    },
    "filterController.initialFilters.date_range.to_date": function (val, oldVal) {
      if (val !== oldVal) {
        this.loadData();
      }
    },
    "filterController.initialFilters.scale": function (val, oldVal) {
      if (val !== oldVal) {
        this.loadData();
      }
    }
  }
};
</script>
<style lang="scss">
.level2-timeline {
  width: 100%;
  height: calc(100% - 20px);
  margin-top: 10px;
  .card {
    height: 100%;
    .card-content {
      .chartWrapper {
        height: 100%;
        #level2-timeline-chart {
          flex: 1;
          margin: 0;
          height: 100%;
        }
      }
    }
  }
}
</style>
