import moment from "moment/moment";
import Vue from "vue";

export default function () {
  Vue.prototype.$datetime = {
    default: "MMM D, YYYY, hh:mm A",
    iot: "MMM D, YYYY, hh:mm:ss A",
    date: "MMM D, YYYY"
  };
}

export function parseUTCDatetime(datetimeString) {
  var datetimeRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
  var dateArray = datetimeRegex.exec(String(datetimeString));
  if (dateArray) {
    return new Date(
      Date.UTC(
        dateArray[1],
        dateArray[2] - 1, // Careful, month starts at 0!
        dateArray[3],
        dateArray[4],
        dateArray[5],
        dateArray[6]
      )
    );
  }
}

export function getDateDisplay(timeString, interval = "hour", isKPI = false) {
  if (interval == "week") {
    let start = moment(timeString);
    let end = moment(timeString).add(7, "d");
    if (start.month() != end.month()) {
      return start.format("D MMM") + " - " + end.format("D MMM");
    } else {
      return start.format("D") + " - " + end.format("D MMM");
    }
  } else {
    switch (interval) {
      case "hour":
        if (isKPI) {
          let end = moment(timeString).add(1, "h");
          return moment(timeString).format("D MMM hhA") + " - " + end.format("hhA");
        } else {
          return moment(timeString).format("D MMM hhA");
        }
      case "day":
        return moment(timeString).format("D MMM");
      case "month":
        return moment(timeString).endOf("month").format("MMM YYYY");
    }
  }
}
