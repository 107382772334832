export const OPTIMIZATION_MODELS_ENDPOINT = "lighthouse/api/about";
export const OUTPUT_GROUPS_ENDPOINT = (optimizationModelId, page = 1) =>
  `lighthouse/api/optimization-models/${optimizationModelId}/output-groups?page=${page}`;
export const SCENARIOS_ENDPOINT = (optimizationModelId, page = 1) =>
  `lighthouse/api/optimization-models/${optimizationModelId}/scenarios/?page=${page}`;
export const SCENARIO_RESULT_ENDPOINT = (
  optimizationModelId,
  scenarioId,
  outputGroupId,
  offset = -1,
  limit = 10
) =>
  `lighthouse/api/optimization-models/${optimizationModelId}/scenarios/${scenarioId}/output_groups/${outputGroupId}?dt_ordering_key=${offset}&dt_size=${limit}`;

export const getOptimizationModel = async (client, id) =>
  await client.get(`${OPTIMIZATION_MODELS_ENDPOINT}/${id}`);

export const fetchOutputGroups = async (client, optimizationModelId, page) => {
  try {
    const response = await client.get(OUTPUT_GROUPS_ENDPOINT(optimizationModelId, page));
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchScenarios = async (client, optimizationModelId, page) => {
  try {
    const response = await client.get(`${SCENARIOS_ENDPOINT(optimizationModelId, page)}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchScenarioResult = async ({
  client,
  optimizationModelId,
  scenarioId,
  outputGroupId,
  offset,
  limit
}) => {
  try {
    const response = await client.get(
      SCENARIO_RESULT_ENDPOINT(optimizationModelId, scenarioId, outputGroupId, offset, limit)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};
