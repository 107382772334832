var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tablecontainer",staticClass:"flex-table"},[_c('div',{staticClass:"table-body"},[_c('v-simple-table',{ref:"table",attrs:{"dense":"","fixed-header":"","height":_vm.tableHeight}},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(t,i){return (!t.hidden)?_c('th',{key:i,ref:"tablehead",refInFor:true,class:t.sortable && 'clickable',on:{"click":function($event){t.sortable && _vm.setSort(t.key, t.sort_method)}}},[_c('div',{key:_vm.sortValues,staticClass:"flex-head"},[_c('i',{class:_vm.caretClass[_vm.sort[t.key]]}),_vm._v(" "),_c('div',{style:(t.column_style)},[_vm._v(_vm._s(t.title))]),_vm._v(" "),_c('i',{staticClass:"hidden"})])]):_vm._e()}),0)]),_vm._v(" "),(_vm.sortedData.length > 0)?_c('tbody',_vm._l((_vm.sortedData),function(data,di){return _c('Fragment',{key:di},[_c('tr',{key:di},_vm._l((_vm.columns),function(col,ci){
var _obj, _obj$1;
return (!col.hidden)?_c('td',{key:ci,class:( _obj = {
                titlerow: ci === 0,
                clickable: 'clickHandler' in col
              }, _obj[col.cssClass] = 'cssClass' in col, _obj ),on:{"click":function($event){return _vm.handleClick(col, data)}}},[_c('div',{staticClass:"titlerowContent"},[_c('div',[(ci === 0 && data['_nested'] && data['_nested'].length > 0)?_c('i',{staticClass:"fa fa-chevron-circle-right expand-icon",class:_vm.expanded[data._i] && 'rotated',on:{"click":function () {
                        _vm.expandRow(data);
                      }}}):(ci === 0)?_c('i',{staticClass:"fa fa-chevron-circle-right expand-icon hidden"}):_vm._e()]),_vm._v(" "),(col.html)?_c('div',{style:(("color: " + (_vm.checkSelected(col, data) ? (col.selected ? col.selected.color : 'unset') : 'unset')))},[_c('span',{style:({ cursor: col.click ? 'pointer' : null }),domProps:{"innerHTML":_vm._s(data[col.html])},on:{"click":function () {
                        col.click ? (col.key ? col.click(data[col.key]) : col.click(di)) : null;
                      }}})]):_c('div',{staticClass:"titleText",class:( _obj$1 = {}, _obj$1[data._class && data._class[col.key]] = data._class && data._class[col.key], _obj$1['align-right'] =  col.alignRight, _obj$1 ),style:(col.style),on:{"click":function () {
                      col.click ? col.click(data[col.key]) : null;
                    }}},[_vm._v("\n                  "+_vm._s(col.format ? col.format(data[col.key]) : data[col.key])+"\n                ")])])]):_vm._e()}),0),_vm._v(" "),_vm._l((data['_nested']),function(nested,ni){return (_vm.expanded[data._i])?_c('tr',{key:(di + "_" + ni),staticClass:"nest_row"},_vm._l((_vm.nested_columns),function(col,ci){return _c('td',{key:ci,class:ci === 0 ? 'titlerow nested' : null},[_c('div',{class:nested._class && nested._class[col.key],style:(col.style)},[_vm._v("\n                "+_vm._s(col && nested
                    ? col.format
                      ? col.format(nested[col.key])
                      : nested[col.key]
                    : "")+"\n              ")])])}),0):_vm._e()})],2)}),1):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"table-foot"},[(_vm.footer.length > 0)?_c('v-simple-table',{attrs:{"dense":""}},[_c('tfoot',[_c('tr',_vm._l((_vm.footer),function(t,i){return _c('td',{ref:"tablefoot",refInFor:true},[(t.type === 'csv')?_c('v-btn',{staticClass:"ma-2",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","small":""},on:{"click":t.csv}},[_c('v-icon',{staticStyle:{"font-size":"12px"}},[_vm._v("mdi-file-download")]),_vm._v(" CSV\n            ")],1):_vm._e(),_vm._v(" "),(t.type === 'text')?_c('div',{staticClass:"center-text",class:t.class},[_vm._v("\n              "+_vm._s(t.text)+"\n            ")]):_vm._e()],1)}),0)])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }