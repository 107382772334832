import { useQuery } from "@tanstack/vue-query";
import { settingsApi } from "./settingsApi";
import { computed, readonly } from "@vue/composition-api";
import { QUERY_LONG_LIVE_STALE_TIME } from "@/shared/constants";

export const useSettingsQuery = () => {
  const query = useQuery({
    queryKey: ["settings"],
    queryFn: settingsApi.getSettings,
    staleTime: QUERY_LONG_LIVE_STALE_TIME
  });

  const settings = computed(() => query.data.value && query.data.value.data.settings[0]);

  return {
    ...query,
    settings: readonly(settings)
  };
};
