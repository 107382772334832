<template>
  <div
    class="realtime_card"
    :style="{ background: item.machine_status ? item.machine_status.color : '' }"
  >
    <div class="title_bar">
      <div class="title_bar_left">
        <div class="icon">
          <img
            class="user-image"
            :src="item.avatar"
            v-if="item.avatar"
          />
          <div
            class="default-user"
            v-if="!item.avatar"
          >
            <i class="fa fa-user"></i>
          </div>
        </div>
        <div>{{ item.name }}</div>
        <div class="title_bar_right">
          <div
            v-if="item.checked_in"
            class="icon_check"
          >
            <i class="fa fa-check" />
          </div>
          <div
            v-else
            class="icon_checkedout"
          >
            <i class="fa fa-remove" />
          </div>
        </div>
      </div>
    </div>
    <div class="content_grid">
      <div class="content_item">
        <div>{{ $t("Zone") }}</div>
        <div>{{ item.zone }}</div>
      </div>
      <div class="content_item">
        <div>{{ $t("Status") }}</div>
        <div>{{ item.machine_status.name }}</div>
      </div>
      <div class="content_item">
        <div>{{ $t("Target") }}</div>
        <div>{{ item.target_units.toFixed() }} units</div>
      </div>
      <div class="content_item">
        <div>{{ $t("Machine") }}</div>
        <div>{{ item.machine }}</div>
      </div>
      <div class="content_item">
        <div>{{ $t("Operator") }}</div>
        <div class="name">{{ item.checked_in ? $t("Checked-In") : $t("Checked-Out") }}</div>
      </div>
      <div class="content_item">
        <div>{{ $t("Produced") }}</div>
        <div>{{ item.actual }}</div>
      </div>
      <div class="content_item">
        <div>{{ $t("Part") }}</div>
        <div>{{ item.part }}</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
.realtime_card {
  background: #2f2f2f;
  border-radius: 10px;
  height: 185px;
  margin: 4px;
  padding: 8px;

  .icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .user-image {
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
    .default-user {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      border-radius: 100%;
      background-color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content_grid {
    font-size: 12px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-around;
    font-weight: bold;

    .content_item {
      line-height: 1.3em;
      padding-bottom: 10px;
      & > div:first-child {
        color: #2d2b35;
      }
    }
  }

  .title_bar {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    font-weight: bold;
    padding-bottom: 10px;

    .icon_check {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: white;
      color: #14c914;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }

    .icon_checkedout {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: white;
      color: #930618;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }

    .title_bar_left {
      display: flex;
      align-items: center;
    }

    .title_bar_right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    .icon_user {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: #ffd55b;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }
}
</style>
<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
